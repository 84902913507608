import React, { useEffect, useState, useCallback, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import {
  FaSearch,
  FaEraser,
  FaDollarSign,
  FaTimes,
  FaInfoCircle,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import formatValue from '~/util/formatValue';

import { Input } from '~/components/Form';
import Modal from '~/components/Modal';
import { TableLoading, TableContainer } from '~/components/Table';

import { Container, Badge, Header, Filter, Content } from './styles';

const PriceTable = () => {
  const { company } = useAuth();

  const formRef = useRef(null);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    async function loadEvents(description) {
      if (visible && company) {
        try {
          setLoading(true);

          const response = await api.get('price-table', {
            params: {
              description,
              old_company_id: company.old_id,
            },
          });

          if (response.data.length > 0) {
            const data = response.data.map(event => ({
              ...event,
              formattedPrice: formatValue(event.price),
            }));

            setEvents(data);
          } else {
            setEvents([]);
            toast.warn('Nenhum dado foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setLoading(false);
        } catch (err) {
          toast.error('Falha ao carregar dados.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      }
    }

    loadEvents(search);
  }, [search, company, visible]);

  const handleSubmit = useCallback(data => {
    setSearch(data.description);
  }, []);

  const handleToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const resetForm = useCallback(() => {
    formRef.current.reset();
    setSearch('');
  }, [formRef]);

  return (
    <>
      <Container visible={visible}>
        <Badge onClick={handleToggleVisible}>
          <FaDollarSign size={18} color="#fcfcfc" />
          <span>Tabela de preços</span>
        </Badge>
        <Modal isOpen={visible} setIsOpen={handleToggleVisible}>
          <Header>
            <div>
              <FaDollarSign size={20} color="#44546a" />
              <h1>Tabela de Preços</h1>
            </div>

            <button type="button" onClick={handleToggleVisible}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </Header>

          <Filter ref={formRef} onSubmit={handleSubmit}>
            <Input name="description" label="Pesquisar" />

            <div>
              <button type="submit">
                <FaSearch />
              </button>
              <button type="button" onClick={resetForm}>
                <FaEraser />
              </button>
            </div>
          </Filter>

          {loading || !company ? (
            <TableLoading />
          ) : (
            <Content className="content">
              <ReactTooltip
                place="bottom"
                type="info"
                backgroundColor="#337ab7"
                effect="solid"
              />
              <TableContainer>
                <thead>
                  <tr>
                    <th className="value">Valor</th>
                    <th className="description">Descrição</th>
                    <th className="obs-hint" />
                    <th className="obs">Observação</th>
                  </tr>
                </thead>
                <tbody>
                  {events &&
                    events.map(event => (
                      <tr key={event.id}>
                        <td className="value">{event.formattedPrice}</td>
                        <td className="description">{event.description}</td>
                        {event.obs && (
                          <td>
                            <FaInfoCircle
                              data-tip={event.obs}
                              color="#337ab7"
                            />
                          </td>
                        )}
                        {event.obs && <td className="obs">{event.obs}</td>}
                      </tr>
                    ))}
                </tbody>
              </TableContainer>
            </Content>
          )}
        </Modal>
      </Container>
    </>
  );
};

export default PriceTable;

import React, { useCallback, useState } from 'react';
import { FaEraser, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import base64 from 'base-64';

import history from '~/services/history';
import api from '~/services/api';

import Modal from '~/components/Modal';
import { TableContainer } from '~/components/Table';
import { ButtonSpinner } from '~/components/Spinner';
import PermissionComponent from '~/components/PermissionComponent';

import { Header, Content, Td } from './styles';

const SchedulesModal = ({ isOpen, setIsOpen, data, type }) => {
  const [schedules, setSchedules] = useState(data);
  const [scheduleLoading, setScheduleLoading] = useState(null);

  const handleChangeDeadline = useCallback(async id => {
    try {
      setScheduleLoading(id);
      await api.put(`/kpi/deadline/${id}`);

      setSchedules(oldSchedules => oldSchedules.filter(item => item.id !== id));

      toast.success('Compromisso conrrigido com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      setScheduleLoading(null);
    } catch (err) {
      setScheduleLoading(null);
      toast.error('Falha ao corrigir compromisso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      widthProp={900}
      heightProp={600}
    >
      <Header>
        <div>
          <h1>Compromissos</h1>
        </div>
        <aside>
          <button type="button" onClick={setIsOpen}>
            <FaTimes size={20} color="#44546a" />
          </button>
        </aside>
      </Header>
      <Content>
        <TableContainer>
          <thead>
            <tr>
              <PermissionComponent level={9}>
                {type === 'late' && <th className="icon" />}
              </PermissionComponent>
              <th className="description">Descrição</th>
              {type === 'late' ? (
                <>
                  <th className="deadline">Prazo</th>
                  <th className="deadline">Conclusão</th>
                </>
              ) : (
                <th className="deadlineOnly">Prazo</th>
              )}
            </tr>
          </thead>
          <tbody>
            {schedules.map((item, index) => (
              <tr key={index} className="hover">
                <PermissionComponent level={9}>
                  {type === 'late' && (
                    <td className="icon">
                      {scheduleLoading === item.id ? (
                        <ButtonSpinner color="#44546a" />
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleChangeDeadline(item.id)}
                        >
                          <FaEraser color="#44546a" />
                        </button>
                      )}
                    </td>
                  )}
                </PermissionComponent>
                <Td
                  className="description"
                  onClick={() =>
                    history.push(`/schedule/view/${base64.encode(item.id)}`)
                  }
                >
                  {item.description}
                </Td>
                {type === 'late' ? (
                  <>
                    <td className="deadline">{item.deadline_date}</td>
                    <td className="deadline">{item.conclusion_date}</td>
                  </>
                ) : (
                  <td className="deadlineOnly">{item.deadline_date}</td>
                )}
              </tr>
            ))}
          </tbody>
        </TableContainer>
      </Content>
    </Modal>
  );
};

export default SchedulesModal;

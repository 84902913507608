import React, { useCallback, useEffect, useState } from 'react';
import {
  FaChevronLeft,
  FaChevronRight,
  FaExclamationTriangle,
} from 'react-icons/fa';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { Container, File, Title, FileContent, Header, Content } from './styles';
import api from '~/services/api';
import { Spinner } from '~/components/Spinner';

const ConfirmSendProtocol = ({ onClick, onClose, protocol }) => {
  const [documentIndex, setDocumentIndex] = useState(0);
  const [documentView, setDocumentView] = useState(protocol.documents[0]);
  const [url, setUrl] = useState(null);

  const totalDocuments = protocol.documents.length;
  const { documents } = protocol;

  const [urlLoading, setUrlLoading] = useState(true);

  const handleChangeUrl = useCallback(async blobName => {
    try {
      if (blobName) {
        if (
          blobName.match(/.*pdf/) ||
          blobName.match(/.*txt/) ||
          blobName.match(/.*png/) ||
          blobName.match(/.*jpg/) ||
          blobName.match(/.*jpeg/)
        ) {
          setUrlLoading(true);
          const response = await api.get('files/download', {
            params: {
              blobName,
            },
            responseType: 'blob',
          });

          const fileURL = URL.createObjectURL(response.data);

          setUrl(fileURL);
        } else {
          setUrl(null);
        }
      } else {
        setUrl(null);
      }
    } catch {
      toast.error('Falha ao abrir arquivo.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setUrl(null);
    } finally {
      setUrlLoading(false);
    }
  }, []);

  useEffect(() => {
    handleChangeUrl(documents[0].file_url);
  }, [documents, handleChangeUrl]);

  const nextDocumentView = () => {
    if (totalDocuments === documentIndex + 1) {
      setDocumentView(documents[0]);
      setDocumentIndex(0);
      handleChangeUrl(documents[0].file_url);
    } else {
      setDocumentIndex(old => old + 1);
      setDocumentView(documents[documentIndex + 1]);
      handleChangeUrl(documents[documentIndex + 1].file_url);
    }
  };

  const prevDocumentView = () => {
    if (documentIndex === 0) {
      setDocumentIndex(totalDocuments - 1);
      setDocumentView(documents[totalDocuments - 1]);
      handleChangeUrl(documents[totalDocuments - 1].file_url);
    } else {
      setDocumentIndex(old => old - 1);
      setDocumentView(documents[documentIndex - 1]);
      handleChangeUrl(documents[documentIndex - 1].file_url);
    }
  };

  return (
    <Container>
      <File>
        <Title>
          <span>
            {documentView.document.description} / {documentView.price || ' - '}{' '}
            / {documentView.deadline_formatted || ' - '}
          </span>
          <div>
            <button type="button" onClick={prevDocumentView}>
              <FaChevronLeft />
            </button>

            <span>
              {documentIndex + 1} de {totalDocuments}
            </span>

            <button type="button" onClick={nextDocumentView}>
              <FaChevronRight />
            </button>
          </div>
        </Title>
        <FileContent>
          {urlLoading ? (
            <Spinner />
          ) : (
            <>
              {url ? (
                <iframe
                  title="document-view"
                  src={url}
                  frameBorder="0"
                  scrolling="auto"
                  height="100%"
                  width="100%"
                />
              ) : (
                <>
                  <FaExclamationTriangle size={38} color="#01579b" />
                  <div>
                    <span>
                      {documentView.file_url
                        ? 'Anexo indiponível para visualização'
                        : 'Sem anexo'}
                    </span>
                    {documentView.file && <span>{documentView.file}</span>}
                  </div>
                </>
              )}
            </>
          )}
        </FileContent>
      </File>

      <Content>
        <Header>
          <strong>Atenção</strong>
        </Header>
        <span>
          <strong>protocolo {protocol.cod}</strong>

          <strong>{protocol.client.name}</strong>

          <br />

          <span>para: </span>
          {protocol.viewers.map(viewer => (
            <span key={viewer.id}> - {viewer.email}</span>
          ))}
        </span>
        <div>
          <section>
            <button
              type="button"
              onClick={() => {
                onClick();
                onClose();
              }}
            >
              {protocol.situation === 0 ? 'Enviar ' : 'Reenviar '}
            </button>
            <button
              type="button"
              onClick={() => {
                onClose();
              }}
            >
              Cancelar
            </button>
          </section>
        </div>
      </Content>
    </Container>
  );
};

export default ConfirmSendProtocol;

ConfirmSendProtocol.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

import React, { useCallback, useState } from 'react';
import Modal from '~/components/Modal';
import Form from './Form';
import List from './List';

const ProcessTypes = ({ isOpen, setIsOpen, newModel, defaultType }) => {
  const [isForm, setIsForm] = useState(newModel);
  const [idProcessModel, setIdProcessModel] = useState(null);

  const handleChangeId = useCallback(id => {
    setIdProcessModel(id);
  }, []);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      {isForm ? (
        <Form
          id={idProcessModel}
          handleChangeId={handleChangeId}
          setIsForm={setIsForm}
          defaultType={defaultType}
        />
      ) : (
        <List
          handleChangeId={handleChangeId}
          setIsForm={setIsForm}
          setIsOpen={setIsOpen}
        />
      )}
    </Modal>
  );
};

export default ProcessTypes;

import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';

import ClearBackground from '../../../../components/ClearBackground';

import { Container, Content, Left, Right, Header, Problem } from './styles';

const AlreadyExistsDocuments = ({ setIsOpen, problem, resolve }) => {
  return (
    <>
      <ClearBackground />
      <Container>
        <Content>
          <Left>
            <FaExclamationTriangle size={38} color="#01579b" />
          </Left>
          <Right>
            <Header>
              <strong>Corrija o seguinte problema:</strong>
            </Header>
            <Problem>
              <div className="document">
                <strong>Documento {problem.document}</strong>
                <strong>{problem.competence}</strong>
              </div>
              {problem.problem_type === 0 ? (
                <>
                  <span>
                    Há um protocolo gerado e não enviado com esse mesmo
                    documento (protocolo {problem.protocol_cod}). Deseja retirar
                    o documento deste protocolo ou quer prosseguir assim mesmo e
                    duplicar o documento?
                  </span>

                  <div className="buttons">
                    <button
                      type="button"
                      onClick={() => {
                        resolve();
                        setIsOpen();
                      }}
                    >
                      Retirar
                    </button>
                    <button type="button" onClick={setIsOpen}>
                      Duplicar
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <span>
                    Há um protocolo enviado com esse mesmo documento (Protocolo{' '}
                    {problem.protocol_cod}), deseja enviar e substituir o
                    anterior ou prosseguir assim mesmo e duplicar o documento?
                  </span>

                  <div className="buttons">
                    <button
                      type="button"
                      onClick={() => {
                        resolve();
                        setIsOpen();
                      }}
                    >
                      Substituir
                    </button>
                    <button type="button" onClick={setIsOpen}>
                      Duplicar
                    </button>
                  </div>
                </>
              )}
            </Problem>
          </Right>
        </Content>
      </Container>
    </>
  );
};

export default AlreadyExistsDocuments;

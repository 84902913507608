import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px;
  background-color: #fff;
  color: #44546a;
  border: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  h2 {
    color: #44546a;

    &:hover {
      color: #44546a;
    }
  }

  .signin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    min-width: 375px;
    border-right: 1px solid rgba(68, 84, 106, 0.5);
    padding: 20px 50px 20px 20px;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 70%;
    margin: 30px;
  }

  .preview {
    display: none;
    border: 1px solid ${({ theme }) => theme.hover.primary};
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.hover.primary};
    text-align: center;
    padding: 15px 40px;
    margin-top: 20px;

    svg {
      position: relative;
      top: 2px;
      left: -3px;
    }

    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    padding: 15px;
    overflow: auto;

    .signin {
      width: 100%;
      min-height: 100%;
      min-width: 0;
      padding: 0;
      border: 0;
    }

    .info {
      width: 100%;
      min-height: 100%;
      margin: 0;
      margin-top: 20px;
      overflow: initial;
    }

    .preview {
      display: block;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;

    img {
      width: 100px;
      height: 100px;
    }
  }

  .documents {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 24px;
    margin: 20px 0 25px;

    div {
      display: flex;
      flex-direction: column;
    }

    span {
      opacity: 0.8;
      margin-bottom: 8px;
    }

    span,
    strong {
      color: ${({ theme }) => theme.hover.primary};

      &:hover {
        color: ${({ theme }) => theme.hover.primary};
      }
    }
  }

  form {
    height: 185px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;

    button {
      border: 0;
      border-radius: 8px;
      background: ${({ theme }) => theme.colors.success};
      color: #fff;
      text-align: center;
      padding: 15px 40px;

      transition: background 0.3s;
      &:hover {
        background: ${({ theme }) => theme.hover.success};
      }
    }
  }
`;

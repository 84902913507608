import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import base64 from 'base-64';
import { FaAngleDoubleLeft, FaCheckCircle } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

import { FormLoading } from '~/components/Form';

import history from '~/services/history';
import api from '~/services/api';
import { useProcessAuth } from '~/hooks';

import RelatedsForm from './Relateds';
import IndividualForm from './Individual';
import GroupForm from './Group';
import EmployeeForm from './Employee';
import ProcessContext from './context';

import { Container, Sections, Forms, Finish, BackButton } from './styles';

const ProcessPortalForms = () => {
  const { processModel } = useProcessAuth();
  const [process, setProcess] = useState(null);

  const { state } = useLocation();

  const id = state?.id || null;

  useEffect(() => {
    if (!state || state.id === undefined) {
      history.push('/process-portal');
    }
  }, [state]);

  useEffect(() => {
    if (!processModel || Object.keys(processModel).length === 0) return;

    async function loadProcess() {
      try {
        const response = await api.get(`/process-portal/process/${id}`);

        setProcess(response.data);
      } catch {
        toast.error('Falha ao buscar processo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    if (!id) {
      if (
        (processModel.model_type === 0 && processModel.process_type > 0) ||
        processModel.model_type === 1
      ) {
        setProcess({ portal_situation: 0 });
      } else {
        setProcess({ portal_situation: 1 });
      }
    } else {
      loadProcess();
    }
  }, [id, processModel]);

  return (
    <>
      <Container>
        <ProcessContext.Provider value={{ process, setProcess }}>
          {!process ? (
            <FormLoading />
          ) : (
            <Forms>
              {process.portal_situation === 0 && (
                <>
                  <Sections
                    onClick={() =>
                      setProcess(oldProcess => ({
                        ...oldProcess,
                        situation: oldProcess.situation + 1,
                      }))
                    }
                  >
                    {processModel.model_type === 0 ? (
                      <>
                        {processModel.process_type > 0 && (
                          <section className="in-progress">Cadastro</section>
                        )}
                        <section className="waiting">Dados gerais</section>
                        {processModel.process_type > 0 && (
                          <section className="waiting">Dados por sócio</section>
                        )}
                      </>
                    ) : (
                      <>
                        <section className="in-progress">Funcionário</section>
                        <section className="waiting">Dados gerais</section>
                      </>
                    )}
                  </Sections>

                  {processModel.model_type === 0 ? (
                    <RelatedsForm />
                  ) : (
                    <EmployeeForm />
                  )}
                </>
              )}
              {process.portal_situation === 1 && (
                <>
                  <Sections
                    onClick={() =>
                      setProcess(oldProcess => ({
                        ...oldProcess,
                        situation: oldProcess.situation + 1,
                      }))
                    }
                  >
                    {processModel.model_type === 0 ? (
                      <>
                        {processModel.process_type > 0 && (
                          <section className="concluded">Cadastro</section>
                        )}
                        <section className="in-progress">Dados gerais</section>
                        {processModel.process_type > 0 && (
                          <section className="waiting">Dados por sócio</section>
                        )}
                      </>
                    ) : (
                      <>
                        <section className="concluded">
                          {process.employee.name === null
                            ? 'Funcionário'
                            : process.employee.name}
                        </section>
                        <section className="in-progress">Dados gerais</section>
                      </>
                    )}
                  </Sections>

                  <GroupForm />
                </>
              )}
              {process.portal_situation === 2 && (
                <>
                  <Sections
                    onClick={() =>
                      setProcess(oldProcess => ({
                        ...oldProcess,
                        situation: oldProcess.situation + 1,
                      }))
                    }
                  >
                    {processModel.model_type === 0 &&
                      processModel.process_type > 0 && (
                        <section className="concluded">Cadastro</section>
                      )}
                    <section className="concluded">Dados gerais</section>
                    {processModel.process_type > 0 && (
                      <section className="in-progress">Dados por sócio</section>
                    )}
                  </Sections>

                  <IndividualForm process={process} />
                </>
              )}
              {process.portal_situation === 3 && (
                <>
                  <Sections>
                    {processModel.model_type === 0 ? (
                      <>
                        {processModel.process_type > 0 && (
                          <section className="concluded">Cadastro</section>
                        )}
                        <section className="concluded">Dados gerais</section>
                        {processModel.process_type > 0 && (
                          <section className="concluded">
                            Dados por sócio
                          </section>
                        )}
                      </>
                    ) : (
                      <>
                        <section className="concluded">Funcionário</section>
                        <section className="concluded">Dados gerais</section>
                      </>
                    )}
                  </Sections>

                  <Finish>
                    <FaCheckCircle color="#00af48" size={40} />

                    <h1>Sua parte já foi feita!</h1>

                    <div>
                      <BackButton
                        type="button"
                        onClick={() => history.push('/process-portal')}
                      >
                        <FaAngleDoubleLeft size={10} />
                        Voltar
                      </BackButton>
                      <Link
                        to={`/process-situation/${base64.encode(process.id)}`}
                        target="_blank"
                      >
                        Clique aqui para acompanhar seu processo
                      </Link>
                    </div>
                  </Finish>
                </>
              )}
            </Forms>
          )}
        </ProcessContext.Provider>
      </Container>
    </>
  );
};

export default ProcessPortalForms;

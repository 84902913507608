import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactInputMask from 'react-input-mask';

import { useField } from '@unform/core';

import { Container } from './styles';

const InputMask = ({ name, label, className, ...rest }) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container className={className} error={error ? 1 : 0}>
      <label>{label}</label>
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        onFocus={clearError}
        {...rest}
      />
      {error && <span>{error}</span>}
    </Container>
  );
};

export default InputMask;

InputMask.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

InputMask.defaultProps = {
  label: null,
  className: null,
};

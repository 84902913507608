import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  svg {
    width: 20px;
    height: 20px;
  }

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  a,
  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.primary};
    transition: color 0.2s;

    &:hover {
      color: ${({ theme }) => theme.hover.primary};
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  a,
  button {
    display: flex;
    flex-direction: column;
    align-items: center;

    & + button,
    & + a {
      margin-left: 16px;
    }

    background: none;
    border: none;
    transition: color 0.2s;
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.hover.primary};
    }

    svg {
      width: 15px;
      height: 15px;
      margin-bottom: 4px;
    }

    span {
      font-size: 12px;
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Table = styled.table`
  border-spacing: 0px;

  thead th {
    color: #44546a;
    text-align: left;
    padding: 5px 15px 5px 0px;
    border-bottom: 1px solid #c7c7c7;
    background: ${({ theme }) => theme.colors.white};
    position: sticky;
    top: 0;

    &.sort:hover {
      cursor: pointer;
    }

    > div {
      display: flex;
      align-items: center;

      > svg {
        margin-left: 5px;
      }
    }
  }

  tbody {
    tr {
      &.hover:hover {
        cursor: pointer;
      }

      &:nth-child(2n + 2) {
        background: #f4f4f4;
      }
    }

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 5px 15px 5px 0px;
      text-align: left;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export const FormContainer = styled(Form)`
  padding: 10px;

  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;
    color: ${({ theme }) => theme.colors.grey};

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    > div {
      & + div {
        margin-left: 16px;
      }
    }
  }

  .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }
  }
`;

import styled from 'styled-components';
import { Form } from '@unform/web';

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  .name,
  .situation,
  .level {
    width: 33%;
  }

  > div + div {
    margin-left: 16px;
  }

  button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;
    width: 4%;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .user,
    .email {
      padding-left: 8px;
      width: 35%;
    }

    .level {
      width: 25%;
    }

    .active {
      width: 5%;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BasicInfo = styled.div`
  .document,
  .email,
  .birthday,
  .phone,
  .name,
  .pin,
  .level,
  .active {
    width: 25%;
  }
`;

export const Departments = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .dpt_labour,
  .dpt_tax,
  .dpt_accounting,
  .dpt_financial,
  .dpt_admin,
  .dpt_auxiliar {
    width: 17%;

    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    > input {
      margin-right: 16px;
      transform: scale(1.5);
    }
  }
`;

export const WorkTime = styled.div`
  margin-top: 16px;

  .work_time_description {
    width: 50%;
  }

  .work_time_start,
  .work_time_stop {
    width: 20%;
  }

  .work_time_balance {
    width: 10%;
  }
`;

export const WorkTimetable = styled.div`
  margin: 16px 0px 0px 16px;

  .work_timetable_day {
    width: 50%;
  }

  .work_timetable_in_hour,
  .work_timetable_out_hour {
    width: 25%;
  }
`;

export const AccessTimetable = styled.div`
  margin-top: 16px;

  .access_timetable_day {
    width: 50%;
  }

  .access_timetable_start,
  .access_timetable_stop {
    width: 25%;
  }
`;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import ReactInputMask from 'react-input-mask';
import {
  FaSearch,
  FaUser,
  FaBuilding,
  FaRegIdCard,
  FaPhone,
  FaEnvelope,
  FaTimes,
  FaCopy,
  FaInfoCircle,
  FaBirthdayCake,
} from 'react-icons/fa';
import { format, parseISO } from 'date-fns';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import { Spinner } from '~/components/Spinner';
import { Input } from '~/components/Form';
import ClearBackground from '~/components/ClearBackground';

import {
  Container,
  Badge,
  Content,
  Title,
  SearchContainer,
  ContactsList,
  Contact,
  ContactName,
  ContactInfo,
  RelatedContainer,
  Related,
  RelatedName,
  RelatedContacts,
  LoadingDiv,
  Name,
} from './styles';

const SearchContact = () => {
  const { company } = useAuth();

  const [, height] = useWindowSize();

  const searchRef = useRef(null);
  const checkboxRef = useRef(false);

  const [visible, setVisible] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      const searchElement = document.getElementById('search');

      if (searchElement) {
        searchElement.focus();
      }
    }
  }, [visible]);

  const handleToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleRedirect = useCallback((id, type) => {
    if (type === 1) {
      history.push('/client/view', { id });

      setVisible(false);
    }
    if (type === 0) {
      history.push('/contact/view', { id });

      setVisible(false);
    }
    if (type === 2) {
      history.push('/related-people/view', { id });

      setVisible(false);
    }
  }, []);

  const handleSubmit = useCallback(
    async ({ search }) => {
      try {
        setLoading(true);

        const response = await api.get(
          `relationships/search-contacts/${company.id}`,
          {
            params: {
              search,
              outsourced: checkboxRef.current.checked,
            },
          }
        );

        if (response.data.length > 0) {
          const data = response.data.map(item => ({
            ...item,
            obs: item.obs === '' || item.obs === null ? null : item.obs,
            document:
              item.document === '' || item.document == null
                ? null
                : item.document,
            phone: item.phone === '' || item.phone === null ? null : item.phone,
            phone_type:
              item.phone && item.phone.replace(/[^0-9]/g, '').length === 10
                ? 0
                : 1,
            secondary_phone:
              item.secondary_phone === '' || item.secondary_phone === null
                ? null
                : item.secondary_phone,
            secondary_phone_type:
              item.secondary_phone &&
              item.secondary_phone.replace(/[^0-9]/g, '').length === 10
                ? 0
                : 1,
            related: item.related.map(related => ({
              ...related,
              document:
                related.info.document === '' || related.info.document === null
                  ? null
                  : related.info.document,
              phone:
                related.info.phone === '' || related.info.phone === null
                  ? null
                  : related.info.phone,
              phone_type:
                related.info.phone !== '' &&
                related.info.phone !== null &&
                related.info.phone.replace(/[^0-9]/g, '').length === 10
                  ? 0
                  : 1,
              secondary_phone:
                related.info.secondary_phone === '' ||
                related.info.secondary_phone === null
                  ? null
                  : related.info.secondary_phone,
              secondary_phone_type:
                related.info.secondary_phone !== '' &&
                related.info.secondary_phone !== null &&
                related.info.secondary_phone.replace(/[^0-9]/g, '').length ===
                  10
                  ? 0
                  : 1,
              birthday: related.info.birthday
                ? format(parseISO(related.info.birthday), 'dd/MM/yyyy')
                : null,
            })),
          }));

          setContacts(data);
          setLoading(false);
        } else {
          toast.warn('Nenhum contato foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setLoading(false);
      } catch (err) {
        toast.error('Falha ao buscar contatos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    },
    [company]
  );

  return (
    <>
      <Container visible={visible}>
        <Badge onClick={handleToggleVisible}>
          <FaPhone size={18} color="#fcfcfc" />
          <span>Contatos</span>
        </Badge>
        <Content visible={visible} height={height}>
          <Title>
            <div>
              <FaPhone size={18} color="#01579B" />
              <h3>Contatos</h3>
            </div>

            <FaTimes size={18} color="#ee4256" onClick={handleToggleVisible} />
          </Title>
          <SearchContainer ref={searchRef} onSubmit={handleSubmit}>
            <Input
              id="search"
              name="search"
              placeholder="Digite aqui para pesquisar"
            />

            <button type="submit">
              <FaSearch size={18} color="#44546A" />
            </button>
          </SearchContainer>

          <div className="checkbox">
            <label htmlFor="outsourced">Exibir terceirizados</label>
            <input
              id="outsourced"
              name="outsourced"
              ref={checkboxRef}
              type="checkbox"
            />
          </div>

          {!loading && (
            <ContactsList className="content">
              {contacts.length > 0 &&
                contacts.map(contactItem => (
                  <Contact key={contactItem.id}>
                    <ContactName>
                      <Name
                        onClick={() =>
                          handleRedirect(contactItem.id, contactItem.type)
                        }
                      >
                        <div>
                          {contactItem.document_type === 1 ? (
                            <FaUser color="#44546A" />
                          ) : (
                            <FaBuilding color="#44546A" />
                          )}
                        </div>

                        <span>{contactItem.name}</span>
                      </Name>

                      <button
                        type="button"
                        onClick={() => copy(contactItem.name)}
                      >
                        <FaCopy size={12} />
                      </button>
                    </ContactName>
                    <ContactInfo>
                      {contactItem?.obs !== null && (
                        <div>
                          <FaInfoCircle color="#44546A" />
                          <textarea
                            name="obs"
                            value={contactItem?.obs || ''}
                            readOnly
                          />
                        </div>
                      )}
                      {contactItem.document_type === 1 &&
                        contactItem.document !== null && (
                          <div>
                            <FaRegIdCard color="#44546A" />
                            <ReactInputMask
                              name="document"
                              value={contactItem.document}
                              type="text"
                              mask="999.999.999-99"
                              readOnly
                            />
                            <button
                              type="button"
                              onClick={() => copy(contactItem.document)}
                            >
                              <FaCopy size={12} />
                            </button>
                          </div>
                        )}
                      {contactItem.document_type === 2 &&
                        contactItem.document !== null && (
                          <div>
                            <FaRegIdCard color="#44546A" />
                            <ReactInputMask
                              name="document"
                              value={contactItem.document}
                              type="text"
                              mask="99.999.999/9999-99"
                              readOnly
                            />
                            <button
                              type="button"
                              onClick={() => copy(contactItem.document)}
                            >
                              <FaCopy size={12} />
                            </button>
                          </div>
                        )}
                      {contactItem.phone && (
                        <div>
                          <FaPhone color="#44546A" />
                          {contactItem.phone_type === 0 ? (
                            <ReactInputMask
                              name="phone"
                              value={contactItem.phone}
                              type="text"
                              mask="(99) 9999-9999"
                              readOnly
                            />
                          ) : (
                            <ReactInputMask
                              name="phone"
                              value={contactItem.phone}
                              type="text"
                              mask="(99) 99999-9999"
                              readOnly
                            />
                          )}
                          <button
                            type="button"
                            onClick={() => copy(contactItem.phone)}
                          >
                            <FaCopy size={12} />
                          </button>
                        </div>
                      )}
                      {contactItem.secondary_phone && (
                        <div>
                          <FaPhone color="#44546A" />
                          {contactItem.phone_type === 0 ? (
                            <ReactInputMask
                              name="secondary_phone"
                              value={contactItem.secondary_phone}
                              type="text"
                              mask="(99) 9999-9999"
                              readOnly
                            />
                          ) : (
                            <ReactInputMask
                              name="secondary_phone"
                              value={contactItem.secondary_phone}
                              type="text"
                              mask="(99) 99999-9999"
                              readOnly
                            />
                          )}
                          <button
                            type="button"
                            onClick={() => copy(contactItem.secondary_phone)}
                          >
                            <FaCopy size={12} />
                          </button>
                        </div>
                      )}
                      {!!contactItem.contacts &&
                        contactItem.contacts.map(contact => (
                          <div key={contact.id}>
                            {contact.type === 1 && (
                              <>
                                <a href={`mailto:${contact.content}`}>
                                  <FaEnvelope color="#00c853" />
                                </a>
                                <span>{contact.content}</span>
                              </>
                            )}
                            {contact.type === 2 && (
                              <>
                                <FaPhone color="#44546A" />
                                <ReactInputMask
                                  name="contact.content"
                                  value={contact.content}
                                  type="text"
                                  mask="(99) 9999-9999"
                                  readOnly
                                />
                              </>
                            )}
                            {contact.type === 3 && (
                              <>
                                <FaPhone color="#44546A" />
                                <ReactInputMask
                                  name="contact.content"
                                  value={contact.content}
                                  type="text"
                                  mask="(99) 99999-9999"
                                  readOnly
                                />
                              </>
                            )}
                            <button
                              type="button"
                              onClick={() => copy(contact.content)}
                            >
                              <FaCopy size={12} />
                            </button>
                          </div>
                        ))}
                    </ContactInfo>
                    <RelatedContainer>
                      {!!contactItem.related &&
                        contactItem.related.map(related => (
                          <Related key={related.id}>
                            <RelatedName>
                              <Name>
                                <div>
                                  {related.info.document_type === 1 && (
                                    <FaUser color="#08409a" />
                                  )}
                                  {related.info.document_type === 2 && (
                                    <FaBuilding color="#08409a" />
                                  )}
                                </div>
                                <span>{related.info.name}</span>
                              </Name>

                              <button
                                type="button"
                                onClick={() => copy(related.info.name)}
                              >
                                <FaCopy size={12} />
                              </button>
                            </RelatedName>
                            <RelatedContacts>
                              {related.info.document_type === 1 &&
                                related.document != null && (
                                  <div>
                                    <FaRegIdCard color="#44546A" />
                                    <ReactInputMask
                                      name="document"
                                      value={related.document}
                                      type="text"
                                      mask="999.999.999-99"
                                      readOnly
                                    />
                                    <button
                                      type="button"
                                      onClick={() => copy(related.document)}
                                    >
                                      <FaCopy size={12} />
                                    </button>
                                  </div>
                                )}
                              {related.info.document_type === 2 &&
                                related.document != null && (
                                  <div>
                                    <FaRegIdCard color="#44546A" />
                                    <ReactInputMask
                                      name="document"
                                      value={related.document}
                                      type="text"
                                      mask="99.999.999/9999-99"
                                      readOnly
                                    />
                                    <button
                                      type="button"
                                      onClick={() => copy(related.document)}
                                    >
                                      <FaCopy size={12} />
                                    </button>
                                  </div>
                                )}
                              {related.phone !== null && (
                                <div>
                                  <FaPhone color="#44546A" />
                                  {related.phone_type === 0 ? (
                                    <ReactInputMask
                                      name="phone"
                                      value={related.phone}
                                      type="text"
                                      mask="(99) 9999-9999"
                                      readOnly
                                    />
                                  ) : (
                                    <ReactInputMask
                                      name="phone"
                                      value={related.phone}
                                      type="text"
                                      mask="(99) 99999-9999"
                                      readOnly
                                    />
                                  )}
                                  <button
                                    type="button"
                                    onClick={() => copy(related.phone)}
                                  >
                                    <FaCopy size={12} />
                                  </button>
                                </div>
                              )}
                              {related.secondary_phone !== null && (
                                <div>
                                  <FaPhone color="#44546A" />
                                  {related.secondary_phone_type === 0 ? (
                                    <ReactInputMask
                                      name="secondary_phone"
                                      value={related.secondary_phone}
                                      type="text"
                                      mask="(99) 9999-9999"
                                      readOnly
                                    />
                                  ) : (
                                    <ReactInputMask
                                      name="secondary_phone"
                                      value={related.secondary_phone}
                                      type="text"
                                      mask="(99) 99999-9999"
                                      readOnly
                                    />
                                  )}
                                  <button
                                    type="button"
                                    onClick={() =>
                                      copy(related.secondary_phone)
                                    }
                                  >
                                    <FaCopy size={12} />
                                  </button>
                                </div>
                              )}
                              {related.birthday && (
                                <div>
                                  <FaBirthdayCake color="#44546A" />
                                  <span>{related.birthday}</span>
                                  <button
                                    type="button"
                                    onClick={() => copy(related.birthday)}
                                  >
                                    <FaCopy size={12} />
                                  </button>
                                </div>
                              )}
                              {!!related.info.contacts &&
                                related.info.contacts.map(contact => (
                                  <>
                                    {contact.content && (
                                      <div key={contact.id}>
                                        {contact.type === 1 && (
                                          <>
                                            <a
                                              href={`mailto:${contact.content}`}
                                            >
                                              <FaEnvelope color="#00c853" />
                                            </a>
                                            <span>{contact.content}</span>
                                          </>
                                        )}
                                        {contact.type === 2 && (
                                          <>
                                            <FaPhone color="#44546A" />
                                            <ReactInputMask
                                              name="contact_content"
                                              value={contact.content}
                                              type="text"
                                              mask="(99) 9999-9999"
                                              readOnly
                                            />
                                          </>
                                        )}
                                        {contact.type === 3 && (
                                          <>
                                            <FaPhone color="#44546A" />
                                            <ReactInputMask
                                              name="contact_content"
                                              value={contact.content}
                                              type="text"
                                              mask="(99) 99999-9999"
                                              readOnly
                                            />
                                          </>
                                        )}
                                        <button
                                          type="button"
                                          onClick={() => copy(contact.content)}
                                        >
                                          <FaCopy size={12} />
                                        </button>
                                      </div>
                                    )}
                                  </>
                                ))}
                            </RelatedContacts>
                          </Related>
                        ))}
                    </RelatedContainer>
                  </Contact>
                ))}
            </ContactsList>
          )}
        </Content>
      </Container>

      {loading && (
        <>
          <LoadingDiv>
            <Spinner />
          </LoadingDiv>
        </>
      )}

      {visible && <ClearBackground onClick={handleToggleVisible} />}
    </>
  );
};

export default SearchContact;

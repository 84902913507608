import React, { useState, useEffect, useRef, useCallback } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import {
  FaPlus,
  FaSearch,
  FaEraser,
  FaSort,
  FaSortUp,
  FaSortDown,
} from 'react-icons/fa';
import Pagination from '~/components/Pagination';
import Form from '../Form';

import { Input, DatePicker } from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import ConfirmWindow from '~/components/ConfirmWindow';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DateContainer,
  Line,
  WaitPage,
} from './styles';

const List = () => {
  const [typeForm, setTypeForm] = useState(0);
  const [idSupport, setIdSupport] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [hasActiveCall, setHasActiveCall] = useState(false);

  const { user } = useAuth();

  const filterRef = useRef(null);

  const [appointmentsLoading, setAppointmentsLoading] = useState(true);

  const [appointments, setAppointments] = useState([]);
  const [totalAppointments, setTotalAppointments] = useState(0);

  const [descriptionSearch, setDescriptionSearch] = useState(() => {
    const descriptionStorage = localStorage.getItem(
      '@Diretiva:support:filter:description'
    );

    if (descriptionStorage) {
      return descriptionStorage;
    }

    return '';
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startPeriod, setStartPeriod] = useState(() => {
    const startPeriodStorage = localStorage.getItem(
      '@Diretiva:support:filter:start'
    );

    if (startPeriodStorage && startPeriodStorage !== 'null') {
      return new Date(startPeriodStorage);
    }

    return null;
  });

  const [startPeriodForDatePicker, setStartPeriodForDatePicker] = useState(
    () => {
      const startPeriodStorage = localStorage.getItem(
        '@Diretiva:support:filter:start'
      );

      if (startPeriodStorage && startPeriodStorage !== 'null') {
        return new Date(startPeriodStorage);
      }

      return null;
    }
  );

  const [endPeriod, setEndPeriod] = useState(() => {
    const endPeriodStorage = localStorage.getItem(
      '@Diretiva:support:filter:end'
    );

    if (endPeriodStorage && endPeriodStorage !== 'null') {
      return new Date(endPeriodStorage);
    }

    return new Date();
  });

  const [endPeriodForDatePicker, setEndPeriodForDatePicker] = useState(() => {
    const endPeriodStorage = localStorage.getItem(
      '@Diretiva:support:filter:end'
    );

    if (endPeriodStorage && endPeriodStorage !== 'null') {
      return new Date(endPeriodStorage);
    }

    return new Date();
  });

  const [sortConfig, setSortConfig] = useState({
    key: 'start_date',
    direction: 'desc',
  });
  const sort = useCallback(
    key => {
      let direction = 'asc';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'asc'
      ) {
        direction = 'desc';
      }
      const element = document.getElementById(key);
      element.classList.add(direction);
      setSortConfig({ key, direction });
    },
    [sortConfig]
  );

  const resetFilter = useCallback(() => {
    setCurrentPage(1);

    filterRef.current.clearField('appointment');
    setDescriptionSearch('');
    localStorage.removeItem('@Diretiva:support:filter:description');

    filterRef.current.clearField('start');
    setStartPeriod(null);
    setStartPeriodForDatePicker(null);
    localStorage.removeItem('@Diretiva:support:filter:start');

    setEndPeriod(new Date());
    setEndPeriodForDatePicker(new Date());
    localStorage.removeItem('@Diretiva:support:filter:end');
  }, [filterRef]);

  const handleFilter = useCallback(data => {
    setCurrentPage(1);

    setDescriptionSearch(data.appointment);
    localStorage.setItem(
      '@Diretiva:support:filter:description',
      data.appointment
    );

    setStartPeriod(data.start);
    setStartPeriodForDatePicker(data.start);
    localStorage.setItem('@Diretiva:support:filter:start', data.start);

    setEndPeriod(data.end);
    setEndPeriodForDatePicker(data.end);
    localStorage.setItem('@Diretiva:support:filter:end', data.end);
  }, []);

  const loadSchedule = useCallback(async () => {
    if (user) {
      try {
        setAppointmentsLoading(true);

        const response = await api.get('schedule', {
          params: {
            company_id: 3,
            page: currentPage,
            description: descriptionSearch,
            user: user.id,
            start_period: startPeriod,
            end_period: endPeriod,
            done: '',
            key: sortConfig.key,
            direction: sortConfig.direction,
            type: 5,
          },
        });

        if (response.data.docs.length > 0) {
          const data = response.data.docs.map(item => ({
            ...item,
            start_date: format(parseISO(item.start_date), 'dd/MM/yyyy'),
            start_hour: format(parseISO(item.start_date), 'HH:mm'),
            feedback: item.feedback.map(feedbackItem => ({
              ...feedbackItem,
              feedback_date_formatted: feedbackItem.feedback_date
                ? format(
                    parseISO(feedbackItem.feedback_date),
                    "dd/MM/yyyy 'às' HH:mm"
                  )
                : null,
            })),
          }));

          if (response.data.docs[0].done === false) {
            setHasActiveCall(true);
          }
          setAppointments(data);
          setTotalPages(response.data.pages);
          setTotalAppointments(response.data.total);
        } else {
          setAppointments([]);
          setTotalPages(1);
          setTotalAppointments(0);
          toast.warn('Nenhum pedido de suporte foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setAppointmentsLoading(false);
      } catch (err) {
        toast.error('Falha ao buscar pedidos de suporte.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setAppointmentsLoading(false);
      }
    }
  }, [
    currentPage,
    descriptionSearch,
    endPeriod,
    sortConfig,
    startPeriod,
    user,
  ]);

  useEffect(() => {
    loadSchedule();
  }, [loadSchedule, refresh]);

  const confirmNewCall = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => setTypeForm(1)}
            onClose={onClose}
            message="Já existe um chamado em andamente. Deseja abrir um novo chamado?"
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [setTypeForm]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  return (
    <>
      <Container>
        <Header>
          <div>
            <h1>Problemas relatados</h1>
          </div>
        </Header>

        <Controls>
          <button
            type="button"
            onClick={hasActiveCall ? confirmNewCall : () => setTypeForm(1)}
          >
            <FaPlus />
            <span>Novo</span>
          </button>
          <button type="button" onClick={resetFilter}>
            <FaEraser />
            <span>Limpar filtros</span>
          </button>
        </Controls>

        <Filter ref={filterRef} onSubmit={handleFilter}>
          <Input
            name="appointment"
            className="appointment"
            label="Problema"
            defaultValue={descriptionSearch}
          />

          <DateContainer className="period">
            <label>Período</label>
            <div>
              <DatePicker
                name="start"
                selected={startPeriodForDatePicker}
                onChange={date => setStartPeriodForDatePicker(date)}
              />
              <span>até</span>
              <DatePicker
                name="end"
                selected={endPeriodForDatePicker}
                onChange={date => setEndPeriodForDatePicker(date)}
              />
            </div>
          </DateContainer>

          <button type="submit">
            <FaSearch />
          </button>
        </Filter>
        {appointmentsLoading ? (
          <TableLoading />
        ) : (
          <Content className="content">
            <TableContainer>
              <thead>
                <tr>
                  <th
                    id="start_date"
                    className="start_date sort"
                    onClick={() => sort('start_date')}
                  >
                    <div>
                      Data
                      {sortConfig.key !== 'start_date' && <FaSort />}
                      {sortConfig.key === 'start_date' &&
                        sortConfig.direction === 'asc' && <FaSortUp />}
                      {sortConfig.key === 'start_date' &&
                        sortConfig.direction === 'desc' && <FaSortDown />}
                    </div>
                  </th>
                  <th className="start_hour">Hora</th>
                  <th
                    id="description"
                    className="description sort"
                    onClick={() => sort('description')}
                  >
                    <div>
                      Mensagem
                      {sortConfig.key !== 'description' && <FaSort />}
                      {sortConfig.key === 'description' &&
                        sortConfig.direction === 'asc' && <FaSortUp />}
                      {sortConfig.key === 'description' &&
                        sortConfig.direction === 'desc' && <FaSortDown />}
                    </div>
                  </th>
                  <th className="done">Status</th>
                </tr>
              </thead>
              <tbody>
                {appointments.map(item => (
                  <tr
                    key={String(item.id)}
                    onClick={() => {
                      setIdSupport(item.id);
                      setTypeForm(2);
                    }}
                    className="hover"
                  >
                    <td className="start_date">{item.start_date}</td>
                    <td className="start_hour">{item.start_hour}</td>
                    <td className="description">{item.description}</td>
                    <td className="done">
                      {item.done ? 'Encerrado' : 'Aberto'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          </Content>
        )}
        <Pagination
          loading={appointmentsLoading ? 1 : 0}
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalAppointments}
          handlePage={handlePage}
        />
      </Container>
      <Line />
      {typeForm === 0 && (
        <WaitPage>
          <p>Você pode relatar algum problema</p>
          <button
            type="button"
            className="new"
            onClick={hasActiveCall ? confirmNewCall : () => setTypeForm(1)}
          >
            clicando aqui
          </button>
          <p>ou</p>
          <p>ver um problema já relatado e suas mensagens</p>
          <h4>clicando nele na tabela ao lado!</h4>
        </WaitPage>
      )}
      {typeForm === 1 && (
        <Form
          type={typeForm}
          close={() => setTypeForm(0)}
          refresh={() => {
            setTypeForm(0);
            setRefresh(!refresh);
          }}
          id={null}
        />
      )}
      {typeForm === 2 && (
        <Form
          type={typeForm}
          close={() => setTypeForm(0)}
          refresh={() => {
            setTypeForm(0);
            setRefresh(!refresh);
          }}
          id={idSupport}
        />
      )}
    </>
  );
};

export default List;

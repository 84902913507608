import React from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { Container } from './styles';

const ErrorPage = () => {
  localStorage.clear();

  return (
    <Container>
      <span>Ocorreu algum erro,</span>
      <span>verifique com seu contador se o link está correto!</span>
      <FaTimesCircle color="#E53935" size={50} />
    </Container>
  );
};

export default ErrorPage;

import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border};

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Header = styled.header`
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Content = styled(Form)`
  display: flex;
  flex-direction: column;

  padding: 15px;

  div + div {
    margin-top: 24px;
  }

  button {
    margin-top: 24px;
    padding: 8px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.success};
  }
`;

import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { Container, Icon, Header, Content } from './styles';

const ConfirmMarkDocument = ({ onClick, onClose }) => {
  return (
    <Container>
      <Icon>
        <FaExclamationTriangle size={38} color="#01579b" />
      </Icon>
      <Content>
        <Header>
          <strong>Atenção</strong>
        </Header>
        <span>
          <span>Deseja aualizar os processos em andamento?</span>
          <br />
        </span>
        <div>
          <section>
            <button
              type="button"
              onClick={() => {
                onClick(true);
                onClose();
              }}
            >
              Sim
            </button>
            <button
              type="button"
              onClick={() => {
                onClick(false);
                onClose();
              }}
            >
              Não
            </button>
          </section>
        </div>
      </Content>
    </Container>
  );
};

export default ConfirmMarkDocument;

ConfirmMarkDocument.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

import React from 'react';
import { format } from 'date-fns';
import { FaMinus } from 'react-icons/fa';

import formatContact from '~/util/formatContact';

import { Container, Main, TdTable, Td, Ticket, CompanyInfo } from './styles';

const DeliveryProtocol = ({ protocols, company }) => {
  const phoneCompany = company.phone
    ? company.phone > 9999999999
      ? formatContact(3, company.phone)
      : formatContact(2, company.phone)
    : '';

  return (
    <Container>
      <Main>
        {protocols && company && (
          <>
            {protocols.map(protocol => (
              <div key={protocol.id}>
                <hr />
                <Ticket border="1">
                  <thead>
                    <tr>
                      <td rowSpan="2" colSpan="1">
                        <div className="logo">
                          <img
                            src={
                              company.logo
                                ? company.logo_url
                                : 'https://myhappen.blob.core.windows.net/files-companies/28978_config_company_logo.png'
                            }
                            alt="icone-logo"
                            width="110"
                            height="110"
                          />
                        </div>
                      </td>
                      <Cell
                        rowSpan="1"
                        colSpan="1"
                        className="protocol"
                        label="Protocolo"
                        value={protocol.client.name}
                      />
                      <Cell
                        rowSpan="1"
                        colSpan="1"
                        className="number"
                        value={protocol.cod}
                        label="Número"
                      />
                    </tr>
                    <tr>
                      <Cell
                        rowSpan="1"
                        colSpan="1"
                        className="protocol"
                        value={protocol.receiver || ''}
                        label="Assinatura"
                      />
                      <Cell
                        rowSpan="1"
                        colSpan="1"
                        className="number"
                        value={protocol.delivered_date}
                        label="Data de recebimento"
                      />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="100">
                        <TdTable>
                          <table>
                            <thead>
                              <tr>
                                <th className="cod">Cód.</th>
                                <th className="document">Documento</th>
                                <th className="comp">Comp.</th>
                                <th className="deadline">Venc.</th>
                                <th className="price">Valor</th>
                                <th className="obs">Obs.</th>
                              </tr>
                            </thead>
                            <tbody>
                              {protocol.documents.map(item => (
                                <tr key={item.id}>
                                  <td className="cod">
                                    {item.document.barcode}
                                  </td>
                                  <td className="document">
                                    {item.document.description}
                                  </td>
                                  <td className="comp">{item.competence}</td>
                                  <td className="deadline">
                                    {item.deadline_formatted || <FaMinus />}
                                  </td>
                                  <td className="price">
                                    {item.price || <FaMinus />}
                                  </td>
                                  <td className="obs">
                                    {item.obs || 'Sem observações'}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </TdTable>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <Cell
                        rowSpan="1"
                        colSpan="1"
                        className="date"
                        value={format(new Date(), 'dd/MM/yyyy')}
                        label="Data de impressão"
                      />
                      <Cell
                        rowSpan="1"
                        colSpan="2"
                        className="user"
                        value={protocol.created_by}
                        label="Usuário"
                      />
                    </tr>
                  </tfoot>
                </Ticket>
                <CompanyInfo>
                  <strong>{company.name}</strong>
                  <span>{phoneCompany}</span>
                  <span>{company.email}</span>
                </CompanyInfo>
              </div>
            ))}
          </>
        )}
      </Main>
    </Container>
  );
};

export default DeliveryProtocol;

const Cell = ({ label, value, className, rowSpan, colSpan }) => {
  return (
    <Td rowSpan={rowSpan} colSpan={colSpan}>
      <div className={className}>
        {label && <span>{label}</span>}
        <strong>{value}</strong>
      </div>
    </Td>
  );
};

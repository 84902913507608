import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  margin: 0;
  padding: 0;
  background-color: #eee;
`;

export const Header = styled.header`
  width: calc(100% - 10px);
  margin: 5px;
  height: 40px;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${({ theme }) => theme.colors.blue};

  font-size: 1rem;
  color: #fff;
  border-radius: 5px;

  aside {
    display: flex;
    flex-direction: row;
  }

  button {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    background: transparent;
    height: 100%;
    font-size: 1rem;
    color: #fff;

    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }

    svg {
      margin-left: 10px;
    }
  }

  @media (max-width: 650px) {
    aside span {
      display: none;
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
    font-size: 1rem;
    margin-left: 8px;
  }

  img {
    border: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }

  @media (max-width: 650px) {
    .client-name {
      display: none;
    }
  }
`;

export const Content = styled.main`
  height: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  margin: 35px 8vw;
  max-width: 1500px;

  @media (max-width: 650px) {
    margin: 0px 5vw;
    height: calc(100% - 150px);
    min-height: 500px;
  }

  @media (min-width: 1800px) {
    margin: 35px auto;
  }
`;

export const Footer = styled.footer`
  width: calc(100% - 10px);
  margin: 5px;
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  background: ${({ theme }) => theme.colors.blue};

  font-size: 1rem;
  color: #fff;
  border-radius: 5px;

  div {
    margin: 10px;
    display: flex;
    align-items: center;

    span {
      margin-left: 8px;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    height: 90px;

    div {
      margin: 5px 0;
    }
  }
`;

export const Error = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 2rem;
  font-weight: bold;
  color: #e53935;
`;

import styled from 'styled-components';
import { Form } from '@unform/web';

export const FormContainer = styled(Form)`
  ::-webkit-scrollbar-thumb {
    background: #bbbbbb;
  }
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: calc(100% - 105px);
  overflow: auto;

  section {
    display: flex;
    flex-wrap: wrap;
  }

  h4 {
    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 16px;
  }

  .hide {
    display: none;
  }

  .question {
    width: 100%;
    margin: 0 0 20px 24px;
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 10px;
  height: 60px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.blue};

  button {
    background: transparent;
    color: ${({ theme }) => theme.colors.error};
    border: 0;
    height: 20px;
    margin: 0 20px 16px 0;

    svg {
      margin-left: 10px;
    }

    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }

  section + & {
    margin-top: 30px;
  }

  h4 {
    height: 20px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;

  background: ${({ theme }) => theme.colors.success};
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  transition: background 0.3s;

  &:hover {
    background: ${({ theme }) => theme.hover.success};
  }

  svg {
    margin-left: 10px;
  }

  &:disabled {
    cursor: no-drop;
  }
`;

export const InputInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  svg {
    margin-right: 10px;
  }
  > div {
    width: 100%;
  }
`;

export const UploadFile = styled.div`
  width: calc(50% - 26px);
  display: flex;
  flex-direction: column;
  margin: 10px;

  .dont-have {
    width: 100%;
    margin-top: 5px;

    label {
      color: #e53935;
      font-weight: bold;
    }

    input {
      accent-color: #e53935;
      width: 30px;
      margin-right: 10px;
    }
  }

  .file-input {
    display: flex;
    align-items: center;
    width: 100%;

    > div:first-child {
      margin-right: 10px;
    }
  }

  .file-name {
    width: 100%;

    label {
      opacity: 1;
      font-size: 15px;
    }

    input::placeholder {
      font-size: 12px;
    }
  }

  @media (max-width: 650px) {
    width: calc(100%);
  }
`;

export const FileName = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    opacity: 1;
    font-size: 15px;
  }

  input {
    font-size: 12px;
  }
`;

export const File = styled.div`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    opacity: 1;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
      cursor: pointer;
    }
  }
`;

export const Delete = styled.div`
  height: 40px;
  padding-top: 20px;
  margin-right: 10px;
  width: 30px;

  display: flex;
  justify-content: center;

  button {
    color: #e53935;
  }
`;

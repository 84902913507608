import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      margin-top: 8px;
    }

    > div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }
  }

  @media print {
    & {
      display: none;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }

    a {
      margin-left: 10px;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin: 0 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div + div {
    margin-left: 16px;
  }

  .cod {
    width: 10%;
  }

  .client,
  .user,
  .situation {
    width: 20%;
  }

  .period {
    width: 25%;
    min-width: 215px;
  }

  > button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }

    span {
      color: #545454;
      font-size: 12px;
      opacity: 0.7;
    }
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      height: 30px;
      background: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      text-align: center;
    }

    span {
      margin: 0 10px;
    }

    .react-datepicker-wrapper .react-datepicker__input-container input {
      width: 100%;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .thumb,
    .created-in {
      padding: 0 8px;
    }

    .method {
      padding: 0 8px;

      .hover {
        opacity: 1;
        transition: opacity 0.3s;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .cod {
      width: 80px;
    }

    .start_date,
    .delivered_date {
      width: 11%;
      min-width: 120px;
    }

    .recipient,
    .situation,
    .user {
      width: 15%;
      max-width: 0;
    }

    .client {
      width: 20%;
      max-width: 0;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ProtocolInfo = styled.div`
  section {
    > div + div,
    form + div,
    div + form {
      margin-left: 16px;
    }
  }

  h4 {
    padding-top: 16px;

    &:first-child {
      padding-top: 0;
    }
  }

  form {
    color: ${({ theme }) => theme.colors.primary};
  }

  .cod,
  .date,
  .situation,
  .method,
  .file,
  .cancel {
    width: calc(20% - 16px);
  }

  .obs {
    width: 100%;
  }

  .document-obs {
    width: 80%;
  }

  .client {
    width: 60%;
  }

  .user,
  .email,
  .date_hour {
    width: 50%;
  }

  .deadline,
  .price,
  .comp {
    width: calc(15% - 16px);
  }

  .substitute {
    width: calc(20% - 48px);
  }

  .document {
    width: 35%;
  }

  .new-viewer {
    width: calc(50% - 66px);
  }

  .edit,
  .delete {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 30px;
    color: ${({ theme }) => theme.colors.primary};

    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  .delete {
    color: #e53935;
  }

  .create {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0px;

    h4 {
      padding-top: 16px;
    }

    button {
      margin-left: 8px;
      background: none;
      color: ${({ theme }) => theme.colors.blue};
      border: 1px solid ${({ theme }) => theme.colors.blue};
      border-radius: 50%;
      padding: 2px;
      transition: opacity 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .copy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    margin-top: 8px;

    border: 0;
    background: transparent;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 16px;

    opacity: 1;
    transition: opacity 0.3s;

    span {
      font-size: 13px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .checkbox {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    width: calc(20% - 48px);

    > input {
      margin-right: 16px;
      transform: scale(1.5);
    }
  }

  .file {
    display: flex;
    flex-direction: row;
    align-items: center;

    > aside {
      display: flex;
      flex-direction: column;

      button {
        background: none;
        color: ${({ theme }) => theme.colors.blue};
        padding: 2px;
        transition: color 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: ${({ theme }) => theme.hover.blue};
        }
      }
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 8px;
    }
  }

  > div + div {
    margin-top: 40px;
  }
`;

export const Td = styled.td``;

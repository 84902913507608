import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { Container, Title, Content } from './styles';

const PopUp = ({ visible, setVisible, body, title, icon }) => {
  return (
    <>
      <Container visible={visible}>
        <Title>
          <nav>
            {icon}
            <strong>{title}</strong>
          </nav>

          <FaTimes
            size={18}
            color="#ee4256"
            onClick={() => {
              setVisible(false);
            }}
          />
        </Title>
        <Content>{body}</Content>
      </Container>
    </>
  );
};

export default PopUp;

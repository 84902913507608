const formatContact = (contactType, contact) => {
  if (contact !== '') {
    if (contactType === 1) {
      return contact || '';
    }
    if (contactType === 2) {
      return `(${contact.slice(0, 2)}) ${contact.slice(2, 6)}-${contact.slice(
        6,
        10
      )}`;
    }
    if (contactType === 3) {
      return `(${contact.slice(0, 2)}) ${contact.slice(2, 7)}-${contact.slice(
        7,
        11
      )}`;
    }
    if (contactType === 4) {
      return `+${contact.slice(0, 2)} (${contact.slice(2, 4)}) ${contact.slice(
        4,
        9
      )}-${contact.slice(9, 13)}`;
    }
  }
  return '';
};

export default formatContact;

import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FaUserLock, FaTimes } from 'react-icons/fa';

import api from '~/services/api';

import getValidationErrors from '~/util/getValidationErrors';

import useAuth from '~/hooks/useAuth';

import { Input } from '~/components/Form';
import Modal from '~/components/Modal';
import { ButtonSpinner } from '~/components/Spinner';

import { Container, Header, Content } from './styles';

const AutomatedRoutines = ({ isOpen, setIsOpen }) => {
  const { signOut } = useAuth();

  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});

        setLoading(true);

        const schema = Yup.object().shape({
          email: Yup.string().required('E-mail atual obrigatório.'),
          password: Yup.string().required('Senha atual obrigatória.'),
          new_password: Yup.string().required('Nova senha obrigatória.'),
          confirm_new_password: Yup.string()
            .oneOf([Yup.ref('new_password'), null], 'Confirmação incorreta')
            .required('Confirmação obrigatória.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.put('users/reset/password', data);

        toast.success(
          'Senha redefinida com sucesso! Você será deslogado automaticamente. Logue novamente com a nova senha.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        setTimeout(() => {
          signOut();
        }, 5000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);

          return;
        }

        toast.error(
          `${err.response.data.message || 'Falha ao resetar senha.'}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [signOut]
  );

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      widthProp={470}
      heightProp={430}
    >
      <Container>
        <Header>
          <div>
            <FaUserLock size={20} color="#44546a" />
            <h1>Alterar Senha</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>
        <Content ref={formRef} onSubmit={handleSubmit}>
          <Input name="email" label="E-mail" />
          <Input name="password" type="password" label="Senha atual" />
          <Input name="new_password" type="password" label="Nova senha" />
          <Input
            name="confirm_new_password"
            type="password"
            label="Confirme a nova senha"
          />

          <button type="submit">
            {loading ? <ButtonSpinner size={17} /> : 'Alterar senha'}
          </button>
        </Content>
      </Container>
    </Modal>
  );
};

export default AutomatedRoutines;

AutomatedRoutines.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1000;

  width: 40px;
  height: calc(100% - 60px);

  background: ${({ theme }) => theme.colors.white};
  margin: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border};

  transition: width 0.3s;

  span {
    display: flex;
    width: 0px;
    font-size: 0px;
    transition: width 0.3s, font-size 0.3s;
    margin-left: 5px;
  }

  &:hover {
    width: 200px;

    span {
      width: 160px;
      display: flex;
      font-size: 14px;
    }
  }

  button {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 40px;
    width: 100%;

    background: ${({ theme }) => theme.colors.greyBackground};
    color: ${({ theme }) => theme.colors.primary};
    padding-left: 15px;

    transition: background 0.3s;

    &:hover {
      background: ${({ theme }) => theme.colors.white};
      box-shadow: rgb(9 74 178) 3px 0px 0px inset;
    }

    span {
      text-align: start;
    }
  }

  .active {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: rgb(9 74 178) 3px 0px 0px inset;
  }
`;

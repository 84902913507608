import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  padding: 10px 10px 10px 10px;
  height: 100%;
  width: 50%;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.primary};
  padding: 7px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
    }
  }
`;

export const Line = styled.div`
  height: 90%;
  width: 0px;
  border: 0;
  border-left: 2px solid #c7c7c7;
  margin-top: 2.5%;
`;

export const WaitPage = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #000;

  h4 {
    color: #01579b;
    margin-bottom: 0;
  }

  p {
    color: #545454;
  }

  .new {
    color: #00c853;
    font-size: 25px;
    background: transparent;
    border: 0;
    transition: color 0.5s;
    cursor: pointer;

    &:hover {
      color: #00a843;
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div + div {
    margin-left: 16px;
  }

  .appointment {
    width: calc(90% - 340px);
  }

  .period {
    width: 215px;
  }

  .conclusion {
    width: 125px;
  }

  > button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      height: 30px;
      background: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      text-align: center;
    }

    span {
      margin: 0 10px;
    }

    .react-datepicker-wrapper .react-datepicker__input-container input {
      width: 100%;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .private,
    .priority,
    .done {
      width: 4vw;
    }

    .start_date,
    .deadline_date {
      width: 10vw;
    }

    .start_hour {
      width: 8vw;
    }

    .description {
      width: 60%;
      max-width: 0;
    }
  }
`;

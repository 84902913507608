import React from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import { usePortalAuth } from '~/hooks';

import { Container } from './styles';

const Header = () => {
  const { user, company, signOut } = usePortalAuth();

  return (
    <Container>
      <div className="logo">
        <div className="img">
          {company && company.logo_url && (
            <img src={company.logo_url} alt="logo" />
          )}
        </div>

        <span>{company ? company.name : ''}</span>
      </div>
      <div className="user">
        <span>{user.name ?? ''}</span>
      </div>
      <div className="logout">
        <button type="button" onClick={signOut}>
          <span>Sair</span>
          <FaSignOutAlt />
        </button>
      </div>
    </Container>
  );
};

export default Header;

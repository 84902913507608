import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }

    a {
      margin-left: 10px;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  nav {
    display: flex;
    align-items: center;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
      border: 0;
      transition: opacity 0.2s;

      & + button {
        margin-left: 16px;
      }

      &:hover {
        opacity: 0.7;
      }

      svg {
        font-size: 15px;
        margin-bottom: 3px;
        color: ${({ theme }) => theme.colors.primary};
      }

      span {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  aside {
    display: flex;
    align-items: center;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
      border: 0;
      transition: opacity 0.2s;

      & + button {
        margin-left: 16px;
      }

      &:hover {
        opacity: 0.7;
      }

      svg {
        font-size: 15px;
        margin-bottom: 3px;
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    span {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary};
      margin: 0 16px;
    }

    .feedbacks {
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      color: ${({ theme }) => theme.colors.primary};
      font-size: 16px;
      cursor: pointer;

      div {
        width: 10px;
        height: 10px;
        border: 5px solid #f4c306;
        border-radius: 50%;

        position: absolute;
        margin: -10px 0 0 80px;
      }
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  h1 {
    margin-top: 1rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.blue};
  }

  h4 {
    color: ${({ theme }) => theme.colors.blue};
    margin: 20px 0 8px 16px;

    &:nth-child(2) {
      margin-top: 20px;
    }
  }

  table {
    color: #545454;
    margin: 0 20px;

    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    td,
    th {
      padding: 5px 15px 5px 15px;
    }

    td {
      cursor: default;
    }

    .view {
      cursor: pointer;
      padding: 0;
      button {
        padding: 5px 15px;
        background: transparent;
      }
    }

    .schedule {
      text-align: center;
      width: 18%;
    }

    .scheduleView {
      display: flex;
      flex-direction: row-reverse;

      span {
        text-align: right;
        margin-right: 5px;
      }

      button {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        margin-left: 5px;
        background: transparent;
      }
    }

    .user {
      width: 20%;
    }

    .description {
      width: 60%;
      max-width: 0;
    }

    .note {
      width: 10%;
      text-align: center;
    }
  }
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div + div {
    margin-left: 16px;
  }

  .user {
    width: 25%;
  }

  > button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const LineStyle = styled.div`
  height: 0px;
  border: 0;
  border-bottom: 1px solid #bbb;
  margin: 0 20px;
`;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import { FaTimes, FaEdit, FaSave, FaBuilding } from 'react-icons/fa';

import api from '~/services/api';

import formatContact from '~/util/formatContact';

import { FormLoading, Input, InputMask } from '~/components/Form';
import Modal from '~/components/Modal';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';

import { Header, FormContainer, DetailsContainer } from '~/styles/components';

import { Container, Controls, Content, LogoInput } from './styles';
import { useAuth } from '~/hooks';

const CompanieData = ({ isOpen, setIsOpen }) => {
  const { company } = useAuth();

  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [companyData, setCompanyData] = useState(null);
  const [companyDataForm, setCompanyDataForm] = useState(null);
  const [uploadLogo, setUploadLogo] = useState(null);

  useEffect(() => {
    async function formatCompanyData() {
      if (company) {
        try {
          setLoading(true);

          const response = await api.get(`companies/${company.id}`);

          const { data } = response;

          const formattedCompanyData = {
            name: data.name || '',
            email: data.email || '',
            logo: data.logo || '',
            logo_url: data.logo_url ? data.logo_url : null,
            phone: formatContact(3, data.phone || ''),
            whatsapp_phone: formatContact(4, data.whatsapp_phone || ''),
          };

          if (!isEditing) {
            setCompanyData(formattedCompanyData);
          } else {
            setCompanyDataForm(formattedCompanyData);
          }
        } catch (err) {
          toast.error('Falha ao carregar dados', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } finally {
          setLoading(false);
        }
      }
    }
    formatCompanyData();
  }, [isEditing, company]);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            name: Yup.string().required('O nome é obrigatório.'),
            email: Yup.string().required('O e-mail é obrigatório.'),
            phone: Yup.string().required('O telefone é obrigatório.'),
            whatsapp_phone: Yup.string(),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          data.phone = data.phone.replace(/[^0-9]+/g, '');
          data.whatsapp_phone = data.whatsapp_phone.replace(/[^0-9]+/g, '');

          if (uploadLogo) {
            const formData = new FormData();

            formData.append('file', uploadLogo);

            const fileResponse = await api.post('files/upload', formData, {
              params: {
                prefix: 'Company_logo',
              },
            });

            const { blobName } = fileResponse.data;

            data.logo = blobName;
          }

          const { name, email, phone, whatsapp_phone, logo } = data;

          await api.put(`/companies/${company.id}`, {
            name,
            email,
            phone,
            whatsapp_phone,
            logo,
          });

          toast.success('Dados salvos com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);
          setIsEditing(false);
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error(
              `${err.response.data.message || 'Falha ao salvar dados.'}`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          }

          setSaveLoading(false);
        }
      }
    },
    [company, uploadLogo]
  );

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => setIsEditing(false)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleUpdateLogo = useCallback(logo => {
    setUploadLogo(logo);

    const logoUrl = URL.createObjectURL(logo);

    setCompanyDataForm(oldCompanyDataForm => ({
      ...oldCompanyDataForm,
      logo: logo.name,
      logo_url: logoUrl,
    }));
  }, []);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaBuilding size={20} color="#44546a" />
            <h1>Dados da Empresa</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>
        <Controls>
          {!isEditing ? (
            <button type="button" onClick={handleEdit}>
              <FaEdit size={15} color="#44546a" />
              <span>Editar</span>
            </button>
          ) : (
            <>
              <button
                type="button"
                onClick={() => formRef.current.submitForm()}
              >
                <FaSave size={15} color="#44546a" />
                <span>Salvar</span>
              </button>
              <button type="button" onClick={handleClose}>
                <FaTimes size={15} color="#44546a" />
                <span>Fechar</span>
              </button>
            </>
          )}
        </Controls>
        <Content className="content">
          {!isEditing ? (
            <>
              {loading ? (
                <FormLoading />
              ) : (
                <>
                  {companyData && (
                    <DetailsContainer>
                      <section>
                        <LogoInput>
                          {companyData.logo ? (
                            <img
                              src={companyData.logo_url}
                              alt={companyData.name}
                            />
                          ) : (
                            <img
                              src={`https://avatar.oxro.io/avatar?name=${companyData.name}&background=c7c7c7`}
                              alt={companyData.name}
                            />
                          )}
                        </LogoInput>
                      </section>
                      <section>
                        <div className="name">
                          <label>Nome</label>
                          <input
                            name="name"
                            value={companyData.name}
                            readOnly
                          />
                        </div>
                        <div className="email">
                          <label>E-mail</label>
                          <input
                            name="email"
                            value={companyData.email}
                            readOnly
                          />
                        </div>
                      </section>
                      <section>
                        <div className="phone">
                          <label>Telefone</label>
                          <input
                            name="phone"
                            value={companyData.phone}
                            readOnly
                          />
                        </div>
                        <div className="phone">
                          <label>WhatsApp</label>
                          <input
                            name="whatsapp_phone"
                            value={companyData.whatsapp_phone}
                            readOnly
                          />
                        </div>
                      </section>
                    </DetailsContainer>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <FormLoading />
              ) : (
                <>
                  {companyDataForm !== null && (
                    <FormContainer
                      ref={formRef}
                      onSubmit={handleSubmit}
                      initialData={companyDataForm}
                    >
                      <section>
                        <LogoInput>
                          {companyDataForm.logo ? (
                            <img
                              src={companyDataForm.logo_url}
                              alt={companyDataForm.name}
                            />
                          ) : (
                            <img
                              src={`https://avatar.oxro.io/avatar?name=${companyDataForm.name}&background=c7c7c7`}
                              alt={companyDataForm.name}
                            />
                          )}
                          <label htmlFor="logo">Alterar imagem</label>
                          <input
                            id="logo"
                            type="file"
                            accept="image/*"
                            name="logo"
                            onChange={e => handleUpdateLogo(e.target.files[0])}
                          />
                        </LogoInput>
                      </section>
                      <section>
                        <Input name="name" className="name" label="Nome" />
                        <Input name="email" className="email" label="E-mail" />
                      </section>
                      <section>
                        <InputMask
                          name="phone"
                          className="phone"
                          label="Telefone"
                          mask="(99) 99999-9999"
                        />
                        <InputMask
                          name="whatsapp_phone"
                          className="phone"
                          label="WhatsApp"
                          mask="+55 (99) 99999-9999"
                        />
                      </section>
                    </FormContainer>
                  )}
                </>
              )}
            </>
          )}
        </Content>
      </Container>
      {saveLoading && <Loading />}
    </Modal>
  );
};

export default CompanieData;

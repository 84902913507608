import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    .description {
      width: 100vw;
      padding-left: 8px;
    }
  }
`;

export const ModelInfo = styled.div`
  section {
    div + div {
      margin-left: 16px;
    }
  }

  .description {
    width: 100vw;
  }
`;

export const Documents = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .document {
    width: 90vw;
  }

  .abbrev {
    width: 10vw;
  }
`;

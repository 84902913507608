import React from 'react';
import { FaFrown, FaTimes } from 'react-icons/fa';

import ClearBackground from '../../../../components/ClearBackground';

import { Container, Content, Left, Right, Header } from './styles';

const LimitClientsModal = ({ setIsOpen, onClick, goCreate, type }) => {
  return (
    <>
      <ClearBackground />
      <Container>
        <Content>
          <Left>
            <FaFrown size={38} color="#01579b" />
          </Left>
          <Right>
            <Header>
              <strong>Atenção</strong>
              <button type="button" onClick={setIsOpen}>
                <FaTimes />
              </button>
            </Header>

            {type === 0 && (
              <>
                <strong>
                  Você chegou ao limite de clientes avulsos do seu plano, mas
                  você ainda pode cadastrar clientes regulares.
                </strong>
                <span>
                  Caso queira adicionar mais clientes ou que você esteja com
                  algum problema, faça uma solicitação de suporte.
                </span>

                <div>
                  <button type="button" onClick={goCreate}>
                    Criar regular
                  </button>
                  <button type="button" onClick={onClick}>
                    Suporte
                  </button>
                </div>
              </>
            )}

            {type === 1 && (
              <>
                <strong>
                  Você chegou ao limite de clientes regulares do seu plano, mas
                  você ainda pode cadastrar clientes avulsos.
                </strong>
                <span>
                  Caso queira adicionar mais clientes ou que você esteja com
                  algum problema, faça uma solicitação de suporte.
                </span>

                <div>
                  <button type="button" onClick={goCreate}>
                    Criar avulso
                  </button>
                  <button type="button" onClick={onClick}>
                    Suporte
                  </button>
                </div>
              </>
            )}

            {type === 2 && (
              <>
                <strong>Você chegou ao limite de clientes do seu plano.</strong>
                <span>
                  Caso queira adicionar mais clientes ou que você esteja com
                  algum problema, faça uma solicitação de suporte.
                </span>

                <button type="button" onClick={onClick}>
                  Suporte
                </button>
              </>
            )}
          </Right>
        </Content>
      </Container>
    </>
  );
};

export default LimitClientsModal;

import React from 'react';

import { Container, Client } from './styles';

const Report = ({ clients }) => {
  return (
    <Container>
      {clients.map((item, index) => (
        <Client key={index}>
          <h4>
            {item.name} - {item.quant_employee} trabalhador(es)
          </h4>
          {item.employees.map((employeeItem, employeeIndex) => (
            <span key={employeeIndex}>
              {employeeItem.name} - {employeeItem.admission_date} (
              {employeeItem.situation})
            </span>
          ))}
        </Client>
      ))}
    </Container>
  );
};

export default Report;

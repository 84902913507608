import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaDollarSign,
  FaTimes,
  FaEye,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaEraser,
  FaCheck,
  FaPlus,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import { Input, Select } from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import ClearBackground from '~/components/ClearBackground';
import Pagination from '~/components/Pagination';
import { Spinner } from '~/components/Spinner';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  Details,
  Periods,
  Loading,
} from './styles';
import PermissionComponent from '~/components/PermissionComponent';

const List = () => {
  const { company } = useAuth();

  const filterRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState({});
  const [profileItemIndex, setProfileItemIndex] = useState(0);
  const [totalProfiles, setTotalProfiles] = useState(0);

  const [companySearch, setCompanySearch] = useState('');
  const [selectedContractType, setSelectedContractType] = useState({
    value: '',
    label: 'Todos',
  });

  useEffect(() => {
    async function loadProfiles() {
      if (company) {
        try {
          setLoading(true);

          const response = await api.get('tributary-profiles/', {
            params: {
              page: currentPage,
              company_id: company.id,
              company_name: companySearch,
              contract_type: selectedContractType.value,
            },
          });

          if (response.data.docs.length > 0) {
            const formattedProfiles = response.data.docs.map(profileItem => ({
              ...profileItem,
              periods:
                profileItem.periods &&
                profileItem.periods.map(period => ({
                  ...period,
                  start_period: format(
                    parseISO(period.start_period),
                    'dd/MM/yyyy'
                  ),
                  end_period: period.end_period
                    ? format(parseISO(period.end_period), 'dd/MM/yyyy')
                    : null,
                })),
            }));

            setProfiles(formattedProfiles);
            setProfile(formattedProfiles[0]);
            setTotalPages(response.data.pages);
            setTotalProfiles(response.data.total);
          } else {
            setProfiles([]);
            setProfile([]);
            setTotalPages(1);
            setTotalProfiles(0);
            toast.warn('Nenhum perfil tributário foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setLoading(false);
        } catch (err) {
          toast.error('Falha ao buscar perfis tributários.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      }
    }
    loadProfiles();
  }, [company, companySearch, selectedContractType.value, currentPage]);

  const contractTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Terceirizado' },
      { value: 2, label: 'Próprio' },
    ];
  }, []);

  const alterView = useCallback(() => {
    setShowTable(!showTable);
  }, [showTable]);

  const handleFilter = useCallback(
    ({ company_name, contract_type }) => {
      setCompanySearch(company_name);
      setSelectedContractType(
        contractTypeOptions.find(type => type.value === contract_type)
      );
      setCurrentPage(1);
    },
    [contractTypeOptions]
  );

  const resetFilter = useCallback(() => {
    setCurrentPage(1);

    filterRef.current.reset();

    filterRef.current.clearField('company_name');
    setCompanySearch('');

    filterRef.current.setFieldValue('contract_type', {
      value: '',
      label: 'Todos',
    });
    setSelectedContractType({ value: '', label: 'Todos' });
  }, [filterRef]);

  const getDetails = useCallback(
    (id, index) => {
      alterView();
      const item = profiles.find(profileItem => profileItem.id === id);

      setProfile(item);
      setProfileItemIndex(index);
    },
    [profiles, alterView]
  );

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  const handlePrevItem = useCallback(() => {
    if (profileItemIndex !== 0) {
      setProfileItemIndex(profileItemIndex - 1);
      setProfile(profiles[profileItemIndex - 1]);
    } else {
      setProfileItemIndex(profiles.length - 1);
      setProfile(profiles[profiles.length - 1]);
    }
  }, [profileItemIndex, profiles]);

  const handleNextItem = useCallback(() => {
    if (profileItemIndex !== profiles.length - 1) {
      setProfileItemIndex(profileItemIndex + 1);
      setProfile(profiles[profileItemIndex + 1]);
    } else {
      setProfileItemIndex(0);
      setProfile(profiles[0]);
    }
  }, [profileItemIndex, profiles]);

  const handleDelete = useCallback(
    async id => {
      try {
        setDeleteLoading(true);

        await api.delete(`tributary-profiles/${id}`);

        alterView();

        toast.success('Perfil tributário deletado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      } catch (err) {
        toast.error('Falha ao deletar perfil tributário.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      }
    },
    [alterView]
  );

  const confirmDelete = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => handleDelete(profile.id)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete, profile.id]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaDollarSign size={20} color="#44546a" />
            <h1>Perfil Tributário</h1>
          </div>

          <Link to="/">
            <FaTimes size={20} color="#44546a" />
          </Link>
        </Header>

        <Controls>
          {profiles.length > 0 ? (
            <button type="button" onClick={alterView}>
              <FaEye />
              <span>Visualização</span>
            </button>
          ) : (
            <button type="button" onClick={alterView} disabled>
              <FaEye />
              <span>Visualização</span>
            </button>
          )}

          <PermissionComponent level={5}>
            <Link
              to={{ pathname: '/tributary-profile/new', state: { id: null } }}
            >
              <FaPlus />
              <span>Novo</span>
            </Link>
          </PermissionComponent>
          {showTable ? (
            <button type="button" onClick={resetFilter}>
              <FaEraser />
              <span>Limpar filtros</span>
            </button>
          ) : (
            <>
              <PermissionComponent level={5}>
                <Link
                  to={{
                    pathname: '/tributary-profile/edit',
                    state: { id: profile.id },
                  }}
                >
                  <FaEdit />
                  <span>Editar</span>
                </Link>
              </PermissionComponent>
              <PermissionComponent level={9}>
                <button type="button" onClick={confirmDelete}>
                  <FaTrash />
                  <span>Excluir</span>
                </button>
              </PermissionComponent>
              <div>
                <button type="button" onClick={handlePrevItem}>
                  <FaChevronLeft />
                </button>
                {totalProfiles > 25 ? (
                  <span>{profileItemIndex + 1} de 25</span>
                ) : (
                  <span>
                    {profileItemIndex + 1} de {totalProfiles}
                  </span>
                )}
                <button type="button" onClick={handleNextItem}>
                  <FaChevronRight />
                </button>
              </div>
            </>
          )}
        </Controls>

        {showTable && (
          <Filter ref={filterRef} onSubmit={handleFilter}>
            <Input
              name="company_name"
              className="company_filter"
              label="Empresa"
            />

            <Select
              label="Tipo de Contrato"
              name="contract_type"
              className="contract_type_filter"
              options={contractTypeOptions}
              defaultValue={selectedContractType}
            />

            <button type="submit">
              <FaSearch />
            </button>
          </Filter>
        )}

        {loading || !company ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {showTable ? (
              <TableContainer>
                <thead>
                  <tr>
                    <th className="company">Empresa</th>
                    <th className="contract_type">Tipo de Contrato</th>
                    <th className="status">Ativo</th>
                  </tr>
                </thead>
                <tbody>
                  {profiles &&
                    profiles.map((profileItem, index) => (
                      <tr
                        key={profileItem.id}
                        className="hover"
                        onClick={() => getDetails(profileItem.id, index)}
                      >
                        <td className="company">{profileItem.client.name}</td>
                        <td className="contract_type">
                          {profileItem.contract_type_label}
                        </td>
                        <td className="status">
                          {profileItem.situation && (
                            <FaCheck size={12} color="#707070" />
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </TableContainer>
            ) : (
              <DetailsContainer>
                <Details>
                  <h4>EMPRESA</h4>
                  <section>
                    <div className="company">
                      <label>Empresa</label>
                      <input
                        name="client"
                        value={profile.client.name || ''}
                        readOnly
                      />
                    </div>
                    <div className="contract_type">
                      <label>Tipo de Contrato</label>
                      <input
                        name="contract_type"
                        value={profile.contract_type_label || ''}
                        readOnly
                      />
                    </div>
                    <div className="status">
                      <label>Ativo</label>
                      <input
                        name="status"
                        checked={profile.situation || ''}
                        type="checkbox"
                        disabled
                      />
                    </div>
                  </section>
                </Details>
                {profile.periods?.length > 0 && (
                  <Periods>
                    <h4>PERÍODOS</h4>
                    {profile.periods.map(period => (
                      <section key={period.id}>
                        <div className="taxation_type">
                          <label>Tributação</label>
                          <input
                            name="taxation_type"
                            value={period.taxation_type_label || ''}
                            readOnly
                          />
                        </div>
                        <div className="start_period">
                          <label>Início</label>
                          <input
                            name="start_period"
                            value={period.start_period || ''}
                            readOnly
                          />
                        </div>
                        <div className="end_period">
                          <label>Fim</label>
                          <input
                            name="end_period"
                            value={period.end_period || ''}
                            readOnly
                          />
                        </div>
                      </section>
                    ))}
                  </Periods>
                )}
              </DetailsContainer>
            )}
          </Content>
        )}
        <Pagination
          loading={loading ? 1 : 0}
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalProfiles}
          handlePage={handlePage}
        />
      </Container>
      {deleteLoading && (
        <>
          <Loading>
            <Spinner />
          </Loading>
          <ClearBackground />
        </>
      )}
    </>
  );
};

export default List;

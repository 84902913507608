import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  format,
  getYear,
  getMonth,
  getDate,
  setMinutes,
  setHours,
  parseISO,
  isToday,
  addDays,
} from 'date-fns';
import TextareaAutosize from 'react-autosize-textarea';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import {
  FaSave,
  FaBroom,
  FaPaperclip,
  FaLocationArrow,
  FaMinus,
  FaTimes,
  FaEye,
  FaDownload,
  FaTrash,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import { FormContainer, FileInput, TextArea } from '~/components/Form';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';
import { TableLoading } from '~/components/Table';

import {
  Container,
  Controls,
  Content,
  AppointmentInfo,
  Feedback,
  SendFeedback,
  UploadFile,
  File,
  FeedbackItem,
  ReadOnly,
  Header,
  FeedbackItemForm,
  Submit,
} from './styles';

const Form = ({ type, close, refresh, id }) => {
  const { user } = useAuth();

  const formRef = useRef(null);
  const feedbackRef = useRef(null);

  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [deleteFeedbackLoading, setDeleteFeedbackLoading] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [appointment, setAppointment] = useState(null);

  const [listOfFeedback, setListOfFeedback] = useState([]);

  const loadSchedule = useCallback(async () => {
    try {
      if (id) {
        setAppointmentsLoading(true);

        const response = await api.get(`schedule/${id}`);

        const { data } = response;

        const { start_date } = data;

        data.start_date = format(parseISO(start_date), 'dd/MM/yyyy');
        data.start_hour = format(parseISO(start_date), 'HH:mm');

        const feedbackFormatted = data.feedback.map(feedbackItem => ({
          ...feedbackItem,
          feedback_date_formatted: feedbackItem.feedback_date
            ? format(
                parseISO(feedbackItem.feedback_date),
                "dd/MM/yyyy 'às' HH:mm"
              )
            : null,
        }));

        data.feedback = feedbackFormatted;

        setAppointment(data);
      } else {
        setAppointmentsLoading(true);

        setAppointment({
          start_date: format(new Date(), 'dd/MM/yyyy'),
          start_hour: format(new Date(), 'HH:mm'),
        });
      }

      setAppointmentsLoading(false);
    } catch (err) {
      toast.error('Falha ao buscar problema relatado.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      close();
    }
  }, [id, close]);

  useEffect(() => {
    loadSchedule();
  }, [loadSchedule]);

  const handleSubmit = useCallback(
    async data => {
      if (user) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            description: Yup.string().required('A descrição é obrigatória.'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          data.start_hour = format(new Date(), 'HH:mm');
          data.start_date = new Date();
          data.priority = 1;
          data.recipient_id = 1;
          data.company_id = 3;
          data.type = 5;
          data.conclusion_date = null;
          data.deadline_date = null;
          data.private = true;

          data.done = false;

          const startHour = data.start_hour.split(':');

          data.start_date = setMinutes(
            setHours(
              new Date(
                getYear(data.start_date),
                getMonth(data.start_date),
                getDate(data.start_date)
              ),
              startHour[0]
            ),
            startHour[1]
          );

          data.deadline_date = setMinutes(
            setHours(addDays(data.start_date, 3), startHour[0]),
            startHour[1]
          );

          const createdSchedule = await api.post(`schedule`, data);

          const { id: createdId } = createdSchedule.data;

          if (listOfFeedback.length > 0) {
            const promises = listOfFeedback.map(async feedback => {
              const feedbackData = {
                company_id: 3,
                schedule_id: createdId,
                user_id: user.id,
                content: feedback.content,
                type: feedback.type,
                file_name: feedback.file_name,
              };

              if (feedback.type === 1) {
                const formData = new FormData();

                formData.append('file', feedback.file);

                const fileResponse = await api.post('files/upload', formData, {
                  params: {
                    prefix: 'Schedule_Feedback',
                  },
                });

                const { blobName } = fileResponse.data;

                feedbackData.link = blobName;

                await api.post('schedule/feedback', feedbackData, {
                  params: {
                    isCreatingNewSchedule: true,
                  },
                });
              } else {
                await api.post('schedule/feedback', feedbackData, {
                  params: {
                    isCreatingNewSchedule: true,
                  },
                });
              }
            });

            await Promise.all(promises);
          }

          formRef.current.setErrors({});

          toast.success('Problema relatado com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);

          refresh();
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar relato.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setSaveLoading(false);
        }
      }
    },
    [listOfFeedback, user, refresh]
  );

  const handleFeedback = useCallback(
    async data => {
      if (user) {
        try {
          const schema = Yup.object().shape({
            feedback_message: Yup.string().when('file', {
              is: value => value !== undefined,
              then: Yup.string(),
              otherwise: Yup.string().required('A mensagem é obrigatória'),
            }),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setListOfFeedback(
            produce(listOfFeedback, draft => {
              const newFeedback = {
                id: uuid(),
                feedback_date_formatted: format(
                  new Date(),
                  "dd/MM/yyyy 'às' HH:mm"
                ),
                short_name: user.short_name,
                type: data.file ? 1 : 0,
                file: data.file || null,
                content:
                  data.file && data.feedback_message === ''
                    ? data.file.name
                    : data.feedback_message,
                file_name: data.file ? data.file.name : null,
              };

              if (listOfFeedback.length > 0) {
                draft.unshift(newFeedback);
              } else {
                draft[0] = newFeedback;
              }
            })
          );

          feedbackRef.current.reset();

          feedbackRef.current.setErrors({});
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            feedbackRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar mensagem.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    },
    [listOfFeedback, user]
  );

  const handleFeedbackView = useCallback(
    async data => {
      if (user) {
        try {
          setFeedbackLoading(true);

          const schema = Yup.object().shape({
            feedback_message: Yup.string().when('file', {
              is: value => value !== undefined,
              then: Yup.string(),
              otherwise: Yup.string().required('A mensagem é obrigatória'),
            }),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const feedbackData = {
            company_id: 3,
            schedule_id: appointment.id,
            user_id: user.id,
            content:
              data.file && data.feedback_message === ''
                ? data.file.name
                : data.feedback_message,
            type: data.file ? 1 : 0,
            file_name: data.file ? data.file.name : null,
          };

          if (data.file) {
            const formData = new FormData();

            formData.append('file', data.file);

            const fileResponse = await api.post('files/upload', formData, {
              params: {
                prefix: 'Schedule_Feedback',
              },
            });

            const { blobName } = fileResponse.data;

            feedbackData.link = blobName;
          }

          await api.post('schedule/feedback', feedbackData);

          const response = await api.get(`schedule/${appointment.id}`);

          const scheduleData = response.data;

          const { start_date } = scheduleData;

          scheduleData.start_date = format(parseISO(start_date), 'dd/MM/yyyy');
          scheduleData.start_hour = format(parseISO(start_date), 'HH:mm');

          const feedbackFormatted = scheduleData.feedback.map(feedbackItem => ({
            ...feedbackItem,
            feedback_date_formatted: format(
              parseISO(feedbackItem.feedback_date),
              "dd/MM/yyyy 'às' HH:mm"
            ),
          }));

          scheduleData.feedback = feedbackFormatted;

          setAppointment(scheduleData);

          feedbackRef.current.clearField('feedback_message');

          feedbackRef.current.setErrors({});

          setFeedbackLoading(false);

          toast.success('Mensagem salva com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            feedbackRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar mensagem.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setFeedbackLoading(false);
        }
      }
    },
    [appointment, user]
  );

  const resetForm = useCallback(() => {
    formRef.current.reset();
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleRemoveFeedback = useCallback(
    index => {
      setListOfFeedback(
        produce(listOfFeedback, draft => {
          delete draft[index];
        })
      );
    },
    [listOfFeedback]
  );

  const handleDeleteFeedback = useCallback(
    async (idFeedback, scheduleId, userId, date) => {
      if (user) {
        if (user.id === userId) {
          if (isToday(parseISO(date))) {
            setDeleteFeedbackLoading(true);
            try {
              await api.delete(`schedule/feedback/${idFeedback}`);

              setAppointment(oldAppointment => ({
                ...oldAppointment,
                feedback: oldAppointment?.feedback?.filter(
                  feedback => feedback.id !== idFeedback
                ),
              }));

              toast.success('Mensagem deletada com sucesso.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } catch {
              toast.error('Falha ao deletar mensagem.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } finally {
              setDeleteFeedbackLoading(false);
            }
          } else {
            toast.warn('Não é possivel deletar mensagens antigas.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          toast.warn('Somente o criador da mensagem pode deletá-lo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [user]
  );

  const confirmRemoveFeedback = useCallback(
    (idFeedback, scheduleId, userId, date) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() =>
                handleDeleteFeedback(idFeedback, scheduleId, userId, date)
              }
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleDeleteFeedback]
  );

  const openFile = useCallback(async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  }, []);

  const downloadFile = useCallback(async (blobName, fileName) => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    fileDownload(response.data, fileName);
  }, []);

  return (
    <Container>
      <Header>
        <div>
          <h1>{type === 1 ? <>Relatar problema</> : <>Vizualizar</>}</h1>
        </div>
        <aside>
          <button type="button" onClick={() => close()}>
            <FaTimes size={20} color="#44546a" />
          </button>
        </aside>
      </Header>
      {!id && (
        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
        </Controls>
      )}
      {appointmentsLoading || !appointment ? (
        <TableLoading />
      ) : (
        <Content className="content">
          <FormContainer ref={formRef} onSubmit={handleSubmit}>
            <AppointmentInfo>
              <h4>{!id ? 'Relatar problema' : 'Vizualizar relato'}</h4>
              <section>
                <ReadOnly>
                  <label>Data do ocorrido</label>
                  <input
                    name="start_date"
                    value={appointment.start_date}
                    readOnly
                  />
                </ReadOnly>
                <ReadOnly>
                  <label>Hora</label>
                  <input
                    name="start_hour"
                    value={appointment.start_hour}
                    readOnly
                  />
                </ReadOnly>
                <ReadOnly>
                  <label>Prioridade</label>
                  <input name="priority" value="Urgente" readOnly />
                </ReadOnly>
              </section>
              <section>
                {!id ? (
                  <TextArea
                    name="description"
                    label="Descrição do problema"
                    className="description"
                  />
                ) : (
                  <TextArea
                    name="description"
                    label="Descrição do problema"
                    className="description"
                    value={appointment.description}
                    readOnly
                  />
                )}
              </section>
            </AppointmentInfo>
          </FormContainer>

          <Feedback
            ref={feedbackRef}
            onSubmit={!id ? handleFeedback : handleFeedbackView}
          >
            <h4>Mensagens</h4>
            {!appointment.done && (
              <SendFeedback>
                <TextArea
                  id="feedback_message"
                  name="feedback_message"
                  className="feedback_message"
                  label="Mensagem (CTRL + ENTER para enviar)"
                  type="text"
                />

                <UploadFile>
                  <File>
                    <label htmlFor="file">
                      <FaPaperclip size={14} color="#FCFCFC" />
                    </label>
                    <FileInput
                      id="file"
                      name="file"
                      onChange={() => {
                        feedbackRef.current.submitForm();
                      }}
                    />
                  </File>
                </UploadFile>

                <button type="submit">
                  <FaLocationArrow size={14} />
                </button>
              </SendFeedback>
            )}

            {!id &&
              listOfFeedback.length > 0 &&
              listOfFeedback.map((feedback, index) => (
                <FeedbackItemForm key={`${feedback.id}${index}`}>
                  <button
                    type="button"
                    onClick={() => handleRemoveFeedback(index)}
                  >
                    <FaMinus size={10} />
                  </button>

                  <div className="date">
                    <label>Data/Hora</label>
                    <input
                      name="feedback_date_formatted"
                      value={feedback.feedback_date_formatted}
                      readOnly
                    />
                  </div>
                  <div className="user">
                    <label>Usuário</label>
                    <input name="user" value={feedback.short_name} readOnly />
                  </div>
                  {feedback.type === 0 ? (
                    <div className="content">
                      <label>Mensagem</label>
                      <textarea
                        name="content"
                        value={feedback.content}
                        readOnly
                      />
                    </div>
                  ) : (
                    <>
                      <div className="content">
                        <label>Mensagem</label>
                        <input
                          name="content"
                          value={feedback.content}
                          readOnly
                        />
                      </div>
                      <div className="file">
                        <label>Arquivo</label>
                        <input
                          type="text"
                          value={feedback.file_name}
                          readOnly
                        />
                      </div>
                    </>
                  )}
                </FeedbackItemForm>
              ))}
            {id &&
              appointment.feedback.length > 0 &&
              appointment.feedback.map(feedback => (
                <FeedbackItem key={feedback.id}>
                  <div className="delete">
                    <button
                      type="button"
                      onClick={() =>
                        confirmRemoveFeedback(
                          feedback.id,
                          feedback.schedule_id,
                          feedback.user.id,
                          feedback.feedback_date
                        )
                      }
                    >
                      <FaTrash size={14} />
                    </button>
                  </div>
                  <div className="date">
                    <label>Data/Hora</label>
                    <input
                      name="feedback_date_formatted"
                      value={feedback.feedback_date_formatted}
                      readOnly
                    />
                  </div>
                  <div className="user">
                    <label>Usuário</label>
                    <input
                      name="feedback_user"
                      value={feedback.user.short_name}
                      readOnly
                    />
                  </div>
                  <div className="content">
                    <label>Mensagem</label>
                    <TextareaAutosize
                      name="content"
                      value={feedback.content}
                      readOnly
                      maxRows={4}
                    />
                  </div>
                  {feedback.type === 1 && (
                    <div className="file">
                      <aside>
                        <button
                          type="button"
                          onClick={() => openFile(feedback.link)}
                          title="Visualizar arquivo"
                        >
                          <FaEye size={16} />
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            downloadFile(feedback.link, feedback.file_name)
                          }
                          title="Baixar arquivo"
                        >
                          <FaDownload size={16} />
                        </button>
                      </aside>
                      <div>
                        <label>Arquivo</label>
                        <input
                          type="text"
                          value={feedback.file_name}
                          readOnly
                        />
                      </div>
                    </div>
                  )}
                </FeedbackItem>
              ))}
            {id && appointment.feedback.length === 0 && appointment.done && (
              <h3>Não foi enviado nenhuma mensagem</h3>
            )}
          </Feedback>

          {!id && (
            <Submit type="button" onClick={() => formRef.current.submitForm()}>
              <span>Solicitar</span>
              <FaLocationArrow size={21} />
            </Submit>
          )}
        </Content>
      )}
      {(saveLoading || deleteFeedbackLoading || feedbackLoading) && <Loading />}
    </Container>
  );
};

export default Form;

import JoditEditor from 'jodit-react';
import React from 'react';
import { FaFile, FaTimes } from 'react-icons/fa';
import Modal from '~/components/Modal';
import { Header } from './styles';

const ShowPopModal = ({ isOpen, setIsOpen, pop }) => {
  const config = {
    readonly: true,
    toolbar: false,
    width: '100%',
    height: 'calc(100% - 35px)',
    globalFullSize: false,
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Header>
        <div>
          <FaFile size={20} color="#44546a" />
          <h1>Procedimento Operacional Padrão</h1>
        </div>
        <aside>
          <button type="button" onClick={setIsOpen}>
            <FaTimes size={20} color="#44546a" />
          </button>
        </aside>
      </Header>
      {pop.google_docs ? (
        <section
          style={{
            color: '#000',
            height: 'calc(110vh - 135px)',
            overflow: 'auto',
          }}
        >
          <iframe
            src={pop.docs_links}
            title="Google Document"
            style={{
              readonly: true,
              toolbar: false,
              width: '100%',
              height: 'calc(100% - 35px)',
              globalFullSize: false,
            }}
          />
        </section>
      ) : (
        <JoditEditor value={pop.description} config={config} tabIndex={0} />
      )}
    </Modal>
  );
};

export default ShowPopModal;

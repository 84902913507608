import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { toast } from 'react-toastify';

import DefaultLayout from '../pages/_layouts/default';

import api from '~/services/api';

import { useAuth } from '../hooks';

const PrivateRoutes = ({ component: Component, level, path, ...rest }) => {
  const {
    signOut,
    createChecklistFirstLogin,
    createLabourChecklistOnDay15,
    isLogged,
    isTokenExpired,
  } = useAuth();

  const [isPermitted, setIsPermitted] = useState(true);

  useEffect(() => {
    async function loadRoles() {
      const response = await api.get('company-users/get/level');

      const userLevel = response.data;

      if (userLevel < level) {
        setIsPermitted(false);
      }
    }

    loadRoles();
  }, [level]);

  if (isTokenExpired()) {
    signOut();
  }

  if (!isLogged()) {
    return <Redirect to="/signin" />;
  }

  if (isLogged() && !level) {
    if (path === '/') {
      return (
        <Route
          {...rest}
          render={props => (
            <DefaultLayout>
              <Component
                {...props}
                createChecklistFirstLogin={createChecklistFirstLogin}
                createLabourChecklistOnDay15={createLabourChecklistOnDay15}
              />
            </DefaultLayout>
          )}
        />
      );
    }

    return (
      <Route
        {...rest}
        render={props => (
          <DefaultLayout>
            <Component {...props} />
          </DefaultLayout>
        )}
      />
    );
  }

  if (isPermitted) {
    return (
      <Route
        {...rest}
        render={props => (
          <DefaultLayout>
            <Component {...props} />
          </DefaultLayout>
        )}
      />
    );
  }

  toast.warn('Você não tem autorização para utilizar este recurso.', {
    position: toast.POSITION.BOTTOM_RIGHT,
  });

  return <Redirect to="/" />;
};

export default PrivateRoutes;

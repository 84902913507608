import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parseISO, format } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaUserFriends,
  FaTimes,
  FaEye,
  FaPlus,
  FaCheck,
  FaEdit,
  FaEraser,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import formatDocument from '~/util/formatDocument';
import formatContact from '~/util/formatContact';

import { TableLoading } from '~/components/Table';
import { Input, Select } from '~/components/Form';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Header,
  Controls,
  DetailsContainer,
  Table,
} from '~/styles/components';
import {
  Filter,
  Content,
  BasicInfo,
  Departments,
  WorkTime,
  WorkTimetable,
  AccessTimetable,
} from './styles';

const List = () => {
  const { company } = useAuth();

  const filterRef = useRef(null);

  const [showTable, setShowTable] = useState(true);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [userIndex, setUserIndex] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const [nameSearch, setNameSearch] = useState('');
  const [selectedSituation, setSelectedSituation] = useState({
    value: 1,
    label: 'Ativo',
  });
  const [selectedLevel, setSelectedLevel] = useState({
    value: '',
    label: 'Todos',
  });

  const loadUsers = useCallback(async () => {
    if (company) {
      try {
        setLoading(true);

        const response = await api.get(`company-users`, {
          params: {
            company_id: company.id,
            name: nameSearch,
            active: selectedSituation.value,
            level: selectedLevel.value,
          },
        });

        if (response.data.length > 0) {
          const filteredUsers = response.data
            .filter(userItem => userItem.user_id !== -1)
            .map(userItem => ({
              ...userItem,
              document: userItem.document
                ? formatDocument(1, userItem.document)
                : '',
              phone: userItem.phone ? formatContact(3, userItem.phone) : '',
              birthday: userItem.birthday
                ? format(parseISO(userItem.birthday), 'dd/MM/yyyy')
                : '',
              access_timetable: userItem.access_timetable
                .map(access_time => ({
                  ...access_time,
                  start: format(parseISO(access_time.start), 'HH:mm'),
                  end: format(parseISO(access_time.end), 'HH:mm'),
                }))
                .sort((a, b) => {
                  if (a.day_number < b.day_number) {
                    return -1;
                  }
                  if (a.day_number > b.day_number) {
                    return 1;
                  }
                  return 0;
                }),
              work_time: userItem.work_time && {
                ...userItem.work_time,
                start: userItem.work_time.start
                  ? format(parseISO(userItem.work_time.start), 'dd/MM/yyyy')
                  : '',
                stop: userItem.work_time.stop
                  ? format(parseISO(userItem.work_time.stop), 'dd/MM/yyyy')
                  : '',
                work_timetable: userItem.work_time.work_timetable
                  .map(time => ({
                    ...time,
                    in_hour: time.in_hour
                      ? format(parseISO(time.in_hour), 'HH:mm')
                      : '',
                    out_hour: time.out_hour
                      ? format(parseISO(time.out_hour), 'HH:mm')
                      : '',
                  }))
                  .sort((a, b) => {
                    if (a.day_number < b.day_number) {
                      return -1;
                    }
                    if (a.day_number > b.day_number) {
                      return 1;
                    }
                    return 0;
                  }),
              },
            }));

          setUsers(filteredUsers);
          setUser(filteredUsers[0]);
          setTotalUsers(filteredUsers.length);
        } else {
          setUsers([]);
          setUser({});
          setTotalUsers(0);
          toast.warn('Nenhum usuário foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch (err) {
        toast.error('Falha ao carregar usuários.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    setLoading(false);
  }, [company, nameSearch, selectedLevel, selectedSituation]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const levelOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 0, label: 'Bloqueado' },
      { value: 1, label: 'Usuário externo' },
      { value: 2, label: 'Usuário padrão' },
      { value: 3, label: 'Usuário Avançado' },
      { value: 4, label: 'Coordenador' },
      { value: 5, label: 'Supervisor' },
      { value: 6, label: 'Gestor' },
      { value: 9, label: 'Administrador' },
    ];
  }, []);

  const situationOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Ativo' },
      { value: 0, label: 'Inativo' },
    ];
  }, []);

  const alterView = useCallback(() => {
    if (users.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, users.length]);

  const getDetails = useCallback(
    (id, index) => {
      const item = users.find(userItem => userItem.id === id);

      setUser(item);
      setUserIndex(index);
      alterView();
    },
    [users, alterView]
  );

  const handleFilter = useCallback(
    ({ name, situation, level }) => {
      setNameSearch(name);

      setSelectedSituation(
        situationOptions.find(option => option.value === situation)
      );

      setSelectedLevel(levelOptions.find(option => option.value === level));
    },
    [levelOptions, situationOptions]
  );

  const resetFilter = useCallback(() => {
    filterRef.current.clearField('name');
    setNameSearch('');

    filterRef.current.setFieldValue('situation', {
      value: '',
      label: 'Todos',
    });
    setSelectedSituation({
      value: '',
      label: 'Todos',
    });

    filterRef.current.setFieldValue('level', {
      value: '',
      label: 'Todos',
    });
    setSelectedLevel({
      value: '',
      label: 'Todos',
    });
  }, []);

  const handleDelete = useCallback(
    async id => {
      try {
        setDeleteLoading(true);

        await api.delete(`company-users/${id}`);

        setUsers(oldClients => oldClients.filter(item => item.id !== id));

        alterView();

        loadUsers();

        toast.success('Usuário deletado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      } catch (err) {
        toast.error('Falha ao deletar usuário.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      }
    },
    [alterView, loadUsers]
  );

  const confirmDelete = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => handleDelete(user.id)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete, user.id]);

  const handlePrevItem = useCallback(() => {
    if (userIndex !== 0) {
      setUserIndex(userIndex - 1);
      setUser(users[userIndex - 1]);
    } else {
      setUserIndex(users.length - 1);
      setUser(users[users.length - 1]);
    }
  }, [userIndex, users]);

  const handleNextItem = useCallback(() => {
    if (userIndex !== users.length - 1) {
      setUserIndex(userIndex + 1);
      setUser(users[userIndex + 1]);
    } else {
      setUserIndex(0);
      setUser(users[0]);
    }
  }, [userIndex, users]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaUserFriends size={20} color="#44546a" />
            <h1>Usuários</h1>
          </div>

          <Link to="/">
            <FaTimes size={20} color="#44546a" />
          </Link>
        </Header>
        <Controls>
          <button type="button" onClick={alterView}>
            <FaEye />
            <span>Visualização</span>
          </button>
          <Link to={{ pathname: '/user/new', state: { id: null } }}>
            <FaPlus />
            <span>Novo</span>
          </Link>
          {showTable ? (
            <button type="button" onClick={resetFilter}>
              <FaEraser />
              <span>Limpar filtros</span>
            </button>
          ) : (
            <>
              <Link to={{ pathname: '/user/edit', state: { id: user.id } }}>
                <FaEdit />
                <span>Editar</span>
              </Link>
              <button type="button" onClick={confirmDelete}>
                <FaTrash />
                <span>Excluir</span>
              </button>
              <div>
                <button type="button" onClick={handlePrevItem}>
                  <FaChevronLeft />
                </button>
                {setTotalUsers > 25 ? (
                  <span>{userIndex + 1} de 25</span>
                ) : (
                  <span>
                    {userIndex + 1} de {totalUsers}
                  </span>
                )}
                <button type="button" onClick={handleNextItem}>
                  <FaChevronRight />
                </button>
              </div>
            </>
          )}
        </Controls>
        {showTable && (
          <Filter ref={filterRef} onSubmit={handleFilter}>
            <Input
              name="name"
              className="name"
              label="Nome"
              defaultValue={nameSearch}
            />
            <Select
              label="Situação"
              name="situation"
              className="situation"
              options={situationOptions}
              defaultValue={selectedSituation}
            />
            <Select
              label="Nível de acesso"
              name="level"
              className="level"
              options={levelOptions}
              defaultValue={selectedLevel}
            />

            <button type="submit">
              <FaSearch />
            </button>
          </Filter>
        )}
        {loading || !company ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {showTable ? (
              <Table>
                <thead>
                  <tr>
                    <th className="user">Usuário</th>
                    <th className="email">E-mail para contato interno</th>
                    <th className="level">Nível</th>
                    <th className="active">Ativo</th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((userItem, index) => (
                    <tr
                      key={userItem.id}
                      className="hover"
                      onClick={() => getDetails(userItem.id, index)}
                    >
                      <td className="user">{userItem?.name}</td>
                      <td className="email">{userItem?.contact_email}</td>
                      <td className="level">{userItem?.level_label}</td>
                      <td className="active">
                        {userItem?.active && (
                          <FaCheck size={12} color="#01579B" />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <DetailsContainer>
                <BasicInfo>
                  <h4>USUÁRIO</h4>
                  <section>
                    <div className="document">
                      <label>Documento</label>
                      <input name="document" value={user?.document} readOnly />
                    </div>
                    <div className="name">
                      <label>Usuário</label>
                      <input name="name" value={user?.name} readOnly />
                    </div>
                    <div className="name">
                      <label>Tratamento</label>
                      <input name="nick" value={user?.nick || ''} readOnly />
                    </div>
                    <div className="email">
                      <label>E-mail para contato interno</label>
                      <input
                        name="contact_email"
                        value={user?.contact_email}
                        readOnly
                      />
                    </div>
                    <div className="birthday">
                      <label>Data de nascimento</label>
                      <input name="birthday" value={user?.birthday} readOnly />
                    </div>
                  </section>
                  <section>
                    <div className="phone">
                      <label>Telefone</label>
                      <input name="phone" value={user?.phone} readOnly />
                    </div>
                    <div className="pin">
                      <label>Pin(Opcional)</label>
                      <input name="pin" value={user?.pin || ''} readOnly />
                    </div>
                    <div className="level">
                      <label>Nível</label>
                      <input name="level" value={user?.level_label} readOnly />
                    </div>
                    <div className="active">
                      <label>KPI</label>
                      <input
                        name="hasKpi"
                        value={user?.hasKpi ? 'Possui' : 'Não possui'}
                        readOnly
                      />
                    </div>
                    <div className="active">
                      <label>Situação</label>
                      <input
                        name="active"
                        value={user?.active ? 'Ativo' : 'Inativo'}
                        readOnly
                      />
                    </div>
                  </section>
                </BasicInfo>
                <Departments>
                  <h4>DEPARTAMENTOS</h4>
                  <section>
                    <div className="dpt_labour">
                      <label>Trabalhista</label>
                      <input
                        name="dpt_labour"
                        checked={!!user?.dpt_labour}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="dpt_tax">
                      <label>Tributário</label>
                      <input
                        name="dpt_tax"
                        checked={!!user?.dpt_tax}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="dpt_accounting">
                      <label>Contábil</label>
                      <input
                        name="dpt_accounting"
                        checked={!!user?.dpt_accounting}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="dpt_financial">
                      <label>Financeiro</label>
                      <input
                        name="dpt_financial"
                        checked={!!user?.dpt_financial}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="dpt_admin">
                      <label>Administração</label>
                      <input
                        name="dpt_admin"
                        checked={!!user?.dpt_admin}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="dpt_auxiliar">
                      <label>Auxiliar</label>
                      <input
                        name="dpt_auxiliar"
                        checked={!!user?.dpt_auxiliar}
                        type="checkbox"
                        disabled
                      />
                    </div>
                  </section>
                </Departments>
                {user?.work_time && (
                  <>
                    <WorkTime>
                      <h4>HORÁRIOS DE TRABALHO</h4>
                      <section>
                        <div className="work_time_description">
                          <label>Descrição</label>
                          <input
                            name="work_time_description"
                            value={user?.work_time?.description || ''}
                            readOnly
                          />
                        </div>
                        <div className="work_time_start">
                          <label>Início</label>
                          <input
                            name="work_time_start"
                            value={user?.work_time?.start || ''}
                            readOnly
                          />
                        </div>
                        <div className="work_time_stop">
                          <label>Fim</label>
                          <input
                            name="work_time_stop"
                            value={user?.work_time?.stop || ''}
                            readOnly
                          />
                        </div>
                        <div className="work_time_balance">
                          <label>Saldo(minutos)</label>
                          <input
                            name="work_time_balance"
                            value={user?.work_time?.opening_balance || ''}
                            readOnly
                          />
                        </div>
                      </section>
                    </WorkTime>
                    <WorkTimetable>
                      {user?.work_time?.work_timetable?.map(time => (
                        <section key={time.id}>
                          <div className="work_timetable_day">
                            <label>Dia da semana</label>
                            <input
                              name="work_timetable_day"
                              value={time?.day_label}
                              readOnly
                            />
                          </div>
                          <div className="work_timetable_in_hour">
                            <label>Início</label>
                            <input
                              name="work_timetable_in_hour"
                              value={time?.in_hour}
                              readOnly
                            />
                          </div>
                          <div className="work_timetable_out_hour">
                            <label>Fim</label>
                            <input
                              name="work_timetable_out_hour"
                              value={time?.out_hour}
                              readOnly
                            />
                          </div>
                        </section>
                      ))}
                    </WorkTimetable>
                  </>
                )}
                <AccessTimetable>
                  <h4>HORÁRIOS AUTORIZADOS</h4>
                  {user?.access_timetable?.map(day => (
                    <section key={day.id}>
                      <div className="access_timetable_day">
                        <label>Dia da semana</label>
                        <input
                          name="access_timetable_day"
                          value={day?.day_label || ''}
                          readOnly
                        />
                      </div>
                      <div className="access_timetable_start">
                        <label>Início</label>
                        <input
                          name="access_timetable_start"
                          value={day?.start || ''}
                          readOnly
                        />
                      </div>
                      <div className="access_timetable_stop">
                        <label>Fim</label>
                        <input
                          name="access_timetable_stop"
                          value={day?.end || ''}
                          readOnly
                        />
                      </div>
                    </section>
                  ))}
                </AccessTimetable>
              </DetailsContainer>
            )}
          </Content>
        )}
      </Container>
      {deleteLoading && <Loading />}
    </>
  );
};

export default List;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  margin: 0;
  padding: 0;
  background-color: #eee;
`;

export const Login = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  > div {
    padding: 30px;
    background-color: #fff;
    color: #44546a;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 450px;
    height: 300px;

    > div {
      display: flex;
      flex-direction: column;
    }

    span {
      margin-top: 10px;
    }

    .error {
      position: absolute;
      margin-top: 45px;
      color: rgb(229, 57, 53);
      font-size: 10px;
      font-weight: bold;
    }

    label {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.grey};
      opacity: 0.7;
    }

    input {
      padding-top: 10px;
      height: 30px;
      background: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    }

    button {
      border: 0;
      border-radius: 8px;
      background-color: #01579b;
      color: #fff;
      text-align: center;
      padding: 15px 40px;
    }
  }

  @media (max-width: 700px) {
    > div {
      width: 100%;
      margin: 15px;
    }
  }
`;

export const Header = styled.header`
  width: 100%;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${({ theme }) => theme.colors.blue};

  font-size: 1rem;
  color: #fff;

  .logo {
    img {
      border: 0;
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
  }

  .protocol {
    padding: 20px 0;
    text-align: center;
  }

  .user {
    margin: 20px 0;
    font-size: 1rem;
    text-align: end;
  }

  @media (max-width: 650px) {
    .user {
      display: none;
    }

    .protocol {
      text-align: end;
    }
  }

  @media (min-width: 650px) {
    div {
      width: 30%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  height: calc(100vh - 40px - 1.5rem);
  overflow: auto;

  @media screen and (max-width: 650px) {
    padding: 10px;
  }
`;

export const Document = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  width: 250px;
  height: max-content;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);

  button,
  a {
    text-align: center;
    background: ${({ theme }) => theme.colors.blue};
    color: #fff;
    transition: background 0.3s;
    padding: 8px;
    border-radius: 8px;

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
    }
  }

  > section > section {
    background: #ddd;
    width: 210px;
    text-align: right;
    height: 16px;

    .downloaded {
      color: #00c100;
    }

    .not-downloaded {
      color: rgb(229, 57, 53);
    }
  }

  .document {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 210px;
    height: 210px;
    background: #ddd;

    img {
      height: 60px;
      width: 60px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #44546a;
    width: 100%;
    min-height: 155px;

    span,
    strong {
      font-size: 0.8rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
    }

    section {
      display: flex;
      justify-content: flex-start;
      min-height: 16px;
    }
  }

  @media (max-width: 650px) {
    width: calc(100vw - 60px);
    height: max-content;
    padding: 20px;
    margin: 20px;

    &:last-child {
      margin-bottom: 50px;
    }

    .document {
      width: calc(100vw - 100px);
      height: calc(100vw - 250px);
      padding: 20px;
    }

    > section > section {
      width: calc(100vw - 100px);
    }
  }

  &:last-child {
    .info {
      justify-content: space-between;
    }
  }
`;

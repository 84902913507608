import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaTable,
  FaTimes,
  FaEye,
  FaChevronLeft,
  FaChevronRight,
  FaPlus,
  FaEraser,
  FaEdit,
  FaTrash,
} from 'react-icons/fa';

import { TableLoading, TableContainer } from '~/components/Table';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import {
  Container,
  Header,
  Controls,
  Content,
  DetailsContainer,
  ModelInfo,
  Documents,
} from './styles';

const List = () => {
  const { company } = useAuth();

  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const [models, setModels] = useState([]);
  const [model, setModel] = useState([]);

  const [totalModels, setTotalModels] = useState(0);
  const [modelIndex, setModelIndex] = useState(0);

  useEffect(() => {
    async function loadModels() {
      if (company) {
        try {
          setLoading(true);

          const response = await api.get('checklists-models', {
            params: {
              department: 'dpt_labour',
              company_id: company.id,
            },
          });

          if (response.data.length > 0) {
            setModels(response.data);
            setModel(response.data[0]);
            setTotalModels(response.data.length);
          } else {
            setModels([]);
            setModel([]);
            setTotalModels(0);
            toast.warn('Nenhum modelo foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setLoading(false);
        } catch (err) {
          toast.error('Falha ao buscar modelos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      }
    }

    loadModels();
  }, [company]);

  const alterView = useCallback(() => {
    if (models.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, models]);

  const getDetails = useCallback(
    (id, index) => {
      const modelItem = models.find(item => item.id === id);

      setModel(modelItem);
      setModelIndex(index);
      alterView();
    },
    [models, alterView]
  );

  const handlePrevItem = useCallback(() => {
    if (modelIndex !== 0) {
      setModelIndex(modelIndex - 1);
      setModel(models[modelIndex - 1]);
    } else {
      setModelIndex(models.length - 1);
      setModel(models[models.length - 1]);
    }
  }, [modelIndex, models]);

  const handleNextItem = useCallback(() => {
    if (modelIndex !== models.length - 1) {
      setModelIndex(modelIndex + 1);
      setModel(models[modelIndex + 1]);
    } else {
      setModelIndex(0);
      setModel(models[0]);
    }
  }, [modelIndex, models]);

  const handleDelete = useCallback(
    async id => {
      try {
        setDeleteLoading(true);

        await api.delete(`checklists-models/${id}`);

        const modelsFilter = models.filter(item => item.id !== id);

        setModels(modelsFilter);

        alterView();

        toast.success('Modelo deletado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setDeleteLoading(false);
      } catch (err) {
        toast.error('Falha ao deletar modelo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setDeleteLoading(false);
      }
    },
    [models, alterView]
  );

  const confirmDelete = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => handleDelete(model.id)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete, model.id]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaTable size={20} color="#44546a" />
            <h1>Modelos Trabalhistas</h1>
          </div>

          <Link to="/">
            <FaTimes size={20} color="#44546a" />
          </Link>
        </Header>

        <Controls>
          <button type="button" onClick={alterView}>
            <FaEye />
            <span>Visualização</span>
          </button>

          {!loading && models && models.length === 0 && (
            <Link to={{ pathname: '/labour-model/new', state: { id: null } }}>
              <FaPlus />
              <span>Novo</span>
            </Link>
          )}
          {showTable ? (
            <button type="button">
              <FaEraser />
              <span>Limpar filtros</span>
            </button>
          ) : (
            <>
              <Link
                to={{ pathname: '/labour-model/edit', state: { id: model.id } }}
              >
                <FaEdit />
                <span>Editar</span>
              </Link>
              <button type="button" onClick={confirmDelete}>
                <FaTrash />
                <span>Excluir</span>
              </button>
              <div>
                <button type="button" onClick={handlePrevItem}>
                  <FaChevronLeft />
                </button>
                {totalModels > 25 ? (
                  <span>{modelIndex + 1} de 25</span>
                ) : (
                  <span>
                    {modelIndex + 1} de {totalModels}
                  </span>
                )}
                <button type="button" onClick={handleNextItem}>
                  <FaChevronRight />
                </button>
              </div>
            </>
          )}
        </Controls>

        {loading || !company ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {showTable ? (
              <TableContainer>
                <thead>
                  <tr>
                    <th className="description">Descrição</th>
                  </tr>
                </thead>
                <tbody>
                  {models &&
                    models.map((modelItem, index) => (
                      <tr
                        key={modelItem.id}
                        className="hover"
                        onClick={() => getDetails(modelItem.id, index)}
                      >
                        <td className="description">{modelItem.model_name}</td>
                      </tr>
                    ))}
                </tbody>
              </TableContainer>
            ) : (
              <DetailsContainer>
                <ModelInfo>
                  <h4>MODELO</h4>
                  <section>
                    <div className="description">
                      <label>Descrição</label>
                      <input
                        name="description"
                        value={model.model_name}
                        readOnly
                      />
                    </div>
                  </section>
                </ModelInfo>
                <Documents>
                  <h4>DOCUMENTOS</h4>
                  {model.documents &&
                    model.documents.map(document => (
                      <section key={document.model_document_id}>
                        <div className="abbrev">
                          <label>Abreviação</label>
                          <input
                            name="abbrev"
                            value={document.document_abbrev}
                            readOnly
                          />
                        </div>
                        <div className="document">
                          <label>Documento</label>
                          <input
                            name="document"
                            value={document.document_name}
                            readOnly
                          />
                        </div>
                      </section>
                    ))}
                </Documents>
              </DetailsContainer>
            )}
          </Content>
        )}
      </Container>
      {deleteLoading && <Loading />}
    </>
  );
};

export default List;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border};

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    > div {
      & + div {
        margin-left: 16px;
      }
    }
  }

  .loading {
    position: absolute;
    z-index: 2;
  }

  .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  .hide {
    display: none;
  }
`;

export const RelatedPeopleInfo = styled.div`
  display: flex;
  flex-direction: column;

  .document,
  .treatment_name {
    width: 20%;
  }

  .birthday,
  .protocol_preference,
  .active,
  .end_day {
    width: 25%;
  }

  .name {
    width: 60%;
  }

  .checkbox {
    height: 44px;
  }
`;

export const Addresses = styled.div`
  margin-top: 16px;
  position: relative;

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 16px;
      margin-left: 8px;
      background: none;
      color: ${({ theme }) => theme.colors.blue};
      border: 1px solid ${({ theme }) => theme.colors.blue};
      border-radius: 50%;
      padding: 2px;
      transition: opacity 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  > span {
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 20px;
  }
`;

export const Address = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  button {
    position: absolute;
    top: 40%;
    background: none;
    color: ${({ theme }) => theme.colors.blue};
    border: 1px solid ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    padding: 2px;
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }
  }

  section {
    padding-left: 16px;
  }

  .address_type {
    margin-left: 0;
    width: 20%;
  }

  .zipcode {
    width: 20%;
  }

  .number {
    width: 10%;
  }

  .street {
    width: 50%;
  }

  .complement,
  .neighborhood,
  .city,
  .state {
    width: 25%;
  }
`;

export const ContactsList = styled.div`
  margin-top: 16px;
  position: relative;

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 16px;
      margin-left: 8px;
      background: none;
      color: ${({ theme }) => theme.colors.blue};
      border: 1px solid ${({ theme }) => theme.colors.blue};
      border-radius: 50%;
      padding: 2px;
      transition: opacity 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  > span {
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 20px;
  }
`;

export const Contact = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  > button {
    position: absolute;
    top: 45%;
    background: none;
    color: ${({ theme }) => theme.colors.blue};
    border: 1px solid ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    padding: 2px;
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }
  }

  section {
    padding-left: 16px;
  }

  .contact_type {
    width: 25%;
    margin-left: 0;
  }

  .content {
    width: 75%;
  }
`;

export const TagsList = styled.div`
  margin-top: 16px;
  position: relative;

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 16px;
      margin-left: 8px;
      background: none;
      color: ${({ theme }) => theme.colors.blue};
      border: 1px solid ${({ theme }) => theme.colors.blue};
      border-radius: 50%;
      padding: 2px;
      transition: opacity 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  > span {
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 20px;
  }
`;

export const Tag = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  > button {
    position: absolute;
    top: 45%;
    background: none;
    color: ${({ theme }) => theme.colors.blue};
    border: 1px solid ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    padding: 2px;
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }
  }

  section {
    padding-left: 16px;
  }

  .tag {
    width: 100vw;
    margin-left: 0;
  }
`;

import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { Scope } from '@unform/core';
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import cep from 'cep-promise';
import * as Yup from 'yup';
import axios from 'axios';
import {
  FaHandshake,
  FaSave,
  FaBroom,
  FaTimes,
  FaPlus,
  FaMinus,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import formatContact from '~/util/formatContact';
import returnAddressesWithCitiesOptions from '~/util/returnAddressesWithCitiesOptions';

import {
  FormLoading,
  Select,
  Input,
  InputMask,
  DatePicker,
  Checkbox,
} from '~/components/Form';
import Loading from '~/components/Loading';
import Modal from '~/components/Modal';
import ConfirmWindow from '~/components/ConfirmWindow';

import { Header, Controls, FormContainer } from '~/styles/components';
import {
  Container,
  Content,
  RelatedPeopleInfo,
  Addresses,
  Address,
  ContactsList,
  Contact,
  TagsList,
  Tag,
} from './styles';

const RelatedPeopleModal = ({
  isOpen,
  setIsOpen,
  id,
  relatedIndex,
  relatedId,
  relatedDocument,
  updateRelatedPeople,
}) => {
  const { company } = useAuth();

  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [cepLoading, setCepLoading] = useState(false);

  const [showEndDay, setShowEndDay] = useState(false);

  const [relatedPeople, setRelatedPeople] = useState(null);
  const [states, setStates] = useState(null);

  const [
    requiredAddressErrorMessage,
    setRequiredAddressErrorMessage,
  ] = useState('');
  const [
    requiredContactsErrorMessage,
    setRequiredContactsErrorMessage,
  ] = useState('');
  const [requiredTagsErrorMessage, setRequiredTagsErrorMessage] = useState('');

  useEffect(() => {
    async function loadRelatedPeople() {
      if (id) {
        try {
          setLoading(true);

          const response = await api.get(`relationships/${id}`);

          const { data } = response;

          data.birthday = data.birthday ? parseISO(data.birthday) : null;
          data.end_day = data.end_day ? parseISO(data.end_day) : null;

          data.addresses = await returnAddressesWithCitiesOptions(
            data.addresses
          );

          data.contacts = data?.contacts?.map(contact => ({
            ...contact,
            content: contact.content
              ? formatContact(contact.type, contact.content)
              : '',
          }));

          if (!data.active) {
            setShowEndDay(true);
          }

          setRelatedPeople(data);
        } catch (err) {
          toast.error(
            `${err.response.data.message ||
              'Falha ao buscar pessoa relacionada.'}`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        } finally {
          setLoading(false);
        }
      } else {
        toast.warn(
          'CPF não cadastrado, crie antes o cadastro da pessoa relacionada.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );

        setRelatedPeople({
          document: relatedDocument,
          protocol_preference: 0,
          addresses: [
            {
              id: uuid(),
              type: 1,
              state_label: '',
              owner_id: '',
              street: '',
              number: '',
              neighborhood: '',
              city: '',
              state: '',
              zipcode: '',
              complement: '',
            },
          ],
          contacts: [
            {
              id: uuid(),
              type: 1,
              content: '',
            },
            {
              id: uuid(),
              type: 3,
              content: '',
            },
          ],
        });
      }
    }

    loadRelatedPeople();
  }, [id, relatedDocument]);

  useEffect(() => {
    function loadStates() {
      setStatesLoading(true);

      axios
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(response => {
          const ufInitials = response.data.map(uf => ({
            value: uf.sigla,
            label: uf.nome,
          }));
          setStates(ufInitials);
        });

      setStatesLoading(false);
    }
    loadStates();
  }, []);

  const protocolPreferenceOptions = useMemo(() => {
    return [
      { value: 0, label: 'Portal' },
      { value: 2, label: 'Impressão' },
    ];
  }, []);

  const contactsTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'E-mail' },
      { value: 2, label: 'Fixo' },
      { value: 3, label: 'Celular' },
    ];
  }, []);

  const addressTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'Sede da Empresa' },
      { value: 2, label: 'Correspondência' },
      { value: 3, label: 'Outros' },
    ];
  }, []);

  const tagsOptions = useMemo(() => {
    return [
      { value: 'Empregador PF', label: 'Empregador PF' },
      { value: 'Empregador Doméstico', label: 'Empregador Doméstico' },
      { value: 'MEI', label: 'MEI' },
      { value: 'MEI Somente Declarações', label: 'MEI Somente Declarações' },
      {
        value: 'Simples Nacional C/ Inscrição',
        label: 'Simples Nacional C/ Inscrição',
      },
      {
        value: 'Simples Nacional S/ Inscrição',
        label: 'Simples Nacional S/ Inscrição',
      },
      { value: 'Lucro Presumido', label: 'Lucro Presumido' },
      { value: 'Lucro Real', label: 'Lucro Real' },
      { value: 'Condomínio', label: 'Condomínio' },
      { value: 'Terceiro Setor', label: 'Terceiro Setor' },
    ];
  }, []);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            document: Yup.string().required('O documento é obrigatório.'),
            name: Yup.string().required('O nome é obrigatório.'),
            protocol_preference: Yup.string().required(
              'A preferência de protocolo é obrigatória.'
            ),
            birthday: Yup.string()
              .nullable()
              .required('A data do nascimento é obrigatória.'),
            addresses: Yup.array()
              .min(1)
              .of(
                Yup.object().shape({
                  zipcode: Yup.string().required('O CEP é obrigatório.'),
                  street: Yup.string().required('O endereço é obrigatório.'),
                  number: Yup.string().required('O número é obrigatório.'),
                  neighborhood: Yup.string().required(
                    'O bairro é obrigatório.'
                  ),
                  state: Yup.string().required('O estado é obrigatório.'),
                  city: Yup.string().required('A cidade é obrigatória.'),
                })
              )
              .required('Pelo menos um endereço é obrigatório.'),
            contacts: Yup.array()
              .min(1)
              .of(
                Yup.object().shape({
                  type: Yup.string().required(
                    'O tipo de contato é obrigatório.'
                  ),
                  content: Yup.string().required('O contato é obrigatório.'),
                })
              )
              .required('Pelo menos um contato é obrigatório.'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          data.company_id = company.id;
          data.type = 2;
          data.document = data.document.replace(/[^0-9]+/g, '');
          data.document_type = 1;
          data.start_day = data.birthday;
          data.client_type = 1;

          let related_id;
          let old_related_people_id;

          const getOldData = await api.get('user-companies/old-data', {
            params: {
              company_id: company.id,
            },
          });

          const { old_company_id, mainid } = getOldData.data;

          if (id) {
            related_id = id;

            const existedRelatedPeople = await api.put(
              `relationships/${related_id}`,
              data,
              {
                params: {
                  mainid,
                },
              }
            );

            old_related_people_id = existedRelatedPeople.data.old_id;
          } else {
            const createdRelatedPeople = await api.post('relationships', data, {
              params: {
                old_company_id,
                mainid,
              },
            });

            related_id = createdRelatedPeople.data.id;
            old_related_people_id = createdRelatedPeople.data.old_id;
          }

          const addressesData = data.addresses.map(address => {
            return {
              id: Number(address.id),
              owner_id: related_id,
              street: address.street,
              zipcode: address.zipcode.replace(/[^0-9]+/g, ''),
              number: address.number,
              neighborhood: address.neighborhood,
              city: address.city,
              state: address.state,
              complement: address.complement,
            };
          });

          await api.post(
            'relationships/addresses',
            {
              addresses: addressesData,
            },
            {
              params: {
                owner_id: related_id,
              },
            }
          );

          const contactsData = data.contacts.map(contact => {
            return {
              id: Number(contact.id),
              owner_id: related_id,
              type: contact.type,
              content:
                contact.type === 1
                  ? contact.content
                  : contact.content.replace(/[^0-9]+/g, ''),
            };
          });

          await api.post(
            'relationships/contacts',
            {
              company_id: company.id,
              contacts: contactsData,
            },
            {
              params: {
                relationship_id: related_id,
                old_relationship_id: old_related_people_id,
                old_company_id,
                mainid,
              },
            }
          );

          if (data.tags) {
            const tagsData = data.tags.map(tag => {
              return {
                id: Number(tag.id),
                owner_id: related_id,
                tag: tag.tag,
              };
            });

            await api.post(
              'relationships/tags',
              {
                company_id: company.id,
                tags: tagsData,
              },
              {
                params: {
                  relationship_id: related_id,
                  old_relationship_id: old_related_people_id,
                  old_company_id,
                  mainid,
                },
              }
            );
          }

          formRef.current.setErrors({});

          toast.success('Pessoa relacionada salva com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);
          setIsOpen();
          updateRelatedPeople(data.document, relatedIndex, relatedId);
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              if (error.path === 'addresses') {
                setRequiredAddressErrorMessage(error.message);
              }
              if (error.path === 'contacts') {
                setRequiredContactsErrorMessage(error.message);
              }
              if (error.path === 'tags') {
                setRequiredTagsErrorMessage(error.message);
              }
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar pessoa relacionada.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setSaveLoading(false);
        }
      }
    },
    [id, company, setIsOpen, relatedIndex, updateRelatedPeople, relatedId]
  );

  const handleResetForm = useCallback(() => {
    formRef.current.reset();
    formRef.current.setErrors({});
    setRequiredAddressErrorMessage('');
    setRequiredContactsErrorMessage('');
    setRequiredTagsErrorMessage('');
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={handleResetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleResetForm]);

  const confirmRemove = useCallback((functionToExecute, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => functionToExecute(index)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleAddNewAddress = useCallback(() => {
    setRelatedPeople(
      produce(relatedPeople, draft => {
        const blankAddress = {
          id: uuid(),
          type: 1,
          state_label: '',
          owner_id: '',
          street: '',
          number: '',
          neighborhood: '',
          city: '',
          state: '',
          zipcode: '',
          complement: '',
        };

        if (draft.addresses.length > 0) {
          draft.addresses.unshift(blankAddress);
        } else if (draft.addresses) {
          draft.addresses[0] = blankAddress;
        } else {
          draft.addresses = [blankAddress];
        }
      })
    );
  }, [relatedPeople]);

  const handleRemoveAddress = useCallback(
    async index => {
      setRelatedPeople(
        produce(relatedPeople, draft => {
          delete draft.addresses[index];
        })
      );
    },
    [relatedPeople]
  );

  const handleAddNewContact = useCallback(() => {
    setRelatedPeople(
      produce(relatedPeople, draft => {
        const blankContact = {
          id: uuid(),
          type: 1,
          content: '',
        };

        if (draft.contacts.length > 0) {
          draft.contacts.unshift(blankContact);
        } else if (draft.contacts) {
          draft.contacts[0] = blankContact;
        } else {
          draft.contacts = [blankContact];
        }
      })
    );
  }, [relatedPeople]);

  const handleRemoveContact = useCallback(
    async index => {
      setRelatedPeople(
        produce(relatedPeople, draft => {
          delete draft.contacts[index];
        })
      );
    },
    [relatedPeople]
  );

  const handleAddNewTag = useCallback(() => {
    setRelatedPeople(
      produce(relatedPeople, draft => {
        const blankTag = {
          id: uuid(),
          tag: '',
        };

        if (draft.tags.length > 0) {
          draft.tags.unshift(blankTag);
        } else if (draft.tags) {
          draft.tags[0] = blankTag;
        } else {
          draft.tags = [blankTag];
        }
      })
    );
  }, [relatedPeople]);

  const handleRemoveTag = useCallback(
    async index => {
      setRelatedPeople(
        produce(relatedPeople, draft => {
          delete draft.tags[index];
        })
      );
    },
    [relatedPeople]
  );

  const handleChangeContactType = useCallback(
    (event, index) => {
      setRelatedPeople(
        produce(relatedPeople, draft => {
          draft.contacts[index].type = event.value;
        })
      );
    },
    [relatedPeople]
  );

  const handleChangeZipcode = useCallback(
    async (value, index) => {
      const unmaskedValue = value.replace(/[^0-9]+/g, '');

      if (unmaskedValue.length !== 8) {
        return;
      }

      try {
        setCepLoading(true);

        formRef.current.setErrors({});

        const cepData = await cep(unmaskedValue);

        const citiesOptions = await axios
          .get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${cepData.state}/municipios`
          )
          .then(response => {
            const cityNames = response.data.map(city => ({
              value: city.nome,
              label: city.nome,
            }));

            return cityNames;
          });

        setRelatedPeople(
          produce(relatedPeople, draft => {
            const address = {
              id: uuid(),
              type: 1,
              zipcode: unmaskedValue,
              street: cepData.street,
              number: '',
              complement: '',
              neighborhood: cepData.neighborhood,
              state: cepData.state,
              city: cepData.city,
              citiesOptions,
            };

            draft.addresses[index] = address;
          })
        );

        const numberEl = document.getElementById('number');

        numberEl.focus();
      } catch (err) {
        formRef.current.setFieldError(
          `addresses[${index}].zipcode`,
          'CEP inválido.'
        );
      } finally {
        setCepLoading(false);
      }
    },
    [relatedPeople]
  );

  const handleChangeState = useCallback(
    async (value, index) => {
      const citiesOptions = await axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`
        )
        .then(response => {
          const cityNames = response.data.map(city => ({
            value: city.nome,
            label: city.nome,
          }));

          return cityNames;
        });

      setRelatedPeople(
        produce(relatedPeople, draft => {
          if (draft.addresses[index]) {
            draft.addresses[index].state = value;
            draft.addresses[index].citiesOptions = citiesOptions;
          }
        })
      );
    },
    [relatedPeople]
  );

  const handleChangeActive = useCallback(() => {
    setShowEndDay(!showEndDay);
  }, [showEndDay]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaHandshake size={20} color="#44546a" />
            <h1>Pessoa Relacionada</h1>
          </div>
        </Header>
        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={setIsOpen}>
            <FaTimes />
            <span>Fechar</span>
          </button>
        </Controls>
        {(loading || statesLoading || !company) && (
          <FormLoading className="loading" />
        )}
        {relatedPeople && states && (
          <Content className="content">
            <FormContainer
              ref={formRef}
              loading={loading ? 1 : 0}
              onSubmit={handleSubmit}
              initialData={relatedPeople}
            >
              <RelatedPeopleInfo>
                <h4>INFORMAÇÕES BÁSICAS</h4>
                <section>
                  <InputMask
                    name="document"
                    className="document"
                    label="CPF"
                    mask="999.999.999-99"
                  />
                  <Input name="name" className="name" label="Nome" />
                  <Input
                    name="treatment_name"
                    className="treatment_name"
                    label="Tratamento"
                  />
                </section>
                <section>
                  <DatePicker
                    name="birthday"
                    className="birthday"
                    label="Nascimento"
                  />
                  <Select
                    name="protocol_preference"
                    className="protocol_preference"
                    label="Preferência de protocolo"
                    options={protocolPreferenceOptions}
                    placeholder="Selecione um tipo"
                  />

                  {id && (
                    <Checkbox
                      id="active"
                      name="active"
                      className="situation"
                      label="Ativo"
                      onChange={handleChangeActive}
                    />
                  )}
                  {id && showEndDay && (
                    <DatePicker
                      name="end_day"
                      className="end_day"
                      label="Data de encerramento"
                    />
                  )}
                </section>
              </RelatedPeopleInfo>

              <Addresses>
                <header>
                  <h4>ENDEREÇOS</h4>
                  <button type="button" onClick={handleAddNewAddress}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {requiredAddressErrorMessage && (
                  <span>{requiredAddressErrorMessage}</span>
                )}

                {relatedPeople?.addresses?.map((address, index) => (
                  <Address key={address.id}>
                    <button
                      type="button"
                      onClick={() => confirmRemove(handleRemoveAddress, index)}
                    >
                      <FaMinus size={10} />
                    </button>

                    <Scope path={`addresses[${index}]`}>
                      <section>
                        <Input
                          name="id"
                          type="text"
                          className="hide"
                          readOnly
                        />
                        <Select
                          name="type"
                          className="address_type"
                          label="Tipo"
                          placeholder="Selecione um tipo"
                          options={addressTypeOptions}
                        />
                        <InputMask
                          name="zipcode"
                          className="zipcode"
                          label="CEP"
                          mask="99999-999"
                          onChange={e =>
                            handleChangeZipcode(e.target.value, index)
                          }
                        />
                        <Input
                          name="street"
                          className="street"
                          label="Endereço"
                        />
                        <Input
                          id="number"
                          name="number"
                          className="number"
                          label="Nº"
                        />
                      </section>
                      <section>
                        <Input
                          name="complement"
                          className="complement"
                          label="Complemento"
                        />
                        <Input
                          name="neighborhood"
                          className="neighborhood"
                          label="Bairro"
                        />
                        <Select
                          name="state"
                          className="state"
                          label="Estado"
                          placeholder="Selecione um estado"
                          options={states}
                          onChange={e => handleChangeState(e.value, index)}
                        />
                        {address.state && (
                          <Select
                            name="city"
                            className="city"
                            label="Cidade"
                            placeholder="Selecione uma cidade"
                            defaultValue={{
                              value: address.city,
                              label: address.city,
                            }}
                            options={address.citiesOptions}
                          />
                        )}
                      </section>
                    </Scope>
                  </Address>
                ))}
              </Addresses>

              <ContactsList>
                <header>
                  <h4>CONTATOS</h4>
                  <button type="button" onClick={handleAddNewContact}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {requiredContactsErrorMessage && (
                  <span>{requiredContactsErrorMessage}</span>
                )}

                {relatedPeople?.contacts?.map((contact, index) => (
                  <Contact key={contact.id}>
                    <button
                      type="button"
                      onClick={() => confirmRemove(handleRemoveContact, index)}
                    >
                      <FaMinus size={10} />
                    </button>
                    <Scope path={`contacts[${index}]`}>
                      <section>
                        <Input
                          name="id"
                          type="text"
                          className="hide"
                          readOnly
                        />
                        <Select
                          name="type"
                          className="contact_type"
                          label="Tipo"
                          options={contactsTypeOptions}
                          onChange={e => handleChangeContactType(e, index)}
                          placeholder="Selecione um tipo"
                        />
                        {contact.type === 1 && (
                          <Input
                            name="content"
                            className="content"
                            label="E-mail"
                            type="email"
                          />
                        )}
                        {contact.type === 2 && (
                          <InputMask
                            name="content"
                            className="content"
                            label="Fixo"
                            mask="(99) 9999-9999"
                          />
                        )}
                        {contact.type === 3 && (
                          <InputMask
                            name="content"
                            className="content"
                            label="Celular"
                            mask="(99) 99999-9999"
                          />
                        )}
                      </section>
                    </Scope>
                  </Contact>
                ))}
              </ContactsList>

              <TagsList>
                <header>
                  <h4>TAGS</h4>
                  <button type="button" onClick={handleAddNewTag}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {requiredTagsErrorMessage && (
                  <span>{requiredTagsErrorMessage}</span>
                )}

                {relatedPeople?.tags?.map((tag, index) => (
                  <Tag key={tag.id}>
                    <button
                      type="button"
                      onClick={() => confirmRemove(handleRemoveTag, index)}
                    >
                      <FaMinus size={10} />
                    </button>
                    <Scope path={`tags[${index}]`}>
                      <section>
                        <Input
                          name="id"
                          type="text"
                          className="hide"
                          readOnly
                        />
                        <Select
                          name="tag"
                          className="tag"
                          label="Tag"
                          options={tagsOptions}
                          placeholder="Selecione uma opção"
                        />
                      </section>
                    </Scope>
                  </Tag>
                ))}
              </TagsList>
            </FormContainer>
          </Content>
        )}
      </Container>

      {(saveLoading || cepLoading) && <Loading />}
    </Modal>
  );
};

export default RelatedPeopleModal;

import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: rgba(230, 230, 230, 0.95);
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  button {
    background: transparent;
    border: none;
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

// export const Filter = styled(Form)`
//   padding: 8px;
//   border-bottom: 1px solid ${({ theme }) => theme.colors.border};

//   display: flex;
//   align-items: center;
//   justify-content: space-between;

//   > div + div {
//     margin-left: 16px;
//   }

//   .user {
//     width: 95vw;
//   }

//   button {
//     border: 0;
//     background: transparent;
//     margin: 0 16px;
//     transition: opacity 0.2s;
//     width: 5vw;

//     &:hover {
//       cursor: pointer;
//       opacity: 0.8;
//     }

//     svg {
//       color: ${({ theme }) => theme.colors.primary};
//       height: 20px;
//       width: 20px;
//     }
//   }
// `;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    .description {
      width: 100vw;
      padding-left: 8px;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ModelInfo = styled.div`
  section {
    div + div {
      margin-left: 16px;
    }
  }

  .description {
    width: 100vw;
  }
`;

export const Documents = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .document {
    width: 90vw;
  }

  .abbrev {
    width: 10vw;
  }
`;

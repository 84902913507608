import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import ReactTooltip from 'react-tooltip';
import { format, subMonths, addMonths } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaClipboardCheck,
  FaTimes,
  FaEye,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaMinus,
  FaBan,
  FaCheckCircle,
  FaRegCircle,
  FaThumbsDown,
  FaThumbsUp,
  FaSlidersH,
  FaSync,
} from 'react-icons/fa';

import { Select, SelectWithFilterActiveCheckbox } from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import ParameterModal from '../ParameterModal';
import ConfirmWindow from '../ConfirmMarkDocument';
import CreatingChecklistMessage from '~/components/CreatingChecklistMessage';
import PermissionComponent from '~/components/PermissionComponent';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  Subtitles,
  DocumentTd,
} from './styles';

const List = () => {
  const { user, companyUser, companyUsers, company } = useAuth();

  const filterRef = useRef(null);

  const [cookies, setCookie] = useCookies();

  const [loading, setLoading] = useState(true);
  const [creatingChecklistLoading, setCreatingChecklistLoading] = useState(
    false
  );

  const [toggleParameter, setToggleParameter] = useState(false);

  const [modelDocuments, setModelDocuments] = useState([]);
  const [checklistId, setChecklistId] = useState(null);
  const [checklistDocuments, setChecklistDocuments] = useState([]);
  const [totalChecklist, setTotalChecklist] = useState(0);

  const [usersOptions, setUsersOptions] = useState([]);

  const [period, setPeriod] = useState(() => {
    const periodStorage = localStorage.getItem(
      '@Diretiva:tax-checklist:filter:period'
    );

    if (periodStorage) {
      return new Date(periodStorage);
    }

    return subMonths(new Date(), 1);
  });
  const formattedPeriod = useMemo(() => {
    return format(period, 'MM/yyyy');
  }, [period]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTaxationType, setSelectedTaxationType] = useState(() => {
    if (cookies.tax_checklist_taxation_type) {
      return cookies.tax_checklist_taxation_type;
    }

    return { value: '', label: 'Todos', database_label: '' };
  });
  const [selectedContractType, setSelectedContractType] = useState(() => {
    if (cookies.tax_checklist_contract_type) {
      return cookies.tax_checklist_contract_type;
    }

    return { value: '', label: 'Todos' };
  });
  const [selectedSituation, setSelectedSituation] = useState(() => {
    if (cookies.tax_checklist_situation) {
      return cookies.tax_checklist_situation;
    }

    return { value: '', label: 'Todos' };
  });

  const loadChecklist = useCallback(async () => {
    if (company && selectedUser) {
      try {
        setLoading(true);

        const checklistResponse = await api.get('checklists', {
          params: {
            company_id: company.id,
            department: 'dpt_tax',
            period: formattedPeriod,
          },
        });

        const { checklist_id } = checklistResponse.data;

        setChecklistId(checklist_id);

        let checklistDocumentsResponse;

        if (checklistResponse.data.new) {
          setCreatingChecklistLoading(true);

          checklistDocumentsResponse = await api.post(
            `checklists/${checklist_id}`,
            {
              company_id: company.id,
              department: 'dpt_tax',
              period: formattedPeriod,
              responsible: selectedUser.value,
            }
          );
        } else {
          checklistDocumentsResponse = await api.get(
            `checklists/${checklist_id}`,
            {
              params: {
                company_id: company.id,
                department: 'dpt_tax',
                period: formattedPeriod,
                responsible: selectedUser.value,
                taxation_type: selectedTaxationType.value,
                contract_type: selectedContractType.value,
                taxation_database_label: selectedTaxationType.database_label,
              },
            }
          );
        }

        if (checklistDocumentsResponse.data.length > 0) {
          const modelResponse = await api.get(
            'checklists-models/checklist-model-documents/list',
            {
              params: {
                department: 'dpt_tax',
                company_id: company.id,
                period_month: formattedPeriod.split('/')[0],
              },
            }
          );

          const formattedChecklistDocuments = checklistDocumentsResponse.data.map(
            client => {
              if (client.documents.some(document => document.situation === 1)) {
                return { ...client, done: 0 };
              }
              return { ...client, done: 1 };
            }
          );

          let filteredChecklistDocuments = formattedChecklistDocuments;

          if (selectedSituation.value === 1) {
            filteredChecklistDocuments = formattedChecklistDocuments.filter(
              client => client.done === 1
            );
          }
          if (selectedSituation.value === 2) {
            filteredChecklistDocuments = formattedChecklistDocuments.filter(
              client => client.done === 0
            );
          }

          setChecklistDocuments(filteredChecklistDocuments);
          setModelDocuments(modelResponse.data);
          setTotalChecklist(filteredChecklistDocuments.length + 1);
        } else {
          setModelDocuments([]);
          setChecklistDocuments([]);
          toast.warn('Nenhum checklist foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setCreatingChecklistLoading(false);
        setLoading(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        toast.error('Falha ao buscar checklist.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    }
  }, [
    company,
    selectedUser,
    formattedPeriod,
    selectedTaxationType,
    selectedSituation,
    selectedContractType,
  ]);

  useEffect(() => {
    loadChecklist();
  }, [loadChecklist]);

  useEffect(() => {
    if (!cookies.tax_checklist_user) {
      setCookie(
        'tax_checklist_user',
        {
          value: companyUser.id,
          label: user.short_name,
          company_id: company.id,
        },
        {
          expires: addMonths(new Date(), 1),
        }
      );
    }
  }, [setCookie, cookies, companyUser, user, company]);

  useEffect(() => {
    if (companyUsers && companyUser && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.id,
            label: userItem.short_name,
            active: userItem.active,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: companyUser.id,
        label: user.short_name,
        active: true,
      });

      options.push({
        value: '',
        label: 'Todos',
        active: true,
      });

      setUsersOptions(options);
    }
  }, [companyUsers, companyUser, user]);

  useEffect(() => {
    if (companyUser && user && company && !selectedUser) {
      if (
        !cookies.tax_checklist_user ||
        cookies?.tax_checklist_user?.company_id !== company.id
      ) {
        setSelectedUser({
          value: companyUser.id,
          label: user.short_name,
          company_id: company.id,
        });
      } else {
        setSelectedUser(cookies.tax_checklist_user);
      }
    }
  }, [companyUser, user, company, cookies.tax_checklist_user, selectedUser]);

  const taxationTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos', database_label: '' },
      {
        value: 6,
        label: 'Empregador PF',
        database_label: 'taxation_empregador_pf',
      },
      {
        value: 5,
        label: 'Empregador Doméstico',
        database_label: 'taxation_empregador_domestico',
      },
      { value: 1, label: 'MEI', database_label: 'taxation_mei' },
      {
        value: 11,
        label: 'MEI Somente Declarações',
        database_label: 'taxation_mei_somente_declaracoes',
      },
      {
        value: 7,
        label: 'Simples Nacional C/ Inscrição',
        database_label: 'taxation_simples_com_inscricao',
      },
      {
        value: 8,
        label: 'Simples Nacional S/ Inscrição',
        database_label: 'taxation_simples_sem_inscricao',
      },
      {
        value: 3,
        label: 'Lucro Presumido',
        database_label: 'taxation_lucro_presumido',
      },
      { value: 4, label: 'Lucro Real', database_label: 'taxation_lucro_real' },
      { value: 9, label: 'Condomínio', database_label: 'taxation_condominio' },
      {
        value: 10,
        label: 'Terceiro Setor',
        database_label: 'taxation_terceiro',
      },
    ];
  }, []);

  const contractTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Terceirizado' },
      { value: 2, label: 'Próprio' },
    ];
  }, []);

  const situationOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Concluídos' },
      { value: 2, label: 'Não Concluídos' },
    ];
  }, []);

  const handleFilter = useCallback(
    ({ user_id, taxation_type, contract_type, situation }) => {
      if (company) {
        setSelectedUser(usersOptions.find(option => option.value === user_id));
        setCookie(
          'tax_checklist_user',
          Object.assign(
            usersOptions.find(option => option.value === user_id),
            { company_id: company.id }
          ),
          {
            expires: addMonths(new Date(), 1),
          }
        );

        setSelectedTaxationType(
          taxationTypeOptions.find(option => option.value === taxation_type)
        );
        setCookie(
          'tax_checklist_taxation_type',
          taxationTypeOptions.find(option => option.value === taxation_type),
          {
            expires: addMonths(new Date(), 1),
          }
        );

        setSelectedContractType(
          contractTypeOptions.find(option => option.value === contract_type)
        );
        setCookie(
          'tax_checklist_contract_type',
          contractTypeOptions.find(option => option.value === contract_type),
          {
            expires: addMonths(new Date(), 1),
          }
        );

        setSelectedSituation(
          situationOptions.find(option => option.value === situation)
        );
        setCookie(
          'tax_checklist_situation',
          situationOptions.find(option => option.value === situation),
          {
            expires: addMonths(new Date(), 1),
          }
        );
      }
    },
    [
      usersOptions,
      taxationTypeOptions,
      contractTypeOptions,
      situationOptions,
      company,
      setCookie,
    ]
  );

  const handleFilterUsersOptions = useCallback(
    value => {
      if (value === true) {
        setUsersOptions(oldUsersOptions =>
          oldUsersOptions.filter(userItem => userItem.active !== false)
        );
      } else if (companyUsers && companyUser && user) {
        const options = companyUsers
          .filter(userItem => userItem.user_id !== user.id)
          .map(userItem => {
            return {
              value: userItem.id,
              label: userItem.short_name,
              active: userItem.active,
            };
          });

        options.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        options.unshift({
          value: companyUser.id,
          label: user.short_name,
          active: true,
        });

        options.push({
          value: '',
          label: 'Todos',
          active: true,
        });

        setUsersOptions(options);
      }
    },
    [companyUsers, user, companyUser]
  );

  const handlePrevPeriod = useCallback(() => {
    localStorage.setItem(
      '@Diretiva:tax-checklist:filter:period',
      subMonths(period, 1)
    );
    setPeriod(oldPeriod => subMonths(oldPeriod, 1));
  }, [period]);

  const handleNextPeriod = useCallback(() => {
    const nextPeriod = addMonths(period, 1);
    const currentPeriod = new Date();

    if (nextPeriod > currentPeriod) {
      return;
    }

    localStorage.setItem('@Diretiva:tax-checklist:filter:period', nextPeriod);
    setPeriod(nextPeriod);
  }, [period]);

  const handleToggleParameter = useCallback(() => {
    setToggleParameter(!toggleParameter);
  }, [toggleParameter]);

  const handleMarkDocument = useCallback(
    async (documentId, situation, type, allowMark) => {
      if (situation === 4 && type !== 2 && !allowMark) {
        toast.warn(
          'Este documento só pode ser marcado manualmente se a opção "Em Checklists, permitir marcação manual" estiver selecionada.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 10000,
          }
        );
      } else {
        setChecklistDocuments(oldChecklistDocuments =>
          oldChecklistDocuments.map(checklistDocument => ({
            ...checklistDocument,
            documents: checklistDocument.documents.map(document =>
              document.id === documentId ? { ...document, situation } : document
            ),
          }))
        );

        await api.put(`checklists/mark-document/${documentId}`, {
          situation,
        });
      }
    },
    []
  );

  const confirmMarkDocument = useCallback(
    async (
      documentId,
      documentType,
      allowMark,
      situation,
      documentName,
      clientName
    ) => {
      if (!companyUser) {
        return;
      }

      if (situation === 0 || situation === 2) {
        return;
      }

      if (companyUser.id !== selectedUser.value && companyUser.level !== 9) {
        toast.warn('Somente o responsável pode marcar documentos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        return;
      }

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={handleMarkDocument}
              onClose={onClose}
              document={{
                documentName,
                documentId,
                documentType,
                allowMark,
                clientName,
                formattedPeriod,
              }}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleMarkDocument, formattedPeriod, companyUser, selectedUser]
  );

  const handleRebuildChecklist = useCallback(async () => {
    if (company) {
      try {
        setCreatingChecklistLoading(true);

        await api.put(`checklists/${checklistId}`, {
          company_id: company.id,
          department: 'dpt_tax',
          period: formattedPeriod,
          responsible: selectedUser.value,
        });

        loadChecklist();
      } catch {
        toast.error('Falha ao reconstruir checklist.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setCreatingChecklistLoading(false);
      }
    }
  }, [checklistId, company, formattedPeriod, loadChecklist, selectedUser]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaClipboardCheck size={20} color="#44546a" />
            <h1>Checklist Tributário</h1>
          </div>

          <Link to="/">
            <FaTimes size={20} color="#44546a" />
          </Link>
        </Header>

        <Controls>
          <nav>
            <button type="button">
              <FaEye />
              <span>Visualização</span>
            </button>
            <PermissionComponent level={6}>
              <button type="button" onClick={handleToggleParameter}>
                <FaSlidersH />
                <span>Parâmetros</span>
              </button>
            </PermissionComponent>
            <PermissionComponent level={9}>
              <button type="button" onClick={handleRebuildChecklist}>
                <FaSync />
                <span>Reconstruir Checklist</span>
              </button>
            </PermissionComponent>
          </nav>

          <aside>
            <button type="button" onClick={handlePrevPeriod}>
              <FaChevronLeft />
            </button>
            <span>{formattedPeriod}</span>
            <button type="button" onClick={handleNextPeriod}>
              <FaChevronRight />
            </button>
          </aside>
        </Controls>

        {selectedUser && (
          <Filter ref={filterRef} onSubmit={handleFilter}>
            <SelectWithFilterActiveCheckbox
              label="Usuário"
              name="user_id"
              className="user"
              options={usersOptions}
              defaultValue={selectedUser}
              handleFilter={handleFilterUsersOptions}
            />

            <Select
              label="Tributação"
              name="taxation_type"
              className="taxation_type"
              options={taxationTypeOptions}
              defaultValue={selectedTaxationType}
            />

            <Select
              label="Tipo de contrato"
              name="contract_type"
              className="contract_type"
              options={contractTypeOptions}
              defaultValue={selectedContractType}
            />

            <Select
              label="Situação"
              name="situation"
              className="situation"
              options={situationOptions}
              defaultValue={selectedSituation}
            />

            <button type="submit">
              <FaSearch />
            </button>
          </Filter>
        )}

        {loading ||
        !user ||
        !company ||
        !companyUser ||
        !companyUsers ||
        !selectedUser ? (
          <TableLoading />
        ) : (
          <Content className="content">
            <ReactTooltip
              id="document"
              place="bottom"
              type="info"
              backgroundColor="#337ab7"
              effect="solid"
            />
            <ReactTooltip
              id="client"
              place="bottom"
              type="info"
              backgroundColor="#337ab7"
              effect="solid"
            />
            <ReactTooltip
              id="mark"
              place="bottom"
              type="info"
              backgroundColor="#337ab7"
              effect="solid"
              multiline
            />
            <TableContainer>
              <thead>
                <tr>
                  <th className="done" />
                  <th className="client">Empresa</th>
                  {modelDocuments &&
                    modelDocuments.map(modelDocument => (
                      <th className="document" key={modelDocument.id}>
                        <span
                          data-tip={modelDocument.document_name}
                          data-for="document"
                          color="#337ab7"
                        >
                          {modelDocument.document_abbrev}
                        </span>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {checklistDocuments &&
                  checklistDocuments.map(checklistDocument => (
                    <tr key={checklistDocument.parameter_id}>
                      <th className="done">
                        {checklistDocument.done === 0 && (
                          <FaThumbsDown color="#E53935" />
                        )}
                        {checklistDocument.done === 1 && (
                          <FaThumbsUp color="#006229" />
                        )}
                      </th>
                      <th
                        className="client"
                        data-tip={checklistDocument.nickname}
                        data-for="client"
                      >
                        {checklistDocument.nickname}
                      </th>
                      {checklistDocument.documents &&
                        checklistDocument.documents.map(document => (
                          <DocumentTd
                            key={document.id}
                            situation={document.situation}
                            data-tip={
                              document.user_name
                                ? `${document.user_name} <br/> ${document.marked_date}`
                                : ''
                            }
                            data-for="mark"
                            onClick={() =>
                              confirmMarkDocument(
                                document.id,
                                document.type,
                                document.allow_manual_mark_on_checklist,
                                document.situation,
                                document.document_name,
                                checklistDocument.client_name
                              )
                            }
                          >
                            {document.situation === 0 && (
                              <FaMinus
                                className="opacity"
                                size={18}
                                color="#e1e1e1"
                              />
                            )}
                            {document.situation === 1 && (
                              <FaRegCircle
                                className="opacity"
                                size={18}
                                color="#9dd3fe"
                              />
                            )}
                            {document.situation === 2 && (
                              <FaCheckCircle size={18} color="#006229" />
                            )}
                            {document.situation === 3 && (
                              <FaBan size={18} color="#E53935" />
                            )}
                            {document.situation === 4 && (
                              <FaCheckCircle size={18} color="#01579B" />
                            )}
                          </DocumentTd>
                        ))}
                    </tr>
                  ))}
              </tbody>
            </TableContainer>
          </Content>
        )}
        {!loading && (
          <Subtitles>
            <span>{totalChecklist} registro(s)</span>
            <aside>
              <div>
                <FaMinus size={12} color="#e1e1e1" />
                <span>Desobrigado</span>
              </div>
              <div>
                <FaRegCircle size={12} color="#9dd3fe" />
                <span>Não realizado</span>
              </div>
              <div>
                <FaCheckCircle size={12} color="#006229" />
                <span>Realizado (via protocolo)</span>
              </div>
              <div>
                <FaCheckCircle size={12} color="#01579B" />
                <span>Realizado (marcado manualmente)</span>
              </div>
              <div>
                <FaBan size={12} color="#E53935" />
                <span>Inexistente</span>
              </div>
            </aside>
          </Subtitles>
        )}
      </Container>
      {toggleParameter && (
        <ParameterModal
          isOpen={toggleParameter}
          setIsOpen={handleToggleParameter}
        />
      )}
      {creatingChecklistLoading && (
        <CreatingChecklistMessage period={formattedPeriod} />
      )}
    </>
  );
};

export default List;

import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import produce from 'immer';
import {
  FaTimes,
  FaEdit,
  FaPlus,
  FaLayerGroup,
  FaTimesCircle,
  FaPrint,
  FaChevronRight,
  FaChevronLeft,
  FaEye,
  FaSearch,
  FaEraser,
  FaCheck,
  FaFolderPlus,
  FaThumbsUp,
  FaThumbsDown,
  FaFileDownload,
} from 'react-icons/fa';

import { Select, Input } from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import Pagination from '~/components/Pagination';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import { useAuth } from '~/hooks';
import api from '~/services/api';

import Modal from '~/components/Modal';

import CancellationReasonModal from '../../CancellationReasonModal';

import {
  Container,
  Header,
  Controls,
  Content,
  Filter,
  DetailsContainer,
  ProtocolInfo,
} from './styles';
import ProtocolGroup from './ProtocolGroup';
import ReceiverModal from '../../ReceiverModal';

const List = ({ isOpen, setIsOpen, setIsForm, setProtocolId }) => {
  const { user, company, companyUser, companyUsers } = useAuth();

  const filterRef = useRef(null);
  const [protocolForPrint, setProtocolForPrint] = useState(null);

  const [protocolsLoading, setProtocolsLoading] = useState(false);
  const [optionsClientLoading, setOptionsClientLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const [protocols, setProtocols] = useState([]);
  const [protocol, setProtocol] = useState(null);

  const [protocolIndex, setProtocolIndex] = useState(0);
  const [totalProtocols, setTotalProtocols] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [
    cancellationReasonModalIsOpen,
    setCancellationReasonModalIsOpen,
  ] = useState(false);

  const [usersOptions, setUsersOptions] = useState(null);
  const [clientsOptions, setClientsOptions] = useState(null);

  const situationOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 0, label: 'Gerado' },
      { value: 2, label: 'Entregue' },
      { value: 3, label: 'Impresso' },
      { value: 5, label: 'Cancelado' },
    ];
  }, []);

  const [selectedClientOption, setSelectedClientOption] = useState({
    value: '',
    label: 'Todos',
  });
  const [selectedUserOption, setSelectedUserOption] = useState({
    value: '',
    label: 'Todos',
  });
  const [selectedSituationOption, setSelectedSituationOption] = useState({
    value: '',
    label: 'Todos',
  });
  const [codSearch, setCodSearch] = useState('');

  useEffect(() => {
    (async () => {
      if (user && company) {
        try {
          setOptionsClientLoading(true);
          const response = await api.get(`/relationships`, {
            params: {
              company_id: company.id,
              selectOnly: true,
              type: 1,
            },
          });

          if (response.data.length > 0) {
            response.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });

            const options = response.data.map(item => ({
              value: item.id,
              label: item.name,
            }));

            options.unshift({
              value: '',
              label: 'Todos',
            });

            setClientsOptions(options);
          } else {
            toast.warn('Nenhum cliente foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setOptionsClientLoading(false);
        } catch {
          toast.error('Falha ao buscar clientes.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    })();
  }, [company, user]);

  useEffect(() => {
    if (companyUsers && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
      });

      options.push({
        value: '',
        label: 'Todos',
      });

      setUsersOptions(options);
    }
  }, [companyUsers, user]);

  useEffect(() => {
    async function loadProtocols() {
      try {
        setShowTable(true);
        setProtocolsLoading(true);

        const response = await api.get('/protocols', {
          params: {
            company_id: company.id,
            cod: codSearch,
            situation: selectedSituationOption.value,
            client_id: selectedClientOption.value,
            user_id: selectedUserOption.value,
            page: currentPage,
            type: 1,
          },
        });

        const { docs, total, pages } = response.data;

        if (docs.length > 0) {
          const data = docs.map(item => {
            const group = item.group.map(groupItem => ({
              ...groupItem,
              client:
                clientsOptions.find(
                  option => option.value === groupItem.client_id
                )?.label || '',
            }));

            return {
              ...item,
              created_by:
                companyUsers.find(
                  companyUserItem => companyUserItem.user_id === item.user_id
                )?.name || '',
              created_at: format(parseISO(item.created_at), 'dd/MM/yyyy'),
              delivered_date: item.delivered_date
                ? format(parseISO(item.delivered_date), 'dd/MM/yyyy')
                : null,
              group,
            };
          });

          setProtocols(data);
          setProtocol(data[0]);
          setTotalPages(pages);
          setTotalProtocols(total);
        } else {
          setProtocols([]);
          setProtocol([]);
          setTotalPages(1);
          setTotalProtocols(0);
          toast.warn('Nenhum protocolo foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setProtocolsLoading(false);
      } catch {
        toast.error('Falha ao buscar protocolos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setProtocolsLoading(false);
      }
    }

    if (!company || !companyUsers || !clientsOptions || !usersOptions) return;

    loadProtocols();
  }, [
    company,
    companyUsers,
    currentPage,
    codSearch,
    selectedSituationOption,
    selectedUserOption,
    selectedClientOption,
    clientsOptions,
    situationOptions,
    usersOptions,
  ]);

  const alterView = useCallback(() => {
    if (protocols.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, protocols.length]);

  const getDetails = useCallback(
    async (id, index) => {
      const protocolItem = protocols.find(item => item.id === id);

      setProtocol(protocolItem);
      setProtocolIndex(index);
      alterView();
    },
    [protocols, alterView]
  );

  const handleFilter = useCallback(
    data => {
      setCurrentPage(1);

      setSelectedUserOption(
        usersOptions.find(option => option.value === data.user)
      );

      setCodSearch(data.cod);

      setSelectedSituationOption(
        situationOptions.find(option => option.value === data.situation)
      );

      setSelectedClientOption(
        clientsOptions.find(option => option.value === data.client)
      );
    },
    [usersOptions, situationOptions, clientsOptions]
  );

  const resetFilter = useCallback(() => {
    if (user) {
      setCurrentPage(1);

      filterRef.current.setFieldValue('user', {
        value: user.id,
        label: user.short_name,
      });
      setSelectedUserOption({
        value: user.id,
        label: user.short_name,
      });

      filterRef.current.clearField('cod');
      setCodSearch('');

      filterRef.current.setFieldValue('situation', situationOptions[0]);
      setSelectedSituationOption(situationOptions[0]);

      filterRef.current.setFieldValue('client', clientsOptions[0]);
      setSelectedClientOption(clientsOptions[0]);
    }
  }, [filterRef, user, clientsOptions, situationOptions]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  const handlePrevItem = useCallback(async () => {
    if (protocolIndex !== 0) {
      setProtocolIndex(protocolIndex - 1);
      setProtocol(protocols[protocolIndex - 1]);
    } else {
      setProtocolIndex(protocols.length - 1);
      setProtocol(protocols[protocols.length - 1]);
    }
  }, [protocolIndex, protocols]);

  const handleNextItem = useCallback(async () => {
    if (protocolIndex !== protocols.length - 1) {
      setProtocolIndex(protocolIndex + 1);
      setProtocol(protocols[protocolIndex + 1]);
    } else {
      setProtocolIndex(0);
      setProtocol(protocols[0]);
    }
  }, [protocolIndex, protocols]);

  const handleCancelProtocol = useCallback(
    async reason => {
      try {
        setCancellationReasonModalIsOpen(false);
        setLoading(true);

        await api.put(`/protocols/${protocol.id}`, {
          situation: 5,
          cancellation_reason: reason,
        });

        setProtocols(
          produce(protocols, draft => {
            draft[protocolIndex].situation = 5;
            draft[protocolIndex].cancellation_reason = reason;
          })
        );

        setProtocol(item => ({
          ...item,
          situation: 5,
          cancellation_reason: reason,
        }));

        toast.success('Protocolo cancelado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      } catch {
        toast.error('Falha ao cancelar protocolo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    },
    [protocols, protocolIndex, protocol]
  );

  const handleEdit = useCallback(() => {
    if ((user, companyUser)) {
      if (protocol.situation === 0 || protocol.situation === 3) {
        if (protocol.user_id) {
          if (protocol.user_id !== user.id && companyUser.level < 9) {
            toast.warn('Somente o criador do protocolo pode editá-lo.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            setProtocolId(protocol.id);
            setIsForm();
          }
        } else if (companyUser.level === 9) {
          setProtocolId(protocol.id);
          setIsForm();
        } else {
          toast.warn('Somente o criador do protocolo pode editá-lo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } else {
        toast.warn('Não é possível editar pois o protocol já foi enviado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [protocol, user, companyUser, setIsForm, setProtocolId]);

  const handlePrint = useCallback(
    async protocolId => {
      try {
        const response = await api.get(`/protocols/group/${protocolId}`, {
          params: {
            pdf: true,
          },
        });

        const index = protocols.map(item => item.id).indexOf(protocolId);

        if (protocols[index].situation !== 2) {
          setProtocols(
            produce(protocols, draft => {
              draft[index].situation = 3;

              draft[index].group = draft[index].group.map(item => ({
                ...item,
                situation: 3,
              }));
            })
          );

          setProtocol(item => ({
            ...item,
            situation: 3,
            group: item.group.map(groupItem => ({
              ...groupItem,
              situation: 3,
            })),
          }));
        }

        const { data } = response;

        data.created_by =
          companyUsers.find(
            companyUserItem => companyUserItem.user_id === data.user_id
          )?.name || '';

        if (data.delivered_date) {
          data.delivered_date = format(
            parseISO(data.delivered_date),
            'dd/MM/yyyy'
          );
        }

        data.group = data.group.map(protocolItem => ({
          ...protocolItem,
          documents: protocolItem.documents.map(doc => ({
            ...doc,
            deadline_formatted: doc.deadline_date
              ? format(parseISO(doc.deadline_date), 'dd/MM/yyyy')
              : '',
          })),
        }));

        setProtocolForPrint(data);

        window.print();
      } catch {
        setProtocolForPrint(null);
        toast.error('Falha ao imprimir.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [companyUsers, protocols]
  );

  const confirmPrint = useCallback(
    protocolId => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => handlePrint(protocolId)}
              onClose={onClose}
              message="Deseja imprimir o protocolo? Esta ação não pode ser desfeita"
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handlePrint]
  );

  const [receiverModalIsOpen, setReceiverModalIsOpen] = useState(false);
  const handleWriteOff = useCallback(
    async (receiverName, receiverDate) => {
      if (receiverName.trim() !== '') {
        try {
          setReceiverModalIsOpen(false);

          await api.post('/protocols/document_views', {
            protocol_id: protocol.id,
            type: 1,
            name: receiverName,
            hasDownloaded: true,
            updated_at: receiverDate,
          });

          toast.success('Baixa realizada.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } catch {
          toast.error('Falha ao dar baixa.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setReceiverModalIsOpen(false);
        }
      }
    },
    [protocol]
  );

  return (
    <>
      <Modal
        isOpen={
          isOpen && !cancellationReasonModalIsOpen && !receiverModalIsOpen
        }
        setIsOpen={setIsOpen}
      >
        <Container>
          <Header>
            <div>
              <FaLayerGroup size={20} color="#44546a" />
              <h1>Protocolos em grupo</h1>
            </div>

            <aside>
              <button type="button" onClick={setIsOpen}>
                <FaTimes size={20} color="#44546a" />
              </button>
            </aside>
          </Header>

          <Controls>
            <button type="button" onClick={alterView}>
              <FaEye />
              <span>Visualização</span>
            </button>
            <button
              type="button"
              onClick={() => {
                setProtocolId(null);
                setIsForm();
              }}
            >
              <FaPlus />
              <span>Novo</span>
            </button>

            {showTable ? (
              <>
                <button type="button" onClick={resetFilter}>
                  <FaEraser />
                  <span>Limpar filtros</span>
                </button>
              </>
            ) : (
              <>
                <div>
                  <button type="button" onClick={handlePrevItem}>
                    <FaChevronLeft />
                  </button>

                  <span>
                    {protocolIndex + 1} de {protocols.length}
                  </span>

                  <button type="button" onClick={handleNextItem}>
                    <FaChevronRight />
                  </button>
                </div>

                {protocol.situation !== 5 && (
                  <>
                    <button type="button" onClick={handleEdit}>
                      <FaEdit />
                      <span>Editar</span>
                    </button>

                    <button
                      type="button"
                      onClick={() => confirmPrint(protocol.id)}
                    >
                      <FaPrint />
                      <span>Imprimir</span>
                    </button>

                    <button
                      type="button"
                      onClick={() => {
                        setCancellationReasonModalIsOpen(true);
                      }}
                    >
                      <FaTimesCircle />
                      <span>Cancelar</span>
                    </button>
                  </>
                )}

                {protocol.situation === 3 && (
                  <button
                    type="button"
                    onClick={() => setReceiverModalIsOpen(true)}
                  >
                    <FaFileDownload />
                    <span>Dar baixa</span>
                  </button>
                )}
              </>
            )}
          </Controls>

          {showTable && clientsOptions && usersOptions && (
            <Filter ref={filterRef} onSubmit={handleFilter}>
              <Input
                name="cod"
                className="cod"
                label="Cód."
                type="number"
                defaultValue={codSearch}
              />

              <Select
                label="Grupo"
                name="client"
                className="client"
                options={clientsOptions}
                defaultValue={selectedClientOption}
              />

              <Select
                label="Situação"
                name="situation"
                className="situation"
                options={situationOptions}
                defaultValue={selectedSituationOption}
              />

              <Select
                label="Usuário"
                name="user"
                className="user"
                options={usersOptions}
                defaultValue={selectedUserOption}
              />

              <button type="submit">
                <FaSearch />
              </button>
            </Filter>
          )}

          {protocolsLoading || optionsClientLoading ? (
            <TableLoading />
          ) : (
            <Content className="content" id="sua_div">
              {showTable ? (
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="thumb" />
                      <th className="cod">Cód.</th>
                      <th className="start_date">Data</th>
                      <th className="client">Grupo</th>
                      <th className="situation">Situação</th>
                      <th className="user">Gerado por</th>
                      <th className="recipient">Recebido por</th>
                      <th className="delivered_date">Entregue em</th>
                    </tr>
                  </thead>
                  <tbody>
                    {protocols.map((item, index) => (
                      <tr
                        key={index}
                        className="hover"
                        onClick={() => getDetails(item.id, index)}
                      >
                        <td className="thumb">
                          {item.situation !== 2 && item.situation !== 5 && (
                            <FaThumbsDown color="#E53935" />
                          )}
                          {item.situation === 2 && (
                            <FaThumbsUp color="#006229" />
                          )}
                          {item.situation === 5 && <FaTimes color="#E53935" />}
                        </td>
                        <td className="cod">{item.cod}</td>
                        <td className="start_date">{item.created_at}</td>
                        <td className="client">{item.client.name}</td>
                        <td className="situation">
                          {item.situation === 0 && (
                            <FaFolderPlus color="#01579B" />
                          )}
                          {item.situation === 2 && <FaCheck color="#00c853" />}
                          {item.situation === 3 && <FaPrint color="#44546a" />}
                          {item.situation === 5 && <FaTimes color="#e53935" />}
                          <span style={{ marginLeft: '5px' }}>
                            {
                              situationOptions.find(
                                situation => situation.value === item.situation
                              ).label
                            }
                          </span>
                        </td>
                        <td className="user">
                          {usersOptions.find(
                            option => option.value === item.user_id
                          )?.label || ''}
                        </td>
                        <td className="recipient">{item.receiver}</td>
                        <td className="delivered_date">
                          {item.delivered_date}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableContainer>
              ) : (
                <DetailsContainer>
                  {protocol && (
                    <>
                      <ProtocolInfo>
                        <h4>PROTOCOLO</h4>
                        <section>
                          <div className="cod">
                            <label>Cód.</label>
                            <input
                              name="cod"
                              value={protocol.cod || ''}
                              readOnly
                            />
                          </div>
                          <div className="date">
                            <label>Data de início</label>
                            <input
                              name="start_date"
                              value={protocol.created_at || ''}
                              readOnly
                            />
                          </div>

                          <div className="client">
                            <label>Grupo</label>
                            <input
                              name="client"
                              value={protocol.client.name || ''}
                              readOnly
                            />
                          </div>

                          <div className="situation">
                            <label>Situação</label>
                            <input
                              name="situation"
                              value={
                                situationOptions.find(
                                  situation =>
                                    situation.value === protocol.situation
                                ).label || ''
                              }
                              readOnly
                            />
                          </div>

                          {protocol.situation === 5 && (
                            <div className="cancel">
                              <label>Motivo</label>
                              <input
                                name="cancelation_reason"
                                value={
                                  protocol.cancellation_reason ||
                                  'Motivo não esclarecido'
                                }
                                readOnly
                              />
                            </div>
                          )}
                        </section>

                        <TableContainer>
                          <thead>
                            <tr>
                              <th className="cod">Cód.</th>
                              <th className="client">Cliente</th>
                              <th className="situation">Situação</th>
                              <th className="user">Gerado por</th>
                            </tr>
                          </thead>
                          <tbody>
                            {protocol.group.map((item, index) => (
                              <tr key={index} className="hover">
                                <td className="cod">{item.cod || ''}</td>
                                <td className="client">{item.client || ''}</td>
                                <td className="situation">
                                  {item.situation === 0 && (
                                    <FaFolderPlus color="#01579B" />
                                  )}
                                  {item.situation === 2 && (
                                    <FaCheck color="#00c853" />
                                  )}
                                  {item.situation === 3 && (
                                    <FaPrint color="#44546a" />
                                  )}
                                  {item.situation === 4 && (
                                    <FaPrint color="#44546a" />
                                  )}
                                  <span style={{ marginLeft: '5px' }}>
                                    {situationOptions.find(
                                      situation =>
                                        situation.value === item.situation
                                    )?.label || ''}
                                  </span>
                                </td>
                                <td className="user">
                                  {usersOptions.find(
                                    option => option.value === item.user_id
                                  )?.label || ''}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </TableContainer>
                      </ProtocolInfo>
                    </>
                  )}
                </DetailsContainer>
              )}
            </Content>
          )}
          <Pagination
            loading={protocolsLoading ? 1 : 0}
            currentPage={currentPage}
            pages={totalPages}
            totalDocs={totalProtocols}
            handlePage={handlePage}
          />
        </Container>
      </Modal>

      {company && protocolForPrint && (
        <ProtocolGroup company={company} protocol={protocolForPrint} />
      )}

      {loading && <Loading />}

      {cancellationReasonModalIsOpen && (
        <CancellationReasonModal
          setIsOpen={() => setCancellationReasonModalIsOpen(false)}
          handleCancel={handleCancelProtocol}
        />
      )}

      {receiverModalIsOpen && (
        <ReceiverModal
          setIsOpen={() => setReceiverModalIsOpen(false)}
          handleWriteOff={handleWriteOff}
          protocolCod={protocol.cod}
          client={protocol.client.name}
          method={1}
        />
      )}
    </>
  );
};

export default List;

import React, { useRef, useState } from 'react';
import { FaEdit, FaPaperclip, FaTimes, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';

import * as Yup from 'yup';
import ClearBackground from '~/components/ClearBackground';
import { FileInput, Input, InputMask } from '~/components/Form';

import {
  Container,
  Content,
  Left,
  Right,
  Header,
  FormContainer,
  UploadFile,
  Delete,
  FileName,
  File,
} from './styles';

const ConcludePhaseModal = ({
  setIsOpen,
  onClick,
  type,
  variables,
  info,
  notification,
  setTest,
}) => {
  const formRef = useRef(null);

  const [uploadFile, setUploadFile] = useState({});

  const select = () => {
    setTest(!notification);
  };

  const submitForm = async data => {
    try {
      const schema = Yup.object().shape({
        competence: Yup.string()
          .typeError('O vencimento é obrigatório')
          .required('O vencimento é obrigatório'),
        file_name: Yup.string().required('O arquivo é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const documentData = {
        competence: data.competence,
        uploadFile,
      };

      setIsOpen();

      if (type === 'schedule') {
        onClick(true, documentData);
      }

      if (type === 'process') {
        const { indexProcess, indexPhase, situation } = variables;
        onClick(indexProcess, indexPhase, situation, documentData);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        toast.error('Falha ao salvar processo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  return (
    <>
      <ClearBackground />
      <Container>
        <Content>
          <Left>
            <FaEdit size={38} color="#01579b" />
          </Left>
          <Right>
            <Header>
              <FaTimes onClick={setIsOpen} size={14} color="#e53935" />
              <strong>Anexe o documento para concluir</strong>
            </Header>

            {info && (
              <span style={{ color: '#545454' }}>
                {info.client} <br />
                Anexe <strong>{info.document}</strong> para concluir o processo.
              </span>
            )}

            <FormContainer ref={formRef} onSubmit={submitForm}>
              <InputMask
                name="competence"
                className="date"
                label="Competência"
                mask="99/9999"
              />
              <UploadFile>
                {uploadFile.name ? (
                  <>
                    <Delete>
                      <button type="button" onClick={() => setUploadFile({})}>
                        <FaTrash size={14} />
                      </button>
                    </Delete>
                    <FileName>
                      <Input
                        name="file_name"
                        className="file"
                        label="Arquivo"
                        type="text"
                        value={uploadFile.name}
                        disabled
                      />
                    </FileName>
                  </>
                ) : (
                  <File>
                    <label htmlFor="file_name">
                      <FaPaperclip size={14} color="#FCFCFC" />
                    </label>
                    <FileInput
                      id="file_name"
                      name="file_name"
                      onChange={e => setUploadFile(e.target.files[0])}
                    />
                  </File>
                )}
              </UploadFile>

              <div className="body">
                <UploadFile>
                  {uploadFile.name ? (
                    <>
                      <Delete>
                        <button type="button" onClick={() => setUploadFile({})}>
                          <FaTrash size={14} />
                        </button>
                      </Delete>
                      <FileName>
                        <Input
                          name="file_name"
                          className="file"
                          label="Arquivo"
                          type="text"
                          value={uploadFile.name}
                          disabled
                        />
                      </FileName>
                    </>
                  ) : (
                    <File>
                      <label htmlFor="file_name">
                        <FaPaperclip size={14} color="#FCFCFC" />
                      </label>
                      <FileInput
                        id="file_name"
                        name="file_name"
                        onChange={e => setUploadFile(e.target.files[0])}
                      />
                    </File>
                  )}
                </UploadFile>

                <div className="dpt_labour">
                  <input
                    name="dpt_labour"
                    checked={notification}
                    type="checkbox"
                    readOnly
                    onClick={() => select()}
                  />
                  <label>
                    <span style={{ color: '#545454' }}>Notificar cliente</span>
                  </label>
                </div>
              </div>

              <button type="submit">Concluir</button>
            </FormContainer>
          </Right>
        </Content>
      </Container>
    </>
  );
};

export default ConcludePhaseModal;

import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    position: relative;
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    > div {
      & + div {
        margin-left: 16px;
      }
    }
  }

  .loading {
    position: absolute;
    z-index: 2;
  }

  .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  button {
    background: transparent;
    border: none;
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  form {
    flex: 1;
  }
`;

export const Document = styled.div`
  display: flex;
  flex-direction: column;

  div * label {
    opacity: 0.4;
  }

  .first-section {
    .description {
      width: 60%;
    }

    .abbreviation,
    .document_type {
      width: 12%;
    }

    .folder {
      width: 16%;
    }
  }

  .second-section {
    .territorial_scope,
    .state,
    .city {
      width: 33%;
    }
  }

  .third-section {
    .periodicity_type,
    .due_date_type,
    .date_type,
    .specific_day_month,
    .specific_day,
    .date_calculation {
      width: 33%;
    }
  }

  .fourth-section {
    .start_date,
    .end_date,
    .deadline_days {
      width: 33%;
    }
  }

  .fifth-section {
    flex-direction: column;
    align-items: flex-start;

    .title_to_pay,
    .main_document,
    .use_in_checklist,
    .allow_manual_mark_on_checklist,
    .auto_upload,
    .show_in_labour_checklist_if_has_employee,
    .skip_charge_inactive_clients {
      width: 100%;
      margin-left: 0px;

      input {
        height: 30px;
      }
    }
  }
`;

export const Department = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .dpt_labour,
  .dpt_tax,
  .dpt_accounting,
  .dpt_financial,
  .dpt_admin,
  .dpt_bpo {
    width: 15%;

    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    > input {
      margin-right: 16px;
      height: 30px;
      transform: scale(1.5);
    }
  }
`;

export const Taxation = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  section {
    flex-wrap: wrap;

    input {
      height: 30px;
    }

    .taxation_empregador_pf,
    .taxation_empregador_domestico,
    .taxation_mei,
    .taxation_mei_somente_declaracoes,
    .taxation_simples_com_inscricao,
    .taxation_simples_sem_inscricao {
      width: calc(20% - 16px);
    }

    .taxation_simples_sem_inscricao {
      margin-left: 0;
    }

    .taxation_lucro_presumido,
    .taxation_lucro_real,
    .taxation_condominio,
    .taxation_terceiro {
      width: calc(20% - 16px);
    }
  }
`;

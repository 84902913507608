import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react';
import {
  FaEraser,
  FaFileAlt,
  FaFolder,
  FaLayerGroup,
  FaLowVision,
  FaSearch,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import produce from 'immer';
import { DatePicker, Input, Select } from '~/components/Form';
import { usePortalAuth } from '~/hooks';
import api from '~/services/api';
import { TableLoading } from '~/components/Table';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Document,
  Header,
  Content,
  Filter,
  InputContainer,
} from './styles';

const Main = ({ selectedButton }) => {
  const { user, companyId, folders } = usePortalAuth();
  const [protocolDocuments, setProtocolDocuments] = useState([]);
  const [documentsOptions, setDocumentsOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [loading, setLoading] = useState(true);
  const formRef = useRef();

  const clientsOptions = useMemo(() => {
    if (!user || Object.keys(user).length === 0) {
      return null;
    }

    const options = [];
    user.relations.forEach(item => {
      if (user.main_client === item.client.id) {
        options.unshift({
          value: item.client.id,
          label: item.client.name,
        });
      } else {
        options.push({
          value: item.client.id,
          label: item.client.name,
        });
      }
    });

    options.unshift({ value: '', label: 'Todos' });

    setSelectedClient(options[1]);

    return options;
  }, [user]);

  const loadProtocolDocuments = useCallback(
    async (data = {}) => {
      if (!clientsOptions) return;
      try {
        setLoading(true);

        const {
          client = clientsOptions[1].value,
          start = null,
          end = null,
          document = null,
          minPrice = null,
          maxPrice = null,
        } = data;

        const response = await api.get(`/portal/protocol-documents`, {
          params: {
            company_id: companyId,
            hasNotDownloaded: selectedButton === 0 ? true : '',
            client_id: client,
            start,
            end,
            document,
            minPrice,
            maxPrice,
            folder: selectedButton > 1 ? folders[selectedButton - 4].id : '',
          },
        });

        if (response.data.length === 0) {
          toast.warn('Nenhum documento encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setProtocolDocuments(response.data);
      } catch {
        toast.error('Falha ao carregar os documentos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      setLoading(false);
    },
    [companyId, selectedButton, folders, clientsOptions]
  );

  useEffect(() => {
    if (!companyId) return;

    (async () => {
      try {
        const response = await api.get(`/portal/documents/${companyId}`);

        const options = response.data.map(item => ({
          value: item.document.id,
          label: item.document.description,
        }));

        options.unshift({ value: '', label: 'Todos' });

        setDocumentsOptions(options);
      } catch {
        toast.error('Falha ao carregar as opções de documentos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    })();
    loadProtocolDocuments();
  }, [companyId, loadProtocolDocuments]);

  const openFile = useCallback(
    async (index, document_view_id, blobName) => {
      try {
        await api.put(`portal/downloaded/${document_view_id}`);

        setProtocolDocuments(
          produce(protocolDocuments, draft => {
            draft[index].hasDownloaded = true;
          })
        );

        const response = await api.get('portal/download-file', {
          params: {
            blobName,
          },
          responseType: 'blob',
        });

        const fileURL = URL.createObjectURL(response.data);

        window.open(fileURL, '_blank');
      } catch {
        toast.error('Falha ao baixar documento.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [protocolDocuments]
  );

  const confirmOpenFile = useCallback(
    (index, document_view_id, blobName) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => openFile(index, document_view_id, blobName)}
              onClose={onClose}
              message="Ao baixar esse documento você não será avisado futuramente sobre seu vencimento."
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [openFile]
  );

  const changeClient = useCallback(
    ({ value, label }) => {
      loadProtocolDocuments({
        ...formRef.current.getData(),
        client: value,
      });
      setSelectedClient({ value, label });
    },
    [loadProtocolDocuments]
  );

  const resetFilter = () => {
    formRef.current.clearField('start');
    formRef.current.clearField('end');
    formRef.current.clearField('minPrice');
    formRef.current.clearField('maxPrice');
    formRef.current.clearField('document');
    loadProtocolDocuments({ client: selectedClient.value });
  };

  return (
    <Container>
      <Header>
        <div>
          {selectedButton === 0 ? (
            <>
              <FaLowVision />
              <h1>Não baixados</h1>
            </>
          ) : selectedButton === 1 ? (
            <>
              <FaLayerGroup />
              <h1>Todos</h1>
            </>
          ) : (
            <>
              <FaFolder />
              <h1>{folders[selectedButton - 4].description}</h1>
            </>
          )}
        </div>
        <span>{selectedClient.label}</span>
      </Header>
      {!clientsOptions ? (
        <div style={{ width: '100%' }}>
          <TableLoading />
        </div>
      ) : (
        <>
          <Filter onSubmit={loadProtocolDocuments} ref={formRef}>
            <button type="button" onClick={resetFilter}>
              <FaEraser />
              <span>Limpar Filtro</span>
            </button>

            <button type="submit">
              <FaSearch />
              <span>Pesquisar</span>
            </button>

            <InputContainer className="price">
              <label>Valor</label>
              <div>
                <Input name="minPrice" type="number" step=".01" />
                <span>até</span>
                <Input name="maxPrice" type="number" step=".01" />
              </div>
            </InputContainer>

            <Select
              label="Documento"
              name="document"
              className="document"
              options={documentsOptions}
              defaultValue={{ value: '', label: 'Todos' }}
            />

            <InputContainer className="period">
              <label>Vencimento</label>
              <div>
                <DatePicker name="start" />
                <span>até</span>
                <DatePicker name="end" />
              </div>
            </InputContainer>

            <Select
              label="Empresa"
              name="client"
              className="client"
              options={clientsOptions}
              defaultValue={clientsOptions[1]}
              onChange={changeClient}
            />
          </Filter>
          <Content className="content">
            {loading ? (
              <div style={{ width: '100%' }}>
                <TableLoading />
              </div>
            ) : (
              protocolDocuments.map((item, index) => (
                <Document key={item.id}>
                  <section>
                    <div className="document">
                      <FaFileAlt size="60" />
                    </div>
                    <section>
                      <strong
                        className={
                          item.hasDownloaded ? 'downloaded' : 'not-downloaded'
                        }
                      >
                        {item.hasDownloaded ? 'Baixado' : 'Não baixado'}
                      </strong>
                    </section>
                  </section>

                  <div className="info">
                    <section>
                      <strong style={{ color: '#01579B' }}>
                        {clientsOptions.find(
                          client => client.value === item.clientId
                        )?.label || ''}
                      </strong>
                    </section>

                    <section>
                      <strong>{item.documentDescription}</strong>
                    </section>

                    <section>
                      <span>
                        {item.competence} - {item.price}
                      </span>
                    </section>

                    <section>
                      <strong>Vencimento: {item.deadlineDate}</strong>
                    </section>

                    <section>
                      <span>{item.obs}</span>
                    </section>

                    <button
                      type="button"
                      onClick={() => {
                        if (item.isTitleToPay && !item.hasDownloaded) {
                          confirmOpenFile(index, item.idView, item.fileUrl);
                        } else {
                          openFile(index, item.idView, item.fileUrl);
                        }
                      }}
                    >
                      Baixar
                    </button>
                  </div>
                </Document>
              ))
            )}
          </Content>
        </>
      )}
    </Container>
  );
};

export default Main;

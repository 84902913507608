import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { usePortalAuth } from '~/hooks';

const PortalRoute = ({ component: Component, path, ...rest }) => {
  const { isLogged } = usePortalAuth();

  if (!isLogged()) {
    return <Redirect to="/portal/signin" />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default PortalRoute;

import React, { useState } from 'react';
import ProtocolGroupList from './List';
import ProtocolGroupForm from './Form';

const ProtocolGroupModal = ({ isOpen, setIsOpen }) => {
  const [isForm, setIsForm] = useState(false);
  const [protocolId, setProtocolId] = useState(null);

  if (isForm) {
    return (
      <ProtocolGroupForm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setIsForm={() => setIsForm(false)}
        id={protocolId}
      />
    );
  }

  return (
    <ProtocolGroupList
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setIsForm={() => setIsForm(true)}
      setProtocolId={setProtocolId}
    />
  );
};

export default ProtocolGroupModal;

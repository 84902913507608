import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { confirmAlert } from 'react-confirm-alert';
import {
  FaTable,
  FaTimes,
  FaEye,
  FaChevronLeft,
  FaChevronRight,
  FaEraser,
  FaSave,
  FaCheck,
} from 'react-icons/fa';

import { TableLoading, TableContainer } from '~/components/Table';
import Loading from '~/components/Loading';
// import ConfirmWindow from '~/components/ConfirmWindow';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import {
  Container,
  Header,
  Controls,
  Content,
  DetailsContainer,
  ModelInfo,
} from './styles';

const List = () => {
  const { company } = useAuth();

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const [loading, setLoading] = useState(true);
  const [stateFilter, setStateFilter] = useState(false);

  const [quantidade, setQuantidade] = useState(10);
  const tamanhoFilter = ['Todos', 10, 100, 1000, 10000];
  const [order, setOrdem] = useState(1);
  const OrdemFilter = ['Decrescente', 'Crescente'];
  const [companyFilter, setCompanyFilter] = useState(
    'Digite o nome da Empresa'
  );
  const [yearFilter, setYearFilter] = useState('Digite o ano');
  const [year, setYear] = useState(new Date().getFullYear());
  const [modelIndex, setModelIndex] = useState(year);
  const [tableEdit, setTableEdit] = useState(true);

  const [arrayQuery, setArrayQuery] = useState({});

  const [dataCompany, setDataCompany] = useState({});

  const [dataCompanyModifications, setDataCompanyModifications] = useState({});

  useEffect(() => {
    async function loadModels() {
      if (company) {
        try {
          setLoading(true);

          const response = await api.get('revenue', {
            params: {
              page: 1,
              length: quantidade,
              year,
              order,
            },
          });

          setArrayQuery(response.data.docs);
          setLoading(false);
        } catch (err) {
          toast.error('Falha ao buscar modelos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      }
    }

    loadModels();
  }, [company, year, quantidade, order]);

  function alterModelIndex(index) {
    if (index >= 0) {
      setModelIndex(index);
    }
  }

  const handleEmpresaFocus = () => {
    if (companyFilter === 'Digite o nome da Empresa') {
      setCompanyFilter('');
    }
  };
  const handleEmpresaBlur = () => {
    if (companyFilter === '') {
      setCompanyFilter('Digite o nome da Empresa');
    }
  };
  const handleYearFocus = () => {
    if (yearFilter === 'Digite o ano') {
      setYearFilter('');
    }
  };
  const handleYearBlur = () => {
    if (yearFilter === '') {
      setYearFilter('Digite o ano');
    }
  };

  const handleQuantidadeChange = event => {
    setQuantidade(event.target.value);
  };
  const handleOrdemChange = event => {
    setOrdem(event.target.value);
  };

  const alterFilter = () => {
    setStateFilter(!stateFilter);

    setQuantidade(1);
    setOrdem(1);
    setCompanyFilter('Digite o nome da Empresa');
    setYearFilter('Digite o ano');
  };
  const applyFilters = async () => {
    setLoading(true);

    setYearFilter(Number.isInteger(yearFilter) ? yearFilter : year);

    const response = await api.get('revenue', {
      params: {
        page: 1,
        length: quantidade,
        year: Number.isInteger(yearFilter) ? yearFilter : year,
        order,
      },
    });

    setYear(Number.isInteger(yearFilter) ? yearFilter : year);

    setArrayQuery(response.data.docs);
    setLoading(false);
  };

  async function handleRowClick(item) {
    // query com o id do item atras dos dados
    setLoading(true);

    const response = await api.get(`revenue/${item.id}`, {
      params: {
        year,
      },
    });

    setDataCompany(response.data);
    setDataCompanyModifications(response.data);
    setLoading(false);

    setTableEdit(false);
  }

  async function value() {
    if (tableEdit === true) {
      setLoading(true);

      const response = await api.get(`revenue/${arrayQuery[0].id}`, {
        params: {
          year,
        },
      });

      setDataCompany(response.data);
      setDataCompanyModifications(response.data);

      setLoading(false);

      setTableEdit(false);
    } else {
      setLoading(true);

      const response = await api.get('revenue', {
        params: {
          page: 1,
          length: quantidade,
          year,
          order,
        },
      });

      setArrayQuery(response.data.docs);

      setLoading(false);

      setTableEdit(true);

      setDataCompany({});
      setDataCompanyModifications({});
    }
  }

  const convertJsonToCsv = jsonData => {
    const csvData = [];
    csvData.push(
      [
        'id',
        'nome',
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
      ].join(',')
    );

    // Adiciona as linhas de dados
    jsonData.forEach(item => {
      const { id } = item;
      const nome = item.name;
      const monthsValues = Object.values(item.month);

      const rowData = [id, nome, ...monthsValues];

      csvData.push(rowData.join(','));
    });

    // Converte o array de dados para uma string CSV
    const csvString = csvData.join('\n');
    return csvString;
  };

  function saveModifications() {
    dataCompanyModifications.month.forEach(async (item, index) => {
      if (item !== dataCompany.month[index]) {
        const update = {
          id_accountant: dataCompany.id_accountant,

          year: parseInt(dataCompany.year, 10),
          month: index + 1,

          without_billing: item.without_billing,
          cash_sales: item.cash_sales,
          forward_sales: item.forward_sales,
          turnover: item.turnover,
          billing: item.billing,
          overheads: item.overheads,
          the_payroll: item.the_payroll,
        };
        const response = await api.put(`revenue/${dataCompany.id}`, update);

        if (response.status === 204) {
          toast.success('Alterações salvas com sucesso!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    });
  }

  function exportTable() {
    const csvData = convertJsonToCsv(arrayQuery);

    // Cria um objeto Blob com o CSV e cria um link de download
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const [comment, setComment] = useState('Observação');

  const handleCommentFocus = () => {
    if (comment === 'Observação') {
      setComment('');
    }
  };
  const handleCommentBlur = () => {
    if (comment === '') {
      setComment('Observação');
    }
  };

  function setModifications(month, key, newValue) {
    setDataCompanyModifications(prevState => {
      // Crie um novo array revenues
      const newRevenues = prevState.month.map((revenue, index) => {
        // Se o mês do objeto atual corresponder ao mês que você deseja atualizar,
        // retorne um novo objeto com o dado atualizado
        if (index === month) {
          return { ...revenue, [key]: newValue };
        }

        // Caso contrário, retorne o objeto atual
        return revenue;
      });

      // Retorne o novo estado com o array revenues atualizado
      return { ...prevState, month: newRevenues };
    });
  }

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaTable size={20} color="#44546a" />
            <h1>Rotinas Tributárias - Faturamento</h1>
          </div>

          <Link to="/">
            <FaTimes size={20} color="#44546a" />
          </Link>
        </Header>

        <Controls>
          <button type="button" onClick={value}>
            <FaEye />
            <span>Lista</span>
          </button>

          {!tableEdit ? (
            <button type="button" onClick={saveModifications}>
              <FaSave />
              <span>Salvar</span>
            </button>
          ) : (
            <>
              <button type="button" onClick={alterFilter}>
                {stateFilter ? <FaTimes /> : <FaEraser />}
                <span>{stateFilter ? 'Limpar e fexar' : 'Filtros'}</span>
              </button>
              {stateFilter ? (
                <>
                  <button type="button" onClick={applyFilters}>
                    <FaCheck />
                    <span>Aplicar</span>
                  </button>
                  <input
                    type="text"
                    value={companyFilter}
                    onFocus={handleEmpresaFocus}
                    onBlur={handleEmpresaBlur}
                    onChange={e => setCompanyFilter(e.target.value)}
                  />
                  <input
                    type="text"
                    value={yearFilter}
                    onFocus={handleYearFocus}
                    onBlur={handleYearBlur}
                    onChange={e => setYearFilter(e.target.value)}
                  />
                  <select value={quantidade} onChange={handleQuantidadeChange}>
                    {tamanhoFilter.map(values => (
                      <option key={values} value={values}>
                        {values}
                      </option>
                    ))}
                  </select>
                  <select value={order} onChange={handleOrdemChange}>
                    {OrdemFilter.map((values, index) => (
                      <option key={index} value={index}>
                        {values}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {!tableEdit ? (
            <></>
          ) : (
            <>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <button
                  type="button"
                  onClick={async () => {
                    alterModelIndex(modelIndex - 1);
                    setLoading(true);

                    setYearFilter(modelIndex - 1);

                    const response = await api.get('revenue', {
                      params: {
                        page: 1,
                        length: quantidade,
                        year: modelIndex - 1,
                        order,
                      },
                    });

                    setYear(modelIndex - 1);

                    setArrayQuery(response.data.docs);
                    setLoading(false);
                  }}
                >
                  <FaChevronLeft />
                </button>

                <span>{modelIndex}</span>

                <button
                  type="button"
                  onClick={async () => {
                    if (modelIndex < new Date().getFullYear()) {
                      alterModelIndex(modelIndex + 1);
                      setLoading(true);

                      setYearFilter(modelIndex + 1);

                      const response = await api.get('revenue', {
                        params: {
                          page: 1,
                          length: quantidade,
                          year: modelIndex + 1,
                          order,
                        },
                      });

                      setYear(modelIndex + 1);

                      setArrayQuery(response.data.docs);
                      setLoading(false);
                    }
                  }}
                >
                  <FaChevronRight />
                </button>
              </div>
              <button type="button" onClick={exportTable}>
                <FaSave />
                <span>Importar Planilha</span>
              </button>
            </>
          )}
        </Controls>

        {loading || !company ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {tableEdit ? (
              <TableContainer>
                <thead>
                  <tr>
                    <th className="description">Empresa</th>
                    {[...Array(12).keys()].map(values => (
                      <th className="description">
                        {(values + 1).toString().padStart(2, '0')}/{year}
                      </th>
                    ))}
                    <th className="description" />
                  </tr>
                </thead>
                <tbody>
                  {arrayQuery &&
                    arrayQuery.map(item => (
                      <tr
                        key={item.id}
                        className="hover"
                        onClick={() => handleRowClick(item)}
                      >
                        <td className="description">{item.name}</td>
                        {Object.values(item.month).map((month, index) => (
                          <td key={index} className="description">
                            {month}
                          </td>
                        ))}
                        <td className="description" />
                      </tr>
                    ))}
                </tbody>
              </TableContainer>
            ) : (
              <DetailsContainer>
                <ModelInfo>
                  <h4>EMPRESA</h4>
                  <h3>{dataCompanyModifications.name}</h3>
                  <section>
                    <div className="description">
                      <input
                        type="text"
                        value={comment}
                        onFocus={handleCommentFocus}
                        onBlur={handleCommentBlur}
                        onChange={e => setComment(e.target.value)}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        type="button"
                        onClick={async () => {
                          setYear(year - 1);
                          setLoading(true);

                          const response = await api.get(
                            `revenue/${dataCompanyModifications.id}`,
                            {
                              params: {
                                year: year - 1,
                              },
                            }
                          );

                          setDataCompany(response.data);
                          setDataCompanyModifications(response.data);
                          setLoading(false);

                          setYear(year - 1);

                          setTableEdit(false);
                        }}
                      >
                        <FaChevronLeft />
                      </button>

                      <span style={{ margin: '0 10px' }}>{year}</span>

                      <button
                        type="button"
                        onClick={async () => {
                          if (year < new Date().getFullYear()) {
                            setLoading(true);

                            const response = await api.get(
                              `revenue/${dataCompanyModifications.id}`,
                              {
                                params: {
                                  year: year + 1,
                                },
                              }
                            );

                            setDataCompany(response.data);
                            setDataCompanyModifications(response.data);
                            setLoading(false);

                            setYear(year + 1);

                            setTableEdit(false);
                          }
                        }}
                      >
                        <FaChevronRight />
                      </button>
                    </div>
                  </section>
                </ModelInfo>
                <TableContainer>
                  <h4>RECEITAS</h4>
                  <table
                    style={{
                      width: '70%',
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Mês</th>
                        <th />
                        <th>Vendas à vista</th>
                        <th>Vendas a prazo</th>
                        <th>Vendas Total</th>
                        <th>Compras</th>
                        <th>Despesas Gerais</th>
                        <th>Folha de Pagamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataCompany &&
                        dataCompanyModifications.month.map((revenue, index) => (
                          <tr key={index}>
                            {/* Mês */}
                            <td>{monthNames[parseInt(index, 10)]}</td>
                            {/* [ ] Sem Faturamento */}
                            <td>
                              <label
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={revenue.without_billing === true}
                                  onChange={() => {
                                    setModifications(
                                      index,
                                      'without_billing',
                                      !revenue.without_billing
                                    );
                                  }}
                                />
                                <span style={{ marginLeft: '8px' }}>
                                  Sem Faturamento
                                </span>
                              </label>
                            </td>
                            {/* Vendas à vista */}
                            <td>
                              <input
                                type="text"
                                value={revenue.cash_sales}
                                onChange={e => {
                                  if (!isNaN(e.target.value)) {
                                    let newValue = e.target.value;
                                    if (
                                      (revenue.cash_sales === '0' ||
                                        revenue.cash_sales === 0) &&
                                      newValue !== '0.' &&
                                      newValue !== ''
                                    ) {
                                      newValue = e.target.value.replace(
                                        /^0+/,
                                        ''
                                      );
                                    } else if (newValue === '0.') {
                                      newValue = '0.';
                                    } else if (newValue === '') {
                                      newValue = '0';
                                    }
                                    setModifications(
                                      index,
                                      'cash_sales',
                                      newValue
                                    );
                                  }
                                }}
                              />
                            </td>
                            {/* Vendas a prazo */}
                            <td>
                              <input
                                type="text"
                                value={revenue.forward_sales}
                                onChange={e => {
                                  if (!isNaN(e.target.value)) {
                                    let newValue = e.target.value;
                                    if (
                                      (revenue.price === '0' ||
                                        revenue.price === 0) &&
                                      newValue !== '0.' &&
                                      newValue !== ''
                                    ) {
                                      newValue = e.target.value.replace(
                                        /^0+/,
                                        ''
                                      );
                                    } else if (newValue === '0.') {
                                      newValue = '0.';
                                    } else if (newValue === '') {
                                      newValue = '0';
                                    }
                                    setModifications(
                                      index,
                                      'forward_sales',
                                      newValue
                                    );
                                  }
                                }}
                              />
                            </td>
                            {/* Vendas Total */}
                            <td>
                              <input
                                type="text"
                                value={revenue.turnover}
                                onChange={e => {
                                  if (!isNaN(e.target.value)) {
                                    let newValue = e.target.value;
                                    if (
                                      (revenue.price === '0' ||
                                        revenue.price === 0) &&
                                      newValue !== '0.' &&
                                      newValue !== ''
                                    ) {
                                      newValue = e.target.value.replace(
                                        /^0+/,
                                        ''
                                      );
                                    } else if (newValue === '0.') {
                                      newValue = '0.';
                                    } else if (newValue === '') {
                                      newValue = '0';
                                    }
                                    setModifications(
                                      index,
                                      'turnover',
                                      newValue
                                    );
                                  }
                                }}
                              />
                            </td>
                            {/* Compras */}
                            <td>
                              <input
                                type="text"
                                value={revenue.billing}
                                onChange={e => {
                                  if (!isNaN(e.target.value)) {
                                    let newValue = e.target.value;
                                    if (
                                      (revenue.price === '0' ||
                                        revenue.price === 0) &&
                                      newValue !== '0.' &&
                                      newValue !== ''
                                    ) {
                                      newValue = e.target.value.replace(
                                        /^0+/,
                                        ''
                                      );
                                    } else if (newValue === '0.') {
                                      newValue = '0.';
                                    } else if (newValue === '') {
                                      newValue = '0';
                                    }
                                    setModifications(
                                      index,
                                      'billing',
                                      newValue
                                    );
                                  }
                                }}
                              />
                            </td>
                            {/* Despesas Gerais */}
                            <td>
                              <input
                                type="text"
                                value={revenue.overheads}
                                onChange={e => {
                                  if (!isNaN(e.target.value)) {
                                    let newValue = e.target.value;
                                    if (
                                      (revenue.price === '0' ||
                                        revenue.price === 0) &&
                                      newValue !== '0.' &&
                                      newValue !== ''
                                    ) {
                                      newValue = e.target.value.replace(
                                        /^0+/,
                                        ''
                                      );
                                    } else if (newValue === '0.') {
                                      newValue = '0.';
                                    } else if (newValue === '') {
                                      newValue = '0';
                                    }
                                    setModifications(
                                      index,
                                      'overheads',
                                      newValue
                                    );
                                  }
                                }}
                              />
                            </td>
                            {/* Folha de Pagamento */}
                            <td>
                              <input
                                type="text"
                                value={revenue.the_payroll}
                                onChange={e => {
                                  if (!isNaN(e.target.value)) {
                                    let newValue = e.target.value;
                                    if (
                                      (revenue.price === '0' ||
                                        revenue.price === 0) &&
                                      newValue !== '0.' &&
                                      newValue !== ''
                                    ) {
                                      newValue = e.target.value.replace(
                                        /^0+/,
                                        ''
                                      );
                                    } else if (newValue === '0.') {
                                      newValue = '0.';
                                    } else if (newValue === '') {
                                      newValue = '0';
                                    }
                                    setModifications(
                                      index,
                                      'the_payroll',
                                      newValue
                                    );
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TableContainer>
              </DetailsContainer>
            )}
          </Content>
        )}
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default List;

import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { FaFileExcel } from 'react-icons/fa';
import api from '~/services/api';

const XlsxProcessor = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('output.xlsx');
  const [processedFile, setProcessedFile] = useState(null);
  // const [response, setResponse] = useState(null);

  const handleFileUpload = e => {
    setFile(e.target.files && e.target.files[0]);
  };

  function replaceDescriptions(description_table, history) {
    const newTable = description_table.map(item => {
      const historyItem = history.find(h => h.description === item);
      return historyItem ? historyItem.account : item;
    });

    return newTable;
  }

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    // eslint-disable-next-line no-bitwise
    for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const processFile = () => {
    if (!file) return;

    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = async event => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const datas = []; // colun data

      const description = new Set();
      const description_table = []; // coluna conta

      const saida = []; // coluna valor

      const entrada = []; // coluna debito //credit

      const conta = new Set();
      const conta_table = []; // coluna credito //contas

      const historico = []; // coluna historico //history

      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let row = range.s.r + 1; row <= range.e.r; row += 1) {
        let cellValue = worksheet[XLSX.utils.encode_cell({ r: row, c: 4 })]
          ? worksheet[XLSX.utils.encode_cell({ r: row, c: 4 })].v
          : 'N/A';
        description.add(cellValue);
        description_table.push(cellValue);
        historico.push(cellValue);

        cellValue = worksheet[XLSX.utils.encode_cell({ r: row, c: 1 })]
          ? worksheet[XLSX.utils.encode_cell({ r: row, c: 1 })].v
          : cellValue;
        entrada.push(cellValue);

        cellValue = worksheet[XLSX.utils.encode_cell({ r: row, c: 3 })]
          ? worksheet[XLSX.utils.encode_cell({ r: row, c: 3 })].v
          : 'N/A';
        conta.add(cellValue);
        conta_table.push(cellValue);

        cellValue = worksheet[XLSX.utils.encode_cell({ r: row, c: 2 })]
          ? worksheet[XLSX.utils.encode_cell({ r: row, c: 2 })].v
          : 'N/A';
        saida.push(cellValue);

        cellValue = worksheet[XLSX.utils.encode_cell({ r: row, c: 0 })];
        if (cellValue) {
          if (typeof cellValue.v === 'number') {
            const date = new Date((cellValue.v - (25567 + 2)) * 86400 * 1000);
            cellValue = `${date.getDate()}/${date.getMonth() +
              1}/${date.getFullYear()}`;
          } else {
            cellValue = cellValue.v;
          }
        } else {
          cellValue = 'N/A';
        }
        datas.push(cellValue);
      }

      toast.warn('Processando Dados.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      try {
        const response = await api.post(
          '/teste',
          {
            description: Array.from(description),
            conta: Array.from(conta),
          },
          {
            params: {
              history: true,
              credit: true,
              // Estes dados sao do BD antigo
              company_id: 28978,
              main_id: 195281,
            },
          }
        );

        const { history, credit, contas } = response.data;

        const h = replaceDescriptions(historico, history);
        const e = replaceDescriptions(entrada, credit);
        const c = replaceDescriptions(conta_table, contas);

        const wb = XLSX.utils.book_new();

        const ws = XLSX.utils.aoa_to_sheet([
          ['Datas', 'Conta', 'Valor', 'Debito', 'Crédito', 'Historico'],
          ...datas.map((k, i) => [
            k,
            description_table[i],
            saida[i],
            e[i],
            c[i],
            h[i],
          ]),
        ]);

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        const buf = s2ab(wbout);

        const blob = new Blob([buf], { type: 'application/octet-stream' });
        setProcessedFile(blob);
        toast.success('Planilha está pronta.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch (error) {
        toast.error('Erro ao processar arquivo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const downloadFile = () => {
    if (!processedFile) return;
    saveAs(processedFile, fileName);
  };

  const [isOpen, setIsOpen] = useState(false);
  const fileInput = useRef();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div>
      <FaFileExcel
        onClick={openModal}
        size={18}
        color="#fcfcfc"
        style={{ cursor: 'pointer', fontSize: '2rem' }}
      />

      {isOpen && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '1em',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            width: '300px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileUpload}
              ref={fileInput}
            />
            <button
              type="button"
              onClick={closeModal}
              style={{
                backgroundColor: '#ff6347',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                padding: '5px 10px',
                cursor: 'pointer',
              }}
            >
              Fechar
            </button>
          </div>
          <button type="button" onClick={processFile}>
            Process File
          </button>
          <button
            type="button"
            onClick={downloadFile}
            disabled={!processedFile}
          >
            Download Processed File
          </button>
        </div>
      )}
    </div>
  );
};

export default XlsxProcessor;

import styled from 'styled-components';

export const Container = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10;
`;

export const Content = styled.div`
  max-width: 400px;
  background: ${({ theme }) => theme.colors.white};
  padding: 25px;
  border-radius: 4px;

  display: flex;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const MessageContent = styled.main`
  font-size: 16px;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.border};

  span {
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.grey};
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  strong {
    font-size: 28px;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

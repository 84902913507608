import React, { useRef, useState } from 'react';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

import * as Yup from 'yup';
import ClearBackground from '~/components/ClearBackground';
import { Input } from '~/components/Form';
import { ButtonSpinner } from '~/components/Spinner';
import api from '~/services/api';

import {
  Container,
  Content,
  Left,
  Right,
  Header,
  FormContainer,
} from './styles';

const EditMainCosts = ({ onClose, costsMain, setCostsMain }) => {
  const formRef = useRef(null);
  const initialData = {
    employee_cost: costsMain.employee_cost,
    tributary_cost: costsMain.tributary_cost,
    accounting_cost: costsMain.accounting_cost,
    extra_cost: costsMain.extra_cost,
  };

  const [loading, setLoading] = useState(false);

  const submitForm = async data => {
    try {
      setLoading(true);

      const schema = Yup.object().shape({
        employee_cost: Yup.number().typeError('O valor é obrigatório'),
        tributary_cost: Yup.number().typeError('O valor é obrigatório'),
        accounting_cost: Yup.number().typeError('O valor é obrigatório'),
        extra_cost: Yup.number().typeError('O valor é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await api.put(`/costs/${costsMain.id}`, data);

      toast.success('Alterações salvas com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
      setCostsMain(response.data);
      onClose();
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        toast.error('Falha ao salvar as alterações.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  return (
    <>
      <ClearBackground />
      <Container>
        <Content>
          <Left>
            <FaEdit size={38} color="#01579b" />
          </Left>
          <Right>
            <Header>
              <FaTimes onClick={onClose} size={14} color="#e53935" />
              <strong>Editar custos gerais:</strong>
            </Header>

            <FormContainer
              ref={formRef}
              onSubmit={submitForm}
              initialData={initialData}
            >
              <Input
                name="employee_cost"
                label="Valor por trabalhador"
                type="number"
                step="0.01"
              />
              <Input
                name="tributary_cost"
                label="Valor por hora tributário"
                type="number"
                step="0.01"
              />
              <Input
                name="accounting_cost"
                label="Valor por hora contábil"
                type="number"
                step="0.01"
              />
              <Input
                name="extra_cost"
                label="Valor por hora extra"
                type="number"
                step="0.01"
              />

              <button type="submit" disabled={loading}>
                {loading ? <ButtonSpinner /> : 'Atualizar'}
              </button>
            </FormContainer>
          </Right>
        </Content>
      </Container>
    </>
  );
};

export default EditMainCosts;

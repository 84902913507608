import React from 'react';
import PropTypes from 'prop-types';

import { SpinnerContainer, Bouce1, Bouce2 } from './styles';

export default function ButtonSpinner({ size, color }) {
  return (
    <SpinnerContainer size={size}>
      <Bouce1 color={color} />
      <Bouce2 color={color} />
    </SpinnerContainer>
  );
}

ButtonSpinner.defaultProps = {
  size: 20,
  color: 'rgba(255, 255, 255, 0.9)',
};

ButtonSpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaSave,
  FaTrash,
  FaBroom,
  FaTimes,
  FaTh,
  FaPlus,
  FaPaperclip,
  FaAngleDown,
  FaAngleUp,
  FaMinus,
  FaExchangeAlt,
} from 'react-icons/fa';

import produce from 'immer';
import * as Yup from 'yup';
import { Scope } from '@unform/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';
import ConfirmMarkDocument from './ConfirmMarkDocument';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import {
  Container,
  Header,
  Controls,
  Content,
  ProcessTypeInfo,
  Title,
  Options,
  File,
  UploadFile,
  Delete,
  Icon,
} from './styles';

import {
  FormContainer,
  FormLoading,
  Input,
  FileInput,
  Select,
  Checkbox,
} from '~/components/Form';
import IconsOptionsModal from './IconsOptionsModal';

const Form = ({ id, handleChangeId, setIsForm, defaultType }) => {
  const { user, company, companyUsers } = useAuth();

  const formRef = useRef();

  const [formLoading, setFormLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [processType, setProcessType] = useState({});
  const [initialData, setInitialData] = useState({});
  const deleteDocuments = useRef([]);
  const deleteQuestions = useRef([]);
  const deletePhases = useRef([]);

  const [changeOrderDocuments, setChangeOrderDocuments] = useState(false);
  const [changeOrderQuestions, setChangeOrderQuestions] = useState(false);
  const [changeOrderPhases, setChangeOrderPhases] = useState(false);

  const [selectedIcon, setSelectedIcon] = useState({
    name: 'address-card',
    id: 1,
  });
  const [isOpen, setIsOpen] = useState(false);

  const [typeModel, setTypeModel] = useState(defaultType || 0);
  const [typeOfProcess, setTypeOfProcess] = useState(0);

  const modelOptions = useMemo(() => {
    const options = [
      { value: 0, label: 'Modelo padrão' },
      { value: 1, label: 'Modelo trabalhista' },
      { value: 2, label: 'Controle de vencimentos' },
    ];

    return options;
  }, []);

  const TypesQuestion = [
    { value: 0, label: 'Texto' },
    { value: 1, label: 'Inteiro' },
    { value: 2, label: 'Decimal' },
    { value: 3, label: 'Moeda' },
  ];

  const quantitativeTypesOptions = useMemo(() => {
    const options = [
      { value: 0, label: 'para empresa' },
      { value: 1, label: 'para cada sócio' },
    ];

    return options;
  }, []);

  const phaseTypesOptions = useMemo(() => {
    const options = [
      { value: 0, label: 'Padrão' },
      { value: 1, label: 'Requer pagamento' },
      { value: 2, label: 'Ativar novo processo' },
    ];

    return options;
  }, []);

  const [typesUsed, setTypesUsed] = useState({});

  const processOptions = useMemo(() => {
    if (typeModel === 2) {
      const options = [{ value: 0, label: 'Padrão' }];

      if (!typesUsed.outCompanies) {
        options.push({ value: 1, label: 'Empresas terceirizadas' });
      }
      if (!typesUsed.ownCompanies) {
        options.push({ value: 2, label: 'Empresas próprias' });
      }

      return options;
    }
    if (typeModel === 0) {
      const options = [
        { value: 0, label: 'Processo padrão' },
        { value: 1, label: 'Processos com 1 sócio' },
        { value: 2, label: 'Processos com vários sócios' },
      ];

      return options;
    }

    const options = [
      { value: 0, label: 'Admissão' },
      { value: 1, label: 'Recisão' },
    ];

    return options;
  }, [typeModel, typesUsed]);

  const [usersOptions, setUsersOptions] = useState([]);
  const [modelResponsibleOptions, setModelResponsibleOptions] = useState(null);
  const [documentsOptions, setDocumentsOptions] = useState(null);
  const [processModelsOptions, setProcessModelsOptions] = useState(null);
  const [popOptions, setPopOptions] = useState(null);

  useEffect(() => {
    (async () => {
      if (company) {
        const response = await api.get(
          `/processes/verify-types/${company.id}`,
          {
            params: {
              id,
            },
          }
        );
        setTypesUsed(response.data);
      }
    })();
  }, [company, id]);

  useEffect(() => {
    async function loadModel() {
      if (user && company) {
        if (id) {
          try {
            setFormLoading(true);
            const response = await api.get(`/process-models/${id}`);

            const {
              title,
              menu_title,
              model_responsible_id,
              model_type,
              process_type,
              start_next_phase = false,
              labor,
              tributary,
              accounting,
              financial,
              administration,
              icon_id,
              icon,
              activity_status,
            } = response.data;

            if (response.data) {
              response.data.phases = response.data.phases.map(item => ({
                ...item,
                dead_line_client: item.dead_line_client || 0,
              }));

              setProcessType(response.data);
              setTypeModel(response.data.model_type);
              setTypeOfProcess(response.data.process_type);
              setInitialData({
                title,
                menu_title,
                activity_status,
                model_responsible_id,
                model_type,
                process_type,
                start_next_phase,
                labor,
                tributary,
                accounting,
                financial,
                administration,
              });

              setSelectedIcon({ name: icon.name, id: icon_id });
            } else {
              toast.warn('Nenhum modelo foi encontrado.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }

            setFormLoading(false);
          } catch {
            toast.error('Falha ao buscar modelo.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            handleChangeId(null);
            setIsForm(false);
          }
        } else {
          setProcessType({
            model_type: defaultType || 0,
            start_next_phase: false,
            labor: 1,
            tributary: 1,
            accounting: 1,
            financial: 1,
            administration: 1,
            documents: [],
            questions: [],
            phases: [],
          });
          setInitialData({
            model_type: defaultType || 0,
            labor: 1,
            tributary: 1,
            accounting: 1,
            financial: 1,
            administration: 1,
          });
          setFormLoading(false);
        }
      }
    }

    async function loadDocuments() {
      if (user && company) {
        try {
          const response = await api.get(`/documents/select/${company.id}`);

          if (response.data.length > 0) {
            const options = response.data.map(item => ({
              value: item.document.id,
              label: item.document.description,
            }));

            setDocumentsOptions(options);
          } else {
            toast.warn('Nenhum documento foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setDocumentsOptions([]);
          }
        } catch {
          toast.error('Falha ao buscar documentos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }

    async function loadModels() {
      if (user && company) {
        try {
          const response = await api.get(`/process-models/simple`, {
            params: {
              company_id: company.id,
              model_type: 0,
            },
          });
          if (response.data.length > 0) {
            const options = response.data.map(item => ({
              value: item.id,
              label: item.menu_title || item.title,
              activity_status: item.activity_status,
            }));

            setProcessModelsOptions(options);
          } else {
            toast.warn('Nenhum modelo foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setProcessModelsOptions([]);
          }
        } catch {
          toast.error('Falha ao buscar modelos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }

    async function loadPops() {
      if (user && company) {
        try {
          const response = await api.get('/pop', {
            params: {
              company_id: company.id,
              selectOnly: 1,
            },
          });

          const options = response.data.map(item => ({
            value: item.id,
            label: item.title,
          }));

          options.unshift({
            value: null,
            label: 'Nenhum',
          });

          setPopOptions(options);
        } catch {
          toast.error('Falha ao buscar POPs.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }

    loadModel();
    loadDocuments();
    loadModels();
    loadPops();
  }, [id, company, user, handleChangeId, setIsForm, defaultType]);

  useEffect(() => {
    if (companyUsers && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
            active: userItem.active,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
        active: true,
      });

      options.unshift({
        value: null,
        label: 'Nenhum',
      });

      const [, ...rest] = options;

      setModelResponsibleOptions([...rest]);
      setUsersOptions(options);
    }
  }, [companyUsers, user]);

  const handleSubmit = useCallback(
    async (data, updateProcesses = false) => {
      try {
        setLoading(true);

        data.documents = processType.documents;
        data.questions = processType.questions;
        data.phases = processType.phases;

        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório.'),
          menu_title: Yup.string().required('O título do menu é obrigatório.'),
          model_responsible_id: Yup.number().typeError(
            'O reponsável padrão é obrigatório'
          ),
          activity_status: Yup.boolean(),
          model_type: Yup.number().typeError('O tipo é obrigatório'),
          process_type: Yup.number().typeError('O tipo é obrigatório'),
          labor: Yup.bool(),
          tributary: Yup.bool(),
          accounting: Yup.bool(),
          financial: Yup.bool(),
          administration: Yup.bool(),
          documents: Yup.array().of(
            Yup.object().shape({
              abbreviation:
                typeModel === 2
                  ? Yup.string().required('A abreviatura é obrigatória')
                  : Yup.string().nullable(),
              document: Yup.string().required('O documento é obrigatório'),
              responsible_id: Yup.number()
                .typeError('O responsável é obrigatório')
                .nullable(true),
              quantitative_type: Yup.number()
                .typeError('O tipo quantitativo é obrigatório')
                .nullable(),
              required: Yup.bool(),
              document_required: Yup.bool().nullable(),
              document_id: Yup.string().when('document_required', {
                is: value => value,
                then: Yup.string().required('O documento é obrigatório'),
                otherwise: Yup.string().nullable(),
              }),
            })
          ),
          questions: Yup.array().of(
            Yup.object().shape({
              question: Yup.string().required('A pergunta é obrigatória'),
              explanation: Yup.string()
                .typeError('A explicação é obrigatória')
                .nullable(),
              required: Yup.bool(),
              modifiable_schedule: Yup.bool(),
              quantitative_type: Yup.number()
                .typeError('O tipo quantitativo é obrigatório')
                .nullable(),
              type_question: Yup.number().typeError(
                'O tipo da questão é obrigatório'
              ),
            })
          ),
          phases: Yup.array().of(
            Yup.object().shape({
              abbreviation: Yup.string().required(
                'A abreviatura é obrigatória'
              ),
              document: Yup.string().required('O documento é obrigatório'),
              responsible_id: Yup.number()
                .nullable(true)
                .typeError('O responsável é obrigatório'),
              type: Yup.number().typeError('O tipo é obrigatório'),
              model_to_create: Yup.string().when('type', {
                is: value => value === 2,
                then: Yup.string().required('O modelo é obrigatório'),
                otherwise: Yup.string().nullable(),
              }),
              document_required: Yup.bool(),
              document_id: Yup.string().when('document_required', {
                is: value => value,
                then: Yup.string().required('O documento é obrigatório'),
                otherwise: Yup.string().nullable(),
              }),
              extension_days: Yup.number()
                .positive('O tempo de prorrogação deve ser um número positivo.')
                .moreThan(
                  -1,
                  'O tempo de prorrogação tem que ser maior ou igual a 0'
                )
                .typeError('O tempo de prorrogação tem que ser maior que 0'),
              required: Yup.bool(),
              notificate: Yup.bool(),
            })
          ),
        });

        await schema.validate(data, { abortEarly: false });

        const {
          icon_id,
          title,
          model_responsible_id,
          menu_title,
          model_type,
          process_type,
          start_next_phase,
          accounting,
          administration,
          financial,
          labor,
          tributary,
          activity_status,
        } = data;

        const model = {
          company_id: company.id,
          icon_id,
          title,
          menu_title: menu_title || title,
          model_responsible_id,
          activity_status,
          model_type,
          process_type,
          start_next_phase,
          accounting,
          administration,
          financial,
          labor,
          tributary,
          updateProcesses,
          quant_dependencies:
            processType.documents.length + processType.questions.length,
        };

        if (id) {
          await api.put(`/process-models/${id}`, model);

          const documentsPromises = processType.documents.map(
            async (document, index) => {
              const { order, id: id_document, file_target, ...rest } = document;

              if (file_target) {
                const formData = new FormData();

                formData.append('file', file_target);

                const fileResponse = await api.post('files/upload', formData, {
                  params: {
                    prefix: 'Process_Document',
                  },
                });

                const { blobName } = fileResponse.data;

                const file_url = blobName;

                if (id_document) {
                  await api.put(`/process-models/documents/${id_document}`, {
                    id: id_document,
                    ...rest,
                    file_url,
                    order: index,
                    updateProcesses,
                  });
                } else {
                  await api.post('/process-models/documents', {
                    ...rest,
                    file_url,
                    order: index,
                    updateProcesses,
                  });
                }
              } else if (id_document) {
                await api.put(`/process-models/documents/${id_document}`, {
                  id: id_document,
                  ...rest,
                  order: index,
                  updateProcesses,
                });
              } else {
                await api.post('/process-models/documents', {
                  ...rest,
                  order: index,
                  updateProcesses,
                });
              }
            }
          );

          const questionsPromises = processType.questions.map(
            async (question, index) => {
              const { order, id: id_question, ...rest } = question;

              if (id_question) {
                await api.put(`/process-models/questions/${id_question}`, {
                  id: id_question,
                  ...rest,
                  order: index,
                  updateProcesses,
                });
              } else {
                await api.post('/process-models/questions', {
                  ...rest,
                  order: index,
                  updateProcesses,
                });
              }
            }
          );

          const phasesPromises = processType.phases.map(
            async (phase, index) => {
              const { order, id: id_phase, ...rest } = phase;

              if (id_phase) {
                await api.put(`/process-models/phases/${id_phase}`, {
                  ...rest,
                  order: index,
                  updateProcesses,
                });
              } else {
                await api.post('/process-models/phases', {
                  ...rest,
                  order: index,
                  updateProcesses,
                });
              }
            }
          );

          const deleteDocumentsPromises = deleteDocuments.current.map(
            async id_document => {
              await api.delete(`/process-models/documents/${id_document}`, {
                params: {
                  updateProcesses,
                },
              });
            }
          );

          const deleteQuestionsPromises = deleteQuestions.current.map(
            async id_question => {
              await api.delete(`/process-models/questions/${id_question}`, {
                params: {
                  updateProcesses,
                },
              });
            }
          );

          const deletePhasesPromises = deletePhases.current.map(
            async id_phase => {
              await api.delete(`/process-models/phases/${id_phase}`, {
                params: {
                  updateProcesses,
                },
              });
            }
          );

          await Promise.all(documentsPromises);
          await Promise.all(questionsPromises);
          await Promise.all(phasesPromises);

          await Promise.all(deleteDocumentsPromises);
          await Promise.all(deleteQuestionsPromises);
          await Promise.all(deletePhasesPromises);
        } else {
          const createdModel = await api.post(`/process-models`, model);

          const documentsPromises = processType.documents.map(
            async (document, index) => {
              const { order, model_id, file_target, ...rest } = document;

              if (file_target) {
                const formData = new FormData();

                formData.append('file', file_target);

                const fileResponse = await api.post('files/upload', formData, {
                  params: {
                    prefix: 'Process_Document',
                  },
                });

                const { blobName } = fileResponse.data;

                const file_url = blobName;

                await api.post('/process-models/documents', {
                  model_id: createdModel.data.id,
                  ...rest,
                  file_url,
                  order: index,
                  updateProcesses: false,
                });
              } else {
                await api.post('/process-models/documents', {
                  model_id: createdModel.data.id,
                  ...rest,
                  order: index,
                  updateProcesses: false,
                });
              }
            }
          );

          const questionsPromises = processType.questions.map(
            async (question, index) => {
              const { order, model_id, ...rest } = question;

              await api.post('/process-models/questions', {
                model_id: createdModel.data.id,
                ...rest,
                order: index,
                updateProcesses: false,
              });
            }
          );

          const phasesPromises = processType.phases.map(
            async (phase, index) => {
              const { order, model_id, ...rest } = phase;

              await api.post('/process-models/phases', {
                model_id: createdModel.data.id,
                ...rest,
                order: index,
                updateProcesses: false,
              });
            }
          );

          await Promise.all(documentsPromises);
          await Promise.all(questionsPromises);
          await Promise.all(phasesPromises);

          if (model_type === 2 && process_type !== 0) {
            await api.post('/processes/create-expiration-processes', {
              user_id: user.id,
              user_name: user.short_name,
              company_id: company.id,
              type: process_type,
              model_id: createdModel.data.id,
            });
          }
        }

        formRef.current.setErrors({});

        toast.success('Modelo salvo com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setLoading(false);

        handleChangeId(null);
        setIsForm(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
          toast.error(
            'Existem campos obrigatórios em vermelho que precisam ser preenchidos.',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        } else {
          toast.error('Falha ao salvar modelo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setLoading(false);

        // eslint-disable-next-line
        console.log(err)
      }
    },
    [
      company,
      user,
      id,
      processType,
      deleteDocuments,
      deletePhases,
      typeModel,
      handleChangeId,
      setIsForm,
    ]
  );

  const confirmUpdateProcesses = useCallback(
    async data => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmMarkDocument
              onClick={e => {
                handleSubmit(data, e);
              }}
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleSubmit]
  );

  const handleChangeModel = useCallback(
    selectedOption => {
      const { value } = selectedOption;

      if (typeModel !== 2 && value === 2) {
        setProcessType(
          produce(processType, draft => {
            draft.phases = [];
            draft.questions = [];
          })
        );
      }

      setTypeModel(value);
    },
    [typeModel, processType]
  );

  const handleAddFile = useCallback(
    (index, group, file) => {
      setProcessType(
        produce(processType, draft => {
          draft[group][index].file = file.name;
          draft[group][index].file_target = file;
        })
      );
    },
    [processType]
  );

  const handleDeleteFile = useCallback(
    (index, group) => {
      setProcessType(
        produce(processType, draft => {
          draft[group][index].file = null;
          draft[group][index].file_target = null;
        })
      );
    },
    [processType]
  );

  const handleNewDocument = useCallback(
    index => {
      setProcessType(
        produce(processType, draft => {
          const newDocument = {
            model_id: id,
            abbreviation: '',
            document: '',
            obs: '',
            file: '',
            responsible_id: null,
            required: 1,
            quantitative_type: 0,
          };

          if (index === null) {
            draft.documents.push(newDocument);
          } else {
            draft.documents.splice(index + 1, 0, newDocument);
          }
        })
      );
    },
    [processType, id]
  );

  const handleDeleteDocument = useCallback(
    index => {
      if (processType.documents[index].id) {
        const { id: id_document } = processType.documents[index];
        deleteDocuments.current.push(id_document);
      }

      setProcessType(
        produce(processType, draft => {
          draft.documents.splice(index, 1);
        })
      );
    },
    [processType, deleteDocuments]
  );

  const handleChangeOrderDocuments = useCallback(
    (index, direction) => {
      setProcessType(
        produce(processType, draft => {
          const aux = draft.documents[index];
          const lastPosition = draft.documents.length - 1;

          if (index === 0 && direction === 'up') {
            draft.documents[0] = draft.documents[lastPosition];
            draft.documents[lastPosition] = aux;
          } else if (index === lastPosition && direction === 'down') {
            const [firstDocument] = draft.documents;
            draft.documents[lastPosition] = firstDocument;
            draft.documents[0] = aux;
          } else if (direction === 'up') {
            draft.documents[index] = draft.documents[index - 1];
            draft.documents[index - 1] = aux;
          } else {
            draft.documents[index] = draft.documents[index + 1];
            draft.documents[index + 1] = aux;
          }
        })
      );
    },
    [processType]
  );

  const handleNewQuestion = useCallback(
    index => {
      setProcessType(
        produce(processType, draft => {
          const newQuestion = {
            model_id: id,
            question: '',
            required: 1,
            key_question: false,
            explanation: '',
            quantitative_type: 0,
            type_question: 0,
          };

          if (index === null) {
            draft.questions.push(newQuestion);
          } else {
            draft.questions.splice(index + 1, 0, newQuestion);
          }
        })
      );
    },
    [processType, id]
  );

  const handleDeleteQuestion = useCallback(
    index => {
      if (processType.questions[index].id) {
        const { id: id_question } = processType.questions[index];
        deleteQuestions.current.push(id_question);
      }

      setProcessType(
        produce(processType, draft => {
          draft.questions.splice(index, 1);
        })
      );
    },
    [processType, deleteQuestions]
  );

  const handleChangeOrderQuestions = useCallback(
    (index, direction) => {
      setProcessType(
        produce(processType, draft => {
          const aux = draft.questions[index];
          const lastPosition = draft.questions.length - 1;

          if (index === 0 && direction === 'up') {
            draft.questions[0] = draft.questions[lastPosition];
            draft.questions[lastPosition] = aux;
          } else if (index === lastPosition && direction === 'down') {
            const [firstQuestion] = draft.questions;
            draft.questions[lastPosition] = firstQuestion;
            draft.questions[0] = aux;
          } else if (direction === 'up') {
            draft.questions[index] = draft.questions[index - 1];
            draft.questions[index - 1] = aux;
          } else {
            draft.questions[index] = draft.questions[index + 1];
            draft.questions[index + 1] = aux;
          }
        })
      );
    },
    [processType]
  );

  const handleNewPhase = useCallback(
    index => {
      setProcessType(
        produce(processType, draft => {
          const newPhase = {
            model_id: id,
            abbreviation: '',
            document: '',
            responsible_id: null,
            dead_line: '',
            required: 1,
            notificate: 1,
            type: 0,
            document_required: false,
            generate_protocol: true,
            related_pop: null,
          };

          if (index === null) {
            draft.phases.push(newPhase);
          } else {
            draft.phases.splice(index + 1, 0, newPhase);
          }
        })
      );
    },
    [processType, id]
  );

  const handleDeletePhase = useCallback(
    index => {
      if (processType.phases[index].id) {
        const { id: id_phase } = processType.phases[index];
        deletePhases.current.push(id_phase);
      }

      setProcessType(
        produce(processType, draft => {
          draft.phases.splice(index, 1);
        })
      );
    },
    [processType, deletePhases]
  );

  const handleChangeOrderPhases = useCallback(
    (index, direction) => {
      setProcessType(
        produce(processType, draft => {
          const aux = draft.phases[index];
          const lastPosition = draft.phases.length - 1;

          if (index === 0 && direction === 'up') {
            draft.phases[0] = draft.phases[lastPosition];
            draft.phases[lastPosition] = aux;
          } else if (index === lastPosition && direction === 'down') {
            const [firstPhase] = draft.phases;
            draft.phases[lastPosition] = firstPhase;
            draft.phases[0] = aux;
          } else if (direction === 'up') {
            draft.phases[index] = draft.phases[index - 1];
            draft.phases[index - 1] = aux;
          } else {
            draft.phases[index] = draft.phases[index + 1];
            draft.phases[index + 1] = aux;
          }
        })
      );
    },
    [processType]
  );

  const resetForm = useCallback(() => {
    let aux;

    for (aux = 0; aux < processType.documents.length; aux += 1) {
      handleDeleteDocument(aux);
    }

    for (aux = 0; aux < processType.questions.length; aux += 1) {
      handleDeleteQuestion(aux);
    }

    for (aux = 0; aux < processType.phases.length; aux += 1) {
      handleDeletePhase(aux);
    }

    formRef.current.reset();
    setProcessType({
      id,
      title: '',
      menu_title: '',
      model_type: null,
      process_type: null,
      start_next_phase: false,
      labor: 0,
      tributary: 0,
      accounting: 0,
      financial: 0,
      administration: 0,
      documents: [],
      questions: [],
      phases: [],
    });
  }, [
    formRef,
    id,
    handleDeleteDocument,
    handleDeleteQuestion,
    handleDeletePhase,
    processType,
  ]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => {
              handleChangeId(null);
              setIsForm(false);
            }}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleChangeId, setIsForm]);

  const handleInputChange = useCallback(
    (event, group, name, index) => {
      const { target } = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;

      setProcessType(
        produce(processType, draft => {
          draft[group][index][name] = value;
        })
      );
    },
    [processType]
  );

  const handleSelectChange = useCallback(
    (selectedOption, group, name, index) => {
      const { value } = selectedOption;

      setProcessType(
        produce(processType, draft => {
          draft[group][index][name] = value;
        })
      );
    },
    [processType]
  );

  const handleSelectIcon = useCallback((name, icon_id) => {
    setSelectedIcon({ name, id: icon_id });
    setIsOpen(open => !open);
  }, []);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaTh size={20} color="#44546a" />
            <h1>Modelos de processos</h1>
          </div>
        </Header>

        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {formLoading ? (
          <FormLoading />
        ) : (
          <Content className="content">
            {processType &&
              popOptions &&
              documentsOptions &&
              processModelsOptions &&
              modelResponsibleOptions && (
                <FormContainer
                  ref={formRef}
                  initialData={initialData}
                  loading={loading ? 1 : 0}
                  onSubmit={data => {
                    if (id) {
                      confirmUpdateProcesses(data);
                    } else {
                      handleSubmit(data);
                    }
                  }}
                >
                  <ProcessTypeInfo>
                    <h4>MODELO DE PROCESSOS</h4>
                    <section>
                      <Icon onClick={() => setIsOpen(open => !open)}>
                        <FontAwesomeIcon
                          icon={selectedIcon.name}
                          size="lg"
                          color="#44546a"
                        />
                      </Icon>
                      <Input
                        name="icon_id"
                        className="hide"
                        value={selectedIcon.id}
                        readOnly
                      />
                      <Input
                        name="title"
                        className="title"
                        label="Título"
                        type="text"
                      />
                      <Input
                        name="menu_title"
                        className="menu-title"
                        label="Título do menu"
                        type="text"
                        maxLength="32"
                      />
                      <Select
                        name="author"
                        className="type"
                        label="Autor"
                        options={modelResponsibleOptions}
                        placeholder="Selecione um autor"
                        type="text"
                      />
                      <Select
                        name="model_responsible_id"
                        className="type"
                        label="Responsável padrão"
                        options={modelResponsibleOptions}
                        placeholder="Selecione um usuário"
                      />
                      {id ? (
                        <Input
                          name="modelType"
                          className="type"
                          label="Tipo do modelo"
                          type="text"
                          value={
                            modelOptions.find(
                              option => option.value === initialData.model_type
                            )?.label
                          }
                          disabled
                        />
                      ) : (
                        <Select
                          name="model_type"
                          className="type"
                          label="Tipo do modelo"
                          type="text"
                          options={modelOptions}
                          onChange={handleChangeModel}
                          placeholder="Selecione um modelo"
                        />
                      )}

                      <Checkbox
                        id="activity_status"
                        name="activity_status"
                        className="checkbox"
                        de
                        label="Status de Atividade"
                      />

                      {typeModel === 0 && (
                        <Checkbox
                          id="start_next_phase"
                          name="start_next_phase"
                          className="checkbox"
                          label="Disparar etapa seguinte"
                        />
                      )}
                    </section>
                    <section className="sector">
                      <Checkbox
                        id="labor"
                        name="labor"
                        className="checkbox"
                        label="Trabalhista"
                      />
                      <Checkbox
                        id="tributary"
                        name="tributary"
                        className="checkbox"
                        label="Tributário"
                      />
                      <Checkbox
                        id="accounting"
                        name="accounting"
                        className="checkbox"
                        label="Contábil"
                      />
                      <Checkbox
                        id="financial"
                        name="financial"
                        className="checkbox"
                        label="Financeiro"
                      />
                      <Checkbox
                        id="administration"
                        name="administration"
                        className="checkbox"
                        label="Administração"
                      />
                    </section>

                    <section>
                      {id ? (
                        <Input
                          name="processType"
                          className="type"
                          label="Tipo de processo"
                          type="text"
                          value={
                            processOptions.find(
                              option =>
                                option.value === initialData.process_type
                            )?.label
                          }
                          disabled
                        />
                      ) : (
                        <Select
                          name="process_type"
                          className="type"
                          label="Tipo do processo"
                          type="text"
                          options={processOptions}
                          placeholder="Selecione um modelo"
                          onChange={e => setTypeOfProcess(e.value)}
                        />
                      )}
                    </section>

                    <Title>
                      <h4 className="description">DOCUMENTAÇÃO NECESSÁRIA</h4>
                      <button
                        type="button"
                        onClick={() => handleNewDocument(null)}
                      >
                        <FaPlus size={10} />
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          setChangeOrderDocuments(prevState => !prevState)
                        }
                        className="order"
                      >
                        <FaExchangeAlt size={10} />
                      </button>
                    </Title>
                    {processType.documents.map((item, index) => (
                      <Scope path={`documents[${index}]`} key={index}>
                        <section>
                          {changeOrderDocuments ? (
                            <Options>
                              <button
                                type="button"
                                onClick={() =>
                                  handleChangeOrderDocuments(index, 'up')
                                }
                              >
                                <FaAngleUp size={10} />
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  handleChangeOrderDocuments(index, 'down')
                                }
                              >
                                <FaAngleDown size={10} />
                              </button>
                            </Options>
                          ) : (
                            <Options>
                              <button
                                type="button"
                                onClick={() => handleNewDocument(index)}
                              >
                                <FaPlus size={10} />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteDocument(index)}
                              >
                                <FaMinus size={10} />
                              </button>
                            </Options>
                          )}
                          {typeModel === 2 && (
                            <Input
                              name="abbreviation"
                              className="abreviate"
                              label="Abreviatura"
                              type="text"
                              maxLength="6"
                              value={item.abbreviation}
                              onChange={e =>
                                handleInputChange(
                                  e,
                                  'documents',
                                  'abbreviation',
                                  index
                                )
                              }
                            />
                          )}
                          <Input
                            name="document"
                            className="document"
                            label="Documento"
                            type="text"
                            value={item.document}
                            onChange={e =>
                              handleInputChange(
                                e,
                                'documents',
                                'document',
                                index
                              )
                            }
                          />
                          <Input
                            name="obs"
                            className="document"
                            label="Observação"
                            type="text"
                            value={item.obs}
                            onChange={e =>
                              handleInputChange(e, 'documents', 'obs', index)
                            }
                          />
                          {typeModel === 0 && typeOfProcess > 0 && (
                            <Select
                              name="quantitative_type"
                              className="quantitative-type"
                              label="Tipo do documento"
                              type="text"
                              options={quantitativeTypesOptions}
                              placeholder="Selecione um tipo"
                              value={quantitativeTypesOptions.find(
                                option =>
                                  option.value === item.quantitative_type
                              )}
                              onChange={e =>
                                handleSelectChange(
                                  e,
                                  'documents',
                                  'quantitative_type',
                                  index
                                )
                              }
                            />
                          )}
                          {typeModel === 2 && (
                            <>
                              <Select
                                name="author"
                                className="type"
                                label="Autor"
                                type="text"
                                options={usersOptions}
                                placeholder="Selecione um autor"
                                value={usersOptions.find(
                                  option => option.value === item.author
                                )}
                                onChange={e =>
                                  handleSelectChange(
                                    e,
                                    'documents',
                                    'author',
                                    index
                                  )
                                }
                              />
                              <Select
                                name="responsible_id"
                                className="type"
                                label="Responsável"
                                type="text"
                                options={usersOptions}
                                placeholder="Selecione um responsável"
                                value={usersOptions.find(
                                  option => option.value === item.responsible_id
                                )}
                                onChange={e =>
                                  handleSelectChange(
                                    e,
                                    'documents',
                                    'responsible_id',
                                    index
                                  )
                                }
                              />
                            </>
                          )}
                          {!item.document_required || typeModel !== 2 ? (
                            <>
                              {item.file ? (
                                <div className="file">
                                  <Delete>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleDeleteFile(index, 'documents')
                                      }
                                    >
                                      <FaTrash size={14} />
                                    </button>
                                  </Delete>
                                  <Input
                                    name="file"
                                    className="file_name"
                                    label="Arquivo"
                                    type="text"
                                    value={item.file}
                                    disabled
                                  />
                                </div>
                              ) : (
                                <UploadFile>
                                  <File>
                                    <label htmlFor={`file.doc.${index}`}>
                                      <FaPaperclip size={14} color="#FCFCFC" />
                                    </label>
                                    <FileInput
                                      id={`file.doc.${index}`}
                                      name="file"
                                      onChange={e => {
                                        handleAddFile(
                                          index,
                                          'documents',
                                          e.target.files[0]
                                        );
                                      }}
                                    />
                                  </File>
                                </UploadFile>
                              )}
                            </>
                          ) : (
                            <Select
                              name="document_id"
                              className="type"
                              label="Documento"
                              options={documentsOptions}
                              placeholder="Selecione um documento"
                              value={documentsOptions.find(
                                option => option.value === item.document_id
                              )}
                              onChange={e =>
                                handleSelectChange(
                                  e,
                                  'documents',
                                  'document_id',
                                  index
                                )
                              }
                            />
                          )}
                          <Select
                            name="related_pop"
                            className="type"
                            label="POP Relacionado"
                            type="text"
                            options={popOptions}
                            placeholder="Selecione um POP"
                            value={popOptions.find(
                              option =>
                                option.value ===
                                (item.pop_id || popOptions[0]?.value)
                            )}
                            onChange={e =>
                              handleSelectChange(
                                e,
                                'documents',
                                'pop_id',
                                index
                              )
                            }
                          />
                          {typeModel === 2 ? (
                            <div>
                              <Checkbox
                                id={`doc.required.${index}`}
                                name="document_required"
                                className="checkbox"
                                label="Documento obrigatório"
                                checked={item.document_required || false}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'documents',
                                    'document_required',
                                    index
                                  )
                                }
                              />
                            </div>
                          ) : (
                            <div>
                              <Checkbox
                                id={`doc.required.${index}`}
                                name="required"
                                className="checkbox"
                                label="Obrigatório"
                                checked={item.required}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'documents',
                                    'required',
                                    index
                                  )
                                }
                              />
                            </div>
                          )}
                          <div>
                            <Checkbox
                              id={`doc.notify_due_date.${index}`}
                              name="notify_due_date"
                              className="checkbox"
                              label="Avisar Vencimento"
                              checked={item.notify_due_date}
                              onChange={e =>
                                handleInputChange(
                                  e,
                                  'documents',
                                  'notify_due_date',
                                  index
                                )
                              }
                            />
                          </div>
                        </section>
                      </Scope>
                    ))}

                    {typeModel !== 2 && (
                      <>
                        <Title>
                          <h4 className="description">PERGUNTAS</h4>
                          <button
                            type="button"
                            onClick={() => handleNewQuestion(null)}
                          >
                            <FaPlus size={10} />
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              setChangeOrderQuestions(prevState => !prevState)
                            }
                            className="order"
                          >
                            <FaExchangeAlt size={10} />
                          </button>
                        </Title>
                        {processType.questions.map((item, index) => (
                          <Scope path={`questions[${index}]`} key={index}>
                            <section>
                              {changeOrderQuestions ? (
                                <Options>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleChangeOrderQuestions(index, 'up')
                                    }
                                  >
                                    <FaAngleUp size={10} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleChangeOrderQuestions(index, 'down')
                                    }
                                  >
                                    <FaAngleDown size={10} />
                                  </button>
                                </Options>
                              ) : (
                                <Options>
                                  <button
                                    type="button"
                                    onClick={() => handleNewQuestion(index)}
                                  >
                                    <FaPlus size={10} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => handleDeleteQuestion(index)}
                                  >
                                    <FaMinus size={10} />
                                  </button>
                                </Options>
                              )}
                              <Input
                                name="question"
                                className="document"
                                label="Pergunta"
                                type="text"
                                value={item.question}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'questions',
                                    'question',
                                    index
                                  )
                                }
                              />
                              <Input
                                name="explanation"
                                className="document"
                                label="Explicação da pergunta"
                                type="text"
                                value={item.explanation || ''}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'questions',
                                    'explanation',
                                    index
                                  )
                                }
                              />

                              {typeModel === 0 && typeOfProcess > 0 && (
                                <Select
                                  name="quantitative_type"
                                  className="quantitative-type"
                                  label="Tipo da pergunta"
                                  type="text"
                                  options={quantitativeTypesOptions}
                                  placeholder="Selecione um tipo"
                                  value={quantitativeTypesOptions.find(
                                    option =>
                                      option.value === item.quantitative_type
                                  )}
                                  onChange={e =>
                                    handleSelectChange(
                                      e,
                                      'questions',
                                      'quantitative_type',
                                      index
                                    )
                                  }
                                />
                              )}

                              <div>
                                <Checkbox
                                  id={`question.required.${index}`}
                                  name="required"
                                  className="checkbox"
                                  label="Obrigatório"
                                  checked={item.required}
                                  onChange={e =>
                                    handleInputChange(
                                      e,
                                      'questions',
                                      'required',
                                      index
                                    )
                                  }
                                />
                              </div>

                              <div>
                                <Checkbox
                                  id={`question.key_question.${index}`}
                                  name="key_question"
                                  className="checkbox"
                                  label="Pergunta Chave"
                                  checked={item.key_question}
                                  onChange={e => {
                                    handleInputChange(
                                      e,
                                      'questions',
                                      'key_question',
                                      index
                                    );
                                  }}
                                />
                              </div>

                              <div>
                                <Checkbox
                                  id={`question.modifiable_schedule.${index}`}
                                  name="modifiable_schedule"
                                  className="checkbox"
                                  label="Modificavel na Agenda"
                                  checked={item.modifiable_schedule}
                                  onChange={e => {
                                    handleInputChange(
                                      e,
                                      'questions',
                                      'modifiable_schedule',
                                      index
                                    );
                                  }}
                                />
                              </div>

                              <Select
                                name="type_question"
                                className="type"
                                label="Tipo da pergunta"
                                options={TypesQuestion}
                                placeholder="Selecione um tipo"
                                value={TypesQuestion.find(
                                  option => option.value === item.type_question
                                )}
                                onChange={e =>
                                  handleSelectChange(
                                    e,
                                    'questions',
                                    'type_question',
                                    index
                                  )
                                }
                              />
                            </section>
                          </Scope>
                        ))}
                      </>
                    )}

                    {typeModel !== 2 && (
                      <>
                        <Title>
                          <h4 className="description">ETAPAS</h4>
                          <button
                            type="button"
                            onClick={() => handleNewPhase(null)}
                          >
                            <FaPlus size={10} />
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              setChangeOrderPhases(prevState => !prevState)
                            }
                            className="order"
                          >
                            <FaExchangeAlt size={10} />
                          </button>
                        </Title>
                        {processType.phases.map((item, index) => (
                          <Scope path={`phases[${index}]`} key={index}>
                            <section className="phase">
                              {changeOrderPhases ? (
                                <Options>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleChangeOrderPhases(index, 'up')
                                    }
                                  >
                                    <FaAngleUp size={10} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleChangeOrderPhases(index, 'down')
                                    }
                                  >
                                    <FaAngleDown size={10} />
                                  </button>
                                </Options>
                              ) : (
                                <Options>
                                  <button
                                    type="button"
                                    onClick={() => handleNewPhase(index)}
                                  >
                                    <FaPlus size={10} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => handleDeletePhase(index)}
                                  >
                                    <FaMinus size={10} />
                                  </button>
                                </Options>
                              )}
                              <Input
                                name="abbreviation"
                                className="abreviate"
                                label="Abreviatura"
                                type="text"
                                maxLength="6"
                                value={item.abbreviation}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'phases',
                                    'abbreviation',
                                    index
                                  )
                                }
                              />
                              <Input
                                name="document"
                                className="document"
                                label="Documento"
                                type="text"
                                value={item.document}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'phases',
                                    'document',
                                    index
                                  )
                                }
                              />
                              <Select
                                name="author"
                                className="type"
                                label="Autor"
                                type="text"
                                options={usersOptions}
                                placeholder="Selecione um autor"
                                value={usersOptions.find(
                                  option => option.value === item.author
                                )}
                                onChange={e =>
                                  handleSelectChange(
                                    e,
                                    'phases',
                                    'author',
                                    index
                                  )
                                }
                              />
                              <Select
                                name="responsible_id"
                                className="type"
                                label="Responsável"
                                type="text"
                                options={usersOptions}
                                placeholder="Selecione um responsável"
                                value={usersOptions.find(
                                  option => option.value === item.responsible_id
                                )}
                                onChange={e =>
                                  handleSelectChange(
                                    e,
                                    'phases',
                                    'responsible_id',
                                    index
                                  )
                                }
                              />
                              <Select
                                name="type"
                                className="type"
                                label="Tipo"
                                type="text"
                                options={phaseTypesOptions}
                                placeholder="Selecione um tipo"
                                value={phaseTypesOptions.find(
                                  option => option.value === item.type
                                )}
                                onChange={e =>
                                  handleSelectChange(e, 'phases', 'type', index)
                                }
                              />

                              {item.type === 2 && (
                                <Select
                                  name="model_to_create"
                                  className="type"
                                  label="Modelo do processo"
                                  type="text"
                                  options={
                                    id
                                      ? processModelsOptions.filter(
                                          pm => pm.value !== id
                                        )
                                      : processModelsOptions
                                  }
                                  placeholder="Selecione um modelo"
                                  value={processModelsOptions.find(
                                    option =>
                                      option.value === item.model_to_create
                                  )}
                                  onChange={e =>
                                    handleSelectChange(
                                      e,
                                      'phases',
                                      'model_to_create',
                                      index
                                    )
                                  }
                                />
                              )}
                            </section>
                            <section className="no-options">
                              <Input
                                name="dead_line"
                                className="deadline"
                                label="Prazo Interno"
                                type="number"
                                value={item.dead_line}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'phases',
                                    'dead_line',
                                    index
                                  )
                                }
                              />
                              <Input
                                name="dead_line_client"
                                className="deadline"
                                label="Prazo P/ Cliente"
                                type="number"
                                value={item.dead_line_client}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'phases',
                                    'dead_line_client',
                                    index
                                  )
                                }
                              />
                              <Input
                                name="extension_days"
                                className="deadline"
                                label="Prorrogação"
                                type="number"
                                value={item.extension_days}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'phases',
                                    'extension_days',
                                    index
                                  )
                                }
                              />
                              <Checkbox
                                id={`phase.notificate.${index}`}
                                name="notificate"
                                className="checkbox"
                                label="Notificar"
                                checked={item.notificate}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'phases',
                                    'notificate',
                                    index
                                  )
                                }
                              />
                              <Checkbox
                                id={`phase.required.${index}`}
                                name="required"
                                className="checkbox"
                                label="Obrigatório"
                                checked={item.required}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'phases',
                                    'required',
                                    index
                                  )
                                }
                              />

                              <Select
                                name="related_pop"
                                className="type"
                                label="POP Relacionado"
                                type="text"
                                options={popOptions}
                                placeholder="Selecione um POP"
                                value={popOptions.find(
                                  option => option.value === item.related_pop
                                )}
                                onChange={e =>
                                  handleSelectChange(
                                    e,
                                    'phases',
                                    'related_pop',
                                    index
                                  )
                                }
                              />

                              <Checkbox
                                id={`phase.document_required.${index}`}
                                name="document_required"
                                className="checkbox"
                                label="Documento Obrigatório"
                                checked={item.document_required || false}
                                onChange={e =>
                                  handleInputChange(
                                    e,
                                    'phases',
                                    'document_required',
                                    index
                                  )
                                }
                              />

                              {item.document_required && (
                                <>
                                  <Checkbox
                                    id={`phase.generate_protocol.${index}`}
                                    name="generate_protocol"
                                    className="checkbox"
                                    label="Protocolo Automático"
                                    checked={item.generate_protocol || false}
                                    onChange={e =>
                                      handleInputChange(
                                        e,
                                        'phases',
                                        'generate_protocol',
                                        index
                                      )
                                    }
                                  />
                                  <Select
                                    name="document_id"
                                    className="type"
                                    label="Documento"
                                    type="text"
                                    options={documentsOptions}
                                    placeholder="Selecione um documento"
                                    value={documentsOptions.find(
                                      option =>
                                        option.value === item.document_id
                                    )}
                                    onChange={e =>
                                      handleSelectChange(
                                        e,
                                        'phases',
                                        'document_id',
                                        index
                                      )
                                    }
                                  />
                                </>
                              )}
                            </section>
                          </Scope>
                        ))}
                      </>
                    )}
                  </ProcessTypeInfo>
                </FormContainer>
              )}
          </Content>
        )}
      </Container>
      {loading && <Loading />}

      <IconsOptionsModal
        onClick={handleSelectIcon}
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(open => !open)}
      />
    </>
  );
};

export default Form;

import React from 'react';

import { Spinner } from '~/components/Spinner';
import ClearBackground from '~/components/ClearBackground';

import { LoadingContainer } from './styles';

const Loading = () => {
  return (
    <>
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
      <ClearBackground />
    </>
  );
};

export default Loading;

import React, { useRef } from 'react';
import { FaTimes, FaTimesCircle } from 'react-icons/fa';

import ClearBackground from '../../../../../components/ClearBackground';

import { Container, Content, Left, Right, Header } from './styles';

const CancellationReasonModal = ({ setIsOpen, handleCancel, processIndex }) => {
  const inputRef = useRef(null);

  return (
    <>
      <ClearBackground />
      <Container>
        <Content>
          <Left>
            <FaTimesCircle size={38} color="#01579b" />
          </Left>
          <Right>
            <Header>
              <FaTimes onClick={setIsOpen} size={14} color="#e53935" />
              <strong>Motivo do cancelamento:</strong>
            </Header>

            <input name="name" type="text" ref={inputRef} />
            <button
              type="button"
              onClick={() => handleCancel(inputRef.current.value, processIndex)}
            >
              Cancelar processo
            </button>
          </Right>
        </Content>
      </Container>
    </>
  );
};

export default CancellationReasonModal;

import { format } from 'date-fns';
import React from 'react';
import formatContact from '~/util/formatContact';
import { Container, Ticket, Td, CompanyInfo, TdTable } from './styles';

const DeadFile = ({ protocol, company, settings }) => {
  const phoneCompany = company.phone
    ? company.phone > 9999999999
      ? formatContact(3, company.phone)
      : formatContact(2, company.phone)
    : '';

  return (
    <Container>
      <Ticket border="1">
        <thead>
          <tr>
            <td rowSpan="2" colSpan="1">
              <div className="logo">
                <img
                  src={
                    company.logo
                      ? company.logo_url
                      : 'https://myhappen.blob.core.windows.net/files-companies/28978_config_company_logo.png'
                  }
                  alt="icone-logo"
                  width="110"
                  height="110"
                />
              </div>
            </td>
            <Cell
              rowSpan="1"
              colSpan="1"
              className="protocol"
              label="Arquivo morto"
              value={protocol.client.name}
            />
            <Cell
              rowSpan="1"
              colSpan="1"
              className="number"
              value={protocol.cod}
              label="Número"
            />
          </tr>
          <tr>
            <Cell
              rowSpan="1"
              colSpan="1"
              className="protocol"
              value={protocol.receiver || ''}
              label="Assinatura"
            />
            <Cell
              rowSpan="1"
              colSpan="1"
              className="number"
              value={protocol.delivered_date}
              label="Data de recebimento"
            />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan="1" colSpan="3">
              <TdTable>
                <table>
                  <thead>
                    <tr>
                      <th className="document">Documento</th>
                      <th className="comp">Comp. (Início)</th>
                      <th className="comp">Comp. (Fim)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {protocol.documents.map(document => (
                      <>
                        <tr key={document.id}>
                          <td className="document">
                            {document.document.description}
                          </td>
                          <td className="comp">{document.competence}</td>
                          <td className="comp">{document.final_competence}</td>
                        </tr>
                        <tr>
                          <td className="obs" colSpan="3">
                            {document.obs}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
                <br />
                <span style={{ whiteSpace: 'pre-line' }}>
                  {settings.description
                    ? settings.description
                    : `
                    Prezado Cliente, os arquivos contidos neste pacote deverão ser
                    06 anos - Documentos Fiscais (Notas de Entrada, Saída, CTRC e DANFE), Documentos de Caixa (Duplicatas, Boletos e Impostos Pagos).
                    40 anos - Documentos Trabalhistas (Documentos de Admissão, Rescisões, Folhas de Pagamento, GFIP, GRFC, GPS, ASO, PPP, e demais documentos trabalhistas).
                    Indeterminado - Notas Fiscais de Ativos Imobilizados deverão ser guardadas enquanto o imobilizado existir (máquinas, móveis, veículos, etc).
                    Atenção não nos responsabilizamos pelo conteúdo deste volume caso a embalagem esteja violada.
                  `}
                </span>
              </TdTable>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <Cell
              rowSpan="1"
              colSpan="1"
              className="date"
              value={format(new Date(), 'dd/MM/yyyy')}
              label="Data de impressão"
            />
            <Cell
              rowSpan="1"
              colSpan="2"
              className="user"
              value={protocol.created_by}
              label="Usuário"
            />
          </tr>
        </tfoot>
      </Ticket>
      <CompanyInfo>
        <strong>{company.name}</strong>
        <span>{phoneCompany}</span>
        <span>{company.email}</span>
      </CompanyInfo>
    </Container>
  );
};

export default DeadFile;

const Cell = ({ label, value, className, rowSpan, colSpan }) => {
  return (
    <Td rowSpan={rowSpan} colSpan={colSpan}>
      <div className={className}>
        {label && <span>{label}</span>}
        <strong>{value}</strong>
      </div>
    </Td>
  );
};

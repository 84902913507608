import React from 'react';
import { FaTimes } from 'react-icons/fa';

import Modal from '~/components/Modal';

import { Header, Content, Model } from './styles';

const NewsProcessModal = ({ type, isOpen, setIsOpen, data, moveTo }) => {
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      widthProp={550}
      heightProp={600}
    >
      <Header>
        <div>
          <h1>
            {type === 0 && 'Seus novos processos'}
            {type === 1 && 'Seus novos processos trabalhistas'}
            {type === 2 && 'Seus novos controles de vencimento'}
          </h1>
        </div>
        <aside>
          <button type="button" onClick={setIsOpen}>
            <FaTimes size={20} color="#44546a" />
          </button>
        </aside>
      </Header>

      <Content>
        {data.length === 0 && (
          <div className="empty">
            Você já visualizou todos os seus processos
          </div>
        )}
        {data.map(item => (
          <Model key={item.id} onClick={() => moveTo(item.id, true)}>
            <h3>{item.menu_title || item.title}:</h3>
            <div>
              {item.processes.map(process => (
                <span key={process.id}> - Cliente {process.client}</span>
              ))}
            </div>
          </Model>
        ))}
      </Content>
    </Modal>
  );
};

export default NewsProcessModal;

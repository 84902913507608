import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    > button {
      margin-left: 16px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }

    a {
      margin-left: 10px;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  h1 {
    margin-top: 1rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.blue};
    font-size: 1.5rem;
  }
`;

export const ProcessTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-around;

    height: 4rem;
    width: 20rem;
    margin: 20px 10px;

    cursor: pointer;

    font-size: 1rem;
    opacity: 1;
    border-radius: 5px;

    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Type = styled.div`
  background: rgba(230, 230, 230);
  color: #000;

  border: 0;
  border-bottom: 3px solid ${({ theme }) => theme.colors.blue};

  svg {
    margin: 0 1rem;
  }

  span {
    justify-content: center;
    width: 17rem;
  }
`;

export const NewType = styled.div`
  background: ${({ theme }) => theme.colors.blue};
  color: #fff;

  border: 0;
`;

export const InProgress = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    text-align: center;

    margin: 20px 0 0 20px;
    position: absolute;
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 10px;
    font-weight: bold;
    width: 15px;
    height: 15px;
    border: 0;
    border-radius: 50%;
  }
`;

export const NewProcessButton = styled.div`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
  cursor: pointer;

  div {
    width: 10px;
    height: 10px;
    border: 5px solid #f4c306;
    border-radius: 50%;

    position: absolute;
    margin: -10px 0 0 50px;
  }
`;

import React, { useCallback, useState, useRef, useEffect } from 'react';
import { FaFileAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

import base64 from 'base-64';
import { format, parseISO } from 'date-fns';
import produce from 'immer';
import Loading from '~/components/Loading';
import { ButtonSpinner } from '../../components/Spinner';
import ConfirmWindow from '~/components/ConfirmWindow';

import api from '~/services/api';

import { Container, Header, Content, Document, Login } from './styles';

const Portal = ({ match }) => {
  const [id, setId] = useState(null);

  const inputRef = useRef('');
  const [errorInput, setErrorInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);

  const [companyData, setCompanyData] = useState('');
  const [protocolCod, setProtocolCod] = useState('');
  const [protocolDocuments, setProtocolDocuments] = useState([]);
  const [showDocuments, setShowDocuments] = useState(false);

  const [user, setUser] = useState(null);

  const [portalLink, setPortalLink] = useState('');

  useEffect(() => {
    (async () => {
      if (id && user) {
        try {
          setLoading(true);

          const response = await api.get(`portal/protocol/${id}`);

          const {
            cod,
            documents,
            documentViews,
            client,
            company,
          } = response.data;

          setProtocolCod(cod);
          setCompanyData(company);

          const redirectParams = base64.encode(
            JSON.stringify({
              companyId: company.id,
              user: user.document,
              password: user.portalPassword,
            })
          );
          setPortalLink(
            `http://app.diretiva1.com.br/portal/redirect/${redirectParams}`
          );

          if (documents.length > 0) {
            setProtocolDocuments(
              documents.map(item => ({
                id: item.id,
                document_view: documentViews.find(
                  documentView =>
                    documentView.document_id === item.id &&
                    documentView.relationship_id === user.relationship_id
                ),
                name: item.document.description,
                client: client.nickname,
                title_to_pay: item.document.title_to_pay,
                competence: item.competence,
                deadline: item.deadline_date
                  ? format(parseISO(item.deadline_date), 'dd/MM/yyyy')
                  : '',
                price: item.price ? `R$ ${item.price}` : '',
                obs: item.obs,
                document_url: item.file_url,
              }))
            );
          } else {
            toast.warn('Nenhum documento disponível.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setLoading(false);
        } catch {
          setLoading(false);
          toast.error('Falha ao buscar documentos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    })();
  }, [id, user]);

  const handleSubmit = useCallback(
    async value => {
      if (value && value.trim() !== '') {
        try {
          setLoadingLogin(true);

          const response = await api.post('/portal/login', {
            protocol_id: id,
            email: value,
          });

          if (response.data) {
            setUser(response.data);
            setShowDocuments(true);
            localStorage.setItem('@Diretiva:portal:login:email', value);
          } else {
            setErrorInput('Usuário inválido.');
          }

          setLoadingLogin(false);
          setLoading(false);
        } catch {
          toast.error('Falha ao fazer login.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoadingLogin(false);
          setLoading(false);
        }
      }
      setLoading(false);
    },
    [id]
  );

  useEffect(() => {
    const info = JSON.parse(base64.decode(match.params.info));
    setId(info.id);

    const emailStorage = info.email
      ? info.email
      : localStorage.getItem('@Diretiva:portal:login:email');

    if (emailStorage) {
      setLoading(true);
      handleSubmit(emailStorage);
    }
  }, [handleSubmit, match.params.info]);

  const openFile = useCallback(
    async (index, document_view_id, blobName) => {
      try {
        await api.put(`portal/downloaded/${document_view_id}`, {
          protocol_id: id,
        });

        setProtocolDocuments(
          produce(protocolDocuments, draft => {
            draft[index].document_view.hasDownloaded = true;
          })
        );

        const response = await api.get('portal/download-file', {
          params: {
            blobName,
          },
          responseType: 'blob',
        });

        const fileURL = URL.createObjectURL(response.data);

        window.open(fileURL, '_blank');
      } catch {
        toast.error('Falha ao baixar documento.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [id, protocolDocuments]
  );

  const confirmOpenFile = useCallback(
    (index, document_view_id, blobName) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => openFile(index, document_view_id, blobName)}
              onClose={onClose}
              message="Ao baixar esse documento você não será avisado futuramente sobre seu vencimento."
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [openFile]
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          {!showDocuments ? (
            <Login>
              <div>
                <div>
                  <h2>Login</h2>
                  <span>Entre com seu email para acessar os documentos.</span>
                </div>
                <div>
                  <label>Email</label>
                  <input
                    type="text"
                    ref={inputRef}
                    onClick={() => setErrorInput(null)}
                  />
                  {errorInput && <span className="error">{errorInput}</span>}
                </div>
                <button
                  type="button"
                  onClick={() => handleSubmit(inputRef.current.value)}
                >
                  {loadingLogin ? <ButtonSpinner size={14} /> : 'Entrar'}
                </button>
              </div>
            </Login>
          ) : (
            <>
              <Header>
                <div className="logo">
                  {companyData && <img src={companyData.logo_url} alt="logo" />}
                </div>
                <div className="protocol">
                  <span>
                    Protocolo: {protocolCod} - {protocolDocuments.length}{' '}
                    documento(s)
                  </span>
                </div>
                <div className="user">
                  <h3>{user.name}</h3>
                </div>
              </Header>
              <Content>
                {protocolDocuments.map((item, index) => (
                  <Document key={item.id}>
                    <section>
                      <div className="document">
                        <FaFileAlt size="60" />
                      </div>
                      <section>
                        <strong
                          className={
                            item.document_view.hasDownloaded
                              ? 'downloaded'
                              : 'not-downloaded'
                          }
                        >
                          {item.document_view.hasDownloaded
                            ? 'Baixado'
                            : 'Não baixado'}
                        </strong>
                      </section>
                    </section>

                    <div className="info">
                      <section>
                        <strong style={{ color: '#01579B' }}>
                          {item.client}
                        </strong>
                      </section>

                      <section>
                        <strong>{item.name}</strong>
                      </section>

                      <section>
                        <span>
                          {item.competence} - {item.price}
                        </span>
                      </section>

                      <section>
                        <strong>Vencimento: {item.deadline}</strong>
                      </section>

                      <section>
                        <span>{item.obs}</span>
                      </section>

                      <button
                        type="button"
                        onClick={() => {
                          if (
                            item.title_to_pay &&
                            !item.document_view.hasDownloaded
                          ) {
                            confirmOpenFile(
                              index,
                              item.document_view.id,
                              item.document_url
                            );
                          } else {
                            openFile(
                              index,
                              item.document_view.id,
                              item.document_url
                            );
                          }
                        }}
                      >
                        Baixar
                      </button>
                    </div>
                  </Document>
                ))}

                <Document>
                  <section>
                    <div className="document">
                      <img src={companyData.logo_url} alt="logo" />
                    </div>
                    <section />
                  </section>

                  <div className="info">
                    <section>
                      Clique aqui para acessar nosso portal e baixar mais
                      documentos do seu negócio
                    </section>

                    <a
                      href={portalLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Acessar
                    </a>
                  </div>
                </Document>
              </Content>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default Portal;

import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }
  }

  @media print {
    & {
      display: none;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  button {
    background: transparent;
    border: none;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  .name,
  .taxation_type {
    width: 20%;
  }

  .document_type,
  .situation,
  .client_type,
  .contract_type {
    width: 14%;
  }

  > div + div {
    margin-left: 16px;
  }

  button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;
    width: 4%;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }

  .search-name {
    margin-left: 0px;

    span {
      position: absolute;
      margin-top: 18px;
      margin-left: -15px;
      font-size: 12px;
      font-weight: bold;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .active {
      padding-left: 8px;
      width: 30px;
      text-align: center;
    }

    .document {
      width: 145px;
    }

    .name {
      width: 25%;
      max-width: 0;
    }

    .city {
      width: 10%;
    }

    .group {
      width: 10%;
      max-width: 0;
    }

    .cei,
    .state_registration {
      width: 145px;
    }

    .client_type,
    .contract_type {
      width: 10%;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;

  .document_type,
  .situation {
    width: 10%;
  }

  .document,
  .client_type,
  .closing_tax {
    width: 15%;
  }

  .acquisition_type {
    width: 20%;
  }

  .nickname,
  .cei,
  .state_registration,
  .phone,
  .protocol_preference,
  .birthday,
  .contract_date,
  .start_day,
  .end_day,
  .tributes_payment {
    width: 25%;
  }

  .group {
    width: 50%;
  }

  .name {
    width: 60%;
  }

  .closing_tax {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    > input {
      margin-right: 16px;
      transform: scale(1.5);
    }
  }
`;

export const Addresses = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
`;

export const Address = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  .zipcode,
  .number,
  .type {
    width: 15%;
  }

  .street {
    width: 55%;
  }

  .complement,
  .neighborhood,
  .city,
  .state {
    width: 25%;
  }
`;

export const Contacts = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .contact_type {
    width: 25%;
  }

  .content {
    width: 75%;
  }
`;

export const RelatedList = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
`;

export const Related = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  .related_name,
  .related_treatment_name,
  .related_email {
    width: 25%;
  }

  .related_birthday {
    width: 10%;
  }

  .related_document,
  .related_contact {
    width: 20%;
  }

  .dpt_tax,
  .dpt_labour,
  .dpt_accounting,
  .dpt_financial,
  .dpt_admin,
  .responsible {
    width: 16%;

    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    > input {
      margin-right: 16px;
      transform: scale(1.5);
    }
  }
`;

export const Tags = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .tag {
    width: 100%;
  }
`;

export const Audit = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .date,
  .user,
  .action {
    width: 33%;
  }
`;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FaMailBulk,
  FaTimes,
  FaEdit,
  FaEye,
  FaPlus,
  FaEnvelope,
} from 'react-icons/fa';
import { useAuth } from '~/hooks';
import api from '~/services/api';
import { TableLoading } from '~/components/Table';

import MailForm from './Form';
import { FormContainer, TextArea } from '~/components/Form';

import {
  Container,
  Header,
  Controls,
  MailSettingsInfo,
  ReadOnly,
  NoSettings,
} from './styles';

const MailSettings = () => {
  const { user, company } = useAuth();

  const formRef = useRef(null);
  const [typeHeaderData, setTypeHeaderData] = useState(0);
  const [typeFooterData, setTypeFooterData] = useState(0);
  const [typeServerData, setTypeServerData] = useState(0);
  const [initialData, setInitialData] = useState(null);

  const [loading, setLoading] = useState(1);

  const loadSettings = useCallback(async () => {
    if (user && company) {
      try {
        setLoading(1);
        const response = await api.get(`/mails-smtp/${company.id}`);

        if (response.data) {
          const {
            id,
            email,
            typeServer,
            typeHeader,
            header,
            headerLink,
            typeFooter,
            footer,
            footerLink,
          } = response.data;

          setTypeHeaderData(typeHeader);
          setTypeFooterData(typeFooter);
          setTypeServerData(typeServer);

          setInitialData({
            id,
            email,
            header,
            headerLink,
            footer,
            footerLink,
          });
        }

        setLoading(0);
      } catch (err) {
        toast.error('Falha ao buscar configurações de email.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setLoading(0);
      }
    }
  }, [user, company]);

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  const openFile = useCallback(async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  }, []);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaMailBulk size={20} color="#44546a" />
            <h1>Configurações de Email</h1>
          </div>
          <aside>
            <Link to="/">
              <FaTimes size={20} color="#44546a" />
            </Link>
          </aside>
        </Header>

        {loading ? (
          <TableLoading />
        ) : (
          <>
            <Controls>
              {initialData ? (
                <Link
                  to={{
                    pathname: '/mail-settings/edit',
                    state: { id: initialData.id },
                  }}
                >
                  <FaEdit />
                  <span>Editar</span>
                </Link>
              ) : (
                <Link
                  to={{ pathname: '/mail-settings/new', state: { id: null } }}
                >
                  <FaPlus />
                  <span>Criar</span>
                </Link>
              )}
            </Controls>

            {initialData ? (
              <FormContainer ref={formRef} className="content">
                <MailSettingsInfo>
                  <h4>SMTP</h4>
                  <section>
                    <ReadOnly width="100%">
                      <label>Servidor</label>
                      {typeServerData === 0 && (
                        <input name="typeServer" value="Padrão" readOnly />
                      )}
                      {typeServerData === 1 && (
                        <input name="typeServer" value="Gmail" readOnly />
                      )}
                      {typeServerData === 2 && (
                        <input name="typeServer" value="Outlook" readOnly />
                      )}
                    </ReadOnly>
                  </section>

                  <section>
                    <ReadOnly width="100%">
                      <label>Email</label>
                      <input
                        name="email"
                        defaultValue={initialData.email}
                        readOnly
                      />
                    </ReadOnly>
                  </section>

                  <h4>CABEÇALHO</h4>
                  <section>
                    {typeHeaderData === 1 ? (
                      <>
                        <ReadOnly width="20%">
                          <label>Tipo</label>
                          <input name="typeHeader" value="Imagem" readOnly />
                        </ReadOnly>
                        <button
                          type="button"
                          onClick={() => openFile(initialData.headerLink)}
                          title="Visualizar arquivo"
                        >
                          <div className="imageView">
                            <FaEye size={16} color="#01579B" />
                            <p>Visualizar imagem</p>
                          </div>
                        </button>
                      </>
                    ) : (
                      <>
                        <ReadOnly width="20%">
                          <label>Tipo</label>
                          <input name="typeHeader" value="Texto" readOnly />
                        </ReadOnly>

                        <TextArea
                          name="header"
                          label="Texto"
                          className="description"
                          value={initialData.header}
                          readOnly
                        />
                      </>
                    )}
                  </section>

                  <h4>RODAPÉ</h4>
                  <section>
                    {typeFooterData === 1 ? (
                      <>
                        <ReadOnly width="20%">
                          <label>Tipo</label>
                          <input name="typeFooter" value="Imagem" readOnly />
                        </ReadOnly>

                        <button
                          type="button"
                          onClick={() => openFile(initialData.footerLink)}
                          title="Visualizar arquivo"
                        >
                          <div className="imageView">
                            <FaEye size={16} color="#01579B" />
                            <p>Visualizar imagem</p>
                          </div>
                        </button>
                      </>
                    ) : (
                      <>
                        <ReadOnly width="20%">
                          <label>Tipo</label>
                          <input name="typeFooter" value="Texto" readOnly />
                        </ReadOnly>
                        <TextArea
                          name="footer"
                          label="Texto"
                          className="description"
                          value={initialData.footer}
                          readOnly
                        />
                      </>
                    )}
                  </section>
                </MailSettingsInfo>
              </FormContainer>
            ) : (
              <NoSettings>
                <FaEnvelope size={50} color="#E53935" />
                <span>Configurações de email inexistentes</span>
                <Link
                  to={{ pathname: '/mail-settings/new', state: { id: null } }}
                >
                  Criar configurações
                </Link>
              </NoSettings>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export { MailSettings, MailForm };

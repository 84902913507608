import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  > span {
    position: absolute;
    top: 5px;
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
  }
`;

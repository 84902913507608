import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FaDollarSign,
  FaSave,
  FaBroom,
  FaTimes,
  FaPlus,
  FaMinus,
} from 'react-icons/fa';
import { parseISO } from 'date-fns';
import { Scope } from '@unform/core';
import * as Yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import produce from 'immer';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import {
  FormContainer,
  FormLoading,
  Select,
  Input,
  Checkbox,
  DatePicker,
} from '~/components/Form';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';

import {
  Container,
  Header,
  Controls,
  Content,
  Company,
  Periods,
  Period,
} from './styles';

const { v4: uuid } = require('uuid');

const Form = () => {
  const { company, companyUsers } = useAuth();

  const { state } = useLocation();

  const id = state?.id || null;

  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [clientsLoading, setClientsLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [tributaryProfile, setTributaryProfile] = useState(null);
  const [clients, setClients] = useState(null);

  const [requiredPeriodErrorMessage, setRequiredPeriodErrorMessage] = useState(
    ''
  );

  useEffect(() => {
    async function loadProfile() {
      if (id) {
        try {
          setLoading(true);

          const response = await api.get(`tributary-profiles/${id}`);

          const { data } = response;

          const formattedPeriods = data.periods.map(period => ({
            ...period,
            start_period: parseISO(period.start_period),
            end_period: period.end_period ? parseISO(period.end_period) : null,
          }));

          data.periods = formattedPeriods;

          setTributaryProfile(data);

          setLoading(false);
        } catch (err) {
          toast.error('Falha ao buscar perfil tributário.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      } else {
        setTributaryProfile({
          id: uuid(),
          periods: [],
          active: true,
        });
      }
    }

    loadProfile();
  }, [id]);

  useEffect(() => {
    async function loadClients() {
      if (company) {
        setClientsLoading(true);

        const response = await api.get(
          `tributary-profiles/clients/${company.id}`
        );

        const clientsOptions = response.data.map(client => ({
          value: client.id,
          label: client.name,
        }));

        setClients(clientsOptions);

        setClientsLoading(false);
      }
    }

    loadClients();
  }, [company]);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            client: Yup.string().required('A empresa é obrigatória'),
            contract_type: Yup.string().required(
              'O tipo de contrato é obrigatório'
            ),
            periods: Yup.array()
              .min(1)
              .of(
                Yup.object().shape({
                  taxation_type: Yup.string()
                    .required('A tributação é obrigatória')
                    .nullable(),
                  start_period: Yup.string()
                    .nullable()
                    .required('A data de início é obrigatória'),
                  end_period: Yup.string().nullable(),
                  labour: Yup.string().nullable(),
                  tax: Yup.string().nullable(),
                  accounting: Yup.string().nullable(),
                })
              )
              .required('Pelo menos um período é obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const tributaryProfileData = {
            client_id: data.client.id,
            contract_type: data.contract_type,
            situation: data.situation,
          };

          if (id) {
            const decodedId = id;

            const periodsData = data.periods.map(period => {
              return {
                id: Number(period.id),
                tributary_id: Number(decodedId),
                taxation_type: period.taxation_type,
                start_period: period.start_period,
                end_period: period.end_period || null,
                responsible_labour: period.labour,
                responsible_tax: period.tax,
                responsible_accounting: period.accounting,
              };
            });

            await Promise.all([
              api.put(`tributary-profiles/${id}`, tributaryProfileData),
              api.post('tributary-profiles/periods', periodsData),
            ]);
          } else {
            tributaryProfileData.company_id = company.id;

            const response = await api.post(
              'tributary-profiles',
              tributaryProfileData
            );

            const { id: tributary_id } = response.data;

            const periodsData = data.periods.map(period => {
              return {
                id: Number(period.id),
                tributary_id,
                taxation_type: period.taxation_type,
                start_period: period.start_period,
                end_period: period.end_period || null,
                responsible_labour: period.labour,
                responsible_tax: period.tax,
                responsible_accounting: period.accounting,
              };
            });

            await api.post('tributary-profiles/periods', periodsData);
          }

          formRef.current.setErrors({});

          toast.success('Perfil tributário salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push('/tributary-profile');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              if (error.path === 'periods') {
                setRequiredPeriodErrorMessage(error.message);
              }
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar perfil tributário.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setSaveLoading(false);
        }
      }
    },
    [id, company]
  );

  const contractTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'Terceirizado' },
      { value: 2, label: 'Próprio' },
    ];
  }, []);

  const taxationTypeOptions = useMemo(() => {
    return [
      { value: 6, label: 'Empregador PF' },
      { value: 5, label: 'Empregador Doméstico' },
      { value: 1, label: 'MEI' },
      { value: 11, label: 'MEI Somente Declarações' },
      { value: 7, label: 'Simples Nacional C/ Inscrição' },
      { value: 8, label: 'Simples Nacional S/ Inscrição' },
      { value: 3, label: 'Lucro Presumido' },
      { value: 4, label: 'Lucro Real' },
      { value: 9, label: 'Condomínio' },
      { value: 10, label: 'Terceiro Setor' },
    ];
  }, []);

  const usersOptions = useMemo(() => {
    if (companyUsers) {
      const options = companyUsers
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.id,
            label: userItem.short_name,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      return options;
    }

    return [];
  }, [companyUsers]);

  const resetForm = useCallback(() => {
    formRef.current.reset();
    formRef.current.setErrors({});
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/tributary-profile')}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleAddNewPeriod = useCallback(() => {
    setTributaryProfile(
      produce(tributaryProfile, draft => {
        const blankPeriod = {
          id: uuid(),
          taxation_type: null,
          start_period: '',
          end_period: '',
          labour: '',
          tax: '',
          accounting: '',
          new: true,
        };

        if (draft?.periods?.length > 0) {
          draft.periods.push(blankPeriod);
        } else {
          draft.periods[0] = blankPeriod;
        }
      })
    );
  }, [tributaryProfile]);

  const handleRemovePeriod = useCallback(
    index => {
      setTributaryProfile(
        produce(tributaryProfile, draft => {
          delete draft.periods[index];
        })
      );
    },
    [tributaryProfile]
  );

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaDollarSign size={20} color="#44546a" />
            <h1>Perfil Tributário</h1>
          </div>
        </Header>
        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {(loading || clientsLoading || !company || !companyUsers) && (
          <FormLoading className="loading" />
        )}
        {tributaryProfile && clients && (
          <Content className="content">
            <FormContainer
              ref={formRef}
              loading={loading ? 1 : 0}
              onSubmit={handleSubmit}
              initialData={tributaryProfile}
            >
              <Company>
                <h4>EMPRESA</h4>
                <section>
                  {id ? (
                    <Input
                      name="client.name"
                      className="client"
                      label="Empresa"
                      readOnly
                    />
                  ) : (
                    <Select
                      name="client.id"
                      className="client"
                      label="Empresa"
                      placeholder="Selecione uma empresa"
                      options={clients}
                    />
                  )}
                  <Select
                    name="contract_type"
                    className="contract_type"
                    label="Tipo de Contrato"
                    placeholder="Selecione um tipo"
                    options={contractTypeOptions}
                  />
                  <Checkbox
                    name="situation"
                    className="situation"
                    label="Ativo"
                  />
                </section>
              </Company>
              <Periods>
                <header>
                  <h4>PERÍODOS</h4>
                  <button type="button" onClick={handleAddNewPeriod}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {requiredPeriodErrorMessage && (
                  <span>{requiredPeriodErrorMessage}</span>
                )}

                {tributaryProfile.periods.length > 0 &&
                  tributaryProfile.periods.map((period, index) => (
                    <Period key={period.id}>
                      <button
                        type="button"
                        onClick={() => handleRemovePeriod(index)}
                      >
                        <FaMinus size={10} />
                      </button>

                      <Scope path={`periods[${index}]`}>
                        <section>
                          <Input
                            name="id"
                            type="text"
                            className="hide"
                            readOnly
                          />
                          <Select
                            name="taxation_type"
                            className="taxation_type"
                            label="Tributação"
                            placeholder="Selecione um tipo"
                            options={taxationTypeOptions}
                          />
                          <DatePicker
                            name="start_period"
                            className="start_period"
                            label="Início"
                          />
                          <DatePicker
                            name="end_period"
                            className="end_period"
                            label="Fim"
                          />
                        </section>
                        {period.new && (
                          <section className="responsibles">
                            <Select
                              name="labour"
                              className="labour"
                              label="Responsável Trabalhista"
                              placeholder="Selecione um responsável"
                              options={usersOptions}
                            />
                            <Select
                              name="tax"
                              className="tax"
                              label="Responsável Tributário"
                              placeholder="Selecione um responsável"
                              options={usersOptions}
                            />
                            <Select
                              name="accounting"
                              className="accounting"
                              label="Responsável Contábil"
                              placeholder="Selecione um responsável"
                              options={usersOptions}
                            />
                          </section>
                        )}
                      </Scope>
                    </Period>
                  ))}
              </Periods>
            </FormContainer>
          </Content>
        )}
      </Container>

      {saveLoading && <Loading />}
    </>
  );
};

export default Form;

import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { parseISO } from 'date-fns';
import { Scope } from '@unform/core';
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import {
  FaSlidersH,
  FaSave,
  FaBroom,
  FaTimes,
  FaPlus,
  FaMinus,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import {
  FormContainer,
  FormLoading,
  Select,
  Input,
  DatePicker,
  Checkbox,
} from '~/components/Form';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Header,
  Controls,
  Content,
  ParameterInfo,
  ResponsibleList,
  Responsible,
  Documents,
} from './styles';

const Form = () => {
  const { state } = useLocation();

  const id = state?.id || null;

  const { company, user, companyUser, companyUsers } = useAuth();

  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [clientsLoading, setClientsLoading] = useState(false);

  const [parameter, setParameter] = useState(null);
  const [clients, setClients] = useState([]);
  const [models, setModels] = useState([]);
  const [taxationType, setTaxationType] = useState(null);
  const [selectedStartPeriod, setSelectedStartPeriod] = useState(null);
  const [selectedEndPeriod, setSelectedEndPeriod] = useState(null);

  const [
    requiredResposnibleErrorMessage,
    setRequiredResponsibleErrorMessage,
  ] = useState('');

  useEffect(() => {
    async function loadParameter() {
      if (id) {
        try {
          setLoading(true);

          const response = await api.get(`checklists-parameters/${id}`);

          if (response.data) {
            const formattedParameter = response.data;

            const { start_period, end_period } = formattedParameter;

            formattedParameter.start_period = start_period
              ? parseISO(start_period)
              : null;
            formattedParameter.end_period = end_period
              ? parseISO(end_period)
              : null;

            formattedParameter.responsible =
              formattedParameter.responsible.length > 0 &&
              formattedParameter.responsible.map(responsible => ({
                ...responsible,
                start_period: responsible.start_period
                  ? parseISO(responsible.start_period)
                  : null,
                end_period: responsible.end_period
                  ? parseISO(responsible.end_period)
                  : null,
              }));
            formattedParameter.actives_documents = formattedParameter.parameter_document
              .filter(parameterDoc => parameterDoc.situation !== false)
              .map(parameterDoc => ({
                ...parameterDoc,
                start_period: parameterDoc.start_period
                  ? parseISO(parameterDoc.start_period)
                  : null,
                end_period: parameterDoc.end_period
                  ? parseISO(parameterDoc.end_period)
                  : null,
                model_document: {
                  id: parameterDoc.model_document.id,
                  document_order: parameterDoc.model_document.document_order,
                  company_document_id:
                    parameterDoc.model_document.company_document.id,
                  document_id:
                    parameterDoc.model_document.company_document.document.id,
                  document_name:
                    parameterDoc.model_document.company_document.document
                      .description,
                },
              }))
              .sort((a, b) => {
                if (
                  a.model_document.document_name <
                  b.model_document.document_name
                ) {
                  return -1;
                }
                if (
                  a.model_document.document_name >
                  b.model_document.document_name
                ) {
                  return 1;
                }
                return 0;
              });

            formattedParameter.inactives_documents = formattedParameter.parameter_document
              .filter(parameterDoc => parameterDoc.situation !== true)
              .map(parameterDoc => ({
                ...parameterDoc,
                start_period: parameterDoc.start_period
                  ? parseISO(parameterDoc.start_period)
                  : null,
                end_period: parameterDoc.end_period
                  ? parseISO(parameterDoc.end_period)
                  : null,
                model_document: {
                  id: parameterDoc.model_document.id,
                  document_order: parameterDoc.model_document.document_order,
                  company_document_id:
                    parameterDoc.model_document.company_document.id,
                  document_id:
                    parameterDoc.model_document.company_document.document.id,
                  document_name:
                    parameterDoc.model_document.company_document.document
                      .description,
                },
              }))
              .sort((a, b) => {
                if (
                  a.model_document.document_name <
                  b.model_document.document_name
                ) {
                  return -1;
                }
                if (
                  a.model_document.document_name >
                  b.model_document.document_name
                ) {
                  return 1;
                }
                return 0;
              });

            setParameter(formattedParameter);
          }

          setLoading(false);
        } catch {
          toast.error('Falha ao buscar parâmetro.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setLoading(false);
        }
      } else {
        setParameter({ responsible: [] });
      }
    }

    loadParameter();
  }, [id]);

  useEffect(() => {
    async function loadClients() {
      if (company) {
        setClientsLoading(true);

        const response = await api.get(
          `checklists-parameters/clients/${company.id}`,
          {
            params: {
              department: 'dpt_labour',
            },
          }
        );

        if (response.data) {
          const formattedClients = response.data.map(client => {
            return {
              value: client.id,
              label: client.name,
              start_period: client.start_day,
              end_period: client.end_period,
              taxation_type: client.tributary_profile.periods[0].taxation_type,
            };
          });

          setClients(formattedClients);
        }

        setClientsLoading(false);
      }
    }

    loadClients();
  }, [company]);

  useEffect(() => {
    async function loadModels() {
      if (company) {
        const response = await api.get('checklists-models', {
          params: {
            only_models: true,
            department: 'dpt_labour',
            company_id: company.id,
          },
        });

        const formattedModels = response.data.map(model => {
          return { value: model.id, label: model.model_name };
        });

        setModels(formattedModels);
      }
    }

    loadModels();
  }, [company]);

  const usersOptions = useMemo(() => {
    if (user && companyUsers && companyUser) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return { value: userItem.id, label: userItem.short_name };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: companyUser.id,
        label: user.short_name,
      });

      return options;
    }

    return [];
  }, [companyUsers, user, companyUser]);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            client: Yup.object().shape({
              name: Yup.string().required('A empresa é obrigatória.'),
            }),
            model: Yup.object().shape({
              name: Yup.string().required('O modelo é obrigatório.'),
            }),
            start_period: Yup.string().required(
              'A data de início é obrigatória.'
            ),
            responsible: Yup.array()
              .min(1)
              .of(
                Yup.object().shape({
                  company_user: Yup.object().shape({
                    id: Yup.string().required('O responsável é obrigatório.'),
                  }),
                  start_period: Yup.string()
                    .nullable()
                    .required('A data de início é obrigatória.'),
                  end_period: Yup.string().nullable(),
                })
              )
              .required('Pelo menos um responsável é obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          if (id) {
            await api.put(`checklists-parameters/${id}`, {
              obs: data.obs,
              active: data.active,
            });

            data.responsible = data.responsible.map(responsible => {
              return {
                id: responsible.id,
                parameter_id: id,
                responsible_id: responsible.company_user.id,
                start_period: responsible.start_period,
                end_period: responsible.end_period || null,
              };
            });

            await api.put(`checklists-parameters/responsibles/${company.id}`, {
              responsibles: data.responsible,
            });

            const actives_documents =
              data?.actives_documents?.map(document => {
                return {
                  id: document.id,
                  situation: document.situation,
                };
              }) || [];

            const inactives_documents =
              data?.inactives_documents?.map(document => {
                return {
                  id: document.id,
                  situation: document.situation,
                };
              }) || [];

            const documents = actives_documents.concat(inactives_documents);

            await api.put(`checklists-parameters/documents/${company.id}`, {
              documents,
              company_id: company.id,
              department: 'dpt_labour',
            });
          } else {
            const parameterData = {
              company_id: company.id,
              client_id: data.client.name,
              model_id: data.model.name,
              start_period: data.start_period,
              end_period: data.end_period || null,
            };

            const createdParameter = await api.post(
              'checklists-parameters',
              parameterData
            );

            const { id: parameter_id } = createdParameter.data;

            const promises = data.responsible.map(async responsible => {
              const responsibleData = {
                id: responsible.id,
                parameter_id,
                responsible_id: responsible.company_user.id,
                start_period: responsible.start_period,
                end_period: responsible.end_period || null,
              };

              await api.post(
                'checklists-parameters/responsibles',
                responsibleData
              );

              let taxation_type;

              if (taxationType === 1) {
                taxation_type = 'taxation_mei';
              }
              if (taxationType === 3) {
                taxation_type = 'taxation_lucro_presumido';
              }
              if (taxationType === 4) {
                taxation_type = 'taxation_lucro_real';
              }
              if (taxationType === 5) {
                taxation_type = 'taxation_empregador_domestico';
              }
              if (taxationType === 6) {
                taxation_type = 'taxation_empregador_pf';
              }
              if (taxationType === 7) {
                taxation_type = 'taxation_simples_com_inscricao';
              }
              if (taxationType === 8) {
                taxation_type = 'taxation_simples_sem_inscricao';
              }
              if (taxationType === 9) {
                taxation_type = 'taxation_condominio';
              }
              if (taxationType === 10) {
                taxation_type = 'taxation_terceiro';
              }
              if (taxationType === 11) {
                taxation_type = 'taxation_mei_somente_declaracoes';
              }

              const documentsData = {
                parameter_id,
                model_id: data.model.name,
                taxation_type,
                department: 'dpt_labour',
                start_period: responsible.start_period,
                company_id: company.id,
                client_id: data.client.name,
              };

              await api.post('checklists-parameters/documents', documentsData);
            });

            await Promise.all(promises);
          }

          formRef.current.setErrors({});

          toast.success('Parâmetro salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);

          history.push('/labour-parameter');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              if (error.path === 'responsible') {
                setRequiredResponsibleErrorMessage(error.message);
              }
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar parâmetro.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setSaveLoading(false);
        }
      }
    },
    [company, taxationType, id]
  );

  const resetForm = useCallback(() => {
    if (!id) {
      formRef.current.reset();
      formRef.current.setErrors({});
      setSelectedStartPeriod(null);
      setSelectedEndPeriod(null);
      setTaxationType(null);
    }
    setRequiredResponsibleErrorMessage('');
  }, [id]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/labour-parameter')}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleAddNewResponsible = useCallback(() => {
    setParameter(
      produce(parameter, draft => {
        const blankResponsible = {
          id: uuid(),
          start_period: null,
          end_period: null,
        };

        if (draft.responsible.length > 0) {
          draft.responsible.push(blankResponsible);
        } else if (draft.responsible) {
          draft.responsible[0] = blankResponsible;
        } else {
          draft.responsible = [blankResponsible];
        }
      })
    );

    setRequiredResponsibleErrorMessage('');
  }, [parameter]);

  const handleRemoveResponsible = useCallback(
    async (responsibleId, index) => {
      setParameter(
        produce(parameter, draft => {
          delete draft.responsible[index];
        })
      );

      await api.delete(`checklists-parameters/responsibles/${responsibleId}`);
    },
    [parameter]
  );

  const confirmRemove = useCallback(
    (functionToExecute, responsibleId, index) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => functionToExecute(responsibleId, index)}
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    []
  );

  const handleChangeClient = useCallback(e => {
    if (e.start_period) {
      setSelectedStartPeriod(parseISO(e.start_period));

      formRef.current.setFieldError('start_period', '');
    }

    if (e.end_period) {
      setSelectedEndPeriod(parseISO(e.end_period));
    }

    setTaxationType(e.taxation_type);
  }, []);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaSlidersH size={20} color="#44546a" />
            <h1>Parâmetros Trabalhistas</h1>
          </div>
        </Header>
        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {(loading ||
          clientsLoading ||
          !user ||
          !companyUser ||
          !company ||
          !companyUsers) && <FormLoading className="loading" />}
        {parameter && (
          <Content className="content">
            <FormContainer
              ref={formRef}
              loading={loading || clientsLoading ? 1 : 0}
              onSubmit={handleSubmit}
              initialData={parameter}
            >
              <ParameterInfo>
                <h4>PARÂMETRO</h4>
                <section>
                  {id ? (
                    <>
                      <Input
                        name="client.name"
                        className="client"
                        label="Empresa"
                        readOnly
                      />
                      <Input
                        name="model.name"
                        className="model"
                        label="Modelo"
                        readOnly
                      />
                      <DatePicker
                        name="start_period"
                        className="start_period"
                        label="Início"
                        readOnly
                      />
                      <DatePicker
                        name="end_period"
                        className="end_period"
                        label="Fim"
                        readOnly
                      />
                      <Checkbox
                        id="active"
                        name="active"
                        className="active"
                        label="Ativo"
                      />
                    </>
                  ) : (
                    <>
                      <Select
                        name="client.name"
                        className="client"
                        label="Empresa"
                        options={clients}
                        placeholder="Selecione uma empresa"
                        onChange={handleChangeClient}
                      />
                      <Select
                        name="model.name"
                        className="model"
                        label="Modelo"
                        options={models}
                        placeholder="Selecione um modelo"
                      />
                      <DatePicker
                        name="start_period"
                        className="start_period"
                        label="Início"
                        selected={selectedStartPeriod}
                        readOnly
                      />
                      <DatePicker
                        name="end_period"
                        className="end_period"
                        label="Fim"
                        selected={selectedEndPeriod}
                        readOnly
                      />
                    </>
                  )}
                </section>
                <section>
                  <Input name="obs" className="obs" label="Orientações" />
                </section>
              </ParameterInfo>
              <ResponsibleList>
                <header>
                  <h4>RESPONSÁVEIS</h4>
                  <button type="button" onClick={handleAddNewResponsible}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {requiredResposnibleErrorMessage && (
                  <span>{requiredResposnibleErrorMessage}</span>
                )}

                {parameter.responsible &&
                  parameter.responsible.length > 0 &&
                  parameter.responsible.map((responsible, index) => (
                    <Responsible key={responsible.id}>
                      <button
                        type="button"
                        onClick={() =>
                          confirmRemove(
                            handleRemoveResponsible,
                            responsible.id,
                            index
                          )
                        }
                      >
                        <FaMinus size={10} />
                      </button>
                      <Scope path={`responsible[${index}]`}>
                        <section>
                          <Input
                            name="id"
                            type="text"
                            className="hide"
                            readOnly
                          />
                          <Select
                            name="company_user.id"
                            className="responsible"
                            label="Responsável"
                            options={usersOptions}
                            placeholder="Selecione um responsável"
                          />
                          <DatePicker
                            name="start_period"
                            className="responsible_start_period"
                            label="Início"
                          />
                          <DatePicker
                            name="end_period"
                            className="responsible_end_period"
                            label="Fim"
                          />
                        </section>
                      </Scope>
                    </Responsible>
                  ))}
              </ResponsibleList>
              {id && (
                <>
                  <Documents>
                    <h4>DOCUMENTOS ATIVOS</h4>
                    {parameter &&
                      parameter.actives_documents.map((document, index) => (
                        <React.Fragment key={`${document.id}`}>
                          <Scope path={`actives_documents[${index}]`}>
                            <section>
                              <Input
                                name="id"
                                type="text"
                                className="hide"
                                readOnly
                              />
                              <Checkbox
                                id={`active-status${index}`}
                                name="situation"
                                className="status"
                                label="Ativo"
                              />
                              <Input
                                name="model_document.document_name"
                                className="document"
                                label="Documento"
                                readOnly
                              />
                              <Input
                                name="model_document.obs"
                                className="obs"
                                label="Obs"
                              />
                            </section>
                          </Scope>
                        </React.Fragment>
                      ))}
                  </Documents>
                  <Documents>
                    <h4>DOCUMENTOS INATIVOS</h4>
                    {parameter &&
                      parameter.inactives_documents.map((document, index) => (
                        <React.Fragment key={`${document.id}`}>
                          <Scope path={`inactives_documents[${index}]`}>
                            <section>
                              <Input
                                name="id"
                                type="text"
                                className="hide"
                                readOnly
                              />
                              <Checkbox
                                id={`unactive-status${index}`}
                                name="situation"
                                className="status"
                                label="Ativo"
                              />
                              <Input
                                name="model_document.document_name"
                                className="document"
                                label="Documento"
                                readOnly
                              />
                              <Input
                                name="model_document.obs"
                                className="obs"
                                label="Obs"
                              />
                            </section>
                          </Scope>
                        </React.Fragment>
                      ))}
                  </Documents>
                </>
              )}
            </FormContainer>
          </Content>
        )}
      </Container>

      {saveLoading && <Loading />}
    </>
  );
};

export default Form;

import React, { useEffect } from 'react';
import {
  FaEnvelope,
  FaPhone,
  FaSignOutAlt,
  FaTimesCircle,
} from 'react-icons/fa';

import logo from '~/assets/building.png';
import Loading from '~/components/Loading';
import { useProcessAuth } from '~/hooks';

import { Container, Header, Logo, Content, Footer, Error } from './styles';

const Layout = ({ children }) => {
  const {
    user,
    company,
    loadCompanyAndModel,
    signOut,
    processModel,
  } = useProcessAuth();

  useEffect(() => {
    if (company) return;

    loadCompanyAndModel();
  }, [company, loadCompanyAndModel]);

  return (
    <Container>
      {!company ? (
        <Loading />
      ) : (
        <>
          {company.id ? (
            <>
              <Header>
                <Logo>
                  <img
                    src={company && company.logo ? company.logo_url : logo}
                    alt="Logo"
                  />
                  <span>{processModel?.menu_title || company?.name}</span>
                  {user.clientName && (
                    <>
                      <span className="client-name">|</span>
                      <span className="client-name">{user.clientName}</span>
                    </>
                  )}
                </Logo>
                {user.cpf && (
                  <aside>
                    <span>{user && (user.name || user.cpf)}</span>
                    <button type="button" onClick={signOut}>
                      Sair
                      <FaSignOutAlt color="#fff" size={19} />
                    </button>
                  </aside>
                )}
              </Header>

              <Content>{children}</Content>

              <Footer>
                <div>
                  <span>{company.name}</span>
                </div>
                <div>
                  <FaPhone color="#FFF" />
                  <span>{company.phone}</span>
                </div>
                <div>
                  <FaEnvelope color="#FFF" />
                  <span>{company.email}</span>
                </div>
              </Footer>
            </>
          ) : (
            <Error>
              <span>Ocorreu algum erro,</span>
              <span>verifique com seu contador se o link está correto!</span>
              <FaTimesCircle color="#E53935" size={50} />
            </Error>
          )}
        </>
      )}
    </Container>
  );
};

export default Layout;

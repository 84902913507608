import React, { useCallback, useEffect, useState } from 'react';

import { Container, Content } from './styles';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Main from '../components/Main';
import { usePortalAuth } from '~/hooks';

const Portal = () => {
  const { user, company, loadCompany, loadUser } = usePortalAuth();

  const [selectedButton, setSelectedButton] = useState(0);

  const selectMenu = useCallback(
    index => {
      switch (index) {
        case 2:
          window.open(
            `http://doc.diretiva1.com.br/${company.nick}#/revenue`,
            '_blank'
          );
          break;
        case 3:
          window.open(
            `http://doc.diretiva1.com.br/${company.nick}#/inconsistencies`,
            '_blank'
          );
          break;
        default:
          setSelectedButton(index);
      }
    },
    [company]
  );

  useEffect(() => {
    if (!company) {
      loadCompany();
    }
  }, [loadCompany, company]);

  useEffect(() => {
    if (!user || Object.keys(user).length === 0) {
      loadUser();
    }
  }, [loadUser, user]);

  return (
    <Container>
      <Header />
      <Content>
        <Sidebar selectedButton={selectedButton} selectMenu={selectMenu} />
        <Main selectedButton={selectedButton} />
      </Content>
    </Container>
  );
};

export default Portal;

import styled from 'styled-components';
import { Form } from '@unform/web';

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  .name,
  .situation {
    width: 50%;
  }

  > div + div {
    margin-left: 16px;
  }

  button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;
    width: 4%;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  .document-table {
    padding-left: 8px;
    width: 20%;
  }

  .name-table {
    width: 30%;
  }

  .address-table {
    width: 30%;
  }

  .number-table {
    width: 10%;
  }

  .city-table {
    width: 10%;
  }
`;

export const RelatedPeopleInfo = styled.div`
  display: flex;
  flex-direction: column;

  .document,
  .portal_password,
  .treatment_name {
    width: 20%;
  }

  .birthday,
  .protocol_preference,
  .active,
  .end_day {
    width: 25%;
  }

  .name {
    width: 40%;
  }

  .close-connection {
    align-self: flex-end;
    width: 30px;
    margin-left: 10px;
    background: none;

    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Addresses = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
`;

export const Address = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  .zipcode,
  .number {
    width: 20%;
  }

  .street {
    width: 60%;
  }

  .complement,
  .neighborhood,
  .city,
  .state {
    width: 25%;
  }
`;

export const Contacts = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .contact_type {
    width: 25%;
  }

  .content {
    width: 75%;
  }
`;

export const Tags = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .tag {
    width: 100%;
  }
`;

export const Audit = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .date,
  .user,
  .action {
    width: 33%;
  }
`;

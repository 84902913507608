import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';

import { format, parseISO, isToday } from 'date-fns';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';
import { produce } from 'immer';
import * as Yup from 'yup';
import TextareaAutosize from 'react-autosize-textarea/lib';
import base64 from 'base-64';

import {
  FaClock,
  FaTimes,
  FaEye,
  FaPlus,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaEraser,
  FaExclamationCircle,
  FaCheckCircle,
  FaRegCircle,
  FaMinus,
  FaThumbsDown,
  FaThumbsUp,
  FaDownload,
  FaFileAlt,
  FaCopy,
  FaEnvelope,
  FaSave,
  FaInfoCircle,
} from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import copy from 'copy-to-clipboard';
import { Form } from '@unform/web';
import ConfirmWindow from '~/components/ConfirmWindow';
import Pagination from '~/components/Pagination';
import {
  Checkbox,
  Input,
  Select,
  SelectWithFilterActiveCheckbox,
} from '~/components/Form';
import { TableContainer, TableLoading } from '~/components/Table';
import Loading from '~/components/Loading';
import FeedbacksModal from '~/components/FeedbacksModal';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import ConfirmMarkDocument from './ConfirmMarkDocument';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  Subtitles,
  DocumentTd,
  Feedback,
  FeedbackItem,
  Situation,
} from './styles';
import CancellationReasonModal from './CancellationReasonModal';
import ConfirmSendProcess from './ConfirmSendProcess';
import ConcludePhaseModal from './ConcludePhaseModal';
import ShowPopModal from '~/components/Header/SearchPop/ShowPopModal';
import { SendFeedback } from '~/components/SendFeedback';
import base64ToBlob from '~/util/converterBase64ToBLob';

const List = () => {
  const { user, company, companyUsers, companyUser } = useAuth();

  const { state } = useLocation();
  const [model_id, setModelId] = useState(state?.model_id || null);

  const [loading, setLoading] = useState(false);
  const [processesLoading, setProcessesLoading] = useState(true);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [deleteFeedbackLoading, setDeleteFeedbackLoading] = useState(false);

  const [showTable, setShowTable] = useState(true);

  const [filterUser, setFilterUser] = useState(null);
  const [filterClient, setFilterClient] = useState(null);

  const filterRef = useRef(null);
  const feedbackRef = useRef(null);
  const feedbacksForModal = useRef(null);

  const [phaseFeedbacksModalIsOpen, setPhaseFeedbacksModalIsOpen] = useState(
    false
  );

  const [
    confirmSendProcessModalIsOpen,
    setConfirmSendProcessModalIsOpen,
  ] = useState(false);

  const [concludePhaseModalIsOpen, setConcludePhaseModalIsOpen] = useState(
    false
  );

  const [indexProcessView, setIndexProcessView] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProcesses, setTotalProcesses] = useState(0);

  const [processType, setProcessType] = useState({});
  const [processes, setProcesses] = useState([]);
  const [process, setProcess] = useState(null);

  const changeInputRef = useRef(null);
  const [isEditting, setIsEditting] = useState(false);
  const [documentEditting, setDocumentEditting] = useState(null);
  const [phaseEditting, setPhaseEditting] = useState(null);
  const [questionEditting, setQuestionEditting] = useState(null);

  const [isCreatingViewer, setIsCreatingViewer] = useState(false);
  const [viewersOptions, setViewersOptions] = useState([]);
  const newViewerRef = useRef(null);

  const [
    cancellationReasonModalIsOpen,
    setCancellationReasonModalIsOpen,
  ] = useState(false);
  const indexCancelProcess = useRef(null);

  const [uploadFile, setUploadFile] = useState([]);
  const [copyFile, setCopyFile] = useState(null);

  const viewOptions = useMemo(() => {
    const options = [
      { label: 'Todos', value: '' },
      { label: 'Não visualizado', value: 0 },
      { label: 'Visualizado', value: 1 },
    ];

    return options;
  }, []);

  const [selectedSituation, setSelectedSituation] = useState(() => {
    const situationStorage = localStorage.getItem(
      '@Diretiva:processes:filter:situation'
    );

    if (situationStorage) {
      return JSON.parse(situationStorage);
    }

    return {
      value: 0,
      label: 'Em andamento',
    };
  });

  const [selectedView, setSelectedView] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const [popModalIsOpen, setPopModalIsOpen] = useState(false);
  const popForModal = useRef(null);

  const [notification, setNotification] = useState(true);

  async function handleOpenPopModal(data) {
    try {
      const response = await api.get(`/pop/${data.id}`);

      popForModal.current = response.data;
      setPopModalIsOpen(true);
    } catch (err) {
      toast.error('Falha ao buscar POP', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  async function handleOpenPopModalDocument(data) {
    try {
      const response = await api.get(`/pop/${data}`);

      popForModal.current = response.data;
      setPopModalIsOpen(true);
    } catch (err) {
      toast.error('Falha ao buscar POP', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }

  useEffect(() => {
    if (user) {
      const userStorage = localStorage.getItem(
        '@Diretiva:processes:filter:user'
      );
      const clientStorage = localStorage.getItem(
        '@Diretiva:processes:filter:client'
      );
      const viewStorage = localStorage.getItem(
        '@Diretiva:processes:filter:view'
      );

      if (userStorage) {
        setSelectedUser(JSON.parse(userStorage));
        setFilterUser(JSON.parse(userStorage));
      } else {
        setSelectedUser({
          value: user.id,
          label: user.name,
        });
        setFilterUser({
          value: user.id,
          label: user.name,
        });
      }

      if (clientStorage) {
        setSelectedClient(JSON.parse(clientStorage));
        setFilterClient(JSON.parse(clientStorage));
      } else {
        setSelectedClient({
          value: '',
          label: 'Todos',
        });
        setFilterClient({
          value: '',
          label: 'Todos',
        });
      }

      if (viewStorage) {
        setSelectedView(JSON.parse(viewStorage));
      } else {
        setSelectedView({
          value: '',
          label: 'Todos',
        });
      }
    }
  }, [user]);

  const situationOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 0, label: 'Em andamento' },
      { value: 1, label: 'Concluído' },
      { value: 2, label: 'Cancelado' },
    ];
  }, []);

  const [usersOptions, setUsersOptions] = useState([]);
  useEffect(() => {
    if (companyUsers && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
            active: userItem.active,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
        active: true,
      });

      options.push({
        value: '',
        label: 'Todos',
        active: true,
      });

      setUsersOptions(options);
    }
  }, [companyUsers, user]);

  const [processModelsOptions, setProcessModelsOptions] = useState([]);
  useEffect(() => {
    if (!company) return;

    async function loadModelsOptions() {
      try {
        const response = await api.get(`/process-models/simple`, {
          params: {
            company_id: company.id,
            model_type: 0,
            selectOnly: true,
          },
        });

        if (response.data.length > 0) {
          const options = response.data.map(item => ({
            value: item.id,
            label: item.menu_title || item.title,
          }));

          options.sort((a, b) => {
            if (a.label.toLowerCase() < b.label.toLowerCase()) {
              return -1;
            }
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          setProcessModelsOptions(options);
        } else {
          toast.error('Falha ao buscar modelos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push('/process-models');
        }
      } catch {
        toast.error('Falha ao buscar modelos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        history.push('/process-models');
      }
    }

    loadModelsOptions();
  }, [company]);

  const handleFilterUsersOptions = useCallback(
    value => {
      if (user && companyUsers) {
        if (value === true) {
          setUsersOptions(oldUsersOptions =>
            oldUsersOptions.filter(userItem => userItem.active !== false)
          );
        } else {
          const options = companyUsers
            .filter(userItem => userItem.user_id !== user.id)
            .filter(userItem => userItem.user_id !== -1)
            .map(userItem => {
              return {
                value: userItem.user_id,
                label: userItem.short_name,
                active: userItem.active,
              };
            });

          options.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          options.unshift({
            value: user.id,
            label: user.short_name,
            active: true,
          });

          options.push({
            value: '',
            label: 'Todos',
            active: true,
          });

          setUsersOptions(options);
        }
      }
    },
    [companyUsers, user]
  );

  const [clients, setClients] = useState(null);
  const [clientsOptions, setClientsOptions] = useState([]);

  useEffect(() => {
    async function loadClients() {
      if (user && company) {
        try {
          const response = await api.get(`/relationships`, {
            params: {
              company_id: company.id,
              selectOnly: true,
              type: 1,
            },
          });

          if (response.data.length > 0) {
            response.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });

            const options = response.data.map(item => ({
              value: item.id,
              label: item.name,
              active: item.active,
            }));

            options.unshift({
              value: '',
              label: 'Todos',
              active: true,
            });

            setClients(options);
            setClientsOptions(options.filter(item => item.active));
          } else {
            toast.warn('Nenhum cliente foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } catch {
          toast.error('Falha ao buscar clientes.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }

    loadClients();
  }, [company, user]);

  const handleFilterClientsOptions = useCallback(
    value => {
      if (clients) {
        if (value === true) {
          setClientsOptions(clients.filter(clientItem => clientItem.active));
        } else {
          setClientsOptions(clients);
        }
      }
    },
    [clients]
  );

  const loadProcess = useCallback(async id => {
    try {
      setLoading(true);

      const response = await api.get(`/processes/${id}`);

      const { data } = response;

      const formattedData = {
        ...data,
        start_date: format(parseISO(data.start_date), 'dd/MM/yyyy'),
        documents: data.documents.map(documentItem => ({
          ...documentItem,
          conclusion_date: documentItem.conclusion_date
            ? format(parseISO(documentItem.conclusion_date), 'dd/MM/yyyy')
            : null,
          partner: documentItem.partner?.name || '',
        })),
        questions: data.questions.map(questionItem => ({
          ...questionItem,
          conclusion_date: questionItem.conclusion_date
            ? format(parseISO(questionItem.conclusion_date), 'dd/MM/yyyy')
            : null,
          partner: questionItem.partner?.name || '',
        })),
        phases: data.phases.map(phaseItem => ({
          ...phaseItem,
          conclusion_date: phaseItem.conclusion_date
            ? format(parseISO(phaseItem.conclusion_date), 'dd/MM/yyyy')
            : null,
        })),
        viewers: data.viewers.map(viewerItem => ({
          id: viewerItem.id,
          relationship_id: viewerItem.relationship_id,
          name: viewerItem.viewer?.user?.name || '',
          email: viewerItem.viewer?.content || '',
        })),
        feedbacks: data.feedbacks.map(feedbackItem => ({
          ...feedbackItem,
          feedback_date: format(
            parseISO(feedbackItem.created_at),
            'dd/MM/yyyy'
          ),
        })),
      };
      return formattedData;
    } catch {
      throw new Error('Falha ao carregar os dados');
    } finally {
      setLoading(false);
    }
  }, []);

  const loadProcesses = useCallback(async () => {
    if (user && company && selectedUser) {
      try {
        setProcessesLoading(true);

        const response = await api.get(`/processes`, {
          params: {
            company_id: company.id,
            model_id,
            user_id: selectedUser ? selectedUser.value : user.id,
            situation: selectedSituation.value,
            client_id: selectedClient ? selectedClient.value : '',
            view: selectedView ? selectedView.value : '',
            page: currentPage,
            type: 'process',
          },
        });

        const { docs, pages, total } = response.data;

        if (docs.length > 0) {
          const data = docs.map(item => ({
            ...item,
            start_date: format(parseISO(item.start_date), 'dd/MM/yyyy'),
            prevision_date: item.prevision_date
              ? format(parseISO(item.prevision_date), 'dd/MM/yyyy')
              : '',
            isLate:
              item.phases
                .filter(phaseItem => phaseItem.situation !== 3)
                .filter(phaseItem => phaseItem.situation !== 4)
                .filter(phaseItem => phaseItem.prevision_date !== null)
                .filter(
                  phaseItem => parseISO(phaseItem.prevision_date) < new Date()
                ).length > 0,
            phases: item.phases.map(phaseItem => {
              const updated_at = phaseItem.updated_at
                ? format(
                    parseISO(phaseItem.updated_at),
                    "dd/MM/yyyy 'às' HH:mm"
                  )
                : null;

              return {
                ...phaseItem,
                hint: phaseItem.obs
                  ? `${phaseItem.document} <br /> ${updated_at} <br /> ${phaseItem.obs}`
                  : `${phaseItem.document} <br /> ${updated_at}`,
                conclusion_date: phaseItem.conclusion_date
                  ? format(parseISO(phaseItem.conclusion_date), 'dd/MM/yyyy')
                  : null,
                updated_at,
                documentName: phaseItem.doc ? phaseItem.doc.description : null,
              };
            }),
          }));

          setProcesses(data);
        } else {
          toast.warn('Nenhum processo foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setProcesses([]);
        }

        setProcess(null);
        setIndexProcessView(-1);

        setTotalPages(pages);
        setTotalProcesses(total);
        setProcessesLoading(false);
      } catch {
        toast.error('Falha ao buscar processos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setProcessesLoading(false);
      }
    }
  }, [
    user,
    company,
    model_id,
    selectedSituation,
    selectedUser,
    selectedClient,
    selectedView,
    currentPage,
  ]);

  useEffect(() => {
    async function loadModel() {
      try {
        const response = await api.get(
          `/process-models/new-process/${model_id}`
        );

        if (!response.data) {
          toast.error('Falha ao buscar modelo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push('/process-models');
        }

        setProcessType(response.data);
      } catch {
        toast.error('Falha ao buscar modelo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        history.push('/process-models');
      }
    }

    if (!model_id) {
      toast.warn('Nenhum modelo selecionado.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      history.push('/process-models');
    } else {
      loadModel();
      loadProcesses();
    }
  }, [model_id, loadProcesses]);

  const handleSubmitFilter = useCallback(
    data => {
      setSelectedUser(usersOptions.find(option => option.value === data.user));
      localStorage.setItem(
        '@Diretiva:processes:filter:user',
        JSON.stringify(usersOptions.find(option => option.value === data.user))
      );

      setSelectedSituation(
        situationOptions.find(option => option.value === data.situation)
      );
      localStorage.setItem(
        '@Diretiva:processes:filter:situation',
        JSON.stringify(
          situationOptions.find(option => option.value === data.situation)
        )
      );

      setSelectedClient(
        clientsOptions.find(option => option.value === data.client)
      );
      localStorage.setItem(
        '@Diretiva:processes:filter:client',
        JSON.stringify(
          clientsOptions.find(option => option.value === data.client)
        )
      );

      setSelectedView(viewOptions.find(option => option.value === data.view));
      localStorage.setItem(
        '@Diretiva:processes:filter:view',
        JSON.stringify(viewOptions.find(option => option.value === data.view))
      );
    },
    [usersOptions, situationOptions, clientsOptions, viewOptions]
  );

  const resetFilter = useCallback(() => {
    filterRef.current.setFieldValue(
      'situation',
      situationOptions.find(option => option.value === 0)
    );
    filterRef.current.setFieldValue('view', { label: 'Todos', value: '' });

    setSelectedUser(usersOptions.find(option => option.value === user.id));
    setFilterUser(usersOptions.find(option => option.value === user.id));
    localStorage.removeItem('@Diretiva:processes:filter:user');

    setSelectedSituation(situationOptions.find(option => option.value === 0));
    localStorage.removeItem('@Diretiva:processes:filter:situation');

    setSelectedClient({ label: 'Todos', value: '' });
    setFilterClient({ label: 'Todos', value: '' });
    localStorage.removeItem('@Diretiva:processes:filter:client');

    setSelectedView({ label: 'Todos', value: '' });
    localStorage.removeItem('@Diretiva:processes:filter:view');
  }, [user, usersOptions, situationOptions]);

  const handleChangeSituation = useCallback(
    async (indexProcess, indexPhase, situation, documentData) => {
      try {
        const conclusion_date = situation === 3 ? new Date() : null;

        const { id: phase_id, responsible_id, dead_line, document } = processes[
          indexProcess
        ].phases[indexPhase];

        const { name } = processes[indexProcess].client;

        let documentForProtocol = null;

        if (documentData) {
          const { competence, uploadFile: file } = documentData;

          documentForProtocol = {
            competence,
          };

          if (file.name) {
            const formData = new FormData();

            formData.append('file', file);

            const fileResponse = await api.post('files/upload', formData, {
              params: {
                prefix: 'Process_Phase',
              },
            });

            const { blobName } = fileResponse.data;

            documentForProtocol.file = file.name;
            documentForProtocol.file_url = blobName;
          }
        }

        const resultDate = new Date();
        resultDate.setDate(resultDate.getDate() + dead_line);
        resultDate.setUTCHours(23, 59, 0);

        await api.put(`/processes/alter-phase-situation/${phase_id}`, {
          situation,
          conclusion_date,
          company_id: company.id,
          user_id: user.id,
          recipient_id: responsible_id,
          description: `${processType.title} - ${document} - ${name}`,
          deadline_date: resultDate,
          notificate: notification,
          documentForProtocol,
        });

        setProcesses(
          produce(processes, draft => {
            draft[indexProcess].phases[indexPhase].situation = situation;
            draft[indexProcess].phases[indexPhase].updated_at = format(
              new Date(),
              "dd/MM/yyyy 'às' HH:mm"
            );

            if (situation === 3) {
              draft[indexProcess].phases[indexPhase].conclusion_date = format(
                conclusion_date,
                'dd/MM/yyyy'
              );
            }

            const notConcludedLength = draft[indexProcess].phases
              .filter(phase => phase.situation !== 3)
              .filter(phase => phase.situation !== 4).length;

            if (notConcludedLength === 0) {
              draft[indexProcess].situation = 1;
            } else if (draft[indexProcess].situation === 1) {
              draft[indexProcess].situation = 0;
            } else if (
              (situation === 3 || situation === 4) &&
              draft[indexProcess].start_next_phase
            ) {
              const nextPhase = draft[indexProcess].phases.find(
                phase =>
                  phase.situation === 0 &&
                  phase.order ===
                    draft[indexProcess].phases[indexPhase].order + 1
              );

              if (nextPhase) {
                const indexNextPhase = draft[indexProcess].phases
                  .map(phase => phase.id)
                  .indexOf(nextPhase.id);

                draft[indexProcess].phases[indexNextPhase].situation = 1;
              }
            }
          })
        );
      } catch (err) {
        if (err.response?.status === 405) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error('Falha ao alterar a situação da etapa.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [processes, company, processType, user, notification]
  );

  const variablesToConcludeModal = useRef(null);
  const infoToConcludeModal = useRef(null);
  const handleConcludePhase = useCallback((indexProcess, indexPhase) => {
    variablesToConcludeModal.current = {
      indexProcess,
      indexPhase,
      situation: 3,
    };

    setConcludePhaseModalIsOpen(true);
  }, []);

  const [
    confirmMarkDocumentModalIsOpen,
    setConfirmMarkDocumentModalIsOpen,
  ] = useState(false);
  const phaseToMarkDocumentModal = useRef(null);

  const confirmMarkDocument = useCallback(
    async (phase, indexProcess, indexPhase, clientName, processUserId) => {
      const {
        id: phaseId,
        document: phaseName,
        required: phaseRequired,
        document_required: documentRequired,
        responsible_id: responsible,
        documentName,
      } = phase;

      if (
        companyUser.dpt_labour === false &&
        processUserId !== user?.id &&
        responsible !== user?.id &&
        companyUser.level < 9
      ) {
        toast.warn('Somente o criador do processo pode editá-lo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        phaseToMarkDocumentModal.current = {
          phaseId,
          indexProcess,
          indexPhase,
          phaseName,
          phaseRequired,
          clientName,
          documentRequired,
        };

        infoToConcludeModal.current = {
          client: clientName,
          document: documentName,
        };
        setConfirmMarkDocumentModalIsOpen(true);
      }
    },
    [companyUser, user]
  );

  const alterView = useCallback(async () => {
    try {
      if (!showTable || (processes.length > 0 && process)) {
        setShowTable(!showTable);
      } else if (showTable && processes.length > 0) {
        const formattedData = await loadProcess(processes[0].id);

        setProcess(formattedData);
        setIndexProcessView(0);

        setShowTable(!showTable);
      }
    } catch {
      toast.error('Falha ao buscar processo.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [processes, showTable, process, loadProcess]);

  const handleDeleteProcess = useCallback(async () => {
    try {
      setLoading(true);
      await api.delete(`/processes/${process.id}`);

      setProcesses(oldProcesses =>
        oldProcesses.filter(item => item.id !== process.id)
      );

      alterView();

      loadProcesses();

      setLoading(false);
    } catch {
      toast.error('Falha ao deletar processo.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  }, [process, alterView, loadProcesses]);

  const confirmDeleteProcess = useCallback(() => {
    if (user && companyUser) {
      if (process.user_id !== user?.id && companyUser.level < 9) {
        toast.warn('Somente o criador do processo pode deletá-lo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ConfirmWindow onClick={handleDeleteProcess} onClose={onClose} />
            );
          },
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    }
  }, [handleDeleteProcess, process, user, companyUser]);

  const handlePrevItem = useCallback(async () => {
    try {
      if (indexProcessView === 0) {
        const formattedData = await loadProcess(
          processes[processes.length - 1].id
        );

        setProcess(formattedData);
        setIndexProcessView(processes.length - 1);
      } else {
        const formattedData = await loadProcess(
          processes[indexProcessView - 1].id
        );

        setProcess(formattedData);
        setIndexProcessView(indexProcessView - 1);
      }
    } catch {
      toast.error('Falha ao buscar processo.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [indexProcessView, processes, loadProcess]);

  const handleNextItem = useCallback(async () => {
    try {
      if (indexProcessView === processes.length - 1) {
        const formattedData = await loadProcess(processes[0].id);

        setProcess(formattedData);
        setIndexProcessView(0);
      } else {
        const formattedData = await loadProcess(
          processes[indexProcessView + 1].id
        );

        setProcess(formattedData);
        setIndexProcessView(indexProcessView + 1);
      }
    } catch {
      toast.error('Falha ao buscar processo.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [indexProcessView, processes, loadProcess]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  const openFile = useCallback(async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  }, []);

  const downloadFile = useCallback(async (blobName, fileName) => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    fileDownload(response.data, fileName);
  }, []);

  const editProcess = useCallback(() => {
    if (user && companyUser) {
      if (process.user_id !== user?.id && companyUser.level < 9) {
        toast.warn('Somente o criador do processo pode editá-lo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        history.push({
          pathname: '/process/edit',
          state: { id: process.id, model_id },
        });
      }
    }
  }, [user, companyUser, process, model_id]);

  const mime = 'image/png';

  const handleFeedback = useCallback(
    async data => {
      let blob = null;
      if (copyFile) {
        blob = base64ToBlob(copyFile, mime);
      }
      if (company && user) {
        try {
          setFeedbackLoading(true);
          if (copyFile) {
            data.file = {};
            data.file.name = 'feedback.png';
            data.file.type = 'image/png';
          }

          const schema = Yup.object().shape({
            feedback_message: Yup.string().when('file', {
              is: value => value !== undefined,
              then: Yup.string(),
              otherwise: Yup.string().required('A mensagem é obrigatória'),
            }),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const process_id = data.processId ? data.processId : process.id;

          const feedbackData = {
            company_id: company.id,
            process_id,
            user_id: user.id,
            content:
              data.file && data.feedback_message === ''
                ? data.file.name
                : data.feedback_message,
            type: data.file ? 1 : 0,
            file_name: data.file ? data.file.name : null,
          };

          if (data.file) {
            const formData = new FormData();

            if (!copyFile) {
              formData.append('file', data.file);
            } else {
              formData.append('file', blob, 'feedback.png');
              setCopyFile(null);
            }

            const fileResponse = await api.post('files/upload', formData, {
              params: {
                prefix: 'Process_Feedback',
              },
            });

            const { blobName } = fileResponse.data;

            feedbackData.link = blobName;
          }

          await api.post('processes/feedback', feedbackData);

          const response = await api.get(`processes/feedback/${process_id}`);

          const feedbacks = response.data.map(feedback => ({
            ...feedback,
            feedback_date: format(parseISO(feedback.created_at), 'dd/MM/yyyy'),
          }));

          if (process?.id === process_id) {
            setProcess(oldProcess => ({
              ...oldProcess,
              feedbacks,
            }));
          }

          if (!data.isCancellation) {
            feedbackRef.current.clearField('feedback_message');

            feedbackRef.current.setErrors({});
          }

          setUploadFile([]);
          setFeedbackLoading(false);

          toast.success('Feedback salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            feedbackRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar feedback.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setUploadFile([]);
          setFeedbackLoading(false);
        }
      }
    },
    [company, user, process, copyFile]
  );

  const handleDeleteFeedback = useCallback(
    async (id, userId, date) => {
      if (user) {
        if (user.id === userId) {
          if (isToday(parseISO(date))) {
            setDeleteFeedbackLoading(true);
            try {
              await api.delete(`processes/feedback/${id}`);

              setProcess(oldProcess => ({
                ...oldProcess,
                feedbacks: oldProcess?.feedbacks?.filter(
                  feedback => feedback.id !== id
                ),
              }));

              toast.success('Mensagem deletada com sucesso.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } catch {
              toast.error('Falha ao deletar mensagem.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } finally {
              setDeleteFeedbackLoading(false);
            }
          } else {
            toast.warn('Não é possivel deletar mensagens antigas.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          toast.warn('Somente o criador da mensagem pode deletá-lo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [user]
  );

  const confirmRemoveFeedback = useCallback(
    (id, userId, date) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => handleDeleteFeedback(id, userId, date)}
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleDeleteFeedback]
  );

  useEffect(() => {
    if (!showTable && !processesLoading) {
      const element = document.getElementById('feedback_message');

      if (element) {
        element.addEventListener('keydown', event => {
          if (event.ctrlKey && event.key === 'Enter') {
            feedbackRef.current.submitForm();
          }
        });
      }
    }
  }, [showTable, processesLoading]);

  useEffect(() => {
    if (uploadFile.length !== 0) {
      feedbackRef.current.submitForm();
    }
  }, [uploadFile]);

  const handleViewPhaseFeedbacks = useCallback(async phaseId => {
    try {
      setLoading(true);

      const response = await api.get(`/processes/phase-feedbacks/${phaseId}`);

      if (response.data.length > 0) {
        const formattedFeedbacks = response.data.map(feedback => ({
          ...feedback,
          feedback_date_formatted: format(
            parseISO(feedback.feedback_date),
            "dd/MM/yyyy 'às' HH:mm"
          ),
        }));

        feedbacksForModal.current = formattedFeedbacks;

        setPhaseFeedbacksModalIsOpen(true);
      } else {
        toast.warn('Nenhum feedback encontrado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      setLoading(false);
    } catch {
      toast.error('Falha ao buscar os feedbacks.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    async function checkViewProcess() {
      if (process && user && user.id === process.user_id && !showTable) {
        if (process.view === false) {
          await api.put(`/processes/check-view/${process.id}`);

          setProcesses(oldProcesses =>
            oldProcesses.map(item => {
              if (item.id === process.id) {
                return {
                  ...item,
                  view: true,
                };
              }

              return item;
            })
          );
        }
      }
    }
    checkViewProcess();
  }, [process, user, showTable]);

  const handleCheckAllUsers = useCallback(
    e => {
      const { checked } = e.target;

      if (checked) {
        setSelectedUser({ value: '', label: 'Todos' });
        setFilterUser({ value: '', label: 'Todos' });
        localStorage.setItem(
          '@Diretiva:processes:filter:user',
          JSON.stringify({ value: '', label: 'Todos' })
        );
      } else {
        setSelectedUser(usersOptions[0]);
        setFilterUser(usersOptions[0]);
        localStorage.setItem(
          '@Diretiva:processes:filter:user',
          JSON.stringify(usersOptions[0])
        );
      }
    },
    [usersOptions]
  );

  const handleCancelProcess = useCallback(
    async (reason, index) => {
      if (reason.trim() !== '' && Number.isInteger(index)) {
        try {
          setCancellationReasonModalIsOpen(false);
          setLoading(true);

          await api.put(`/processes/situation/${processes[index].id}`, {
            situation: 2,
          });

          await handleFeedback({
            feedback_message: `Cancelado por ${user.name}\n\nmotivo: ${reason}`,
            isCancellation: true,
            processId: processes[index].id,
            processesIndex: index,
          });

          setProcesses(
            produce(processes, draft => {
              draft[index].situation = 2;
            })
          );

          setLoading(false);
        } catch {
          setLoading(false);
          toast.error('Falha ao cancelar processo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [processes, handleFeedback, user]
  );

  const copyLink = useCallback(() => {
    const params = base64.encode(process.id);

    copy(`https://app.diretiva1.com.br/process-situation/${params}`);
  }, [process]);

  const handleView = useCallback(
    async index => {
      try {
        const formattedData = await loadProcess(processes[index].id);

        setProcess(formattedData);
        setIndexProcessView(index);
        setShowTable(false);
      } catch {
        toast.error('Falha ao buscar processo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [processes, loadProcess]
  );

  const handleSend = useCallback(async () => {
    try {
      setConfirmSendProcessModalIsOpen(false);
      setLoading(true);

      await api.put(`/processes/send/${process.id}`, {
        company_id: company.id,
        sender: user.id,
        viewers: process.viewers,
      });

      toast.success('Processo enviado com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error('Falha ao enviar processo.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [user, company, process]);

  const confirmSend = useCallback(
    async index => {
      try {
        setLoading(true);

        const formattedData = await loadProcess(processes[index].id);

        setProcess(formattedData);
        setIndexProcessView(index);

        if (formattedData.viewers.length === 0) {
          alterView();
          toast.error(
            'Esse processo ainda não possui destinatários. Cadastre algum para poder enviar.',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        } else {
          setConfirmSendProcessModalIsOpen(true);
        }
        setIndexProcessView(index);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error('Falha ao buscar processo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [processes, loadProcess, alterView]
  );

  const handleChangeDocSituation = useCallback(
    async (index, situation) => {
      if (process && process.documents[index].situation !== situation) {
        try {
          let conclusion_date = null;
          if (situation === 2) {
            conclusion_date = new Date();
          }

          await api.put(`/processes/documents/${process.documents[index].id}`, {
            situation,
            conclusion_date,
          });

          if (situation >= 2 && process.documents[index].situation < 2) {
            await api.put(`/processes/dependencies/increment/${process.id}`);
            setProcesses(
              produce(processes, draft => {
                draft[indexProcessView].dependencies_concluded =
                  processes[indexProcessView].dependencies_concluded + 1;
              })
            );
          } else if (situation < 2 && process.documents[index].situation >= 1) {
            await api.put(`/processes/dependencies/decrement/${process.id}`);
            setProcesses(
              produce(processes, draft => {
                draft[indexProcessView].dependencies_concluded =
                  processes[indexProcessView].dependencies_concluded - 1;
              })
            );
          }

          setProcess(
            produce(process, draft => {
              draft.documents[index].situation = situation;

              if (situation === 2) {
                draft.documents[index].conclusion_date = format(
                  conclusion_date,
                  'dd/MM/yyyy'
                );
              }
            })
          );
        } catch {
          toast.error('Falha ao alterar situação do documento.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [process, processes, indexProcessView]
  );

  const handleChangeObs = useCallback(
    async ({ obs }, group, index) => {
      setIsEditting(false);
      setDocumentEditting(null);

      if (obs !== process[group][index].obs) {
        try {
          setLoading(true);

          await api.put(`/processes/${group}/${process[group][index].id}`, {
            obs,
          });

          setProcess(
            produce(process, draft => {
              draft[group][index].obs = obs;
            })
          );

          toast.success('Observação alterado com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        } catch {
          setLoading(false);
          toast.error('Falha ao alterar observação.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [process]
  );

  const handleChangeAnswer = useCallback(
    async ({ answer }, index) => {
      setIsEditting(false);
      setQuestionEditting(null);

      if (answer !== process.questions[index].answer) {
        try {
          setLoading(true);

          let conclusion_date = null;
          let conclusion = null;
          let situation = 0;
          if (answer.trim() !== '') {
            conclusion_date = new Date();
            conclusion = format(new Date(), 'dd/MM/yyyy');
            situation = 2;
          }

          if (situation === 0) {
            await api.put(`/processes/dependencies/decrement/${process.id}`);
            setProcesses(
              produce(processes, draft => {
                draft[indexProcessView].dependencies_concluded =
                  processes[indexProcessView].dependencies_concluded - 1;
              })
            );
          } else {
            await api.put(`/processes/dependencies/increment/${process.id}`);
            setProcesses(
              produce(processes, draft => {
                draft[indexProcessView].dependencies_concluded =
                  processes[indexProcessView].dependencies_concluded + 1;
              })
            );
          }

          await api.put(`/processes/questions/${process.questions[index].id}`, {
            answer,
            conclusion_date,
            situation,
          });

          setProcess(
            produce(process, draft => {
              draft.questions[index].answer = answer;
              draft.questions[index].conclusion_date = conclusion;
              draft.questions[index].situation = situation;
            })
          );

          toast.success('Resposta alterado com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        } catch {
          setLoading(false);
          toast.error('Falha ao alterar resposta.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [process, processes, indexProcessView]
  );

  const loadRelated = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/relationships/related/${process.client_id}`
      );

      const { data } = response;

      if (data.length > 0) {
        const options = [];

        data.forEach(item => {
          item.related_contacts.forEach(contact => {
            options.push({
              value: contact.id,
              relationship_id: item.info.id,
              email: contact.content,
              name: item.info.name,
              label: `${item.info.name} - ${contact.content}`,
            });
          });
        });

        setViewersOptions(options);
        setIsCreatingViewer(true);
        setLoading(false);
      } else {
        setLoading(false);
        toast.warn('Nenhuma pessoa relacionada foi encontrado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch {
      setLoading(false);
      toast.error('Falha ao buscar pessoas relacionadas.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [process]);

  const handleCreateViewer = useCallback(
    async ({ viewer }) => {
      try {
        setLoading(true);

        const { relationship_id, name, email } = viewersOptions.find(
          item => item.value === viewer
        );

        const newViewer = await api.post('/processes/viewers', {
          contact_id: viewer,
          relationship_id,
          process_id: process.id,
        });

        setProcess(
          produce(process, draft => {
            draft.viewers.push({
              id: newViewer.data.id,
              name,
              email,
            });
          })
        );

        setIsCreatingViewer(false);
        setLoading(false);
        toast.success('Destinatário criado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch {
        setLoading(false);
        toast.error('Falha ao criar destinatário.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [process, viewersOptions]
  );

  const handleDeleteViewer = useCallback(
    async id => {
      try {
        setLoading(true);

        await api.delete(`/processes/viewers/${id}`);

        setProcess(
          produce(process, draft => {
            draft.viewers = draft.viewers.filter(item => item.id !== id);
          })
        );

        setLoading(false);
        toast.success('Destinatário excluído com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch {
        setLoading(false);
        toast.error('Falha ao excluir destinatário.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [process]
  );

  const confirmDeleteViewer = useCallback(
    id => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => handleDeleteViewer(id)}
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleDeleteViewer]
  );

  const copyModel = useCallback(async () => {
    if ((user, companyUser)) {
      if (companyUser.level < 9) {
        toast.warn(
          'Somente o administrador da empresa pode criar uma copia de processo.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      } else {
        const response = await api.post(
          `/process-models/copy-process/${processType.processModel_id}`
        );

        if (response.status !== 201) {
          toast.error('Nao foi possivel fazer a copia deste processo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.success('Processo copiado com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }
  }, [user, companyUser, processType.processModel_id]);

  const confirmCopyModel = useCallback(() => {
    if (user && companyUser) {
      if (companyUser.level < 9) {
        toast.warn(
          'Somente o administrador da empresa pode criar uma copia de processo.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      } else {
        const message = 'Deseja realmente duplicar este processo?';
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ConfirmWindow
                onClick={copyModel}
                onClose={onClose}
                message={message}
              />
            );
          },
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    }
  }, [user, companyUser, copyModel]);

  return (
    <>
      <Container>
        <Header>
          <div>
            {processType.icon && processType.title && (
              <>
                <FontAwesomeIcon
                  icon={processType.icon.name}
                  size="lg"
                  color="#44546a"
                />
                <h1>{processType.menu_title || processType.title}</h1>
              </>
            )}
          </div>
          <aside>
            <Link to="/process-models">
              <FaTimes size={20} color="#44546a" />
            </Link>
          </aside>
        </Header>

        <Controls>
          <button type="button" onClick={alterView}>
            <FaEye />
            <span>Visualização</span>
          </button>
          <Link to={{ pathname: '/process/new', state: { model_id } }}>
            <FaPlus />
            <span>Novo</span>
          </Link>
          {showTable ? (
            <>
              <button type="button" onClick={resetFilter}>
                <FaEraser />
                <span>Limpar filtros</span>
              </button>
              {companyUser?.level >= 9 ? (
                <button type="button" onClick={() => confirmCopyModel()}>
                  <FaCopy />
                  <span>Duplicar Processo</span>
                </button>
              ) : null}
            </>
          ) : (
            <>
              <button type="button" onClick={editProcess}>
                <FaEdit />
                <span>Editar</span>
              </button>
              <button type="button" onClick={confirmDeleteProcess}>
                <FaTrash />
                <span>Excluir</span>
              </button>
              <button type="button" onClick={copyLink}>
                <FaCopy />
                <span>Link</span>
              </button>
              <div>
                <button type="button" onClick={handlePrevItem}>
                  <FaChevronLeft />
                </button>

                <span>
                  {indexProcessView + 1} de {processes.length}
                </span>

                <button type="button" onClick={handleNextItem}>
                  <FaChevronRight />
                </button>
              </div>
            </>
          )}
        </Controls>

        {showTable &&
          selectedUser &&
          clients &&
          processModelsOptions.length > 0 && (
            <Filter ref={filterRef} onSubmit={handleSubmitFilter}>
              <Select
                label="Modelo"
                name="processModel"
                className="conclusion"
                options={processModelsOptions}
                defaultValue={
                  processModelsOptions.find(item => item.value === model_id) ||
                  null
                }
                onChange={e => setModelId(e.value)}
              />
              <SelectWithFilterActiveCheckbox
                label="Cliente"
                name="client"
                className="user"
                options={clientsOptions}
                value={filterClient}
                handleFilter={handleFilterClientsOptions}
                onChange={e => setFilterClient(e)}
              />
              <Select
                label="Situação"
                name="situation"
                className="conclusion"
                options={situationOptions}
                defaultValue={selectedSituation}
              />
              <Select
                name="view"
                label="Visualização"
                className="view"
                options={viewOptions}
                defaultValue={selectedView}
              />
              <SelectWithFilterActiveCheckbox
                label="Usuário"
                name="user"
                className="user"
                options={usersOptions}
                value={filterUser}
                handleFilter={handleFilterUsersOptions}
                onChange={e => setFilterUser(e)}
              />
              <Checkbox
                name="allUsers"
                label="Todos"
                className="all-users"
                checked={filterUser && filterUser.value === ''}
                onChange={handleCheckAllUsers}
              />
              <button type="submit">
                <FaSearch />
              </button>
            </Filter>
          )}

        {processesLoading || !processType || !processes ? (
          <TableLoading />
        ) : (
          <>
            {showTable ? (
              <>
                <Content className="content">
                  <ReactTooltip
                    id="option"
                    place="bottom"
                    type="info"
                    backgroundColor="#337ab7"
                    effect="solid"
                    multiline
                  />
                  <ReactTooltip
                    id="document"
                    place="bottom"
                    type="info"
                    backgroundColor="#337ab7"
                    effect="solid"
                  />
                  <ReactTooltip
                    id="client"
                    place="bottom"
                    type="info"
                    backgroundColor="#337ab7"
                    effect="solid"
                  />
                  <ReactTooltip
                    id="mark"
                    place="bottom"
                    type="info"
                    backgroundColor="#337ab7"
                    effect="solid"
                    multiline
                  />
                  <TableContainer>
                    <thead>
                      <tr>
                        <th className="fixed" />
                        <th className="fixed" />
                        <th className="fixed" />
                        <th className="fixed" />
                        <th className="fixed" />
                        <th className="fixed client">Empresa</th>
                        {processType.phases &&
                          processType.phases.map(phase => (
                            <th className="document" key={phase.id}>
                              <span
                                data-tip={phase.document}
                                data-for="document"
                                color="#337ab7"
                              >
                                {phase.abbreviation}
                              </span>
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {processes &&
                        processes.map((item, indexItem) => (
                          <tr key={item.id}>
                            <th className="fixed">
                              {item.situation === 0 && (
                                <FaThumbsDown color="#E53935" />
                              )}
                              {item.situation === 1 && (
                                <FaThumbsUp color="#006229" />
                              )}
                              {item.situation === 2 && (
                                <FaTimes color="#E53935" />
                              )}
                            </th>
                            <th className="fixed icon-button">
                              <FaFileAlt
                                color={
                                  item.dependencies_concluded <
                                  item.quant_dependencies
                                    ? '#E53935'
                                    : '#006229'
                                }
                                data-tip="Visualizar"
                                data-for="option"
                                onClick={() => handleView(indexItem)}
                              />
                            </th>
                            <th className="fixed">
                              {item.situation === 0 ? (
                                <FaClock
                                  color={item.isLate ? '#E53935' : '#006229'}
                                  data-tip={`Previsão: ${item.prevision_date}`}
                                  data-for="option"
                                />
                              ) : (
                                <FaClock color="#44546A" />
                              )}
                            </th>

                            <th className="fixed icon-button">
                              <FaEnvelope
                                color="#01579B"
                                onClick={() => confirmSend(indexItem)}
                                data-tip="Enviar"
                                data-for="option"
                              />
                            </th>

                            {item.situation === 2 ? (
                              <th className="fixed">
                                <FaTimes color="#44546A" />
                              </th>
                            ) : (
                              <th className="fixed icon-button">
                                <FaTimes
                                  color="#FF7F00"
                                  onClick={() => {
                                    indexCancelProcess.current = indexItem;
                                    setCancellationReasonModalIsOpen(true);
                                  }}
                                  data-tip="Cancelar"
                                  data-for="option"
                                />
                              </th>
                            )}

                            <th
                              className="fixed client"
                              style={{ cursor: 'pointer' }}
                              data-tip={item.client.name}
                              data-for="client"
                              onClick={() => handleView(indexItem)}
                            >
                              {item.client.nickname || item.client.name}
                            </th>
                            {item.phases &&
                              item.phases.map((phase, indexPhase) => (
                                <DocumentTd
                                  key={phase.id}
                                  situation={phase.situation}
                                  data-tip={phase.hint || ''}
                                  data-for="mark"
                                  onClick={() =>
                                    confirmMarkDocument(
                                      phase,
                                      indexItem,
                                      indexPhase,
                                      item.client.name,
                                      item.user_id
                                    )
                                  }
                                >
                                  {phase.situation === 0 && (
                                    <FaRegCircle size={18} color="#9dd3fe" />
                                  )}
                                  {phase.situation === 1 && (
                                    <FaClock size={18} color="#f4c306" />
                                  )}
                                  {phase.situation === 2 && (
                                    <FaExclamationCircle
                                      size={18}
                                      color="#E53935"
                                    />
                                  )}
                                  {phase.situation === 3 && (
                                    <FaCheckCircle size={18} color="#006229" />
                                  )}
                                  {phase.situation === 4 && (
                                    <FaMinus size={18} color="#e1e1e1" />
                                  )}
                                </DocumentTd>
                              ))}
                          </tr>
                        ))}
                    </tbody>
                  </TableContainer>
                </Content>
              </>
            ) : (
              <Content className="content">
                <DetailsContainer>
                  {process && (
                    <>
                      <h4>PROCESSO</h4>
                      <section>
                        <div className="title">
                          <label>Cliente</label>
                          <input
                            name="name"
                            value={process.client.name || ''}
                            readOnly
                          />
                        </div>
                        <div className="user">
                          <label>Responsável</label>
                          <input
                            name="name"
                            value={
                              usersOptions.find(
                                option => option.value === process.user_id
                              )?.label || ''
                            }
                            readOnly
                          />
                        </div>

                        <div className="type">
                          <label>Modelo</label>
                          <input
                            name="typeModel"
                            value={processType.title}
                            readOnly
                          />
                        </div>
                        <div className="date">
                          <label>Data início</label>
                          <input
                            name="name"
                            value={process.start_date || ''}
                            readOnly
                          />
                        </div>
                        <div className="type">
                          <label>Situação</label>
                          {process.situation === 0 && (
                            <input
                              name="situation"
                              value="Em andamento"
                              readOnly
                            />
                          )}
                          {process.situation === 1 && (
                            <input
                              name="situation"
                              value="Concluído"
                              readOnly
                            />
                          )}
                          {process.situation === 2 && (
                            <input
                              name="situation"
                              value="Cancelado"
                              readOnly
                            />
                          )}
                        </div>
                      </section>
                      <section className="sector">
                        <div className="checkbox">
                          <label>Trabalhista</label>
                          <input
                            name="labor"
                            checked={process.labor}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Tributário</label>
                          <input
                            name="tributary"
                            checked={process.tributary}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Contábil</label>
                          <input
                            name="accounting"
                            checked={process.accounting}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Financeiro</label>
                          <input
                            name="financial"
                            checked={process.financial}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Administração</label>
                          <input
                            name="administration"
                            checked={process.administration}
                            type="checkbox"
                            disabled
                          />
                        </div>
                      </section>

                      <div className="create">
                        <h4>ENVIAR PARA</h4>
                        {process.user_id === user.id && (
                          <>
                            {isCreatingViewer ? (
                              <button
                                type="button"
                                onClick={() => setIsCreatingViewer(false)}
                              >
                                <FaTimes size={10} />
                              </button>
                            ) : (
                              <button type="button" onClick={loadRelated}>
                                <FaPlus size={10} />
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      {process.viewers.map(item => (
                        <section key={item.id}>
                          {process.user_id === user.id && (
                            <div className="delete">
                              <FaTrash
                                onClick={() => confirmDeleteViewer(item.id)}
                              />
                            </div>
                          )}
                          <div className="viewer-user">
                            <label>Usuário</label>
                            <input
                              name="user"
                              value={item.name || ''}
                              readOnly
                            />
                          </div>
                          <div className="viewer-email">
                            <label>Email</label>
                            <input
                              name="email"
                              value={item.email || ''}
                              readOnly
                            />
                          </div>
                        </section>
                      ))}

                      {isCreatingViewer && (
                        <section>
                          <div className="edit">
                            <FaSave
                              onClick={() => newViewerRef.current.submitForm()}
                            />
                          </div>
                          <Form
                            className="new-viewer"
                            onSubmit={handleCreateViewer}
                            ref={newViewerRef}
                          >
                            <Select
                              name="viewer"
                              label="Novo destinatário"
                              placeholder="Selecione um destinatário"
                              options={viewersOptions}
                            />
                          </Form>
                        </section>
                      )}

                      {process.documents.length > 0 && (
                        <h4 className="description">DOCUMENTAÇÃO NECESSÁRIA</h4>
                      )}
                      {process.documents.map((item, index) => (
                        <div key={index} className="docs">
                          <ReactTooltip
                            id="situation"
                            place="bottom"
                            type="info"
                            backgroundColor="#337ab7"
                            effect="solid"
                            multiline
                          />
                          <section>
                            <Situation className="situation">
                              <button
                                type="button"
                                onClick={() =>
                                  handleChangeDocSituation(index, 0)
                                }
                                data-tip="Não recebido"
                                data-for="situation"
                              >
                                <div
                                  className={`option ${item.situation === 0 &&
                                    'waiting'}`}
                                >
                                  <div />
                                </div>
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  handleChangeDocSituation(index, 1)
                                }
                                data-tip="Pendente"
                                data-for="situation"
                              >
                                <div
                                  className={`option ${item.situation === 1 &&
                                    'pending'}`}
                                >
                                  <FaExclamationCircle size={18} />
                                </div>
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  handleChangeDocSituation(index, 2)
                                }
                                data-tip="Recebido"
                                data-for="situation"
                              >
                                <div
                                  className={`option ${item.situation === 2 &&
                                    'recived'}`}
                                >
                                  <FaCheckCircle size={18} />
                                </div>
                              </button>

                              <button
                                type="button"
                                onClick={() =>
                                  handleChangeDocSituation(index, 3)
                                }
                                data-tip="Desobrigado"
                                data-for="situation"
                              >
                                <div
                                  className={`option ${item.situation === 3 &&
                                    'released'}`}
                                >
                                  <FaMinus size={18} />
                                </div>
                              </button>
                            </Situation>
                            <div className="document">
                              <label>
                                Documento{' '}
                                {item.model.pop_id ? (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleOpenPopModalDocument(
                                        item.model.pop_id
                                      )
                                    }
                                    className="how-make"
                                  >
                                    <FaInfoCircle />
                                    <span>Como fazer</span>
                                  </button>
                                ) : (
                                  ''
                                )}
                              </label>
                              <input
                                name="documentName"
                                value={item.document || ''}
                                readOnly
                              />
                            </div>

                            {item.partner_id && (
                              <div className="document">
                                <label>Sócio</label>
                                <input
                                  name="partnerName"
                                  value={item.partner}
                                  readOnly
                                />
                              </div>
                            )}

                            {item.situation === 2 && (
                              <div className="date">
                                <label>Recibido em</label>
                                <input
                                  name="name"
                                  value={item.conclusion_date || ''}
                                  readOnly
                                />
                              </div>
                            )}

                            {item.file && (
                              <div className="file">
                                <aside>
                                  <button
                                    type="button"
                                    onClick={() => openFile(item.file_url)}
                                    title="Visualizar arquivo"
                                  >
                                    <FaEye size={16} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      downloadFile(item.file_url, item.file)
                                    }
                                    title="Baixar arquivo"
                                  >
                                    <FaDownload size={16} />
                                  </button>
                                </aside>
                                <div>
                                  <label>Arquivo</label>
                                  <input
                                    name="file"
                                    value={item.file || ''}
                                    readOnly
                                  />
                                </div>
                              </div>
                            )}
                          </section>
                          <section>
                            {isEditting && documentEditting === index ? (
                              <>
                                <button
                                  type="button"
                                  className="edit-button"
                                  onClick={() => {
                                    changeInputRef.current.submitForm();
                                  }}
                                >
                                  <FaSave color="#44546a" />
                                </button>

                                <Form
                                  className="form"
                                  ref={changeInputRef}
                                  onSubmit={e =>
                                    handleChangeObs(e, 'documents', index)
                                  }
                                >
                                  <Input
                                    name="obs"
                                    label="Observação"
                                    defaultValue={item.obs || ''}
                                    autoFocus
                                  />
                                </Form>
                              </>
                            ) : (
                              <>
                                {isEditting ? (
                                  <button
                                    type="button"
                                    className="edit-button"
                                  />
                                ) : (
                                  <button
                                    type="button"
                                    className="edit-button"
                                    onClick={() => {
                                      setIsEditting(true);
                                      setDocumentEditting(index);
                                    }}
                                  >
                                    <FaEdit color="#44546a" />
                                  </button>
                                )}

                                <div className="obs">
                                  <label>Observação</label>
                                  <input
                                    name="obs"
                                    value={item.obs || ''}
                                    readOnly
                                  />
                                </div>
                              </>
                            )}
                          </section>
                        </div>
                      ))}

                      {process.questions.length > 0 && (
                        <h4 className="description">PERGUNTAS</h4>
                      )}
                      {process.questions.map((item, index) => (
                        <section key={index}>
                          <div className="document">
                            <label>Pergunta</label>
                            <input
                              name="questionName"
                              value={item.question || ''}
                              readOnly
                            />
                          </div>

                          {item.partner_id && (
                            <div className="date">
                              <label>Sócio</label>
                              <input
                                name="partnerName"
                                value={item.partner}
                                readOnly
                              />
                            </div>
                          )}

                          <div className="type">
                            <label>Situação</label>
                            {item.situation === 0 && (
                              <input
                                name="situation"
                                value="Não respondida"
                                readOnly
                              />
                            )}
                            {item.situation === 1 && (
                              <input
                                name="situation"
                                value="Pendente"
                                readOnly
                              />
                            )}
                            {item.situation === 2 && (
                              <input
                                name="situation"
                                value="Respondida"
                                readOnly
                              />
                            )}
                            {item.situation === 3 && (
                              <input
                                name="situation"
                                value="Desobrigada"
                                readOnly
                              />
                            )}
                          </div>

                          {isEditting && questionEditting === index ? (
                            <>
                              <button
                                type="button"
                                className="edit-button"
                                onClick={() => {
                                  changeInputRef.current.submitForm();
                                }}
                              >
                                <FaSave color="#44546a" />
                              </button>

                              <Form
                                className="form-answer"
                                ref={changeInputRef}
                                onSubmit={e => handleChangeAnswer(e, index)}
                              >
                                <Input
                                  name="answer"
                                  label="Resposta"
                                  defaultValue={item.answer || ''}
                                  autoFocus
                                />
                              </Form>
                            </>
                          ) : (
                            <>
                              {isEditting ? (
                                <button type="button" className="edit-button" />
                              ) : (
                                <button
                                  type="button"
                                  className="edit-button"
                                  onClick={() => {
                                    setIsEditting(true);
                                    setQuestionEditting(index);
                                  }}
                                >
                                  <FaEdit color="#44546a" />
                                </button>
                              )}

                              <div className="answer">
                                <label>Resposta</label>
                                <input
                                  name="answer"
                                  value={item.answer || ''}
                                  readOnly
                                />
                              </div>
                            </>
                          )}

                          {item.situation === 2 && (
                            <div className="date">
                              <label>Respondida em</label>
                              <input
                                name="name"
                                value={item.conclusion_date || ''}
                                readOnly
                              />
                            </div>
                          )}
                        </section>
                      ))}

                      <ReactTooltip
                        id="view_feedback"
                        place="bottom"
                        type="info"
                        backgroundColor="#337ab7"
                        effect="solid"
                        multiline
                      />

                      {process.typeModel !== 2 && (
                        <>
                          {process.phases.length > 0 && (
                            <h4 className="description">ETAPAS</h4>
                          )}
                          {process.phases.map((item, index) => (
                            <div key={index} className="phases">
                              <section>
                                {item.hasScheduleFeedbacks ? (
                                  <div
                                    data-tip="Feedbacks"
                                    data-for="view_feedback"
                                    className="view-feedbacks"
                                  >
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleViewPhaseFeedbacks(item.id)
                                      }
                                    >
                                      <FaEye color="#00c853" size={15} />
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    data-tip="Sem feedbacks"
                                    data-for="view_feedback"
                                    className="view-feedbacks"
                                  >
                                    <button type="button">
                                      <FaEye color="#44546a" size={15} />
                                    </button>
                                  </div>
                                )}

                                <div className="document">
                                  <label>
                                    Etapa
                                    {item.relatedPop ? (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleOpenPopModal(item.relatedPop)
                                        }
                                        className="how-make"
                                      >
                                        <FaInfoCircle />
                                        {/* <br /> */}
                                        <span>Como fazer</span>
                                      </button>
                                    ) : (
                                      ''
                                    )}
                                  </label>
                                  <input
                                    name="documentPhase"
                                    value={item.document || ''}
                                    readOnly
                                  />
                                </div>
                                <div className="type">
                                  <label>Autor</label>
                                  <input
                                    name="author"
                                    value={
                                      item.author
                                        ? usersOptions.find(
                                            option =>
                                              option.value === item.author
                                          ).label
                                        : 'Nenhum'
                                    }
                                    readOnly
                                  />
                                </div>
                                <div className="type">
                                  <label>Responsável</label>
                                  <input
                                    name="responsible_id"
                                    value={
                                      item.responsible_id
                                        ? usersOptions.find(
                                            option =>
                                              option.value ===
                                              item.responsible_id
                                          ).label
                                        : 'Nenhum'
                                    }
                                    readOnly
                                  />
                                </div>
                                <div className="deadline">
                                  <label>Prazo Interno</label>
                                  <input
                                    name="deadline"
                                    value={item.dead_line || 0}
                                    readOnly
                                  />
                                </div>
                                <div className="deadline">
                                  <label>Prazo P/ Cliente</label>
                                  <input
                                    name="deadline_client"
                                    value={item.dead_line_client || 0}
                                    readOnly
                                  />
                                </div>
                                <div className="deadline">
                                  <label>Prorrogação</label>
                                  <input
                                    name="extension_days"
                                    value={item.extension_days || 0}
                                    readOnly
                                  />
                                </div>

                                <div className="type">
                                  <label>Situação</label>
                                  {item.situation === 0 && (
                                    <input
                                      name="situation"
                                      value="Não realizado"
                                      readOnly
                                    />
                                  )}
                                  {item.situation === 1 && (
                                    <input
                                      name="situation"
                                      value="Em andamento"
                                      readOnly
                                    />
                                  )}
                                  {item.situation === 2 && (
                                    <input
                                      name="situation"
                                      value="Pendente"
                                      readOnly
                                    />
                                  )}
                                  {item.situation === 3 && (
                                    <input
                                      name="situation"
                                      value="Realizado"
                                      readOnly
                                    />
                                  )}
                                  {item.situation === 4 && (
                                    <input
                                      name="situation"
                                      value="Desobrigado"
                                      readOnly
                                    />
                                  )}
                                </div>

                                {item.situation === 3 && (
                                  <div className="date">
                                    <label>Conclusão</label>
                                    <input
                                      name="name"
                                      value={item.conclusion_date || ''}
                                      readOnly
                                    />
                                  </div>
                                )}

                                <div className="checkbox">
                                  <label className="checkbox">Notificar</label>
                                  <input
                                    name="notificate"
                                    checked={item.notificate}
                                    type="checkbox"
                                    disabled
                                  />
                                </div>
                              </section>
                              <section>
                                {isEditting && phaseEditting === index ? (
                                  <>
                                    <button
                                      type="button"
                                      className="edit-button"
                                      onClick={() => {
                                        changeInputRef.current.submitForm();
                                      }}
                                    >
                                      <FaSave color="#44546a" />
                                    </button>

                                    <Form
                                      className="form"
                                      ref={changeInputRef}
                                      onSubmit={e =>
                                        handleChangeObs(e, 'phases', index)
                                      }
                                    >
                                      <Input
                                        name="obs"
                                        label="Observação"
                                        defaultValue={item.obs || ''}
                                        autoFocus
                                      />
                                    </Form>
                                  </>
                                ) : (
                                  <>
                                    {isEditting ? (
                                      <button
                                        type="button"
                                        className="edit-button"
                                      />
                                    ) : (
                                      <button
                                        type="button"
                                        className="edit-button"
                                        onClick={() => {
                                          setIsEditting(true);
                                          setPhaseEditting(index);
                                        }}
                                      >
                                        <FaEdit color="#44546a" />
                                      </button>
                                    )}

                                    <div className="obs">
                                      <label>Observação</label>
                                      <input
                                        name="obs"
                                        value={item.obs || ''}
                                        readOnly
                                      />
                                    </div>
                                  </>
                                )}

                                {item.file && (
                                  <div className="file">
                                    <aside>
                                      <button
                                        type="button"
                                        onClick={() => openFile(item.file_url)}
                                        title="Visualizar arquivo"
                                      >
                                        <FaEye size={16} />
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          downloadFile(item.file_url, item.file)
                                        }
                                        title="Baixar arquivo"
                                      >
                                        <FaDownload size={16} />
                                      </button>
                                    </aside>
                                    <div>
                                      <label>Arquivo</label>
                                      <input
                                        name="file"
                                        value={item.file || ''}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                )}
                              </section>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}

                  <Feedback ref={feedbackRef} onSubmit={handleFeedback}>
                    <SendFeedback
                      uploadFile={uploadFile}
                      feedbackRef={feedbackRef}
                      copyFile={copyFile}
                      setCopyFile={setCopyFile}
                      setUploadFile={setUploadFile}
                    />
                    {process.feedbacks.length > 0 &&
                      process.feedbacks.map(feedback => (
                        <FeedbackItem key={feedback.id}>
                          <div className="delete">
                            <button
                              type="button"
                              onClick={() =>
                                confirmRemoveFeedback(
                                  feedback.id,
                                  feedback.user.id,
                                  feedback.created_at
                                )
                              }
                            >
                              <FaTrash size={14} />
                            </button>
                          </div>
                          <div className="date">
                            <label>Data/Hora</label>
                            <input
                              name="feedback_date_formatted"
                              value={feedback.feedback_date}
                              readOnly
                            />
                          </div>
                          <div className="user">
                            <label>Usuário</label>
                            <input
                              name="feedback_user"
                              value={feedback.user.short_name}
                              readOnly
                            />
                          </div>
                          <div className="content">
                            <label>Mensagem</label>
                            <TextareaAutosize
                              name="content"
                              value={feedback.content}
                              readOnly
                              maxRows={4}
                            />
                          </div>
                          {feedback.type === 1 && (
                            <div className="file">
                              <aside>
                                <button
                                  type="button"
                                  onClick={() => openFile(feedback.link)}
                                  title="Visualizar arquivo"
                                >
                                  <FaEye size={16} />
                                </button>
                                <button
                                  type="button"
                                  onClick={() =>
                                    downloadFile(
                                      feedback.link,
                                      feedback.file_name
                                    )
                                  }
                                  title="Baixar arquivo"
                                >
                                  <FaDownload size={16} />
                                </button>
                              </aside>
                              <div>
                                <label>Arquivo</label>
                                <input
                                  type="text"
                                  value={feedback.file_name}
                                  readOnly
                                />
                              </div>
                            </div>
                          )}
                        </FeedbackItem>
                      ))}
                  </Feedback>
                </DetailsContainer>
              </Content>
            )}
          </>
        )}
        {showTable && (
          <>
            <Pagination
              loading={processesLoading ? 1 : 0}
              currentPage={currentPage}
              pages={totalPages}
              totalDocs={totalProcesses}
              handlePage={handlePage}
            />
            <Subtitles>
              <aside>
                <div>
                  <FaMinus size={12} color="#e1e1e1" />
                  <span>Desobrigado</span>
                </div>
                <div>
                  <FaRegCircle size={12} color="#9dd3fe" />
                  <span>Não realizado</span>
                </div>
                <div>
                  <FaCheckCircle size={12} color="#006229" />
                  <span>Realizado</span>
                </div>
                <div>
                  <FaClock size={12} color="#f4c306" />
                  <span>Em andamento</span>
                </div>
                <div>
                  <FaExclamationCircle size={12} color="#E53935" />
                  <span>Pendente</span>
                </div>
              </aside>
            </Subtitles>
          </>
        )}

        {popModalIsOpen && (
          <ShowPopModal
            isOpen={popModalIsOpen}
            setIsOpen={() => setPopModalIsOpen(false)}
            pop={popForModal.current}
          />
        )}
      </Container>

      {phaseFeedbacksModalIsOpen && (
        <FeedbacksModal
          data={feedbacksForModal.current}
          isOpen={phaseFeedbacksModalIsOpen}
          setIsOpen={() => setPhaseFeedbacksModalIsOpen(false)}
          type="phases"
        />
      )}

      {cancellationReasonModalIsOpen && (
        <CancellationReasonModal
          setIsOpen={() => setCancellationReasonModalIsOpen(false)}
          handleCancel={handleCancelProcess}
          processIndex={indexCancelProcess.current}
        />
      )}

      {confirmSendProcessModalIsOpen && (
        <ConfirmSendProcess
          onClick={handleSend}
          isOpen={confirmSendProcessModalIsOpen}
          setIsOpen={() => setConfirmSendProcessModalIsOpen(false)}
          changeDocSituation={handleChangeDocSituation}
          confirmDeleteViewer={confirmDeleteViewer}
          process={process}
          processType={processType.title}
        />
      )}

      {concludePhaseModalIsOpen && (
        <ConcludePhaseModal
          setIsOpen={() => setConcludePhaseModalIsOpen(false)}
          onClick={handleChangeSituation}
          type="process"
          variables={variablesToConcludeModal.current}
          info={infoToConcludeModal.current}
          notification={notification}
          setNotification={setNotification}
        />
      )}

      {confirmMarkDocumentModalIsOpen && (
        <ConfirmMarkDocument
          onClose={() => setConfirmMarkDocumentModalIsOpen(false)}
          onClick={handleChangeSituation}
          conclude={handleConcludePhase}
          phase={phaseToMarkDocumentModal.current}
          companyUsers={companyUsers}
          userId={user.id}
          permissionRequired={companyUser.level < 6}
        />
      )}

      {(loading || deleteFeedbackLoading || feedbackLoading) && <Loading />}
    </>
  );
};

export default List;

List.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      model_id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  FaMailBulk,
  FaTimes,
  FaPaperclip,
  FaSave,
  FaBroom,
  FaTrash,
} from 'react-icons/fa';

import history from '~/services/history';
import ConfirmWindow from '~/components/ConfirmWindow';
import { useAuth } from '~/hooks';
import api from '~/services/api';

import Loading from '~/components/Loading';
import { TableLoading } from '~/components/Table';

import {
  FormContainer,
  TextArea,
  Input,
  Select,
  FileInput,
} from '~/components/Form';

import {
  Container,
  Header,
  Controls,
  MailForm,
  UploadFile,
  FileName,
  File,
  Delete,
} from './styles';

const Form = () => {
  const { user, company } = useAuth();

  const { state } = useLocation();

  const id = state?.id || null;

  const formRef = useRef(null);

  const [uploadFileHeader, setUploadFileHeader] = useState([]);
  const [uploadFileFooter, setUploadFileFooter] = useState([]);

  const [testConcluded, setTestConcluded] = useState(false);

  const [typeHeaderData, setTypeHeaderData] = useState(0);
  const [typeFooterData, setTypeFooterData] = useState(0);
  const [typeServerData, setTypeServerData] = useState(1);
  const [initialData, setInitialData] = useState({});

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const serversOptions = useMemo(() => {
    const options = [
      { value: 1, label: 'Gmail' },
      { value: 2, label: 'Outlook' },
    ];

    return options;
  }, []);

  const typeOptions = useMemo(() => {
    const options = [
      { value: 0, label: 'texto' },
      { value: 1, label: 'Imagem' },
    ];

    return options;
  }, []);

  const loadSettings = useCallback(async () => {
    if (user && company) {
      try {
        setLoading(true);
        const response = await api.get(`/mails-smtp/${company.id}`);
        const {
          email,
          password,
          typeServer,
          typeHeader,
          header,
          typeFooter,
          footer,
        } = response.data;

        setTypeHeaderData(typeHeader);
        setTypeFooterData(typeFooter);
        setTypeServerData(typeServer);

        setInitialData({
          email: typeServer > 0 ? email : '',
          password: typeServer > 0 ? password : '',
          header: !typeHeader ? header : '',
          footer: !typeFooter ? footer : '',
          typeHeader,
          typeFooter,
          typeServer,
        });

        if (typeHeader === 1) {
          setUploadFileHeader({
            name: header,
            old: true,
          });
        }
        if (typeFooter === 1) {
          setUploadFileFooter({
            name: footer,
            old: true,
          });
        }

        setLoading(false);
      } catch (err) {
        toast.error('Falha ao buscar configurações de email.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setLoading(false);
      }
    }
  }, [user, company]);

  useEffect(() => {
    if (id) {
      loadSettings();
    } else {
      setInitialData({
        typeHeader: 0,
        typeFooter: 0,
        typeServer: 1,
      });
      setLoading(false);
    }
  }, [loadSettings, id]);

  const resetForm = useCallback(() => {
    formRef.current.reset();
    setUploadFileHeader([]);
    setUploadFileFooter([]);
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/mail-settings')}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleSubmit = useCallback(
    async data => {
      if (user && company) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            typeServer: Yup.number().required(
              'O tipo do servidor é obrigatório.'
            ),
            email: Yup.string()
              .email('O email inserido não esta correto.')
              .required('O email é obrigatório.'),
            password: Yup.string().required('A senha é obrigatória.'),
            typeHeader: Yup.number().required(
              'O tipo do cabeçalho é obrigatório.'
            ),
            header: Yup.string().required('O cabeçalho é obrigatório.'),
            typeFooter: Yup.number().required(
              'o tipo do rodapé é obrigatório.'
            ),
            footer: Yup.string().required('O cabeçalho é obrigatório.'),
            to: Yup.string()
              .email('O email inserido não esta correto.')
              .required('o destinatário de teste é obrigatório.'),
          });
          await schema.validate(data, {
            abortEarly: false,
          });

          if (typeHeaderData === 1 && !uploadFileHeader.old) {
            const formData = new FormData();

            formData.append('file', uploadFileHeader);

            const fileResponse = await api.post('files/upload', formData, {
              params: {
                prefix: 'Mail_Header',
              },
            });

            const { blobName } = fileResponse.data;

            data.headerLink = blobName;
            data.header = uploadFileHeader.name;
          }

          if (typeFooterData === 1 && !uploadFileFooter.old) {
            const formData = new FormData();

            formData.append('file', uploadFileFooter);

            const fileResponse = await api.post('files/upload', formData, {
              params: {
                prefix: 'Mail_Footer',
              },
            });

            const { blobName } = fileResponse.data;

            data.footerLink = blobName;
            data.footer = uploadFileFooter.name;
          }

          await api.post(`/mails-smtp/test`, data);

          toast.success('Teste concluído.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setTestConcluded(true);

          data.company_id = company.id;

          if (id) {
            await api.put(`/mails-smtp/${id}`, data);
          } else {
            await api.post('/mails-smtp', data);
          }

          toast.success('Configurações de email atualizadas.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);

          history.push('/mail-settings');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else if (!testConcluded) {
            toast.error(
              'Falha no teste, verifique se seus dados estão corretos.',
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          } else {
            toast.error('Falha ao salvar as configurações de email.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setSaveLoading(false);
          setTestConcluded(false);
        }
      }
    },
    [
      id,
      user,
      company,
      testConcluded,
      typeHeaderData,
      typeFooterData,
      uploadFileHeader,
      uploadFileFooter,
    ]
  );

  const handleTypeHeader = useCallback(selectedOption => {
    const { value } = selectedOption;

    setTypeHeaderData(value);
  }, []);

  const handleTypeFooter = useCallback(selectedOption => {
    const { value } = selectedOption;

    setTypeFooterData(value);
  }, []);

  const handleTypeServer = useCallback(selectedOption => {
    const { value } = selectedOption;

    setTypeServerData(value);
  }, []);

  const openFile = useCallback(async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  }, []);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaMailBulk size={20} color="#44546a" />
            <h1>Configurações de Email</h1>
          </div>
        </Header>

        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>

        {loading ? (
          <TableLoading />
        ) : (
          <FormContainer
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={initialData}
            className="content mail"
          >
            <MailForm>
              <h4>SMTP</h4>
              <section>
                <Select
                  name="typeServer"
                  className="server"
                  label="Servidor"
                  type="text"
                  options={serversOptions}
                  onChange={handleTypeServer}
                  placeholder="Selecione um servidor"
                />
              </section>

              <section>
                {typeServerData > 0 && (
                  <Input
                    name="email"
                    className="user"
                    label="Email"
                    type="email"
                  />
                )}
              </section>

              {typeServerData > 0 && (
                <section>
                  <Input
                    name="password"
                    className="password"
                    label="Senha"
                    type="password"
                  />
                </section>
              )}

              {typeServerData === 1 && (
                <span className="auth-span">
                  Caso seu email utilize verificação de duas etapas é necessário
                  uma senha de aplicativo, acesse o
                  <button
                    type="button"
                    onClick={() =>
                      openFile(
                        'Schedule_Feedback-3ab09375-f564-4816-90fa-4ac7a6e1a8cb@Criar_senha_de_aplicativo.pdf'
                      )
                    }
                  >
                    PDF
                  </button>
                  para saber como criar.
                </span>
              )}

              <h4>CABEÇALHO</h4>
              <section>
                <Select
                  name="typeHeader"
                  className="type"
                  label="Tipo"
                  type="text"
                  options={typeOptions}
                  onChange={handleTypeHeader}
                  placeholder="Selecione um tipo"
                />
                {typeHeaderData === 1 ? (
                  <UploadFile>
                    {uploadFileHeader.name ? (
                      <>
                        <Delete>
                          <button
                            type="button"
                            onClick={() => {
                              setUploadFileHeader([]);
                            }}
                          >
                            <FaTrash size={14} />
                          </button>
                        </Delete>
                        <FileName>
                          <Input
                            name="header"
                            value={uploadFileHeader.name}
                            type="text"
                            disabled
                          />
                        </FileName>
                      </>
                    ) : (
                      <File>
                        <label htmlFor="header">
                          <FaPaperclip size={14} color="#FCFCFC" />
                        </label>
                        <FileInput
                          id="header"
                          name="header"
                          accept="image/*"
                          onChange={e => {
                            setUploadFileHeader(e.target.files[0]);
                          }}
                        />
                      </File>
                    )}
                  </UploadFile>
                ) : (
                  <TextArea
                    name="header"
                    label="Texto"
                    className="description"
                  />
                )}
              </section>

              <h4>RODAPÉ</h4>
              <section>
                <Select
                  name="typeFooter"
                  className="type"
                  label="Tipo"
                  type="text"
                  options={typeOptions}
                  onChange={handleTypeFooter}
                  placeholder="Selecione um tipo"
                />
                {typeFooterData === 1 ? (
                  <UploadFile>
                    {uploadFileFooter.name ? (
                      <>
                        <Delete>
                          <button
                            type="button"
                            onClick={() => {
                              setUploadFileFooter([]);
                            }}
                          >
                            <FaTrash size={14} />
                          </button>
                        </Delete>
                        <FileName>
                          <Input
                            name="footer"
                            value={uploadFileFooter.name}
                            type="text"
                            disabled
                          />
                        </FileName>
                      </>
                    ) : (
                      <File>
                        <label htmlFor="footer">
                          <FaPaperclip size={14} color="#FCFCFC" />
                        </label>
                        <FileInput
                          id="footer"
                          name="footer"
                          accept="image/*"
                          onChange={e => {
                            setUploadFileFooter(e.target.files[0]);
                          }}
                        />
                      </File>
                    )}
                  </UploadFile>
                ) : (
                  <TextArea
                    name="footer"
                    label="Texto"
                    className="description"
                  />
                )}
              </section>

              {typeServerData === 1 && (
                <>
                  <h4>ETAPAS NECESSÁRIAS</h4>
                  <h4>Etapa 1:</h4>
                  <section>
                    <p>
                      Habilite a permissão para que outros sistemas possam fazer
                      uso do email
                      <a
                        href="https://www.google.com/settings/security/lesssecureapps"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        Clicando aqui
                      </a>
                    </p>
                  </section>
                  <section>
                    <p>
                      <b style={{ color: '#ff0000' }}>OBS.:</b> Verifique se o
                      email logado no link que irá abrir é o mesmo inserindo no
                      campo clicando no ícone no canto superior direiro.
                    </p>
                  </section>

                  <h4>Etapa 2:</h4>
                  <section>
                    <p>
                      Permita o acesso ao seu email
                      <a
                        href="https://accounts.google.com/b/0/displayunlockcaptcha"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        Clicando aqui
                      </a>
                    </p>
                  </section>

                  <h4>Etapa 3:</h4>
                  <section>
                    <p>
                      Caso a etapa 2 não funcione, talvez seja necessário o
                      Administrador da conta de email alterar os níveis de
                      segurança.
                      <a
                        href="https://admin.google.com/u/0/ac/security/lsa?hl=pt"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <br />
                        Clique aqui{' '}
                      </a>
                      para alterar os níveis de segurança e execute novamente a
                      etapa 2
                    </p>
                  </section>
                </>
              )}

              <h4>TESTE</h4>
              <section>
                <Input
                  name="to"
                  className="recipient"
                  label="Email destinatário"
                  type="email"
                />
                <button type="submit" className="test">
                  Teste
                </button>
              </section>
            </MailForm>
          </FormContainer>
        )}
      </Container>

      {saveLoading && <Loading />}
    </>
  );
};

export default Form;

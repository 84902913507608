import React from 'react';
import PropTypes from 'prop-types';

import Header from '~/components/Header';
import Sidebar from '~/components/Sidebar';
import BirthdayMessage from '~/components/BirthdayMessage';
import KpiChampionMessage from '~/components/KpiChampionMessage';

import { Wrapper } from './styles';

export default function defaultLayout({ children }) {
  return (
    <Wrapper>
      <Header />
      <Sidebar />
      <BirthdayMessage />
      <KpiChampionMessage />
      {children}
    </Wrapper>
  );
}

defaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

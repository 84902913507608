import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ThemeProvider } from 'styled-components';

import Routes from '~/routes';
import history from '~/services/history';

import NewContentAvailableNotification from '~/components/NewContentAvailableNotification';

import GlobalStyle from '~/styles/global';

import AppProvider from './contexts';

import { theme } from './styles/theme';
import { ProcessAuthProvider } from '~/contexts/processAuth';
import { PortalAuthProvider } from '~/contexts/portalAuth';

function App() {
  library.add(fas);

  useEffect(() => {
    history.listen(() => {
      // check for sw updates on page change
      navigator.serviceWorker
        .getRegistrations()
        .then(regs => regs.forEach(reg => reg.update()));
    });
  }, []);

  const handleUpdateServiceWorker = () => {
    navigator.serviceWorker.getRegistrations().then(regs =>
      regs.forEach(reg => {
        reg.waiting.postMessage({ type: 'SKIP_WAITING' });
      })
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Router history={history}>
        <AppProvider>
          <ProcessAuthProvider>
            <PortalAuthProvider>
              <ThemeProvider theme={theme}>
                <Routes />
              </ThemeProvider>
            </PortalAuthProvider>
          </ProcessAuthProvider>
        </AppProvider>
        <ToastContainer autoClose={5000} toastClassName="foo" />
        <NewContentAvailableNotification
          updateServiceWorker={handleUpdateServiceWorker}
        />
        <GlobalStyle />
      </Router>
    </DndProvider>
  );
}

export default App;

import React, { useState, useEffect } from 'react';

import api from '~/services/api';

const PermissionComponent = ({ level, children }) => {
  const [userLevel, setUserLevel] = useState(false);

  useEffect(() => {
    async function loadRoles() {
      const response = await api.get('company-users/get/level');

      setUserLevel(response.data);
    }

    loadRoles();
  }, []);

  return <>{userLevel >= level && children}</>;
};

export default PermissionComponent;

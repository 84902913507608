import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 100%;
  width: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;

  h1 {
    margin-top: 1rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.blue};
    font-size: 1.5rem;
  }

  box-shadow: 0px 0px 20px -10px;
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: row;

    height: 7rem;
    width: 21rem;
    max-width: 90%;
    margin: 20px 10px;

    cursor: pointer;

    font-size: 1rem;
    opacity: 1;
    border-radius: 5px;

    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  .client {
    height: 4rem;
  }
`;

export const Card = styled.div`
  align-items: center;
  justify-content: space-around;

  background: rgba(230, 230, 230);
  color: #000;

  border: 0;
  border-bottom: 3px solid ${({ theme }) => theme.colors.blue};

  div {
    display: flex;
    flex-direction: column;
    width: 17rem;
    max-width: calc(100% - 3rem);
  }

  svg {
    margin: 0 1rem;
  }

  span,
  strong {
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 700px) {
    span,
    strong {
      font-size: 0.9rem;
    }

    div {
      max-width: calc(100% - 2rem);
    }

    svg {
      margin: 0 0.5rem;
    }
  }
`;

export const NewProcess = styled.div`
  background: ${({ theme }) => theme.colors.blue};
  color: #fff;
  align-items: center;
  justify-content: center;

  border: 0;
`;

export const Search = styled.div`
  margin: 10px calc(50% - 200px);
  display: flex;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: 5px;

  input {
    width: 370px;
    height: 30px;
    margin-left: 5px;
  }

  button {
    border: 0;
    width: 30px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: ${({ theme }) => theme.colors.blue};

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
    }
  }

  @media (max-width: 700px) {
    margin: 10px 20px;
    width: calc(100% - 40px);

    input {
      width: calc(100% - 30px);
    }
  }
`;

import { darken, lighten } from 'polished';

export const theme = {
  colors: {
    primary: '#44546a',
    grey: '#545454',
    white: '#fafafa',
    greyBackground: 'rgba(230,230,230,0.95)',
    iceBackground: 'rgba(209,209,240,0.15)',
    darkBackground: 'rgba(0, 0, 0, 0.65)',
    blue: '#01579B',
    success: '#00c853',
    error: '#E53935',
    border: '#c7c7c7',
    lightBorder: '#ddd',
    orange: '#ff6600',
  },
  hover: {
    primary: lighten(0.1, '#44546a'),
    success: darken(0.05, '#00c853'),
    error: darken(0.05, '#E53935'),
    blue: darken(0.05, '#01579B'),
    lightBorder: lighten(0.09, '#ddd'),
  },
};

import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, subYears, addYears, addMonths } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import { useCookies } from 'react-cookie';

import {
  FaCalculator,
  FaTimes,
  FaEye,
  FaChevronLeft,
  FaChevronRight,
  FaSlidersH,
  FaMinus,
  FaCheckCircle,
  FaRegCircle,
  FaBan,
  FaSearch,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import PermissionComponent from '~/components/PermissionComponent';
import { SelectWithFilterActiveCheckbox, Select } from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import ConfirmWindow from '../ConfirmMarkDocument';
import ParameterModal from '../ParameterModal';

import { Container, Header, DetailsContainer } from '~/styles/components';

import {
  Controls,
  Content,
  Subtitles,
  DocumentTd,
  DetailsInfo,
  Filter,
} from './styles';

const List = () => {
  const { company, companyUser, companyUsers, user } = useAuth();

  const [cookies, setCookie] = useCookies();

  const filterRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [toggleParameter, setToggleParameter] = useState(false);

  const [accountingChecklist, setAccountingChecklist] = useState([]);
  const [clientIndex, setClientIndex] = useState(0);
  const [totalClients, setTotalClients] = useState(0);

  const [usersOptions, setUsersOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTaxationType, setSelectedTaxationType] = useState(() => {
    if (cookies.accounting_checklist_taxation_type) {
      return cookies.accounting_checklist_taxation_type;
    }

    return { value: '', label: 'Todos', database_label: '' };
  });
  const [selectedContractType, setSelectedContractType] = useState(() => {
    if (cookies.accounting_checklist_contract_type) {
      return cookies.accounting_checklist_contract_type;
    }

    return { value: '', label: 'Todos' };
  });

  const [period, setPeriod] = useState(() => {
    const periodStorage = localStorage.getItem(
      '@Diretiva:tax-checklist:filter:period'
    );

    if (periodStorage) {
      return new Date(periodStorage);
    }

    return new Date();
  });

  const formattedPeriod = useMemo(() => {
    return format(period, 'yyyy');
  }, [period]);

  const months = useMemo(() => {
    const result = [];

    for (let i = 1; i <= 12; i += 1) {
      result.push(`${String(i).padStart(2, '0')}/${formattedPeriod.slice(-2)}`);
    }

    return result;
  }, [formattedPeriod]);

  const taxationTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos', database_label: '' },
      {
        value: 6,
        label: 'Empregador PF',
        database_label: 'taxation_empregador_pf',
      },
      {
        value: 5,
        label: 'Empregador Doméstico',
        database_label: 'taxation_empregador_domestico',
      },
      { value: 1, label: 'MEI', database_label: 'taxation_mei' },
      {
        value: 11,
        label: 'MEI Somente Declarações',
        database_label: 'taxation_mei_somente_declaracoes',
      },
      {
        value: 7,
        label: 'Simples Nacional C/ Inscrição',
        database_label: 'taxation_simples_com_inscricao',
      },
      {
        value: 8,
        label: 'Simples Nacional S/ Inscrição',
        database_label: 'taxation_simples_sem_inscricao',
      },
      {
        value: 3,
        label: 'Lucro Presumido',
        database_label: 'taxation_lucro_presumido',
      },
      { value: 4, label: 'Lucro Real', database_label: 'taxation_lucro_real' },
      { value: 9, label: 'Condomínio', database_label: 'taxation_condominio' },
      {
        value: 10,
        label: 'Terceiro Setor',
        database_label: 'taxation_terceiro',
      },
    ];
  }, []);

  const contractTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Terceirizado' },
      { value: 2, label: 'Próprio' },
    ];
  }, []);

  const checkIfMonthIsComplete = useCallback(
    array => {
      const result = [];

      for (let i = 0; i < months.length; i += 1) {
        let isComplete = true;

        Object.entries(array).forEach(item => {
          if (
            item[1].find(monthDoc => monthDoc.month === i + 1) &&
            item[1].find(monthDoc => monthDoc.month === i + 1).situation === 1
          ) {
            isComplete = false;
          }
        });

        result[i] = isComplete;
      }

      return result;
    },
    [months]
  );

  const loadChecklist = useCallback(async () => {
    if (company && selectedUser) {
      try {
        setLoading(true);

        const checklistResponse = await api.get('checklists', {
          params: {
            company_id: company.id,
            department: 'dpt_accounting',
            period: formattedPeriod,
          },
        });

        const { checklist_id } = checklistResponse.data;

        const checklistDocumentsResponse = await api.get(
          `checklists/accounting-documents/${checklist_id}`,
          {
            params: {
              year: formattedPeriod,
              responsible_id: selectedUser.value,
              taxation_type: selectedTaxationType.value,
              contract_type: selectedContractType.value,
            },
          }
        );

        if (checklistDocumentsResponse.data.length === 0) {
          toast.warn('Nenhum checklist foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setAccountingChecklist([]);
          setTotalClients(0);

          return;
        }

        const formattedData = checklistDocumentsResponse.data.map(
          checklistItem => {
            return {
              client_name: checklistItem.client_name,
              documents: Object.entries(checklistItem.documents).map(
                document => ({
                  document_name: document[0],
                  months: document[1],
                })
              ),
              isMonthComplete: checkIfMonthIsComplete(checklistItem.documents),
            };
          }
        );

        setAccountingChecklist(formattedData);
        setTotalClients(checklistDocumentsResponse.data.length);
      } catch (err) {
        toast.error('Falha ao buscar checklist.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [
    company,
    checkIfMonthIsComplete,
    formattedPeriod,
    selectedUser,
    selectedTaxationType,
    selectedContractType,
  ]);

  useEffect(() => {
    loadChecklist();
  }, [loadChecklist]);

  useEffect(() => {
    if (!cookies.accounting_checklist_user && companyUser && user && company) {
      setCookie(
        'accounting_checklist_user',
        {
          value: companyUser.id,
          label: user.short_name,
          company_id: company.id,
        },
        {
          expires: addMonths(new Date(), 1),
        }
      );
    }
  }, [setCookie, cookies, companyUser, user, company]);

  useEffect(() => {
    if (companyUser && companyUser && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.id,
            label: userItem.short_name,
            active: userItem.active,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: companyUser.id,
        label: user.short_name,
        active: true,
      });

      options.push({
        value: '',
        label: 'Todos',
        active: true,
      });

      setUsersOptions(options);
    }
  }, [companyUsers, companyUser, user]);

  useEffect(() => {
    if (companyUser && user && company && !selectedUser) {
      if (
        !cookies.accounting_checklist_user ||
        cookies?.accounting_checklist_user?.company_id !== company.id
      ) {
        setSelectedUser({
          value: companyUser.id,
          label: user.short_name,
          company_id: company.id,
        });
      } else {
        setSelectedUser(cookies.accounting_checklist_user);
      }
    }
  }, [
    companyUser,
    user,
    company,
    cookies.accounting_checklist_user,
    selectedUser,
  ]);

  const alterView = useCallback(() => {
    setShowTable(!showTable);
  }, [showTable]);

  const handleToggleParameter = useCallback(() => {
    setToggleParameter(!toggleParameter);
  }, [toggleParameter]);

  const handlePrevItem = useCallback(() => {
    if (clientIndex !== 0) {
      setClientIndex(clientIndex - 1);
    } else {
      setClientIndex(accountingChecklist.length - 1);
    }
  }, [clientIndex, accountingChecklist]);

  const handleNextItem = useCallback(() => {
    if (clientIndex !== accountingChecklist.length - 1) {
      setClientIndex(clientIndex + 1);
    } else {
      setClientIndex(0);
    }
  }, [clientIndex, accountingChecklist]);

  const handlePrevPeriod = useCallback(() => {
    localStorage.setItem(
      '@Diretiva:tax-checklist:filter:period',
      subYears(period, 1)
    );
    setPeriod(oldPeriod => subYears(oldPeriod, 1));
  }, [period]);

  const handleNextPeriod = useCallback(() => {
    localStorage.setItem(
      '@Diretiva:tax-checklist:filter:period',
      addYears(period, 1)
    );
    setPeriod(oldPeriod => addYears(oldPeriod, 1));
  }, [period]);

  const handleDetails = useCallback(
    index => {
      setClientIndex(index);
      alterView();
    },
    [alterView]
  );

  const handleFilter = useCallback(
    ({ user_id, taxation_type, contract_type }) => {
      if (company) {
        localStorage.setItem('@Diretiva:accounting_checklist:page', 1);

        setSelectedUser(usersOptions.find(option => option.value === user_id));
        setCookie(
          'accounting_checklist_user',
          Object.assign(
            usersOptions.find(option => option.value === user_id),
            { company_id: company?.id }
          ),
          {
            expires: addMonths(new Date(), 1),
          }
        );

        setSelectedTaxationType(
          taxationTypeOptions.find(option => option.value === taxation_type)
        );
        setCookie(
          'accounting_checklist_taxation_type',
          taxationTypeOptions.find(option => option.value === taxation_type),
          {
            expires: addMonths(new Date(), 1),
          }
        );

        setSelectedContractType(
          contractTypeOptions.find(option => option.value === contract_type)
        );
        setCookie(
          'accounting_checklist_contract_type',
          contractTypeOptions.find(option => option.value === contract_type),
          {
            expires: addMonths(new Date(), 1),
          }
        );
      }
    },
    [usersOptions, taxationTypeOptions, setCookie, company, contractTypeOptions]
  );

  const handleFilterUsersOptions = useCallback(
    value => {
      if (value === true) {
        setUsersOptions(oldUsersOptions =>
          oldUsersOptions.filter(userItem => userItem.active !== false)
        );
      } else if (companyUsers && companyUser && user) {
        const options = companyUsers
          .filter(userItem => userItem.user_id !== user.id)
          .map(userItem => {
            return {
              value: userItem.id,
              label: userItem.short_name,
              active: userItem.active,
            };
          });

        options.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        options.unshift({
          value: companyUser.id,
          label: user.short_name,
          active: true,
        });

        options.push({
          value: '',
          label: 'Todos',
          active: true,
        });

        setUsersOptions(options);
      }
    },
    [companyUsers, user, companyUser]
  );

  const handleMarkDocument = useCallback(
    async (documentIndex, documentId, situation) => {
      setAccountingChecklist(oldAccountingChecklist =>
        oldAccountingChecklist.map((client, index) => {
          if (index === clientIndex) {
            return {
              client_name: client.client_name,
              documents: client.documents.map(
                (document, clientDocumentIndex) => {
                  if (clientDocumentIndex === documentIndex) {
                    return {
                      document_name: document.document_name,
                      months: document.months.map(monthDocument =>
                        monthDocument.id === documentId
                          ? { ...monthDocument, situation }
                          : monthDocument
                      ),
                    };
                  }

                  return document;
                }
              ),
              isMonthComplete: client.isMonthComplete,
            };
          }

          return client;
        })
      );

      await api.put(
        `checklists/accounting-documents/mark-document/${documentId}`,
        {
          situation,
        }
      );
    },
    [clientIndex]
  );

  const confirmMarkDocument = useCallback(
    async (
      documentIndex,
      documentId,
      documentName,
      clientName,
      monthPeriod
    ) => {
      if (!companyUser) {
        return;
      }

      if (companyUser.id !== selectedUser.value && companyUser.level !== 9) {
        toast.warn('Somente o responsável pode marcar documentos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        return;
      }

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={handleMarkDocument}
              onClose={onClose}
              document={{
                documentName,
                documentId,
                documentIndex,
                clientName,
                monthPeriod,
              }}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleMarkDocument, companyUser, selectedUser]
  );

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaCalculator size={20} />
            <h1>Checklist Contábil</h1>
          </div>

          <Link to="/">
            <FaTimes size={20} />
          </Link>
        </Header>

        <Controls>
          <nav>
            <button type="button" onClick={alterView}>
              <FaEye />
              <span>Visualização</span>
            </button>
            <PermissionComponent level={6}>
              <button type="button" onClick={handleToggleParameter}>
                <FaSlidersH />
                <span>Parâmetros</span>
              </button>
            </PermissionComponent>
            {!showTable && (
              <>
                <div>
                  <button type="button" onClick={handlePrevItem}>
                    <FaChevronLeft />
                  </button>
                  <span>
                    {clientIndex + 1} de {totalClients}
                  </span>
                  <button type="button" onClick={handleNextItem}>
                    <FaChevronRight />
                  </button>
                </div>
              </>
            )}
          </nav>

          <aside>
            <button type="button" onClick={handlePrevPeriod}>
              <FaChevronLeft />
            </button>
            <span>{formattedPeriod}</span>
            <button type="button" onClick={handleNextPeriod}>
              <FaChevronRight />
            </button>
          </aside>
        </Controls>

        {selectedUser && (
          <Filter ref={filterRef} onSubmit={handleFilter}>
            <SelectWithFilterActiveCheckbox
              label="Usuário"
              name="user_id"
              className="user"
              options={usersOptions}
              defaultValue={selectedUser}
              handleFilter={handleFilterUsersOptions}
            />

            <Select
              label="Tributação"
              name="taxation_type"
              className="taxation_type"
              options={taxationTypeOptions}
              defaultValue={selectedTaxationType}
            />

            <Select
              label="Tipo de contrato"
              name="contract_type"
              className="contract_type"
              options={contractTypeOptions}
              defaultValue={selectedContractType}
            />

            <button type="submit">
              <FaSearch />
            </button>
          </Filter>
        )}

        {loading || !company ? (
          <TableLoading />
        ) : (
          <>
            <Content className="content">
              {showTable ? (
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="company">Empresa</th>
                      {months.map(month => (
                        <th key={month} className="month">
                          {month}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {accountingChecklist.map((document, index) => (
                      <tr key={document.client_name}>
                        <th
                          className="company"
                          onClick={() => handleDetails(index)}
                        >
                          {document.client_name}
                        </th>
                        {months.map((month, monthIndex) => (
                          <DocumentTd key={month}>
                            {document.isMonthComplete[monthIndex] ? (
                              <FaCheckCircle color="#006229" />
                            ) : (
                              <FaRegCircle color="#9dd3fe" />
                            )}
                          </DocumentTd>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </TableContainer>
              ) : (
                <DetailsContainer>
                  <DetailsInfo>
                    <section>
                      <div className="company">
                        <label>Empresa</label>
                        <input
                          name="company"
                          value={accountingChecklist[clientIndex].client_name}
                          readOnly
                        />
                      </div>
                    </section>
                  </DetailsInfo>
                  <TableContainer className="details">
                    <thead>
                      <tr>
                        <th className="document">Documentos</th>
                        {months.map(month => (
                          <th key={month} className="month">
                            {month}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {accountingChecklist[clientIndex].documents.map(
                        (document, index) => (
                          <tr key={document.document_name}>
                            <th style={{ width: 250 }}>
                              {document.document_name}
                            </th>
                            {document.months.map(monthDocument => (
                              <DocumentTd
                                key={monthDocument.id}
                                onClick={() => {
                                  confirmMarkDocument(
                                    index,
                                    monthDocument.id,
                                    document.document_name,
                                    accountingChecklist[clientIndex]
                                      .client_name,
                                    months[monthDocument.month - 1]
                                  );
                                }}
                                hover
                              >
                                {monthDocument.situation === 0 && (
                                  <FaMinus color="#e1e1e1" />
                                )}
                                {monthDocument.situation === 1 && (
                                  <FaRegCircle color="#9dd3fe" />
                                )}
                                {monthDocument.situation === 2 && (
                                  <FaCheckCircle color="#006229" />
                                )}
                                {monthDocument.situation === 3 && (
                                  <FaBan color="#E53935" />
                                )}
                              </DocumentTd>
                            ))}
                          </tr>
                        )
                      )}
                    </tbody>
                  </TableContainer>
                </DetailsContainer>
              )}
            </Content>
          </>
        )}
        {!loading && (
          <>
            {showTable ? (
              <Subtitles>
                <span>{totalClients} registro(s)</span>
                <aside>
                  <div>
                    <FaRegCircle size={12} color="#9dd3fe" />
                    <span>Não realizado</span>
                  </div>
                  <div>
                    <FaCheckCircle size={12} color="#006229" />
                    <span>Realizado</span>
                  </div>
                </aside>
              </Subtitles>
            ) : (
              <Subtitles>
                <aside>
                  <div>
                    <FaMinus size={12} color="#e1e1e1" />
                    <span>Desobrigado</span>
                  </div>
                  <div>
                    <FaRegCircle size={12} color="#9dd3fe" />
                    <span>Não realizado</span>
                  </div>
                  <div>
                    <FaCheckCircle size={12} color="#006229" />
                    <span>Realizado</span>
                  </div>
                  <div>
                    <FaBan size={12} color="#E53935" />
                    <span>Inexistente</span>
                  </div>
                </aside>
              </Subtitles>
            )}
          </>
        )}
      </Container>
      {toggleParameter && (
        <ParameterModal
          isOpen={toggleParameter}
          setIsOpen={handleToggleParameter}
        />
      )}
    </>
  );
};

export default List;

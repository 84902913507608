import React, { useEffect, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import {
  FaFolder,
  FaTimes,
  FaEye,
  FaPlus,
  FaEdit,
  FaTrash,
  FaChevronRight,
  FaChevronLeft,
  FaCheck,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import { TableLoading } from '~/components/Table';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';

import {
  Container,
  Header,
  Controls,
  Table,
  DetailsContainer,
} from '~/styles/components';
import { Content, Folder, SubFolderList } from './styles';
import PermissionComponent from '~/components/PermissionComponent';

const List = () => {
  const { company } = useAuth();

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const [folder, setFolder] = useState([]);
  const [folders, setFolders] = useState([]);
  const [folderIndex, setFolderIndex] = useState([]);
  const [totalFolders, setTotalFolders] = useState([]);

  const loadFolders = useCallback(async () => {
    if (company) {
      try {
        setLoading(true);

        const response = await api.get('folders', {
          params: {
            company_id: company.id,
          },
        });

        if (response.data.length > 0) {
          setFolders(response.data);
          setTotalFolders(response.data.length);
        } else {
          toast.warn('Nenhuma pasta foi encontrada.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch (err) {
        toast.error('Falha ao buscar pastas.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [company]);

  useEffect(() => {
    loadFolders();
  }, [loadFolders]);

  const handleView = useCallback(() => {
    if (folders.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, folders]);

  const handlePrevItem = useCallback(() => {
    if (folderIndex !== 0) {
      setFolderIndex(folderIndex - 1);
      setFolder(folders[folderIndex - 1]);
    } else {
      setFolderIndex(folders.length - 1);
      setFolder(folders[folders.length - 1]);
    }
  }, [folderIndex, folders]);

  const handleNextItem = useCallback(() => {
    if (folderIndex !== folders.length - 1) {
      setFolderIndex(folderIndex + 1);
      setFolder(folders[folderIndex + 1]);
    } else {
      setFolderIndex(0);
      setFolder(folders[0]);
    }
  }, [folderIndex, folders]);

  const handleDelete = useCallback(
    async id => {
      try {
        setDeleteLoading(true);

        await api.delete(`folders/${id}`);

        handleView();

        loadFolders();

        toast.success('Pasta deletada com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch (err) {
        toast.error('Falha ao deletar pasta.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setDeleteLoading(false);
      }
    },
    [handleView, loadFolders]
  );

  const confirmDelete = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => handleDelete(folder.id)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete, folder]);

  const handleDetails = useCallback(
    (id, index) => {
      const folderDetails = folders.find(item => item.id === id);

      setFolder(folderDetails);
      setFolderIndex(index);
      handleView();
    },
    [folders, handleView]
  );

  return (
    <Container>
      <Header>
        <div>
          <FaFolder />
          <h1>Pastas</h1>
        </div>

        <Link to="/">
          <FaTimes />
        </Link>
      </Header>
      <Controls>
        <button type="button" onClick={handleView}>
          <FaEye />
          <span>Visualização</span>
        </button>
        <PermissionComponent level={9}>
          <Link to={{ pathname: '/folder/new', state: { id: null } }}>
            <FaPlus />
            <span>Novo</span>
          </Link>
        </PermissionComponent>
        {!showTable && (
          <>
            <PermissionComponent level={9}>
              <Link to={{ pathname: '/folder/edit', state: { id: folder.id } }}>
                <FaEdit />
                <span>Editar</span>
              </Link>
              <button type="button" onClick={confirmDelete}>
                <FaTrash />
                <span>Excluir</span>
              </button>
            </PermissionComponent>
            <div>
              <button type="button" onClick={handlePrevItem}>
                <FaChevronLeft />
              </button>
              {totalFolders > 25 ? (
                <span>{folderIndex + 1} de 25</span>
              ) : (
                <span>
                  {folderIndex + 1} de {totalFolders}
                </span>
              )}
              <button type="button" onClick={handleNextItem}>
                <FaChevronRight />
              </button>
            </div>
          </>
        )}
      </Controls>
      {loading ? (
        <TableLoading />
      ) : (
        <Content className="content">
          {showTable ? (
            <Table>
              <thead>
                <tr>
                  <th className="barcode-table">Cod. Barras</th>
                  <th className="description-table">Descrição</th>
                  <th className="department-table">Departamento</th>
                  <th className="color-table">Cor</th>
                  <th className="portal-table">Portal</th>
                </tr>
              </thead>
              <tbody>
                {folders?.map((folderItem, index) => (
                  <tr
                    key={folderItem.id}
                    className="hover"
                    onClick={() => handleDetails(folderItem.id, index)}
                  >
                    <td className="barcode-table">{folderItem.barcode}</td>
                    <td className="description-table">
                      {folderItem.description}
                    </td>
                    <td className="department-table">
                      {folderItem.department_label}
                    </td>
                    <td className="color-table">{folderItem.color_label}</td>
                    <td className="portal-table">
                      {folderItem.portal && (
                        <FaCheck size={12} color="#01579B" />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <DetailsContainer>
              <Folder>
                <h4>PASTA</h4>
                <section>
                  <div className="barcode">
                    <label>Cod. Barras</label>
                    <input
                      name="barcode"
                      value={folder?.barcode || ''}
                      readOnly
                    />
                  </div>
                  <div className="description">
                    <label>Descrição</label>
                    <input
                      name="description"
                      value={folder?.description || ''}
                      readOnly
                    />
                  </div>
                  <div className="department">
                    <label>Departamento</label>
                    <input
                      name="department"
                      value={folder?.department_label || ''}
                      readOnly
                    />
                  </div>
                  <div className="color">
                    <label>Cor</label>
                    <input
                      name="color"
                      value={folder?.color_label || ''}
                      readOnly
                    />
                  </div>
                </section>
                <section>
                  <div className="portal">
                    <label>Portal</label>
                    <input
                      name="portal"
                      type="checkbox"
                      checked={folder?.portal || ''}
                      readOnly
                    />
                  </div>
                </section>
              </Folder>
              <SubFolderList>
                <h4>SUB PASTAS</h4>
                {folder?.subFolders?.map(subFolder => (
                  <section key={subFolder.id}>
                    <div className="description">
                      <label>Departamento</label>
                      <input
                        name="description"
                        value={subFolder?.description || ''}
                        readOnly
                      />
                    </div>
                  </section>
                ))}
              </SubFolderList>
            </DetailsContainer>
          )}
        </Content>
      )}
      {deleteLoading && <Loading />}
    </Container>
  );
};

export default List;

import { subHours, subDays, subWeeks, subMonths, subYears } from 'date-fns';

const schedulerLastCreation = (type, quantity, datetime) => {
  if (type === 1) {
    return subHours(datetime, quantity);
  }
  if (type === 2) {
    return subDays(datetime, quantity);
  }
  if (type === 3) {
    return subWeeks(datetime, quantity);
  }
  if (type === 4) {
    return subMonths(datetime, quantity);
  }
  if (type === 5) {
    return subYears(datetime, quantity);
  }
  return '';
};

export default schedulerLastCreation;

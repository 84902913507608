import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  .barcode-table {
    padding-left: 8px;
    width: 10%;
  }

  .description-table {
    width: 45%;
  }

  .department-table,
  .color-table {
    width: 20%;
  }

  .portal-table {
    width: 5%;
    text-align: center;
  }
`;

export const Folder = styled.div`
  display: flex;
  flex-direction: column;

  .barcode {
    width: 10%;
  }

  .color {
    width: 15;
  }

  .description {
    width: 50%;
  }

  .department {
    width: 25%;
  }

  .portal {
    width: 100%;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    > input {
      margin-right: 16px;
      transform: scale(1.5);
    }
  }
`;

export const SubFolderList = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .description {
    width: 100%;
  }
`;

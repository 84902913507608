import styled from 'styled-components';

export const Container = styled.div`
  display: ${props => (props.visible && !props.hide ? 'flex' : 'none')};
  position: absolute;
  z-index: ${props => (props.visible ? '10' : '9')};
  top: 30%;
  left: 50%;
  width: 330px;
  height: 250px;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 3px ${({ theme }) => theme.colors.darkBackground};
  border-radius: 4px;
  padding: 10px;
  flex-wrap: wrap;
  overflow-y: auto;
  opacity: ${props => (props.loading ? '0.5' : '1')};

  button {
    display: flex;
    flex-direction: column;
    width: calc(33.33333% - 0.5px);
    align-items: center;
    padding: 15px;
    text-align: center;
    background: none;
    height: 150px;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
  }
`;

export const Loading = styled.div`
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 4;
`;

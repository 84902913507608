import React, { useRef, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import { lighten } from 'polished';

import { ButtonSpinner } from '../../Spinner';

import { Container, SpinnerContainer, SelectContainer } from './styles';

const InputSelect = ({
  name,
  label,
  className,
  onChangeInput,
  onChangeSelect,
  options,
  loading,
  ...rest
}) => {
  const inputRef = useRef(null);

  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const customStyles = {
    container: provided => ({
      ...provided,
      display: 'inline-block',
      minHeight: '1px',
      textAlign: 'left',
      border: '0',
    }),
    control: provided => ({
      ...provided,
      borderRadius: '0',
      minHeight: '1px',
      height: '30px',
      border: 0,
      borderBottom: error ? '1px solid #e53935' : '1px solid #ddd',
      fontSize: 12,
      background: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '',
      },
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#ccc',
      '&:hover': {
        color: '#ccc',
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: '#545454',
    }),
    option: (provided, { isDisabled: disabled, isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: disabled
        ? null
        : isSelected
        ? '#01579b'
        : isFocused
        ? '#e5eef5'
        : null,
    }),
    input: provided => ({
      ...provided,
      top: '40%',
    }),
  };

  return (
    <Container className={className} error={error ? 1 : 0}>
      <label>{label}</label>
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        onChange={onChangeInput}
        onFocus={clearError}
        {...rest}
      />
      {loading && (
        <SpinnerContainer>
          <ButtonSpinner size={12} color="#545454" />
        </SpinnerContainer>
      )}
      {error && <span>{error}</span>}
      {options && options.length > 0 && (
        <SelectContainer>
          <ReactSelect
            onChange={onChangeSelect}
            options={options}
            styles={customStyles}
            classNamePrefix="react-select"
            placeholder="Selecione uma opção"
            defaultMenuIsOpen
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#094AB2',
                primary25: lighten(0.55, '#094AB2'),
              },
            })}
          />
        </SelectContainer>
      )}
    </Container>
  );
};

export default InputSelect;

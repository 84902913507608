import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: ${props => (props.isDisabled ? '0.4' : '1')};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'all')};

  > label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  > span {
    position: absolute;
    top: 45px;
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
  }

  .only-actives {
    display: flex;
    align-items: center;

    height: 30px;
    width: 100%;
    padding-left: 10px;

    label {
      margin-left: 4px;
      opacity: 1;
      font-size: 13px;
    }
  }
`;

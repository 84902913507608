import React, { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';
import {
  FaUserFriends,
  FaTimes,
  FaEye,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSave,
  FaBroom,
  FaEraser,
  FaSearch,
} from 'react-icons/fa';

import api from '~/services/api';

import { FormContainer, FormLoading, Input } from '~/components/Form';
import Modal from '~/components/Modal';
import { TableLoading, TableContainer } from '~/components/Table';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  UserInfo,
} from './styles';

const Users = ({ isOpen, setIsOpen }) => {
  const formRef = useRef(null);
  const filterRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [isCreatingOrEditing, setIsCreatingOrEditing] = useState(false);

  const [users, setUsers] = useState([]);
  const [userDetails, setUserDetails] = useState({});

  const [totalUsers, setTotalUsers] = useState(0);
  const [userIndex, setUserIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);

  const [nameSearch, setNameSearch] = useState('');
  const [emailSearch, setEmailSearch] = useState('');

  const loadUsers = useCallback(async () => {
    if (isCreatingOrEditing === false) {
      try {
        setLoading(true);

        const response = await api.get('users', {
          params: {
            page: currentPage,
            name: nameSearch,
            email: emailSearch,
          },
        });

        if (response.data.docs.length > 0) {
          setUsers(response.data.docs);
          setUserDetails(response.data.docs[0]);
          setTotalPages(response.data.pages);
          setTotalUsers(response.data.total);
        } else {
          setUsers([]);
          setUserDetails([]);
          setTotalPages(1);
          setTotalUsers(0);
          toast.warn('Nenhum usuário foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch (err) {
        toast.error(
          `${err.response.data.message || 'Falha ao buscar usuários.'}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      }

      setLoading(false);
    }
  }, [isCreatingOrEditing, currentPage, nameSearch, emailSearch]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  useEffect(() => {
    async function loadUser() {
      if (isCreatingOrEditing === true) {
        if (userId) {
          try {
            setLoading(true);

            const response = await api.get(`users/${userId}`);

            if (response.data) {
              setUser(response.data);
            }
          } catch (err) {
            toast.error(
              `${err.response.data.message || 'Falha ao buscar usuários.'}`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          }
          setLoading(false);
        }
      }
    }

    loadUser();
  }, [isCreatingOrEditing, userId]);

  const handleFilter = useCallback(({ name, email }) => {
    setCurrentPage(1);

    setNameSearch(name);
    setEmailSearch(email);
  }, []);

  const handleResetFilter = useCallback(() => {
    setCurrentPage(1);

    filterRef.current.reset();

    setNameSearch('');

    setEmailSearch('');
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        if (data.password === '') {
          delete data.password;
        }

        await api.put(`users/${userId}`, data);

        formRef.current.setErrors({});

        toast.success('Usuário salvo com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setSaveLoading(false);
        setUser(null);
        setIsCreatingOrEditing(false);
        setShowTable(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
        } else {
          toast.error(
            `${err.response.data.message || 'Falha ao salvar usuário.'}`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }

        setSaveLoading(false);
      }
    },
    [userId]
  );

  const handleView = useCallback(() => {
    if (users.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, users]);

  const getDetails = useCallback(
    (id, index) => {
      const userItem = users.find(item => item.id === id);

      setUserDetails(userItem);
      setUserIndex(index);
      handleView();
    },
    [users, handleView]
  );

  const handlePrevItem = useCallback(() => {
    if (userIndex !== 0) {
      setUserIndex(userIndex - 1);
      setUserDetails(users[userIndex - 1]);
    } else {
      setUserIndex(users.length - 1);
      setUserDetails(users[users.length - 1]);
    }
  }, [userIndex, users]);

  const handleNextItem = useCallback(() => {
    if (userIndex !== users.length - 1) {
      setUserIndex(userIndex + 1);
      setUserDetails(users[userIndex + 1]);
    } else {
      setUserIndex(0);
      setUserDetails(users[0]);
    }
  }, [userIndex, users]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },
    [totalPages]
  );

  const handleEdit = useCallback(() => {
    setUserId(userDetails.id);
    setIsCreatingOrEditing(true);
  }, [userDetails]);

  const handleDelete = useCallback(async () => {
    try {
      setDeleteLoading(true);

      await api.delete(`users/${userDetails.id}`);

      handleView();
      loadUsers();

      toast.success('Usuário deletado com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (err) {
      toast.error(
        `${err.response.data.message || 'Falha ao delete usuário.'}`,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
    } finally {
      setDeleteLoading(false);
    }
  }, [userDetails, handleView, loadUsers]);

  const confirmDelete = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={handleDelete} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaUserFriends size={20} color="#44546a" />
            <h1>Usuários</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>
        <Controls>
          {!isCreatingOrEditing ? (
            <>
              <button type="button" onClick={handleView}>
                <FaEye size={15} color="#44546a" />
                <span>Visualização</span>
              </button>
              {showTable ? (
                <button type="button" onClick={handleResetFilter}>
                  <FaEraser size={15} color="#44546a" />
                  <span>Limpar filtros</span>
                </button>
              ) : (
                <>
                  <button type="button" onClick={handleEdit}>
                    <FaEdit size={15} color="#44546a" />
                    <span>Editar</span>
                  </button>
                  <button type="button" onClick={confirmDelete}>
                    <FaTrash size={15} color="#44546a" />
                    <span>Excluir</span>
                  </button>
                  <div>
                    <button type="button" onClick={handlePrevItem}>
                      <FaChevronLeft size={15} color="#44546a" />
                    </button>
                    {totalUsers > 25 ? (
                      <span>{userIndex + 1} de 25</span>
                    ) : (
                      <span>
                        {userIndex + 1} de {totalUsers}
                      </span>
                    )}
                    <button type="button" onClick={handleNextItem}>
                      <FaChevronRight size={15} color="#44546a" />
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => formRef.current.submitForm()}
              >
                <FaSave size={15} color="#44546a" />
                <span>Salvar</span>
              </button>
              <button type="button">
                <FaBroom size={15} color="#44546a" />
                <span>Limpar</span>
              </button>
              <button type="button">
                <FaTimes size={15} color="#44546a" />
                <span>Fechar</span>
              </button>
            </>
          )}
        </Controls>
        <Filter ref={filterRef} onSubmit={handleFilter}>
          <Input
            name="name"
            className="name"
            label="Nome"
            defaultValue={nameSearch}
          />

          <Input
            name="email"
            className="email"
            label="E-mail"
            defaultValue={emailSearch}
          />

          <button type="submit">
            <FaSearch />
          </button>
        </Filter>
        {!isCreatingOrEditing ? (
          <>
            {loading ? (
              <TableLoading />
            ) : (
              <Content className="content">
                {showTable ? (
                  <TableContainer>
                    <thead>
                      <tr>
                        <th className="name">Nome</th>
                        <th className="email">E-mail</th>
                        <th className="nick">Nick</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((item, index) => (
                        <tr
                          key={item.id}
                          className="hover"
                          onClick={() => getDetails(item.id, index)}
                        >
                          <td className="name">{item.name || ''}</td>
                          <td className="model">{item.email || ''}</td>
                          <td className="nick">{item.nick || ''}</td>
                        </tr>
                      ))}
                    </tbody>
                  </TableContainer>
                ) : (
                  <DetailsContainer>
                    <UserInfo>
                      <h4>USUÁRIO</h4>
                      <section>
                        <div className="name">
                          <label>Nome</label>
                          <input
                            name="name"
                            value={userDetails.name || ''}
                            readOnly
                          />
                        </div>
                      </section>
                      <section>
                        <div className="email">
                          <label>E-mail</label>
                          <input
                            name="email"
                            value={userDetails.email || ''}
                            readOnly
                          />
                        </div>
                        <div className="nick">
                          <label>Nick</label>
                          <input
                            name="nick"
                            value={userDetails.nick || ''}
                            readOnly
                          />
                        </div>
                      </section>
                    </UserInfo>
                  </DetailsContainer>
                )}
              </Content>
            )}
          </>
        ) : (
          <>
            {loading && <FormLoading className="loading" />}
            {user !== null && (
              <Content className="content">
                <FormContainer
                  ref={formRef}
                  loading={loading ? 1 : 0}
                  onSubmit={handleSubmit}
                  initialData={user}
                >
                  <UserInfo>
                    <h4>USUÁRIO</h4>
                    <section>
                      <Input name="name" className="name" label="Nome" />
                    </section>
                    <section>
                      <Input name="email" className="email" label="E-mail" />
                      <Input name="nick" className="nick" label="Nick" />
                      <Input
                        name="password"
                        className="password"
                        label="Senha"
                      />
                    </section>
                  </UserInfo>
                </FormContainer>
              </Content>
            )}
          </>
        )}
        {!isCreatingOrEditing && (
          <Pagination
            loading={loading ? 1 : 0}
            currentPage={currentPage}
            pages={totalPages}
            totalDocs={totalUsers}
            handlePage={handlePage}
          />
        )}
      </Container>
      {(saveLoading || deleteLoading) && <Loading />}
    </Modal>
  );
};

export default Users;

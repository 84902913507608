import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: calc(100% - 20px);
  width: calc(100% - 75px);
  margin: 10px;
  margin-left: 65px;

  border: 1px solid ${({ theme }) => theme.colors.border};
  background: ${({ theme }) => theme.colors.white};
`;

export const Header = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: space-between;

  height: 40px;
  width: 100%;
  padding: 5px 10px;

  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  h1 {
    margin-left: 10px;
    font-size: 16px;
  }

  div {
    display: flex;
    align-items: center;
  }

  @media (max-width: 900px) {
    span {
      display: none;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow: auto;

  padding: 10px;
  width: 100%;
  height: calc(100% - 40px);

  @media screen and (max-width: 650px) {
    padding: 0px;
  }
`;

export const Document = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  width: 250px;
  height: max-content;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);

  button,
  a {
    text-align: center;
    background: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    padding: 8px;
    border-radius: 8px;

    transition: background 0.3s;

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
    }
  }

  > section > section {
    background: ${({ theme }) => theme.colors.greyBackground};
    width: 210px;
    text-align: right;
    height: 0px;

    strong {
      position: relative;
      bottom: 23px;
      right: 10px;
    }

    .downloaded {
      color: #00c100;
    }

    .not-downloaded {
      color: rgb(229, 57, 53);
    }
  }

  .document {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 210px;
    height: 210px;
    background: ${({ theme }) => theme.colors.greyBackground};
    color: ${({ theme }) => theme.colors.primary};

    img {
      height: 60px;
      width: 60px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${({ theme }) => theme.colors.primary};
    width: 100%;
    min-height: 155px;

    span,
    strong {
      font-size: 0.8rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
    }

    section {
      display: flex;
      justify-content: flex-start;
      min-height: 16px;
    }
  }

  @media (max-width: 650px) {
    width: 100%;
    height: max-content;
    padding: 20px;
    margin: 20px;

    &:last-child {
      margin-bottom: 50px;
    }

    > section {
      width: 100%;
    }

    .document {
      width: 100%;
      padding: 20px;
    }

    > section > section {
      width: 100%;
    }
  }
`;

export const Filter = styled(Form)`
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;

  > div + div {
    margin-left: 16px;
  }

  .client label {
    color: ${({ theme }) => theme.colors.blue};
    font-weight: bold;
  }

  .client,
  .document {
    width: 30%;
  }

  .period,
  .price {
    width: 20%;
    min-width: 215px;
  }

  .price > div > div {
    width: calc(50% - 20px);
  }

  .price input {
    text-align: right;
  }

  .period {
    border-left: 2px solid ${({ theme }) => theme.colors.border};
    padding-left: 15px;
  }

  > button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }

    span {
      color: #545454;
      font-size: 12px;
      opacity: 0.7;
    }
  }

  @media (max-width: 900px) {
    > div,
    > button {
      display: none;
    }
    .client {
      display: flex;
      width: 100%;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      height: 30px;
      background: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      text-align: center;
    }

    span {
      margin: 0 10px;
    }

    .react-datepicker-wrapper .react-datepicker__input-container input {
      width: 100%;
    }
  }
`;

import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: calc(100vw - 70px);
  height: calc(100vh - 60px);
  top: 60px;
  left: 70px;
  background: #fafafa;
  padding: 25px;
  border-radius: 4px;

  display: flex;
`;

export const File = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 400px);
  margin-right: 16px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  div {
    button {
      background: transparent;
    }
  }
`;

export const FileContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  strong {
    font-size: 28px;
    color: #01579b;
  }
`;

export const Content = styled.main`
  font-size: 16px;
  padding-left: 16px;
  border-left: 1px solid #c7c7c7;
  width: 400px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 3px solid transparent;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    box-shadow: inset 0 0 0 10px;
    height: 6px;
    width: 6px;
    color: #bbb;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  > span {
    display: flex;
    flex-direction: column;
  }

  span {
    margin-top: 8px;
    color: #666;
  }

  div {
    margin-top: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    section {
      width: 100%;
      display: flex;

      & + section {
        margin-top: 8px;
      }
    }

    button {
      padding: 8px;
      border-radius: 4px;
      color: #fff;
      transition: background 0.2s;
      flex: 1;

      & + button {
        margin-left: 16px;
      }
    }

    section:nth-child(1) {
      button:nth-child(1) {
        background: #00c853;
        padding-left: 20px;
        padding-right: 20px;

        &:hover {
          background: ${darken(0.05, '#00c853')};
        }
      }

      button:nth-child(2) {
        background: #ee4256;
        padding-left: 20px;
        padding-right: 20px;

        &:hover {
          background: ${darken(0.05, '#ee4256')};
        }
      }
    }

    section:nth-child(2) {
      width: 100%;
      display: flex;

      button {
        background: #01579b;

        &:hover {
          background: ${darken(0.05, '#01579B')};
        }
      }
    }
  }
`;

import styled from 'styled-components';
import { Form } from '@unform/web';

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  visibility: ${props => (props.loading ? 'hidden' : 'visible')};

  h4 {
    margin-bottom: 16px;
    color: #44546a;
  }

  section {
    margin-left: 16px;
    color: ${({ theme }) => theme.colors.grey};

    display: flex;
    align-items: flex-end;

    & + section {
      margin-top: 16px;
    }
  }
`;

export default FormContainer;

import styled from 'styled-components';

export const Container = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10;
`;

export const Content = styled.div`
  max-width: 600px;
  background: #fafafa;
  padding: 25px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;

  strong {
    margin-bottom: 16px;
  }
`;

export const Left = styled.aside`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const Right = styled.main`
  font-size: 16px;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  width: 100%;
  display: flex;
  flex-direction: column;

  > button {
    margin-top: 16px;
    width: 50%;
    align-self: center;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.white};
    padding: 5px;
    transition: background 0.2s;

    &:hover {
      background-color: ${({ theme }) => theme.hover.error};
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  position: relative;

  strong {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;

export const Problem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 8px;
  border-radius: 8px;
  border: 0;
  background-color: #eee;

  & + div {
    margin-top: 26px;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .documents {
    font-size: 15px;
  }

  .buttons {
    button {
      width: 35%;
      margin: 16px 30px;
      border: 0;
      text-align: center;
      opacity: 1;
      border-radius: 4px;
      color: ${({ theme }) => theme.colors.white};
      padding: 5px;
      transition: background 0.2s;

      &:first-child {
        background: ${({ theme }) => theme.colors.success};
      }

      &:nth-child(2) {
        background: ${({ theme }) => theme.colors.blue};
      }

      &:hover {
        &:first-child {
          background: ${({ theme }) => theme.hover.success};
        }

        &:nth-child(2) {
          background: ${({ theme }) => theme.hover.blue};
        }
      }
    }

    span {
      margin: 16px 0;
      width: 100%;
      color: ${({ theme }) => theme.colors.success};
      text-align: center;
    }
  }
`;

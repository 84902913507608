import React, { useState } from 'react';
import { FaLink } from 'react-icons/fa';

import { Body } from './styles';
import ProcessesLinks from '../Dropdown/ProcessesLinks';

const LinkProcess = () => {
  const [visible, setVisible] = useState(false);
  const [showProcessesLinks, setShowProcessesLinks] = useState(false);

  const handleProcessesLinks = () => {
    setShowProcessesLinks(!showProcessesLinks);
    if (visible === true) {
      setVisible(false);
    }
  };

  return (
    <>
      <Body onClick={() => handleProcessesLinks()}>
        <span>Links processos</span>
        <FaLink size={18} color="#fcfcfc" />
      </Body>
      {showProcessesLinks && (
        <ProcessesLinks
          isOpen={showProcessesLinks}
          setIsOpen={handleProcessesLinks}
        />
      )}
    </>
  );
};

export default LinkProcess;

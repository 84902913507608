import { createGlobalStyle } from 'styled-components';
import { darken } from 'polished';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
    -webkit-font-smoothing: antialiased;

    @media print {
      height: auto;
    }
  }

  body, button, input, textarea {
    font: 14px Roboto, sans-serif;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  body {
    background: rgba(209, 209, 240, 0.15);
  }

  #root {
    margin: 0 auto;
    overflow: hidden;
  }

  @media print {
    #root {
      overflow: inherit;
    }
  }

  a {
    text-decoration: none;
  }

  ul, li {
    list-style: none;
  }

  button {
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }

  input, select, textarea {
    border: 0;
    color: #545454;
  }

  .foo {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
  }

  .react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.65);
    .react-confirm-alert-body {
      border-radius: 4px;
      font-size: 16px;
      width: 400px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: none;
      h1 {
        margin-bottom: 10px;
        align-self: flex-start;
      }
      .react-confirm-alert-button-group {
        justify-content: space-between;
        width: 100%;
        button {
          height: 36px;
          width: 120px;
          font-size: 16px;
          width: 100%;
          transition: background 0.2s;
          &:nth-child(1) {
            border: 1px solid #094AB2;
            background: #FCFCFC;
            color: #094AB2;
            &:hover {
              background: #094AB2;
              color: #FCFCFC;
            }
          }
          &:nth-child(2) {
            background: #094AB2;
            color: #FCFCFC;
            &:hover {
              background: ${darken(0.03, '#094AB2')}
            }
          }
        }
      }
    }
  }

  .react-datepicker {
    .react-datepicker__navigation--previous {
      border-right-color: #444;
    }

    .react-datepicker__navigation--next {
      border-left-color: #444;
    }
  }

  .react-select__value-container {
    padding: 0px !important;
  }

  .react-select__menu {
    z-index: 3 !important;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    z-index: 3 !important;
  }

  .content {
    overflow: auto;
    color: #00000000;
    transition: color 0.3s;

    &:hover {
      color: #bbb;
    }
  }

  .content::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  .content::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border: 3px solid transparent;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    box-shadow: inset 0 0 0 10px;
    height: 6px;
    width: 6px;
  }

  .content::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  .content::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .react-confirm-alert-overlay {
    z-index: 9999;
  }

  .new-updates.show {
    display: flex !important;
  }

`;

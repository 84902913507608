import styled from 'styled-components';
import { Form } from '@unform/web';
import { darken } from 'polished';

export const Container = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10;
`;

export const Content = styled.div`
  width: 500px;
  height: 315px;
  background: #fafafa;
  padding: 25px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
`;

export const Left = styled.aside`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const Right = styled.main`
  font-size: 16px;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:last-child {
    display: flex;
    justify-content: space-between;

    > button {
      padding: 8px;
      border-radius: 4px;
      color: ${({ theme }) => theme.colors.white};
      transition: background 0.2s;
      padding-left: 20px;
      padding-right: 20px;
      width: 45%;

      &:nth-child(1) {
        background: #00c853;
        &:hover {
          background: ${darken(0.05, '#00c853')};
        }
      }

      &:nth-child(2) {
        background: #ee4256;
        &:hover {
          background: ${darken(0.05, '#ee4256')};
        }
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  position: relative;

  strong {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;

export const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;

  .select {
    margin-bottom: 16px;
  }
`;

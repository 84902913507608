import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Form } from '@unform/web';
import debounce from 'debounce-promise';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import * as Yup from 'yup';
import { FaPen, FaTimes, FaCopy } from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import removeAccents from '~/util/removeAccents';
import getValidationErrors from '~/util/getValidationErrors';

import ClearBackground from '~/components/ClearBackground';
import { Spinner } from '~/components/Spinner';
import { Input, InputMask, Select, AsyncSelect } from '~/components/Form';

import { Container, Title, Content, Result } from './styles';

const NameGenerator = ({ visible, handleNameGenerator }) => {
  const { company } = useAuth();

  const formRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [result, setResult] = useState(null);

  useEffect(() => {
    async function loadDocuments() {
      if (visible === true && company) {
        try {
          setLoading(true);

          const response = await api.get(
            `documents/name-generator/${company.id}`
          );

          if (response.data) {
            const options = response.data.map(doc => {
              const formattedDescription = removeAccents(
                doc.description.replace(/\s/g, '').replace('-', '')
              );

              return {
                value: `${doc.barcode}.${formattedDescription}`,
                label: doc.description,
              };
            });

            setDocuments(options);
          }

          setLoading(false);
        } catch {
          toast.error('Falha ao buscar documentos', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setLoading(false);
        }
      }
    }

    loadDocuments();
  }, [company, visible]);

  const currentMonthMinusOne = useMemo(() => {
    const date = new Date();

    const month = date.getMonth();

    return String(month).padStart(2, '0');
  }, []);

  const currentYear = useMemo(() => {
    const date = new Date();

    return date.getFullYear();
  }, []);

  const handleSubmit = useCallback(async data => {
    try {
      const schema = Yup.object().shape({
        client: Yup.string().required('O cliente é obrigatório.'),
        document: Yup.string().required('O documento é obrigatório.'),
        year: Yup.string().required('O ano é obrigatório.'),
        month: Yup.string().required('O mes é obrigatório.'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const clientSplit = data.client.split('/');
      const cnpj = clientSplit[0];
      const clientName = clientSplit[2];

      let formattedResult = `${data.year}.${data.month}.${cnpj}.${
        data.document.split('.')[0]
      }`;

      if (data.due_date) {
        formattedResult = `${formattedResult}.${data.due_date.replaceAll(
          '/',
          '-'
        )}`;
      }

      if (data.price) {
        if (data.price.indexOf('.') === -1) {
          data.price = `${data.price}00`;
        } else {
          data.price = data.price.replace('.', '');
        }
        formattedResult = `${formattedResult}.${data.price}`;
      }

      formattedResult = `${formattedResult}.${clientName.trim()}.${
        data.document.split('.')[1]
      }`;

      setResult(formattedResult);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current.setErrors(errors);
      } else {
        toast.error('Falha ao gerar nome.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, []);

  const getOptionsByName = debounce(
    useCallback(
      async value => {
        if (!value) {
          return Promise.resolve({ options: [] });
        }

        const { data } = await api.get('relationships', {
          params: {
            company_id: company?.id,
            selectOnly: true,
            selectOnlyType: 'name',
            search: value,
            type: 1,
          },
        });

        if (data.length > 0) {
          return data.map(client => {
            return {
              value: `${client.document}/${client.label}/${client.nick}`,
              label: client.label,
            };
          });
        }

        return [];
      },
      [company]
    ),
    500
  );

  return (
    <>
      <Container visible={visible}>
        <Title>
          <nav>
            <FaPen size="18" color="#01579b" />
            <strong>Gerador de nome</strong>
          </nav>

          <FaTimes
            size={18}
            color="#ee4256"
            onClick={() => {
              setResult(null);
              handleNameGenerator();
            }}
          />
        </Title>
        <Content>
          {loading || !company ? (
            <Spinner />
          ) : (
            <>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <section>
                  <AsyncSelect
                    name="client"
                    cacheOptions
                    label="Cliente"
                    className="client"
                    loadOptions={getOptionsByName}
                    placeholder="Selecione um cliente"
                    noOptionsMessage={() => 'Digite para pesquisar'}
                    loadingMessage={() => 'Carregando...'}
                  />
                </section>
                <section>
                  <Select
                    name="document"
                    className="document"
                    options={documents}
                    label="Documento"
                    placeholder="Selecione um documento"
                  />
                </section>
                <section>
                  <InputMask
                    name="month"
                    mask="99"
                    className="month"
                    label="Mês"
                    defaultValue={currentMonthMinusOne}
                  />
                  <InputMask
                    name="year"
                    className="year"
                    mask="9999"
                    label="Ano"
                    defaultValue={currentYear}
                  />
                </section>
                <section>
                  <InputMask
                    name="due_date"
                    mask="99/99/9999"
                    className="due_date"
                    label="Vencimento"
                  />
                  <Input name="price" className="price" label="Valor" />
                </section>
                <button type="submit">Gerar</button>
              </Form>
              {result && (
                <Result>
                  <span>{result}</span>
                  <button type="button" onClick={() => copy(result)}>
                    <FaCopy size="16" color="#01579b" />
                  </button>
                </Result>
              )}
            </>
          )}
        </Content>
      </Container>
      {visible && <ClearBackground style={{ marginLeft: '0px !important' }} />}
    </>
  );
};

export default NameGenerator;

import styled from 'styled-components';
import { Form } from '@unform/web';

export const FormContainer = styled(Form)`
  ::-webkit-scrollbar-thumb {
    background: #bbbbbb;
  }
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: calc(100% - 105px);
  overflow: auto;

  section {
    margin-left: 16px;

    display: flex;
    align-items: flex-end;

    & + section {
      margin-top: 16px;
    }

    > div {
      width: 33%;
    }

    > div + div {
      margin-left: 16px;
    }
  }

  h4 {
    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 16px;
  }

  section + h4 {
    margin-top: 20px;
  }

  .hide {
    display: none;
  }

  .date {
    input {
      width: 100%;
    }
  }

  @media (max-width: 650px) {
    section {
      flex-direction: column;

      > div {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    section + section {
      margin-top: 0;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 10px;
  height: 60px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.blue};

  button {
    background: transparent;
    color: ${({ theme }) => theme.colors.error};
    border: 0;
    height: 20px;
    margin: 0 20px 16px 0;

    svg {
      margin-left: 10px;
    }

    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }

  section + & {
    margin-top: 30px;
  }

  h4 {
    height: 20px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;

  background: ${({ theme }) => theme.colors.success};
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  transition: background 0.3s;

  &:hover {
    background: ${({ theme }) => theme.hover.success};
  }

  svg {
    margin-left: 10px;
  }

  &:disabled {
    cursor: no-drop;
  }
`;

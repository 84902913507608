import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Loading from '~/components/Loading';
import Modal from '~/components/Modal';
import api from '~/services/api';

import { Header, Content, Icon } from './styles';

const IconsOptionsModal = ({ onClick, isOpen, setIsOpen }) => {
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    async function loadIcons() {
      try {
        const response = await api.get('/process-icons');

        setIcons(response.data);
      } catch {
        toast.error('Falha ao buscar ícones.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    loadIcons();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      widthProp={700}
      heightProp={538}
    >
      <Header>
        <div>
          <h1>Selecione um ícone</h1>
        </div>
        <aside>
          <button type="button" onClick={setIsOpen}>
            <FaTimes size={20} color="#44546a" />
          </button>
        </aside>
      </Header>
      {icons ? (
        <Content className="content">
          {icons.map(icon => (
            <Icon onClick={() => onClick(icon.name, icon.id)} key={icon.id}>
              <FontAwesomeIcon icon={icon.name} color="#44546a" />
            </Icon>
          ))}
        </Content>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};

export default IconsOptionsModal;

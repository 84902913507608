import React, { useEffect, useState } from 'react';
import { FaRegStar, FaStar, FaStarHalfAlt, FaTimes } from 'react-icons/fa';
import Modal from '~/components/Modal';
import { TableContainer } from '~/components/Table';

import { Resume, Header, Content } from './styles';

const ResumeModal = ({ isOpen, setIsOpen, data, type }) => {
  const [stars, setStars] = useState([]);

  useEffect(() => {
    if (type === 1) {
      const starsAux = [];

      for (let aux = 0; aux < 5; aux += 1) {
        if (data.average <= aux) {
          starsAux.push(0);
        } else if (data.average <= aux + 0.5) {
          starsAux.push(1);
        } else {
          starsAux.push(2);
        }
      }

      setStars(starsAux);
    }
  }, [data, type]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      widthProp={600}
      heightProp={600}
    >
      <Header>
        <div>
          <h1>Resumo</h1>
        </div>
        <aside>
          <button type="button" onClick={setIsOpen}>
            <FaTimes size={20} color="#44546a" />
          </button>
        </aside>
      </Header>
      <Content>
        {type === 0 ? (
          <TableContainer>
            <thead>
              <tr>
                <th className="user">Usuário</th>
                <th className="note">Aproveitamento</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover">
                  <td className="user">{item.userName}</td>
                  <td className="note">{item.percent.toFixed(2)}%</td>
                </tr>
              ))}
            </tbody>
          </TableContainer>
        ) : (
          <Resume>
            <h1>KPI - {data.userName}</h1>
            <span>
              <span>Quant. KPIs:</span> <strong>{data.count}</strong>
            </span>
            <span>
              <span>Expectativa:</span> <strong>{data.count * 5}</strong>
            </span>
            <span>
              <span>Pontuação:</span> <strong>{data.note || 0}</strong>
            </span>
            <span>
              <span>Expectativa Agenda:</span> <strong>{5}</strong>
            </span>
            <span>
              <span>Pontuação Agenda:</span>{' '}
              <strong>{data.noteSchedule || 0}</strong>
            </span>
            <div />
            <span>
              Aproveitamento: <span>{(data.percent || 0).toFixed(2)}%</span>
            </span>
            <span>
              {stars.map((star, index) => {
                if (star === 0) {
                  return <FaRegStar key={index} color="#f4c306" />;
                }
                if (star === 1) {
                  return <FaStarHalfAlt key={index} color="#f4c306" />;
                }
                return <FaStar key={index} color="#f4c306" />;
              })}
            </span>
          </Resume>
        )}
      </Content>
    </Modal>
  );
};

export default ResumeModal;

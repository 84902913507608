import React, { useCallback, useEffect, useRef, useState } from 'react';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaSave,
  FaTimes,
  FaLayerGroup,
  FaFolderPlus,
  FaPrint,
} from 'react-icons/fa';

import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import {
  FormContainer,
  FormLoading,
  Input,
  Select,
  DatePicker,
  Checkbox,
} from '~/components/Form';

import { Container, Header, Controls, Content, ProtocolInfo } from './styles';
import Modal from '~/components/Modal';
import { TableContainer } from '~/components/Table';

const Form = ({ isOpen, setIsOpen, setIsForm, id }) => {
  const { user, company, companyUsers } = useAuth();

  const formRef = useRef();

  const [protocolLoading, setProtocolLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [protocol, setProtocol] = useState({});

  const [clientsOptions, setClientsOptions] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const protocolsToActive = useRef([]);
  const protocolsToInactive = useRef([]);

  const [protocolsOfGroup, setProtocolsOfGroup] = useState([]);
  const [protocolOfGroupLoading, setProtocolOfGroupLoading] = useState(false);

  const situationOptions = [
    { value: 0, label: 'Gerado' },
    { value: 3, label: 'Impresso' },
    { value: 4, label: 'Impresso/Enviado' },
  ];

  const [usersOptions, setUsersOptions] = useState([]);
  useEffect(() => {
    if (companyUsers && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
      });

      setUsersOptions(options);
    }
  }, [companyUsers, user]);

  useEffect(() => {
    async function loadClients() {
      try {
        const response = await api.get(`/relationships`, {
          params: {
            company_id: company.id,
            selectOnly: true,
            type: 1,
          },
        });

        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          const options = response.data.map(item => ({
            value: item.id,
            label: item.name,
            preference: item.protocol_preference,
          }));

          setClientsOptions(options);
        } else {
          toast.warn('Nenhum cliente foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setClientsOptions([]);
        }
      } catch {
        toast.error('Falha ao buscar clientes.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    async function loadProtocol() {
      if (id) {
        try {
          setProtocolLoading(true);

          const response = await api.get(`/protocols/group/${id}`);
          const { data } = response;

          data.start_date = parseISO(data.created_at);

          setSelectedClient(data.client_id);
          setProtocol(data);

          setProtocolLoading(false);
        } catch {
          toast.error('Falha ao buscar protocolo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setProtocolLoading(false);
        }
      } else {
        setProtocol({
          start_date: new Date(),
          situation: 0,
          client: null,
          obs: null,
        });
        setProtocolLoading(false);
      }
    }

    if (!user || !company) return;

    loadClients();
    loadProtocol();
  }, [id, user, company]);

  useEffect(() => {
    async function loadProtocolsOfGroup() {
      try {
        setProtocolOfGroupLoading(true);

        protocolsToActive.current = [];
        protocolsToInactive.current = [];

        const response = await api.get(
          `/protocols/group/protocols/${selectedClient}`,
          {
            params: {
              protocol_id: id,
            },
          }
        );

        if (response.data.length > 0) {
          setProtocolsOfGroup(response.data);

          if (!id) {
            protocolsToActive.current = response.data.map(item => item.id);
          }
        } else {
          setProtocolsOfGroup([]);
          toast.warn('Nenhum protocolo para o grupo encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch {
        toast.error('Falha ao buscar protocolo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setProtocolOfGroupLoading(false);
      }
    }

    if (selectedClient) {
      loadProtocolsOfGroup();
    }
  }, [selectedClient, id]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);

        if (!id && protocolsToActive.current.length === 0) {
          toast.error('É preciso ao menos um protocol selecionado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          const protocolData = {
            user_id: user.id,
            company_id: company.id,
            client_id: data.client_id,
            protocolsToActive: protocolsToActive.current,
            protocolsToInactive: protocolsToInactive.current,
          };

          if (id) {
            await api.put(`/protocols/group/${id}`, protocolData);
          } else {
            await api.post(`/protocols/group`, protocolData);
          }

          toast.success('Protocolo salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setIsForm();
        }
      } catch {
        toast.error('Falha ao salvar protocolo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    },
    [company, id, user, setIsForm]
  );

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={setIsForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [setIsForm]);

  const handleSelectClient = useCallback(
    selected => {
      const { value } = selected;

      if (value !== selectedClient) {
        setSelectedClient(value);
      }
    },
    [selectedClient]
  );

  const handleChangeActive = useCallback(
    (protocolId, checked) => {
      const protocolOfgroup = protocolsOfGroup.find(
        item => item.id === protocolId
      );

      if (!protocolsOfGroup) return;

      if (checked && protocolOfgroup.group_id) {
        const index = protocolsToInactive.current.indexOf(protocolId);

        if (index !== -1) {
          protocolsToInactive.current.splice(index, 1);
        }
      } else if (checked && !protocolOfgroup.group_id) {
        protocolsToActive.current.push(protocolId);
      } else if (!checked && protocolOfgroup.group_id) {
        protocolsToInactive.current.push(protocolId);
      } else {
        const index = protocolsToActive.current.indexOf(protocolId);

        if (index !== -1) {
          protocolsToActive.current.splice(index, 1);
        }
      }
    },
    [protocolsOfGroup]
  );

  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <Container>
          <Header>
            <div>
              <FaLayerGroup size={20} color="#44546a" />
              <h1>Protocolos em grupo</h1>
            </div>
          </Header>

          <Controls>
            <button type="button" onClick={() => formRef.current.submitForm()}>
              <FaSave size={15} color="#44546a" />
              <span>Salvar</span>
            </button>
            <button type="button" onClick={handleClose}>
              <FaTimes size={15} color="#44546a" />
              <span>Fechar</span>
            </button>
          </Controls>
          {protocolLoading || !clientsOptions ? (
            <FormLoading />
          ) : (
            <Content className="content">
              {protocol && (
                <FormContainer
                  ref={formRef}
                  loading={loading ? 1 : 0}
                  onSubmit={handleSubmit}
                  style={{ height: '100%' }}
                >
                  <ProtocolInfo>
                    <h4>PROTOCOLO</h4>
                    <section>
                      <Input
                        name="cod"
                        className="cod"
                        label="Cód."
                        type="text"
                        value={protocol.cod}
                        placeholder="(Automático)"
                        disabled
                      />
                      <DatePicker
                        name="start_date"
                        selected={protocol.start_date}
                        className="date"
                        label="Data"
                        readOnly
                      />
                      {id ? (
                        <Input
                          name="client_id"
                          className="client"
                          label="Cliente"
                          type="text"
                          value={protocol.client?.name}
                          disabled
                        />
                      ) : (
                        <Select
                          name="client_id"
                          className="client"
                          label="Grupo"
                          type="text"
                          defaultValue={clientsOptions.find(
                            option => option.value === protocol.client_id
                          )}
                          options={clientsOptions}
                          onChange={handleSelectClient}
                          placeholder="Selecione um grupo"
                        />
                      )}
                      <Input
                        name="situation"
                        className="type"
                        label="Situação"
                        type="text"
                        value={
                          situationOptions.find(
                            option => option.value === protocol.situation
                          )?.label || ''
                        }
                        disabled
                      />
                    </section>

                    {protocolOfGroupLoading ? (
                      <FormLoading />
                    ) : (
                      <>
                        <TableContainer>
                          <thead>
                            <tr>
                              <th className="active">Ativo</th>
                              <th className="cod">Cód.</th>
                              <th className="client">Cliente</th>
                              <th className="situation">Situação</th>
                              <th className="user">Gerado por</th>
                            </tr>
                          </thead>
                          <tbody>
                            {protocolsOfGroup.map((item, index) => (
                              <tr key={index} className="hover">
                                <td className="active">
                                  {id ? (
                                    <Checkbox
                                      name="active"
                                      defaultChecked={!!item.group_id}
                                      onChange={e =>
                                        handleChangeActive(
                                          item.id,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  ) : (
                                    <Checkbox
                                      name="active"
                                      defaultChecked
                                      onChange={e =>
                                        handleChangeActive(
                                          item.id,
                                          e.target.checked
                                        )
                                      }
                                    />
                                  )}
                                </td>
                                <td className="cod">{item.cod || ''}</td>
                                <td className="client">
                                  {item.client?.name || ''}
                                </td>
                                <td className="situation">
                                  {item.situation === 0 && (
                                    <FaFolderPlus color="#01579B" />
                                  )}
                                  {item.situation === 3 && (
                                    <FaPrint color="#44546a" />
                                  )}
                                  {item.situation === 4 && (
                                    <FaPrint color="#44546a" />
                                  )}
                                  <span style={{ marginLeft: '5px' }}>
                                    {situationOptions.find(
                                      situation =>
                                        situation.value === item.situation
                                    )?.label || ''}
                                  </span>
                                </td>
                                <td className="user">
                                  {usersOptions.find(
                                    option => option.value === item.user_id
                                  )?.label || ''}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </TableContainer>

                        {protocolsOfGroup.length === 0 && (
                          <div className="empty">
                            Nenhum protocol relacionado encontrado
                          </div>
                        )}
                      </>
                    )}
                  </ProtocolInfo>
                </FormContainer>
              )}
            </Content>
          )}
        </Container>
        {loading && <Loading />}
      </Modal>
    </>
  );
};

export default Form;

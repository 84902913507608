import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border};
`;

export const Header = styled.header`
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const Type = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  height: 4rem;
  width: 20rem;
  margin: 20px 10px;

  cursor: pointer;

  font-size: 1rem;
  opacity: 1;
  border-radius: 5px;

  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }

  background: rgba(230, 230, 230);
  color: #000;

  border: 0;
  border-bottom: 3px solid ${({ theme }) => theme.colors.blue};

  svg {
    margin: 0 1rem;
  }

  span {
    justify-content: center;
    width: 17rem;
  }
`;

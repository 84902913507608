import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: ${props => (props.visible ? '10' : '8')};
`;

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  margin-left: 16px;

  label {
    position: absolute;
    right: 3px;
    top: 0;
    z-index: 1;
    font-size: 7px;
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover span {
    visibility: visible;
    top: 180%;
    opacity: 1;
  }

  span {
    visibility: hidden;
    width: 120px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 10002;
    top: 150%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.8s;
  }

  span:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${({ theme }) => theme.colors.primary}
      transparent;
  }
`;

const animation = keyframes`
  from {
    visibility: hidden;
    clip-path: inset(0px 0px 100% 100% round 5px);
  }
  to {
    visibility: visible;
    clip-path: inset(0px 0px 0px 0px round 5px);
  }
`;

export const Content = styled.div`
  visibility: hidden;
  position: absolute;
  top: 40px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  width: 170px;
  right: 0px;

  ${props =>
    props.visible &&
    css`
      animation: ${animation} 0.75s forwards cubic-bezier(0.25, 1, 0.25, 1);
    `}
`;

export const Title = styled.header`
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 8px;
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  svg:nth-child(2):hover {
    cursor: pointer;
  }
`;

export const AppsList = styled.ul`
  > svg {
    position: absolute;
    right: 5px;
    top: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  li {
    strong {
      font-weight: 400;
    }

    &:hover {
      cursor: pointer;

      a,
      button,
      svg {
        background-color: ${({ theme }) => theme.hover.lightBorder} !important;
      }
    }

    & + li {
      border-top: 1px solid ${({ theme }) => theme.colors.border};
    }

    a,
    button {
      padding: 10px;
      color: ${({ theme }) => theme.colors.primary};
      background: none;

      display: flex;
      align-items: center;
      margin-left: 0 !important;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary} !important;
      margin-right: 8px;
      transition: color 0.25s;
    }
  }
`;

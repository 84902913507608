import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    > div {
      & + div {
        margin-left: 16px;
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }

    a {
      margin-left: 10px;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  form {
    height: 100%;
  }
`;

export const PopInfo = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;

  height: 100%;

  section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .dpt-error {
    color: ${({ theme }) => theme.colors.error};
    font-size: 12px;
    font-weight: bold;
    margin-left: 16px;
    height: 16px;
  }

  .sector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title,
  .docs_links,
  .key-words {
    width: 50%;
  }

  .checkbox {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    min-width: 6%;

    > input {
      margin-right: 16px;
      transform: scale(1.5);
    }
  }

  .generate-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > strong {
      color: ${({ theme }) => theme.colors.blue};
      margin-bottom: 8px;
    }
  }
`;

export const GenerateLink = styled.div`
  display: flex;
  flex-direction: row;
`;

export const File = styled.div`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.blue};
    color: #fff;
    border-radius: 8px;
    height: 30px;
    padding: 0 16px;
    transition: background 0.2s;
    opacity: 1;

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
      cursor: pointer;
    }

    svg {
      margin-right: 8px;
    }
  }
`;

export const FileLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  height: 30px;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: 8px;
  margin-left: 16px;

  > div {
    background: #fff;
    color: ${({ theme }) => theme.hover.primary};
    width: 270px;
    padding: 0 8px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  button {
    background: ${({ theme }) => theme.colors.blue};
    width: 30px;
    height: 30px;
    border-radius: 0 8px 8px 0;

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
      cursor: pointer;
    }
  }
`;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import {
  FaSlidersH,
  FaTimes,
  FaSearch,
  FaEye,
  FaPlus,
  FaEraser,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import { Input } from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  ParameterInfo,
  Responsibles,
  Documents,
} from './styles';

const List = () => {
  const { company } = useAuth();

  const filterRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const [parameters, setParameters] = useState([]);
  const [parameter, setParameter] = useState({});

  const [clientSearch, setClientSearch] = useState(() => {
    const clienteStorage = sessionStorage.getItem(
      '@Diretiva:labour-parameter:filter:client'
    );

    if (clienteStorage) {
      return clienteStorage;
    }

    return '';
  });

  const [totalParameters, setTotalParameters] = useState(0);
  const [parameterIndex, setParameterIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(() => {
    const currentPageStorage = sessionStorage.getItem(
      '@Diretiva:labour-parameter:page'
    );

    if (currentPageStorage) {
      return Number(currentPageStorage);
    }

    return 1;
  });
  const [totalPages, setTotalPages] = useState(1);

  const loadParameters = useCallback(async () => {
    if (company) {
      try {
        setLoading(true);

        const response = await api.get('checklists-parameters', {
          params: {
            company_id: company.id,
            page: currentPage,
            client: clientSearch,
            department: 'dpt_labour',
          },
        });

        if (response.data.docs.length > 0) {
          const data = response.data.docs.map(parameterItem => {
            return {
              id: parameterItem.id,
              client: parameterItem.client,
              model_id: parameterItem.model_id,
              model: parameterItem.model,
              start_period: parameterItem.start_period
                ? format(parseISO(parameterItem.start_period), 'dd/MM/yyyy')
                : null,
              end_period: parameterItem.end_period
                ? format(parseISO(parameterItem.end_period), 'dd/MM/yyyy')
                : null,
              responsible: parameterItem.responsible.map(responsible => ({
                id: responsible.id,
                responsible_id: responsible.company_user
                  ? responsible.company_user.id
                  : null,
                name: responsible.company_user
                  ? responsible.company_user.user.name
                  : 'Não se aplica',
                start_period: responsible.start_period
                  ? format(parseISO(responsible.start_period), 'dd/MM/yyyy')
                  : '',
                end_period: responsible.end_period
                  ? format(parseISO(responsible.end_period), 'dd/MM/yyyy')
                  : '',
              })),
              active: parameterItem.active,
              obs: parameterItem.obs,
              actives_documents: parameterItem.parameter_document
                .filter(parameterDoc => parameterDoc.situation !== false)
                .map(parameterDoc => ({
                  ...parameterDoc,
                  start_period: parameterDoc.start_period
                    ? format(parseISO(parameterDoc.start_period), 'dd/MM/yyyy')
                    : '',
                  end_period: parameterDoc.end_period
                    ? format(parseISO(parameterDoc.end_period), 'dd/MM/yyyy')
                    : '',
                  model_document: {
                    id: parameterDoc.model_document.id,
                    document_order: parameterDoc.model_document.document_order,
                    company_document_id:
                      parameterDoc.model_document.company_document.id,
                    document_id:
                      parameterDoc.model_document.company_document.document.id,
                    document_name:
                      parameterDoc.model_document.company_document.document
                        .description,
                  },
                }))
                .sort((a, b) => {
                  if (
                    a.model_document.document_name <
                    b.model_document.document_name
                  ) {
                    return -1;
                  }
                  if (
                    a.model_document.document_name >
                    b.model_document.document_name
                  ) {
                    return 1;
                  }
                  return 0;
                }),
              inactives_documents: parameterItem.parameter_document
                .filter(parameterDoc => parameterDoc.situation !== true)
                .map(parameterDoc => ({
                  ...parameterDoc,
                  start_period: parameterDoc.start_period
                    ? format(parseISO(parameterDoc.start_period), 'dd/MM/yyyy')
                    : '',
                  end_period: parameterDoc.end_period
                    ? format(parseISO(parameterDoc.end_period), 'dd/MM/yyyy')
                    : '',
                  model_document: {
                    id: parameterDoc.model_document.id,
                    document_order: parameterDoc.model_document.document_order,
                    company_document_id:
                      parameterDoc.model_document.company_document.id,
                    document_id:
                      parameterDoc.model_document.company_document.document.id,
                    document_name:
                      parameterDoc.model_document.company_document.document
                        .description,
                  },
                }))
                .sort((a, b) => {
                  if (
                    a.model_document.document_name <
                    b.model_document.document_name
                  ) {
                    return -1;
                  }
                  if (
                    a.model_document.document_name >
                    b.model_document.document_name
                  ) {
                    return 1;
                  }
                  return 0;
                }),
            };
          });

          setParameters(data);
          setParameter(data[0]);
          setTotalPages(response.data.pages);
          setTotalParameters(response.data.total);
        } else {
          setParameters([]);
          setParameter([]);
          setTotalPages(1);
          setTotalParameters(0);
          toast.warn('Nenhum parâmetro foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setLoading(false);
      } catch (err) {
        toast.error('Falha ao buscar parâmetros.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    }
  }, [company, currentPage, clientSearch]);

  useEffect(() => {
    loadParameters();
  }, [loadParameters]);

  const handleFilter = useCallback(({ client }) => {
    setClientSearch(client);
    sessionStorage.setItem('@Diretiva:labour-parameter:filter:client', client);

    setCurrentPage(1);
    sessionStorage.setItem('@Diretiva:labour-parameter:page', 1);
  }, []);

  const resetFilter = useCallback(() => {
    filterRef.current.reset();

    setCurrentPage(1);
    sessionStorage.setItem('@Diretiva:labour-parameter:page', 1);

    setClientSearch('');
    sessionStorage.removeItem('@Diretiva:labour-parameter:filter:client');
  }, []);

  const alterView = useCallback(() => {
    if (parameters.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, parameters]);

  const getDetails = useCallback(
    (id, index) => {
      const parameterItem = parameters.find(item => item.id === id);

      setParameter(parameterItem);
      setParameterIndex(index);
      alterView();
    },
    [parameters, alterView]
  );

  const handlePrevItem = useCallback(() => {
    if (parameterIndex !== 0) {
      setParameterIndex(parameterIndex - 1);
      setParameter(parameters[parameterIndex - 1]);
    } else {
      setParameterIndex(parameters.length - 1);
      setParameter(parameters[parameters.length - 1]);
    }
  }, [parameterIndex, parameters]);

  const handleNextItem = useCallback(() => {
    if (parameterIndex !== parameters.length - 1) {
      setParameterIndex(parameterIndex + 1);
      setParameter(parameters[parameterIndex + 1]);
    } else {
      setParameterIndex(0);
      setParameter(parameters[0]);
    }
  }, [parameterIndex, parameters]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
        sessionStorage.setItem('@Diretiva:labour-parameter:page', 1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
        sessionStorage.setItem('@Diretiva:labour-parameter:page', totalPages);
      } else {
        setCurrentPage(page);
        sessionStorage.setItem('@Diretiva:labour-parameter:page', page);
      }
    },
    [totalPages]
  );

  const handleDelete = useCallback(
    async id => {
      try {
        setDeleteLoading(true);

        await api.delete(`checklists-parameters/${id}`);

        alterView();

        loadParameters();

        toast.success('Parâmetro deletado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      } catch (err) {
        toast.error('Falha ao deletar parâmetro.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      }
    },
    [alterView, loadParameters]
  );

  const confirmDelete = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => handleDelete(parameter.id)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete, parameter.id]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaSlidersH size={20} color="#44546a" />
            <h1>Parâmetros Trabalhistas</h1>
          </div>

          <Link to="/">
            <FaTimes size={20} color="#44546a" />
          </Link>
        </Header>
        <Controls>
          <button type="button" onClick={alterView}>
            <FaEye />
            <span>Visualização</span>
          </button>

          <Link to={{ pathname: '/labour-parameter/new', state: { id: null } }}>
            <FaPlus />
            <span>Novo</span>
          </Link>

          {showTable ? (
            <button type="button" onClick={resetFilter}>
              <FaEraser />
              <span>Limpar filtros</span>
            </button>
          ) : (
            <>
              <Link
                to={{
                  pathname: '/labour-parameter/edit',
                  state: { id: parameter.id },
                }}
              >
                <FaEdit />
                <span>Editar</span>
              </Link>
              <button type="button" onClick={confirmDelete}>
                <FaTrash />
                <span>Excluir</span>
              </button>
              <div>
                <button type="button" onClick={handlePrevItem}>
                  <FaChevronLeft />
                </button>
                {totalParameters > 25 ? (
                  <span>{parameterIndex + 1} de 25</span>
                ) : (
                  <span>
                    {parameterIndex + 1} de {totalParameters}
                  </span>
                )}
                <button type="button" onClick={handleNextItem}>
                  <FaChevronRight />
                </button>
              </div>
            </>
          )}
        </Controls>
        {showTable && (
          <Filter ref={filterRef} onSubmit={handleFilter}>
            <Input
              name="client"
              className="client"
              label="Empresa"
              defaultValue={clientSearch}
            />

            <button type="submit">
              <FaSearch />
            </button>
          </Filter>
        )}
        {loading || !company ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {showTable ? (
              <TableContainer>
                <thead>
                  <tr>
                    <th className="client">Empresa</th>
                    <th className="model">Modelo</th>
                    <th className="start_period">Início</th>
                  </tr>
                </thead>
                <tbody>
                  {parameters.map((item, index) => (
                    <tr
                      key={item.id}
                      className="hover"
                      onClick={() => getDetails(item.id, index)}
                    >
                      <td className="client">{item.client.name}</td>
                      <td className="model">{item.model.name}</td>
                      <td className="start_period">{item.start_period}</td>
                    </tr>
                  ))}
                </tbody>
              </TableContainer>
            ) : (
              <DetailsContainer>
                <ParameterInfo>
                  <h4>PARÂMETRO</h4>
                  <section>
                    <div className="client">
                      <label>Empresa</label>
                      <input
                        name="client"
                        value={parameter.client.name}
                        readOnly
                      />
                    </div>
                    <div className="model">
                      <label>Modelo</label>
                      <input
                        name="model"
                        value={parameter.model.name}
                        readOnly
                      />
                    </div>
                    <div className="start_period">
                      <label>Início</label>
                      <input
                        name="start_period"
                        value={parameter.start_period || ''}
                        readOnly
                      />
                    </div>
                    <div className="end_period">
                      <label>Fim</label>
                      <input
                        name="end_period"
                        value={parameter.end_period || ''}
                        readOnly
                      />
                    </div>
                  </section>
                  <section>
                    <div className="obs">
                      <label>Orientações</label>
                      <input name="obs" value={parameter.obs || ''} readOnly />
                    </div>
                  </section>
                </ParameterInfo>
                <Responsibles>
                  <h4>RESPONSÁVEIS</h4>
                  {parameter &&
                    parameter.responsible.map(responsible => (
                      <section key={responsible.id}>
                        <div className="responsible">
                          <label>Responsável</label>
                          <input
                            name="responsible"
                            value={responsible.name}
                            readOnly
                          />
                        </div>
                        <div className="start_period">
                          <label>Início</label>
                          <input
                            name="start_period"
                            value={responsible.start_period}
                            readOnly
                          />
                        </div>
                        <div className="end_period">
                          <label>Fim</label>
                          <input
                            name="end_period"
                            value={responsible.end_period}
                            readOnly
                          />
                        </div>
                      </section>
                    ))}
                </Responsibles>
                <Documents>
                  <h4>DOCUMENTOS ATIVOS</h4>
                  {parameter &&
                    parameter.actives_documents.length > 0 &&
                    parameter.actives_documents.map(document => (
                      <React.Fragment key={`${document.id}`}>
                        <section>
                          <div className="document">
                            <label>Documento</label>
                            <input
                              name="document"
                              value={document.model_document.document_name}
                              readOnly
                            />
                          </div>
                          <div className="obs">
                            <label>Obs</label>
                            <input
                              name="obs"
                              value={document.obs || ''}
                              readOnly
                            />
                          </div>
                        </section>
                      </React.Fragment>
                    ))}
                </Documents>
                <Documents>
                  <h4>DOCUMENTOS INATIVOS</h4>
                  {parameter &&
                    parameter.inactives_documents.length > 0 &&
                    parameter.inactives_documents.map(document => (
                      <React.Fragment key={document.id}>
                        <section>
                          <div className="document">
                            <label>Documento</label>
                            <input
                              name="document"
                              value={document.model_document.document_name}
                              readOnly
                            />
                          </div>
                          <div className="obs">
                            <label>Obs</label>
                            <input
                              name="obs"
                              value={document.obs || ''}
                              readOnly
                            />
                          </div>
                        </section>
                      </React.Fragment>
                    ))}
                </Documents>
              </DetailsContainer>
            )}
          </Content>
        )}
        <Pagination
          loading={loading ? 1 : 0}
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalParameters}
          handlePage={handlePage}
        />
      </Container>
      {deleteLoading && <Loading />}
    </>
  );
};

export default List;

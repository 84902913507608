import React, { useState, useCallback } from 'react';
import {
  FaHandPointUp,
  FaGlobe,
  FaBars,
  FaTimes,
  FaGlobeAmericas,
} from 'react-icons/fa';
import base64 from 'base-64';

import { useAuth } from '~/hooks';

import ClearBackground from '~/components/ClearBackground';

import { Container, Badge, Content, Title, AppsList } from './styles';

const Dropdown = () => {
  const { company } = useAuth();

  const [visible, setVisible] = useState(false);
  const handleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  return (
    <>
      <Container visible={visible}>
        <Badge onClick={handleVisible}>
          <FaBars size={18} color="#fcfcfc" />
          <span>Ferramentas</span>
        </Badge>

        <Content visible={visible}>
          <Title>
            <div>
              <FaBars size={18} color="#01579b" />
              <h3>Tools</h3>
            </div>
            <FaTimes size={18} color="#ee4256" onClick={handleVisible} />
          </Title>

          {company && (
            <AppsList>
              <li>
                {company?.nick && (
                  <a
                    href={`http://doc.diretiva1.com.br/${company.nick}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleVisible}
                  >
                    <FaGlobe size="18" />
                    <strong>Abrir portal</strong>
                  </a>
                )}
              </li>
              <li>
                {company?.nick && (
                  <a
                    href={`https://app.diretiva1.com.br/portal/signin/${base64.encode(
                      JSON.stringify({ companyId: company.id })
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleVisible}
                  >
                    <FaGlobeAmericas size="18" />
                    <strong>Abrir novo portal</strong>
                  </a>
                )}
              </li>
              <li>
                {company?.hasPoint && (
                  <a
                    href={`http://logineletronico.diretiva1.com.br/${company.nick}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleVisible}
                  >
                    <FaHandPointUp size="18" />
                    <strong>Login eletrônico</strong>
                  </a>
                )}
              </li>
            </AppsList>
          )}
        </Content>
      </Container>
      {visible && (
        <ClearBackground
          onClick={handleVisible}
          style={{ marginLeft: '0px !important' }}
        />
      )}
    </>
  );
};

export default Dropdown;

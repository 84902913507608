import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { parseISO, subDays } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';

import * as Yup from 'yup';
import produce from 'immer';
import { Scope } from '@unform/core';

import {
  FaSave,
  FaBroom,
  FaTimes,
  FaPaperclip,
  FaTrash,
  FaCalendar,
} from 'react-icons/fa';

import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';
import {
  FormContainer,
  FormLoading,
  Input,
  FileInput,
  Select,
  Checkbox,
  DatePicker,
} from '~/components/Form';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import {
  Container,
  Header,
  Controls,
  Content,
  ProcessTypeInfo,
  Title,
  File,
  UploadFile,
  FileName,
  Delete,
} from './styles';

const Form = () => {
  const { user, company, companyUsers } = useAuth();

  const { state } = useLocation();

  const formRef = useRef();

  const releasedDocuments = useRef([]);

  const id = state?.id || null;
  const model_id = state?.model_id || null;

  const [formLoading, setFormLoading] = useState(true);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [optionsClientLoading, setOptionsClientLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [uploadFiles, setUploadFiles] = useState([]);

  const [initialData, setInitialData] = useState();

  const client = useRef(null);

  const [modelsOptions, setModelsOptions] = useState([]);
  const [clientsOptions, setClientsOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [responsibleOptions, setResponsibleOptions] = useState(null);

  useEffect(() => {
    if (companyUsers && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
            active: userItem.active,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
        active: true,
      });

      options.push({
        value: null,
        label: 'Nenhum',
        active: true,
      });

      const [...rest] = options;
      const optionsForResponsible = [...rest];
      optionsForResponsible.pop();

      setResponsibleOptions(optionsForResponsible);
      setUsersOptions(options);
    }
  }, [companyUsers, user]);

  useEffect(() => {
    async function loadModels() {
      if (user && company) {
        try {
          setOptionsLoading(true);
          const response = await api.get(`/process-models/simple/`, {
            params: {
              company_id: company.id,
              model_type: 2,
            },
          });

          if (response.data.length > 0) {
            setModelsOptions(
              response.data.map(item => ({
                value: item.id,
                label: item.title,
              }))
            );
          } else {
            toast.warn('Nenhum modelo foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            history.push({
              pathname: '/expiration-control',
              state: { model_id },
            });
          }
          setOptionsLoading(false);
        } catch {
          toast.error('Falha ao buscar modelos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push({
            pathname: '/expiration-control',
            state: { model_id },
          });
        }
      }
    }

    loadModels();
  }, [company, user, model_id]);

  const loadClients = useCallback(
    async modelId => {
      if (user && company) {
        try {
          setOptionsClientLoading(true);
          const response = await api.get(`/relationships/clients/processes`, {
            params: {
              company_id: company.id,
              processModel_id: modelId || model_id,
            },
          });

          if (response.data.length > 0) {
            response.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });

            setClientsOptions(
              response.data.map(item => ({
                value: item.id,
                label: item.name,
              }))
            );
          } else {
            setClientsOptions([]);
            toast.warn('Nenhum cliente foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setOptionsClientLoading(false);
        } catch {
          toast.error('Falha ao buscar clientes.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [user, company, model_id]
  );

  const loadModel = useCallback(
    async model => {
      try {
        setFormLoading(true);

        const response = await api.get(`/process-models/new-process/${model}`);

        if (!response.data) {
          toast.error('Falha ao buscar modelo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push({
            pathname: '/expiration-control',
            state: { model_id },
          });
        }

        response.data.documents.forEach(item => {
          item.situation = 0;
        });

        setUploadFiles({
          documents: response.data.documents.map(item => ({
            file: item.file,
            file_target: null,
          })),
        });

        const { data } = response;

        data.documents = data.documents.map(item => ({
          ...item,
          days_to_notificate: data.process_type === 2 ? 30 : 45,
        }));

        await loadClients(data.processModel_id);
        setInitialData(data);
        setFormLoading(false);
      } catch {
        toast.error('Falha ao buscar modelo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        history.push({ pathname: '/expiration-control', state: { model_id } });
      }
    },
    [model_id, loadClients]
  );

  const loadProcess = useCallback(async () => {
    if (user && company) {
      try {
        setFormLoading(true);

        const response = await api.get(`/processes/${id}`);
        const { data } = response;

        if (data) {
          data.documents = data.documents.map(documentItem => ({
            ...documentItem,
            expiration_date: documentItem.expiration_date
              ? parseISO(documentItem.expiration_date)
              : null,
            released: documentItem.situation === 3,
          }));

          client.current = data.client.name;
          delete data.client;

          setUploadFiles({
            documents: data.documents.map(item => ({
              file: item.file,
              file_target: null,
            })),
          });
          setInitialData(data);
        } else {
          toast.warn('O processo não foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push({
            pathname: '/expiration-control',
            state: { model_id },
          });
        }

        setFormLoading(false);
      } catch {
        toast.error('Falha ao buscar processo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        history.push({ pathname: '/expiration-control', state: { model_id } });
      }
    }
  }, [id, company, user, model_id]);

  useEffect(() => {
    if (id) {
      loadProcess();
    } else if (model_id) {
      loadModel(model_id);
    } else {
      toast.warn('Nenhum modelo selecionado.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      history.push('/expiration-control-models');
    }
  }, [id, model_id, loadModel, loadProcess]);

  const resetForm = useCallback(() => {
    loadModel(model_id);
  }, [model_id, loadModel]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() =>
              history.push({
                pathname: '/expiration-control',
                state: { model_id },
              })
            }
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [model_id]);

  const handleSubmit = useCallback(
    async data => {
      if (user && company) {
        setLoading(true);
        try {
          const schema = Yup.object().shape({
            client_id: Yup.number().typeError('O cliente é obrigatório'),
            processModel_id: Yup.number().typeError('O modelo é obrigatório'),
            user_id: Yup.number().typeError('O responsável é obrigatório'),
            labor: Yup.bool(),
            tributary: Yup.bool(),
            accounting: Yup.bool(),
            financial: Yup.bool(),
            administration: Yup.bool(),
            documents: Yup.array().of(
              Yup.object().shape({
                document: Yup.string().required('O documento é obrigatório'),
                responsible_id: Yup.number()
                  .typeError('O responsável é obrigatório')
                  .required('O responsável é obrigatório'),
                obs: Yup.string('A observação deve ser um texto'),
                expiration_date: Yup.string().nullable(),
                released: Yup.bool(),
                days_to_notificate: Yup.number()
                  .typeError('Esse campo é obrigatório')
                  .required('Esse campo é obrigatório'),
                file_name: Yup.string()
                  .typeError('O arquivo é obrigatório')
                  .when(
                    ['document_required', 'released'],
                    (required, released, yupString) => {
                      return required && !released
                        ? yupString.required('O arquivo é obrigatório')
                        : yupString.nullable();
                    }
                  ),
              })
            ),
          });

          await schema.validate(data, { abortEarly: false });

          const {
            client_id,
            processModel_id,
            user_id,
            labor,
            tributary,
            accounting,
            financial,
            administration,
          } = data;

          const notification = {
            model: modelsOptions.find(option => option.value === model_id)
              .label,
            sender_id: user.id,
            sender_name: user.short_name,
          };

          if (data.documents) {
            data.documents = data.documents.map(item => ({
              ...item,
              expiration_date: item.expiration_date || subDays(new Date(), 1),
            }));
          }

          let processId = id || null;

          if (id) {
            await api.put(`/processes/${id}`, {
              client_id,
              processModel_id,
              user_id,
              view: initialData.user_id !== user_id ? 0 : 1,
              start_date: new Date(),
              situation: 0,
              labor,
              tributary,
              accounting,
              financial,
              administration,
              notification,
            });

            if (data.documents) {
              const documentsPromises = data.documents.map(
                async ({ released, ...all }, index) => {
                  const situation = released ? 3 : 0;

                  if (uploadFiles.documents[index].file_target) {
                    const formData = new FormData();

                    formData.append(
                      'file',
                      uploadFiles.documents[index].file_target
                    );

                    const fileResponse = await api.post(
                      'files/upload',
                      formData
                    );

                    const { blobName } = fileResponse.data;

                    await api.put(
                      `/processes/documents/${initialData.documents[index].id}`,
                      {
                        ...all,
                        situation,
                        file: uploadFiles.documents[index].file,
                        file_url: blobName,
                      }
                    );
                  } else {
                    await api.put(
                      `/processes/documents/${initialData.documents[index].id}`,
                      {
                        ...all,
                        situation,
                        file: uploadFiles.documents[index].file,
                        file_url: uploadFiles.documents[index].file
                          ? initialData.documents[index].file_url
                          : null,
                      }
                    );
                  }
                }
              );

              await Promise.all(documentsPromises);
            }
          } else {
            const response = await api.post('/processes', {
              client_id,
              company_id: company.id,
              processModel_id,
              user_id,
              start_date: new Date(),
              situation: 0,
              labor,
              tributary,
              accounting,
              financial,
              administration,
              model_type: 2,
              process_type: initialData.process_type,
              notification,
            });

            processId = response.data.id;

            if (data.documents) {
              const documentsPromises = data.documents.map(
                async ({ released, ...all }, index) => {
                  const situation = released ? 3 : 0;

                  if (uploadFiles.documents[index].file_target) {
                    const formData = new FormData();

                    formData.append(
                      'file',
                      uploadFiles.documents[index].file_target
                    );

                    const fileResponse = await api.post(
                      'files/upload',
                      formData
                    );

                    const { blobName } = fileResponse.data;

                    await api.post('/processes/documents', {
                      ...all,
                      situation,
                      order: initialData.documents[index].order,
                      modelDocument_id: initialData.documents[index].id,
                      required: initialData.documents[index].required,
                      process_id: response.data.id,
                      file: uploadFiles.documents[index].file,
                      file_url: blobName,
                    });
                  } else {
                    await api.post('/processes/documents', {
                      ...all,
                      situation,
                      order: initialData.documents[index].order,
                      modelDocument_id: initialData.documents[index].id,
                      required: initialData.documents[index].required,
                      process_id: response.data.id,
                      file: uploadFiles.documents[index].file,
                      file_url: uploadFiles.documents[index].file
                        ? initialData.documents[index].file_url
                        : null,
                    });
                  }
                }
              );

              await Promise.all(documentsPromises);
            }
          }

          if (releasedDocuments.current.length > 0 && processId) {
            let content = `Os seguintes documento foram desobrigados pelo usuário ${user.name}:\n`;

            releasedDocuments.current.forEach(item => {
              content += `\n- ${item.document}`;
            });

            await api.post('processes/feedback', {
              company_id: company.id,
              process_id: processId,
              user_id: user.id,
              type: 0,
              content,
            });
          }

          formRef.current.setErrors({});

          toast.success('Processo salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setLoading(false);

          history.push({
            pathname: '/expiration-control',
            state: { model_id },
          });
        } catch (err) {
          setLoading(false);

          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
            toast.error(
              'Existem campos obrigatórios em vermelho que precisam ser preenchidos.',
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          } else {
            toast.error('Falha ao salvar processo.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    },
    [user, company, id, initialData, uploadFiles, model_id, modelsOptions]
  );

  const handleChangeModel = useCallback(
    selectedOption => {
      const { value } = selectedOption;
      formRef.current.setFieldValue('client_id', null);
      loadModel(value);
    },
    [loadModel]
  );

  const handleAddFile = useCallback(
    (file, index, group) => {
      setUploadFiles(
        produce(uploadFiles, draft => {
          draft[group][index].file_target = file;
          draft[group][index].file = file.name;
        })
      );
    },
    [uploadFiles]
  );

  const handleDeleteFile = useCallback(
    (index, group) => {
      setUploadFiles(
        produce(uploadFiles, draft => {
          draft[group][index].file_target = null;
          draft[group][index].file = null;
        })
      );
    },
    [uploadFiles]
  );

  const handleChangeReleased = useCallback(
    ({ checked }, index, document) => {
      if (checked && !initialData.documents[index].released) {
        releasedDocuments.current.push({ id: index, document });
      } else {
        const indexOfDocumentToDelete = releasedDocuments.current
          .map(item => item.id)
          .indexOf(index);
        if (indexOfDocumentToDelete !== -1) {
          releasedDocuments.current.splice(indexOfDocumentToDelete, 1);
        }
      }
    },
    [initialData]
  );

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaCalendar size={20} color="#44546a" />
            <h1>Controle de Vencimento</h1>
          </div>
        </Header>

        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {formLoading || optionsLoading || optionsClientLoading ? (
          <FormLoading />
        ) : (
          <Content className="content">
            {initialData && responsibleOptions && (
              <FormContainer
                ref={formRef}
                initialData={initialData}
                loading={loading ? 1 : 0}
                onSubmit={handleSubmit}
              >
                <ProcessTypeInfo>
                  <h4>PROCESSO</h4>
                  <section>
                    {id ? (
                      <Input
                        name="processModel"
                        className="type"
                        label="Modelo"
                        type="text"
                        value={
                          modelsOptions.find(
                            option => option.value === model_id
                          )?.label
                        }
                        disabled
                      />
                    ) : (
                      <Select
                        name="processModel_id"
                        className="type"
                        label="Modelo"
                        type="text"
                        options={modelsOptions}
                        onChange={handleChangeModel}
                        placeholder="Selecione um modelo"
                      />
                    )}
                    {id ? (
                      <>
                        <Input
                          name="client"
                          className="title"
                          label="Cliente"
                          type="text"
                          value={client.current || ''}
                          disabled
                        />
                        <div className="hidden">
                          <Input name="client_id" />
                        </div>
                      </>
                    ) : (
                      <Select
                        name="client_id"
                        className="title"
                        label="Cliente"
                        type="text"
                        options={clientsOptions}
                        placeholder="Selecione um cliente"
                      />
                    )}

                    <Select
                      name="user_id"
                      className="user"
                      label="Responsável"
                      options={responsibleOptions}
                      placeholder="Selecione um responsável"
                    />
                  </section>
                  <section className="sector">
                    <Checkbox
                      id="labor"
                      name="labor"
                      className="checkbox"
                      label="Trabalhista"
                    />
                    <Checkbox
                      id="tributary"
                      name="tributary"
                      className="checkbox"
                      label="Tributário"
                    />
                    <Checkbox
                      id="accounting"
                      name="accounting"
                      className="checkbox"
                      label="Contábil"
                    />
                    <Checkbox
                      id="financial"
                      name="financial"
                      className="checkbox"
                      label="Financeiro"
                    />
                    <Checkbox
                      id="administration"
                      name="administration"
                      className="checkbox"
                      label="Administração"
                    />
                  </section>

                  {initialData.documents.length > 0 && (
                    <Title>
                      <h4 className="description">DOCUMENTAÇÃO NECESSÁRIA</h4>
                    </Title>
                  )}
                  {initialData.documents.map((item, index) => (
                    <section key={index}>
                      <Scope path={`documents[${index}]`}>
                        <Input
                          name="document"
                          className="document"
                          label="Documento"
                          type="text"
                        />
                        <div className="hidden">
                          <Checkbox name="document_required" />
                        </div>
                        <Select
                          name="responsible_id"
                          className="responsible"
                          label="Responsável"
                          type="text"
                          options={usersOptions}
                          placeholder="Selecione um responsável"
                        />
                        <DatePicker
                          name="expiration_date"
                          className="date"
                          label="Vencimento"
                        />
                        <Input
                          name="days_to_notificate"
                          type="number"
                          className="days"
                          label="Notificar (x) dias antes"
                        />
                        <UploadFile>
                          {uploadFiles.documents[index].file ? (
                            <>
                              <Delete>
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleDeleteFile(index, 'documents')
                                  }
                                >
                                  <FaTrash size={14} />
                                </button>
                              </Delete>
                              <FileName>
                                <Input
                                  name="file_name"
                                  className="file"
                                  label="Arquivo"
                                  type="text"
                                  value={uploadFiles.documents[index].file}
                                  disabled
                                />
                              </FileName>
                            </>
                          ) : (
                            <File>
                              <label htmlFor={`file.doc.${index}`}>
                                <FaPaperclip size={14} color="#FCFCFC" />
                              </label>
                              <FileInput
                                id={`file.doc.${index}`}
                                name="file_name"
                                onChange={e =>
                                  handleAddFile(
                                    e.target.files[0],
                                    index,
                                    'documents'
                                  )
                                }
                              />
                            </File>
                          )}
                        </UploadFile>
                        <div>
                          <Checkbox
                            id={`doc${index}`}
                            name="released"
                            className="checkbox"
                            label="Desobrigado"
                            onChange={e =>
                              handleChangeReleased(
                                e.target,
                                index,
                                item.document
                              )
                            }
                          />
                        </div>
                      </Scope>
                    </section>
                  ))}
                </ProcessTypeInfo>
              </FormContainer>
            )}
          </Content>
        )}
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default Form;

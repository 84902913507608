import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;

  display: none;
  align-items: center;

  &.show {
    display: flex;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  background: #f0f0f0;
  border-radius: 4px;
  padding: 16px 24px;

  min-height: 250px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: row;

  img {
    width: 120px;
  }

  button {
    margin-left: 4px;
    background: #01579b;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    padding: 6px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.05, '#01579B')};
    }
  }

  section {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  section:first-child {
    margin: 0;
  }

  section:nth-child(2) {
    min-width: 250px;
    max-width: 350px;
    border-left: 1px solid #bbb;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  h2 {
    font-size: 1.5rem;
    color: #01579b;
    margin-left: 8px;
  }
`;

export const UpdateInfo = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 1rem;
  margin-left: 1rem;

  p {
    margin-bottom: 8px;
    color: #545454;
  }

  h3 {
    color: #01579b;
    margin: 4px 0;
  }

  span {
    display: block;
    font-size: 14px;
    margin-left: 16px;
    margin-bottom: 2px;
  }

  ul {
    color: #545454;
    padding-left: 32px;
    font-size: 13px;

    li {
      list-style: disc;
    }
  }
`;

import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import {
  FaTimes,
  FaEye,
  FaEraser,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaUserFriends,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import formatDocument from '~/util/formatDocument';

import { Input, Select } from '~/components/Form';
import { TableLoading } from '~/components/Table';
import Pagination from '~/components/Pagination';

import {
  Container,
  Header,
  Controls,
  Table,
  DetailsContainer,
} from '~/styles/components';
import { Filter, Content, EmployeeInfo } from './styles';

const List = () => {
  const { company } = useAuth();

  const filterRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const [showTable, setShowTable] = useState(true);

  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState({});
  const [employeeIndex, setEmployeeIndex] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);

  const [currentPage, setCurrentPage] = useState(() => {
    const currentPageStorage = sessionStorage.getItem(
      '@Diretiva:employees:page'
    );

    if (currentPageStorage) {
      return Number(currentPageStorage);
    }

    return 1;
  });
  const [totalPages, setTotalPages] = useState(1);

  const [nameSearch, setNameSearch] = useState(() => {
    const nameStorage = localStorage.getItem('@Diretiva:employees:filter:name');

    if (nameStorage) {
      return nameStorage;
    }

    return '';
  });
  const [selectedSituation, setSelectedSituation] = useState(() => {
    const situationStorage = localStorage.getItem(
      '@Diretiva:employees:filter:situation'
    );

    if (situationStorage) {
      return JSON.parse(situationStorage);
    }

    return { value: '', label: 'Todas' };
  });
  const [selectedClient, setSelectedClient] = useState(() => {
    const clientStorage = localStorage.getItem(
      '@Diretiva:employees:filter:client'
    );

    if (clientStorage) {
      return JSON.parse(clientStorage);
    }

    return { value: '', label: 'Todos' };
  });

  const loadEmployees = useCallback(async () => {
    if (company) {
      try {
        setShowTable(true);
        setLoading(true);

        const response = await api.get('/employees', {
          params: {
            company_id: company.id,
            page: currentPage,
            name: nameSearch,
            client_id: selectedClient.value,
            situation: selectedSituation.value,
          },
        });

        if (response.data.docs.length > 0) {
          const formattedEmployees = response.data.docs.map(item => ({
            ...item,
            client_name: item.client.name,
            client_nickname: item.client.nickname,
            admission_date: item.admission_date
              ? format(parseISO(item.admission_date), 'dd/MM/yyyy')
              : '',
            resignation_date: item.resignation_date
              ? format(parseISO(item.resignation_date), 'dd/MM/yyyy')
              : '',
            attest_date: item.attest_date
              ? format(parseISO(item.attest_date), 'dd/MM/yyyy')
              : '',
            first_ctto_date: item.first_ctto_date
              ? format(parseISO(item.first_ctto_date), 'dd/MM/yyyy')
              : '',
            second_ctto_date: item.second_ctto_date
              ? format(parseISO(item.second_ctto_date), 'dd/MM/yyyy')
              : '',
            cpf: item.cpf ? formatDocument(1, item.cpf) : '',
          }));

          setEmployees(formattedEmployees);
          setTotalPages(response.data.pages);
          setTotalEmployees(response.data.total);
        } else {
          toast.warn('Nenhum funcionário foi encontrada.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch {
        toast.error('Falha ao buscar pessoas funcinários.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [company, currentPage, nameSearch, selectedSituation, selectedClient]);

  const situationOptions = useMemo(() => {
    return [
      { value: '', label: 'Todas' },
      { value: 0, label: 'Admitido' },
      { value: 1, label: 'Demitido' },
    ];
  }, []);

  const [clientsOptions, setClientsOptions] = useState([]);

  const loadClients = useCallback(async () => {
    if (company) {
      try {
        const response = await api.get(`/relationships`, {
          params: {
            company_id: company.id,
            selectOnly: true,
            type: 1,
          },
        });

        let options = [];
        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          options = response.data.map(item => ({
            value: item.id,
            label: item.name,
          }));
        } else {
          toast.warn('Nenhum cliente foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        options.unshift({ value: '', label: 'Todos' });
        setClientsOptions(options);
      } catch {
        toast.error('Falha ao buscar clientes.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [company]);

  useEffect(() => {
    loadEmployees();
    loadClients();
  }, [loadEmployees, loadClients]);

  const handleView = useCallback(() => {
    if (employees.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, employees]);

  const handlePrevItem = useCallback(() => {
    if (employeeIndex !== 0) {
      setEmployeeIndex(employeeIndex - 1);
      setEmployee(employees[employeeIndex - 1]);
    } else {
      setEmployeeIndex(employees.length - 1);
      setEmployee(employees[employees.length - 1]);
    }
  }, [employeeIndex, employees]);

  const handleNextItem = useCallback(() => {
    if (employeeIndex !== employees.length - 1) {
      setEmployeeIndex(employeeIndex + 1);
      setEmployee(employees[employeeIndex + 1]);
    } else {
      setEmployeeIndex(0);
      setEmployee(employees[0]);
    }
  }, [employeeIndex, employees]);

  const handleFilter = useCallback(
    ({ name, situation, client }) => {
      setCurrentPage(1);
      localStorage.setItem('@Diretiva:employees:page', 1);

      setNameSearch(name);
      localStorage.setItem('@Diretiva:employees:filter:name', name);

      setSelectedSituation(
        situationOptions.find(option => option.value === situation)
      );
      localStorage.setItem(
        '@Diretiva:employees:filter:situation',
        JSON.stringify(
          situationOptions.find(option => option.value === situation)
        )
      );

      setSelectedClient(clientsOptions.find(option => option.value === client));
      localStorage.setItem(
        '@Diretiva:employees:filter:client',
        JSON.stringify(clientsOptions.find(option => option.value === client))
      );
    },
    [situationOptions, clientsOptions]
  );

  const handleResetFilter = useCallback(() => {
    setCurrentPage(1);
    localStorage.removeItem('@Diretiva:employees:page');

    filterRef.current.reset();

    setNameSearch('');
    localStorage.removeItem('@Diretiva:employees:filter:name');

    filterRef.current.setFieldValue('situation', {
      value: '',
      label: 'Todas',
    });
    setSelectedSituation({
      value: '',
      label: 'Todas',
    });
    localStorage.removeItem('@Diretiva:employees:filter:situation');

    filterRef.current.setFieldValue('client', {
      value: '',
      label: 'Todos',
    });
    setSelectedClient({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:employees:filter:client');
  }, []);

  const handleDetails = useCallback(
    index => {
      setEmployee(employees[index]);
      setEmployeeIndex(index);
      handleView();
    },
    [employees, handleView]
  );

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
        sessionStorage.setItem('@Diretiva:employees:page', 1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
        sessionStorage.setItem('@Diretiva:employees:page', totalPages);
      } else {
        setCurrentPage(page);
        sessionStorage.setItem('@Diretiva:employees:page', page);
      }
    },
    [totalPages]
  );

  return (
    <Container>
      <Header>
        <div>
          <FaUserFriends />
          <h1>Funcionários</h1>
        </div>

        <Link to="/">
          <FaTimes />
        </Link>
      </Header>
      <Controls>
        <button type="button" onClick={handleView}>
          <FaEye />
          <span>Visualização</span>
        </button>
        {showTable ? (
          <button type="button" onClick={handleResetFilter}>
            <FaEraser />
            <span>Limpar filtros</span>
          </button>
        ) : (
          <div>
            <button type="button" onClick={handlePrevItem}>
              <FaChevronLeft />
            </button>
            {totalEmployees > 25 ? (
              <span>{employeeIndex + 1} de 25</span>
            ) : (
              <span>
                {employeeIndex + 1} de {totalEmployees}
              </span>
            )}
            <button type="button" onClick={handleNextItem}>
              <FaChevronRight />
            </button>
          </div>
        )}
      </Controls>
      {showTable && (
        <Filter ref={filterRef} onSubmit={handleFilter}>
          <Input
            name="name"
            className="name"
            label="Nome"
            defaultValue={nameSearch}
          />
          <Select
            label="Situação"
            name="situation"
            className="situation"
            options={situationOptions}
            defaultValue={selectedSituation}
          />
          <Select
            label="Cliente"
            name="client"
            className="client"
            options={clientsOptions}
            defaultValue={selectedClient}
          />

          <button type="submit">
            <FaSearch />
          </button>
        </Filter>
      )}
      {loading ? (
        <TableLoading />
      ) : (
        <Content className="content">
          {showTable ? (
            <Table className="table">
              <thead>
                <tr>
                  <th className="cpf">CPF</th>
                  <th className="name">Nome</th>
                  <th className="client">Cliente</th>
                  <th className="situation">Situação</th>
                </tr>
              </thead>
              <tbody>
                {employees?.map((item, index) => (
                  <tr
                    key={item.id}
                    className="hover"
                    onClick={() => handleDetails(index)}
                  >
                    <td className="cpf">{item.cpf}</td>
                    <td className="name">{item.name}</td>
                    <td className="client">{item.client_nickname}</td>
                    <td className="situation">
                      {
                        situationOptions.find(
                          option => option.value === item.situation
                        )?.label
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <DetailsContainer>
              <EmployeeInfo>
                <h4>FUNCIONÁRIO</h4>
                <section>
                  <div className="cpf">
                    <label>CPF</label>
                    <input name="cpf" value={employee?.cpf || ''} readOnly />
                  </div>
                  <div className="name">
                    <label>Nome</label>
                    <input name="name" value={employee?.name || ''} readOnly />
                  </div>
                  <div className="client">
                    <label>Cliente</label>
                    <input
                      name="client"
                      value={employee?.client_name || ''}
                      readOnly
                    />
                  </div>
                  <div className="situation">
                    <label>Situação</label>
                    {employee.situation === 0 && (
                      <input name="situation" value="Admitido" readOnly />
                    )}
                    {employee.situation === 1 && (
                      <input name="situation" value="Demitido" readOnly />
                    )}
                  </div>
                </section>
                <section>
                  <div className="date">
                    <label>Data de admissão</label>
                    <input
                      name="date"
                      value={employee?.admission_date || ''}
                      readOnly
                    />
                  </div>
                  <div className="date">
                    <label>Data de demissão</label>
                    <input
                      name="date"
                      value={employee?.resignation_date || ''}
                      readOnly
                    />
                  </div>
                  <div className="date">
                    <label>Data de atestado</label>
                    <input
                      name="date"
                      value={employee?.attest_date || ''}
                      readOnly
                    />
                  </div>
                  <div className="date">
                    <label>Fim Etapa 1 Ctto</label>
                    <input
                      name="date"
                      value={employee?.first_ctto_date || ''}
                      readOnly
                    />
                  </div>
                  <div className="date">
                    <label>Fim Etapa 2 Ctto</label>
                    <input
                      name="date"
                      value={employee?.second_ctto_date}
                      readOnly
                    />
                  </div>
                </section>
              </EmployeeInfo>
            </DetailsContainer>
          )}
        </Content>
      )}
      <Pagination
        loading={loading ? 1 : 0}
        currentPage={currentPage}
        pages={totalPages}
        totalDocs={totalEmployees}
        handlePage={handlePage}
      />
    </Container>
  );
};

export default List;

import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  h1 {
    margin-top: 1rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.blue};
  }

  table {
    color: #545454;
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    td,
    th {
      padding: 5px 15px 5px 15px;
      cursor: default;
    }

    .icon {
      width: 20px;

      button {
        background: transparent;

        transition: opacity 0.3s;
        &:hover {
          opacity: 0.7;
        }
      }
    }

    .description {
      width: 70%;
      max-width: 0;
    }

    .deadline {
      width: 15%;
      text-align: center;
    }

    .deadlineOnly {
      width: 30%;
      text-align: center;
    }
  }
`;

export const Td = styled.td``;

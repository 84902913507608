import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  /* Estilizando a barra de rolagem */
  ::-webkit-scrollbar {
    width: 10px; /* Largura da barra de rolagem vertical */
    height: 10px; /* Altura da barra de rolagem horizontal */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Cor do fundo da barra de rolagem */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor da barra de rolagem */
    border-radius: 10px; /* Arredondamento da barra de rolagem */
    border: 3px solid #f1f1f1; /* Espaço ao redor da barra de rolagem */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Cor da barra de rolagem ao passar o mouse */
  }

  table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }
    tbody {
      td {
        cursor: default;
      }
    }

    tr {
      td:not(:last-child) {
        padding-left: 10px;
      }
    }

    .date,
    .hour,
    .situation {
      padding-left: 10px;
      width: 10%;
    }

    .client,
    .phone {
      width: 13%;
    }

    .message {
      width: 50%;
      min-width: 200px; /* Largura mínima para evitar corte */
      word-wrap: break-word; /* Quebra de linha para evitar overflow */
      max-width: 0;
    }
  }
`;

const findAndFormatContact = (array, type) => {
  let found;

  if (type === 'email') {
    found = array.find(item => item.type === 1);

    if (found) {
      return found.content;
    }

    return '';
  }

  found = array.find(item => item.type !== 1);

  if (found) {
    if (found.content.length === 10) {
      return `(${found.content.slice(0, 2)}) ${found.content.slice(
        2,
        6
      )}-${found.content.slice(6, 10)}`;
    }
    return `(${found.content.slice(0, 2)}) ${found.content.slice(
      2,
      7
    )}-${found.content.slice(7, 11)}`;
  }
  return '';
};

export default findAndFormatContact;

import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { Container, Icon, Header, Content } from './styles';

const OptionWindow = ({ yesClick, noClose, Close, body }) => {
  return (
    <Container>
      <Icon>
        <FaExclamationTriangle size={38} color="#01579b" />
      </Icon>
      <Content>
        <Header>
          <strong>Atenção</strong>
        </Header>
        <span>{body}</span>
        <div>
          <button
            type="button"
            onClick={() => {
              Close();
              yesClick();
            }}
          >
            Sim
          </button>
          <button
            type="button"
            onClick={() => {
              Close();
              noClose();
            }}
          >
            Não
          </button>
        </div>
      </Content>
    </Container>
  );
};

export default OptionWindow;

OptionWindow.defaultProps = {
  body: 'Todos os dados não salvos serão perdidos com esta ação.',
  noClose: () => {}, // Add a defaultProps declaration for the "noClose" propType.
};

OptionWindow.propTypes = {
  yesClick: PropTypes.func.isRequired,
  noClose: PropTypes.func,
  Close: PropTypes.func.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from '@react-hook/window-size';
import { FaGift, FaTimes } from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import { Container, Content, Title, Message, NotShowAgain } from './styles';

const BirthdayMessage = () => {
  const { CheckUserBirthday, user, company } = useAuth();

  const [width, height] = useWindowSize();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    async function isUserBirthday() {
      setVisible(await CheckUserBirthday());
    }

    isUserBirthday();
  }, [CheckUserBirthday]);

  const handleClose = useCallback(() => {
    const birthdayMessage = document.getElementById('birthday-message');
    birthdayMessage.className = '';
    birthdayMessage.style = 'display: none';
  }, []);

  const handleNotShowAgain = useCallback(async () => {
    await api.put('company-users/get/birthday');
  }, []);

  return (
    <>
      <Container visible={visible} id="birthday-message">
        {user && company && (
          <Content>
            <section>
              <img
                src={user.avatar ? user.avatar_url : company.logo_url}
                alt="update"
              />
            </section>
            <section>
              <Title>
                <FaGift size={20} color="#01579B" />
                <h2>{user.nick}, parabéns!</h2>
                <FaTimes
                  className="close"
                  color="#E53935"
                  size={16}
                  onClick={handleClose}
                />
              </Title>
              <Message>
                <span>"Eu te desejo não parar tão cedo</span>
                <span>Pois toda idade tem prazer e medo</span>
                <span>E com os que erram feio e bastante</span>
                <span>Que você consiga ser tolerante</span>
                <span>Quando você ficar triste, que seja por um dia</span>
                <span>E não o ano inteiro</span>
                <span>E que você descubra que rir é bom</span>
                <span>Mas que rir de tudo é desespero</span>
                <span>Desejo que você tenha a quem amar</span>
                <span>E quando estiver bem cansado</span>
                <span>Ainda exista amor pra recomeçar."</span>

                <strong>Feliz Aniversário!</strong>
              </Message>
              <NotShowAgain>
                <input
                  id="not-show"
                  type="checkbox"
                  onClick={handleNotShowAgain}
                />
                <label htmlFor="not-show">
                  Não mostrar esta mensagem novamente
                </label>
              </NotShowAgain>
            </section>
          </Content>
        )}
        <Confetti
          width={width}
          height={height}
          initialVelocityY={10}
          numberOfPieces={600}
          tweenDuration={500}
          gravity={0.1}
        />
      </Container>
    </>
  );
};

export default BirthdayMessage;

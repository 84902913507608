import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import base64 from 'base-64';

const RedirectPortal = ({
  component: Component,
  path,
  computedMatch,
  ...rest
}) => {
  const { data } = computedMatch.params;

  let dataParse;
  try {
    dataParse = JSON.parse(base64.decode(data));
  } catch {
    return <Redirect to="/portal/error" />;
  }

  const { companyId, user, password } = dataParse;

  if (!companyId) {
    return <Redirect to="/portal/error" />;
  }

  localStorage.setItem('@Diretiva:portal:companyId', base64.encode(companyId));

  if (user && password) {
    localStorage.setItem('@Diretiva:portal:user', base64.encode(user));
    localStorage.setItem('@Diretiva:portal:password', base64.encode(password));
  }

  if (path === '/portal/redirect/:data') {
    return <Redirect to="/portal/signin" />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default RedirectPortal;

import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import ClearBackground from '~/components/ClearBackground';
import { Spinner } from '~/components/Spinner';
import logo from '~/assets/building.png';

import { Container, Loading } from './styles';

export default function ChooseCompany({ visible, handleToggleChooseCompany }) {
  const { token, changeCompany, user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    async function loadCompanies() {
      const companiesInStorage = localStorage.getItem('@Diretiva:companies');

      try {
        if (companiesInStorage) {
          setCompanies(JSON.parse(companiesInStorage));
        } else {
          setLoading(true);

          const response = await api.get('user-companies');

          localStorage.setItem(
            '@Diretiva:companies',
            JSON.stringify(response.data)
          );

          setCompanies(response.data);
        }

        setLoading(false);
      } catch (err) {
        toast.error('Falha ao buscar empresas.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setLoading(false);
      }
    }

    loadCompanies();
  }, []);

  const handleChangeCompany = useCallback(
    async id => {
      try {
        setLoading(true);
        await changeCompany(id, null, token);
        setLoading(false);
        handleToggleChooseCompany();
      } catch {
        toast.error('Falha ao trocar de empresa.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
        handleToggleChooseCompany();
      }
    },
    [changeCompany, token, handleToggleChooseCompany]
  );

  return (
    <>
      <Container visible={visible} loading={loading ? 1 : 0}>
        {companies?.map(company => (
          <button
            type="button"
            onClick={() => handleChangeCompany(company.company.id)}
            key={String(company.company.id)}
          >
            <img
              src={company.company.logo ? company.company.logo_url : logo}
              alt={company.company.name}
            />
            <span>{company.company.name}</span>
          </button>
        ))}
      </Container>
      {loading ||
        (!user && (
          <>
            <Loading>
              <Spinner />
            </Loading>
          </>
        ))}
      {visible && <ClearBackground />}
    </>
  );
}

ChooseCompany.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleToggleChooseCompany: PropTypes.func.isRequired,
};

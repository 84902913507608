import styled, { keyframes } from 'styled-components';

const apperFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const apperFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const apperFromBottom = keyframes`
  from {
    opacity: 0;
    bottom: 0;
  }
  to {
    opacity: 1;
    bottom: 20px;
  }
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;

  cursor: ${props => (props.loading ? 'wait' : 'default')};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 500px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  @media screen and (max-width: 850px) {
    max-width: 100%;
  }

  footer {
    position: absolute;
    bottom: 20px;
    color: ${({ theme }) => theme.colors.grey};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: ${apperFromBottom} 1s;

    div {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      strong {
        font-size: 14px;
      }

      svg {
        margin-right: 2px;
      }
    }

    span {
      font-size: 12px;
    }
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${apperFromRight} 1s;
  width: 250px;

  img {
    width: 150px;
    height: 150px;
  }

  form {
    margin-top: 30px;
    width: 100%;
    text-align: center;

    h1 {
      color: ${props => `${props.color}`};
      margin-bottom: 24px;
    }

    button {
      background: ${props => `${props.color}`};
      height: 40px;
      border-radius: 4px;
      border: 0;
      padding: 0 16px;
      color: ${({ theme }) => theme.colors.white};
      width: 100%;
      font-weight: 500;
      margin: 16px 0 16px 0;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.9;
      }
    }

    a {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.blue};
      align-self: flex-start;

      &:hover {
        color: ${({ theme }) => theme.hover.blue};
      }
    }
  }
`;

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-size: cover !important;

  @media screen and (max-width: 850px) {
    display: none;
  }

  .message {
    display: flex;
    flex-direction: column;
    padding: 80px;
    animation: ${apperFromLeft} 1s;

    p {
      font-size: 56px;
      color: ${({ theme }) => theme.colors.white};
      text-shadow: 2px 2px black, 2px 2px 2px black;
    }
  }

  .slogan {
    display: flex;
    flex: 1;
    padding: 80px;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;

    p {
      font-size: 32px;
      color: ${({ theme }) => theme.colors.white};
      text-shadow: 2px 2px black, 2px 2px 2px black;
    }
  }

  @media (max-width: 1800px) {
    .message {
      p {
        font-size: 48px;
      }
    }

    .slogan {
      p {
        font-size: 24px;
      }
    }
  }

  @media (max-width: 1600px) {
    .message {
      p {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 1400px) {
    .message {
      p {
        font-size: 32px;
      }
    }

    .slogan {
      p {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 1250px) {
    .message {
      p {
        font-size: 24px;
      }
    }
  }

  @media (max-width: 1100px) {
    .message {
      p {
        font-size: 20px;
      }
    }
  }
`;

import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  position: relative;
  z-index: ${props => (props.visible ? '10' : '8')};
`;

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  margin-left: 16px;

  label {
    position: absolute;
    right: 3px;
    top: 0;
    z-index: 1;
    font-size: 7px;
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover span {
    visibility: visible;
    top: 180%;
    opacity: 1;
  }

  span {
    visibility: hidden;
    width: 120px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 10002;
    top: 150%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.8s;
  }

  span:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${({ theme }) => theme.colors.primary}
      transparent;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  right: 0;
  margin-right: 10px;
  top: 50px;
  width: 330px;
  border: 1px solid ${({ theme }) => theme.colors.lightBorder};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 4;
  border-radius: 4px;
  padding: 5px 10px;
  height: ${props => `${props.height - 60}px`};
  transform: ${({ visible }) =>
    visible ? 'translateX(0)' : 'translateX(340px)'};
  transition: transform 0.25s ease-in-out;

  .checkbox {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    input {
      margin-right: 16px;
      transform: scale(1.5);
      padding-top: 10px;
      height: 30px;
      background: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    }

    label {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.grey};
      opacity: 0.7;
      margin-right: 8px;
    }
  }
`;

export const Title = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px;

  div {
    display: flex;
    align-items: center;

    margin-left: 10px;

    h3 {
      margin-left: 15px;
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  svg {
    margin-right: 5px;
    cursor: pointer;
  }
`;

export const SearchContainer = styled(Form)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 10px;
  margin: 10px 5px 5px 5px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 4px;

  > div {
    flex: 1;

    input {
      all: unset;
    }
  }

  button {
    background: none;
  }
`;

export const ContactsList = styled.div`
  display: flex;
  flex-direction: column;

  padding: 5px 5px;
  border-radius: 4px;
`;

export const Contact = styled.div`
  & + div {
    margin-top: 16px;
  }
`;

export const Results = styled.div`
  display: ${props => (props.loading ? 'none' : 'flex')};
  flex-direction: column;
  padding: 5px 5px;
  border-radius: 4px;
`;

export const ContactName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background: none;
    border: none;
    margin-right: 15px;
    color: ${({ theme }) => theme.colors.grey};
    transition: color 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 40px);

  &:hover {
    cursor: pointer;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  > span {
    font-size: 13px;
    margin-left: 5px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 8px;

  > div {
    display: flex;
    align-items: center;
    position: relative;

    & + div {
      margin-top: 8px;
    }

    a {
      display: flex;
    }

    textarea {
      width: 100%;
      resize: unset;
      margin-left: 5px;
      font-size: 11px;
      color: ${({ theme }) => theme.colors.primary};
      overflow: hidden;
      overflow-wrap: break-word;
    }

    input {
      margin-left: 5px;
      font-size: 11px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      margin-left: 5px;
      font-size: 11px;
      color: ${({ theme }) => theme.colors.primary};
    }

    button {
      position: absolute;
      background: none;
      border: none;
      right: 15px;
      color: ${({ theme }) => theme.colors.grey};
      transition: color 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.blue};
      }
    }
  }
`;

export const RelatedContainer = styled.div`
  margin-left: 20px;
`;

export const Related = styled.div`
  margin-left: 10px;
  margin-top: 10px;
`;

export const RelatedName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background: none;
    border: none;
    margin-right: 15px;
    color: ${({ theme }) => theme.colors.grey};
    transition: color 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  > div {
    > div {
      border: 1px solid ${({ theme }) => theme.colors.blue};
    }

    > span {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

export const RelatedContacts = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 8px;

  > div {
    display: flex;
    align-items: center;
    position: relative;

    & + div {
      margin-top: 8px;
    }

    a {
      display: flex;
    }

    input {
      margin-left: 5px;
      font-size: 11px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      margin-left: 5px;
      font-size: 11px;
      color: ${({ theme }) => theme.colors.primary};
    }

    button {
      position: absolute;
      background: none;
      border: none;
      right: 15px;
      color: ${({ theme }) => theme.colors.grey};
      transition: color 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.blue};
      }
    }
  }
`;

export const LoadingDiv = styled.div`
  position: absolute;
  z-index: 10;
  top: 140px;
  right: 140px;
`;

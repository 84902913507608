import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaFileAlt, FaSave, FaBroom, FaTimes } from 'react-icons/fa';
import { parseISO } from 'date-fns';
import * as Yup from 'yup';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import {
  FormContainer,
  FormLoading,
  Select,
  Input,
  InputMask,
  DatePicker,
  Checkbox,
} from '~/components/Form';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Header,
  Controls,
  Content,
  Document,
  Department,
  Taxation,
} from './styles';

const Form = () => {
  const { user, companyUser, company } = useAuth();

  const { state } = useLocation();

  const id = state?.id || null;

  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [typeOfEdition, setTypeOfEdition] = useState(true);
  const [showMainDocument, setShowMainDocument] = useState(true);

  const [document, setDocument] = useState(null);
  const [folders, setFolders] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedDocumentType, setSelectedDocumentType] = useState({
    value: '',
    label: 'Selecione um tipo',
  });
  const [selectedPeriodicityType, setSelectedPeriodicityType] = useState({
    value: '',
    label: 'Selecione uma periodicidade',
  });
  const [selectedDueDateType, setSelectedDueDateType] = useState({
    value: '',
    label: 'Selecione um vencimento',
  });
  const [selectedDateType, setSelectedDateType] = useState({
    value: '',
    label: 'Selecione uma data',
  });
  const [selectedTerritorialScope, setSelectedTerritorialScope] = useState({
    value: '',
    label: 'Selecione uma abrangência',
  });
  const [selectedState, setSelectedState] = useState({
    value: '',
    label: 'Selecione um estado',
  });

  useEffect(() => {
    async function loadDocument() {
      if (companyUser && user) {
        if (id) {
          try {
            setLoading(true);

            const response = await api.get(`documents/${id}`);

            const { data } = response;

            if (data.main_document) {
              if (companyUser.level === 9 && user.main_company === 3) {
                setTypeOfEdition(true);
              } else {
                setShowMainDocument(false);
                setTypeOfEdition(false);
              }
            } else if (companyUser.level !== 9 || user.main_company !== 3) {
              setShowMainDocument(false);
            }

            if (data) {
              data.start_date = data.start_date
                ? parseISO(data.start_date)
                : null;
              data.end_date = data.end_date ? parseISO(data.end_date) : null;

              setSelectedDocumentType({
                value: data.document_type,
                label: data.document_type_label,
              });

              setSelectedTerritorialScope({
                value: data.territorial_scope,
                label: data.territorial_scope_label,
              });

              setSelectedState({
                value: data.state,
                label: data.state_label,
              });

              setSelectedPeriodicityType({
                value: data.periodicity_type,
                label: data.periodicity_type_label,
              });

              setSelectedDueDateType({
                value: data.due_date_type,
                label: data.due_date_type_label,
              });

              setSelectedDateType({
                value: data.date_type,
                label: data.date_type_label,
              });

              setDocument(data);
            }

            setLoading(false);
          } catch (err) {
            toast.error('Falha ao buscar documento.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            setLoading(false);
          }
        } else {
          if (companyUser.level !== 9 || user.main_company !== 3) {
            setShowMainDocument(false);
          }
          setDocument({});
        }
      }
    }

    loadDocument();
  }, [id, companyUser, user]);

  useEffect(() => {
    async function loadFolders() {
      if (company) {
        try {
          const foldersRepsonse = await api.get('folders', {
            params: {
              company_id: company.id,
            },
          });

          const foldersOptions = foldersRepsonse.data.map(folder => ({
            value: folder.id,
            label: folder.description,
          }));

          const noFolder = foldersOptions.find(
            folder => folder.label === 'Sem Pasta'
          );

          const filteredOptions = foldersOptions.filter(
            folder => folder.label !== 'Sem Pasta'
          );

          filteredOptions.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          filteredOptions.unshift({
            value: noFolder?.value,
            label: noFolder?.label,
          });

          setFolders(filteredOptions);
        } catch (err) {
          toast.error('Falha ao carregar pastas.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    }

    loadFolders();
  }, [company]);

  useEffect(() => {
    function loadStates() {
      axios
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(response => {
          const ufInitials = response.data.map(uf => ({
            value: uf.sigla,
            label: uf.nome,
          }));

          setStates(ufInitials);
        });
    }
    loadStates();
  }, []);

  useEffect(() => {
    function loadCities() {
      if (
        selectedState.value === '' &&
        (selectedTerritorialScope.value === 0 ||
          selectedTerritorialScope.value === 1)
      ) {
        return;
      }

      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState.value}/municipios`
        )
        .then(response => {
          const cityNames = response.data.map(city => ({
            value: city.nome,
            label: city.nome,
          }));

          setCities(cityNames);
        });
    }

    loadCities();
  }, [selectedState.value, selectedTerritorialScope.value]);

  const documentTypeOptions = useMemo(() => {
    return [
      { value: 0, label: 'Documento' },
      { value: 1, label: 'Tributo' },
      { value: 2, label: 'Declaração' },
    ];
  }, []);

  const periodicityTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'Mensal' },
      { value: 2, label: 'Anual' },
      { value: 3, label: 'Eventual (Parcelamento)' },
    ];
  }, []);

  const dueDateTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'Mês atual' },
      { value: 2, label: 'Mês seguinte' },
      { value: 3, label: 'Ano atual' },
      { value: 4, label: 'Próximo ano' },
      { value: 5, label: 'Contra Apresentação' },
      { value: 6, label: '2º mês seguinte' },
    ];
  }, []);

  const dateTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'Dia específico' },
      { value: 2, label: 'Cálculo' },
    ];
  }, []);

  const dateCalculationOptions = useMemo(() => {
    return [
      { value: 1, label: 'Último dia do primeiro decêndio' },
      { value: 2, label: 'Último dia do segundo decêndio' },
      { value: 3, label: 'Último dia do mês subsequente' },
      { value: 4, label: 'Último dia da primeira quinzena' },
      { value: 5, label: 'Último dia da segunda quinzena' },
      { value: 6, label: 'Quinto dia útil' },
    ];
  }, []);

  const territorialScopeOptions = useMemo(() => {
    return [
      { value: 0, label: 'Federal' },
      { value: 1, label: 'Estadual' },
      { value: 2, label: 'Municipal' },
      { value: 3, label: 'Específico' },
    ];
  }, []);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            description: Yup.string().required(
              'A descrição do documento é obrigatória.'
            ),
            folder: Yup.string().required('A pasta é obrigátória.'),
            document_type: Yup.string().required(
              'O tipo de documento é obrigatório.'
            ),
            territorial_scope: Yup.string().when('document_type', {
              is: value => value && value === '0',
              then: Yup.string(),
              otherwise: Yup.string().required('A abrangência é obrigatória.'),
            }),
            periodicity_type: Yup.string().test(
              'required-periodicity-type',
              'A periodicidade do documento é obrigatória.',
              value => {
                if (data.use_in_checklist && !value) return false;

                return true;
              }
            ),
            due_date_type: Yup.string().test(
              'required-due-date-type',
              'O vencimento do documento é obrigatório.',
              value => {
                if (data.use_in_checklist && !value) return false;

                return true;
              }
            ),
            date_type: Yup.string().test(
              'required-date-type',
              'A data é obrigatória.',
              value => {
                if (!data.use_in_checklist) return true;

                if (
                  data.use_in_checklist &&
                  (data.periodicity_type === '' || data.due_date_type === '')
                )
                  return true;

                if (
                  data.use_in_checklist &&
                  data.periodicity_type !== 2 &&
                  data.due_date_type !== 5 &&
                  !value
                )
                  return false;

                return true;
              }
            ),
            specific_day_month: Yup.string().test(
              'required-specific-day-month',
              'O dia/mês é obrigatório',
              value => {
                if (!data.use_in_checklist) return true;

                if (data.use_in_checklist && data.periodicity_type === '')
                  return true;

                if (
                  data.use_in_checklist &&
                  data.periodicity_type === 2 &&
                  !value
                )
                  return false;

                return true;
              }
            ),
            specific_day: Yup.string().test(
              'required-specific-day-range',
              'Insira um dia de 1 a 28',
              value => {
                if (!data.use_in_checklist) return true;

                if (
                  data.use_in_checklist &&
                  (data.periodicity_type === '' ||
                    data.due_date_type === '' ||
                    data.date_type !== 1)
                )
                  return true;

                if (
                  data.use_in_checklist &&
                  data.periodicity_type !== 2 &&
                  data.due_date_type !== 5 &&
                  data.date_type === 1 &&
                  !value
                )
                  return false;

                if (value >= 1 && value <= 28) return true;

                return false;
              }
            ),
            date_calculation: Yup.string().test(
              'required-date-calculation',
              'O cálculo é obrigatõrio',
              value => {
                if (!data.use_in_checklist) return true;

                if (
                  data.use_in_checklist &&
                  (data.periodicity_type === '' ||
                    data.due_date_type === '' ||
                    data.date_type !== 2)
                )
                  return true;

                if (
                  data.use_in_checklist &&
                  data.periodicity_type !== 2 &&
                  data.due_date_type !== 5 &&
                  data.date_type === 2 &&
                  !value
                )
                  return false;

                return true;
              }
            ),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          if (data.specific_day_month) {
            data.specific_day_month = data.specific_day_month.replace('_', '');
          }

          if (data.specific_day) {
            data.specific_day = data.specific_day.replace('_', '');
          }

          if (!data.main_document) {
            data.main_document = 0;
          }

          if (id) {
            await api.put(`documents/${id}`, data, {
              params: {
                old_company_id: company.old_id,
              },
            });
          } else {
            await api.post('documents', data, {
              params: {
                company_id: company.id,
                mainid: user.old_id,
                old_company_id: company.old_id,
              },
            });
          }

          formRef.current.setErrors({});

          toast.success('Documento salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);

          history.push('/document');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar documento.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setSaveLoading(false);
        }
      }
    },
    [company, id, user]
  );

  const resetForm = useCallback(() => {
    formRef.current.reset();
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/document')}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleChangeDocumentType = useCallback(event => {
    setSelectedDocumentType({
      value: event.value,
      label: event.label,
    });
  }, []);

  const handleChangePeriodicityType = useCallback(event => {
    setSelectedPeriodicityType({
      value: event.value,
      label: event.label,
    });
  }, []);

  const handleChangeDueDateType = useCallback(event => {
    setSelectedDueDateType({
      value: event.value,
      label: event.label,
    });
  }, []);

  const handleChangeDateType = useCallback(event => {
    setSelectedDateType({
      value: event.value,
      label: event.label,
    });
  }, []);

  const handleChangeTerritorialScope = useCallback(event => {
    setSelectedTerritorialScope({
      value: event.value,
      label: event.label,
    });
  }, []);

  const handleChangeState = useCallback(event => {
    setSelectedState({
      value: event.value,
      label: event.label,
    });
  }, []);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaFileAlt size={20} color="#44546a" />
            <h1>Documentos</h1>
          </div>
        </Header>
        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {(loading || !company || !user || !companyUser || !folders) && (
          <FormLoading className="loading" />
        )}
        {document && folders && (
          <Content className="content">
            <FormContainer
              ref={formRef}
              loading={loading ? 1 : 0}
              onSubmit={handleSubmit}
              initialData={document}
            >
              <Document>
                <h4>DOCUMENTO</h4>
                <section className="first-section">
                  <Input
                    name="description"
                    className="description"
                    label="Descrição"
                    readOnly={!typeOfEdition}
                  />
                  <Input
                    type="text"
                    name="abbreviation"
                    className="abbreviation"
                    label="Abreviatura"
                    readOnly={!typeOfEdition}
                    maxLength="6"
                  />
                  <Select
                    name="folder"
                    className="folder"
                    label="Pasta"
                    placeholder="Selecione uma pasta"
                    options={folders}
                  />
                  <Select
                    name="document_type"
                    className="document_type"
                    label="Tipo"
                    placeholder="Selecione um tipo"
                    options={documentTypeOptions}
                    onChange={handleChangeDocumentType}
                    isDisabled={!typeOfEdition}
                  />
                </section>
                {(selectedDocumentType.value === 1 ||
                  selectedDocumentType.value === 2) && (
                  <section className="second-section">
                    <Select
                      name="territorial_scope"
                      className="territorial_scope"
                      label="Abrangência"
                      placeholder="Selecione uma abrangência"
                      options={territorialScopeOptions}
                      onChange={handleChangeTerritorialScope}
                      isDisabled={!typeOfEdition}
                    />

                    {(selectedTerritorialScope.value === 1 ||
                      selectedTerritorialScope.value === 2) && (
                      <Select
                        name="state"
                        className="state"
                        label="Estado"
                        placeholder="Selecione um estado"
                        options={states}
                        onChange={handleChangeState}
                        isDisabled={!typeOfEdition}
                      />
                    )}
                    {selectedState.value !== '' &&
                      selectedTerritorialScope.value === 2 && (
                        <Select
                          name="city"
                          className="city"
                          label="Cidade"
                          placeholder="Selecione uma cidade"
                          options={cities}
                          isDisabled={!typeOfEdition}
                        />
                      )}
                  </section>
                )}

                <section className="third-section">
                  <Select
                    name="periodicity_type"
                    className="periodicity_type"
                    label="Periodicidade"
                    placeholder="Selecione uma periodicidade"
                    options={periodicityTypeOptions}
                    onChange={handleChangePeriodicityType}
                    isDisabled={!typeOfEdition}
                  />
                  <Select
                    name="due_date_type"
                    className="due_date_type"
                    label="Vencimento"
                    placeholder="Selecione um vencimento"
                    options={dueDateTypeOptions}
                    onChange={handleChangeDueDateType}
                    isDisabled={!typeOfEdition}
                  />
                  {(selectedPeriodicityType.value === 1 ||
                    selectedPeriodicityType.value === 3) &&
                    selectedDueDateType.value !== 5 &&
                    selectedDueDateType.value !== '' && (
                      <Select
                        name="date_type"
                        className="date_type"
                        label="Data"
                        placeholder="Selecione uma data"
                        options={dateTypeOptions}
                        onChange={handleChangeDateType}
                        isDisabled={!typeOfEdition}
                      />
                    )}
                  {(selectedPeriodicityType.value === 1 ||
                    selectedPeriodicityType.value === 3) &&
                    selectedDueDateType.value !== 5 &&
                    selectedDateType.value !== '' && (
                      <>
                        {selectedDateType.value === 1 ? (
                          <InputMask
                            name="specific_day"
                            className="specific_day"
                            label="Dia específico"
                            mask="99"
                            type="text"
                            readOnly={!typeOfEdition}
                          />
                        ) : (
                          <Select
                            name="date_calculation"
                            className="date_calculation"
                            label="Cálculo"
                            placeholder="Selecione um cálculo"
                            options={dateCalculationOptions}
                            isDisabled={!typeOfEdition}
                          />
                        )}
                      </>
                    )}
                  {selectedPeriodicityType.value === 2 &&
                    selectedDueDateType.value !== 5 && (
                      <InputMask
                        name="specific_day_month"
                        className="specific_day_month"
                        label="Dia/Mês"
                        mask="99/99"
                        type="text"
                        readOnly={!typeOfEdition}
                      />
                    )}
                </section>
                <section className="fourth-section">
                  <DatePicker
                    name="start_date"
                    className="start_date"
                    label="Início"
                    disabled={!typeOfEdition}
                  />
                  {(selectedDocumentType.value === 1 ||
                    selectedDocumentType.value === 2) && (
                    <DatePicker
                      name="end_date"
                      className="end_date"
                      label="Fim"
                      disabled={!typeOfEdition}
                    />
                  )}
                  {selectedDocumentType.value !== 0 &&
                    selectedDocumentType.value !== '' &&
                    selectedPeriodicityType.value !== 3 &&
                    selectedPeriodicityType.value !== '' && (
                      <Input
                        name="deadline_days"
                        className="deadline_days"
                        label="Prazo (dias)"
                        type="text"
                        readOnly={!typeOfEdition}
                      />
                    )}
                </section>
                <section className="fifth-section">
                  {(selectedDocumentType.value === 1 ||
                    selectedDocumentType.value === 2) && (
                    <Checkbox
                      id="title_to_pay"
                      name="title_to_pay"
                      className="title_to_pay"
                      label="Título a pagar"
                      readOnly={!typeOfEdition}
                    />
                  )}
                  {showMainDocument && (
                    <Checkbox
                      id="main_document"
                      name="main_document"
                      className="main_document"
                      label="Documento padrão do sistema"
                      readOnly={!typeOfEdition}
                    />
                  )}
                  {/* {typeOfEdition === true && (
                    <Checkbox
                      id="main_document"
                      name="main_document"
                      className="main_document"
                      label="Documento padrão do sistema"
                      readOnly={!typeOfEdition}
                    />
                  )} */}
                  <Checkbox
                    id="use_in_checklist"
                    name="use_in_checklist"
                    className="use_in_checklist"
                    label="Usar em checklist"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="allow_manual_mark_on_checklist"
                    name="allow_manual_mark_on_checklist"
                    className="allow_manual_mark_on_checklist"
                    label="Em Checklists, permitir marcação manual"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="auto_upload"
                    name="auto_upload"
                    className="auto_upload"
                    label="Upload automático pelo Nubo"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="show_in_labour_checklist_if_has_employee"
                    name="show_in_labour_checklist_if_has_employee"
                    className="show_in_labour_checklist_if_has_employee"
                    label="Exibir no Checklist Trabalhisa somente se a empresa tiver funcionário"
                    readOnly={!typeOfEdition}
                  />
                  {selectedPeriodicityType.value === 2 && (
                    <Checkbox
                      id="skip_charge_inactive_clients"
                      name="skip_charge_inactive_clients"
                      className="skip_charge_inactive_clients"
                      label="Não cobrar documento de clientes inativos (SOMENTE DOCUMENTO ANUAL)"
                      readOnly={!typeOfEdition}
                    />
                  )}
                </section>
              </Document>
              <Department>
                <h4>DEPARTAMENTOS</h4>
                <section>
                  <Checkbox
                    id="dpt_labour"
                    name="dpt_labour"
                    className="dpt_labour"
                    label="Trabalhista"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="dpt_tax"
                    name="dpt_tax"
                    className="dpt_tax"
                    label="Tributário"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="dpt_accounting"
                    name="dpt_accounting"
                    className="dpt_accounting"
                    label="Contábil"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="dpt_financial"
                    name="dpt_financial"
                    className="dpt_financial"
                    label="Financeiro"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="dpt_admin"
                    name="dpt_admin"
                    className="dpt_admin"
                    label="Administração"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="dpt_bpo"
                    name="dpt_bpo"
                    className="dpt_bpo"
                    label="BPO"
                    readOnly={!typeOfEdition}
                  />
                </section>
              </Department>
              <Taxation>
                <h4>TRIBUTAÇÃO</h4>
                <section>
                  <Checkbox
                    id="taxation_empregador_pf"
                    name="taxation_empregador_pf"
                    className="taxation_empregador_pf"
                    label="Empregador PF"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="taxation_empregador_domestico"
                    name="taxation_empregador_domestico"
                    className="taxation_empregador_domestico"
                    label="Empregador Doméstico"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="taxation_mei"
                    name="taxation_mei"
                    className="taxation_mei"
                    label="MEI"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="taxation_mei_somente_declaracoes"
                    name="taxation_mei_somente_declaracoes"
                    className="taxation_mei_somente_declaracoes"
                    label="MEI Somente Declarações"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="taxation_simples_com_inscricao"
                    name="taxation_simples_com_inscricao"
                    className="taxation_simples_com_inscricao"
                    label="Simples Nacional C/ Inscrição"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="taxation_simples_sem_inscricao"
                    name="taxation_simples_sem_inscricao"
                    className="taxation_simples_sem_inscricao"
                    label="Simples Nacional S/ Inscrição"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="taxation_lucro_presumido"
                    name="taxation_lucro_presumido"
                    className="taxation_lucro_presumido"
                    label="Lucro Presumido"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="taxation_lucro_real"
                    name="taxation_lucro_real"
                    className="taxation_lucro_real"
                    label="Lucro Real"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="taxation_condominio"
                    name="taxation_condominio"
                    className="taxation_condominio"
                    label="Condomínio"
                    readOnly={!typeOfEdition}
                  />
                  <Checkbox
                    id="taxation_terceiro"
                    name="taxation_terceiro"
                    className="taxation_terceiro"
                    label="Terceiro Setor"
                    readOnly={!typeOfEdition}
                  />
                </section>
              </Taxation>
            </FormContainer>
          </Content>
        )}
      </Container>

      {saveLoading && <Loading />}
    </>
  );
};

export default Form;

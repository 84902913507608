import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaHatWizard,
  FaTimes,
  FaRegCircle,
  FaRegCheckCircle,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import Modal from '~/components/Modal';
import { TableLoading, TableContainer } from '~/components/Table';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import { Container, Header, Content, ActiveButton } from './styles';

const AutomatedRoutines = ({ isOpen, setIsOpen }) => {
  const { company } = useAuth();

  const [loading, setLoading] = useState(false);
  const [executeRoutineLoading, setExecuteRoutineLoading] = useState(false);
  const [activeRoutineLoading, setActiveRoutineLoading] = useState(false);

  const [routines, setRoutines] = useState([]);

  useEffect(() => {
    async function loadRoutines() {
      if (company) {
        try {
          setLoading(true);

          const response = await api.get(`automated-routines`, {
            params: {
              company_id: company.id,
            },
          });

          if (response.data) {
            const formattedRoutines = response.data.map(routine => ({
              ...routine,
              formatted_last_execution: routine.last_execution
                ? format(
                    parseISO(routine.last_execution),
                    "dd/MM/yyyy 'às' HH:mm"
                  )
                : null,
            }));

            setRoutines(formattedRoutines);
          } else {
            toast.warn('Nenhuma rotina foi encontrada.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setLoading(false);
        } catch {
          toast.error('Falha ao buscar rotinas.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setLoading(false);
        }
      }
    }

    loadRoutines();
  }, [company]);

  const handleExecuteRoutine = useCallback(
    async (id, routine) => {
      if (company) {
        try {
          setExecuteRoutineLoading(true);

          await api.get('execute-job', {
            params: {
              job: routine,
              company_id: company.id,
            },
          });

          setRoutines(
            routines.map(item =>
              item.id === id
                ? {
                    ...item,
                    last_execution: new Date(),
                    formatted_last_execution: format(
                      new Date(),
                      "dd/MM/yyyy 'às' HH:mm"
                    ),
                  }
                : item
            )
          );

          toast.success('Rotina executada com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setExecuteRoutineLoading(false);
        } catch {
          toast.error('Falha ao executar rotina.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setExecuteRoutineLoading(false);
        }
      }
    },
    [company, routines]
  );

  const handleActiveRoutine = useCallback(
    async (id, active) => {
      try {
        setActiveRoutineLoading(true);

        await api.put(`automated-routines/${id}`, { active });

        setRoutines(
          routines.map(item =>
            item.id === id
              ? {
                  ...item,
                  active: !active,
                }
              : item
          )
        );

        if (active === true) {
          toast.success('Rotina desativada com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.success('Rotina ativada com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setActiveRoutineLoading(false);
      } catch {
        if (active === true) {
          toast.error('Falha ao desativar rotina.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error('Falha ao ativar rotina.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setActiveRoutineLoading(false);
      }
    },
    [routines]
  );

  const confirmActiveRoutine = useCallback(
    (id, active) => {
      if (active === true) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ConfirmWindow
                onClick={() => handleActiveRoutine(id, active)}
                onClose={onClose}
                message="Isto irá desativar esta rotina."
              />
            );
          },
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      } else {
        handleActiveRoutine(id, active);
      }
    },
    [handleActiveRoutine]
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaHatWizard size={20} color="#44546a" />
            <h1>Rotinas Automatizadas</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>
        {loading || !company ? (
          <TableLoading />
        ) : (
          <Content className="content">
            <TableContainer>
              <thead>
                <tr>
                  <th className="active">Ativado</th>
                  <th className="routine">Rotina</th>
                  <th className="last_execution">Última execução</th>
                  <th className="execute" />
                </tr>
              </thead>
              <tbody>
                {routines.map(routine => (
                  <tr key={String(routine.id)}>
                    <td className="active">
                      <ActiveButton
                        active={routine.active ? 1 : 0}
                        onClick={() =>
                          confirmActiveRoutine(routine.id, routine.active)
                        }
                      >
                        {routine.active ? (
                          <FaRegCheckCircle size={16} color="#00c853" />
                        ) : (
                          <FaRegCircle size={16} color="#ee4256" />
                        )}
                      </ActiveButton>
                    </td>
                    <td className="routine">{routine.routine_label}</td>
                    <td className="last_execution">
                      {routine.formatted_last_execution}
                    </td>
                    <td className="execute">
                      <button
                        type="button"
                        onClick={() =>
                          handleExecuteRoutine(routine.id, routine.routine)
                        }
                      >
                        Executar agora
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          </Content>
        )}
      </Container>
      {(executeRoutineLoading || activeRoutineLoading) && <Loading />}
    </Modal>
  );
};

export default AutomatedRoutines;

AutomatedRoutines.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

import base64 from 'base-64';

const redirectToOldPlatform = (user, companyOldId, link) => {
  window.open(
    `http://diretiva1.com.br/?login=true&mainid=${base64.encode(
      user.old_id
    )}&nick=${base64.encode(user.nick)}&email=${base64.encode(
      user.email
    )}&company=${base64.encode(companyOldId)}&app=${base64.encode(link)}`,
    '_blank',
    'noopener noreferrer'
  );
};

export default redirectToOldPlatform;

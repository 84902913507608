import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background: #fafafa;
  opacity: ${props => (props.readOnly ? '0.4' : '1')};
  pointer-events: ${props => (props.readOnly ? 'none' : 'all')};

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;

  > label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  > input {
    margin-right: 16px;
    transform: scale(1.5);
  }

  > span {
    position: absolute;
    top: 45px;
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
  }

  > label,
  input {
    &:hover {
      cursor: pointer;
    }
  }
`;

import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #c7c7c7;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  h4 {
    margin-bottom: 16px;
    color: #44546a;
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    > div {
      & + div {
        margin-left: 16px;
      }
    }
  }

  .loading {
    position: absolute;
    z-index: 2;
  }

  .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
  }
`;

export const Header = styled.header`
  color: #44546a;
  background: rgba(230, 230, 230, 0.95);
  padding: 5px 10px;
  border-bottom: 1px solid #c7c7c7;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid #c7c7c7;

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      margin-bottom: 3px;
    }

    span {
      font-size: 12px;
      color: #44546a;
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid #c7c7c7;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .user {
    width: 100%;
  }

  > div + div {
    margin-left: 16px;
  }

  > button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: #44546a;
      height: 20px;
      width: 20px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    .active {
      padding-left: 8px;

      width: 5%;
      text-align: center;
    }

    .message {
      width: 50%;
      max-width: 0;
    }

    .recipient,
    .frequency,
    .frequency_type {
      width: 15%;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;

  section {
    > div {
      display: flex;
      flex-direction: column;
    }
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid #ddd;
  }

  textarea {
    resize: unset;
    background: transparent;
    border: 0px;
    border-bottom: 1px solid #ddd;
    margin-top: 8px;
  }
`;

export const SchedulerInfo = styled.div`
  display: flex;
  flex-direction: column;

  .private,
  .active {
    width: 10%;
  }

  .start_date,
  .start_hour,
  .priority,
  .type,
  .deadline,
  .deadline_type,
  .frequency,
  .frequency_type,
  .monitor {
    width: 25%;
  }

  .deadline_type,
  .frequency_type {
    padding-top: 13px;
  }

  .recipients {
    width: 100%;

    .react-select__value-container {
      flex-wrap: unset;

      .react-select__multi-value {
        margin: 0;
        min-width: unset;
        margin-bottom: 6px;

        & + div {
          margin-left: 3px;
        }

        .react-select__multi-value__label {
          padding: 3px;
          text-overflow: unset;
          font-size: 12px;
        }

        /* .react-select__multi-value__remove {
          display: none;
        } */
      }
    }

    .react-select__option {
      display: flex;
      align-items: center;

      label {
        margin-left: 3px;
      }
    }
  }

  .recipients_details {
    width: 100%;

    > div {
      padding-top: 10px;
      padding-bottom: 5px;
      flex-direction: row;
      align-items: flex-end;
      border-bottom: 1px solid #ddd;
      flex-wrap: wrap;

      > span {
        color: ${({ theme }) => theme.colors.grey};
        padding: 3px;
        font-size: 12px;
        border-radius: 2px;
        /* margin-bottom: 3px; */
        background: #e6e6e6;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 3px;
      }
    }
  }

  .message,
  .sender {
    width: 100%;
  }

  .checkbox {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    > input {
      margin-right: 16px;
      transform: scale(1.5);
    }
  }
`;

export const Logs = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .log {
    width: 100%;

    > input {
      padding-top: 0px;
    }
  }
`;

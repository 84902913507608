const formatDocument = (documentType, document) => {
  if (document !== '') {
    if (documentType === null) {
      return `${document.slice(0, 2)}.${document.slice(2, 9)}.${document.slice(
        9,
        11
      )}-${document.slice(11, 13)}`;
    }
    if (documentType === 1) {
      return `${document.slice(0, 3)}.${document.slice(3, 6)}.${document.slice(
        6,
        9
      )}-${document.slice(9, 11)}`;
    }
    if (documentType === 2) {
      return `${document.slice(0, 2)}.${document.slice(2, 5)}.${document.slice(
        5,
        8
      )}/${document.slice(8, 12)}-${document.slice(12, 14)}
      `;
    }
  }
  return '';
};

export default formatDocument;

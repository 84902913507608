import React, { useRef, useState } from 'react';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

import * as Yup from 'yup';
import ClearBackground from '~/components/ClearBackground';
import { Input, InputMask } from '~/components/Form';
import { ButtonSpinner } from '~/components/Spinner';
import api from '~/services/api';

import {
  Container,
  Content,
  Left,
  Right,
  Header,
  FormContainer,
} from './styles';

const EditClientCosts = ({ onClose, costsClient, loadCostsClient }) => {
  const formRef = useRef(null);

  const initialData = {
    honorary: costsClient.honorary,
    limit_employee: costsClient.limit_employee,
    tributary_time: costsClient.tributaryTimeFormatted,
    accounting_time: costsClient.accountingTimeFormatted,
    extra_time: costsClient.extraTimeFormatted,
  };

  const [loading, setLoading] = useState(false);

  const submitForm = async data => {
    try {
      setLoading(true);

      const schema = Yup.object().shape({
        honorary: Yup.number().typeError('O honorário é obrigatório'),
        limit_employee: Yup.number().typeError(
          'O limite de trabalhador é obrigatório'
        ),
        tributary_time: Yup.string().required('O tempo é obrigatório'),
        accounting_time: Yup.string().required('O tempo é obrigatório'),
        extra_time: Yup.string().required('O tempo é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const auxTributaryTime = data.tributary_time.split(':');
      const auxAccountingTime = data.accounting_time.split(':');
      const auxExtraTime = data.extra_time.split(':');

      const tributaryTimeInMinutes =
        auxTributaryTime[0] * 60 + auxTributaryTime[1] * 1;
      const accountingTimeInMinutes =
        auxAccountingTime[0] * 60 + auxAccountingTime[1] * 1;
      const extraTimeInMinutes = auxExtraTime[0] * 60 + auxExtraTime[1] * 1;

      if (
        isNaN(tributaryTimeInMinutes) ||
        isNaN(accountingTimeInMinutes) ||
        isNaN(extraTimeInMinutes)
      ) {
        throw Error('Dados de tempo inválidos.');
      }

      data.tributary_time = tributaryTimeInMinutes;
      data.accounting_time = accountingTimeInMinutes;
      data.extra_time = extraTimeInMinutes;

      await api.put(`/costs/client/${costsClient.id}`, data);

      setLoading(false);
      toast.success('Alterações realizadas com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      loadCostsClient();
      onClose();
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  return (
    <>
      <ClearBackground />
      <Container>
        <Content>
          <Left>
            <FaEdit size={38} color="#01579b" />
          </Left>
          <Right>
            <Header>
              <FaTimes onClick={onClose} size={14} color="#e53935" />
              <strong>Editar medidas da empresa {costsClient.client}:</strong>
            </Header>

            <FormContainer
              ref={formRef}
              onSubmit={submitForm}
              initialData={initialData}
            >
              <Input
                name="honorary"
                label="Honorário"
                type="number"
                step="0.01"
              />
              <Input
                name="limit_employee"
                label="Limite de trabalhadores"
                type="number"
              />
              <InputMask
                name="tributary_time"
                label="Tempo tributário"
                mask="99:99"
              />
              <InputMask
                name="accounting_time"
                label="Tempo contábil"
                mask="99:99"
              />
              <InputMask name="extra_time" label="Tempo extra" mask="99:99" />

              <button type="submit" disabled={loading}>
                {loading ? <ButtonSpinner /> : 'Atualizar'}
              </button>
            </FormContainer>
          </Right>
        </Content>
      </Container>
    </>
  );
};

export default EditClientCosts;

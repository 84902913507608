import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  margin: 0;
  padding: 0;

  @media print {
    display: flex;
    flex-direction: column;
  }
`;

export const Client = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 16px;
  }

  span {
    margin-left: 20px;
  }

  div + & {
    margin-top: 20px;
  }
`;

import styled, { css } from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      margin-top: 8px;
    }

    div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }

    a {
      margin-left: 10px;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  h1 {
    margin-top: 1rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.blue};
  }

  table {
    position: relative;
    width: 100%;

    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      text-align: center;
      top: 0;
      padding: 5px;

      &:nth-child(1) {
        left: 0px;
        z-index: 2;
        width: 30px;
        min-width: 30px;
        text-align: left;
        padding-left: 8px;
      }

      &:nth-child(2) {
        left: 30px;
        z-index: 2;
        width: 140px;
        min-width: 140px;
        text-align: left;
        padding-left: 8px;
      }
    }

    tbody {
      tr {
        &:nth-child(2n + 1) {
          background: #fafafa;

          th {
            background: #fafafa;
          }
        }

        &:nth-child(2n + 2) {
          background: #f4f4f4;

          th {
            background: #f4f4f4;
          }
        }
      }

      th {
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 5px;
        color: ${({ theme }) => theme.colors.grey};

        &:nth-child(1) {
          position: sticky;
          left: 0px;
          z-index: 1;
          width: 30px;
          min-width: 30px;
          text-align: left;
          max-width: 0;
          padding-left: 8px;
        }

        &:nth-child(2) {
          position: sticky;
          left: 30px;
          z-index: 1;
          width: 140px;
          min-width: 140px;
          text-align: left;
          max-width: 0;
          padding-left: 8px;
        }
      }

      td {
        cursor: pointer;
        padding: 5px;
      }
    }
  }
`;

export const SituationButton = styled.div`
  border: 2px solid ${props => props.color || '#01579b'};
  border-radius: 50%;
  width: 15px;
  height: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;

  > div + div {
    margin-left: 16px;
  }

  .user,
  .view {
    width: 20%;
  }

  .all-users {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 8px;
    justify-content: center;
    align-items: center;

    input {
      margin: 0;
    }
  }

  .buttons {
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border: 0;
      background: transparent;
      margin: 0 16px 0 16px;
      transition: opacity 0.2s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      svg {
        color: ${({ theme }) => theme.colors.primary};
        height: 20px;
        width: 20px;
      }

      span {
        color: #545454;
        font-size: 12px;
        opacity: 0.7;
      }
    }
  }

  > button {
    display: flex;
    flex-direction: row;

    border: 0;
    background: transparent;
    margin: 0 16px 0 16px;
    transition: opacity 0.2s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;

  section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .documents-title {
    display: flex;
    flex-direction: row;
    margin: 20px 0 16px;

    h4 {
      margin: 0px;
    }
    svg {
      margin-left: 8px;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .sector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .description {
    margin-top: 20px;
  }

  .title {
    width: 45%;
  }

  .user {
    width: 20%;
  }

  .document {
    width: 22%;
    min-width: 265px;
  }

  .date {
    width: 95px;
  }

  .responsible {
    width: 15%;
  }

  .file {
    width: 20%;
  }

  .type {
    width: 35%;
  }

  .days {
    width: 140px;
  }

  .checkbox {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    min-width: 5%;

    > input {
      margin-right: 16px;
      transform: scale(1.5);
    }
  }

  .view-feedbacks {
    width: 20px;
    opacity: 1;
    transition: opacity 0.3s;
    height: 100%;
    justify-content: flex-end;
    padding-bottom: 5px;

    button {
      display: flex;
      align-items: flex-end;

      border: 0;
      background: transparent;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .file {
    display: flex;
    flex-direction: row;
    align-items: center;

    > aside {
      display: flex;
      flex-direction: column;

      button {
        background: none;
        color: ${({ theme }) => theme.colors.blue};
        padding: 2px;
        transition: color 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: ${({ theme }) => theme.hover.blue};
        }
      }
    }

    > div {
      flex: 1;

      margin-left: 8px;
    }
  }
`;

export const Subtitles = styled.legend`
  display: flex;
  justify-content: flex-end;

  padding: 3px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 10px;
  }

  aside {
    display: flex;

    div {
      display: flex;
      align-items: center;

      & + div {
        margin-left: 8px;
      }

      span {
        margin-left: 3px;
        font-size: 12px;
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const DocumentTd = styled.td`
  min-width: 50px;
  text-align: center !important;
  overflow: visible !important;
  white-space: unset !important;

  ${props =>
    (props.situation === 1 || props.situation === 3 || props.situation === 4) &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  > div {
    position: relative;
  }
`;

export const Feedback = styled(Form)`
  margin: 16px 0;

  display: flex;
  flex-direction: column;

  section {
    align-items: flex-end;
  }
`;

export const FeedbackItem = styled.section`
  margin-top: 16px;
  position: relative;

  display: flex;

  div {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.grey};
      opacity: 0.7;
    }

    input {
      height: 30px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      background: transparent;
    }

    textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      margin-top: 8px;
    }

    button {
      background: none;
      margin-right: 8px;
      padding: 2px;
      transition: color 0.2s;
      color: ${({ theme }) => theme.colors.error};

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: ${({ theme }) => theme.hover.error};
      }
    }
  }

  div + div {
    margin-left: 16px;
  }

  .delete {
    height: 40px;
    padding-top: 20px;

    display: flex;
    justify-content: center;
  }

  .date {
    margin-left: 0;
    width: 15%;
  }

  .user {
    width: 15%;
  }

  .file {
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: center;

    > aside {
      display: flex;
      flex-direction: column;

      button {
        background: none;
        color: ${({ theme }) => theme.colors.blue};
        padding: 2px;
        transition: color 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: ${({ theme }) => theme.hover.blue};
        }
      }
    }

    > div {
      flex: 1;

      margin-left: 8px;
    }
  }

  .content {
    width: 40%;
  }
`;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  FaChevronLeft,
  FaChevronRight,
  FaListAlt,
  FaSearch,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTimes,
  FaTrophy,
  FaEye,
  FaEdit,
  FaTrash,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import { addMonths, format, subMonths, parseISO } from 'date-fns';

import { confirmAlert } from 'react-confirm-alert';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import PermissionComponent from '~/components/PermissionComponent';
import { TableContainer, TableLoading } from '~/components/Table';
import { Select } from '~/components/Form';
import Loading from '~/components/Loading';

import ResumeModal from './ResumeModal';
import ScheduleModal from './ScheduleModal';
import SchedulesModal from './SchedulesModal';

import { useAuth } from '~/hooks';
import api from '~/services/api';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  LineStyle,
} from './styles';
import ConfirmWindow from '~/components/ConfirmWindow';
import EditNoteModal from './EditNoteModal';
import KpiFeedbackModal from './KpiFeedbackModal';

export const KPI = () => {
  const { user, company, companyUser, companyUsers } = useAuth();

  const filterRef = useRef();

  const [loading, setLoading] = useState(false);
  const [kpiLoading, setKpiLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);

  const [period, setPeriod] = useState(null);
  const [formattedPeriod, setFormattedPeriod] = useState(null);

  const [lastReleasedPeriod, seLastReleasedPeriod] = useState(null);
  const getPeriod = useCallback(async () => {
    try {
      const response = await api.get(`/kpi/released/${company.id}`);

      const { competence_date } = response.data;

      const parseCompetence = parseISO(competence_date);

      if (companyUser.level === 9) {
        setPeriod(addMonths(parseCompetence, 1));
        setFormattedPeriod(format(addMonths(parseCompetence, 1), 'MM/yyyy'));
      } else {
        setPeriod(parseCompetence);
        setFormattedPeriod(format(parseCompetence, 'MM/yyyy'));
      }
      seLastReleasedPeriod(parseCompetence);
    } catch (err) {
      toast.error('Falha ao mostrar KPIs.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [company, companyUser]);

  useEffect(() => {
    if (company && companyUser) {
      getPeriod();
    }
  }, [company, companyUser, getPeriod]);

  const [resumeIsOpen, setResumeIsOpen] = useState(false);
  const [modalType, setModalType] = useState(1);
  const resume = useRef([]);

  const [scheduleModalIsOpen, setScheduleModalIsOpen] = useState(false);
  const [schedulesModalIsOpen, setSchedulesModalIsOpen] = useState(false);
  const [schedulesModalType, setSchedulesModalType] = useState(null);
  const schedule = useRef([]);
  const schedulesModalInfo = useRef([]);

  const [editNoteModalIsOpen, setEditNoteModalIsOpen] = useState(false);
  const kpiToEdit = useRef(null);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [kpis, setKpis] = useState([]);
  const [schedulesInfo, setSchedulesInfo] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const usersOptions = useMemo(() => {
    if (companyUsers && user && companyUser) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .filter(userItem => userItem.hasKpi === true)
        .map(userItem => {
          let g;
          let b;

          const r = Math.floor(Math.random() * 110) + 75;

          do {
            g = Math.floor(Math.random() * 110) + 75;
          } while (g === r);

          do {
            b = Math.floor(Math.random() * 110) + 75;
          } while (b === g || b === r);

          let color = r.toString(16);
          color += g.toString(16);
          color += b.toString(16);
          color = `#${color}`;

          return {
            value: userItem.user_id,
            label: userItem.short_name,
            color,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
        color: '#01579B',
      });

      if (companyUser.level === 9) {
        setSelectedUser({
          value: '',
          label: 'Todos',
        });
        setModalType(0);
      } else {
        setSelectedUser({
          value: user.id,
          label: user.short_name,
          color: '#01579B',
        });
      }

      options.push({
        value: '',
        label: 'Todos',
      });

      return options;
    }
    return [];
  }, [user, companyUser, companyUsers]);

  useEffect(() => {
    async function loadKPIs() {
      try {
        setKpiLoading(true);
        const response = await api.get('/kpi', {
          params: {
            user_id: selectedUser.value,
            company_id: company.id,
            period,
            direction: sortConfig.direction,
          },
        });

        const { list, schedules, concluded, late, unconcluded } = response.data;

        if (list.length > 0) {
          setKpis(list);
        } else {
          setKpis([]);
          toast.warn('Nenhum KPI foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        if (selectedUser.value === '') {
          setSchedulesInfo(() => {
            const infos = usersOptions.map(item => {
              if (item.value !== '') {
                const schedulesQuantity = schedules.find(
                  scheduleItem => scheduleItem.recipient_id === item.value
                )
                  ? schedules.find(
                      scheduleItem => scheduleItem.recipient_id === item.value
                    ).quant
                  : 0;

                const concludedQuantity = concluded.find(
                  concludedItem => concludedItem.recipient_id === item.value
                )
                  ? concluded.find(
                      concludedItem => concludedItem.recipient_id === item.value
                    ).quant
                  : 0;

                const lateQuantity = late.find(
                  lateItem => lateItem.recipient_id === item.value
                )
                  ? late.find(lateItem => lateItem.recipient_id === item.value)
                      .quant
                  : 0;

                const unconcludedQuantity = unconcluded.find(
                  unconcludedItem => unconcludedItem.recipient_id === item.value
                )
                  ? unconcluded.find(
                      unconcludedItem =>
                        unconcludedItem.recipient_id === item.value
                    ).quant
                  : 0;

                const scheduleInfoAux = {
                  userId: item.value,
                  userName: item.label,
                  schedules: schedulesQuantity,
                  concluded: concludedQuantity,
                  late: lateQuantity,
                  unconcluded: unconcludedQuantity,
                  percent: schedulesQuantity
                    ? Math.round(
                        ((concludedQuantity * 100) / schedulesQuantity) * 100
                      ) / 100
                    : 0,
                };

                return scheduleInfoAux;
              }
              return null;
            });

            const indexOfNull = infos.findIndex(info => info === null);
            infos.splice(indexOfNull, 1);

            infos.sort((a, b) => {
              if (a.percent < b.percent) {
                return 1;
              }
              if (a.percent > b.percent) {
                return -1;
              }
              return 0;
            });

            return infos;
          });
        } else {
          setSchedulesInfo([
            {
              userId: selectedUser.value,
              userName: usersOptions.find(
                userOption => userOption.value === selectedUser.value
              ).label,
              schedules: schedules[0] ? schedules[0].quant : 0,
              concluded: concluded[0] ? concluded[0].quant : 0,
              late: late[0] ? late[0].quant : 0,
              unconcluded: unconcluded[0] ? unconcluded[0].quant : 0,
              percent: schedules[0]
                ? Math.round(
                    (((concluded[0] ? concluded[0].quant : 0) * 100) /
                      schedules[0].quant) *
                      100
                  ) / 100
                : 0,
            },
          ]);
        }

        setKpiLoading(false);
      } catch {
        setKpiLoading(false);
        toast.error('Falha ao buscar KPIs.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    if (period && company) {
      loadKPIs();
    }
  }, [company, period, selectedUser, sortConfig, usersOptions]);

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    async function loadResults() {
      try {
        const response = await api.get(`/kpi/results/${company.id}`, {
          params: {
            competence: lastReleasedPeriod,
            user_id: selectedUser.value,
          },
        });

        setChartData(response.data);
      } catch (e) {
        toast.error('Falha ao buscar resultados anteriores.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    if (company && lastReleasedPeriod) {
      loadResults();
    }
  }, [company, selectedUser, lastReleasedPeriod]);

  const handleFilter = useCallback(
    data => {
      if (data.user === '') {
        setModalType(0);
      } else {
        setModalType(1);
      }
      setSelectedUser(usersOptions.find(item => item.value === data.user));
    },
    [usersOptions]
  );

  const handlePrevPeriod = () => {
    setFormattedPeriod(format(subMonths(period, 1), 'MM/yyyy'));
    setPeriod(subMonths(period, 1));
  };

  const handleNextPeriod = () => {
    const nextPeriod = addMonths(period, 1);
    const currentPeriod = subMonths(new Date(), 1);
    const nextReleasedPeriod = addMonths(lastReleasedPeriod, 1);

    if (nextPeriod > currentPeriod && companyUser.level < 9) {
      return;
    }

    if (nextPeriod > nextReleasedPeriod) {
      return;
    }

    setFormattedPeriod(format(nextPeriod, 'MM/yyyy'));
    setPeriod(nextPeriod);
  };

  const sort = useCallback(
    key => {
      let direction = 'asc';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'asc'
      ) {
        direction = 'desc';
      }
      const element = document.getElementById(key);
      element.classList.add(direction);
      setSortConfig({ key, direction });
    },
    [sortConfig]
  );

  const openModalResume = async () => {
    if (selectedUser) {
      try {
        setModalLoading(true);

        const userId = modalType === 0 ? 0 : selectedUser.value;

        resume.current = [];

        const response = await api.get(`/KPI/${userId}`, {
          params: {
            company_id: company.id,
            period,
          },
        });

        const { data } = response;

        if (modalType === 1) {
          let { count, note } = data.resumeKpis[0];
          const quantSchedules = data.schedules[0]?.quant || 0;
          const quantConcluded = data.concluded[0]?.quant || 0;
          const kpiFeedbackNote = data.kpiFeedback?.note;

          count = count || 0;
          note = note || 0;

          count =
            !isNaN(kpiFeedbackNote) && period > lastReleasedPeriod
              ? count + 1
              : count;
          note =
            !isNaN(kpiFeedbackNote) && period > lastReleasedPeriod
              ? note + kpiFeedbackNote
              : note;

          const noteSchedule =
            quantSchedules > 0
              ? Math.round((quantConcluded / quantSchedules) * 500) / 100
              : 0;

          const average = (note + noteSchedule) / (count + 1);

          const percent = Math.round(average * 2000) / 100;

          resume.current = {
            count,
            note,
            noteSchedule,
            percent,
            userName: selectedUser.label,
            average,
          };
        } else {
          resume.current = usersOptions
            .filter(item => item.value !== '')
            .map(item => {
              const dataResumeKpis =
                data.resumeKpis.find(
                  resumeItem => resumeItem.user_id === item.value
                ) || {};
              const dataSchedules =
                data.schedules.find(
                  schedulesItem => schedulesItem.recipient_id === item.value
                ) || {};
              const dataConcluded =
                data.concluded.find(
                  concludedItem => concludedItem.recipient_id === item.value
                ) || {};
              const dataKpiFeedback =
                data.kpiFeedbacks.find(
                  feedbackItem => feedbackItem.user_id === item.value
                ) || {};

              let { count, note } = dataResumeKpis;
              const quantSchedules = dataSchedules?.quant || 0;
              const quantConcluded = dataConcluded?.quant || 0;
              const kpiFeedbackNote = dataKpiFeedback?.note;

              count = count || 0;
              note = note || 0;

              count =
                !isNaN(kpiFeedbackNote) && period > lastReleasedPeriod
                  ? count + 1
                  : count;
              note =
                !isNaN(kpiFeedbackNote) && period > lastReleasedPeriod
                  ? note + kpiFeedbackNote
                  : note;

              const noteSchedule =
                quantSchedules > 0
                  ? Math.round((quantConcluded / quantSchedules) * 500) / 100
                  : 0;

              const average = (note + noteSchedule) / (count + 1);

              const percent = Math.round(average * 2000) / 100;

              return {
                userName: item.label,
                percent,
              };
            });

          resume.current.sort((a, b) => {
            if (a.percent > b.percent) {
              return -1;
            }
            if (a.percent < b.percent) {
              return 1;
            }
            return 0;
          });
        }

        setModalLoading(false);
        setResumeIsOpen(true);
      } catch {
        toast.error('Falha ao buscar resumo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setModalLoading(false);
      }
    }
  };

  const openModalSchedule = async scheduleId => {
    try {
      setModalLoading(true);

      const response = await api.get(`/schedule/${scheduleId}`);
      const { data } = response;

      const feedbackFormatted = data.feedback.map(feedbackItem => ({
        ...feedbackItem,
        feedback_date_formatted: feedbackItem.feedback_date
          ? format(
              parseISO(feedbackItem.feedback_date),
              "dd/MM/yyyy 'às' HH:mm"
            )
          : null,
      }));

      data.feedback = feedbackFormatted;

      schedule.current = data;

      setModalLoading(false);
      setScheduleModalIsOpen(true);
    } catch {
      toast.error('Falha ao buscar KPI.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setModalLoading(false);
    }
  };

  const openModalSchedules = async (type, userId) => {
    try {
      setModalLoading(true);

      const response = await api.get(`/schedule/kpi/${userId}`, {
        params: {
          company_id: company.id,
          type,
          period,
        },
      });
      const { data } = response;

      if (data.length > 0) {
        const schedulesFormatted = data.map(scheduleItem => ({
          ...scheduleItem,
          deadline_date: scheduleItem.deadline_date
            ? format(
                parseISO(scheduleItem.deadline_date),
                "dd/MM/yyyy 'às' HH:mm"
              )
            : null,
          conclusion_date: scheduleItem.conclusion_date
            ? format(
                parseISO(scheduleItem.conclusion_date),
                "dd/MM/yyyy 'às' HH:mm"
              )
            : null,
        }));

        schedulesModalInfo.current = schedulesFormatted;
      } else {
        schedulesModalInfo.current = [];
      }

      setModalLoading(false);
      setSchedulesModalIsOpen(true);
    } catch {
      toast.error('Falha ao buscar compromissos.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setModalLoading(false);
    }
  };

  const handleDeleteKpi = useCallback(async id => {
    try {
      setLoading(true);

      await api.delete(`/kpi/${id}`);

      setKpis(oldKpis =>
        oldKpis.map(item => ({
          ...item,
          hasDeleted: item.id === id ? true : item.hasDeleted,
        }))
      );

      toast.success('KPI excluído com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error('Falha ao excluir KPI.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, []);

  const confirmDeleteKpi = useCallback(
    id => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => handleDeleteKpi(id)}
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleDeleteKpi]
  );

  const handleEditNote = useCallback(async note => {
    if (note !== kpiToEdit.current.note) {
      try {
        setLoading(true);

        await api.put(`/kpi/${kpiToEdit.current.id}`, { note });

        setKpis(oldKpis =>
          oldKpis.map(item => ({
            ...item,
            note: item.id === kpiToEdit.current.id ? note : item.note,
          }))
        );

        toast.success('Nota editada com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error('Falha ao editar nota.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, []);

  const [kpiFeedbackModalIsOpen, setKpiFeedbackModalIsOpen] = useState(false);
  const releaseKpi = useCallback(async () => {
    try {
      setKpiFeedbackModalIsOpen(false);
      setLoading(true);
      await api.get(`/kpi/release/${company.id}`, {
        params: {
          competence: period,
        },
      });

      getPeriod();
    } catch {
      toast.error('Falha ao liberar feedbacks.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setLoading(false);
    }
  }, [company, period, getPeriod]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaTrophy size={20} color="#44546a" />
            <h1>KPI</h1>
          </div>
          <aside>
            <Link to="/">
              <FaTimes size={20} color="#44546a" />
            </Link>
          </aside>
        </Header>

        <Controls>
          <nav>
            <button type="button" onClick={openModalResume}>
              <FaListAlt />
              <span>Resumo</span>
            </button>
          </nav>

          <aside>
            {period > lastReleasedPeriod && (
              <PermissionComponent level={9}>
                <button
                  className="feedbacks"
                  type="button"
                  onClick={() => setKpiFeedbackModalIsOpen(true)}
                >
                  <strong>Feedbacks</strong>
                  <div />
                </button>
              </PermissionComponent>
            )}

            <button type="button" onClick={handlePrevPeriod}>
              <FaChevronLeft />
            </button>
            <span>{formattedPeriod}</span>
            <button type="button" onClick={handleNextPeriod}>
              <FaChevronRight />
            </button>
          </aside>
        </Controls>

        <PermissionComponent level={9}>
          {selectedUser !== null && (
            <Filter ref={filterRef} onSubmit={handleFilter}>
              <Select
                label="Usuário"
                name="user"
                className="user"
                options={usersOptions}
                defaultValue={selectedUser}
              />

              <button type="submit">
                <FaSearch />
              </button>
            </Filter>
          )}
        </PermissionComponent>

        {kpiLoading ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {chartData && (
              <LineChart
                width={window.innerWidth - 90}
                height={300}
                data={chartData}
                margin={{
                  top: 16,
                  right: 16,
                  left: 16,
                  bottom: 16,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {selectedUser.value === '' ? (
                  usersOptions
                    .filter(item => item.value !== '')
                    .map((item, index) => (
                      <Line
                        key={index}
                        type="monotone"
                        dataKey={item.label}
                        stroke={item.color}
                      />
                    ))
                ) : (
                  <Line
                    type="monotone"
                    dataKey={selectedUser.label}
                    stroke={selectedUser.color}
                  />
                )}
              </LineChart>
            )}
            <h4>COMPROMISSOS DA AGENDA</h4>
            <LineStyle />
            <TableContainer>
              <thead>
                <tr>
                  <th className="user">Usuário</th>
                  <th className="schedule">Compromissos Atribuídos</th>
                  <th className="schedule">Compromissos Concluídos</th>
                  <th className="schedule">Compromissos Atrasados</th>
                  <th className="schedule">Compromissos Pendentes</th>
                  <th className="note">Aproveitamento</th>
                </tr>
              </thead>
              <tbody>
                {schedulesInfo.map((item, index) => {
                  if (item) {
                    return (
                      <tr key={index} className="hover">
                        <td className="user">{item.userName}</td>
                        <td className="schedule">{item.schedules}</td>
                        <td className="schedule">{item.concluded}</td>
                        <td className="schedule">
                          <div className="scheduleView">
                            <button
                              type="button"
                              onClick={() => {
                                setSchedulesModalType('late');
                                openModalSchedules('late', item.userId);
                              }}
                            >
                              <FaEye color="#01579B" />
                            </button>
                            <span>{item.late}</span>
                          </div>
                        </td>
                        <td className="schedule">
                          <div className="scheduleView">
                            <button
                              type="button"
                              onClick={() => {
                                setSchedulesModalType('unconcluded');
                                openModalSchedules('unconcluded', item.userId);
                              }}
                            >
                              <FaEye color="#01579B" />
                            </button>
                            <span>{item.unconcluded}</span>
                          </div>
                        </td>
                        <td className="note">
                          {(item.percent || 0).toFixed(2)}%
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </TableContainer>

            <h4>KPIs</h4>
            <LineStyle />
            <TableContainer>
              <thead>
                <tr>
                  <th />
                  <PermissionComponent level={9}>
                    <th />
                    <th />
                  </PermissionComponent>
                  <th className="user">Usuário</th>
                  <th className="note">Expectativa</th>
                  <th className="note">Nota</th>
                  <th
                    id="description"
                    className="description sort"
                    onClick={() => sort('description')}
                  >
                    <div>
                      Descrição
                      {sortConfig.key !== 'description' && <FaSort />}
                      {sortConfig.key === 'description' &&
                        sortConfig.direction === 'asc' && <FaSortUp />}
                      {sortConfig.key === 'description' &&
                        sortConfig.direction === 'desc' && <FaSortDown />}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {kpis.map((item, index) => (
                  <tr key={index} className="hover">
                    <td className="view">
                      <button
                        type="button"
                        onClick={() => openModalSchedule(item.schedule_id)}
                      >
                        <FaEye color="#01579B" />
                      </button>
                    </td>
                    <PermissionComponent level={9}>
                      <td className="view">
                        <button
                          type="button"
                          onClick={() => {
                            kpiToEdit.current = {
                              id: item.id,
                              note: item.note,
                            };
                            setEditNoteModalIsOpen(true);
                          }}
                        >
                          <FaEdit color="#44546a" />
                        </button>
                      </td>
                      {item.hasDeleted ? (
                        <td className="view">
                          <button type="button">
                            <FaTrash color="#44546a" />
                          </button>
                        </td>
                      ) : (
                        <td className="view">
                          <button
                            type="button"
                            onClick={() => confirmDeleteKpi(item.id)}
                          >
                            <FaTrash color="#E53935" />
                          </button>
                        </td>
                      )}
                    </PermissionComponent>
                    <td className="user">{item.user.short_name}</td>
                    <td className="note">5</td>
                    <td className="note">{item.note}</td>
                    <td className="description">{item.schedule.description}</td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          </Content>
        )}
      </Container>

      {modalLoading || loading ? (
        <Loading />
      ) : (
        <>
          {scheduleModalIsOpen && (
            <ScheduleModal
              isOpen={scheduleModalIsOpen}
              setIsOpen={() => setScheduleModalIsOpen(isOpen => !isOpen)}
              data={schedule.current}
            />
          )}
          {schedulesModalIsOpen && (
            <SchedulesModal
              isOpen={schedulesModalIsOpen}
              setIsOpen={() => setSchedulesModalIsOpen(isOpen => !isOpen)}
              data={schedulesModalInfo.current}
              type={schedulesModalType}
            />
          )}
          {resumeIsOpen && (
            <ResumeModal
              isOpen={resumeIsOpen}
              setIsOpen={() => setResumeIsOpen(isOpen => !isOpen)}
              data={resume.current}
              type={modalType}
              usersOptions={usersOptions}
            />
          )}
          {editNoteModalIsOpen && (
            <EditNoteModal
              isOpen={editNoteModalIsOpen}
              setIsOpen={() => setEditNoteModalIsOpen(isOpen => !isOpen)}
              oldNote={kpiToEdit.current.note}
              editNote={handleEditNote}
            />
          )}
          {kpiFeedbackModalIsOpen && (
            <KpiFeedbackModal
              isOpen={kpiFeedbackModalIsOpen}
              setIsOpen={() => setKpiFeedbackModalIsOpen(false)}
              company_id={company.id}
              competence={period}
              releaseKpi={releaseKpi}
            />
          )}
        </>
      )}
    </>
  );
};

import React, { useRef, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { FormContainer, InputMask } from '~/components/Form';
import { ButtonSpinner } from '~/components/Spinner';

import { useProcessAuth } from '~/hooks';
import history from '~/services/history';

import logo from '~/assets/building.png';

import { Container } from './styles';

const Signin = () => {
  const { signIn, processModel, company } = useProcessAuth();

  const formRef = useRef(null);

  const [loadingSignin, setLoadingSignin] = useState(false);

  const handleSubmit = async ({ cpf, birthday }) => {
    try {
      setLoadingSignin(true);

      const user = await signIn({
        cpf,
        birthday,
      });

      if (user && Object.keys(user).length > 0) {
        if (
          processModel.process_type > 0 &&
          processModel.model_type === 0 &&
          !user.id
        ) {
          history.push('/process-portal/new', { id: null });
        } else {
          history.push(`/process-portal`);
        }
      } else {
        setLoadingSignin(false);
        toast.error('Login inválido.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch {
      setLoadingSignin(false);
      toast.error('Falha ao efetuar o login.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <Container>
      <div className="signin">
        <div className="title">
          <img
            src={company && company.logo ? company.logo_url : logo}
            alt="Logo"
          />
          <h2>{processModel.title}</h2>
          <span>Entre com seu dados para iniciar o processo.</span>
        </div>
        <FormContainer ref={formRef} onSubmit={handleSubmit}>
          <InputMask name="cpf" label="CPF" mask="999.999.999-99" required />
          <InputMask
            name="birthday"
            label="Data de nascimento"
            mask="99/99/9999"
            required
          />

          <button type="submit">
            {loadingSignin ? <ButtonSpinner size={14} /> : 'Continuar'}
          </button>
        </FormContainer>

        {processModel?.documents?.length > 0 && (
          <button
            type="button"
            className="preview"
            onClick={() =>
              document
                .getElementById('info')
                .scrollIntoView({ block: 'start', behavior: 'smooth' })
            }
          >
            <FaEye />
            Prévia dos documentos necessários
          </button>
        )}
      </div>
      <div className="info content" id="info">
        <h2>
          Para andamento do seu processo, vamos precisar dos documentos abaixo:
        </h2>

        <div className="documents">
          {processModel?.documents?.map((item, index) => (
            <div key={index}>
              <strong>{item.document}</strong>
              <span>{item.obs}</span>
            </div>
          ))}
        </div>

        <h2>
          Vamos precisar também que você responda essas perguntas duarante o
          processo:
        </h2>

        <div className="documents">
          {processModel?.questions?.map((item, index) => (
            <div key={index}>
              <strong>{item.question}</strong>
              <span>{item.explanation}</span>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Signin;

import React, { useCallback, useEffect, useState } from 'react';
import { FaRegStar, FaStar, FaTimes } from 'react-icons/fa';
import Modal from '~/components/Modal';

import { Header, Container } from './styles';

const EditNoteModal = ({ isOpen, setIsOpen, oldNote, editNote }) => {
  const [stars, setStars] = useState([]);
  const [note, setNote] = useState(null);

  const changeNote = useCallback(newNote => {
    const newStars = [];

    for (let x = 0; x < 5; x += 1) {
      newStars.push(newNote > x ? 1 : 0);
    }

    setStars(newStars);
    setNote(newNote);
  }, []);

  useEffect(() => {
    changeNote(oldNote);
  }, [changeNote, oldNote]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      widthProp={400}
      heightProp={200}
      style={{ alignItems: 'space-beetwen' }}
    >
      <Container>
        <Header>
          <div>
            <h1>Alterar nota</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>
        <span>
          {stars.map((star, index) => {
            if (star === 0) {
              return (
                <FaRegStar
                  key={index}
                  size={18}
                  onClick={() => changeNote(note === index + 1 ? 0 : index + 1)}
                  color="#f4c306"
                />
              );
            }
            return (
              <FaStar
                key={index}
                size={18}
                onClick={() => changeNote(note === index + 1 ? 0 : index + 1)}
                color="#f4c306"
              />
            );
          })}
        </span>

        <button
          type="button"
          onClick={() => {
            editNote(note);
            setIsOpen();
          }}
        >
          Confirmar
        </button>
      </Container>
    </Modal>
  );
};

export default EditNoteModal;

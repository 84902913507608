import React, { useEffect } from 'react';
import {
  FaDonate,
  FaExclamation,
  FaFolder,
  FaLayerGroup,
  FaLowVision,
} from 'react-icons/fa';
import { usePortalAuth } from '~/hooks';

import { Container } from './styles';

const Sidebar = ({ selectedButton, selectMenu }) => {
  const { folders, loadFolders } = usePortalAuth();

  useEffect(() => {
    if (!folders || folders.length === 0) {
      (async () => {
        await loadFolders();
      })();
    }
  }, [folders, loadFolders]);

  return (
    <Container>
      <button
        className={selectedButton === 0 ? 'active' : ''}
        type="button"
        onClick={() => selectMenu(0)}
      >
        <FaLowVision />
        <span>Não baixados</span>
      </button>
      <button
        className={selectedButton === 1 ? 'active' : ''}
        type="button"
        onClick={() => selectMenu(1)}
      >
        <FaLayerGroup />
        <span>Todos</span>
      </button>
      {folders.map((item, index) => (
        <button
          className={selectedButton === 4 + index ? 'active' : ''}
          type="button"
          onClick={() => selectMenu(4 + index)}
          key={index}
        >
          <FaFolder />
          <span>{item.description}</span>
        </button>
      ))}
      <button type="button" onClick={() => selectMenu(2)}>
        <FaDonate />
        <span>Faturamento</span>
      </button>
      <button type="button" onClick={() => selectMenu(3)}>
        <FaExclamation />
        <span>Inconsistências</span>
      </button>
    </Container>
  );
};

export default Sidebar;

import styled from 'styled-components';

const TableContainer = styled.table`
  border-spacing: 0px;

  thead th {
    color: #44546a;
    text-align: left;
    padding: 5px 15px 5px 0px;
    border-bottom: 1px solid #c7c7c7;

    &.sort:hover {
      cursor: pointer;
    }

    > div {
      display: flex;
      align-items: center;

      > svg {
        margin-left: 5px;
      }
    }
  }

  tbody {
    tr {
      &.hover:hover {
        cursor: pointer;
      }

      &:nth-child(2n + 2) {
        background: #f4f4f4;
      }
    }

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 5px 15px 5px 0px;
      text-align: left;
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

export default TableContainer;

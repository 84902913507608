import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parseISO, format } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaFileAlt,
  FaTimes,
  FaEye,
  FaPlus,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaEraser,
  FaCheck,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import { Input, Select } from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  Document,
  Department,
  Taxation,
} from './styles';

const List = () => {
  const { company, companyUser } = useAuth();

  const filterRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState({});
  const [documentIndex, setDocumentIndex] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [folders, setFolders] = useState([]);

  const [descriptionSearch, setDescriptionSearch] = useState(() => {
    const descriptionStorage = localStorage.getItem(
      '@Diretiva:document:filter:description'
    );

    if (descriptionStorage) {
      return descriptionStorage;
    }

    return '';
  });
  const [selectedDocumentType, setSelectedDocumentType] = useState(() => {
    const documentTypeStorage = localStorage.getItem(
      '@Diretiva:document:filter:document_type'
    );

    if (documentTypeStorage) {
      return JSON.parse(documentTypeStorage);
    }

    return { value: '', label: 'Todos' };
  });
  const [selectedTaxationType, setSelectedTaxationType] = useState(() => {
    const taxationTypeStorage = localStorage.getItem(
      '@Diretiva:document:filter:taxation_type'
    );

    if (taxationTypeStorage) {
      return JSON.parse(taxationTypeStorage);
    }

    return { value: '', label: 'Todos' };
  });
  const [selectedDepartment, setSelectedDepartment] = useState(() => {
    const departmentStorage = localStorage.getItem(
      '@Diretiva:document:filter:department'
    );

    if (departmentStorage) {
      return JSON.parse(departmentStorage);
    }

    return { value: '', label: 'Todos' };
  });
  const [selectedFolder, setSelectedFolder] = useState(() => {
    const folderStorage = localStorage.getItem(
      '@Diretiva:document:filter:folder'
    );

    if (folderStorage) {
      return JSON.parse(folderStorage);
    }

    return { value: '', label: 'Todos' };
  });
  const [selectedDocument, setSelectedDocument] = useState(() => {
    const documentStorage = localStorage.getItem(
      '@Diretiva:document:filter:main_document'
    );

    if (documentStorage) {
      return JSON.parse(documentStorage);
    }

    return { value: '', label: 'Todos' };
  });

  const [currentPage, setCurrentPage] = useState(() => {
    const currentPageStorage = sessionStorage.getItem(
      '@Diretiva:document:page'
    );

    if (currentPageStorage) {
      return Number(currentPageStorage);
    }

    return 1;
  });
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    async function loadDocuments() {
      if (company) {
        try {
          setLoading(true);

          const response = await api.get(`documents`, {
            params: {
              company_id: company.id,
              page: currentPage,
              description: descriptionSearch,
              document_type: selectedDocumentType.value,
              taxation_type: selectedTaxationType.value,
              department: selectedDepartment.value,
              folder: selectedFolder.value,
              main_document: selectedDocument.value,
            },
          });

          const documentsData = response.data.docs;

          if (documentsData.length > 0) {
            const formattedDocumentsData = documentsData.map(item => ({
              ...item,
              document: {
                ...item.document,
                start_date: item.document.start_date
                  ? format(parseISO(item.document.start_date), 'dd/MM/yyyy')
                  : '',
                end_date: item.document.end_date
                  ? format(parseISO(item.document.end_date), 'dd/MM/yyyy')
                  : '',
              },
            }));

            setDocuments(formattedDocumentsData);
            setDocument(formattedDocumentsData[0]);
            setTotalPages(response.data.pages);
            setTotalDocuments(response.data.total);
          } else {
            setDocuments([]);
            setDocument({});
            setTotalPages(1);
            setTotalDocuments(0);
            toast.warn('Nenhum documento foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setLoading(false);
        } catch (err) {
          toast.error('Falha ao buscar documentos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setLoading(false);
        }
      }
    }

    loadDocuments();
  }, [
    company,
    currentPage,
    selectedDocumentType.value,
    selectedTaxationType.value,
    selectedDepartment.value,
    selectedFolder.value,
    selectedDocument.value,
    descriptionSearch,
  ]);

  useEffect(() => {
    async function loadFolderOptions() {
      if (company) {
        const response = await api.get('folders', {
          params: {
            company_id: company.id,
          },
        });

        const foldersOptions = response.data.map(folderItem => ({
          value: folderItem.id,
          label: folderItem.description,
        }));

        if (foldersOptions.length > 0) {
          const noFolder = foldersOptions.find(
            folder => folder.label === 'Sem Pasta'
          );

          const filteredOptions = foldersOptions.filter(
            folder => folder.label !== 'Sem Pasta'
          );

          filteredOptions.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          filteredOptions.unshift({
            value: noFolder?.value,
            label: noFolder?.label,
          });

          foldersOptions.push({
            value: '',
            label: 'Todos',
          });

          setFolders(foldersOptions);
        }
      }
    }

    loadFolderOptions();
  }, [company]);

  const documentTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 0, label: 'Documento' },
      { value: 1, label: 'Tributo' },
      { value: 2, label: 'Declaração' },
    ];
  }, []);

  const taxationTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 'taxation_empregador_pf', label: 'Empregador PF' },
      { value: 'taxation_empregador_domestico', label: 'Empregador Doméstico' },
      { value: 'taxation_mei', label: 'MEI' },
      {
        value: 'taxation_mei_somente_declaracoes',
        label: 'MEI Somente Declarações',
      },
      {
        value: 'taxation_simples_com_inscricao',
        label: 'Simples c/ Inscrição',
      },
      {
        value: 'taxation_simples_sem_inscricao',
        label: 'Simples s/ Inscrição',
      },
      { value: 'taxation_lucro_presumido', label: 'Lucro Presumido' },
      { value: 'taxation_lucro_real', label: 'Lucro Real' },
      { value: 'taxation_condominio', label: 'Condomínio' },
      { value: 'taxation_terceiro', label: 'Terceiro Setor' },
    ];
  }, []);

  const departmentOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 'dpt_labour', label: 'Trabalhista' },
      { value: 'dpt_tax', label: 'Tributário' },
      { value: 'dpt_accounting', label: 'Contábil' },
      {
        value: 'dpt_financial',
        label: 'Financeiro',
      },
      {
        value: 'dpt_admin',
        label: 'Administração',
      },
      { value: 'dpt_bpo', label: 'BPO' },
    ];
  }, []);

  const documentOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Padrões do Sistema' },
      { value: 0, label: 'Meus documentos' },
    ];
  }, []);

  const alterView = useCallback(() => {
    setShowTable(!showTable);
  }, [showTable]);

  const handleFilter = useCallback(
    ({
      description,
      document_type,
      taxation_type,
      department,
      folder,
      main_document,
    }) => {
      setCurrentPage(1);
      localStorage.setItem('@Diretiva:document:page', 1);

      setDescriptionSearch(description);
      localStorage.setItem(
        '@Diretiva:document:filter:description',
        description
      );

      setSelectedDocumentType(
        documentTypeOptions.find(option => option.value === document_type)
      );
      localStorage.setItem(
        '@Diretiva:document:filter:document_type',
        JSON.stringify(
          documentTypeOptions.find(option => option.value === document_type)
        )
      );

      setSelectedTaxationType(
        taxationTypeOptions.find(option => option.value === taxation_type)
      );
      localStorage.setItem(
        '@Diretiva:document:filter:taxation_type',
        JSON.stringify(
          taxationTypeOptions.find(option => option.value === taxation_type)
        )
      );

      setSelectedDepartment(
        departmentOptions.find(option => option.value === department)
      );
      localStorage.setItem(
        '@Diretiva:document:filter:department',
        JSON.stringify(
          departmentOptions.find(option => option.value === department)
        )
      );

      setSelectedFolder(folders.find(option => option.value === folder));
      localStorage.setItem(
        '@Diretiva:document:filter:folder',
        JSON.stringify(folders.find(option => option.value === folder))
      );

      setSelectedDocument(
        documentOptions.find(option => option.value === main_document)
      );
      localStorage.setItem(
        '@Diretiva:document:filter:main_document',
        JSON.stringify(
          documentOptions.find(option => option.value === main_document)
        )
      );
    },
    [
      documentTypeOptions,
      taxationTypeOptions,
      departmentOptions,
      documentOptions,
      folders,
    ]
  );

  const resetFilter = useCallback(() => {
    setCurrentPage(1);
    localStorage.setItem('@Diretiva:document:page', 1);

    filterRef.current.reset();

    filterRef.current.clearField('description');
    setDescriptionSearch('');
    localStorage.removeItem('@Diretiva:document:filter:description');

    filterRef.current.setFieldValue('document_type', {
      value: '',
      label: 'Todos',
    });
    setSelectedDocumentType({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:document:filter:document_type');

    filterRef.current.setFieldValue('taxation_type', {
      value: '',
      label: 'Todos',
    });
    setSelectedTaxationType({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:document:filter:taxation_type');

    filterRef.current.setFieldValue('department', {
      value: '',
      label: 'Todos',
    });
    setSelectedDepartment({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:document:filter:department');

    filterRef.current.setFieldValue('folder', {
      value: '',
      label: 'Todos',
    });
    setSelectedFolder({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:document:filter:folder');

    filterRef.current.setFieldValue('main_document', {
      value: '',
      label: 'Todos',
    });
    setSelectedDocument({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:document:filter:main_document');
  }, [filterRef]);

  const getDetails = useCallback(
    (id, index) => {
      alterView();
      const item = documents.find(documentItem => documentItem.id === id);

      setDocument(item);
      setDocumentIndex(index);
    },
    [documents, alterView]
  );

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
        sessionStorage.setItem('@Diretiva:document:page', 1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
        sessionStorage.setItem('@Diretiva:document:page', totalPages);
      } else {
        setCurrentPage(page);
        sessionStorage.setItem('@Diretiva:document:page', page);
      }
    },
    [totalPages]
  );

  const handlePrevItem = useCallback(() => {
    if (documentIndex !== 0) {
      setDocumentIndex(documentIndex - 1);
      setDocument(documents[documentIndex - 1]);
    } else {
      setDocumentIndex(documents.length - 1);
      setDocument(documents[documents.length - 1]);
    }
  }, [documentIndex, documents]);

  const handleNextItem = useCallback(() => {
    if (documentIndex !== documents.length - 1) {
      setDocumentIndex(documentIndex + 1);
      setDocument(documents[documentIndex + 1]);
    } else {
      setDocumentIndex(0);
      setDocument(documents[0]);
    }
  }, [documentIndex, documents]);

  const handleDelete = useCallback(
    async id => {
      if (company) {
        try {
          setDeleteLoading(true);

          await api.delete(`documents/${id}`, {
            params: {
              old_company_id: company.old_id,
            },
          });

          setDocuments(oldDocuments =>
            oldDocuments.filter(item => item.id !== id)
          );

          alterView();

          toast.success('Documento deletado com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setDeleteLoading(false);
        } catch (err) {
          toast.error('Falha ao deletar documento.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setDeleteLoading(false);
        }
      }
    },
    [alterView, company]
  );

  const confirmDelete = useCallback(() => {
    if (document.document.main_document === true && companyUser.level < 9) {
      toast.warn('Somente o administrador pode excluir este documento.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => handleDelete(document.id)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete, document, companyUser]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaFileAlt size={20} color="#44546a" />
            <h1>Documentos</h1>
          </div>

          <Link to="/">
            <FaTimes size={20} color="#44546a" />
          </Link>
        </Header>

        <Controls>
          {documents.length > 0 ? (
            <button type="button" onClick={alterView}>
              <FaEye />
              <span>Visualização</span>
            </button>
          ) : (
            <button type="button" onClick={alterView} disabled>
              <FaEye />
              <span>Visualização</span>
            </button>
          )}
          <Link to={{ pathname: '/document/new', state: { id: null } }}>
            <FaPlus />
            <span>Novo</span>
          </Link>
          {showTable ? (
            <button type="button" onClick={resetFilter}>
              <FaEraser />
              <span>Limpar filtros</span>
            </button>
          ) : (
            <>
              <Link
                to={{ pathname: '/document/edit', state: { id: document.id } }}
              >
                <FaEdit />
                <span>Editar</span>
              </Link>
              <button type="button" onClick={confirmDelete}>
                <FaTrash />
                <span>Excluir</span>
              </button>
              <div>
                <button type="button" onClick={handlePrevItem}>
                  <FaChevronLeft />
                </button>
                {totalDocuments > 25 ? (
                  <span>{documentIndex + 1} de 25</span>
                ) : (
                  <span>
                    {documentIndex + 1} de {totalDocuments}
                  </span>
                )}
                <button type="button" onClick={handleNextItem}>
                  <FaChevronRight />
                </button>
              </div>
            </>
          )}
        </Controls>

        {showTable && (
          <Filter ref={filterRef} onSubmit={handleFilter}>
            <Input
              name="description"
              className="description"
              label="Descrição"
              defaultValue={descriptionSearch}
            />

            <Select
              label="Tipo"
              name="document_type"
              className="document_type"
              options={documentTypeOptions}
              defaultValue={selectedDocumentType}
            />

            <Select
              label="Tributação"
              name="taxation_type"
              className="taxation_type"
              options={taxationTypeOptions}
              defaultValue={selectedTaxationType}
            />

            <Select
              label="Departamento"
              name="department"
              className="department"
              options={departmentOptions}
              defaultValue={selectedDepartment}
            />

            <Select
              label="Pasta"
              name="folder"
              className="folder"
              options={folders}
              defaultValue={selectedFolder}
            />

            <Select
              label="Documentos"
              name="main_document"
              className="main_document"
              options={documentOptions}
              defaultValue={selectedDocument}
            />

            <button type="submit">
              <FaSearch />
            </button>
          </Filter>
        )}

        {loading || !company || !companyUser ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {showTable ? (
              <TableContainer>
                <thead>
                  <tr>
                    <th className="barcode">Cód.</th>
                    <th className="abrev">Abreviatura</th>
                    <th className="description">Descrição</th>
                    <th className="folder">Pasta</th>
                    <th className="status">Ativo</th>
                  </tr>
                </thead>
                <tbody>
                  {documents &&
                    documents.map((documentItem, index) => (
                      <tr
                        key={documentItem.id}
                        className="hover"
                        onClick={() => getDetails(documentItem.id, index)}
                      >
                        <td className="barcode">
                          {documentItem.document.barcode}
                        </td>
                        <td className="abrev">
                          {documentItem.document.abbreviation}
                        </td>
                        <td className="description">
                          {documentItem.document.description}
                        </td>
                        <td className="folder">
                          {documentItem.folder?.description}
                        </td>
                        <td className="status">
                          {documentItem.document.status && (
                            <FaCheck size={12} color="#707070" />
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </TableContainer>
            ) : (
              <DetailsContainer>
                <Document>
                  <h4>DOCUMENTO</h4>
                  <section className="first-section">
                    <div className="barcode">
                      <label>Cód. Barras</label>
                      <input
                        name="barcode"
                        value={document.document.barcode}
                        readOnly
                      />
                    </div>
                    <div className="description">
                      <label>Descrição</label>
                      <input
                        name="description"
                        value={document.document.description}
                        readOnly
                      />
                    </div>
                    <div className="abbreviation">
                      <label>Abreviatura</label>
                      <input
                        name="abbreviation"
                        value={document.document.abbreviation}
                        readOnly
                      />
                    </div>
                    <div className="folder">
                      <label>Pasta</label>
                      <input
                        name="folder"
                        value={document.folder?.description}
                        readOnly
                      />
                    </div>
                    <div className="type">
                      <label>Tipo</label>
                      <input
                        name="type"
                        value={document.document.document_type_label}
                        readOnly
                      />
                    </div>
                  </section>
                  {(document.document.document_type === 1 ||
                    document.document.document_type === 2) && (
                    <section className="second-section">
                      <div className="territorial_scope">
                        <label>Abrangência</label>
                        <input
                          name="territorial_scope"
                          value={document.document.territorial_scope_label}
                          readOnly
                        />
                      </div>
                      {(document.document.territorial_scope === 1 ||
                        document.document.territorial_scope === 2) && (
                        <div className="state">
                          <label>Estado</label>
                          <input
                            name="state"
                            value={document.document.state_label}
                            readOnly
                          />
                        </div>
                      )}
                      {document.document.territorial_scope === 2 && (
                        <div className="city">
                          <label>Cidade</label>
                          <input
                            name="city"
                            value={document.document.city}
                            readOnly
                          />
                        </div>
                      )}
                    </section>
                  )}
                  <section className="third-section">
                    <div className="periodicity_type">
                      <label>Periodicidade</label>
                      <input
                        name="periodicity_type"
                        value={document.document.periodicity_type_label}
                        readOnly
                      />
                    </div>
                    <div className="due_date_type">
                      <label>Vencimento</label>
                      <input
                        name="due_date_type"
                        value={document.document.due_date_type_label}
                        readOnly
                      />
                    </div>
                    {(document.document.periodicity_type === 1 ||
                      document.document.periodicity_type === 3) &&
                      document.document.due_date_type !== 5 && (
                        <div className="date_type">
                          <label>Data</label>
                          <input
                            name="date_type"
                            value={document.document.date_type_label}
                            readOnly
                          />
                        </div>
                      )}
                    {document.document.periodicity_type === 2 && (
                      <div className="specific_day_month">
                        <label>Dia/Mês</label>
                        <input
                          name="specific_day_month"
                          value={document.document.specific_day_month || ''}
                          readOnly
                        />
                      </div>
                    )}
                    {document.document.date_type === 1 ? (
                      <div className="specific_day">
                        <label>Dia específico</label>
                        <input
                          name="specific_day"
                          value={document.document.specific_day}
                          readOnly
                        />
                      </div>
                    ) : (
                      <div className="date_calculation">
                        <label>Cálculo</label>
                        <input
                          name="date_calculation"
                          value={document.document.date_calculation_label}
                          readOnly
                        />
                      </div>
                    )}
                  </section>
                  <section className="fourth-section">
                    <div className="start_date">
                      <label>Início</label>
                      <input
                        name="start_date"
                        value={document.document.start_date || ''}
                        readOnly
                      />
                    </div>
                    {(document.document.document_type === 1 ||
                      document.document.document_type === 2) && (
                      <div className="end_date">
                        <label>Fim</label>
                        <input
                          name="end_date"
                          value={document.document.end_date}
                          readOnly
                        />
                      </div>
                    )}
                    {document.document.document_type !== 0 &&
                      document.document.periodicity_type !== 3 && (
                        <div className="deadline_days">
                          <label>Prazo (dias)</label>
                          <input
                            name="deadline_days"
                            value={document.document.deadline_days}
                            readOnly
                          />
                        </div>
                      )}
                  </section>
                  <section className="fifth-section">
                    {(document.document.document_type === 1 ||
                      document.document.document_type === 2) && (
                      <div className="title_to_pay">
                        <label>Titulo a pagar</label>
                        <input
                          name="title_to_pay"
                          checked={document.document.title_to_pay}
                          type="checkbox"
                          disabled
                        />
                      </div>
                    )}
                    <div className="main_document">
                      <label>Documento padrão do sistema</label>
                      <input
                        name="main_document"
                        checked={document.document.main_document}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="use_in_checklist">
                      <label>Usar em checklist</label>
                      <input
                        name="use_in_checklist"
                        checked={document.document.use_in_checklist}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="allow_manual_mark_on_checklist">
                      <label>Em Checklists, permitir marcação manual</label>
                      <input
                        name="allow_manual_mark_on_checklist"
                        checked={
                          document.document.allow_manual_mark_on_checklist
                        }
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="auto_upload">
                      <label>Upload automático pelo Nubo</label>
                      <input
                        name="auto_upload"
                        checked={document.document.auto_upload}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="show_in_labour_checklist_if_has_employee">
                      <label>
                        Exibir no Checklist Trabalhisa somente se a empresa
                        tiver funcionários
                      </label>
                      <input
                        name="show_in_labour_checklist_if_has_employee"
                        checked={
                          document.document
                            .show_in_labour_checklist_if_has_employee
                        }
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="skip_charge_inactive_clients">
                      <label>
                        Não cobrar documento de clientes inativos (SOMENTE
                        DOCUMENTO ANUAL)
                      </label>
                      <input
                        name="skip_charge_inactive_clients"
                        checked={document.document.skip_charge_inactive_clients}
                        type="checkbox"
                        disabled
                      />
                    </div>
                  </section>
                </Document>
                <Department>
                  <h4>DEPARTAMENTOS</h4>
                  <section>
                    <div className="dpt_labour">
                      <label>Trabalhista</label>
                      <input
                        name="dpt_labour"
                        checked={document.document.dpt_labour}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="dpt_tax">
                      <label>Tributário</label>
                      <input
                        name="dpt_tax"
                        checked={document.document.dpt_tax}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="dpt_accounting">
                      <label>Contábil</label>
                      <input
                        name="dpt_accounting"
                        checked={document.document.dpt_accounting}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="dpt_financial">
                      <label>Financeiro</label>
                      <input
                        name="dpt_financial"
                        checked={document.document.dpt_financial}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="dpt_admin">
                      <label>Administração</label>
                      <input
                        name="dpt_admin"
                        checked={document.document.dpt_admin}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="dpt_bpo">
                      <label>BPO</label>
                      <input
                        name="dpt_bpo"
                        checked={document.document.dpt_bpo}
                        type="checkbox"
                        disabled
                      />
                    </div>
                  </section>
                </Department>
                <Taxation>
                  <h4>TRIBUTAÇÃO</h4>
                  <section>
                    <div className="taxation_empregador_pf">
                      <label>Empregador PF</label>
                      <input
                        name="taxation_empregador_pf"
                        checked={document.document.taxation_empregador_pf}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="taxation_empregador_domestico">
                      <label>Empregador Doméstico</label>
                      <input
                        name="taxation_empregador_domestico"
                        checked={
                          document.document.taxation_empregador_domestico
                        }
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="taxation_mei">
                      <label>MEI</label>
                      <input
                        name="taxation_mei"
                        checked={document.document.taxation_mei}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="taxation_mei_somente_declaracoes">
                      <label>MEI Somente Declarações</label>
                      <input
                        name="taxation_mei_somente_declaracoes"
                        checked={
                          document.document.taxation_mei_somente_declaracoes
                        }
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="taxation_simples_com_inscricao">
                      <label>Simples Nacional Com Inscrição</label>
                      <input
                        name="taxation_simples_com_inscricao"
                        checked={
                          document.document.taxation_simples_com_inscricao
                        }
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="taxation_simples_sem_inscricao">
                      <label>Simples Nacional Sem Inscrição</label>
                      <input
                        name="taxation_simples_sem_inscricao"
                        checked={
                          document.document.taxation_simples_sem_inscricao
                        }
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="taxation_lucro_presumido">
                      <label>Lucro Presumido</label>
                      <input
                        name="taxation_lucro_presumido"
                        checked={document.document.taxation_lucro_presumido}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="taxation_lucro_real">
                      <label>Lucro Real</label>
                      <input
                        name="taxation_lucro_real"
                        checked={document.document.taxation_lucro_real}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="taxation_condominio">
                      <label>Condomínio</label>
                      <input
                        name="taxation_condominio"
                        checked={document.document.taxation_condominio}
                        type="checkbox"
                        disabled
                      />
                    </div>
                    <div className="taxation_terceiro">
                      <label>Terceiro Setor</label>
                      <input
                        name="taxation_terceiro"
                        checked={document.document.taxation_terceiro}
                        type="checkbox"
                        disabled
                      />
                    </div>
                  </section>
                </Taxation>
              </DetailsContainer>
            )}
          </Content>
        )}
        <Pagination
          loading={loading ? 1 : 0}
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalDocuments}
          handlePage={handlePage}
        />
        {deleteLoading && <Loading />}
      </Container>
    </>
  );
};

export default List;

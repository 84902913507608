import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  form {
    flex: 1;
  }

  .hide {
    display: none;
  }
`;

export const ModelInfo = styled.div`
  display: flex;
  flex-direction: column;

  .description,
  .responsible_id {
    width: 100%;
  }
`;

export const Documents = styled.div`
  margin-top: 16px;
  position: relative;

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 16px;
      margin-left: 8px;
      background: none;
      color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.primary};
      border-radius: 50%;
      padding: 2px;
      transition: opacity 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.7;
      }

      &.order {
        transform: rotate(90deg);
      }
    }
  }

  .company_document_id {
    width: 100%;
  }

  > span {
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 20px;
  }
`;

export const Document = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & + div {
    margin-top: 16px;
  }

  nav {
    display: flex;

    button {
      background: none;
      color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.primary};
      border-radius: 50%;
      padding: 2px;
      transition: opacity 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 8px;
      }
    }
  }

  section {
    flex: 1;
  }
`;

import React, { useCallback, useState, useRef, useEffect } from 'react';
import { FaCheck, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import Modal from '~/components/Modal';
import { ButtonSpinner } from '~/components/Spinner';
import { FormLoading, Input } from '~/components/Form';

import api from '~/services/api';

import { Header, Content, KpiFeedbacks } from './styles';

const KpiFeedbackModal = ({
  isOpen,
  setIsOpen,
  competence,
  company_id,
  releaseKpi,
}) => {
  const formRef = useRef(null);

  const [feedbackIsEditting, setFeedbackIsEditting] = useState(null);
  const [kpiFeedbacks, setKpiFeedbacks] = useState(null);
  const [kpisLoading, setKpisLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/kpi/feedback/${company_id}`, {
          params: {
            competence,
          },
        });

        setKpiFeedbacks(response.data);
      } catch {
        toast.error('Falha ao buscar.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setKpisLoading(false);
      }
    })();
  }, [company_id, competence]);

  const enforceMinMax = useCallback(el => {
    if (el.value !== '') {
      if (Number(el.value) < Number(el.min)) {
        el.value = el.min;
      }
      if (Number(el.value) > Number(el.max)) {
        el.value = el.max;
      }
    }
  }, []);

  const handleSubmit = async data => {
    try {
      setSaveLoading(true);

      const schema = Yup.object().shape({
        description: Yup.string().required('A mensagem é obrigatória.'),
        note: Yup.number()
          .typeError('A nota é obrigatória.')
          .required('A nota é obrigatória.'),
      });

      await schema.validate(data, { abortEarly: false });

      await api.put(`/kpi/feedback/${feedbackIsEditting}`, {
        ...data,
        done: true,
      });

      setKpiFeedbacks(oldItems =>
        oldItems.map(item => {
          if (feedbackIsEditting === item.id) {
            return {
              ...item,
              description: data.description,
              note: data.note,
              done: true,
            };
          }

          return item;
        })
      );

      toast.success('Salvo com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        toast.error('Falha ao salvar.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } finally {
      setSaveLoading(false);
      setFeedbackIsEditting(null);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      widthProp={700}
      heightProp={500}
      style={{ alignItems: 'space-beetwen' }}
    >
      <Header>
        <div>
          <h1>KPI Feedback</h1>
        </div>
        <aside>
          <button type="button" onClick={setIsOpen}>
            <FaTimes size={20} color="#44546a" />
          </button>
        </aside>
      </Header>

      {kpisLoading || !kpiFeedbacks ? (
        <FormLoading />
      ) : (
        <Content>
          <KpiFeedbacks>
            {kpiFeedbacks.map(item => (
              <div key={item.id}>
                {feedbackIsEditting === item.id ? (
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    {saveLoading ? (
                      <ButtonSpinner color="#44546a" />
                    ) : (
                      <button type="submit">
                        <FaSave color="#44546a" />
                      </button>
                    )}
                    <Input
                      name="user"
                      value={item.user_name || ''}
                      label="Usuário"
                      className="user"
                      type="text"
                      disabled
                    />
                    <Input
                      name="description"
                      defaultValue={item.description || ''}
                      label="Mensagem"
                      className="description"
                      type="text"
                    />
                    <Input
                      name="note"
                      defaultValue={item.note || 5}
                      label="Nota"
                      className="note"
                      type="number"
                      min={0}
                      max={5}
                      placeholder="0 ~ 5"
                      onKeyUp={e => enforceMinMax(e.target)}
                    />
                  </Form>
                ) : (
                  <section>
                    {feedbackIsEditting === null ? (
                      <button
                        type="button"
                        onClick={() => setFeedbackIsEditting(item.id)}
                      >
                        <FaEdit color="#44546a" />
                      </button>
                    ) : (
                      <div style={{ minWidth: '14px' }} />
                    )}

                    <div className="user">
                      <label>Usuário</label>
                      <input
                        name="user"
                        value={item.user_name || ''}
                        readOnly
                      />
                    </div>
                    {item.done ? (
                      <span>
                        Concluído <FaCheck />
                      </span>
                    ) : (
                      <>
                        <div className="description">
                          <label>Mensagem</label>
                          <input
                            name="description"
                            value={item.description || ''}
                            readOnly
                          />
                        </div>
                        <div className="note">
                          <label>Nota</label>
                          <input name="note" value={item.note || ''} readOnly />
                        </div>
                      </>
                    )}
                  </section>
                )}
              </div>
            ))}
          </KpiFeedbacks>
          <button
            type="button"
            onClick={releaseKpi}
            disabled={kpiFeedbacks.filter(item => !item.done).length > 0}
          >
            Liberar
          </button>
        </Content>
      )}
    </Modal>
  );
};

export default KpiFeedbackModal;

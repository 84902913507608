import styled, { keyframes, css } from 'styled-components';
import { darken, lighten } from 'polished';

export const Container = styled.div`
  position: relative;
`;

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  margin-left: 16px;
`;

const animation = keyframes`
  from {
    visibility: hidden;
    clip-path: inset(0px 0px 100% 100% round 5px);
  }
  to {
    visibility: visible;
    clip-path: inset(0px 0px 0px 0px round 5px);
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  z-index: ${props => (props.visible ? '10' : '8')};

  border-radius: 4px;
  position: fixed;
  width: 300px;
  max-height: 400px;
  top: 100px;
  right: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  visibility: hidden;
  margin-right: 40px;

  overflow: auto;

  ${props =>
    props.visible &&
    css`
      animation: ${animation} 0.75s forwards cubic-bezier(0.25, 1, 0.25, 1);
    `}

  > button {
    display: flex !important;
    align-items: center;
    font-size: 15px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    background: none;
    padding: 8px 0;
    color: ${({ theme }) => theme.colors.primary};
    width: 100%;

    & + button {
      border-top: 1px solid ${darken(0.1, '#DDD')} !important;
    }

    &:hover {
      background-color: ${lighten(0.09, '#DDD')};
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    > svg {
      margin: 0 8px 0 10px;
    }
  }
`;

export const Title = styled.header`
  padding: 10px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 8px;
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  svg:nth-child(2):hover {
    cursor: pointer;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Content = styled.div`
  display: flex;
  height: calc(100% - 40px);
  width: 100%;
`;

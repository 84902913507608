import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Dashboard from '~/pages/Dashboard';
import SignIn from '~/pages/SignIn';

// Schedule
import { ScheduleList, ScheduleForm } from '~/pages/Schedule';

import Support from '~/pages/Support';
import { MailSettings, MailForm } from '~/pages/MailSettings';

// Registers
import {
  TributaryProfileList,
  TributaryProfileForm,
} from '~/pages/TributaryProfile';
import { DocumentList, DocumentForm } from '~/pages/Document';
import { DocumentFolderList, DocumentFolderForm } from '~/pages/DocumentFolder';
import { ClientList, ClientForm } from '~/pages/Client';
import { UserList, UserForm } from '~/pages/User';
import { RelatedPeopleList, RelatedPeopleForm } from '~/pages/RelatedPeople';
import { ContactList, ContactForm } from '~/pages/Contact';

// TaxDepartment
import { TaxModelList, TaxModelForm } from '~/pages/TaxModel';
import { TaxParameterList, TaxParameterForm } from '~/pages/TaxParameter';
import { TaxChecklistList } from '~/pages/TaxChecklist';
import { RevenuesList } from '~/pages/Revenues';

// LabourDepartment
import { LabourModelList, LabourModelForm } from '~/pages/LabourModel';
import {
  LabourParameterList,
  LabourParameterForm,
} from '~/pages/LabourParameter';
import { LabourChecklistList } from '~/pages/LabourChecklist';

// AccountingDepartment
import {
  AccountingModelList,
  AccountingModelForm,
} from '~/pages/AccountingModel';
import {
  AccountingParameterList,
  AccountingParameterForm,
} from '~/pages/AccountingParameter';
import { AccountingChecklistList } from '~/pages/AccountingChecklist';

// Process
import { Process, ProcessList, ProcessForm } from '~/pages/Process';

import { WhatsappForm, WhatsappList } from '~/pages/Whatsapp';

import { KPI } from '~/pages/KPI';

import { ProtocolForm, ProtocolList } from '~/pages/Protocol';

import Portal from '~/pages/Portal';

import {
  ExpirationControl,
  ExpirationControlForm,
  ExpirationControlList,
} from '~/pages/ExpirationControl';

import ProcessSituation from '~/pages/ProcessSituation';

import { PopForm, PopList } from '~/pages/Pop';

import EmployeeList from '~/pages/Employee';
import {
  LaborProcess,
  LaborProcessList,
  LaborProcessForm,
} from '~/pages/LaborProcess';

import CostsList from '~/pages/Costs';

import ProcessPortalForms from '~/pages/ProcessPortal/Forms';
import ProcessPortalSignin from '~/pages/ProcessPortal/Signin';
import ProcessPortalList from '~/pages/ProcessPortal/List';
import {
  PortalSignin,
  PortalPage,
  PortalErrorPage,
} from '~/pages/Portal/exportPortal';
import ProcessPortalRoute from './ProcessPortalRoute';
import RedirectProcessPortal from './RedirectProcessPortal';

import PortalRoute from './PortalRoute';
import RedirectPortal from './RedirectPortal';
import PrivateRoute from './PrivateRoute';

export default function Routes() {
  return (
    <Switch>
      <Route path="/signin" component={SignIn} />

      <Route path="/portal/signin" component={PortalSignin} exact />
      <Route path="/portal/error" component={PortalErrorPage} />
      <RedirectPortal path="/portal/redirect/:data" />
      <RedirectPortal path="/portal/signin/:data" component={PortalSignin} />
      <PortalRoute path="/portal" component={PortalPage} exact />
      <Route path="/portal/:info" component={Portal} />

      <Route path="/process-situation/:id" component={ProcessSituation} />

      <RedirectProcessPortal path="/redirect/:companyId/:modelId" />

      <ProcessPortalRoute
        path="/process-portal/signin"
        component={ProcessPortalSignin}
      />
      <ProcessPortalRoute
        path="/process-portal/"
        exact
        component={ProcessPortalList}
      />
      <ProcessPortalRoute
        path="/process-portal/new"
        component={ProcessPortalForms}
      />
      <ProcessPortalRoute
        path="/process-portal/edit"
        component={ProcessPortalForms}
      />

      <PrivateRoute path="/" exact component={Dashboard} />

      <PrivateRoute path="/schedule" exact component={ScheduleList} />
      <PrivateRoute path="/schedule/new" component={ScheduleForm} />
      <PrivateRoute path="/schedule/edit" component={ScheduleForm} />
      <PrivateRoute path="/schedule/view/:id" component={ScheduleList} />

      <PrivateRoute path="/support" exact component={Support} />

      <PrivateRoute
        path="/mail-settings"
        exact
        component={MailSettings}
        level={9}
      />
      <PrivateRoute path="/mail-settings/new" component={MailForm} level={9} />
      <PrivateRoute path="/mail-settings/edit" component={MailForm} level={9} />

      <PrivateRoute
        path="/tributary-profile"
        exact
        component={TributaryProfileList}
      />
      <PrivateRoute
        path="/tributary-profile/new"
        component={TributaryProfileForm}
        level={5}
      />
      <PrivateRoute
        path="/tributary-profile/edit"
        component={TributaryProfileForm}
        level={5}
      />

      <PrivateRoute path="/document" exact component={DocumentList} level={6} />
      <PrivateRoute path="/document/new" component={DocumentForm} level={6} />
      <PrivateRoute path="/document/edit" component={DocumentForm} level={6} />

      <PrivateRoute path="/folder" exact component={DocumentFolderList} />
      <PrivateRoute
        path="/folder/new"
        component={DocumentFolderForm}
        level={9}
      />
      <PrivateRoute
        path="/folder/edit"
        component={DocumentFolderForm}
        level={9}
      />

      <PrivateRoute path="/client" exact component={ClientList} />
      <PrivateRoute path="/client/new" component={ClientForm} />
      <PrivateRoute path="/client/edit" component={ClientForm} level={5} />
      <PrivateRoute path="/client/view" component={ClientList} />

      <PrivateRoute path="/user" exact component={UserList} level={9} />
      <PrivateRoute path="/user/new" component={UserForm} level={9} />
      <PrivateRoute path="/user/edit" component={UserForm} level={9} />

      <PrivateRoute
        path="/related-people"
        exact
        component={RelatedPeopleList}
      />
      <PrivateRoute path="/related-people/new" component={RelatedPeopleForm} />
      <PrivateRoute
        path="/related-people/edit"
        component={RelatedPeopleForm}
        level={5}
      />
      <PrivateRoute path="/related-people/view" component={RelatedPeopleList} />

      <PrivateRoute path="/contact" exact component={ContactList} />
      <PrivateRoute path="/contact/new" component={ContactForm} />
      <PrivateRoute path="/contact/edit" component={ContactForm} level={5} />
      <PrivateRoute path="/contact/view" component={ContactList} />

      <PrivateRoute path="/process-models" component={Process} />
      <PrivateRoute path="/process" exact component={ProcessList} />
      <PrivateRoute path="/process/new" component={ProcessForm} />
      <PrivateRoute path="/process/edit" component={ProcessForm} />

      <PrivateRoute path="/tax-model" exact component={TaxModelList} />
      <PrivateRoute path="/tax-model/new" exact component={TaxModelForm} />
      <PrivateRoute path="/tax-model/edit" exact component={TaxModelForm} />

      <PrivateRoute path="/tax-parameter" exact component={TaxParameterList} />
      <PrivateRoute
        path="/tax-parameter/new"
        exact
        component={TaxParameterForm}
      />
      <PrivateRoute
        path="/tax-parameter/edit"
        exact
        component={TaxParameterForm}
      />

      <PrivateRoute path="/tax-checklist" exact component={TaxChecklistList} />
      <PrivateRoute path="/revenues" exact component={RevenuesList} />

      <PrivateRoute path="/labour-model" exact component={LabourModelList} />
      <PrivateRoute
        path="/labour-model/new"
        exact
        component={LabourModelForm}
      />
      <PrivateRoute
        path="/labour-model/edit"
        exact
        component={LabourModelForm}
      />

      <PrivateRoute
        path="/labour-parameter"
        exact
        component={LabourParameterList}
      />
      <PrivateRoute
        path="/labour-parameter/new"
        exact
        component={LabourParameterForm}
      />
      <PrivateRoute
        path="/labour-parameter/edit"
        exact
        component={LabourParameterForm}
      />

      <PrivateRoute
        path="/labour-checklist"
        exact
        component={LabourChecklistList}
      />

      <PrivateRoute
        path="/accounting-model"
        exact
        component={AccountingModelList}
      />
      <PrivateRoute
        path="/accounting-model/new"
        exact
        component={AccountingModelForm}
      />
      <PrivateRoute
        path="/accounting-model/edit"
        exact
        component={AccountingModelForm}
      />

      <PrivateRoute
        path="/accounting-parameter"
        exact
        component={AccountingParameterList}
      />
      <PrivateRoute
        path="/accounting-parameter/new"
        exact
        component={AccountingParameterForm}
      />
      <PrivateRoute
        path="/accounting-parameter/edit"
        exact
        component={AccountingParameterForm}
      />

      <PrivateRoute path="/kpi" component={KPI} />

      <PrivateRoute path="/message" exact component={WhatsappList} />
      <PrivateRoute path="/message/new" component={WhatsappForm} />

      <PrivateRoute
        path="/accounting-checklist"
        exact
        component={AccountingChecklistList}
      />

      <PrivateRoute path="/protocol" exact component={ProtocolList} />
      <PrivateRoute path="/protocol/edit" component={ProtocolForm} />
      <PrivateRoute path="/protocol/new" component={ProtocolForm} />

      <PrivateRoute
        path="/expiration-control-models"
        component={ExpirationControl}
      />
      <PrivateRoute
        path="/expiration-control"
        exact
        component={ExpirationControlList}
      />
      <PrivateRoute
        path="/expiration-control/new"
        exact
        component={ExpirationControlForm}
      />
      <PrivateRoute
        path="/expiration-control/edit"
        exact
        component={ExpirationControlForm}
      />

      <PrivateRoute path="/pop" exact component={PopList} />
      <PrivateRoute path="/pop/new" exact component={PopForm} />
      <PrivateRoute path="/pop/edit" exact component={PopForm} />

      <PrivateRoute path="/employee" exact component={EmployeeList} />
      <PrivateRoute
        path="/labor-process-models"
        exact
        component={LaborProcess}
      />
      <PrivateRoute path="/labor-process" exact component={LaborProcessList} />
      <PrivateRoute
        path="/labor-process/new"
        exact
        component={LaborProcessForm}
      />
      <PrivateRoute
        path="/labor-process/edit"
        exact
        component={LaborProcessForm}
      />

      <PrivateRoute path="/costs" exact component={CostsList} />
    </Switch>
  );
}

import styled from 'styled-components';
import { darken } from 'polished';

export const Background = styled.div`
  position: absolute;
  height: calc(100vh - 40px);
  width: ${props => (props.isOpen ? '100vw' : '0vw')};
  background: #ddd;
  opacity: ${props => (props.isOpen ? 0.7 : 0)};
  z-index: 3;

  ${props =>
    props.isOpen
      ? 'transition: opacity 0.35s;'
      : 'transition: opacity 0.35s, width 0s ease 0.35s'}

  @media print {
    & {
      display: none;
    }
  }
`;

export const Container = styled.div`
  height: ${props => `${props.height - 60}px`};
  width: ${props => (props.isOpen ? '200px' : '40px')};
  background: #fafafa;
  color: #44546a;
  text-align: center;
  position: absolute;
  top: 50px;
  left: 10px;
  border: 1px solid #c7c7c7;
  z-index: 3;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition: width 0.35s;

  > button {
    background: none;
    color: #707070;
    margin-bottom: 10px;
  }

  > ul {
    > li:nth-child(${props => props.option}) {
      > div {
        color: #094ab2;
      }

      background: ${darken(0.06, '#fafafa')};
      box-shadow: inset 2px 0 0 #094ab2;
      ul {
        margin-top: 5px;
        background: transparent;
        display: flex;
        flex-direction: column;
        visibility: visible;
        opacity: 1;
        width: 200px;

        ${props =>
          props.isOpen &&
          'transition: visibility 0s, width 0s, height 0s, opacity 0.35s linear;transition-delay: 0.35s;'}

        a, button {
          background: transparent;

          &:hover {
            color: #094ab2;
            background: #fafafa;
            box-shadow: inset 2px 0 0 #094ab2;
          }

          strong,
          li {
            background: transparent;
            height: 30px;

            ${props =>
              props.isOpen &&
              'transition: height 0s linear;transition-delay: 0.35s;'}
          }
          height: 30px;

          ${props =>
            props.isOpen &&
            'transition: height 0s linear;transition-delay: 0.35s;'}
        }
      }

      &.protocolos {
        > div {
          color: #d76464;
        }

        box-shadow: inset 2px 0 0 #d76464;

        ul a,
        ul button {
          &:hover {
            color: #d76464;
            box-shadow: inset 2px 0 0 #d76464;
          }
        }
      }

      &.processos {
        > div {
          color: #9932cc;
        }

        box-shadow: inset 2px 0 0 #9932cc;

        ul a,
        ul button {
          &:hover {
            color: #9932cc;
            box-shadow: inset 2px 0 0 #9932cc;
          }
        }
      }

      &.rotinas-tributarias {
        > div {
          color: ${({ theme }) => theme.colors.success};
        }

        box-shadow: inset 2px 0 0 ${({ theme }) => theme.colors.success};

        ul a,
        ul button {
          &:hover {
            color: ${({ theme }) => theme.colors.success};
            box-shadow: inset 2px 0 0 ${({ theme }) => theme.colors.success};
          }
        }
      }

      &.rotinas-trabalhistas {
        > div {
          color: ${({ theme }) => theme.colors.blue};
        }

        box-shadow: inset 2px 0 0 ${({ theme }) => theme.colors.blue};

        ul a,
        ul button {
          &:hover {
            color: ${({ theme }) => theme.colors.blue};
            box-shadow: inset 2px 0 0 ${({ theme }) => theme.colors.blue};
          }
        }
      }

      &.relacionamento {
        > div {
          color: #fac306;
        }

        box-shadow: inset 2px 0 0 #fac306;

        ul a,
        ul button {
          &:hover {
            color: #fac306;
            box-shadow: inset 2px 0 0 #fac306;
          }
        }
      }
    }
  }

  @media print {
    & {
      display: none;
    }
  }
`;

export const Icon = styled.div`
  width: 1.5rem;
  justify-content: center;
`;

export const Options = styled.section`
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    justify-content: space-between;
    margin: 5px 10px;
    background: transparent;
    height: 14px;

    span {
      margin-left: ${props => (props.isOpen ? '10px' : '0px')};
      width: ${props => (props.isOpen ? '100%' : '0px')};
      text-align: left;
      font-size: ${props => (props.isOpen ? '14px' : '0px')};
      visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
      opacity: ${props => (props.isOpen ? '1' : '0')};

      ${props =>
        props.isOpen &&
        'transition: margin-left 0s, width 0s, visibility 0s, font-size 0s, opacity 0.35s linear;transition-delay: 0.35s;'}
    }
  }
`;

export const SidebarItens = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background 0.25s, color 0.25s;
  z-index: 3;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: ${props => (props.isOpen ? 'space-between' : 'center')};
    padding: 7px 17px;
    width: 100%;
    transition: width 0.35s;
    height: 100%;

    strong {
      cursor: default;
      text-align: left;
      margin-left: ${props => (props.isOpen ? '10px' : '0px')};
      visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
      opacity: ${props => (props.isOpen ? '1' : '0')};
      width: ${props => (props.isOpen ? '160px' : '0px')};
      font-size: ${props => (props.isOpen ? '14px' : '0px')};

      ${props =>
        props.isOpen &&
        'transition: visibility 0s, width 0s, font-size 0s, opacity 0.35s linear;transition-delay: 0.35s;'}
    }
  }

  svg {
    font-size: 16px;
  }

  &:hover {
    color: #094ab2;
    background: ${darken(0.06, '#fafafa')};
    box-shadow: inset 2px 0 0 #094ab2;
  }

  &.protocolos {
    color: #d76464;

    &:hover {
      box-shadow: inset 2px 0 0 #d76464;
    }
  }

  &.processos {
    color: #9932cc;

    &:hover {
      box-shadow: inset 2px 0 0 #9932cc;
    }
  }

  &.rotinas-tributarias {
    color: ${({ theme }) => theme.colors.success};

    &:hover {
      box-shadow: inset 2px 0 0 ${({ theme }) => theme.colors.success};
    }
  }

  &.rotinas-trabalhistas {
    color: ${({ theme }) => theme.colors.blue};

    &:hover {
      box-shadow: inset 2px 0 0 ${({ theme }) => theme.colors.blue};
    }
  }

  &.relacionamento {
    color: #fac306;

    &:hover {
      box-shadow: inset 2px 0 0 #fac306;
    }
  }

  > ul {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    opacity: 0;
    width: 0px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    background: #fafafa;

    div {
      display: flex;
      align-items: center;
      height: 0px;

      strong {
        font-size: 16px;
        font-weight: bold;
        color: #094ab2;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        flex: 1;
        height: 0px;
        z-index: 1;
        background: ${darken(0.06, '#fafafa')};
        padding-left: 13px;

        align-items: center;
        justify-content: flex-start;
        display: flex;
      }
    }

    li {
      display: flex;
      align-items: center;
      height: 0px;
      color: #44546a;
      transition: background 0.25s, color 0.25s;
      word-wrap: unset;
      z-index: 1;
      background: #fafafa;
      padding-left: 20px;

      &:hover {
        background: ${darken(0.06, '#FAFAFA')};
      }

      div {
        display: flex;
        justify-content: center;
        margin: 0 10px;
        width: 20px;
      }

      span {
        font-size: 12px;
      }

      svg {
        font-size: 14px;
      }
    }
  }
`;

export const Menu = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 3;
  position: relative;
  padding: 7px;
  height: 30px;
  transition: background 0.25s, color 0.25s;

  &:hover {
    color: #2e2e2e;
    background: ${darken(0.06, '#FAFAFA')};
  }

  > ul {
    position: absolute;
    display: none;
    flex-direction: column;
    left: 39px;
    top: ${props => `${(props.apps * 30 - 28) * -1}px`};
    background: #fafafa;
    width: 200px;
    text-transform: capitalize;
    word-wrap: unset;
    border: 1px solid #ddd;

    li {
      position: relative;
      display: flex;
      height: 30px;

      & + li {
        border-top: 1px solid #ddd;
      }

      &:hover > ul {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &:hover > ul {
    display: flex;
    flex-direction: column;
  }
`;

export const MenuTitle = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  color: #094ab2;
  font-size: 16px;
  font-weight: bold;
  cursor: default;

  transition: background 0.25s, color 0.25s;

  div {
    display: flex;
    justify-content: center;
    margin: 0 10px;
    width: 20px;
  }

  &:hover {
    background: ${darken(0.06, '#FAFAFA')};
  }

  &:hover > ul {
    display: flex;
    flex-direction: column;
  }
`;

export const MenuSubApps = styled.ul`
  display: none;
  position: absolute;
  left: 198px;
  top: -1px;
  background: #fafafa;
  border: 1px solid #ddd;
  width: 200px;
  font-size: 12px;

  li {
    display: flex;
    align-items: center;
    color: #707070;
    height: 30px;
    background: #fafafa;
    transition: background 0.25s, color 0.25s;

    div {
      display: flex;
      justify-content: center;
      margin: 0 10px;
      width: 20px;

      svg {
        font-size: 14px;
      }
    }

    &:hover {
      color: #2e2e2e;
      background: ${darken(0.06, '#FAFAFA')};
    }
  }
`;

export const ItemTitle = styled.div``;

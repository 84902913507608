import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import ReactTooltip from 'react-tooltip';
import produce from 'immer';
import * as Yup from 'yup';
import { Scope } from '@unform/core';
import { toast } from 'react-toastify';
import {
  FaAngleDoubleRight,
  FaPaperclip,
  FaQuestionCircle,
  FaTrash,
} from 'react-icons/fa';

import { Checkbox, FileInput, Input } from '~/components/Form';
import Loading from '~/components/Loading';

import { useProcessAuth } from '~/hooks';
import ProcessContext from '../context';
import api from '~/services/api';

import {
  FormContainer,
  Button,
  InputInfo,
  File,
  FileName,
  Delete,
  UploadFile,
  Options,
} from './styles';

const Form = () => {
  const { processModel, user } = useProcessAuth();
  const { process, setProcess } = useContext(ProcessContext);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [processQuestions, setProcessQuestions] = useState([]);

  const [saveLoading, setSaveLoading] = useState(false);

  const formRef = useRef();

  const applyMask = (valor, tipo) => {
    let isValid = true;

    // Verifica se o valor é válido para o tipo especificado
    switch (tipo) {
      case 0: // Texto
        isValid = test(valor);
        break;
      case 1: // Inteiro
        isValid = /^-?\d+$/.test(valor);
        break;
      case 2: // Decimal
        isValid = /^-?\d+(\.\d+)?$/.test(valor);
        break;
      case 3: // Moeda (considerando como válido números decimais)
        isValid = /^-?\d+(\.\d{1,2})?$/.test(valor);
        break;
      default:
        isValid = false;
        break;
    }

    // Se não for válido, retorna um erro
    if (!isValid) {
      return {
        error: true,
        message: 'Valor inválido para o tipo de máscara especificado.',
      };
    }

    // Aplica a máscara se for válido
    switch (tipo) {
      case 0: // Texto
        return { error: false, value: valor };
      case 1: // Inteiro
        return { error: false, value: valor.replace(/\D/g, '') };
      case 2: // Decimal
        return { error: false, value: valor.replace(/[^0-9.]/g, '') };
      case 3: // Moeda
        return {
          error: false,
          value: valor
            .replace(/\D/g, '')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
        };
      default:
        return { error: false, value: valor };
    }
  };

  useEffect(() => {
    if (!processModel || !process?.id || !user.id) return;

    const docs = process.documents
      .map(item => ({
        ...item,
        quantitative_type:
          processModel.documents.find(
            docModelItem => docModelItem.id === item.modelDocument_id
          )?.quantitative_type || null,
      }))
      .filter(
        item =>
          item.quantitative_type === 1 &&
          (item.partner_id === user.id || item.partner_id === null)
      );
    const quests = process.questions
      .map(item => ({
        ...item,
        explanation:
          processModel.questions.find(
            questModelItem => questModelItem.id === item.modelQuestion_id
          )?.explanation || null,
        quantitative_type:
          processModel.questions.find(
            questModelItem => questModelItem.id === item.modelQuestion_id
          )?.quantitative_type || null,
      }))
      .filter(
        item =>
          item.quantitative_type === 1 &&
          (item.partner_id === user.id || item.partner_id === null)
      );

    const hasNotFinishedDocument = docs.some(item => item.situation < 2);
    const hasNotFinishedQuestion = quests.some(item => item.situation < 2);

    if (!hasNotFinishedDocument && !hasNotFinishedQuestion) {
      setProcess(oldProcess => ({
        ...oldProcess,
        portal_situation: 3,
      }));
    }

    setUploadFiles(docs);
    setProcessQuestions(quests);
  }, [processModel, process, user, setProcess]);

  const handleSubmit = useCallback(
    async data => {
      setSaveLoading(true);
      try {
        const schema = Yup.object().shape({
          questions: Yup.array().of(
            Yup.object().shape({
              answer: Yup.string('A resposta deve ser um texto.').test(
                'is-required',
                'A resposta é obrigatória.',
                function validateAnswer(value) {
                  const { path } = this;
                  const question = parseInt(path.match(/\[(\d+)\]/)[1], 10);
                  if (processModel.questions[question].required && !value) {
                    // eslint-disable-next-line react/no-this-in-sfc
                    return this.createError({
                      path,
                      message: 'A resposta é obrigatória.',
                    });
                  }
                  if (processModel.questions[question].key_question && !value) {
                    // eslint-disable-next-line react/no-this-in-sfc
                    return this.createError({
                      path,
                      message: 'A resposta é obrigatória das perguntas chave.',
                    });
                  }
                  return true;
                }
              ),
            })
          ),
          documents: Yup.array().of(
            Yup.object().shape({
              file_name: Yup.string().when('release', {
                is: value => !value,
                then: Yup.string('O arquivo é necessário.').required(
                  'O arquivo é necessário.'
                ),
                otherwise: Yup.string('O arquivo é necessário.'),
              }),
            })
          ),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put(`/process-portal/process/${process.id}`, {
          portal_situation: 2,
          client_id: process.client_id,
          view: false,
          user_id: process.user_id,
          notification: {
            model: processModel.title,
            sender_id: null,
            sender_name: user.name,
          },
        });

        formRef.current.setErrors({});

        toast.success('Processo salvo com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setProcess(oldProcess => ({
          ...oldProcess,
          portal_situation: 3,
        }));
      } catch (err) {
        setSaveLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);

          toast.error(
            'Existem campos obrigatórios em vermelho que precisam ser preenchidos.',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        } else {
          toast.error('Falha ao salvar processo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [process, setProcess, processModel, user]
  );

  const handleAddFile = async (file, index) => {
    try {
      setSaveLoading(true);

      const formData = new FormData();

      formData.append('file', file);

      const fileResponse = await api.post('/process-portal/upload', formData, {
        params: {
          prefix: 'Process_Document',
        },
      });

      const { blobName } = fileResponse.data;

      await api.put(`/process-portal/dependencies/increment/${process.id}`);
      await api.put(`/process-portal/document/${uploadFiles[index].id}`, {
        situation: 2,
        conclusion_date: new Date(),
        file: file.name,
        file_url: blobName,
      });

      setUploadFiles(
        produce(uploadFiles, draft => {
          draft[index].file = file.name;
          draft[index].situation = 2;
        })
      );
    } catch (err) {
      toast.error('Falha ao editar documento.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setSaveLoading(false);
    }
  };

  const handleDeleteFile = async index => {
    try {
      setSaveLoading(true);

      await api.put(`/process-portal/dependencies/decrement/${process.id}`);
      await api.put(`/process-portal/document/${uploadFiles[index].id}`, {
        situation: 0,
        conclusion_date: null,
        file: null,
        file_url: null,
      });

      setUploadFiles(
        produce(uploadFiles, draft => {
          draft[index].file = null;
          draft[index].situation = 0;
        })
      );
    } catch (err) {
      toast.error('Falha ao editar documento.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setSaveLoading(false);
    }
  };

  const handleChangeAnswer = async (answer, index) => {
    try {
      setSaveLoading(true);

      if (processQuestions[index].answer !== answer) {
        const isEmpty = answer.trim() === '';

        if (isEmpty) {
          await api.put(`/process-portal/dependencies/decrement/${process.id}`);
        } else {
          await api.put(`/process-portal/dependencies/increment/${process.id}`);
        }

        await api.put(
          `/process-portal/question/${processQuestions[index].id}`,
          {
            answer,
            situation: isEmpty ? 0 : 2,
            conclusion_date: isEmpty ? null : new Date(),
          }
        );

        setProcessQuestions(
          produce(processQuestions, draft => {
            draft[index].answer = answer;
          })
        );
      }
    } catch (err) {
      toast.error('Falha ao editar a resposta.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setSaveLoading(false);
    }
  };

  const handleReleaseDoc = async index => {
    try {
      setSaveLoading(true);

      if (uploadFiles[index].situation === 0) {
        await api.put(`/process-portal/dependencies/increment/${process.id}`);
      } else if (uploadFiles[index].situation === 3) {
        await api.put(`/process-portal/dependencies/decrement/${process.id}`);
      }

      await api.put(`/process-portal/document/${uploadFiles[index].id}`, {
        situation: uploadFiles[index].situation === 3 ? 0 : 3,
        conclusion_date: null,
        file: null,
        file_url: null,
      });

      setUploadFiles(
        produce(uploadFiles, draft => {
          draft[index].situation = draft[index].situation === 3 ? 0 : 3;
          draft[index].file = null;
        })
      );
    } catch (err) {
      toast.error('Falha ao editar a resposta.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <>
      <FormContainer ref={formRef} onSubmit={handleSubmit} className="content">
        <h4>
          Para andamento do seu processo, vamos precisar dos documentos abaixo:
        </h4>
        <section>
          {uploadFiles.map((item, index) => (
            <Scope path={`documents[${index}]`} key={index}>
              <UploadFile>
                {item.file ? (
                  <div className="file-input">
                    <Delete>
                      <button
                        type="button"
                        onClick={() => handleDeleteFile(index)}
                      >
                        <FaTrash size={14} />
                      </button>
                    </Delete>
                    <FileName>
                      <Input
                        name="file_name"
                        className="file"
                        label={item.document}
                        type="text"
                        value={item.file}
                        disabled
                        readOnly={item.situation === 3}
                      />
                    </FileName>
                  </div>
                ) : (
                  <div className="file-input">
                    {item.situation !== 3 && (
                      <File>
                        <label htmlFor={`doc.${index}`}>
                          <FaPaperclip size={14} color="#FCFCFC" />
                        </label>
                        <FileInput
                          id={`doc.${index}`}
                          name="file"
                          onChange={e =>
                            handleAddFile(e.target.files[0], index)
                          }
                        />
                      </File>
                    )}
                    <Input
                      name="file_name"
                      className="file-name"
                      label={item.document}
                      type="text"
                      placeholder="Clique aqui para incluir o arquivo"
                      disabled
                      readOnly={item.situation === 3}
                    />
                  </div>
                )}
                {!item.required && (
                  <Checkbox
                    id={`release${index}`}
                    name="release"
                    label="Não tenho esse documento"
                    className="dont-have"
                    checked={item.situation === 3}
                    onChange={() => handleReleaseDoc(index)}
                  />
                )}
              </UploadFile>
            </Scope>
          ))}
        </section>
        <h4 style={{ marginTop: '20px' }}>
          Agora precisamos que você responda a essas perguntas:
        </h4>
        <section>
          {processQuestions.map((item, index) => {
            if (item.explanation) {
              return (
                <Scope path={`questions[${index}]`} key={index}>
                  <ReactTooltip
                    id="info"
                    place="bottom"
                    type="info"
                    backgroundColor="#337ab7"
                    effect="solid"
                    multiline
                  />
                  <InputInfo key={index}>
                    <FaQuestionCircle
                      color="#44546a"
                      size={15}
                      data-tip={item.explanation}
                      data-for="info"
                    />
                    <Input
                      name="answer"
                      label={item.question}
                      defaultValue={item.answer}
                      type="text"
                      onBlur={e => {
                        const result = applyMask(
                          e.target.value,
                          item.model.type_question
                        );
                        if (result.error) {
                          // Trate o erro como achar melhor, por exemplo, exibindo uma mensagem
                          toast.error(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                          });
                        } else {
                          // Se não houver erro, passa o valor mascarado para handleChangeAnswer
                          handleChangeAnswer(result.value, index);
                        }
                      }}
                    />
                  </InputInfo>
                </Scope>
              );
            }

            return (
              <Scope path={`questions[${index}]`} key={index}>
                <Input
                  name="answer"
                  label={item.question}
                  className="question"
                  defaultValue={item.answer}
                  type="text"
                  onBlur={e => {
                    const result = applyMask(
                      e.target.value,
                      item.model.type_question
                    );
                    if (result.error) {
                      // Trate o erro como achar melhor, por exemplo, exibindo uma mensagem
                      toast.error(result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });
                    } else {
                      // Se não houver erro, passa o valor mascarado para handleChangeAnswer
                      handleChangeAnswer(result.value, index);
                    }
                  }}
                />
              </Scope>
            );
          })}
        </section>
      </FormContainer>

      <Options>
        <Button type="button" onClick={() => formRef.current.submitForm()}>
          Finalizar
          <FaAngleDoubleRight size={15} />
        </Button>
      </Options>

      {saveLoading && <Loading />}
    </>
  );
};

export default Form;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaAddressCard, FaSearch } from 'react-icons/fa';

import { TableLoading } from '~/components/Table';

import history from '~/services/history';
import { useProcessAuth } from '~/hooks';
import api from '~/services/api';

import { Container, NewProcess, Cards, Card, Search } from './styles';

const List = () => {
  const {
    processModel,
    user,
    clientsRelateds,
    company,
    handleChangeClient,
  } = useProcessAuth();

  const searchRef = useRef('');
  const [clientSearch, setClientSearch] = useState('');

  const [processes, setProcesses] = useState(null);
  const [clients, setClients] = useState(null);

  const situationOptions = [
    { value: -1, label: 'Pendente' },
    { value: 0, label: 'Em andamento' },
    { value: 1, label: 'Concluído' },
  ];

  const loadClients = useCallback(async () => {
    try {
      const response = await api.get('/process-portal/clients', {
        params: {
          company_id: company.id,
          clientsIds: clientsRelateds,
        },
      });

      setClients(response.data);
    } catch (err) {
      toast.error('Falha ao buscar os clientes.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [clientsRelateds, company]);

  const loadProcess = useCallback(async () => {
    try {
      const response = await api.get('/process-portal/process', {
        params: {
          company_id: company.id,
          relationship_id: user.id,
          processModel_id: processModel.id,
          client_id: user.client_id,
        },
      });

      const { data } = response;
      if (data.length > 0) {
        if (
          data.length === 1 &&
          processModel.model_type === 0 &&
          processModel.process_type > 0
        ) {
          history.push('/process-portal/edit', { id: data[0].id });
        }

        const formattedData = data.map(item => ({
          ...item,
          createdAt: format(parseISO(item.created_at), "dd/MM/yyyy 'às' HH:mm"),
          updatedAt: format(parseISO(item.updated_at), "dd/MM/yyyy 'às' HH:mm"),
          employee: item.employee?.name || '',
        }));

        setProcesses(formattedData);
      } else if (
        processModel.model_type === 0 &&
        processModel.process_type === 0
      ) {
        history.push('/process-portal/new', { id: null });
      } else {
        setProcesses([]);
        toast.warn(
          'Nenhum processo relacionado a você em andamento encontrado.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      }
    } catch (err) {
      toast.error('Falha ao buscar os processos.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [company, user, processModel]);

  useEffect(() => {
    if (!user.cpf || !processModel.id || clientsRelateds.length === 0) return;

    if (user.client_id === -1) {
      history.push('/process-portal/new', { id: null });
    } else if (user.client_id) {
      loadProcess();
    } else {
      loadClients();
    }
  }, [loadProcess, loadClients, user, processModel, clientsRelateds]);

  return (
    <Container className="content">
      {user.client_id ? (
        <>
          <h1>Selecione o processo que deseja visualizar</h1>

          {!processes ? (
            <TableLoading />
          ) : (
            <Cards>
              {processes.map(item => (
                <Card
                  key={item.id}
                  onClick={() =>
                    history.push('/process-portal/edit', { id: item.id })
                  }
                >
                  <FontAwesomeIcon
                    icon={processModel.icon.name}
                    color="#44546a"
                    size="lg"
                  />
                  <div>
                    <strong>{processModel.menu_title}</strong>
                    {processModel.model_type === 1 && (
                      <span>{item.employee}</span>
                    )}
                    <span>Iniciado em: {item.createdAt}</span>
                    {/* <span>Atualizado em: {item.createdAt}</span> */}
                    <span>
                      {`Status: `}
                      <strong
                        style={
                          item.situation === -1
                            ? { color: 'red' }
                            : { color: '' }
                        }
                      >
                        {
                          situationOptions.find(
                            option => option.value === item.situation
                          ).label
                        }
                      </strong>
                    </span>
                  </div>
                </Card>
              ))}

              {!(
                processModel.model_type === 0 && processModel.process_type > 0
              ) && (
                <NewProcess
                  onClick={() =>
                    history.push('/process-portal/new', { id: null })
                  }
                >
                  Incluir novo processo
                </NewProcess>
              )}
            </Cards>
          )}
        </>
      ) : (
        <>
          <h1>Selecione a empresa a qual se referem os processos</h1>
          <Search>
            <input
              name="search"
              ref={searchRef}
              placeholder="Pesquisar empresa"
              onChange={() => setClientSearch(searchRef.current.value)}
            />
            <button
              type="button"
              onClick={() => setClientSearch(searchRef.current.value)}
            >
              <FaSearch color="#fafafa" />
            </button>
          </Search>
          {!clients ? (
            <TableLoading />
          ) : (
            <Cards>
              {clients
                .filter(item => item.name.match(new RegExp(clientSearch, 'i')))
                .map(item => (
                  <Card
                    key={item.id}
                    onClick={() => handleChangeClient(item.id)}
                    className="client"
                  >
                    <FaAddressCard size={20} color="#44546a" />
                    <span>{item.name}</span>
                  </Card>
                ))}

              {processModel.model_type === 0 && processModel.process_type > 0 && (
                <NewProcess
                  onClick={() => {
                    handleChangeClient(-1);
                    history.push('/process-portal/new', { id: null });
                  }}
                  className="client"
                >
                  Abrir nova empresa
                </NewProcess>
              )}
            </Cards>
          )}
        </>
      )}
    </Container>
  );
};

export default List;

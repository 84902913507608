import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 35px);

  > button {
    margin: 15px;
    padding: 9px 75px;
    background: ${({ theme }) => theme.colors.success};
    color: #fff;
    border: 0px;
    border-radius: 4px;

    transition: background 0.3s;
    &:hover {
      background: ${({ theme }) => theme.hover.success};
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const KpiFeedbacks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  padding-top: 16px;
  color: ${({ theme }) => theme.colors.primary};
  overflow: auto;

  .user {
    width: 20%;
  }

  .description {
    width: 70%;
  }

  .note {
    width: 10%;
  }

  > div + div {
    margin-top: 16px;
  }

  > div > section,
  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 16px;

    button {
      display: flex;
      height: 100%;
      align-items: center;
      margin-right: 16px;
    }
  }

  form > div {
    & + div {
      margin-left: 16px;
    }
  }

  > div > section {
    > div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }

    label {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.grey};
      opacity: 0.7;
    }

    input {
      padding-top: 10px;
      height: 30px;
      background: transparent;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    }

    span {
      display: flex;
      height: 100%;
      width: 20%;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colors.success};

      svg {
        margin-left: 4px;
      }
    }
  }
`;

import React from 'react';
import { FaCopy, FaLocationArrow, FaPaperclip } from 'react-icons/fa';
import { FileInput, TextArea } from '~/components/Form';
import { Feedback, UploadFile, FileName, File } from './styles';

export function SendFeedback({
  uploadFile,
  feedbackRef,
  copyFile,
  setCopyFile,
  setUploadFile,
}) {
  return (
    <Feedback>
      <div>
        <TextArea
          id="feedback_message"
          name="feedback_message"
          className="feedback_message"
          label="Mensagem (CTRL + ENTER para enviar)"
          type="text"
          copyFile={copyFile}
          setCopyFile={setCopyFile}
        />
      </div>

      <UploadFile>
        {uploadFile.name && (
          <FileName>
            <label htmlFor="filename">Arquivo</label>
            <input id="fileName" value={uploadFile.name} readOnly type="text" />
          </FileName>
        )}
        <File>
          <label htmlFor="file">
            <FaPaperclip size={14} color="#FCFCFC" />
          </label>
          <FileInput
            id="file"
            name="file"
            onChange={e => {
              setUploadFile(e.target.files[0]);
            }}
          />
        </File>
      </UploadFile>

      <button
        type="button"
        style={{
          background: '#9400d3',
          borderColor: '#9400d3',
        }}
        onClick={() => {
          navigator.clipboard.read().then(data => {
            data.forEach(item => {
              if (item.types.includes('image/png')) {
                item.getType('image/png').then(blob => {
                  const reader = new FileReader();
                  reader.onload = ev => {
                    setCopyFile(ev.target.result);
                  };
                  reader.readAsDataURL(blob);
                });
              }
            });
          });
        }}
      >
        <FaCopy
          size={14}
          style={{
            transform: 'none',
          }}
        />
      </button>

      <button type="button" onClick={() => feedbackRef.current.submitForm()}>
        <FaLocationArrow size={14} />
      </button>
    </Feedback>
  );
}

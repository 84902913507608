import styled from 'styled-components';

export const Search = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  .input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 25px;
    width: 300px;
    border: 0;
    border-radius: 4px;
    background: #fff;

    input {
      width: 270px;
      margin: 3px;
    }

    svg {
      margin-right: 5px;
    }
  }

  .result {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    width: 300px;
    max-height: 160px;
    overflow: auto;
    background: #fff;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 0 3px #000;
  }

  .selected {
    background: #01579b;
    color: #fff;

    &:hover {
      background: #01579b;
    }
  }

  .hidden {
    display: none;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    margin-right: 16px;
  }

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 1rem;
  }
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 35px;
  min-height: 35px;
  padding-left: 16px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    background: #e5eef5;
  }
`;

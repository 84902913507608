import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  height: calc(100% - 60px);
  padding: 30px;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 24px;
`;

export const Boards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

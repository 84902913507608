import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 400px;
  background: #fafafa;
  padding: 25px;
  border-radius: 4px;

  display: flex;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  strong {
    font-size: 28px;
    color: #01579b;
  }
`;

export const Content = styled.main`
  font-size: 16px;
  padding-left: 16px;
  border-left: 1px solid #c7c7c7;

  span {
    margin-top: 8px;
    color: #666;
  }

  div {
    margin-top: 24px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: 8px;
      border-radius: 4px;
      color: #fff;
      transition: background 0.2s;
      padding-left: 20px;
      padding-right: 20px;

      &:nth-child(1) {
        background: #00c853;
        margin-right: 16px;

        &:hover {
          background: ${darken(0.05, '#00c853')};
        }
      }

      &:nth-child(2) {
        background: #ee4256;

        &:hover {
          background: ${darken(0.05, '#ee4256')};
        }
      }
    }
  }
`;

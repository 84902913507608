import axios from 'axios';

import history from './history';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error.response.status === 401 &&
      error.response.data.error === 'Token invalid'
    ) {
      localStorage.removeItem('@Diretiva:token');
      localStorage.removeItem('@Diretiva:user');
      localStorage.removeItem('@Diretiva:company');
      localStorage.removeItem('@Diretiva:companyUser');
      localStorage.removeItem('@Diretiva:companyUsers');
      localStorage.removeItem('@Diretiva:apps');
      localStorage.removeItem('@Diretiva:companies');

      history.push('/signin');
    }

    return Promise.reject(error);
  }
);

export default api;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border};

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    & + section {
      margin-top: 16px;
    }

    > div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }
  }

  .loading {
    position: absolute;
    z-index: 2;
  }

  .document,
  .email {
    opacity: 1;
  }

  .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      margin-bottom: 3px;
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }

  button:nth-child(2) {
    span,
    svg {
      color: ${({ theme }) => theme.colors.success};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .name,
  .email,
  .birthday,
  .phone,
  .pin,
  .document {
    width: 33%;
  }

  .hide {
    display: none;
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }
`;

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;

  img {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;

    width: 100%;
    height: 100%;
    border-radius: 50%;

    background-color: #aaa;
    color: transparent;
    font-size: 16px;

    opacity: 0;
    transition: opacity 0.3s, color 0.3s;

    &:hover {
      cursor: pointer;

      color: #fff;
      opacity: 0.7;
    }
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;

  .name {
    width: 100%;
  }

  .email,
  .nick,
  .password {
    width: 33%;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;

  @media print {
    @page {
      size: A4 landscape;
    }
    & {
      display: flex;
    }
  }

  table {
    width: 100%;

    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .active {
      width: 30px;
      padding-left: 8px;
      text-align: center;
    }

    .document {
      width: 15%;
    }

    .name {
      width: 35%;
    }

    .type {
      width: 10%;
    }
    .date,
    .registration {
      width: 15%;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleDoubleRight,
  FaAngleRight,
} from 'react-icons/fa';

import { Container, Content } from './styles';

export default function Pagination({
  currentPage,
  pages,
  totalDocs,
  handlePage,
  loading,
}) {
  return (
    <Container loading={loading}>
      <Content>
        <div>{totalDocs} registro(s)</div>
        <aside>
          <button type="button" onClick={() => handlePage(1)}>
            <FaAngleDoubleLeft size={12} />
          </button>
          <button type="button" onClick={() => handlePage(currentPage - 1)}>
            <FaAngleLeft size={12} />
          </button>
          <span>
            {currentPage} / {pages}
          </span>
          <button type="button" onClick={() => handlePage(currentPage + 1)}>
            <FaAngleRight size={12} />
          </button>
          <button type="button" onClick={() => handlePage(pages)}>
            <FaAngleDoubleRight size={12} />
          </button>
        </aside>
      </Content>
    </Container>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  totalDocs: PropTypes.number.isRequired,
  handlePage: PropTypes.func.isRequired,
  loading: PropTypes.number.isRequired,
};

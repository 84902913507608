import useSWR from 'swr';
import api from '../services/api';

export default function useFetch(url, params) {
  const { data, error, mutate } = useSWR(
    url,
    async apiUrl => {
      const response = await api.get(apiUrl, { params });

      return response.data;
    },
    {
      refreshInterval: 300000,
      revalidateOnFocus: false,
    }
  );

  return { data, error, mutate };
}

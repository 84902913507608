import React, { useRef, useState, useEffect } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import { ptBR } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';

import { Container } from './styles';

const DatePicker = ({ name, label, className, useTime, disabled, ...rest }) => {
  const datepickerRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  const [date, setDate] = useState(defaultValue || null);

  const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

  ptBR.localize.day = n => days[n];

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      getValue: ref => ref.props.selected,
      setValue: (ref, value) => {
        if (value) {
          ref.setSelected(value);
        }
      },
      clearValue: ref => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container className={className} error={error ? 1 : 0} disabled={disabled}>
      <label>{label}</label>
      {useTime ? (
        <ReactDatePicker
          ref={datepickerRef}
          selected={date}
          onChange={setDate}
          locale={ptBR}
          dateFormat="dd/MM/yyyy HH:mm"
          showTimeSelect={useTime}
          timeFormat="HH:mm"
          timeIntervals={10}
          timeSelectLabel="Hora"
          customInput={
            <MaskedTextInput
              type="text"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
          }
          disabled={disabled}
          onFocus={clearError}
          {...rest}
        />
      ) : (
        <ReactDatePicker
          ref={datepickerRef}
          selected={date}
          onChange={setDate}
          locale={ptBR}
          dateFormat="dd/MM/yyyy"
          customInput={
            <MaskedTextInput
              type="text"
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            />
          }
          disabled={disabled}
          onFocus={clearError}
          {...rest}
        />
      )}
      {error && <span>{error}</span>}
    </Container>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  useTime: PropTypes.bool,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  label: null,
  className: null,
  useTime: false,
  disabled: false,
};

export default DatePicker;

import React from 'react';
import Skeleton from 'react-loading-skeleton';

const FormLoading = () => {
  return (
    <div style={{ lineHeight: 3 }}>
      <Skeleton height={30} count={10} />
    </div>
  );
};

export default FormLoading;

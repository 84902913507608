import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border};

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    > div {
      & + div {
        margin-left: 16px;
      }
    }
  }

  .loading {
    position: absolute;
    z-index: 2;
  }

  .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
  }
`;

export const Header = styled.header`
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      margin-bottom: 3px;
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div + div {
    margin-left: 16px;
  }

  .client {
    width: 100%;
  }

  button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;
    width: 4%;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .client {
      width: 50%;
      padding-left: 8px;
    }

    .model {
      width: 30%;
    }

    .start_period {
      width: 20%;
    }
  }

  .hide {
    display: none;
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;

  section {
    > div {
      display: flex;
      flex-direction: column;
    }
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }
`;

export const ParameterInfo = styled.div`
  display: flex;
  flex-direction: column;

  .client,
  .model,
  .start_period,
  .end_period,
  .active {
    width: 25%;
    opacity: 1;
  }

  .obs {
    width: 100%;
  }
`;

export const Responsibles = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  section {
    div + div {
      margin-left: 16px;
    }

    .responsible {
      width: 50%;
    }

    .start_period,
    .end_period {
      width: 25%;
    }
  }
`;

export const Documents = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  .status {
    width: 10%;
  }

  .document {
    opacity: 1;
    width: 65%;
  }

  .obs {
    width: 25%;
  }
`;

export const ResponsibleList = styled.div`
  margin-top: 16px;
  position: relative;

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 16px;
      margin-left: 8px;
      background: none;
      color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.primary};
      border-radius: 50%;
      padding: 2px;
      transition: opacity 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  > span {
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 20px;
  }
`;

export const Responsible = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  > button {
    position: absolute;
    top: 45%;
    background: none;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    padding: 2px;
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }
  }

  section {
    padding-left: 16px;
  }

  .responsible {
    width: 50%;
    margin-left: 0;
  }

  .responsible_start_period,
  .responsible_end_period {
    width: 25%;
  }
`;

import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  position: relative;
  z-index: ${props => (props.visible ? '10' : '8')};
`;

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  margin-left: 16px;

  label {
    position: absolute;
    right: 3px;
    top: 0;
    z-index: 1;
    font-size: 7px;
    color: ${({ theme }) => theme.colors.white};
  }

  &:hover span {
    visibility: visible;
    top: 180%;
    opacity: 1;
  }

  span {
    visibility: hidden;
    width: 120px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 10002;
    top: 150%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.8s;
  }

  span:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${({ theme }) => theme.colors.primary}
      transparent;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  div {
    display: flex;
    align-items: center;
    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }
`;

export const Filter = styled(Form)`
  padding: 8px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  justify-content: space-between;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  div:nth-child(1) {
    width: 90vw;
    margin-left: 16px;
    margin-right: 16px;
  }

  div:nth-child(2) {
    width: 10vw;
    display: flex;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background: transparent;
      border: 0;
      transition: opacity 0.2s;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      svg {
        color: ${({ theme }) => theme.colors.primary};
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.greyBackground};
    padding: 5px 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};

    div {
      display: flex;
      align-items: center;

      h1 {
        font-size: 16px;
        margin-left: 10px;
      }
    }

    button {
      background: transparent;
      border: none;
    }
  }

  table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .value {
      width: 10%;
      text-align: right;
    }

    .obs-hint {
      color: ${({ theme }) => theme.colors.blue};
    }

    .obs {
      width: 35%;
    }

    .description {
      width: 55%;
    }
  }
`;

import React, { useEffect, useState, useMemo } from 'react';
import {
  FaCheck,
  FaCheckCircle,
  FaClock,
  FaEnvelope,
  FaExclamationCircle,
  FaMinus,
  FaTimes,
  FaEye,
  FaTrash,
} from 'react-icons/fa';

import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import {
  Header,
  SendButton,
  Container,
  Content,
  Documents,
  Title,
  Phases,
  Phase,
  Situation,
  Viewers,
  Viewer,
} from './styles';
import Modal from '~/components/Modal';
import api from '~/services/api';

const ConfirmSendProcess = ({
  onClick,
  isOpen,
  setIsOpen,
  changeDocSituation,
  confirmDeleteViewer,
  process,
  processType,
}) => {
  const [processDocuments, setProcessDocuments] = useState(
    process.documents
      .map((item, index) => ({ ...item, originalIndex: index }))
      .filter(item => item.situation === 0 || item.situation === 1)
  );

  const processViewers = useMemo(() => {
    return process.viewers;
  }, [process]);

  const [processPhases, setProcessPhases] = useState([]);

  const [finalPrevision, setFinalPrevision] = useState(null);

  useEffect(() => {
    let previsionAux = null;

    const phasesAux = process.phases
      .filter(item => item.notificate)
      .map(phase => {
        let formattedPrevision = '';

        if (phase.situation !== 3 && phase.situation !== 4) {
          const parsedPrevision = parseISO(phase.prevision_date);

          if (previsionAux < parsedPrevision) {
            previsionAux = parsedPrevision;
          }

          formattedPrevision = phase.prevision_date
            ? format(parsedPrevision, 'dd/MM/yyyy')
            : '';
        }

        return {
          ...phase,
          formattedPrevision,
        };
      });
    phasesAux.reverse();
    setProcessPhases(phasesAux);

    setFinalPrevision(
      previsionAux
        ? `Previsão ${format(previsionAux, 'dd/MM/yyyy')}`
        : 'Finalizado'
    );
  }, [process]);

  const handleChangeDocSituation = (index, situation) => {
    try {
      changeDocSituation(processDocuments[index].originalIndex, situation);

      setProcessDocuments(oldDocs =>
        oldDocs.map((item, indexItem) => {
          if (indexItem === index) {
            return {
              ...item,
              situation,
            };
          }

          return item;
        })
      );
    } catch (err) {
      toast.error('Falha ao alterar situação.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const openFile = async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaEnvelope size={20} color="#44546a" />
            <h1>Confirmação de envio</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>

        <SendButton onClick={onClick} disabled={processViewers.length === 0}>
          Enviar
        </SendButton>

        <Content>
          <h1>{processType}</h1>
          <h1>{process.client.name}</h1>

          <Viewers>
            <Title>
              <h2>Destinatários</h2>
            </Title>

            {processViewers.length > 0 ? (
              <>
                {processViewers.map(viewer => (
                  <Viewer key={viewer.id}>
                    <button
                      type="button"
                      onClick={() => confirmDeleteViewer(viewer.id)}
                    >
                      <FaTrash color="#E53935" />
                    </button>
                    <span>
                      <strong>{viewer.name}: </strong>
                      {viewer.email}
                    </span>
                  </Viewer>
                ))}
              </>
            ) : (
              <Viewer>
                <span>Nenhum destinatário cadastrado!</span>
              </Viewer>
            )}
          </Viewers>

          <Documents>
            {processDocuments.length === 0 ? (
              <div className="all-recived">
                <FaCheck size={18} />
                <h3>Todos os documentos já foram recebidos.</h3>
              </div>
            ) : (
              <>
                <Title>
                  <h2>Documentos Pendentes</h2>
                </Title>

                <div className="docs">
                  {processDocuments.map((item, index) => (
                    <div key={item.id}>
                      <ReactTooltip
                        id="situation"
                        place="bottom"
                        type="info"
                        backgroundColor="#337ab7"
                        effect="solid"
                        multiline
                      />
                      <Situation className="situation">
                        <button
                          type="button"
                          onClick={() => handleChangeDocSituation(index, 0)}
                          data-tip="Não recebido"
                          data-for="situation"
                        >
                          <div
                            className={`option ${item.situation === 0 &&
                              'waiting'}`}
                          >
                            <div />
                          </div>
                        </button>

                        <button
                          type="button"
                          onClick={() => handleChangeDocSituation(index, 1)}
                          data-tip="Pendente"
                          data-for="situation"
                        >
                          <div
                            className={`option ${item.situation === 1 &&
                              'pending'}`}
                          >
                            <FaExclamationCircle size={18} />
                          </div>
                        </button>

                        <button
                          type="button"
                          onClick={() => handleChangeDocSituation(index, 2)}
                          data-tip="Recebido"
                          data-for="situation"
                        >
                          <div
                            className={`option ${item.situation === 2 &&
                              'recived'}`}
                          >
                            <FaCheckCircle size={18} />
                          </div>
                        </button>

                        <button
                          type="button"
                          onClick={() => handleChangeDocSituation(index, 3)}
                          data-tip="Desobrigado"
                          data-for="situation"
                        >
                          <div
                            className={`option ${item.situation === 3 &&
                              'released'}`}
                          >
                            <FaMinus size={18} />
                          </div>
                        </button>
                      </Situation>
                      <span>{item.document}</span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </Documents>

          <Phases>
            <Title>
              <h2>Etapas</h2>
            </Title>

            <Phase>
              <section className="route">
                <div
                  className={`circle ${process.situation ? 'start' : 'end'}`}
                />
                <div className="line" />
              </section>
              <section className="info">
                <h4>Conclusão do Processo</h4>
                <span>{finalPrevision}</span>
              </section>
            </Phase>

            {processPhases.map(item => (
              <Phase key={item.id}>
                <section className="route">
                  {item.situation === 0 && <div className="circle" />}
                  {item.situation === 1 && (
                    <div className="circle progress">
                      <FaClock color="#f4c306" />
                    </div>
                  )}
                  {item.situation === 2 && (
                    <div className="circle pending">
                      <FaExclamationCircle color="#E53935" />
                    </div>
                  )}
                  {item.situation === 3 && (
                    <div className="circle success">
                      <FaCheckCircle color="#006229" />
                    </div>
                  )}
                  {item.situation === 4 && (
                    <div className="circle released">
                      <FaMinus color="#ccc" />
                    </div>
                  )}
                  <div className="line" />
                </section>
                <section className="info">
                  <h4>{item.document}</h4>

                  {item.file && (
                    <button
                      type="button"
                      onClick={() => openFile(item.file_url)}
                    >
                      <FaEye /> Clique aqui para visualizar
                    </button>
                  )}

                  {item.situation === 0 && (
                    <span>{`Previsão ${item.formattedPrevision}`}</span>
                  )}
                  {item.situation === 1 && (
                    <>
                      <span>Em andamento</span>
                      <span>{`Previsão ${item.formattedPrevision}`}</span>
                    </>
                  )}
                  {item.situation === 2 && <span>Pendente</span>}
                  {item.situation === 3 && (
                    <span>Realizado {item.conclusion_date || ''}</span>
                  )}
                  {item.situation === 4 && <span>Desobrigado</span>}

                  {item.obs && <span>Obs.: {item.obs}</span>}
                </section>
              </Phase>
            ))}

            <Phase>
              <section className="route">
                <div className="circle start" />
              </section>
              <section className="info">
                <h4>Início do processo</h4>
              </section>
            </Phase>
          </Phases>
        </Content>
      </Container>
    </Modal>
  );
};

export default ConfirmSendProcess;

import styled, { keyframes, css } from 'styled-components';
import { darken, lighten } from 'polished';

export const Container = styled.div`
  position: relative;
  z-index: ${props => (props.visible ? '10' : '8')};
`;

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  margin-left: 16px;

  img {
    border-radius: 50px;
    border-color: #ddd;
    height: 25px;
    width: 25px;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 10px;
  color: ${({ theme }) => theme.colors.primary};

  img {
    width: 50px;
    padding-right: 10px;
    border-right: 1px solid ${darken(0.1, '#DDD')} !important;
  }

  div {
    text-align: left;
    margin-left: 10px;
    margin-right: 10px;

    strong {
      display: block;
      font-size: 16px;
    }
  }
`;

const animation = keyframes`
  from {
    visibility: hidden;
    clip-path: inset(0px 0px 100% 100% round 5px);
  }
  to {
    visibility: visible;
    clip-path: inset(0px 0px 0px 0px round 5px);
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  position: fixed;
  width: 300px;
  top: 50px;
  right: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  visibility: hidden;

  ${props =>
    props.visible &&
    css`
      animation: ${animation} 0.75s forwards cubic-bezier(0.25, 1, 0.25, 1);
    `}

  &:before {
    content: '';
    width: 15px;
    height: 15px;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: -12px;
    right: 10px;
    transform: translateY(0.5em) rotate(45deg);
  }

  > button {
    display: flex !important;
    align-items: center;
    font-size: 15px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    background: none;
    padding: 8px 0;
    color: ${({ theme }) => theme.colors.primary};

    & + button {
      border-top: 1px solid ${darken(0.1, '#DDD')} !important;
    }

    &:hover {
      background-color: ${lighten(0.09, '#DDD')};
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    > svg {
      margin: 0 15px 0 15px;
    }
  }
`;

export const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 20px;

  &:hover {
    cursor: pointer;
  }
`;

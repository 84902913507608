import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      margin-top: 8px;
    }

    > div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }
  }

  @media print {
    & {
      display: none;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }

    a {
      margin-left: 10px;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin: 0 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div + div {
    margin-left: 16px;
  }

  .cod {
    width: 80px;
  }

  .client {
    width: 40%;
  }

  .user {
    width: 30%;
  }

  .situation {
    width: 20%;
  }

  > button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  > table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .thumb {
      width: 50px;
      padding: 0 8px;
    }

    .cod {
      width: 80px;
    }

    .start_date,
    .delivered_date {
      width: 14%;
      min-width: 120px;
    }

    .recipient,
    .situation,
    .user {
      width: 15%;
      max-width: 0;
    }

    .client {
      width: 20%;
      max-width: 0;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ProtocolInfo = styled.div`
  section {
    > div + div,
    form + div,
    div + form {
      margin-left: 16px;
    }
  }

  h4 {
    padding-top: 16px;

    &:first-child {
      padding-top: 0;
    }
  }

  form {
    color: ${({ theme }) => theme.colors.primary};
  }

  table {
    margin: 16px;
    width: calc(100% - 32px);

    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .active {
      width: 50px;
      padding: 0 8px;
      text-align: center;

      > div {
        width: 100%;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      label {
        display: none;
      }

      input {
        margin-right: 0;
      }
    }

    .cod {
      width: 80px;
    }

    .situation,
    .user {
      width: 25%;
      max-width: 0;
    }

    .client {
      width: 30%;
      max-width: 0;
    }
  }

  .cod,
  .date {
    width: 12%;
  }

  .situation {
    width: 16%;
  }

  .client {
    width: 60%;
  }
`;

import React, { useEffect, useState, useCallback, useRef } from 'react';

import { FaFileAlt, FaSearch, FaTimes } from 'react-icons/fa';
import { parseISO, format } from 'date-fns';
import { toast } from 'react-toastify';

import Modal from '~/components/Modal';
import Pagination from '~/components/Pagination';
import { TableContainer, TableLoading } from '~/components/Table';

import api from '~/services/api';

import { Header, Container, Content, Filter } from './styles';
import { InputMask, Select } from '~/components/Form';

const DocumentsModal = ({ isOpen, setIsOpen, filter }) => {
  const {
    cod,
    company_id,
    client_id,
    user_id,
    situation,
    start_period,
    end_period,
  } = filter;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState([]);
  const [searchComp, setSearchComp] = useState('');
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentsOptions, setDocumentsOptions] = useState([
    { label: 'Todos', value: '' },
  ]);

  const filterRef = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/documents/select/${company_id}`);

        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            if (a.document.description < b.document.description) {
              return -1;
            }
            if (a.document.description > b.document.description) {
              return 1;
            }
            return 0;
          });

          const options = response.data.map(item => ({
            value: item.document.id,
            label: item.document.description,
          }));

          options.unshift({
            value: '',
            label: 'Todos',
          });

          setDocumentsOptions(options);
        } else {
          toast.warn('Nenhum documento foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch {
        toast.error('Falha ao buscar dcumentos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    })();
  }, [company_id]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get('/protocols/documents', {
          params: {
            company_id,
            client_id,
            user_id,
            situation,
            page: currentPage,
            cod,
            start_period,
            end_period,
            comp: searchComp,
            document_id: selectedDocument,
          },
        });

        const { docs, pages, total } = response.data;

        if (total > 0) {
          const formattedData = docs.map(item => ({
            ...item,
            deadline: item.deadline_date
              ? format(parseISO(item.deadline_date), 'dd/MM/yyyy')
              : '',
            client: item.protocol.client.nickname || '',
            document: item.document.description,
            cod: item.protocol.cod,
          }));

          setTotalPages(pages);
          setTotalDocs(total);
          setDocuments(formattedData);
        } else {
          setTotalPages(1);
          setTotalDocs(0);
          setDocuments([]);
        }
      } catch {
        toast.error('Falha ao buscar os documentos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [
    company_id,
    client_id,
    user_id,
    situation,
    currentPage,
    cod,
    start_period,
    end_period,
    searchComp,
    selectedDocument,
  ]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  const handleFilter = useCallback(({ comp, document }) => {
    setSearchComp(comp);
    setSelectedDocument(document);
  }, []);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaFileAlt size={20} color="#44546a" />
            <h1>Documentos</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>

        <Content className="content">
          <Filter ref={filterRef} onSubmit={handleFilter}>
            <InputMask
              name="comp"
              className="comp"
              label="Comp."
              mask="99/9999"
              defaultValue=""
            />

            <Select
              label="Documento"
              name="document"
              className="document"
              options={documentsOptions}
              defaultValue={{ label: 'Todos', value: '' }}
            />

            <button type="submit">
              <FaSearch />
            </button>
          </Filter>

          {loading ? (
            <TableLoading />
          ) : (
            <TableContainer>
              <thead>
                <tr>
                  <th className="cod">Cod.</th>
                  <th className="client">Cliente</th>
                  <th className="document">Documento</th>
                  <th className="deadline">Vencimento</th>
                  <th className="comp">Comp.</th>
                  <th className="price">Valor</th>
                  <th className="obs">Obs.</th>
                </tr>
              </thead>
              <tbody>
                {documents.map(item => (
                  <tr key={item.id} className="hover">
                    <td className="cod">{item.cod}</td>
                    <td className="client">{item.client}</td>
                    <td className="document">{item.document}</td>
                    <td className="deadline">{item.deadline}</td>
                    <td className="comp">{item.competence}</td>
                    <td className="price">R$ {item.price}</td>
                    <td className="obs">{item.obs}</td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          )}
        </Content>
        <Pagination
          loading={loading ? 1 : 0}
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalDocs}
          handlePage={handlePage}
        />
      </Container>
    </Modal>
  );
};

export default DocumentsModal;

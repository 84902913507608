import styled from 'styled-components';
import { Form } from '@rocketseat/unform';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: ${props => (props.visible ? '10' : '8')};
`;

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  margin-left: 16px;

  label {
    position: absolute;
    right: 3px;
    top: 0;
    z-index: 1;
    font-size: 7px;
  }

  &:hover span {
    visibility: visible;
    top: 180%;
    opacity: 1;
  }

  span {
    visibility: hidden;
    width: 120px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 10002;
    top: 150%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.8s;
  }

  span:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${({ theme }) => theme.colors.primary}
      transparent;
  }
`;

export const Content = styled(Form)`
  display: ${props => (props.visible ? 'flex' : 'none')};

  position: absolute;
  left: 50%;
  top: 20%;
  min-width: 400px;
  transform: translate(-50%, -50%);
  padding: 25px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`;

export const Left = styled.aside`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const Right = styled.main`
  font-size: 16px;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    margin-top: 10px;
    border: 1px solid ${({ theme }) => theme.colors.lightBorder};
    border-radius: 4px;
    padding: 3px;
  }

  button {
    margin-top: 16px;
    width: 50%;
    align-self: flex-end;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.success};
    margin-left: 0 !important;
    color: ${({ theme }) => theme.colors.white};
    padding: 5px;
    transition: background 0.2s;

    &:hover {
      background-color: ${({ theme }) => theme.hover.success};
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  position: relative;

  strong {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaMailBulk,
  FaTimes,
  FaEdit,
  FaPlus,
  FaEnvelope,
  FaBroom,
  FaSave,
} from 'react-icons/fa';
import * as Yup from 'yup';

import { useAuth } from '~/hooks';
import api from '~/services/api';

import { TableLoading } from '~/components/Table';
import Modal from '~/components/Modal';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';
import { FormContainer, Input, TextArea } from '~/components/Form';

import {
  Container,
  Header,
  Controls,
  MailSettingsInfo,
  MailSettingsForm,
  ReadOnly,
  NoSettings,
} from './styles';

const MailSettingsModal = ({
  isOpen,
  setIsOpen,
  deadfileSettings,
  setDeadfileSettings,
}) => {
  const { company } = useAuth();

  const formRef = useRef(null);
  const [initialData, setInitialData] = useState(null);

  const [formLoading, setFormLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [isForm, setIsForm] = useState(false);

  const loadSettings = useCallback(async () => {
    if (company) {
      try {
        setFormLoading(true);
        const response = await api.get(
          `/protocols/mail-settings/${company.id}`
        );

        if (response.data) {
          const { id, title, start_message, end_message } = response.data;

          setInitialData({
            id,
            title,
            start_message,
            end_message,
            description: deadfileSettings.description,
          });
        }

        setFormLoading(false);
      } catch {
        toast.error('Falha ao buscar configurações de email.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setFormLoading(false);
      }
    }
  }, [company, deadfileSettings.description]);

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setLoading(true);

          const schema = Yup.object().shape({
            title: Yup.string().required('O título é obrigatório'),
            start_message: Yup.string().required('a mensagem é obrigatória'),
            end_message: Yup.string().required('a mensagem é obrigatória'),
            description: Yup.string().required('a mensagem é obrigatória'),
          });

          await schema.validate(data, { abortEarly: false });

          data.company_id = company.id;

          if (initialData) {
            await api.put(`/protocols/mail-settings/${initialData.id}`, data);
          } else {
            await api.post(`/protocols/mail-settings`, data);
          }

          await api.put(`/protocols/deadfile-settings/${deadfileSettings.id}`, {
            description: data.description,
          });
          setDeadfileSettings(oldSettings => ({
            ...oldSettings,
            description: data.description,
          }));
          deadfileSettings.description = data.description;

          setLoading(false);
          setIsForm(false);
          loadSettings();
          toast.success('Configurações salvas com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar configurações.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setLoading(false);
        }
      }
    },
    [company, initialData, loadSettings, deadfileSettings, setDeadfileSettings]
  );

  const resetForm = useCallback(() => {
    formRef.current.reset();
    formRef.current.setErrors({});
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow onClick={() => setIsForm(false)} onClose={onClose} />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <Container>
          <Header>
            <div>
              <FaMailBulk size={20} color="#44546a" />
              <h1>Configurações de emails e arquivo morto</h1>
            </div>

            {!isForm && (
              <aside>
                <button type="button" onClick={setIsOpen}>
                  <FaTimes size={20} color="#44546a" />
                </button>
              </aside>
            )}
          </Header>

          {formLoading ? (
            <TableLoading />
          ) : (
            <>
              <Controls>
                {!isForm ? (
                  <button type="button" onClick={() => setIsForm(true)}>
                    {initialData ? <FaEdit /> : <FaPlus />}
                    <span>{initialData ? 'Editar' : 'Novo'}</span>
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => formRef.current.submitForm()}
                    >
                      <FaSave size={15} color="#44546a" />
                      <span>Salvar</span>
                    </button>
                    <button type="button" onClick={confirmResetForm}>
                      <FaBroom size={15} color="#44546a" />
                      <span>Limpar</span>
                    </button>
                    <button type="button" onClick={handleClose}>
                      <FaTimes size={15} color="#44546a" />
                      <span>Fechar</span>
                    </button>
                  </>
                )}
              </Controls>

              {!isForm ? (
                <>
                  {initialData ? (
                    <FormContainer ref={formRef}>
                      <MailSettingsInfo>
                        <h4>CONFIGURAÇÕES DE EMAIL</h4>
                        <section>
                          <ReadOnly width="100%">
                            <label>Título</label>
                            <input
                              name="title"
                              value={initialData.title || ''}
                              readOnly
                            />
                          </ReadOnly>
                        </section>

                        <section>
                          <ReadOnly width="100%">
                            <label>
                              Texto de abertura (antes dos documentos)
                            </label>
                            <TextareaAutosize
                              name="start_message"
                              value={initialData.start_message || ''}
                              readOnly
                            />
                          </ReadOnly>
                        </section>

                        <section>
                          <ReadOnly width="100%">
                            <label>
                              Texto de fechamento (depois dos documentos)
                            </label>
                            <TextareaAutosize
                              name="start_message"
                              value={initialData.end_message || ''}
                              readOnly
                            />
                          </ReadOnly>
                        </section>

                        <h4>CONFIGURAÇÕES DE ARQUIVO MORTO</h4>
                        <section>
                          <ReadOnly width="100%">
                            <label>Texto de exibição</label>
                            <TextareaAutosize
                              name="description"
                              value={initialData.description || ''}
                              readOnly
                            />
                          </ReadOnly>
                        </section>
                      </MailSettingsInfo>
                    </FormContainer>
                  ) : (
                    <NoSettings>
                      <FaEnvelope size={50} color="#E53935" />
                      <span>
                        Configurações de email para protocolos inexistentes
                      </span>
                      <button type="button" onClick={() => setIsForm(true)}>
                        Criar configurações
                      </button>
                    </NoSettings>
                  )}
                </>
              ) : (
                <FormContainer
                  ref={formRef}
                  initialData={initialData}
                  onSubmit={handleSubmit}
                >
                  <MailSettingsForm>
                    <h4>CONFIGURAÇÕES DE EMAIL</h4>
                    <section>
                      <Input name="title" label="Título" className="title" />
                    </section>

                    <section>
                      <TextArea
                        name="start_message"
                        label="Texto de abertura (antes dos documentos)"
                        className="start"
                      />
                    </section>

                    <section>
                      <TextArea
                        name="end_message"
                        label="Texto de encerramento (depois dos documentos)"
                        className="end"
                      />
                    </section>

                    <h4>CONFIGURAÇÕES DE ARQUIVO MORTO</h4>
                    <section>
                      <TextArea
                        name="description"
                        label="Texto de exibição"
                        className="end"
                      />
                    </section>
                  </MailSettingsForm>
                </FormContainer>
              )}
            </>
          )}
        </Container>
      </Modal>

      {loading && <Loading />}
    </>
  );
};

export default MailSettingsModal;

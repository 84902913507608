import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }

    a {
      margin-left: 10px;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const SendButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 85%;

  padding: 8px 50px;
  margin-top: 16px;
  color: #fff;
  font-size: 1rem;
  background: ${({ theme }) => theme.colors.blue};
  border: 0;
  border-radius: 5px;

  transition: background 0.3s;
  &:hover {
    background: ${({ theme }) => theme.hover.blue};
  }

  &:disabled {
    cursor: no-drop;
  }
`;

export const Content = styled.main`
  width: 100%;
  height: calc(100% - 35px);
  overflow: auto;

  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.primary};
  padding: 16px 20%;

  h1 {
    color: ${({ theme }) => theme.colors.blue};
    text-align: center;
  }

  h1:first-child {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 650px) {
    padding: 16px 5%;
  }
`;

export const Documents = styled.div`
  margin: 50px 0;

  .docs {
    display: flex;
    flex-direction: column;
    font-size: 1rem;

    div {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;

      span {
        width: 100%;
      }
    }
  }

  span {
    margin: 8px 0 0 16px;
  }

  .all-recived {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #006229;

    h3 {
      margin-left: 8px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 8px;

  h2 {
    margin-right: 8px;
  }
`;

export const Phases = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Phase = styled.div`
  display: flex;
  flex-direction: row;

  .route {
    width: 50px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .end-route {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;
    min-height: 30px;
    border: 3px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
  }

  .success {
    border-color: #006229;
  }

  .progress {
    border-color: #f4c306;
  }

  .pending {
    border-color: #e53935;
  }

  .released {
    border-color: #ccc;
  }

  .end {
    background: #f4c306;
    border-color: #f4c306;
  }

  .start {
    background: #006229;
    border-color: #006229;
  }

  .line {
    height: 100%;
    margin: 8px 0;
    width: 0;
    border-left: 6px solid ${({ theme }) => theme.colors.primary};
    border-radius: 8px;
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;

    h4 {
      margin: 7px 0;
    }

    span,
    button {
      margin: 5px 0 0 16px;
    }

    button {
      background: transparent;
      border: 0;
      color: ${({ theme }) => theme.colors.blue};
      font-weight: bold;
      display: flex;

      svg {
        margin-right: 4px;
      }
    }
  }
`;

export const Situation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 175px;
  height: 100%;
  color: ${({ theme }) => theme.colors.primary};

  > button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;

    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }

  .option {
    width: 26px;
    height: 26px;

    padding: 2px;
    border: 2px solid #aaa;
    color: #aaa;
    border-radius: 50%;

    div {
      height: 100%;
      width: 100%;
      background: #aaa;
      border-radius: 50%;
    }
  }

  .waiting {
    border-color: #9dd3fe;
    color: #9dd3fe;

    div {
      background: #9dd3fe;
    }
  }

  .pending {
    border-color: #e53935;
    color: #e53935;
  }

  .recived {
    border-color: #006229;
    color: #006229;
  }

  .released {
    border-color: #9dd3fe;
    color: #9dd3fe;
  }
`;

export const Viewers = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

export const Viewer = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  margin: 5px 0;

  button {
    width: 50px;
    background: transparent;

    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
    }
  }
`;

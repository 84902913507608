import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Scope } from '@unform/core';
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';
import {
  FaTable,
  FaTimes,
  FaSave,
  FaBroom,
  FaPlus,
  FaMinus,
  FaExchangeAlt,
  FaChevronUp,
  FaChevronDown,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import { FormContainer, FormLoading, Select, Input } from '~/components/Form';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';

import {
  Container,
  Header,
  Controls,
  Content,
  ModelInfo,
  Documents,
  Document,
} from './styles';

const Form = () => {
  const { company } = useAuth();

  const { state } = useLocation();

  const id = state?.id || null;

  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [model, setModel] = useState(null);
  const [
    currentModelDocumentsLength,
    setCurrentModelDocumentsLength,
  ] = useState(null);
  const [documentsOptions, setDocumentsOptions] = useState(null);
  const [isOrdering, setIsOrdering] = useState(false);
  const [
    requiredDocumentErrorMessage,
    setRequiredDocumentErrorMessage,
  ] = useState('');

  useEffect(() => {
    async function loadModels() {
      if (company) {
        const response = await api.get('checklists-models', {
          params: {
            department: 'dpt_tax',
            company_id: company.id,
          },
        });

        if (response.data.length > 0 && !id) {
          history.push('/tax-model');
        }
      }
    }

    loadModels();
  }, [company, id]);

  useEffect(() => {
    async function loadModel() {
      if (id) {
        try {
          setLoading(true);

          const response = await api.get(`checklists-models/${id}`);

          if (response.data) {
            const formattedModel = response.data;

            formattedModel.documents = formattedModel.documents.map(
              document => {
                return {
                  model_document_id: document.model_document_id,
                  company_document_id: document.company_document_id,
                  order: document.order,
                  documentRef: null,
                };
              }
            );

            setModel(formattedModel);
          }
          setLoading(false);
        } catch (err) {
          toast.error('Falha ao buscar modelo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setLoading(false);
        }
      } else {
        setModel({ documents: [] });
      }
    }

    loadModel();
  }, [id]);

  useEffect(() => {
    async function loadDocuments() {
      if (company) {
        setDocumentsLoading(true);

        const response = await api.get(
          `checklists-models/documents/${company.id}`,
          {
            params: {
              department: 'dpt_tax',
            },
          }
        );

        const formattedDocuments = response.data.map(document => {
          return {
            value: document.id,
            label: document.document.description,
          };
        });

        setDocumentsOptions(formattedDocuments);

        setDocumentsLoading(false);
      }
    }

    loadDocuments();
  }, [company]);

  useEffect(() => {
    if (model && currentModelDocumentsLength) {
      const addedDocumentRef = formRef.current.getFieldRef(
        `documents[${currentModelDocumentsLength - 1}].company_document_id`
      );

      addedDocumentRef.focus();
    }
  }, [model, currentModelDocumentsLength]);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            model_name: Yup.string().required('O nome do modelo é obrigatório'),
            documents: Yup.array()
              .min(1)
              .of(
                Yup.object().shape({
                  company_document_id: Yup.string().required(
                    'O documento é obrigatório'
                  ),
                })
              )
              .required('Pelo menos um documento é obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          let model_id;

          if (id) {
            model_id = id;

            await api.put(`checklists-models/${model_id}`, {
              name: data.model_name,
            });

            const documentsData = data.documents.map((document, index) => {
              return {
                id: document.model_document_id,
                model_id,
                document_id: document.company_document_id,
                document_order: index,
              };
            });

            await api.put(
              `checklists-models/documents/${company.id}`,
              {
                documents: documentsData,
              },
              {
                params: {
                  department: 'dpt_tax',
                },
              }
            );
          } else {
            const createdModel = await api.post('checklists-models', {
              company_id: company.id,
              name: data.model_name,
              department: 'dpt_tax',
            });

            model_id = createdModel.data.id;

            const documentsData = data.documents.map((document, index) => {
              return {
                model_id,
                document_id: document.company_document_id,
                document_order: index,
                company_id: company.id,
              };
            });

            await api.post(
              'checklists-models/documents',
              {
                documents: documentsData,
              },
              {
                params: {
                  department: 'dpt_tax',
                },
              }
            );
          }

          toast.success('Modelo salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);

          history.push('/tax-model');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              if (error.path === 'documents') {
                setRequiredDocumentErrorMessage(error.message);
              }
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar modelo.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setSaveLoading(false);
        }
      }
    },
    [id, company]
  );

  const handleAddNewDocument = useCallback(() => {
    setModel(
      produce(model, draft => {
        const blankDocument = {
          model_document_id: uuid(),
          company_document_id: uuid(),
          order: draft.documents.length + 1,
        };

        if (draft?.documents?.length > 0) {
          draft.documents.push(blankDocument);
        } else {
          draft.documents[0] = blankDocument;
        }

        setCurrentModelDocumentsLength(draft.documents.length);
      })
    );

    setRequiredDocumentErrorMessage('');
  }, [model]);

  const handleRemoveDocument = useCallback(
    async (modelDocumentId, index) => {
      setModel(
        produce(model, draft => {
          delete draft.documents[index];
        })
      );

      setCurrentModelDocumentsLength(null);

      await api.delete(`checklists-models/documents/${modelDocumentId}`);
    },
    [model]
  );

  const confirmRemove = useCallback((functionToExecute, itemId, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => functionToExecute(itemId, index)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleActiveOrdering = useCallback(() => {
    setIsOrdering(!isOrdering);
  }, [isOrdering]);

  const handleMoveUp = useCallback(
    index => {
      if (index === 0) {
        return;
      }

      setModel(
        produce(model, draft => {
          const targetItem = draft.documents[index];
          const itemToBeSwitch = draft.documents[index - 1];

          draft.documents[index] = itemToBeSwitch;
          draft.documents[index - 1] = targetItem;
        })
      );
    },
    [model]
  );

  const handleMoveDown = useCallback(
    index => {
      if (index === model.documents.length - 1) {
        return;
      }

      setModel(
        produce(model, draft => {
          const targetItem = draft.documents[index];
          const itemToBeSwitch = draft.documents[index + 1];

          draft.documents[index] = itemToBeSwitch;
          draft.documents[index + 1] = targetItem;
        })
      );
    },
    [model]
  );

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/tax-model')}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaTable size={20} color="#44546a" />
            <h1>Modelos Tributários</h1>
          </div>
        </Header>
        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button">
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {(loading || documentsLoading || !company) && (
          <FormLoading className="loading" />
        )}
        {model && documentsOptions && (
          <Content className="content">
            <FormContainer
              ref={formRef}
              loading={loading ? 1 : 0}
              onSubmit={handleSubmit}
              initialData={model}
            >
              <ModelInfo>
                <h4>MODELO</h4>
                <section>
                  <Input
                    name="model_name"
                    className="description"
                    label="Descrição"
                  />
                </section>
              </ModelInfo>
              <Documents>
                <header>
                  <h4>DOCUMENTOS</h4>
                  <button type="button" onClick={handleAddNewDocument}>
                    <FaPlus size={10} />
                  </button>
                  <button
                    type="button"
                    onClick={handleActiveOrdering}
                    className="order"
                  >
                    <FaExchangeAlt size={10} />
                  </button>
                </header>

                {requiredDocumentErrorMessage && (
                  <span>{requiredDocumentErrorMessage}</span>
                )}

                {model.documents &&
                  model.documents.map((document, index) => (
                    <Document key={document.company_document_id}>
                      <nav>
                        {isOrdering ? (
                          <>
                            <button
                              type="button"
                              onClick={() => handleMoveUp(index)}
                            >
                              <FaChevronUp size={10} />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleMoveDown(index)}
                            >
                              <FaChevronDown size={10} />
                            </button>
                          </>
                        ) : (
                          <button
                            type="button"
                            onClick={() =>
                              confirmRemove(
                                handleRemoveDocument,
                                document.model_document_id,
                                index
                              )
                            }
                          >
                            <FaMinus size={10} />
                          </button>
                        )}
                      </nav>

                      <Scope path={`documents[${index}]`}>
                        <section>
                          <Input name="model_document_id" className="hide" />
                          <Select
                            label="Documento"
                            name="company_document_id"
                            className="company_document_id"
                            options={documentsOptions}
                            placeholder="Selecione um documento"
                          />
                        </section>
                      </Scope>
                    </Document>
                  ))}
              </Documents>
            </FormContainer>
          </Content>
        )}
      </Container>

      {saveLoading && <Loading />}
    </>
  );
};

export default Form;

import React from 'react';

import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import { Container } from './styles';
import { TableContainer } from '~/components/Table';

const Report = ({ clients }) => {
  return (
    <Container>
      <TableContainer>
        <thead>
          <tr>
            <th className="active">Ativo</th>
            <th className="document">CPF/CNPJ</th>
            <th className="name">Nome</th>
            <th className="type">Tipo cliente</th>
            <th className="type">Tipo contrato</th>
            <th className="registration">Inscrição estadual</th>
            <th className="date">Data do contrato</th>
          </tr>
        </thead>
        <tbody>
          {clients &&
            clients.map(clientItem => (
              <tr key={clientItem.id} className="hover">
                <td className="active">
                  {clientItem?.active ? <FaThumbsUp /> : <FaThumbsDown />}
                </td>
                <td className="document">{clientItem?.document}</td>
                <td className="name">{clientItem?.name}</td>
                <td className="type">{clientItem?.client_type}</td>
                <td className="type">{clientItem?.contract_type}</td>
                <td className="registration">
                  {clientItem?.state_registration}
                </td>
                <td className="date">{clientItem?.contract_date}</td>
              </tr>
            ))}
        </tbody>
      </TableContainer>
    </Container>
  );
};

export default Report;

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Scope } from '@unform/core';
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import {
  FaPhoneAlt,
  FaSave,
  FaBroom,
  FaTimes,
  FaPlus,
  FaMinus,
} from 'react-icons/fa';
import * as Yup from 'yup';
import cep from 'cep-promise';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import formatContact from '~/util/formatContact';
import returnAddressesWithCitiesOptions from '~/util/returnAddressesWithCitiesOptions';

import { FormLoading, Select, Input, InputMask } from '~/components/Form';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Header,
  Controls,
  FormContainer,
} from '~/styles/components';
import {
  Content,
  RelatedPeopleInfo,
  Addresses,
  Address,
  ContactsList,
  Contact,
} from './styles';

const Form = () => {
  const { company } = useAuth();

  const { state } = useLocation();

  const id = state?.id || null;

  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [cepLoading, setCepLoading] = useState(false);

  const [contact, setContact] = useState(null);
  const [states, setStates] = useState(null);

  const [
    requiredContactsErrorMessage,
    setRequiredContactsErrorMessage,
  ] = useState('');

  useEffect(() => {
    async function loadContact() {
      if (id) {
        try {
          setLoading(true);

          const response = await api.get(`relationships/${id}`);

          const { data } = response;

          data.addresses = await returnAddressesWithCitiesOptions(
            data.addresses
          );

          data.contacts = data?.contacts?.map(contactItem => ({
            ...contactItem,
            content: contactItem.content
              ? formatContact(contactItem.type, contactItem.content)
              : '',
          }));

          setContact(data);
        } catch (err) {
          toast.error(
            `${err.response.data.message || 'Falha ao buscar contato.'}`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        } finally {
          setLoading(false);
        }
      } else {
        setContact({
          contacts: [
            {
              id: uuid(),
              type: 1,
              content: '',
            },
          ],
        });
      }
    }

    loadContact();
  }, [id]);

  useEffect(() => {
    function loadStates() {
      setStatesLoading(true);

      axios
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(response => {
          const ufInitials = response.data.map(uf => ({
            value: uf.sigla,
            label: uf.nome,
          }));
          setStates(ufInitials);
        });

      setStatesLoading(false);
    }
    loadStates();
  }, []);

  const contactsTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'E-mail' },
      { value: 2, label: 'Fixo' },
      { value: 3, label: 'Celular' },
    ];
  }, []);

  const addressTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'Sede da Empresa' },
      { value: 2, label: 'Correspondência' },
      { value: 3, label: 'Outros' },
    ];
  }, []);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            name: Yup.string().required('O nome é obrigatório.'),
            addresses: Yup.array().of(
              Yup.object().shape({
                zipcode: Yup.string().required('O CEP é obrigatório.'),
                street: Yup.string().required('O endereço é obrigatório.'),
                number: Yup.string().required('O número é obrigatório.'),
                neighborhood: Yup.string().required('O bairro é obrigatório.'),
                state: Yup.string().required('O estado é obrigatório.'),
                city: Yup.string().required('A cidade é obrigatória.'),
              })
            ),
            contacts: Yup.array()
              .min(1)
              .of(
                Yup.object().shape({
                  type: Yup.string().required(
                    'O tipo de contato é obrigatório.'
                  ),
                  content: Yup.string().required('O contato é obrigatório.'),
                })
              )
              .required('Pelo menos um contato é obrigatório.'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          data.company_id = company.id;
          data.type = 0;
          data.client_type = 1;

          let contact_id;
          let old_contact_id;

          const getOldData = await api.get('user-companies/old-data', {
            params: {
              company_id: company.id,
            },
          });

          const { old_company_id, mainid } = getOldData.data;

          if (id) {
            contact_id = id;

            const existedContact = await api.put(
              `relationships/${contact_id}`,
              data,
              {
                params: {
                  mainid,
                },
              }
            );

            old_contact_id = existedContact.data.old_id;
          } else {
            const createdContact = await api.post('relationships', data, {
              params: {
                old_company_id,
                mainid,
              },
            });

            contact_id = createdContact.data.id;
            old_contact_id = createdContact.data.old_id;
          }

          if (data.addresses) {
            const addressesData = data.addresses.map(address => {
              return {
                id: Number(address.id),
                owner_id: contact_id,
                street: address.street,
                zipcode: address.zipcode.replace(/[^0-9]+/g, ''),
                number: address.number,
                neighborhood: address.neighborhood,
                city: address.city,
                state: address.state,
                complement: address.complement,
              };
            });

            await api.post(
              'relationships/addresses',
              {
                addresses: addressesData,
              },
              {
                params: {
                  owner_id: contact_id,
                },
              }
            );
          }

          const contactsData = data.contacts.map(contactItem => {
            return {
              id: Number(contactItem.id),
              owner_id: contact_id,
              type: contactItem.type,
              content:
                contactItem.type === 1
                  ? contactItem.content
                  : contactItem.content.replace(/[^0-9]+/g, ''),
            };
          });

          await api.post(
            'relationships/contacts',
            {
              company_id: company.id,
              contacts: contactsData,
            },
            {
              params: {
                relationship_id: contact_id,
                old_relationship_id: old_contact_id,
                old_company_id,
                mainid,
              },
            }
          );

          formRef.current.setErrors({});

          toast.success('Contato salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);

          history.push('/contact', { id: null });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              if (error.path === 'contacts') {
                setRequiredContactsErrorMessage(error.message);
              }
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar contato.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setSaveLoading(false);
        }
      }
    },
    [id, company]
  );

  const handleResetForm = useCallback(() => {
    formRef.current.reset();
    formRef.current.setErrors({});
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={handleResetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleResetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/contact', { id: null })}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const confirmRemove = useCallback((functionToExecute, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => functionToExecute(index)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleAddNewAddress = useCallback(() => {
    setContact(
      produce(contact, draft => {
        const blankAddress = {
          id: uuid(),
          type: 1,
          state_label: '',
          owner_id: '',
          street: '',
          number: '',
          neighborhood: '',
          city: '',
          state: '',
          zipcode: '',
          complement: '',
        };

        if (draft?.addresses?.length > 0) {
          draft.addresses.unshift(blankAddress);
        } else if (draft?.addresses) {
          draft.addresses[0] = blankAddress;
        } else {
          draft.addresses = [blankAddress];
        }
      })
    );
  }, [contact]);

  const handleRemoveAddress = useCallback(
    async index => {
      setContact(
        produce(contact, draft => {
          draft.addresses.splice(index, 1);
        })
      );
    },
    [contact]
  );

  const handleAddNewContact = useCallback(() => {
    setContact(
      produce(contact, draft => {
        const blankContact = {
          id: uuid(),
          type: 1,
          content: '',
        };

        if (draft?.contacts?.length > 0) {
          draft.contacts.unshift(blankContact);
        } else if (draft?.contacts) {
          draft.contacts[0] = blankContact;
        } else {
          draft.contacts = [blankContact];
        }
      })
    );
  }, [contact]);

  const handleRemoveContact = useCallback(
    async index => {
      setContact(
        produce(contact, draft => {
          draft.addresses.splice(index, 1);
        })
      );
    },
    [contact]
  );

  const handleChangeContactType = useCallback(
    (event, index) => {
      setContact(
        produce(contact, draft => {
          draft.contacts[index].type = event.value;
        })
      );
    },
    [contact]
  );

  const handleChangeZipcode = useCallback(
    async (value, index) => {
      const unmaskedValue = value.replace(/[^0-9]+/g, '');

      if (unmaskedValue.length !== 8) {
        return;
      }

      try {
        setCepLoading(true);

        formRef.current.setErrors({});

        const cepData = await cep(unmaskedValue);

        const citiesOptions = await axios
          .get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${cepData.state}/municipios`
          )
          .then(response => {
            const cityNames = response.data.map(city => ({
              value: city.nome,
              label: city.nome,
            }));

            return cityNames;
          });

        setContact(
          produce(contact, draft => {
            const address = {
              id: uuid(),
              type: 1,
              zipcode: unmaskedValue,
              street: cepData.street,
              number: '',
              complement: '',
              neighborhood: cepData.neighborhood,
              state: cepData.state,
              city: cepData.city,
              citiesOptions,
            };

            draft.addresses[index] = address;
          })
        );

        const numberEl = document.getElementById('number');

        numberEl.focus();
      } catch (err) {
        formRef.current.setFieldError(
          `addresses[${index}].zipcode`,
          'CEP inválido.'
        );
      } finally {
        setCepLoading(false);
      }
    },
    [contact]
  );

  const handleChangeState = useCallback(
    async (value, index) => {
      const citiesOptions = await axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`
        )
        .then(response => {
          const cityNames = response.data.map(city => ({
            value: city.nome,
            label: city.nome,
          }));

          return cityNames;
        });

      setContact(
        produce(contact, draft => {
          if (draft.addresses[index]) {
            draft.addresses[index].state = value;
            draft.addresses[index].citiesOptions = citiesOptions;
          }
        })
      );
    },
    [contact]
  );

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaPhoneAlt size={20} color="#44546a" />
            <h1>Contatos</h1>
          </div>
        </Header>
        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {(loading || statesLoading || !company) && (
          <FormLoading className="loading" />
        )}
        {contact && states && (
          <Content className="content">
            <FormContainer
              ref={formRef}
              loading={loading ? 1 : 0}
              onSubmit={handleSubmit}
              initialData={contact}
            >
              <RelatedPeopleInfo>
                <h4>INFORMAÇÕES BÁSICAS</h4>
                <section>
                  <Input name="name" className="name" label="Nome" />
                </section>
                <section>
                  <Input name="obs" className="obs" label="Obs" />
                </section>
              </RelatedPeopleInfo>

              <Addresses>
                <header>
                  <h4>ENDEREÇOS</h4>
                  <button type="button" onClick={handleAddNewAddress}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {contact?.addresses?.map((address, index) => (
                  <Address key={address.id}>
                    <button
                      type="button"
                      onClick={() => confirmRemove(handleRemoveAddress, index)}
                    >
                      <FaMinus size={10} />
                    </button>

                    <Scope path={`addresses[${index}]`}>
                      <section>
                        <Input
                          name="id"
                          type="text"
                          className="hide"
                          readOnly
                        />
                        <Select
                          name="type"
                          className="address_type"
                          label="Tipo"
                          placeholder="Selecione um tipo"
                          options={addressTypeOptions}
                        />
                        <InputMask
                          name="zipcode"
                          className="zipcode"
                          label="CEP"
                          mask="99999-999"
                          onChange={e =>
                            handleChangeZipcode(e.target.value, index)
                          }
                        />
                        <Input
                          name="street"
                          className="street"
                          label="Endereço"
                        />
                        <Input
                          id="number"
                          name="number"
                          className="number"
                          label="Nº"
                        />
                      </section>
                      <section>
                        <Input
                          name="complement"
                          className="complement"
                          label="Complemento"
                        />
                        <Input
                          name="neighborhood"
                          className="neighborhood"
                          label="Bairro"
                        />
                        <Select
                          name="state"
                          className="state"
                          label="Estado"
                          placeholder="Selecione um estado"
                          options={states}
                          onChange={e => handleChangeState(e.value, index)}
                        />
                        {address.state && (
                          <Select
                            name="city"
                            className="city"
                            label="Cidade"
                            placeholder="Selecione uma cidade"
                            defaultValue={{
                              value: address.city,
                              label: address.city,
                            }}
                            options={address.citiesOptions}
                          />
                        )}
                      </section>
                    </Scope>
                  </Address>
                ))}
              </Addresses>

              <ContactsList>
                <header>
                  <h4>CONTATOS</h4>
                  <button type="button" onClick={handleAddNewContact}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {requiredContactsErrorMessage && (
                  <span>{requiredContactsErrorMessage}</span>
                )}

                {contact?.contacts?.map((contactItem, index) => (
                  <Contact key={contactItem.id}>
                    <button
                      type="button"
                      onClick={() => confirmRemove(handleRemoveContact, index)}
                    >
                      <FaMinus size={10} />
                    </button>
                    <Scope path={`contacts[${index}]`}>
                      <section>
                        <Input
                          name="id"
                          type="text"
                          className="hide"
                          readOnly
                        />
                        <Select
                          name="type"
                          className="contact_type"
                          label="Tipo"
                          options={contactsTypeOptions}
                          onChange={e => handleChangeContactType(e, index)}
                          placeholder="Selecione um tipo"
                        />
                        {contactItem.type === 1 && (
                          <Input
                            name="content"
                            className="content"
                            label="E-mail"
                            type="email"
                          />
                        )}
                        {contactItem.type === 2 && (
                          <InputMask
                            name="content"
                            className="content"
                            label="Fixo"
                            mask="(99) 9999-9999"
                          />
                        )}
                        {contactItem.type === 3 && (
                          <InputMask
                            name="content"
                            className="content"
                            label="Celular"
                            mask="(99) 99999-9999"
                          />
                        )}
                      </section>
                    </Scope>
                  </Contact>
                ))}
              </ContactsList>
            </FormContainer>
          </Content>
        )}
      </Container>

      {(saveLoading || cepLoading) && <Loading />}
    </>
  );
};

export default Form;

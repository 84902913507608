import React, { useRef, useContext, useState, useEffect } from 'react';
import {
  FaCalendarAlt,
  FaLandmark,
  FaHammer,
  FaStar,
  FaStarHalfAlt,
  FaRegStar,
  FaClipboardList,
  FaEnvelopeOpenText,
  FaHandshake,
} from 'react-icons/fa';
import { useDrag, useDrop } from 'react-dnd';

import BoardContext from '../context';

import { Container, Icon, Title, Content, Line } from './styles';

const Board = ({ data, lastData, index, taxPeriod, labourPeriod }) => {
  const ref = useRef();
  const { move } = useContext(BoardContext);

  const [stars, setStars] = useState([]);
  const [hasPendencies, setHasPendencies] = useState(false);

  useEffect(() => {
    if (data.type === 'kpi') {
      if (data.currentKpiNote) {
        const starsAux = [];
        for (let aux = 0; aux < 5; aux += 1) {
          if (data.currentKpiNote <= aux) {
            starsAux.push(0);
          } else if (data.currentKpiNote <= aux + 0.5) {
            starsAux.push(1);
          } else {
            starsAux.push(2);
          }
        }
        setStars(starsAux);
      } else {
        setStars([0, 0, 0, 0, 0]);
      }
    }

    if (data.type === 'protocol' && data.userProtocolsNotSend > 0) {
      setHasPendencies(true);
    }

    if (data.type === 'process-models' && data.processesLate > 0) {
      setHasPendencies(true);
    }
  }, [data]);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'BOARD', id: data.id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'BOARD',
    hover(item, monitor) {
      const draggedId = item.id;
      const draggedIndex = item.index;

      const targetId = data.id;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = (targetSize.bottom - targetSize.top) / 2;

      const draggedOffset = monitor.getClientOffset();
      const draggedLeft = draggedOffset.x - targetSize.left;

      if (draggedIndex < targetIndex && draggedLeft < targetCenter) {
        return;
      }

      if (draggedIndex > targetIndex && draggedLeft > targetCenter) {
        return;
      }

      move(draggedId, draggedIndex, targetId, targetIndex);

      item.index = targetIndex;
    },
  });

  dragRef(dropRef(ref));

  return (
    <Container
      ref={ref}
      isDragging={isDragging}
      className={hasPendencies ? 'has-pendencies' : ''}
    >
      {data.type === 'schedule' && (
        <>
          <Icon to={data.type} type={data.type}>
            <FaCalendarAlt />
          </Icon>
          <Title>Agenda</Title>
          <Content>
            <div>
              <span>
                Compromissos para hoje: <span>{data.todayAppointments}</span>
              </span>
            </div>
            <div>
              <span>
                Compromissos atrasados: <span>{data.lateAppointments}</span>
              </span>
            </div>
          </Content>
        </>
      )}
      {data.type === 'tax-checklist' && (
        <>
          <Icon to={data.type} type={data.type}>
            <FaLandmark />
          </Icon>
          <Title>Checklist Tributário</Title>
          <Content>
            <div>
              <span>
                Progresso {taxPeriod}: <span>{data.taxPercentageDone}%</span>
              </span>
              <progress value={data.taxPercentageDone} max="100" />
              <span className="competence">
                Mês anterior: {lastData.taxPercentageDone}%
              </span>
            </div>
          </Content>
        </>
      )}
      {data.type === 'labour-checklist' && (
        <>
          <Icon to={data.type} type={data.type}>
            <FaHammer />
          </Icon>
          <Title>Checklist Trabalhista</Title>
          <Content>
            <div>
              <span>
                Progresso {labourPeriod}:{' '}
                <span>{data.labourPercentageDone}%</span>
              </span>
              <progress value={data.labourPercentageDone} max="100" />
              <span className="competence">
                Mês anterior: {lastData.labourPercentageDone}%
              </span>
            </div>
          </Content>
        </>
      )}
      {data.type === 'kpi' && (
        <>
          <Icon to={data.type} type={data.type}>
            <FaHandshake />
          </Icon>
          <Title>KPI</Title>
          <Content>
            <div>
              <span>
                Nota {data.period}:
                <span>
                  {stars.map((star, indexStar) => {
                    if (star === 0) {
                      return <FaRegStar key={indexStar} color="#f4c306" />;
                    }
                    if (star === 1) {
                      return <FaStarHalfAlt key={indexStar} color="#f4c306" />;
                    }
                    return <FaStar key={indexStar} color="#f4c306" />;
                  })}
                </span>
              </span>
              <Line />
              <span className="competence">
                Mês anterior: {data.lastKpiNote || 0}%
              </span>
            </div>
          </Content>
        </>
      )}
      {data.type === 'process-models' && (
        <>
          <Icon to={data.type} type={data.type}>
            <FaClipboardList />
          </Icon>
          <Title>Processos</Title>
          <Content>
            <div>
              <span>Novos processos: {data.processesNotView}</span>
              <Line />
              <span>Processos em atraso: {data.processesLate}</span>
            </div>
          </Content>
        </>
      )}
      {data.type === 'protocol' && (
        <>
          <Icon to={data.type} type={data.type}>
            <FaEnvelopeOpenText />
          </Icon>
          <Title>Protocolos</Title>
          <Content>
            <div>
              <span>Não entregues: {data.companyProtocolsNotRecived || 0}</span>
              <Line style={{ marginTop: '5px' }} />
              <span>Não enviados: {data.userProtocolsNotSend || 0}</span>
              <Line style={{ marginTop: '5px' }} />
              <span>
                Totais de não enviados: {data.companyProtocolsNotSend || 0}
              </span>
            </div>
          </Content>
        </>
      )}
    </Container>
  );
};

export default Board;

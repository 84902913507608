import styled from 'styled-components';

export const Container = styled.div`
  display: ${props => (props.loading ? 'none' : 'flex')};
  padding: 10px;
`;

export const Content = styled.div`
  display: flex;
  width: 95vw;
  justify-content: space-between;
  align-items: center;

  div {
    color: #44546a;
    font-size: 10px;
  }

  aside {
    display: flex;
    align-items: center;

    button {
      background: #fcfcfc;
      border: 1px solid #44546a;
      border-radius: 4px;
      padding: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.2s;

      & + button {
        margin-left: 5px;
      }

      &:hover {
        background: #44546a;

        & > svg {
          color: #fcfcfc;
        }
      }

      svg {
        color: #44546a;
      }
    }

    span {
      color: ${({ theme }) => theme.colors.grey};
      font-weight: bold;
      font-size: 12px;
      margin: 0 10px;
    }
  }
`;

import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { FaDownload } from 'react-icons/fa';
import logoDiretiva1 from '~/assets/sign-in-logo.svg';

import api from '~/services/api';

import { Container, Title, Content, UpdateInfo } from './styles';

const NewContentAvailableNotification = ({ updateServiceWorker }) => {
  const [markdown, setMarkdown] = useState(null);

  useEffect(() => {
    async function getContentMessage() {
      const response = await api.get('new-content');

      if (response.data !== '') {
        setMarkdown(response.data);
      }
    }

    getContentMessage();
  }, []);

  return (
    <Container id="new-updates">
      <Content>
        <section>
          <img src={logoDiretiva1} alt="update" />
        </section>
        <section>
          <Title>
            <FaDownload size={20} color="#01579B" />
            <h2>Nova Versão</h2>
          </Title>
          <UpdateInfo>
            {markdown && (
              <ReactMarkdown allowDangerousHtml>{markdown}</ReactMarkdown>
            )}
          </UpdateInfo>
          <button type="button" onClick={updateServiceWorker}>
            Atualizar
          </button>
        </section>
      </Content>
    </Container>
  );
};

export default NewContentAvailableNotification;

import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  button {
    background: transparent;
    border: none;
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div + div {
    margin-left: 16px;
  }

  .description,
  .document_type,
  .taxation_type,
  .department,
  .folder,
  .main_document {
    width: 16%;
  }

  button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;
    width: 4%;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .barcode {
      padding-left: 8px;
      width: 5vw;
    }

    .abrev {
      width: 10vw;
    }

    .description {
      width: 50vw;
    }

    .folder {
      width: 30vw;
    }

    .status {
      width: 5vw;
      text-align: center;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Document = styled.div`
  display: flex;
  flex-direction: column;

  .first-section {
    .abbreviation,
    .description,
    .folder,
    .document_type {
      width: 20%;
    }
  }

  .second-section {
    .territorial_scope,
    .state,
    .city {
      width: 20%;
    }
  }

  .third-section {
    .periodicity_type,
    .due_date_type,
    .date_type,
    .specific_day_month,
    .specific_day,
    .date_calculation {
      width: 20%;
    }
  }

  .fourth-section {
    .start_date,
    .end_date,
    .deadline_days {
      width: 20%;
    }
  }

  .fifth-section {
    flex-direction: column;
    align-items: flex-start;

    .title_to_pay,
    .main_document,
    .use_in_checklist,
    .allow_manual_mark_on_checklist,
    .auto_upload,
    .show_in_labour_checklist_if_has_employee,
    .skip_charge_inactive_clients {
      width: 100%;
      margin-left: 0px;

      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;

      > input {
        margin-right: 16px;
        transform: scale(1.5);
      }
    }
  }
`;

export const Department = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .dpt_labour,
  .dpt_tax,
  .dpt_accounting,
  .dpt_financial,
  .dpt_admin,
  .dpt_bpo {
    width: 15%;

    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    > input {
      margin-right: 16px;
      transform: scale(1.5);
    }
  }
`;

export const Taxation = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  section {
    flex-wrap: wrap;

    div {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;

      > input {
        margin-right: 16px;
        transform: scale(1.5);
      }
    }

    .taxation_empregador_pf,
    .taxation_empregador_domestico,
    .taxation_mei,
    .taxation_mei_somente_declaracoes,
    .taxation_simples_com_inscricao,
    .taxation_simples_sem_inscricao {
      width: calc(20% - 16px);
    }

    .taxation_simples_sem_inscricao {
      margin-left: 0;
    }

    .taxation_lucro_presumido,
    .taxation_lucro_real,
    .taxation_condominio,
    .taxation_terceiro {
      width: calc(20% - 16px);
    }
  }
`;

import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { setDate, setMonth, addMinutes, isWithinInterval } from 'date-fns';
import { FaEnvelope, FaLock, FaCopyright } from 'react-icons/fa';

import { useAuth } from '../../hooks';

import history from '~/services/history';

import getValidationErrors from '../../util/getValidationErrors';

import { ButtonSpinner } from '../../components/Spinner';
import Input from './Input';

import defaultBackground from '../../assets/default.jpg';
import newYearBackground from '../../assets/new-year.jpg';
import downBackground from '../../assets/down.jpg';
import easterBackground from '../../assets/easter.jpg';
import motherBackground from '../../assets/mother.jpg';
import fatherBackground from '../../assets/father.jpg';
import yellowSeptemberBackground from '../../assets/yellow-september.jpg';
import pinkOctoberBackground from '../../assets/pink-october.jpg';
import blueNovemberBackground from '../../assets/blue-november.jpg';
import christmasBackground from '../../assets/christmas.jpg';
import accountingBackground from '../../assets/accounting.jpg';

import defaultLogo from '../../assets/sign-in-logo.svg';
import yellowSeptemberLogo from '../../assets/sign-in-logo-yellow-september.svg';
import pinkOctoberLogo from '../../assets/sign-in-logo-pink-october.svg';
import christmasLogo from '../../assets/sign-in-logo-christmas.svg';

import { Container, Background, Content, AnimationContainer } from './styles';

const SignIn = () => {
  const { signIn } = useAuth();

  const formRef = useRef(null);

  const [backgroundLoading, setBackgroundLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState('#01579b');
  const [message, setMessage] = useState(`
    <p>Eleve seu escritório</p>
    <p>ao próximo nível.</p>
  `);

  useEffect(() => {
    setBackgroundLoading(true);

    // add 1 minute for don´t conflit with start date
    const currentDate = addMinutes(new Date(), 1);
    const background = document.getElementById('background');
    const logo = document.getElementById('logo');

    if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 0), 1),
        end: setDate(setMonth(new Date(), 0), 15),
      })
    ) {
      background.style.background = `url(${newYearBackground}) no-repeat center`;
      logo.setAttribute('src', defaultLogo);
      setMessage(`
        <p>Embora ninguém possa volta atrás e fazer</p>
        <p>um novo começo, qualquer um pode começar</p>
        <p>agora e fazer um novo fim. Feliz Ano novo!</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 2), 15),
        end: setDate(setMonth(new Date(), 2), 31),
      })
    ) {
      background.style.background = `url(${downBackground}) no-repeat center`;
      logo.setAttribute('src', defaultLogo);
      setMessage(`
        <p>21/03</p>
        <p>Dia Internacional da Síndrome de Down</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 3), 1),
        end: setDate(setMonth(new Date(), 3), 10),
      })
    ) {
      background.style.background = `url(${easterBackground}) no-repeat center`;
      logo.setAttribute('src', defaultLogo);
      setMessage(`
        <p>Eu sou a ressurreição e a vida.</p>
        <p>Quem crê em mim viverá para sempre!</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 3), 20),
        end: setDate(setMonth(new Date(), 3), 30),
      })
    ) {
      background.style.background = `url(${accountingBackground}) no-repeat center`;
      logo.setAttribute('src', defaultLogo);
      setMessage(`
        <p>Abril é mês dos Profissionais </p>
        <p>da Área Contábil. </p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 4), 1),
        end: setDate(setMonth(new Date(), 4), 15),
      })
    ) {
      background.style.background = `url(${motherBackground}) no-repeat center`;
      logo.setAttribute('src', defaultLogo);
      setMessage(`
        <p>Maio</p>
        <p>Mês das mães</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 7), 1),
        end: setDate(setMonth(new Date(), 7), 15),
      })
    ) {
      background.style.background = `url(${fatherBackground}) no-repeat center`;
      logo.setAttribute('src', defaultLogo);
      setMessage(`
        <p>Agosto</p>
        <p>Mês dos pais</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 8), 1),
        end: setDate(setMonth(new Date(), 8), 15),
      })
    ) {
      background.style.background = `url(${yellowSeptemberBackground}) no-repeat center`;
      logo.setAttribute('src', yellowSeptemberLogo);
      setColor('#ffbf00');
      setMessage(`
        <p>Setembro Amarelo</p>
        <p>Mês de prevenção ao suicídio.</p>
        <p>Peça ajuda. Ligue 188.</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 8), 20),
        end: setDate(setMonth(new Date(), 8), 30),
      })
    ) {
      background.style.background = `url(${accountingBackground}) no-repeat center`;
      logo.setAttribute('src', defaultLogo);
      setMessage(`
        <p>Setembro é mês dos Contadores.</p>
        <p>Parabéns a todos os profissionais da área!</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 9), 1),
        end: setDate(setMonth(new Date(), 9), 15),
      })
    ) {
      background.style.background = `url(${pinkOctoberBackground}) no-repeat center`;
      logo.setAttribute('src', pinkOctoberLogo);
      setColor('#FE5088');
      setMessage(`
        <p>Outubro Rosa</p>
        <p>Mês de prevenção ao câncer de mama.</p>
        <p>Cuide-se: faça o autoexame!</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 10), 1),
        end: setDate(setMonth(new Date(), 10), 15),
      })
    ) {
      background.style.background = `url(${blueNovemberBackground}) no-repeat center`;
      logo.setAttribute('src', defaultLogo);
      setColor('#01579b');
      setMessage(`
        <p>Novembrol Azul</p>
        <p>Mês de prevenção ao câncer de próstata</p>
        <p>Cuide-se: procure seu urologista!</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 11), 1),
        end: setDate(setMonth(new Date(), 11), 31),
      })
    ) {
      background.style.background = `url(${christmasBackground}) no-repeat center`;
      logo.setAttribute('src', christmasLogo);
      setColor('#d3081b');
      setMessage(`
        <p>Hoje, na cidade de Davi, nasceu para vós</p>
        <p>o Salvador, que é o Cristo Senhor!</p>
      `);
    } else {
      background.style.background = `url(${defaultBackground}) no-repeat center`;
      logo.setAttribute('src', defaultLogo);
    }

    setBackgroundLoading(false);
  }, []);

  const handleSubmit = useCallback(
    async ({ email, password }) => {
      try {
        setLoading(true);

        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Insira um e-mail válido!')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(
          { email, password },
          {
            abortEarly: false,
          }
        );

        await signIn({
          email,
          password,
        });

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
        }

        setLoading(false);
      }
    },
    [signIn]
  );

  return (
    <Container loading={loading ? 1 : 0}>
      {!backgroundLoading && (
        <Background id="background">
          <div
            className="message"
            dangerouslySetInnerHTML={{ __html: message }}
          />

          <div className="slogan">
            <p>Tenha todo o controle </p>
            <p>de sua empresa em um só lugar.</p>
          </div>
        </Background>
      )}
      <Content>
        <AnimationContainer color={color}>
          <img id="logo" alt="logo" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu login</h1>

            <Input
              name="email"
              icon={FaEnvelope}
              type="email"
              placeholder="E-mail"
              color={color}
            />

            <Input
              name="password"
              icon={FaLock}
              type="password"
              placeholder="Senha"
              color={color}
            />

            <button type="submit">
              {loading ? <ButtonSpinner size={14} /> : 'Entrar'}
            </button>
          </Form>
        </AnimationContainer>
        <footer>
          <div>
            <FaCopyright />
            <strong>2020 - Contabilizar Digital</strong>
          </div>
          <span>Todos os direitos reservados</span>
        </footer>
      </Content>
    </Container>
  );
};

export default SignIn;

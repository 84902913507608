import React, { useRef, useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { IoMdCloseCircle } from 'react-icons/io';
import { Container } from './styles';

const TextArea = ({
  name,
  label,
  className,
  copyFile,
  setCopyFile,
  ...rest
}) => {
  const textAreaRef = useRef(null);

  const {
    defaultValue,
    fieldName,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    const handlePaste = event => {
      const { items } = event.clipboardData || window.clipboardData;
      Array.from(items).forEach(item => {
        if (item.type.indexOf('image') !== -1) {
          const blob = item.getAsFile();
          const reader = new FileReader();
          reader.onload = ev => {
            setCopyFile(ev.target.result);
          };
          reader.readAsDataURL(blob);
        }
      });
    };

    const textArea = textAreaRef.current;
    textArea.addEventListener('paste', handlePaste);

    return () => {
      textArea.removeEventListener('paste', handlePaste);
    };
  }, [setCopyFile]);

  const handleRemoveImage = () => {
    setCopyFile(null);
  };

  return (
    <Container className={className} error={error ? 1 : 0}>
      <label htmlFor={fieldName}>{label}</label>
      <TextareaAutosize
        id={fieldName}
        defaultValue={defaultValue}
        ref={textAreaRef}
        onFocus={clearError}
        {...rest}
      />
      {copyFile && (
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            marginTop: '10px',
          }}
        >
          <button
            type="button"
            onClick={handleRemoveImage}
            style={{
              top: '0',
              right: '0',
              background: 'white',
              border: 'none',
              padding: '0',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            <IoMdCloseCircle
              color="red"
              size={20}
              style={{
                transform: 'none',
                position: 'absolute',
                marginLeft: '500px',
                marginTop: '10px',
              }}
            />
          </button>
          <img
            src={copyFile}
            alt="Imagem colada"
            style={{ maxWidth: '250px', maxHeight: '250px' }}
          />
        </div>
      )}
      {error && <span>{error}</span>}
    </Container>
  );
};

export default TextArea;

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  copyFile: PropTypes.string,
  setCopyFile: PropTypes.func,
};

TextArea.defaultProps = {
  label: null,
  className: null,
  copyFile: null,
  setCopyFile: () => {},
};

import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { Container, Icon, Header, Content } from './styles';

const ConfirmWindow = ({ onClick, onClose, document }) => {
  return (
    <Container>
      <Icon>
        <FaExclamationTriangle size={38} color="#01579b" />
      </Icon>
      <Content>
        <Header>
          <strong>Atenção</strong>
        </Header>
        <span>
          <strong>Tem certeza?</strong> <span>Marcar </span>
          <strong>{document.documentName}</strong>
          <span> da empresa </span>
          <strong>{document.clientName}</strong>{' '}
          <span>
            como <strong>realizado</strong> em{' '}
            <strong>{document.formattedPeriod}?</strong>
          </span>
        </span>
        <div>
          <section>
            <button
              type="button"
              onClick={() => {
                onClick(
                  document.documentId,
                  4,
                  document.documentType,
                  document.allowMark
                );
                onClose();
              }}
            >
              Ok
            </button>
            <button type="button" onClick={onClose}>
              Não
            </button>
          </section>
          <section>
            <button
              type="button"
              onClick={() => {
                onClick(
                  document.documentId,
                  3,
                  document.documentType,
                  document.allowMark
                );
                onClose();
              }}
            >
              Inexistente
            </button>
            <button
              type="button"
              onClick={() => {
                onClick(
                  document.documentId,
                  1,
                  document.documentType,
                  document.allowMark
                );
                onClose();
              }}
            >
              Não realizado
            </button>
          </section>
        </div>
      </Content>
    </Container>
  );
};

export default ConfirmWindow;

ConfirmWindow.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

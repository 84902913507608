import React, { useState, useCallback } from 'react';
import { Input } from '@rocketseat/unform';
import { FaTimes, FaDesktop, FaTv } from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import ClearBackground from '~/components/ClearBackground';
import { ButtonSpinner } from '~/components/Spinner';

import { Container, Badge, Content, Left, Right, Header } from './styles';

const Tv = () => {
  const { company, user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleSubmit = useCallback(
    async (data, { resetForm }) => {
      if (user && company) {
        setLoading(true);
        const response = await api.post(
          `tv/?oldCompanyId=${company.old_id}&oldUserId=${user.old_id}&name=${data.name}`
        );

        if (response) {
          setLoading(false);
          resetForm();
          setVisible(false);
        }
      }
    },
    [company, user]
  );

  return (
    <>
      <Container visible={visible}>
        <Badge onClick={handleVisible}>
          <FaDesktop size={18} color="#fcfcfc" />
          <span>Boas Vindas</span>
        </Badge>

        <Content onSubmit={handleSubmit} visible={visible ? 1 : 0}>
          <Left>
            <FaTv size={38} color="#01579b" />
          </Left>
          <Right>
            <Header>
              <FaTimes onClick={handleVisible} size={14} color="#e53935" />
              <strong>Digite o nome do cliente:</strong>
            </Header>
            <Input name="name" type="text" />
            <button type="submit">
              {loading ? <ButtonSpinner /> : 'Enviar'}
            </button>
          </Right>
        </Content>
      </Container>
      {visible && <ClearBackground />}
    </>
  );
};

export default Tv;

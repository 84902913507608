import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: ${props => (props.isDisabled ? '0.4' : '1')};
  pointer-events: ${props => (props.isDisabled ? 'none' : 'all')};

  > label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  > input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: ${props =>
      props.error ? '1px solid #e53935' : '1px solid #ddd'};
  }

  > span {
    position: absolute;
    top: 45px;
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 25px;
  right: 15px;
`;

export const SelectContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 40px;

  > div {
    width: 100%;
  }
`;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { FaTimes, FaEraser, FaSearch, FaAddressCard } from 'react-icons/fa';

import api from '~/services/api';

import { Input } from '~/components/Form';
import Modal from '~/components/Modal';
import { TableLoading, TableContainer } from '~/components/Table';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';

import { Container, Header, Controls, Filter, Content } from './styles';

const MigrateClient = ({ isOpen, setIsOpen }) => {
  const filterRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [migrateLoading, setMigrateLoading] = useState(false);

  const [clienteToMigrate, setClientsToMigrate] = useState([]);

  const [nameSearch, setNameSearch] = useState('');

  const loadClientsToMigrate = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/migrate-client', {
        params: {
          name: nameSearch,
        },
      });

      if (response.data.length > 0) {
        setClientsToMigrate(response.data);
      } else {
        setClientsToMigrate([]);
        toast.warn('Nenhum empresa foi encontrado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch {
      toast.error('Falha ao buscar empresas.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    setLoading(false);
  }, [nameSearch]);

  useEffect(() => {
    loadClientsToMigrate();
  }, [loadClientsToMigrate]);

  const handleFilter = useCallback(({ name }) => {
    setNameSearch(name);
  }, []);

  const handleResetFilter = useCallback(() => {
    filterRef.current.reset();

    setNameSearch('');
  }, []);

  const handleMigrateClient = useCallback(
    async id => {
      try {
        setMigrateLoading(true);

        await api.post('/migrate-client', { id });

        loadClientsToMigrate();

        toast.success('Empresa migrada com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch {
        toast.error('Falha ao migrar empresas.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setMigrateLoading(false);
    },
    [loadClientsToMigrate]
  );

  const confirmMigrateClient = useCallback(
    (id, name) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => handleMigrateClient(id)}
              onClose={onClose}
              message={`Deseja migrar a empresa ${name}?`}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleMigrateClient]
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaAddressCard size={20} color="#44546a" />
            <h1>Migrar Clientes</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>
        <Controls>
          <button type="button" onClick={handleResetFilter}>
            <FaEraser size={15} color="#44546a" />
            <span>Limpar filtros</span>
          </button>
        </Controls>
        <Filter ref={filterRef} onSubmit={handleFilter}>
          <div>
            <Input
              name="name"
              className="name"
              label="Nome"
              defaultValue={nameSearch}
            />
          </div>

          <button type="submit">
            <FaSearch />
          </button>
        </Filter>

        {loading ? (
          <TableLoading />
        ) : (
          <Content className="content">
            <TableContainer>
              <thead>
                <tr>
                  <th className="name">Nome</th>
                  <th className="migrate" />
                </tr>
              </thead>
              <tbody>
                {clienteToMigrate.map(item => (
                  <tr key={item.id}>
                    <td className="name">{item.name || ''}</td>
                    <td className="migrate">
                      <button
                        type="button"
                        onClick={() => confirmMigrateClient(item.id, item.name)}
                      >
                        Migrar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          </Content>
        )}
      </Container>
      {migrateLoading && <Loading />}
    </Modal>
  );
};

export default MigrateClient;

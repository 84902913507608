import React from 'react';
import { Redirect } from 'react-router-dom';

const RedirectProcessPortal = ({ path, computedMatch }) => {
  const { companyId, modelId } = computedMatch.params;

  localStorage.setItem('@Diretiva:process:companyId', companyId);
  localStorage.setItem('@Diretiva:process:modelId', modelId);

  if (path === '/redirect/:companyId/:modelId') {
    return <Redirect to="/process-portal/signin" />;
  }

  return <Redirect to="/signin" />;
};

export default RedirectProcessPortal;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;

  padding: 8px;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors.lightBorder};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey};
  position: relative;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: ${({ theme }) => theme.colors.error};
      color: ${({ theme }) => theme.colors.error};
    `}

  ${props =>
    props.isFocused &&
    css`
      color: ${props.color};
      border-color: ${props.color};
      background: ${({ theme }) => theme.colors.white};
    `}

  ${props =>
    props.isFilled &&
    css`
      border-color: ${({ theme }) => theme.colors.grey};
      color: ${({ theme }) => theme.colors.grey};
    `}

  input {
    flex: 1;
    background: transparent;
    border: 0;
    transition: color 0.2s, border-color 0.2s;
  }

  label {
    position: absolute;
  }

  svg {
    margin-right: 8px;
  }

`;

export const Error = styled.div`
  ${props =>
    props.isFocused &&
    css`
      display: none;
    `}

  ${props =>
    props.isFilled &&
    css`
      display: none;
    `}
`;

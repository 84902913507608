import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useProcessAuth } from '~/hooks';
import Layout from '~/pages/ProcessPortal/Layout';

const ProcessPortalRoute = ({ component: Component, path, ...rest }) => {
  const { isLogged } = useProcessAuth();

  if (path !== '/process-portal/signin' && !isLogged()) {
    return <Redirect to="/process-portal/signin" />;
  }
  if (path === '/process-portal/signin' && isLogged()) {
    return <Redirect to="/process-portal" />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default ProcessPortalRoute;

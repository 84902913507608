import styled from 'styled-components';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  .hide {
    display: none;
  }
`;

export const Folder = styled.div`
  display: flex;
  flex-direction: column;

  .description {
    width: 50%;
  }

  .department,
  .color {
    width: 25%;
  }

  .portal {
    width: 100%;
    height: 44px;
  }
`;

export const SubFolderList = styled.div`
  margin-top: 16px;
  position: relative;

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 16px;
      margin-left: 8px;
      background: none;
      color: ${({ theme }) => theme.colors.blue};
      border: 1px solid ${({ theme }) => theme.colors.blue};
      border-radius: 50%;
      padding: 2px;
      transition: opacity 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const SubFolder = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  button {
    position: absolute;
    top: 40%;
    background: none;
    color: ${({ theme }) => theme.colors.blue};
    border: 1px solid ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    padding: 2px;
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }
  }

  section {
    padding-left: 16px;
  }

  .description {
    margin-left: 0;
    width: 100%;
  }
`;

import styled from 'styled-components';
import { Form } from '@unform/web';
import { darken } from 'polished';

export const Container = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10;
`;

export const Content = styled.div`
  width: 500px;
  background: #fafafa;
  padding: 25px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;

  strong {
    margin-bottom: 16px;
  }
`;

export const Options = styled.div`
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  section {
    width: 100%;
    display: flex;

    & + section {
      margin-top: 8px;
    }
  }

  button {
    padding: 8px;
    border-radius: 4px;
    color: #fff;
    transition: background 0.2s;
    flex: 1;

    & + button {
      margin-left: 16px;
    }
  }

  section:nth-child(1) {
    button:nth-child(1) {
      background: #00c853;
      padding-left: 20px;
      padding-right: 20px;

      &:hover {
        background: ${darken(0.05, '#00c853')};
      }
    }

    button:nth-child(2) {
      background: #ee4256;
      padding-left: 20px;
      padding-right: 20px;

      &:hover {
        background: ${darken(0.05, '#ee4256')};
      }
    }
  }

  section:nth-child(2) {
    width: 100%;
    display: flex;

    button {
      background: #01579b;

      &:hover {
        background: ${darken(0.05, '#01579B')};
      }
    }
  }
`;

export const Left = styled.aside`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const Right = styled.main`
  font-size: 16px;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  position: relative;

  strong {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;

export const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;

  strong {
    margin: 16px 0 0 0;
  }

  > div {
    width: 100%;
    margin-top: 16px;
  }

  > button {
    margin-top: 16px;
    padding: 8px;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
    transition: background 0.2s;
    padding-left: 20px;
    padding-right: 20px;

    background: ${({ theme }) => theme.colors.success};
    margin-right: 16px;

    &:hover {
      background: ${({ theme }) => theme.hover.success};
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    position: relative;
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    > div {
      & + div {
        margin-left: 16px;
      }
    }
  }

  .loading {
    position: absolute;
    z-index: 2;
  }

  .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  button {
    background: transparent;
    border: none;
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const Content = styled.div`
  overflow: auto;

  display: flex;
  flex-direction: column;
  flex: 1;

  .hide {
    display: none;
  }
`;

export const ParameterInfo = styled.div`
  display: flex;
  flex-direction: column;

  .client,
  .model,
  .start_period,
  .end_period,
  .active {
    width: 25%;
    opacity: 1;
  }

  .obs {
    width: 100%;
  }
`;

export const ResponsibleList = styled.div`
  margin-top: 16px;
  position: relative;

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 16px;
      margin-left: 8px;
      background: none;
      color: ${({ theme }) => theme.colors.blue};
      border: 1px solid ${({ theme }) => theme.colors.blue};
      border-radius: 50%;
      padding: 2px;
      transition: opacity 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  > span {
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 20px;
  }
`;

export const Responsible = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  > button {
    position: absolute;
    top: 45%;
    background: none;
    color: ${({ theme }) => theme.colors.blue};
    border: 1px solid ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    padding: 2px;
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }
  }

  section {
    padding-left: 16px;
  }

  .responsible {
    width: 50%;
    margin-left: 0;
  }

  .responsible_start_period,
  .responsible_end_period {
    width: 25%;
  }
`;

export const Documents = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  .status {
    width: 5%;
  }

  .document {
    opacity: 1;
    width: 65%;
  }

  .obs {
    width: 25%;
  }
`;

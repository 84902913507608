import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blue};
  font-size: 1rem;

  .logo {
    display: flex;
    align-items: center;

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
    }

    img {
      border: 0;
      border-radius: 50%;
      height: 30px;
      width: 30px;
    }

    span {
      margin-left: 10px;
    }
  }

  .user {
    text-align: center;
    width: 100%;
    font-size: 20px;
  }

  .logout {
    display: flex;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 80px;
      padding: 10px;
      border: 0;
      margin-left: 10px;
      font-size: 16px;

      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.blue};
      transition: background 0.3s;

      &:hover {
        background: ${({ theme }) => theme.hover.blue};
      }
    }

    span {
      margin-right: 10px;
    }
    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  @media (max-width: 650px) {
    .user {
      display: none;
    }

    .logout {
      span {
        display: none;
      }
    }
  }

  @media (min-width: 650px) {
    > div {
      width: 30%;
    }
  }
`;

import styled, { keyframes, css } from 'styled-components';
import { lighten, darken } from 'polished';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Scroll = styled(PerfectScrollbar)`
  max-height: 300px;
  padding: 8px 10px;

  h3 {
    color: ${({ theme }) => theme.colors.grey};
    text-align: center;
  }
`;

export const Container = styled.div`
  position: relative;
  z-index: ${props => (props.visible ? '10' : '8')};
`;

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  margin-left: 16px;

  label {
    position: absolute;
    right: 3px;
    top: 0;
    z-index: 1;
    font-size: 7px;
    color: #fff;
  }

  ${props =>
    props.hasNotifications &&
    css`
      & {
        svg {
          color: #ee4256 !important;
        }

        label {
          color: #000;
          font-weight: bold;
        }
      }

      &::after {
        position: absolute;
        right: 0;
        top: 0;
        width: 10px;
        height: 10px;
        background: #fff;
        content: '';
        border-radius: 50%;
      }
    `}

  &:hover span {
    visibility: visible;
    top: 180%;
    opacity: 1;
  }

  span {
    visibility: hidden;
    width: 120px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fcfcfc;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 10002;
    top: 150%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.8s;
  }

  span:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${({ theme }) => theme.colors.primary}
      transparent;
  }
`;

const animation = keyframes`
  from {
    visibility: hidden;
    clip-path: inset(0px 0px 100% 100% round 5px);
  }
  to {
    visibility: visible;
    clip-path: inset(0px 0px 0px 0px round 5px);
  }
`;

export const NotificationList = styled.div`
  visibility: hidden;
  position: absolute;
  top: 40px;
  background: #fafafa;
  border-radius: 4px;
  width: 330px;
  right: -15px;
  padding: 10px 8px;

  ${props =>
    props.visible &&
    css`
      animation: ${animation} 0.75s forwards cubic-bezier(0.25, 1, 0.25, 1);
    `}

  &:before {
    content: '';
    width: 15px;
    height: 15px;
    background-color: #fcfcfc;
    position: absolute;
    top: -12px;
    right: 17px;
    transform: translateY(0.5em) rotate(45deg);
  }
`;

export const Title = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px 5px 10px;

  div {
    display: flex;
    align-items: center;

    margin-left: 10px;

    h3 {
      margin-left: 15px;
      color: #01579b;
    }
  }

  > svg {
    margin-right: 5px;
    cursor: pointer;
  }
`;

export const Notification = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0;

  & + div {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
  }

  .conclusion {
    margin-left: 3px;
    color: ${({ theme }) => theme.colors.success};
  }

  .extension {
    margin-left: 3px;
    color: ${({ theme }) => theme.colors.orange};
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  margin-right: 10px;
`;

export const NotificationContent = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.grey};
  padding-left: 10px;
  color: ${({ theme }) => theme.colors.grey};

  h4 {
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 5px;

    strong {
      color: #000;
    }
  }

  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;

    margin-left: 5px;
    margin-bottom: 5px;
    word-wrap: break-word;
    max-width: 250px;
    font-size: 12px;
  }

  time {
    display: block;
    font-size: 11px;
    opacity: 0.6;
  }
`;

export const Buttons = styled.div`
  margin-top: 5px;
  width: 240px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ViewButton = styled.button`
  background: #01579b !important;
  color: #fafafa;
  padding: 5px;
  margin-left: 0 !important;
  border-radius: 4px;
  font-size: 11px;
  transition: background 0.2s;

  &:hover {
    background: ${lighten(0.05, '#01579b')} !important;
  }
`;

export const MaskAsReadButton = styled.button`
  color: ${({ theme }) => theme.colors.grey};
  font-size: 11px;
  border: 0;
  background: none;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

export const RequestButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    color: #fafafa;
    padding: 5px;
    border-radius: 4px;
    font-size: 11px;
    transition: background 0.2s;

    &:nth-child(1) {
      background: #00c853;

      &:hover {
        background: ${darken(0.05, '#00c853')};
      }
    }

    &:nth-child(2) {
      margin-left: 8px;
      background: #ee4256;

      &:hover {
        background: ${darken(0.05, '#ee4256')};
      }
    }
  }
`;

export const Justification = styled.div`
  margin-top: 8px;

  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: flex-end;

  label {
    font-size: 12px;
    opacity: 0.6;
  }

  input {
    margin-top: 4px;
    background: none;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    padding: 2px;
    width: 215px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    margin-left: 8px !important;
    border: 1px solid #00c853 !important;
    border-radius: 50%;
    background: #00c853 !important;
    color: #fafafa;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.05, '#00c853')} !important;
    }

    > svg {
      transform: rotate(45deg);
    }
  }
`;

export const KPIFeedback = styled.div`
  margin-top: 8px;

  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;

  label {
    font-size: 12px;
    opacity: 0.6;
  }

  input {
    margin-top: 4px;
    background: none;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    padding: 2px;
    width: 100%;
  }

  .message {
    width: 150px;
  }

  .note {
    width: 50px;
    margin-left: 8px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    margin-left: 8px !important;
    border: 1px solid #00c853 !important;
    border-radius: 50%;
    background: #00c853 !important;
    color: #fafafa;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.05, '#00c853')} !important;
    }

    > svg {
      transform: rotate(45deg);
    }
  }
`;

export const RequestExtensionDate = styled.div`
  margin-top: 8px;

  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: flex-end;

  label {
    font-size: 12px;
    opacity: 0.6;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  input {
    margin-top: 4px;
    background: none;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    padding: 2px;
    width: 215px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    margin-left: 8px !important;
    border: 1px solid #00c853 !important;
    border-radius: 50%;
    background: #00c853 !important;
    color: #fafafa;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.05, '#00c853')} !important;
    }

    > svg {
      transform: rotate(45deg);
    }
  }
`;

import React, { useCallback, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { FaTimes, FaCog, FaPlus, FaCopy, FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import ClearBackground from '~/components/ClearBackground';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';
import api from '~/services/api';

import { Badge, Container, Menu, Title } from './styles';

const ProcessSettingsOptions = ({
  newModel,
  configModels,
  loadModels,
  company_id,
  type,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleCopyModels = useCallback(async () => {
    try {
      setLoading(true);

      await api.post('/process-models/copy-models', {
        company_id,
        type,
      });

      handleToggleVisible();
      setLoading(false);
      loadModels();
      toast.success('Modelos copiados com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch {
      setLoading(false);
      toast.error('Falha ao copiar os modelos.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [handleToggleVisible, company_id, type, loadModels]);

  const confirmCopyModels = useCallback(() => {
    if (company_id && Number.isInteger(type)) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={handleCopyModels}
              onClose={onClose}
              message="Deseja mesmo copiar os modelos padronizados? Essa ação *NÃO* poderá ser desfeita posteriormente."
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      toast.error('Falha ao copiar os modelos.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [company_id, type, handleCopyModels]);

  return (
    <>
      <Container visible={visible}>
        <Badge onClick={handleToggleVisible}>
          <FaCog size={20} color="#44546a" />
        </Badge>
        <Menu visible={visible}>
          <Title>
            <div>
              <FaCog size={18} color="#01579b" />
              <h3>MENU</h3>
            </div>
            <FaTimes size={18} color="#ee4256" onClick={handleToggleVisible} />
          </Title>

          <button
            type="button"
            onClick={() => {
              handleToggleVisible();
              newModel();
            }}
          >
            <FaPlus color="#44546A" />
            Novo modelo
          </button>
          <button type="button" onClick={confirmCopyModels}>
            <FaCopy color="#44546A" />
            Copiar modelos padronizados
          </button>
          <button
            type="button"
            onClick={() => {
              handleToggleVisible();
              configModels();
            }}
          >
            <FaEdit color="#44546A" />
            Editar modelos
          </button>
        </Menu>
      </Container>
      {visible && <ClearBackground onClick={handleToggleVisible} />}
      {loading && <Loading />}
    </>
  );
};

export default ProcessSettingsOptions;

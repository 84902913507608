import { setHours } from 'date-fns';
import React, { useRef } from 'react';
import { FaFileDownload, FaTimes } from 'react-icons/fa';
import { DatePicker, FormContainer, Input } from '~/components/Form';

import ClearBackground from '../../../../components/ClearBackground';

import { Container, Content, Left, Right, Header } from './styles';

const ReceiverModal = ({
  setIsOpen,
  handleWriteOff,
  protocolCod,
  client,
  method,
}) => {
  const formRef = useRef(null);

  const handleSubmit = ({ name, date }) => {
    if (!name || !date) return;

    const formattedDate = setHours(date, 8);

    handleWriteOff(name, formattedDate);
  };

  return (
    <>
      <ClearBackground />
      <Container>
        <Content>
          <Left>
            <FaFileDownload size={38} color="#01579b" />
          </Left>
          <Right>
            <Header>
              <FaTimes onClick={setIsOpen} size={14} color="#e53935" />
              <strong>Digite o nome do receptor e a data:</strong>
            </Header>

            <span>
              Baixar {method === 1 ? 'Protocolo ' : 'Arquivo Morto '}
              {protocolCod} <br />
              de {client}.
            </span>

            <FormContainer ref={formRef} onSubmit={handleSubmit}>
              <Input name="name" label="Nome" type="text" />
              <DatePicker name="date" label="Data do recebimento" />
            </FormContainer>

            <button type="button" onClick={() => formRef.current.submitForm()}>
              Baixar
            </button>
          </Right>
        </Content>
      </Container>
    </>
  );
};

export default ReceiverModal;

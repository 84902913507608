import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Scope } from '@unform/core';
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import {
  FaFolder,
  FaSave,
  FaBroom,
  FaTimes,
  FaPlus,
  FaMinus,
} from 'react-icons/fa';
import * as Yup from 'yup';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import { FormLoading, Select, Input, Checkbox } from '~/components/Form';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Header,
  Controls,
  FormContainer,
} from '~/styles/components';
import { Content, Folder, SubFolderList, SubFolder } from './styles';

const Form = () => {
  const { company } = useAuth();

  const { state } = useLocation();

  const id = state?.id || null;

  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [folder, setFolder] = useState(null);

  useEffect(() => {
    async function loadFolder() {
      if (id) {
        try {
          setLoading(true);

          const response = await api.get(`folders/${id}`);

          const { data } = response;

          setFolder(data);
        } catch (err) {
          toast.error(
            `${err.response.data.message || 'Falha ao buscar pasta.'}`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        } finally {
          setLoading(false);
        }
      } else {
        setFolder({
          subFolders: [],
        });
      }
    }

    loadFolder();
  }, [id]);

  const departmentOptions = useMemo(() => {
    return [
      { value: 1, label: 'Contábil' },
      { value: 2, label: 'Tributário' },
      { value: 3, label: 'Pessoal' },
      { value: 4, label: 'Administrativo' },
      { value: 5, label: 'Diversos' },
    ];
  }, []);

  const colorOptions = useMemo(() => {
    return [
      { value: 0, label: 'Sem cor' },
      { value: 1, label: 'Amarelo' },
      { value: 2, label: 'Azul' },
      { value: 3, label: 'Verde' },
      { value: 4, label: 'Vermelho' },
      { value: 5, label: 'Branco' },
      { value: 6, label: 'Rosa' },
      { value: 7, label: 'Laranja' },
    ];
  }, []);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            description: Yup.string().required('A descrição é obrigatória.'),
            department: Yup.string().required('O departamento é obrigatório.'),
            subFolders: Yup.array().of(
              Yup.object().shape({
                description: Yup.string().required(
                  'A descrição é obrigatória.'
                ),
              })
            ),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          data.company_id = company.id;

          let folder_id;

          if (id) {
            folder_id = id;

            await api.put(`folders/${folder_id}`, data);
          } else {
            const createdFolder = await api.post('folders', data);

            folder_id = createdFolder.data.id;
          }

          if (data.subFolders) {
            const subFoldersData = data.subFolders.map(subFolder => {
              return {
                id: subFolder.id,
                folder_id,
                description: subFolder.description,
              };
            });

            await api.put(`folders/sub-folders/${folder_id}`, {
              data: subFoldersData,
            });
          } else {
            await api.put(`folders/sub-folders/${folder_id}`, {
              data: [],
            });
          }

          formRef.current.setErrors({});

          toast.success('Pasta salva com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);

          history.push('/folder');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error(
              `${err?.response?.data?.message || 'Falha ao salvar pasta.'}`,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          }

          setSaveLoading(false);
        }
      }
    },
    [id, company]
  );

  const handleResetForm = useCallback(() => {
    formRef.current.reset();
    formRef.current.setErrors({});
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={handleResetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleResetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/folder')}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const confirmRemove = useCallback((functionToExecute, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => functionToExecute(index)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleAddSubFolder = useCallback(() => {
    setFolder(
      produce(folder, draft => {
        const blankSubFolder = {
          id: uuid(),
          description: '',
        };

        if (draft.subFolders.length > 0) {
          draft.subFolders.unshift(blankSubFolder);
        } else if (draft.subFolders) {
          draft.subFolders[0] = blankSubFolder;
        } else {
          draft.subFolders = [blankSubFolder];
        }
      })
    );
  }, [folder]);

  const handleRemoveSubFolder = useCallback(
    async index => {
      setFolder(
        produce(folder, draft => {
          draft.subFolders.splice(index, 1);
        })
      );
    },
    [folder]
  );

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaFolder size={20} color="#44546a" />
            <h1>Pasta</h1>
          </div>
        </Header>
        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {(loading || !company) && <FormLoading className="loading" />}
        {folder && (
          <Content className="content">
            <FormContainer
              ref={formRef}
              loading={loading ? 1 : 0}
              onSubmit={handleSubmit}
              initialData={folder}
            >
              <Folder>
                <h4>PASTA</h4>
                <section>
                  <Input
                    name="description"
                    className="description"
                    label="Descrição"
                  />
                  <Select
                    name="department"
                    className="department"
                    label="Departamento"
                    placeholder="Selecione um departamento"
                    options={departmentOptions}
                  />
                  <Select
                    name="color"
                    className="color"
                    label="Cor"
                    placeholder="Selecione uma cor"
                    options={colorOptions}
                  />
                </section>
                <section>
                  <Checkbox
                    id="portal"
                    name="portal"
                    className="portal"
                    label="Usar no portal"
                  />
                </section>
              </Folder>

              <SubFolderList>
                <header>
                  <h4>SUB PASTAS</h4>
                  <button type="button" onClick={handleAddSubFolder}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {folder?.subFolders?.map((subFolder, index) => (
                  <SubFolder key={subFolder.id}>
                    <button
                      type="button"
                      onClick={() =>
                        confirmRemove(handleRemoveSubFolder, index)
                      }
                    >
                      <FaMinus size={10} />
                    </button>

                    <Scope path={`subFolders[${index}]`}>
                      <section>
                        <Input
                          name="id"
                          type="text"
                          className="hide"
                          readOnly
                        />
                        <Input
                          name="description"
                          className="description"
                          label="Descrição"
                        />
                      </section>
                    </Scope>
                  </SubFolder>
                ))}
              </SubFolderList>
            </FormContainer>
          </Content>
        )}
      </Container>

      {saveLoading && <Loading />}
    </>
  );
};

export default Form;

import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import ReactTooltip from 'react-tooltip';
import { FaExclamationCircle } from 'react-icons/fa';

import { Container, Error } from './styles';

const Input = ({
  name,
  label,
  className,
  visible,
  icon: Icon,
  color = '#01579B',
  ...rest
}) => {
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      className={className}
      visible={visible}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      color={color}
    >
      <label>{label}</label>
      {Icon && <Icon size={14} />}
      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        {...rest}
      />

      {error && (
        <Error isFilled={isFilled} isFocused={isFocused}>
          <ReactTooltip
            place="right"
            effect="solid"
            backgroundColor="#e53935"
          />
          <FaExclamationCircle color="#E53935" size={14} data-tip={error} />
        </Error>
      )}
    </Container>
  );
};

export default Input;

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  visible: PropTypes.bool,
  icon: PropTypes.func,
};

Input.defaultProps = {
  label: null,
  className: null,
  visible: true,
  icon: null,
};

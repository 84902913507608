import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 270px;
  height: 215px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 16px;
  padding: 10px;
  margin-right: 21px;
  margin-top: 56px;
  position: relative;

  &:hover {
    cursor: grab;
  }

  ${props =>
    props.isDragging &&
    css`
      background: transparent;
      cursor: grabbing;
      border: 2px dashed ${({ theme }) => theme.colors.border};

      main {
        display: none !important;
      }
    `}

  h4 {
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 20px;
  }

  &.has-pendencies {
    border: 1px solid ${({ theme }) => theme.colors.error};

    h2,
    span {
      color: ${({ theme }) => theme.colors.error};
    }

    div {
      border-color: ${({ theme }) => theme.colors.error};
    }
  }
`;

export const Icon = styled(Link)`
  position: absolute;

  color: ${({ theme }) => theme.colors.white};
  padding: 15px;
  border-radius: 20px;
  top: -30px;
  left: 20%;
  transform: translate(-50%);

  svg {
    width: 30px;
    height: 30px;
  }

  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.type === 'schedule' &&
    css`
      background: ${({ theme }) => theme.colors.orange};
    `}

  ${props =>
    props.type === 'tax-checklist' &&
    css`
      background: ${({ theme }) => theme.colors.success};
    `}

  ${props =>
    props.type === 'labour-checklist' &&
    css`
      background: ${({ theme }) => theme.colors.blue};
    `}

  ${props =>
    props.type === 'kpi' &&
    css`
      background: #f4c306;
    `}

  ${props =>
    props.type === 'process-models' &&
    css`
      background: #9932cc;
    `}
    
  ${props =>
    props.type === 'protocol' &&
    css`
      background: #d76464;
    `}
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 32px;
  margin-left: 20px;
`;

export const Content = styled.main`
  height: calc(100% - 57px);
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  div {
    color: ${({ theme }) => theme.colors.primary};
    display: flex;
    flex-direction: column;
    font-size: 18px;

    & + div {
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid ${({ theme }) => theme.colors.lightBorder};
    }

    > span {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }

    progress {
      width: 100%;
      margin-top: 4px;
    }

    .competence {
      margin-top: 6px;
      font-size: 12px;
    }
  }
`;

export const Line = styled.div`
  height: 0;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #bbb;
  margin-top: calc(1rem - 1px);
  margin-bottom: 5px;
  align-self: center;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  right: 0;
  margin-right: 10px;
  top: 50px;
  width: 330px;
  border: 1px solid ${({ theme }) => theme.colors.lightBorder};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 10;
  border-radius: 4px;
  padding: 5px 10px;
  transform: ${({ visible }) =>
    visible ? 'translateX(0)' : 'translateX(340px)'};
  transition: transform 0.25s ease-in-out;
`;

export const Title = styled.header`
  color: ${({ theme }) => theme.colors.blue};
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  nav {
    display: flex;
    align-items: center;

    strong {
      margin-left: 8px;
    }

    svg {
      margin-left: 0px !important;
    }
  }

  > svg {
    cursor: pointer;
  }
`;

export const Content = styled.main`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;

    section {
      width: 100%;
      margin-bottom: 24px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .client,
      .document {
        width: 100%;
      }

      .year,
      .month,
      .due_date,
      .price {
        width: 45%;
      }

      .year {
        margin-left: 16px;
      }
    }

    button {
      width: 100%;
      background: ${({ theme }) => theme.colors.success};
      border-radius: 4px;
      color: ${({ theme }) => theme.colors.white};
      transition: background 0.2s;
      padding: 6px 0;
      font-size: 16px;

      &:hover {
        background: ${({ theme }) => theme.hover.success};
      }
    }
  }
`;

export const Result = styled.div`
  margin-top: 16px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border: none;
    background: none;
    margin-left: 16px;
    transition: color 0.25s;

    &:hover svg {
      color: ${({ theme }) => theme.hover.blue} !important;
    }
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useField } from '@unform/core';

import { Container } from './styles';

const FileInput = ({ name, className, ...rest }) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container className={className}>
      <input type="file" ref={inputRef} {...rest} />
      {error && <span>{error}</span>}
    </Container>
  );
};

export default FileInput;

FileInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

FileInput.defaultProps = {
  label: null,
  className: null,
};

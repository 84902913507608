import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import JoditEditor from 'jodit-react';

import * as Yup from 'yup';

import {
  FaSave,
  FaBroom,
  FaTimes,
  FaBriefcase,
  FaLink,
  FaCopy,
} from 'react-icons/fa';

import copy from 'copy-to-clipboard';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';
import {
  FormContainer,
  FormLoading,
  Input,
  Checkbox,
  FileInput,
} from '~/components/Form';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import {
  Container,
  Header,
  Controls,
  Content,
  PopInfo,
  GenerateLink,
  File,
  FileLink,
} from './styles';

const Form = () => {
  const { company } = useAuth();

  const { state } = useLocation();

  const formRef = useRef();

  const editor = useRef(null);
  const config = {
    readonly: false,
    width: '100%',
    minHeight: '300px',
  };

  const id = state?.id || null;

  const [formLoading, setFormLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [docs, setDocs] = useState(false);
  const [docsLinks, setDocsLinks] = useState('');

  const [initialData, setInitialData] = useState();

  const loadPop = useCallback(async () => {
    try {
      setFormLoading(true);

      const response = await api.get(`/pop/${id}`);

      if (response.data) {
        setInitialData(response.data);
        setDocsLinks(response.data.docs_link);
      } else {
        toast.error('Falha ao buscar POP.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setFormLoading(false);
    } catch {
      setFormLoading(false);
      toast.error('Falha ao buscar POP.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      loadPop();
    } else {
      setInitialData({
        title: '',
        keyWords: '',
        labor: false,
        tributary: false,
        administration: false,
        accouting: false,
        financial: false,
      });

      setFormLoading(false);
    }
  }, [id, loadPop]);

  const [dptError, setDptError] = useState(false);
  const handleSubmit = useCallback(
    async data => {
      if (company) {
        setDptError(false);
        setLoading(true);
        try {
          const schema = Yup.object().shape({
            title: Yup.string().required('O título é obrigatório.'),
            key_words: Yup.string().required(
              'Ao menos uma palavra chave é obrigatória.'
            ),
            docs_links: Yup.string(),
            labor: Yup.bool(),
            tributary: Yup.bool(),
            accounting: Yup.bool(),
            financial: Yup.bool(),
            administration: Yup.bool(),
            doc: Yup.bool(),
          });
          await schema.validate(data, { abortEarly: false });
          const {
            title,
            key_words,
            docs_links,
            labor,
            tributary,
            accounting,
            financial,
            administration,
            auxiliary,
            google_docs,
          } = data;
          const description = editor.current.value;
          if (
            !labor &&
            !tributary &&
            !accounting &&
            !financial &&
            !administration &&
            !auxiliary &&
            !google_docs
          ) {
            setDptError(true);
            throw new Error(400);
          }
          if (id) {
            await api.put(`/pop/${id}`, {
              title,
              key_words,
              docs_links,
              labor,
              tributary,
              accounting,
              financial,
              administration,
              auxiliary,
              description,
              google_docs,
            });
          } else {
            await api.post('/pop', {
              company_id: company.id,
              title,
              key_words,
              docs_links,
              labor,
              tributary,
              accounting,
              financial,
              administration,
              auxiliary,
              description,
              google_docs,
            });
          }
          formRef.current.setErrors({});
          toast.success('POP salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
          history.push('/pop');
        } catch (err) {
          setLoading(false);
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};
            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });
            formRef.current.setErrors(errorMessages);
            toast.error(
              'Existem campos obrigatórios em vermelho que precisam ser preenchidos.',
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          } else if (err.message === '400') {
            toast.error(
              'Existem campos obrigatórios em vermelho que precisam ser preenchidos.',
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          } else {
            toast.error('Falha ao salvar POP.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      }
    },
    [company, id]
  );

  const resetForm = useCallback(() => {
    formRef.current.reset();
  }, []);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/pop')}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const [fileLink, setFileLink] = useState('');
  const generateLink = useCallback(async file => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append('file', file);

      const fileResponse = await api.post('files/upload', formData, {
        params: {
          prefix: 'pop_image',
        },
      });

      const { blobName } = fileResponse.data;

      setFileLink(
        `https://myhappen.blob.core.windows.net/files-companies/${blobName}`
      );

      setLoading(false);
    } catch {
      setLoading(false);
      setFileLink(null);

      toast.error('Falha ao gerar link.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, []);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaBriefcase size={20} color="#44546a" />
            <h1>POP</h1>
          </div>
        </Header>

        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {formLoading || !initialData ? (
          <FormLoading />
        ) : (
          <Content className="content">
            <FormContainer
              ref={formRef}
              initialData={initialData}
              onSubmit={handleSubmit}
            >
              <PopInfo>
                <h4>PROCEDIMENTO OPERACIONAL PADRÃO</h4>
                <section>
                  <Input
                    name="title"
                    className="title"
                    label="Título"
                    type="text"
                  />
                  <Input
                    name="key_words"
                    className="key-words"
                    label="Palavras-chave (escreva separadas por vírgulas)"
                    type="text"
                  />
                </section>
                <section>
                  <Input
                    name="docs_links"
                    className="docs_links"
                    value={docsLinks}
                    label="Link do Docs"
                    onChange={e => setDocsLinks(e.target.value)}
                    type="text"
                  />
                  <Checkbox
                    id="google_docs"
                    name="google_docs"
                    className="checkbox"
                    onChange={e => setDocs(e.target.checked)}
                    label="Utilizar Google Docs como POP padrão"
                  />
                </section>
                <section className="sector">
                  <Checkbox
                    id="labor"
                    name="labor"
                    className="checkbox"
                    label="Trabalhista"
                  />
                  <Checkbox
                    id="tributary"
                    name="tributary"
                    className="checkbox"
                    label="Tributário"
                  />
                  <Checkbox
                    id="accounting"
                    name="accounting"
                    className="checkbox"
                    label="Contábil"
                  />
                  <Checkbox
                    id="financial"
                    name="financial"
                    className="checkbox"
                    label="Financeiro"
                  />
                  <Checkbox
                    id="administration"
                    name="administration"
                    className="checkbox"
                    label="Administração"
                  />
                  <Checkbox
                    id="auxiliary"
                    name="auxiliary"
                    className="checkbox"
                    label="Auxiliar"
                  />
                </section>
                {dptError && (
                  <span className="dpt-error">
                    Ao menos um departamento dever ser selecionado.
                  </span>
                )}
                <section className="generate-link">
                  <strong>
                    Para adicionar uma imagem contida em seu computador é
                    necessário gerar um link
                  </strong>
                  <GenerateLink>
                    <File>
                      <label htmlFor="file">
                        <FaLink />
                        Gerar Link
                      </label>
                      <FileInput
                        id="file"
                        name="file"
                        accept="image/*"
                        onChange={e => generateLink(e.target.files[0])}
                      />
                    </File>

                    {fileLink && (
                      <FileLink>
                        <div>{fileLink}</div>
                        <button type="button" onClick={() => copy(fileLink)}>
                          <FaCopy color="#fff" />
                        </button>
                      </FileLink>
                    )}
                  </GenerateLink>
                </section>

                {docs ? (
                  <section style={{ color: '#000' }}>
                    <iframe
                      src={docsLinks}
                      title="Google Document"
                      width="100%"
                      height="1000px "
                    />
                  </section>
                ) : (
                  ''
                )}

                <section style={{ color: '#000' }}>
                  <JoditEditor
                    ref={editor}
                    config={config}
                    value={initialData.description}
                    tabIndex={0}
                  />
                </section>
              </PopInfo>
            </FormContainer>
          </Content>
        )}
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default Form;

import styled from 'styled-components';
import { Form } from '@unform/web';

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div + div {
    margin-left: 16px;
  }

  .client {
    width: 100%;
  }

  button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;
    width: 4%;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .client {
      width: 50vw;
      padding-left: 8px;
    }

    .model {
      width: 30vw;
    }

    .start_period {
      width: 20vw;
    }
  }
`;

export const DetailsContainer = styled.div`
  padding: 10px;

  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ParameterInfo = styled.div`
  display: flex;
  flex-direction: column;

  section {
    div + div {
      margin-left: 16px;
    }

    .client,
    .model {
      width: 30vw;
    }

    .start_period,
    .end_period {
      width: 20vw;
    }

    .obs {
      width: 100vw;
    }
  }
`;

export const Responsibles = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  section {
    div + div {
      margin-left: 16px;
    }

    .responsible {
      width: 50vw;
    }

    .start_period,
    .end_period {
      width: 25vw;
    }
  }
`;

export const Documents = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  .status {
    width: 5%;
  }

  .document {
    opacity: 1;
    width: 55%;
  }

  .obs,
  .document_start_period {
    width: 25%;
  }
`;

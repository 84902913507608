import styled from 'styled-components';

export const Feedback = styled.div`
  margin-left: 16px;

  display: flex;
  align-items: center;

  .feedback_message {
    width: 40vw;

    textarea {
      width: 40vw;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    padding: 7px;
    border: 1px solid ${({ theme }) => theme.colors.success};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.success};
    }

    > svg {
      transform: rotate(45deg);
    }
  }
`;

export const File = styled.div`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-left: 16px;
    opacity: 1;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
      cursor: pointer;
    }
  }
`;

export const FileName = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 30vw;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    height: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    background: transparent;
  }
`;

export const UploadFile = styled.div`
  display: flex;
  align-items: center;
`;

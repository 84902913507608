import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - 60px);
  margin: 10px;
  overflow: auto;

  .empty {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Model = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 0;
  background-color: ${({ theme }) => theme.colors.greyBackground};
  transition: opacity 0.3s;
  cursor: pointer;

  h3 {
    color: ${({ theme }) => theme.colors.blue};
  }

  & + div {
    margin-top: 26px;
  }

  > div {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    opacity: 0.7;
  }
`;

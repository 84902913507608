import React, { createContext, useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import { toast } from 'react-toastify';

import api from '../services/api';
import history from '../services/history';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const location = useLocation();

  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@Diretiva:token');
    const apps = localStorage.getItem('@Diretiva:apps');

    if (token && apps) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      return {
        token,
        apps: JSON.parse(apps),
      };
    }

    return {};
  });

  useEffect(() => {
    async function loadData() {
      if (
        location.pathname !== '/signin' &&
        !location.pathname.match(/.*process-portal.*/) &&
        !location.pathname.match(/.*redirect.*/) &&
        !location.pathname.match(/.*portal.*/)
      ) {
        if (
          !data.company ||
          !data.user ||
          !data.companyUser ||
          !data.companyUsers
        ) {
          const [
            companyUserResponse,
            companyResponse,
            companyUsersResponse,
          ] = await Promise.all([
            api.get('company-users/get/data'),
            api.get('company-users/get/company'),
            api.get('company-users/get/all-company-users'),
          ]);

          setData(oldData => ({
            ...oldData,
            company: companyResponse.data,
            user: companyUserResponse.data.user,
            companyUser: companyUserResponse.data,
            companyUsers: companyUsersResponse.data,
          }));
        }
      }
    }

    loadData();
  }, [location, data]);

  const signOut = useCallback(() => {
    localStorage.clear();

    setData({});
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    try {
      localStorage.clear();

      api.defaults.headers.Authorization = null;

      const sessionResponse = await api.post('sessions', {
        email,
        password,
      });

      const {
        token,
        level,
        company,
        createChecklistFirstLogin,
        createLabourChecklistOnDay15,
      } = sessionResponse.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      const companyAppsResponse = await api.get(`companies-apps`, {
        params: {
          type: company.type,
          model_id: company.model_id,
          level,
        },
      });

      const apps = companyAppsResponse.data;

      localStorage.setItem('@Diretiva:token', token);
      localStorage.setItem('@Diretiva:apps', JSON.stringify(apps));

      setData({
        token,
        apps,
        createChecklistFirstLogin,
        createLabourChecklistOnDay15,
      });
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }, []);

  const changeCompany = useCallback(async (companyId, url, token) => {
    localStorage.clear();

    const changeCompanyResponse = await api.get(
      `company-users/change-company/${companyId}`
    );

    const { level, company } = changeCompanyResponse.data;

    const companyAppsResponse = await api.get(`companies-apps`, {
      params: {
        type: company.type,
        model_id: company.model_id,
        level,
      },
    });

    const apps = companyAppsResponse.data;

    localStorage.setItem('@Diretiva:token', token);
    localStorage.setItem('@Diretiva:apps', JSON.stringify(apps));

    if (url) {
      history.push(`${url}`);
    } else {
      history.push('/');
      window.location.reload();
    }

    setData({ token, apps });
  }, []);

  const isLogged = useCallback(() => {
    const token = localStorage.getItem('@Diretiva:token');

    if (token) {
      return true;
    }

    return false;
  }, []);

  const isTokenExpired = useCallback(() => {
    const token = localStorage.getItem('@Diretiva:token');

    if (token) {
      const { exp } = jwt.decode(token);

      const currentTime = new Date().getTime() / 1000;

      if (currentTime > exp) {
        return true;
      }

      return false;
    }

    return false;
  }, []);

  const CheckUserBirthday = useCallback(async () => {
    const response = await api.get('company-users/get/birthday');

    const { isUserBirthday, showBirthdayMessageAgain } = response.data;

    if (isUserBirthday && showBirthdayMessageAgain) {
      return true;
    }

    return false;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        apps: data.apps,
        user: data.user,
        company: data.company,
        companyUser: data.companyUser,
        companyUsers: data.companyUsers,
        createChecklistFirstLogin: data.createChecklistFirstLogin || false,
        createLabourChecklistOnDay15:
          data.createLabourChecklistOnDay15 || false,
        signIn,
        signOut,
        changeCompany,
        isLogged,
        isTokenExpired,
        CheckUserBirthday,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

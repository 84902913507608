import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import { Container } from './styles';

const Checkbox = ({ name, label, className, readOnly, ...rest }) => {
  const checkboxRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: checkboxRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <Container className={className} readOnly={readOnly}>
      <label htmlFor={rest.id}>{label}</label>
      <input
        ref={checkboxRef}
        defaultChecked={defaultValue}
        readOnly={readOnly}
        type="checkbox"
        {...rest}
      />
      {error && <span>{error}</span>}
    </Container>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
};

Checkbox.defaultProps = {
  label: null,
  className: null,
  readOnly: false,
};

import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';
import {
  FaTimes,
  FaEye,
  FaEdit,
  FaChevronLeft,
  FaChevronRight,
  FaSave,
  FaBroom,
  FaEraser,
  FaSearch,
  FaCheck,
  FaBuilding,
  FaTimesCircle,
  FaCheckCircle,
} from 'react-icons/fa';

import api from '~/services/api';

import { Checkbox, FormContainer, Input, Select } from '~/components/Form';
import Modal from '~/components/Modal';
import { TableLoading, TableContainer } from '~/components/Table';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  CompanyInfo,
} from './styles';

const Companies = ({ isOpen, setIsOpen }) => {
  const formRef = useRef(null);
  const filterRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [activeLoading, setActiveLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({});

  const [totalCompanies, setTotalCompanies] = useState(0);
  const [companyIndex, setCompanyIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [companyId, setCompanyId] = useState(null);

  const [nameSearch, setNameSearch] = useState('');
  const [selectedSituation, setSelectedSituation] = useState(1);
  const [activeFilter, setActiveFilter] = useState(1);

  const nuboTargetOptions = useMemo(() => {
    return [
      { value: 0, label: 'Plataforma antiga' },
      { value: 1, label: 'Plataforma nova' },
    ];
  }, []);

  const activeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Ativo' },
      { value: 0, label: 'Inativo' },
    ];
  }, []);

  const loadCompanies = useCallback(async () => {
    if (isEditing === false) {
      try {
        setLoading(true);
        const response = await api.get('/companies', {
          params: {
            name: nameSearch,
            active: selectedSituation,
            page: currentPage,
          },
        });

        if (response.data.docs.length > 0) {
          setCompanies(response.data.docs);
          setCompany(response.data.docs[0]);
          setCompanyIndex(0);
          setTotalPages(response.data.pages);
          setTotalCompanies(response.data.total);
        } else {
          setCompanies([]);
          setCompany([]);
          setTotalPages(1);
          setTotalCompanies(0);
          toast.warn('Nenhum empresa foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setShowTable(true);
      } catch {
        toast.error('Falha ao buscar empresas.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      setLoading(false);
    }
  }, [isEditing, currentPage, nameSearch, selectedSituation]);

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);

  const handleFilter = useCallback(({ name, active }) => {
    setCurrentPage(1);

    setNameSearch(name);
    setSelectedSituation(active);
  }, []);

  const handleResetFilter = useCallback(() => {
    setCurrentPage(1);

    filterRef.current.reset();

    setNameSearch('');
    setSelectedSituation(1);
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        setSaveLoading(true);

        const schema = Yup.object().shape({
          client_limit: Yup.number()
            .typeError('O limite é obrigatório')
            .required('O limite é obrigatório'),
          nubo_target: Yup.number().typeError('O direcionamento é obrigatório'),
          hasWhatsapp: Yup.bool(),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put(`companies/${companyId}`, data);

        formRef.current.setErrors({});

        toast.success('Empresa salvo com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setSaveLoading(false);
        setIsEditing(false);
        setShowTable(true);
        loadCompanies();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
        } else {
          toast.error('Falha ao salvar usuário.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setSaveLoading(false);
      }
    },
    [companyId, loadCompanies]
  );

  const handleView = useCallback(() => {
    if (companies.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, companies]);

  const getDetails = useCallback(
    (id, index) => {
      const userItem = companies.find(item => item.id === id);

      setCompany(userItem);
      setCompanyIndex(index);
      handleView();
    },
    [companies, handleView]
  );

  const handlePrevItem = useCallback(() => {
    if (companyIndex !== 0) {
      setCompanyIndex(companyIndex - 1);
      setCompany(companies[companyIndex - 1]);
    } else {
      setCompanyIndex(companies.length - 1);
      setCompany(companies[companies.length - 1]);
    }
  }, [companyIndex, companies]);

  const handleNextItem = useCallback(() => {
    if (companyIndex !== companies.length - 1) {
      setCompanyIndex(companyIndex + 1);
      setCompany(companies[companyIndex + 1]);
    } else {
      setCompanyIndex(0);
      setCompany(companies[0]);
    }
  }, [companyIndex, companies]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },
    [totalPages]
  );

  const handleEdit = useCallback(() => {
    setCompanyId(company.id);
    setIsEditing(true);
  }, [company]);

  const resetForm = useCallback(() => {
    formRef.current.reset();
    formRef.current.setErrors({});
  }, []);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => setIsEditing(false)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleChangeActiveCompany = useCallback(
    async (company_id, status) => {
      try {
        setActiveLoading(true);

        await api.put(`companies/${company_id}`, { status });

        setActiveLoading(false);
        loadCompanies();
      } catch {
        setActiveLoading(false);
        toast.error('Falha ao salvar usuário.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [loadCompanies]
  );

  const confirmActiveCompany = useCallback(() => {
    if (company.id === 3) {
      toast.error('Você não pode inativar a ícone né cabeção.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() =>
                handleChangeActiveCompany(company.id, !company.status)
              }
              onClose={onClose}
              message={`Deseja ${
                company.status ? 'inativar' : 'ativar'
              } a empresa ${company.name}?`}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  }, [company, handleChangeActiveCompany]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaBuilding size={20} color="#44546a" />
            <h1>Empresas</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>
        <Controls>
          {!isEditing ? (
            <>
              <button type="button" onClick={handleView}>
                <FaEye size={15} color="#44546a" />
                <span>Visualização</span>
              </button>
              {showTable ? (
                <button type="button" onClick={handleResetFilter}>
                  <FaEraser size={15} color="#44546a" />
                  <span>Limpar filtros</span>
                </button>
              ) : (
                <>
                  <button type="button" onClick={handleEdit}>
                    <FaEdit size={15} color="#44546a" />
                    <span>Editar</span>
                  </button>
                  {company.status ? (
                    <button type="button" onClick={confirmActiveCompany}>
                      <FaTimesCircle size={15} color="#44546a" />
                      <span>Inativar</span>
                    </button>
                  ) : (
                    <button type="button" onClick={confirmActiveCompany}>
                      <FaCheckCircle size={15} color="#44546a" />
                      <span>Ativar</span>
                    </button>
                  )}
                  <div>
                    <button type="button" onClick={handlePrevItem}>
                      <FaChevronLeft size={15} color="#44546a" />
                    </button>
                    {totalCompanies > 25 ? (
                      <span>{companyIndex + 1} de 25</span>
                    ) : (
                      <span>
                        {companyIndex + 1} de {totalCompanies}
                      </span>
                    )}
                    <button type="button" onClick={handleNextItem}>
                      <FaChevronRight size={15} color="#44546a" />
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <button
                type="button"
                onClick={() => formRef.current.submitForm()}
              >
                <FaSave size={15} color="#44546a" />
                <span>Salvar</span>
              </button>
              <button type="button" onClick={confirmResetForm}>
                <FaBroom size={15} color="#44546a" />
                <span>Limpar</span>
              </button>
              <button type="button" onClick={handleClose}>
                <FaTimes size={15} color="#44546a" />
                <span>Fechar</span>
              </button>
            </>
          )}
        </Controls>
        {!isEditing ? (
          <>
            {showTable && (
              <Filter ref={filterRef} onSubmit={handleFilter}>
                <div>
                  <Input
                    name="name"
                    className="name"
                    label="Nome"
                    defaultValue={nameSearch}
                  />

                  <Select
                    name="active"
                    className="active"
                    label="Situação"
                    options={activeOptions}
                    value={activeOptions.find(
                      option => option.value === activeFilter
                    )}
                    onChange={({ value }) => setActiveFilter(value)}
                  />
                </div>

                <button type="submit">
                  <FaSearch />
                </button>
              </Filter>
            )}

            {loading ? (
              <TableLoading />
            ) : (
              <Content className="content">
                {showTable ? (
                  <TableContainer>
                    <thead>
                      <tr>
                        <th className="name">Nome</th>
                        <th className="quant">Limite de Clientes</th>
                        <th className="nubo">Direcionamento do Nubo</th>
                        <th className="whatsapp">Acesso ao Whatsapp</th>
                        <th className="active">Ativo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companies.map((item, index) => (
                        <tr
                          key={item.id}
                          className="hover"
                          onClick={() => getDetails(item.id, index)}
                        >
                          <td className="name">{item.name || ''}</td>
                          <td className="quant">{item.client_limit || ''}</td>
                          <td className="nubo">
                            {item.nubo_target === 0 && 'Plataforma antiga'}
                            {item.nubo_target === 1 && 'Plataforma nova'}
                          </td>
                          <td className="whatsapp">
                            {item.hasWhatsapp ? <FaCheck /> : <FaTimes />}
                          </td>
                          <td className="active">
                            {item.status ? <FaCheck /> : <FaTimes />}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </TableContainer>
                ) : (
                  <DetailsContainer>
                    <CompanyInfo>
                      <h4>Empresa</h4>
                      <section>
                        <div className="name">
                          <label>Nome</label>
                          <input
                            name="name"
                            value={company.name || ''}
                            readOnly
                          />
                        </div>
                      </section>
                      <section>
                        <div className="client">
                          <label>Limite de Clientes</label>
                          <input
                            name="client_limit"
                            value={company.client_limit || ''}
                            readOnly
                          />
                        </div>
                        <div className="nubo">
                          <label>Direcionamento do Nubo</label>

                          {company.nubo_target === 0 && (
                            <input
                              name="nubo_target"
                              value="Plataforma antiga"
                              readOnly
                            />
                          )}
                          {company.nubo_target === 1 && (
                            <input
                              name="nubo_target"
                              value="Plataforma nova"
                              readOnly
                            />
                          )}
                          {!company.nubo_target &&
                            company.nubo_target !== 0 && (
                              <input name="nubo_target" value="" readOnly />
                            )}
                        </div>
                        <div className="whatsapp checkbox">
                          <label>Acesso ao Whatsapp</label>
                          <input
                            name="private"
                            checked={company.hasWhatsapp}
                            type="checkbox"
                            disabled
                          />
                        </div>
                      </section>
                    </CompanyInfo>
                  </DetailsContainer>
                )}
              </Content>
            )}
          </>
        ) : (
          <>
            {company !== null && (
              <Content className="content">
                <FormContainer
                  ref={formRef}
                  onSubmit={handleSubmit}
                  initialData={company}
                >
                  <CompanyInfo>
                    <h4>USUÁRIO</h4>
                    <section>
                      <Input
                        name="name"
                        className="name"
                        label="Nome"
                        disabled
                      />
                    </section>
                    <section>
                      <Input
                        name="client_limit"
                        className="client"
                        label="Limite de Clientes"
                        type="number"
                      />
                      <Select
                        name="nubo_target"
                        className="nubo"
                        label="Direcionamento do Nubo"
                        options={nuboTargetOptions}
                      />
                      <Checkbox
                        name="hasWhatsapp"
                        className="whatsapp"
                        label="Acesso ao Whatsapp"
                      />
                    </section>
                  </CompanyInfo>
                </FormContainer>
              </Content>
            )}
          </>
        )}
        {!isEditing && (
          <Pagination
            loading={loading ? 1 : 0}
            currentPage={currentPage}
            pages={totalPages}
            totalDocs={totalCompanies}
            handlePage={handlePage}
          />
        )}
      </Container>
      {(saveLoading || activeLoading) && <Loading />}
    </Modal>
  );
};

export default Companies;

import styled from 'styled-components';

export const Container = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10;
`;

export const Content = styled.div`
  max-width: 400px;
  background: #fafafa;
  padding: 25px;
  border-radius: 4px;

  display: flex;
  flex-direction: row;

  strong {
    margin-bottom: 16px;
  }

  input {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    margin: 16px 0 16px 0;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-top: 16px;
      width: 50%;
      padding: 8px;
      border-radius: 4px;
      color: ${({ theme }) => theme.colors.white};
      transition: background 0.2s;
      padding-left: 20px;
      padding-right: 20px;

      &:nth-child(1) {
        background: ${({ theme }) => theme.colors.blue};
        margin-right: 16px;

        &:hover {
          background: ${({ theme }) => theme.hover.blue};
        }
      }

      &:nth-child(2) {
        background: ${({ theme }) => theme.colors.success};

        &:hover {
          background: ${({ theme }) => theme.hover.success};
        }
      }
    }
  }
`;

export const Left = styled.aside`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const Right = styled.main`
  font-size: 16px;
  padding-left: 16px;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    margin-top: 10px;
    border: 1px solid ${({ theme }) => theme.colors.lightBorder};
    border-radius: 4px;
    padding: 3px;
    width: 280px;
  }

  > button {
    margin-top: 16px;
    width: 50%;
    align-self: flex-end;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.success};
    margin-left: 0 !important;
    color: ${({ theme }) => theme.colors.white};
    padding: 5px;
    transition: background 0.2s;

    &:hover {
      background-color: ${({ theme }) => theme.hover.success};
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  position: relative;

  strong {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;

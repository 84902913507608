import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import {
  FaTimes,
  FaEye,
  FaPlus,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaBriefcase,
} from 'react-icons/fa';
import JoditEditor from 'jodit-react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { TableLoading, TableContainer } from '~/components/Table';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import {
  Container,
  Header,
  Controls,
  Content,
  DetailsContainer,
  PopInfo,
} from './styles';

const List = () => {
  const { company, companyUser, companyUsers } = useAuth();

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const [pops, setPops] = useState([]);
  const [pop, setPop] = useState(null);
  const [popIndex, setPopIndex] = useState(0);

  const config = {
    readonly: true,
    toolbar: false,
    width: '100%',
    minHeight: '300px',
  };

  const loadPops = useCallback(async () => {
    if (company && companyUsers) {
      try {
        setShowTable(true);
        setLoading(true);

        const response = await api.get('pop', {
          params: {
            company_id: company.id,
          },
        });

        if (response.data.length > 0) {
          const data = response.data.map(item => ({
            ...item,
            audits: item.audits.map(audit => ({
              ...audit,
              date: format(parseISO(audit.created_at), "dd/MM/yyyy 'às' HH:mm"),
              user: companyUsers.find(
                companyUserItem => companyUserItem.user_id === audit.user_id
              )?.name,
            })),
          }));

          setPops(data);
          setPop(data[0]);
        } else {
          setPops([]);
          setPop([]);
          toast.warn('Nenhum POP foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setLoading(false);
      } catch (err) {
        toast.error('Falha ao buscar POPs.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setLoading(false);
      }
    }
  }, [company, companyUsers]);

  useEffect(() => {
    loadPops();
  }, [loadPops]);

  const handleView = useCallback(() => {
    if (pops.length > 0) {
      setShowTable(oldShow => !oldShow);
    }
  }, [pops]);

  const getDetails = useCallback(
    async (id, index) => {
      const popItem = pops.find(item => item.id === id);

      setPop(popItem);
      setPopIndex(index);
      handleView();
    },
    [pops, handleView]
  );

  const handleDelete = useCallback(
    async id => {
      try {
        setDeleteLoading(true);

        await api.delete(`/pop/${id}`);

        setPops(oldAppoinments =>
          oldAppoinments.filter(item => item.id !== id)
        );

        handleView();

        loadPops();

        toast.success('POP deletado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      } catch (err) {
        toast.error('Falha ao deletar POP.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      }
    },
    [loadPops, handleView]
  );

  const confirmDelete = useCallback(() => {
    if (companyUser) {
      if (companyUser.level < 4) {
        toast.warn('Você não possui permissão para deletar.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ConfirmWindow
                onClick={() => handleDelete(pop.id)}
                onClose={onClose}
              />
            );
          },
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    }
  }, [handleDelete, pop, companyUser]);

  const handleEdit = useCallback(() => {
    setLoading(false);
    if (companyUser) {
      if (companyUser.level < 4) {
        toast.warn('Você não tem permissão para editar.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        history.push('/pop/edit', { id: pop.id });
      }
    }
  }, [pop, companyUser]);

  const handlePrevItem = useCallback(async () => {
    if (popIndex !== 0) {
      setPopIndex(popIndex - 1);
      setPop(pops[popIndex - 1]);
    } else {
      setPopIndex(pops.length - 1);
      setPop(pops[pops.length - 1]);
    }
  }, [popIndex, pops]);

  const handleNextItem = useCallback(async () => {
    if (popIndex !== pops.length - 1) {
      setPopIndex(popIndex + 1);
      setPop(pops[popIndex + 1]);
    } else {
      setPopIndex(0);
      setPop(pops[0]);
    }
  }, [popIndex, pops]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaBriefcase size={20} color="#44546a" />
            <h1>POP</h1>
          </div>
          <aside>
            <Link to="/">
              <FaTimes size={20} color="#44546a" />
            </Link>
          </aside>
        </Header>

        <Controls>
          <button type="button" onClick={handleView}>
            <FaEye />
            <span>Visualização</span>
          </button>
          <Link to={{ pathname: '/pop/new', state: { id: null } }}>
            <FaPlus />
            <span>Novo</span>
          </Link>
          {!showTable && (
            <>
              <button type="button" onClick={handleEdit}>
                <FaEdit />
                <span>Editar</span>
              </button>
              <button type="button" onClick={confirmDelete}>
                <FaTrash />
                <span>Excluir</span>
              </button>
              <div>
                <button type="button" onClick={handlePrevItem}>
                  <FaChevronLeft />
                </button>

                <span>
                  {popIndex + 1} de {pops.length}
                </span>

                <button type="button" onClick={handleNextItem}>
                  <FaChevronRight />
                </button>
              </div>
            </>
          )}
        </Controls>
        {loading || !companyUser ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {showTable ? (
              <TableContainer>
                <thead>
                  <tr>
                    <th className="title">Título</th>
                  </tr>
                </thead>
                <tbody>
                  {pops.map((item, index) => (
                    <tr
                      key={String(item.id)}
                      onClick={() => getDetails(item.id, index)}
                      className="hover"
                    >
                      <td className="title">{item.title}</td>
                    </tr>
                  ))}
                </tbody>
              </TableContainer>
            ) : (
              <DetailsContainer>
                {pop && (
                  <>
                    <PopInfo>
                      <h4>PROCEDIMENTO OPERACIONAL PADRÃO</h4>
                      <section>
                        <div className="title">
                          <label>Título</label>
                          <input
                            name="title"
                            value={pop.title || ''}
                            readOnly
                          />
                        </div>
                        <div className="key-words">
                          <label>Palavras chave (separadas por vírgula)</label>
                          <input
                            name="key_words"
                            value={pop.key_words || ''}
                            readOnly
                          />
                        </div>
                      </section>

                      <section>
                        <div className="title">
                          <label>Link do Docs</label>
                          <input
                            name="docs_link"
                            value={
                              pop.docs_link ||
                              'Nenhum Docs do Google vinculado ao POP'
                            }
                            readOnly
                          />
                        </div>

                        {/* <div className="checkbox">
                          <label>Utilizar Google Docs como POP padrão</label>
                          <input
                            name="google_docs"
                            checked={pop.google_docs}
                            type="checkbox"
                            disabled
                          />
                        </div> */}
                      </section>

                      <section className="sector">
                        <div className="checkbox">
                          <label>Trabalhista</label>
                          <input
                            name="labor"
                            checked={pop.labor}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Tributário</label>
                          <input
                            name="private"
                            checked={pop.tributary}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Contábil</label>
                          <input
                            name="private"
                            checked={pop.accounting}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Financeiro</label>
                          <input
                            name="private"
                            checked={pop.financial}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Administração</label>
                          <input
                            name="private"
                            checked={pop.administration}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Auxiliar</label>
                          <input
                            name="private"
                            checked={pop.auxiliary}
                            type="checkbox"
                            disabled
                          />
                        </div>
                      </section>

                      {pop.google_docs ? (
                        <section style={{ color: '#000' }}>
                          <iframe
                            src={pop.docs_links}
                            title="Google Document"
                            width="100%"
                            height="1000px "
                          />
                        </section>
                      ) : (
                        <section style={{ color: '#000' }}>
                          <JoditEditor
                            value={pop.description}
                            config={config}
                            tabIndex={0}
                          />
                        </section>
                      )}

                      <h4 style={{ marginTop: '16px' }}>LOGS</h4>

                      {pop.audits.map(item => (
                        <section key={item.id}>
                          <div className="audit">
                            <label>Data/Hora</label>
                            <input
                              name="date"
                              value={item.date || ''}
                              type="text"
                              disabled
                            />
                          </div>
                          <div className="audit">
                            <label>Usuário</label>
                            <input
                              name="date"
                              value={item.user || ''}
                              type="text"
                              disabled
                            />
                          </div>
                          <div className="audit">
                            <label>Ação</label>
                            <input
                              name="date"
                              value={item.action_label || ''}
                              type="text"
                              disabled
                            />
                          </div>
                        </section>
                      ))}
                    </PopInfo>
                  </>
                )}
              </DetailsContainer>
            )}
          </Content>
        )}
      </Container>
      {deleteLoading && <Loading />}
    </>
  );
};

export default List;

import React, { useMemo, useRef } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import {
  Container,
  Header,
  Content,
  FormContainer,
  Left,
  Right,
} from './styles';
import { Select } from '~/components/Form';
import ClearBackground from '~/components/ClearBackground';

const ConfirmCreateProcess = ({
  onClick,
  setIsOpen,
  submitForm,
  companyUsers,
  modelsOptions,
}) => {
  const formRef = useRef(null);

  const usersOptions = useMemo(() => {
    if (companyUsers) {
      const options = companyUsers
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      return options;
    }

    return [];
  }, [companyUsers]);

  const handleSubmit = async data => {
    try {
      const schema = Yup.object().shape({
        user_id: Yup.number().typeError('O responsável é obrigatório'),
        processModel_id: Yup.number().typeError('O processo é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const { processModel_id, user_id } = data;

      onClick(processModel_id, user_id);
      setIsOpen();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        toast.error('Falha uma falha, tente novamente.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  const handleSelectModel = ({ responsible_id }) => {
    formRef.current.setFieldValue(
      'user_id',
      usersOptions.find(option => option.value === responsible_id) || null
    );
  };

  return (
    <>
      <ClearBackground />
      <Container>
        <Content>
          <Left>
            <FaExclamationTriangle size={38} color="#01579b" />
          </Left>
          <Right>
            <Header>
              <strong>Atenção</strong>
            </Header>
            <span>
              <strong>Deseja criar um novo processo para esse cliente?</strong>
            </span>

            <FormContainer ref={formRef} onSubmit={handleSubmit}>
              <Select
                name="processModel_id"
                options={modelsOptions}
                onChange={handleSelectModel}
                className="select"
                label="Processo"
                placeholder="Selectione um modelo"
              />
              <Select
                name="user_id"
                options={usersOptions}
                className="select"
                label="Responsável"
                placeholder="Selecione um reponsável"
              />
            </FormContainer>

            <div>
              <button
                type="button"
                onClick={() => formRef.current.submitForm()}
              >
                Criar
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsOpen();
                  submitForm();
                }}
              >
                Não
              </button>
            </div>
          </Right>
        </Content>
      </Container>
    </>
  );
};

export default ConfirmCreateProcess;

ConfirmCreateProcess.propTypes = {
  onClick: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

import React from 'react';
import { Link } from 'react-router-dom';
import { FaQuestionCircle, FaTimes } from 'react-icons/fa';

import List from './List';
import { Container, Header } from './styles';

const Support = () => {
  return (
    <Container>
      <Header>
        <div>
          <FaQuestionCircle size={20} color="#44546a" />
          <h1>Suporte</h1>
        </div>
        <aside>
          <Link to="/">
            <FaTimes size={20} color="#44546a" />
          </Link>
        </aside>
      </Header>
      <div style={{ height: 'calc(100% - 20px)' }}>
        <List />
      </div>
    </Container>
  );
};

export default Support;

import styled, { css } from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  button {
    background: transparent;
    border: none;
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  nav {
    display: flex;
    align-items: center;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
      border: 0;
      transition: opacity 0.2s;

      & + button {
        margin-left: 16px;
      }

      &:hover {
        opacity: 0.7;
      }

      svg {
        font-size: 15px;
        margin-bottom: 3px;
        color: ${({ theme }) => theme.colors.primary};
      }

      span {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  aside {
    display: flex;
    align-items: center;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
      border: 0;
      transition: opacity 0.2s;

      & + button {
        margin-left: 16px;
      }

      &:hover {
        opacity: 0.7;
      }

      svg {
        font-size: 15px;
        margin-bottom: 3px;
        color: ${({ theme }) => theme.colors.primary};
      }
    }

    span {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary};
      margin: 0 16px;
    }
  }
`;

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div + div {
    margin-left: 16px;
  }

  .user {
    width: 30%;
  }

  .taxation_type,
  .contract_type {
    width: 25%;
  }

  .situation {
    width: 20%;
  }

  button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;
    width: 5vw;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  table {
    position: relative;

    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
      text-align: center;

      &:nth-child(1) {
        left: 0;
        z-index: 2;
        width: 50px;
        min-width: 50px;
        padding-left: 8px;
      }

      &:nth-child(2) {
        left: 50px;
        z-index: 2;
        width: 140px;
        min-width: 140px;
        text-align: left;
      }
    }

    tbody {
      tr {
        &:nth-child(2n + 1) {
          background: #fafafa;

          th {
            background: #fafafa;
          }
        }

        &:nth-child(2n + 2) {
          background: #f4f4f4;

          th {
            background: #f4f4f4;
          }
        }
      }

      th {
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 5px;
        color: ${({ theme }) => theme.colors.grey};

        &:nth-child(1) {
          position: sticky;
          left: 0;
          z-index: 1;
          width: 50px;
          min-width: 50px;
          padding-left: 8px;
        }

        &:nth-child(2) {
          position: sticky;
          left: 50px;
          z-index: 1;
          width: 140px;
          min-width: 140px;
          text-align: left;
          max-width: 0;
        }
      }
    }
  }
`;

export const Subtitles = styled.legend`
  display: flex;
  justify-content: space-between;

  padding: 3px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 10px;
  }

  aside {
    display: flex;

    div {
      display: flex;
      align-items: center;

      & + div {
        margin-left: 8px;
      }

      span {
        margin-left: 3px;
        font-size: 12px;
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const DocumentTd = styled.td`
  min-width: 70px;
  text-align: center !important;
  overflow: visible !important;
  white-space: unset !important;

  ${props =>
    (props.situation === 1 || props.situation === 3 || props.situation === 4) &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  > div {
    position: relative;
  }
`;

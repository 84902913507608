import React, { useRef, useState } from 'react';
import { FaEdit, FaPaperclip, FaTimes, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';

import * as Yup from 'yup';
import ClearBackground from '~/components/ClearBackground';
import {
  Checkbox,
  DatePicker,
  FileInput,
  Input,
  Select,
  TextArea,
} from '~/components/Form';
import { ButtonSpinner } from '~/components/Spinner';
import api from '~/services/api';

import {
  Container,
  Content,
  Left,
  Right,
  Header,
  FormContainer,
  UploadFile,
  Delete,
  FileName,
  File,
} from './styles';

const UpdateDocumentModal = ({
  setIsOpen,
  loadProcesses,
  document,
  companyUsers,
  user_id,
  permissionRequired,
}) => {
  const formRef = useRef(null);

  const [uploadFile, setUploadFile] = useState({});
  const [loading, setLoading] = useState(false);
  const [documentReleased, setDocumentReleased] = useState(false);

  const adminsOptions = companyUsers
    .filter(user => user.level === 9 || user.level === 6)
    .map(user => ({
      value: user.user_id,
      label: user.name,
    }));

  const submitForm = async data => {
    if (user_id) {
      try {
        setLoading(true);

        const schema = Yup.object().shape({
          expiration_date: Yup.string().when('released', {
            is: value => !value,
            then: Yup.string()
              .typeError('O vencimento é obrigatório')
              .required('O vencimento é obrigatório'),
            otherwise: Yup.string().nullable(),
          }),
          file_name:
            document.document_required && !data.released
              ? Yup.string().required('O arquivo é obrigatório')
              : Yup.string(),
          released: Yup.bool(),
          admin_id: Yup.number().typeError('O administrador é obrigatório'),
          password: Yup.string().when('released', {
            is: value => value && permissionRequired,
            then: Yup.string().required('A senha é obrigatória'),
            otherwise: Yup.string(),
          }),
          obs: Yup.string().when('released', {
            is: value => value,
            then: Yup.string().required('A observação é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        data.user_id = user_id;
        data.permissionRequired = permissionRequired;

        if (uploadFile.name) {
          const formData = new FormData();

          formData.append('file', uploadFile);

          const fileResponse = await api.post('files/upload', formData, {
            params: {
              prefix: 'Process_Document',
            },
          });

          const { blobName } = fileResponse.data;

          data.file = uploadFile.name;
          data.file_url = blobName;
        }

        const response = await api.put(
          `/processes/documents/expiration/${document.id}`,
          data
        );

        if (response.data && response.data.status === 401) {
          toast.error('Não autorizado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        } else {
          setLoading(false);
          loadProcesses();
          setIsOpen();
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);
        } else {
          toast.error('Falha ao salvar processo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      toast.error('Algo deu errado, reinicia a página e tente novamente.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <>
      <ClearBackground />
      <Container>
        <Content>
          <Left>
            <FaEdit size={38} color="#01579b" />
          </Left>
          <Right>
            <Header>
              <FaTimes onClick={setIsOpen} size={14} color="#e53935" />
              <strong>Atualizar documento:</strong>
            </Header>

            <strong>
              {document.client.name} <br />
              {document.document}
            </strong>

            <FormContainer ref={formRef} onSubmit={submitForm}>
              <DatePicker
                name="expiration_date"
                className="date"
                label="Vencimento"
              />
              <UploadFile>
                {uploadFile.name ? (
                  <>
                    <Delete>
                      <button type="button" onClick={() => setUploadFile({})}>
                        <FaTrash size={14} />
                      </button>
                    </Delete>
                    <FileName>
                      <Input
                        name="file_name"
                        className="file"
                        label="Arquivo"
                        type="text"
                        value={uploadFile.name}
                        disabled
                      />
                    </FileName>
                  </>
                ) : (
                  <File>
                    <label htmlFor="file">
                      <FaPaperclip size={14} color="#FCFCFC" />
                    </label>
                    <FileInput
                      id="file"
                      name="file_name"
                      onChange={e => setUploadFile(e.target.files[0])}
                    />
                  </File>
                )}
              </UploadFile>

              <Checkbox
                id="released"
                name="released"
                className="checkbox"
                label="Desobrigado"
                onChange={e => setDocumentReleased(e.target.checked)}
              />

              {documentReleased && (
                <>
                  {permissionRequired && (
                    <>
                      <strong>Permissão para desobrigar</strong>
                      <Select
                        name="admin_id"
                        options={adminsOptions}
                        className="select"
                        placeholder="Selecione um usuário"
                        label="Administrador"
                      />
                      <Input
                        name="password"
                        className="password"
                        label="Senha"
                        type="password"
                      />
                    </>
                  )}

                  <TextArea name="obs" className="obs" label="Observação" />
                </>
              )}

              <button type="submit" disabled={loading}>
                {loading ? <ButtonSpinner /> : 'Atualizar'}
              </button>
            </FormContainer>
          </Right>
        </Content>
      </Container>
    </>
  );
};

export default UpdateDocumentModal;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  > span {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  > button {
    background: ${({ theme }) => theme.colors.success};
    color: #fff;
    padding: 8px 16px;

    transition: background 0.3s;
    &:hover {
      background: ${({ theme }) => theme.hover.success};
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 10px 10px 60px;
  background: ${({ theme }) => theme.colors.white};
  height: calc(100% - 60px);
  border: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  p {
    font-size: 1rem;
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    }

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  .mail {
    height: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  button,
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: 0;
    transition: opacity 0.2s;

    & + button {
      margin-left: 16px;
    }

    & + a {
      margin-left: 16px;
    }

    &:hover {
      opacity: 0.7;
    }

    svg {
      font-size: 15px;
      margin-bottom: 3px;
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  div {
    margin-left: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin: 0 8px 0;
    }
  }
`;

export const MailForm = styled.div`
  h4:nth-child(n + 2) {
    margin-top: 20px;
  }

  section {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;

    .server,
    .user,
    .password {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .type {
      width: 20%;
    }

    .recipient {
      width: 30%;
    }

    .description {
      margin-left: 16px;
    }

    .description {
      width: calc(100% - 20% - 20px);
    }

    .test {
      color: #fff;
      font-size: 1rem;
      background: #00c853;
      border: 0;
      border-radius: 5px;
      padding: 10px 20px;
      transition: background 0.5s;
      margin-left: 15px;
    }

    .test:hover {
      background: #00a843;
    }
  }

  .auth-span {
    color: ${({ theme }) => theme.colors.error};
    margin-left: 16px;

    button {
      background: transparent;
      border: 0;
      color: ${({ theme }) => theme.colors.blue};
      margin: 16px 5px 0 5px;
    }
  }
`;

export const UploadFile = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 20% - 5px);
`;

export const Delete = styled.div`
  height: 40px;
  padding-top: 20px;
  margin-left: 20px;

  display: flex;
  justify-content: center;

  button {
    color: #e53935;
  }
`;

export const FileName = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 100%;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    height: 30px;
    padding-top: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    background: transparent;
  }
`;

export const File = styled.div`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-left: 16px;
    opacity: 1;
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.blue};
      cursor: pointer;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.blue};
  padding: 10px;

  @media print {
    & {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;

  nav {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 8px;
      color: ${({ theme }) => theme.colors.white};
    }

    > button {
      border: none;
      background-color: ${({ theme }) => theme.colors.white};
      background: none;
      position: relative;
      margin-left: 16px;
    }

    > button {
      span {
        visibility: hidden;
        width: 120px;
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
        font-size: 14px;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 10002;
        top: 150%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.8s;
      }

      span:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent
          ${({ theme }) => theme.colors.primary} transparent;
      }

      &:hover span {
        visibility: visible;
        top: 180%;
        opacity: 1;
      }
    }

    > button:hover span {
      visibility: visible;
      top: 180%;
      opacity: 1;
    }
  }

  aside {
    display: flex;
    align-items: center;

    > button {
      border: none;
      background-color: ${({ theme }) => theme.colors.white};
      background: none;
      position: relative;
      margin-left: 16px;
    }

    > a {
      position: relative;
      margin-left: 16px;
    }

    > button,
    a {
      span {
        visibility: hidden;
        width: 120px;
        background-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
        font-size: 14px;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 10002;
        top: 150%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.8s;
      }

      span:after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent
          ${({ theme }) => theme.colors.primary} transparent;
      }

      &:hover span {
        visibility: visible;
        top: 180%;
        opacity: 1;
      }
    }

    > a:hover span {
      visibility: visible;
      top: 180%;
      opacity: 1;
    }

    > button:hover span {
      visibility: visible;
      top: 180%;
      opacity: 1;
    }
  }
`;

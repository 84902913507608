import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
    }

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }

      & + button {
        margin-left: 10px;
      }
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - 60px);
  margin: 10px;
  overflow: auto;

  label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
  }

  input {
    padding-top: 10px;
    height: 30px;
    background: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    margin-left: 16px;

    display: flex;
    align-items: center;

    & + section {
      margin-top: 16px;
    }

    textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      margin-top: 8px;
    }

    div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-left: 16px;
      }
    }
  }
`;

export const Feedback = styled.div`
  display: flex;
  flex-direction: column;

  section {
    align-items: flex-end;
  }
`;

export const FeedbackItem = styled.section`
  position: relative;
  display: flex;

  div {
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.grey};
      opacity: 0.7;
    }

    input {
      height: 30px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      background: transparent;
    }

    textarea {
      resize: unset;
      background: transparent;
      border: 0px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
      margin-top: 8px;
    }

    button {
      background: none;
      margin-right: 8px;
      padding: 2px;
      transition: color 0.2s;
      color: ${({ theme }) => theme.colors.error};

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: ${({ theme }) => theme.hover.error};
      }
    }
  }

  div + div {
    margin-left: 16px;
  }

  .user {
    width: 20%;
  }

  .date {
    width: 140px;
  }

  .content {
    width: 60%;
  }

  .file {
    flex: 1;

    display: flex;
    flex-direction: row;
    align-items: center;

    > aside {
      display: flex;
      flex-direction: column;

      button {
        background: none;
        color: ${({ theme }) => theme.colors.blue};
        padding: 2px;
        transition: color 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          opacity: ${({ theme }) => theme.hover.blue};
        }
      }
    }

    > div {
      flex: 1;

      margin-left: 8px;
    }
  }
`;

/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from '@react-hook/window-size';
import { FaMedal, FaTimes } from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import { Container, Content, Title, Message, NotShowAgain } from './styles';

const KpiChampionMessage = () => {
  const { user, company } = useAuth();

  const [width, height] = useWindowSize();

  const [visible, setVisible] = useState(false);
  const lastKpiReleasedId = useRef(null);

  const CheckKpiChampion = useCallback(async () => {
    const response = await api.get(`/kpi/released/${company.id}`);

    const lastKpiReleased = response.data;

    if (lastKpiReleased.champion_id === user.id && !lastKpiReleased.read) {
      lastKpiReleasedId.current = lastKpiReleased.id;
      return true;
    }

    return false;
  }, [user, company]);

  useEffect(() => {
    async function isUserChampion() {
      setVisible(await CheckKpiChampion());
    }

    if (company && user) {
      isUserChampion();
    }
  }, [CheckKpiChampion, company, user]);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const handleNotShowAgain = useCallback(async () => {
    await api.put(`/kpi/released/${lastKpiReleasedId.current}`, { read: true });
  }, []);

  return (
    <>
      <Container visible={visible} id="kpi-message">
        {user && company && (
          <Content>
            <section>
              <img
                src={user.avatar ? user.avatar_url : company.logo_url}
                alt="update"
              />
            </section>
            <section>
              <Title>
                <FaMedal size={20} color="#01579B" />
                <h2>{user.nick}, parabéns!</h2>
                <FaTimes
                  className="close"
                  color="#E53935"
                  size={16}
                  onClick={handleClose}
                />
              </Title>
              <Message>
                <span>Você é o grande destaque desse mês!</span>
                <span>Gratidão pelo seu empenho e dedicação!</span>

                <h3>{company.name}</h3>
              </Message>
              <NotShowAgain>
                <input
                  id="not-show"
                  type="checkbox"
                  onClick={handleNotShowAgain}
                />
                <label htmlFor="not-show">
                  Não mostrar esta mensagem novamente
                </label>
              </NotShowAgain>
            </section>
          </Content>
        )}
        <Confetti
          width={width}
          height={height}
          initialVelocityY={10}
          numberOfPieces={600}
          tweenDuration={500}
          gravity={0.1}
        />
      </Container>
    </>
  );
};

export default KpiChampionMessage;

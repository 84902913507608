import React, { useCallback, useEffect, useRef, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { FaBroom, FaSave, FaTimes, FaWhatsapp } from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import ConfirmWindow from '~/components/ConfirmWindow';
import {
  FormLoading,
  Select,
  TextArea,
  Input,
  FormContainer,
} from '~/components/Form';
import Loading from '~/components/Loading';

import formatContact from '~/util/formatContact';

import { useAuth } from '~/hooks';
import api from '~/services/api';
import history from '~/services/history';

import { Container, Header, Controls, Content, MessageInfo } from './styles';

const Form = () => {
  const { company } = useAuth();

  const formRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const [clientsOptions, setClientsOptions] = useState(null);

  const [clients, setClients] = useState([]);

  const [numberClientSelected, setNumberClientSelected] = useState('');

  useEffect(() => {
    async function loadClients() {
      if (company) {
        try {
          setLoading(true);
          const response = await api.get(`/relationships`, {
            params: {
              company_id: company.id,
              selectOnly: true,
              type: 1,
            },
          });

          if (response.data.length > 0) {
            response.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            });

            setClientsOptions(
              response.data.map(item => ({
                value: item.id,
                label: item.name,
              }))
            );

            setClients(response.data);
          } else {
            toast.warn('Nenhum cliente foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            history.push('/whatsapp');
          }
          setLoading(false);
        } catch {
          toast.error('Falha ao buscar clientes.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          history.push('/whatsapp');
        }
      }
    }

    loadClients();
  }, [company]);

  const resetForm = useCallback(() => {
    formRef.current.reset();
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/whatsapp')}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            client_id: Yup.number().typeError('O cliente é obrigatória.'),
            number: Yup.string().required('O número é obrigatória.'),
            message: Yup.string().required('A mensagem é obrigatória.'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          data.company_id = company.id;
          data.number = `55${data.number.replace(/[^0-9]+/g, '')}`;

          await api.post(`/whatsapp-messages`, data);

          formRef.current.setErrors({});

          toast.success('Compromisso salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);

          history.push('/whatsapp');
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar compromisso.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setSaveLoading(false);
        }
      }
    },
    [company]
  );

  const handleSelectClient = useCallback(
    selected => {
      if (selected) {
        const number =
          clients.find(option => option.value === selected.value).phone || '';

        if (number < 9999999999) {
          setNumberClientSelected(formatContact(2, number));
        } else {
          setNumberClientSelected(formatContact(3, number));
        }
      } else {
        setNumberClientSelected('');
      }
    },
    [clients]
  );

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaWhatsapp size={20} color="#44546a" />
            <h1>Whatsapp</h1>
          </div>
        </Header>

        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>

        {loading ? (
          <FormLoading className="loading" />
        ) : (
          <Content className="content">
            <FormContainer
              ref={formRef}
              onSubmit={handleSubmit}
              loading={loading ? 1 : 0}
            >
              <MessageInfo>
                <h4>NOVA MENSAGEM</h4>
                <section>
                  <Select
                    name="client_id"
                    className="client"
                    label="Cliente"
                    type="text"
                    options={clientsOptions}
                    placeholder="Selecione um cliente"
                    onChange={handleSelectClient}
                  />
                  <Input
                    name="number"
                    className="number"
                    label="Telefone"
                    type="text"
                    value={numberClientSelected}
                    disabled
                  />
                </section>
                <section>
                  <TextArea
                    id="message"
                    name="message"
                    className="message"
                    label="Mensagem"
                    type="text"
                  />
                </section>
              </MessageInfo>
            </FormContainer>
          </Content>
        )}
      </Container>

      {saveLoading && <Loading />}
    </>
  );
};

export default Form;

import React, { useEffect, useState, useCallback } from 'react';
import { FaPen } from 'react-icons/fa';

import { Body } from './styles';
import NameGenerator from '../Dropdown/NameGenerator';

const Generator = () => {
  const [visible, setVisible] = useState(false);
  const [showNameGenerator, setShowNameGenerator] = useState(false);

  const handleNameGenerator = useCallback(() => {
    setShowNameGenerator(!showNameGenerator);
    if (visible === true) {
      setVisible(false);
    }
  }, [showNameGenerator, visible]);

  useEffect(() => {
    if (visible) {
      const searchElement = document.getElementById('search');

      if (searchElement) {
        searchElement.focus();
      }
    }
  }, [visible]);

  return (
    <>
      <Body onClick={() => handleNameGenerator()}>
        <span>Gerador de nome</span>
        <FaPen size={18} color="#fcfcfc" />
      </Body>
      <NameGenerator
        visible={showNameGenerator}
        handleNameGenerator={handleNameGenerator}
      />
    </>
  );
};

export default Generator;

import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border};

  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Header = styled.header`
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.greyBackground};
  padding: 5px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    thead th {
      background: ${({ theme }) => theme.colors.white};
      position: sticky;
      top: 0;
    }

    .active {
      padding-left: 8px;
      width: 5%;
      text-align: center;
    }

    .routine {
      padding-left: 8px;
      width: 45%;
    }

    .last_execution,
    .execute {
      width: 25%;
    }

    .execute {
      text-align: center;

      button {
        width: 100%;
        background: none;
        border: none;
        color: ${({ theme }) => theme.colors.success};
        transition: color 0.2s;

        &:hover {
          color: ${({ theme }) => theme.hover.success};
        }
      }
    }
  }
`;

export const ActiveButton = styled.button`
  width: 100%;
  background: none;
  border: none;

  svg {
    transition: color 0.2s;
  }

  &:hover svg {
    color: ${props =>
      props.active
        ? darken(0.05, '#00c853')
        : darken(0.05, '#ee4256')} !important;
  }
`;

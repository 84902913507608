import React, { useRef, useEffect } from 'react';
import ReactSelect, { components } from 'react-select';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import { lighten } from 'polished';

import { Container } from './styles';

const Option = ({ isSelected, label, ...props }) => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={isSelected} onChange={() => null} />
      <label>{label}</label>
    </components.Option>
  </div>
);

const ValueContainer = ({ getValue, options, children, ...props }) => {
  const selectedOptions = getValue();
  let toBeRendered = children;

  if (selectedOptions.length === options.length) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValue = ({ getValue, data, options, ...props }) => {
  const selectedOptions = getValue();

  let labelToBeDisplayed;

  if (selectedOptions.length === options.length) {
    labelToBeDisplayed = 'Todos os usuários.';
  } else {
    labelToBeDisplayed = data.label;
  }

  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const MultiCheckboxSelect = ({ name, options, label, className, ...rest }) => {
  const selectRef = useRef(null);

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  const customStyles = {
    container: provided => ({
      ...provided,
      display: 'inline-block',
      minHeight: '1px',
      textAlign: 'left',
      border: '0',
    }),
    control: provided => ({
      ...provided,
      borderRadius: '0',
      minHeight: '1px',
      height: '30px',
      border: 0,
      borderBottom: error ? '1px solid #e53935' : '1px solid #ddd',
      fontSize: 12,
      background: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '',
      },
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#ccc',
      '&:hover': {
        color: '#ccc',
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: '#545454',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected ? '#01579b' : isFocused ? '#e5eef5' : null,
    }),
    input: provided => ({
      ...provided,
      top: '40%',
    }),
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map(option => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.setValue(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container className={className}>
      <label>{label}</label>
      <ReactSelect
        styles={customStyles}
        defaultValue={
          defaultValue !== undefined &&
          options.filter(option => defaultValue.includes(option.value))
        }
        ref={selectRef}
        classNamePrefix="react-select"
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#094AB2',
            primary25: lighten(0.55, '#094AB2'),
          },
        })}
        options={options}
        onFocus={clearError}
        components={{ Option, ValueContainer, MultiValue }}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        {...rest}
      />
      {error && <span>{error}</span>}
    </Container>
  );
};

export default MultiCheckboxSelect;

MultiCheckboxSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType[(PropTypes.number, PropTypes.string)],
    })
  ).isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

MultiCheckboxSelect.defaultProps = {
  label: null,
  className: null,
};

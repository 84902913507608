import React from 'react';

import { format, parseISO } from 'date-fns';
import { Container } from './styles';
import { TableContainer } from '~/components/Table';

const Report = ({ clients }) => {
  return (
    <Container>
      <TableContainer>
        <thead>
          <tr>
            <th className="name">Nome</th>
            <th className="type">Etapa</th>
            <th className="date">Data</th>
          </tr>
        </thead>
        <tbody>
          {clients &&
            clients.map(clientItem => (
              <tr key={clientItem.id} className="hover">
                <td className="name">{clientItem?.name}</td>
                <td className="type">{clientItem?.stage}</td>
                <td className="date">
                  {format(parseISO(clientItem?.expiration_date), 'dd/MM/yyyy')}
                </td>
              </tr>
            ))}
        </tbody>
      </TableContainer>
    </Container>
  );
};

export default Report;

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parseISO, startOfMonth, format } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import { Scope } from '@unform/core';
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import debounce from 'debounce-promise';
import {
  FaAddressCard,
  FaSave,
  FaBroom,
  FaTimes,
  FaPlus,
  FaMinus,
  FaPen,
} from 'react-icons/fa';
import * as Yup from 'yup';
import cep from 'cep-promise';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import removeAccents from '~/util/removeAccents';

import formatDocument from '~/util/formatDocument';
import formatContact from '~/util/formatContact';
import findAndFormatContact from '~/util/findAndFormatContact';
import returnAddressesWithCitiesOptions from '~/util/returnAddressesWithCitiesOptions';

import {
  FormContainer,
  FormLoading,
  AsyncSelect,
  Select,
  SelectWithAddNewOptionButton,
  Input,
  InputMask,
  InputSelect,
  DatePicker,
  Checkbox,
} from '~/components/Form';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';
import AddOptionModal from './AddOptionModal';
import RelatedPeopleModal from './RelatedPeopleModal';

import {
  Container,
  Header,
  Controls,
  Content,
  BasicInfo,
  Addresses,
  Address,
  ContactsList,
  Contact,
  RelatedList,
  Related,
  TributaryProfile,
  TagsList,
  Tag,
} from './styles';
import ConfirmCreateProcess from './ConfirmCreateProcess';

const Form = () => {
  const { user, company, companyUsers } = useAuth();

  const { state } = useLocation();

  const id = state?.id || null;

  const formRef = useRef(null);

  const [clientTypeStante, setClientTypeStante] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acquisitionTypeLoading, setAcquisitionTypeLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [searchCpfLoading, setSearchCpfLoading] = useState(false);
  const [cepLoading, setCepLoading] = useState(false);

  const [showAddOptionModal, setShowAddOptionModal] = useState(false);
  const [addOptionType, setAddOptionType] = useState(null);
  const [toggleRelatedPeopleModal, setToggleRelatedPeopleModal] = useState(
    false
  );
  const [
    relatedRelationshipPeopleId,
    setRelatedRelationshipPeopleId,
  ] = useState(null);
  const [relatedPeopleId, setRelatedPeopleId] = useState(null);
  const [relatedPeopleIndex, setRelatedPeopleIndex] = useState(null);
  const [relatedPeopleDocument, setRelatedPeopleDocument] = useState(null);

  const [showEndDay, setShowEndDay] = useState(false);

  const [client, setClient] = useState(null);
  const [states, setStates] = useState([]);
  const [acquisitionTypeOptions, setAcquisitionTypeOptions] = useState(null);
  const [inactiveTypeOptions, setInactiveTypeOptions] = useState(null);
  const [currentClientType, setCurrentClientType] = useState(null);

  const newProcess = useRef(null);
  const [createProcessModalIsOpen, setCreateProcessModalIsOpen] = useState(
    false
  );

  const [
    requiredRelatedPeopleErrorMessage,
    setRequiredRelatedPeopleErrorMessage,
  ] = useState('');
  const [
    requiredAddressErrorMessage,
    setRequiredAddressErrorMessage,
  ] = useState('');
  const [requiredTagsErrorMessage, setRequiredTagsErrorMessage] = useState('');

  const [selectedDocumentType, setSelectedDocumentType] = useState({
    value: 2,
    label: 'CNPJ',
  });
  const [selectedClientType, setSelectedClientType] = useState({
    value: '',
    label: 'Selecione um tipo',
  });
  const [selectedStartDay, setSelectedStartDay] = useState(null);

  const currentContractType = useRef(null);
  useEffect(() => {
    async function loadClient() {
      if (id) {
        try {
          setLoading(true);

          const response = await api.get(`relationships/${id}`);

          const { data } = response;

          data.birthday = data.birthday ? parseISO(data.birthday) : null;
          data.contract_date = data.contract_date
            ? parseISO(data.contract_date)
            : null;
          data.start_day = data.start_day ? parseISO(data.start_day) : null;
          data.end_day = data.end_day ? parseISO(data.end_day) : null;
          data.cei = data.cei ? formatDocument(null, data.cei) : '';

          if (data.document_type === 1) {
            data.document_cpf = data.document;
            data.birthday_cpf = data.birthday;
          } else {
            data.document_cnpj = data.document;
            data.birthday_cnpj = data.birthday;
          }

          data.addresses = await returnAddressesWithCitiesOptions(
            data.addresses
          );

          data.contacts =
            data.contacts.length > 0 &&
            data.contacts.map(contact => ({
              ...contact,
              content: contact.content
                ? formatContact(contact.type, contact.content)
                : '',
            }));

          data.related =
            data.related.length > 0 &&
            data.related.map(related => ({
              id: related.id,
              related_relationship_id: related.related_id,
              old_related_relationship_id: related?.info?.old_id,
              related_name: related.info.name,
              treatment_name: related.info.treatment_name,
              dpt_labour: related.dpt_labour,
              dpt_tax: related.dpt_tax,
              dpt_accounting: related.dpt_accounting,
              dpt_financial: related.dpt_financial,
              dpt_admin: related.dpt_admin,
              responsible: related.responsible,
              document: related.info.document
                ? formatDocument(
                    related.info.document_type,
                    related.info.document
                  )
                : '',
              birthday: related.info.birthday
                ? parseISO(related.info.birthday)
                : null,
              email:
                related.related_contacts.length > 0
                  ? findAndFormatContact(related.related_contacts, 'email')
                  : '',
              contact:
                related.related_contacts.length > 0
                  ? findAndFormatContact(related.related_contacts, 'contact')
                  : '',
            }));

          setSelectedDocumentType({
            value: data.document_type,
            label: data.document_type_label,
          });

          setCurrentClientType(data.client_type);

          setSelectedClientType({
            value: data.client_type,
            label: data.client_type_label,
          });

          setSelectedStartDay(data.start_day);

          if (!data.active) {
            setShowEndDay(true);
          }

          setClient(data);

          currentContractType.current = data.contract_type;

          setLoading(false);
        } catch (err) {
          toast.error('Falha ao buscar cliente.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setLoading(false);
        }
      } else {
        setClient({
          document_type: 2,
          protocol_preference: 0,
          tributes_payment: 0,
          closing_tax: true,
          status: true,
          addresses: [
            {
              id: uuid(),
              type: 1,
              state_label: '',
              owner_id: '',
              street: '',
              number: '',
              neighborhood: '',
              city: '',
              state: '',
              zipcode: '',
              complement: '',
            },
          ],
          related: [
            {
              id: uuid(),
              related_name: '',
              treatment_name: '',
              related_relationship_id: '',
              old_related_relationship_id: '',
              document: '',
              birthday: null,
              email: '',
              contact: '',
              dpt_labour: true,
              dpt_tax: true,
              dpt_accounting: true,
              dpt_financial: true,
              dpt_admin: true,
              responsible: true,
            },
          ],
          contacts: [
            {
              id: uuid(),
              type: 1,
              content: '',
            },
          ],
          tags: [],
        });

        setSelectedStartDay(startOfMonth(new Date()));
      }
    }

    loadClient();
  }, [id, formRef]);

  useEffect(() => {
    function loadStates() {
      axios
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(response => {
          const ufInitials = response.data.map(uf => ({
            value: uf.sigla,
            label: uf.nome,
          }));

          setStates(ufInitials);
        });
    }
    loadStates();
  }, []);

  useEffect(() => {
    async function loadAcquisitionTypeOptions() {
      if (company) {
        setAcquisitionTypeLoading(true);

        const response = await api.get(
          `relationships/acquisition-types/${company.id}`
        );

        setAcquisitionTypeOptions(response.data);

        setAcquisitionTypeLoading(false);
      }
    }

    async function loadInactiveTypeOptions() {
      if (company) {
        setAcquisitionTypeLoading(true);

        const response = await api.get(
          `relationships/inactive-types/${company.id}`
        );

        setInactiveTypeOptions(response.data);

        setAcquisitionTypeLoading(false);
      }
    }

    loadAcquisitionTypeOptions();
    loadInactiveTypeOptions();
  }, [company]);

  const documentOptions = useMemo(() => {
    return [
      { value: 1, label: 'CPF' },
      { value: 2, label: 'CNPJ' },
    ];
  }, []);

  const [clientTypeOptions, setClientTypeOptions] = useState([]);

  useEffect(() => {
    (async () => {
      if (company && company.client_limit) {
        const response = await api.get(
          `/relationships/quant-clients/${company.id}`
        );

        const { data } = response;

        const options = [];

        if (data.regular < company.client_limit) {
          options.push({ value: 0, label: 'Regular' });
        }
        if (data.loose < company.client_limit) {
          options.push({ value: 1, label: 'Avulso' });
        }

        setClientTypeOptions(options);
      } else {
        setClientTypeOptions([
          { value: 0, label: 'Regular' },
          { value: 1, label: 'Avulso' },
        ]);
      }
    })();
  }, [company]);

  const protocolPreferenceOptions = useMemo(() => {
    return [
      { value: 0, label: 'Portal' },
      { value: 2, label: 'Impressão' },
    ];
  }, []);

  const tributesPaymentOptions = useMemo(() => {
    return [
      { value: 0, label: 'Feito pelo cliente' },
      { value: 1, label: 'Feito pelo escritório contábil' },
    ];
  }, []);

  const contactsTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'E-mail' },
      { value: 2, label: 'Fixo' },
      { value: 3, label: 'Celular' },
    ];
  }, []);

  const contractTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'Terceirizado' },
      { value: 2, label: 'Próprio' },
    ];
  }, []);

  const taxationTypeOptions = useMemo(() => {
    return [
      { value: 6, label: 'Empregador PF' },
      { value: 5, label: 'Empregador Doméstico' },
      { value: 1, label: 'MEI' },
      { value: 11, label: 'MEI Somente Declarações' },
      { value: 7, label: 'Simples Nacional C/ Inscrição' },
      { value: 8, label: 'Simples Nacional S/ Inscrição' },
      { value: 3, label: 'Lucro Presumido' },
      { value: 4, label: 'Lucro Real' },
      { value: 9, label: 'Condomínio' },
      { value: 10, label: 'Terceiro Setor' },
    ];
  }, []);

  const addressTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'Sede da Empresa' },
      { value: 2, label: 'Correspondência' },
      { value: 3, label: 'Outros' },
    ];
  }, []);

  const usersOptions = useMemo(() => {
    if (companyUsers) {
      const options = companyUsers
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.id,
            label: userItem.short_name,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      return options;
    }

    return [];
  }, [companyUsers]);

  const [processModelsOptions, setProcessModelsOptions] = useState([]);
  useEffect(() => {
    async function loadProcessModels() {
      if (company) {
        try {
          const response = await api.get(`/process-models/simple`, {
            params: {
              company_id: company.id,
              model_type: 0,
            },
          });

          const { data } = response;

          if (data.length > 0) {
            const options = data.map(item => ({
              value: item.id,
              label: item.title,
              responsible_id: item.model_responsible_id,
            }));

            setProcessModelsOptions(options);
          }
        } catch (err) {
          setProcessModelsOptions([]);
        }
      }
    }

    loadProcessModels();
  }, [company]);

  const tagsOptions = useMemo(() => {
    return [
      { value: 'Empregador PF', label: 'Empregador PF' },
      { value: 'Empregador Doméstico', label: 'Empregador Doméstico' },
      { value: 'MEI', label: 'MEI' },
      { value: 'MEI Somente Declarações', label: 'MEI Somente Declarações' },
      {
        value: 'Simples Nacional C/ Inscrição',
        label: 'Simples Nacional C/ Inscrição',
      },
      {
        value: 'Simples Nacional S/ Inscrição',
        label: 'Simples Nacional S/ Inscrição',
      },
      { value: 'Lucro Presumido', label: 'Lucro Presumido' },
      { value: 'Lucro Real', label: 'Lucro Real' },
      { value: 'Condomínio', label: 'Condomínio' },
      { value: 'Terceiro Setor', label: 'Terceiro Setor' },
    ];
  }, []);

  const handleSubmit = useCallback(
    async data => {
      if (company) {
        try {
          setSaveLoading(true);

          let schema;

          if (id) {
            schema = Yup.object().shape({
              document_type: Yup.string().required(
                'O tipo de documento é obrigatório.'
              ),
              document_cpf: Yup.string().when('document_type', {
                is: value => value && value === '1',
                then: Yup.string().required('O documento é obrigatório.'),
                otherwise: Yup.string(),
              }),
              document_cnpj: Yup.string().when('document_type', {
                is: value => value && value === '2',
                then: Yup.string().required('O documento é obrigatório.'),
                otherwise: Yup.string(),
              }),
              name: Yup.string().required('O nome é obrigatório.'),
              client_type: Yup.string().required(
                'O tipo de cliente é obrigatório.aaaaaa'
              ),
              acquisition_type: Yup.string().required(
                'O tipo de aquisição é obrigatório'
              ),
              inactive_type: Yup.string().when('active', {
                is: value => !value,
                then: Yup.string().required(
                  'O tipo de encerramento é obrigatório.'
                ),
                otherwise: Yup.string(),
              }),
              protocol_preference: Yup.string().required(
                'A preferência é obrigatória.'
              ),
              contract_date: Yup.string()
                .nullable()
                .required('A data do contrato é obrigatória.'),
              start_day: Yup.string()
                .nullable()
                .required('A data de implantação é obrigatória.'),
              tributes_payment: Yup.string().required(
                'O tipo de pagamento é obrigatório.'
              ),
              addresses: Yup.array()
                .min(1)
                .of(
                  Yup.object().shape({
                    zipcode: Yup.string().required('O CEP é obrigatório.'),
                    street: Yup.string().required('O endereço é obrigatório.'),
                    number: Yup.string().required('O número é obrigatório.'),
                    neighborhood: Yup.string().required(
                      'O bairro é obrigatório.'
                    ),
                    state: Yup.string().required('O estado é obrigatório.'),
                    city: Yup.string().required('A cidade é obrigatória.'),
                  })
                )
                .required('Pelo menos um endereço é obrigatório.'),
              related: Yup.array().when('document_type', {
                is: value => value && value === '1',
                then: Yup.array().notRequired(),
                otherwise: Yup.array()
                  .min(1)
                  .of(
                    Yup.object().shape({
                      related_name: Yup.string().required(
                        'O nome é obrigatório.'
                      ),
                      document: Yup.string().required(
                        'O documento é obrigatório.'
                      ),
                      birthday: Yup.string()
                        .nullable()
                        .required('A data de nascimento é obrigatória.'),
                      email: Yup.string().required('O email é obrigatório.'),
                      dpt_labour: Yup.boolean(),
                      dpt_tax: Yup.boolean(),
                      dpt_accounting: Yup.boolean(),
                      dpt_financial: Yup.boolean(),
                      dpt_admin: Yup.boolean(),
                      dpt_responsible: Yup.boolean(),
                    })
                  )
                  .required('Pelo menos uma pessoa relacionada é obrigatório.'),
              }),
              tributary_profile: Yup.object().when('client_type', {
                is: value =>
                  (value && currentClientType === 0) ||
                  (value && value !== '0' && currentClientType !== 0),
                then: Yup.object().notRequired(),
                otherwise: Yup.object().shape({
                  contract_type: Yup.string().required(
                    'O tipo de contrato é obrigatório.'
                  ),
                  taxation_type: Yup.string()
                    .required('A tributação é obrigatória.')
                    .nullable(),
                  start_period: Yup.string()
                    .nullable()
                    .required('A data de início é obrigatória.'),
                  labour: Yup.string().required('O responsável é obrigatório.'),
                  tax: Yup.string().required('O responsável é obrigatório.'),
                  accounting: Yup.string().required(
                    'O responsável é obrigatório.'
                  ),
                }),
              }),
            });
          } else {
            schema = Yup.object().shape({
              document_type: Yup.string().required(
                'O tipo de documento é obrigatório.'
              ),
              document_cpf: Yup.string().when('document_type', {
                is: value => value && value === '1',
                then: Yup.string().required('O documento é obrigatório.'),
                otherwise: Yup.string(),
              }),
              document_cnpj: Yup.string().when('document_type', {
                is: value => value && value === '2',
                then: Yup.string().required('O documento é obrigatório.'),
                otherwise: Yup.string(),
              }),
              name: Yup.string().required('O nome é obrigatório.'),
              client_type: Yup.string().required(
                'O tipo de cliente é obrigatório.'
              ),
              protocol_preference: Yup.string().required(
                'A preferência é obrigatória.'
              ),
              acquisition_type: Yup.string().required(
                'O tipo de aquisição é obrigatório'
              ),
              birthday_cpf: Yup.string().when('document_type', {
                is: value => value && value === '1',
                then: Yup.string()
                  .nullable()
                  .required('A data de fundação é obrigatória.'),
                otherwise: Yup.string().nullable(),
              }),
              birthday_cnpj: Yup.string().when('document_type', {
                is: value => value && value === '2',
                then: Yup.string()
                  .nullable()
                  .required('A data de fundação é obrigatória.'),
                otherwise: Yup.string().nullable(),
              }),
              contract_date: Yup.string()
                .nullable()
                .required('A data do contrato é obrigatória.'),
              start_day: Yup.string()
                .nullable()
                .required('A data de implantação é obrigatória.'),
              tributes_payment: Yup.string().required(
                'O tipo de pagamento é obrigatório.'
              ),
              addresses: Yup.array()
                .min(1)
                .of(
                  Yup.object().shape({
                    zipcode: Yup.string().required('O CEP é obrigatório.'),
                    street: Yup.string().required('O endereço é obrigatório.'),
                    number: Yup.string().required('O número é obrigatório.'),
                    neighborhood: Yup.string().required(
                      'O bairro é obrigatório.'
                    ),
                    state: Yup.string().required('O estado é obrigatório.'),
                    city: Yup.string().required('A cidade é obrigatória.'),
                  })
                )
                .required('Pelo menos um endereço é obrigatório.'),
              contacts: Yup.array().when('document_type', {
                is: value => value && value === '2',
                then: Yup.array().of(
                  Yup.object().shape({
                    type: Yup.string().required(
                      'O tipo de contato é obrigatório.'
                    ),
                    content: Yup.string().required('O contato é obrigatório.'),
                  })
                ),
                otherwise: Yup.array()
                  .min(1)
                  .of(
                    Yup.object().shape({
                      type: Yup.string().required(
                        'O tipo de contato é obrigatório.'
                      ),
                      content: Yup.string().required(
                        'O contato é obrigatório.'
                      ),
                    })
                  )
                  .required('Pelo menos um contato é obrigatório.'),
              }),
              related: Yup.array().when('document_type', {
                is: value => value && value === '1',
                then: Yup.array().notRequired(),
                otherwise: Yup.array()
                  .min(1)
                  .of(
                    Yup.object().shape({
                      related_name: Yup.string().required(
                        'O nome é obrigatório.'
                      ),
                      document: Yup.string().required(
                        'O documento é obrigatório.'
                      ),
                      birthday: Yup.string()
                        .nullable()
                        .required('A data de nascimento é obrigatória.'),
                      email: Yup.string().required('O email é obrigatório.'),
                      dpt_labour: Yup.boolean(),
                      dpt_tax: Yup.boolean(),
                      dpt_accounting: Yup.boolean(),
                      dpt_financial: Yup.boolean(),
                      dpt_admin: Yup.boolean(),
                      dpt_responsible: Yup.boolean(),
                    })
                  )
                  .required('Pelo menos uma pessoa relacionada é obrigatório.'),
              }),
              tributary_profile: Yup.object().when('client_type', {
                is: value => value && value !== '0',
                then: Yup.object().notRequired(),
                otherwise: Yup.object().shape({
                  contract_type: Yup.string().required(
                    'O tipo de contrato é obrigatório.'
                  ),
                  taxation_type: Yup.string()
                    .required('A tributação é obrigatória.')
                    .nullable(),
                  start_period: Yup.string()
                    .nullable()
                    .required('A data de início é obrigatória.'),
                  labour: Yup.string().required('O responsável é obrigatório.'),
                  tax: Yup.string().required('O responsável é obrigatório.'),
                  accounting: Yup.string().required(
                    'O responsável é obrigatório.'
                  ),
                }),
              }),
              tags: Yup.array().when('client_type', {
                is: value => value && value !== '0',
                then: Yup.array()
                  .min(1)
                  .of(
                    Yup.object().shape({
                      tag: Yup.string().required('Insira a tag.'),
                    })
                  )
                  .required('Inclua pelo menos uma tag.'),
                otherwise: Yup.array().of(
                  Yup.object().shape({
                    tag: Yup.string().required('Insira a tag.'),
                  })
                ),
              }),
            });
          }

          await schema.validate(data, {
            abortEarly: false,
          });

          data.company_id = company.id;

          if (data.phone) {
            data.phone = data.phone.replace(/[^0-9]+/g, '');
          }

          if (data.cei) {
            data.cei = data.cei.replace(/[^0-9]+/g, '');
          }

          data.type = 1;

          if (data.document_type === 1) {
            data.document = data.document_cpf.replace(/[^0-9]+/g, '');
            data.birthday = data.birthday_cpf;
          } else {
            data.document = data.document_cnpj.replace(/[^0-9]+/g, '');
            data.birthday = data.birthday_cnpj;
          }

          if (data.nickname === '') {
            data.nickname = data.name;
          }

          let client_id;
          let old_client_id;

          const getOldData = await api.get('user-companies/old-data', {
            params: {
              company_id: company.id,
            },
          });

          const { old_company_id, mainid } = getOldData.data;

          if (id) {
            client_id = id;
            data.contract_type = currentContractType.current;

            const existedClient = await api.put(
              `relationships/${client_id}`,
              data,
              {
                params: {
                  mainid,
                },
              }
            );

            old_client_id = existedClient.data.old_id;
          } else {
            data.contract_type = data.tributary_profile?.contract_type || null;

            const createdClient = await api.post('relationships', data, {
              params: {
                old_company_id,
                mainid,
              },
            });

            client_id = createdClient.data.id;
            old_client_id = createdClient.data.old_id;
          }

          if (data.client_type === 0 && currentClientType !== 0) {
            const tributaryProfileData = {
              client_id,
              contract_type: data.tributary_profile.contract_type,
              company_id: company.id,
            };

            const createdTributaryProfile = await api.post(
              'tributary-profiles',
              tributaryProfileData
            );

            const { id: tributary_id } = createdTributaryProfile.data;

            await api.post('tributary-profiles/periods', [
              {
                id: null,
                tributary_id,
                taxation_type: data.tributary_profile.taxation_type,
                start_period: data.tributary_profile.start_period,
                end_period: null,
                responsible_labour: data.tributary_profile.labour,
                responsible_tax: data.tributary_profile.tax,
                responsible_accounting: data.tributary_profile.accounting,
              },
            ]);

            const models = await api.get('checklists-models', {
              params: {
                only_models: true,
                company_id: company.id,
              },
            });

            const promises = models.data.map(async model => {
              if (model.department !== 'dpt_accounting') {
                const parameterData = {
                  company_id: company.id,
                  client_id,
                  model_id: model.id,
                  start_period: data.tributary_profile.start_period,
                  end_period: null,
                };

                const parameter = await api.post(
                  'checklists-parameters',
                  parameterData
                );

                const { id: parameter_id } = parameter.data;

                let responsible;
                let taxation_type;

                if (model.department === 'dpt_tax') {
                  responsible = data.tributary_profile.tax;
                }
                if (model.department === 'dpt_labour') {
                  responsible = data.tributary_profile.labour;
                }

                const responsibleData = {
                  parameter_id,
                  responsible_id: responsible,
                  start_period: data.tributary_profile.start_period,
                  end_period: null,
                };

                await api.post(
                  'checklists-parameters/responsibles',
                  responsibleData
                );

                if (data.tributary_profile.taxation_type === 1) {
                  taxation_type = 'taxation_mei';
                }
                if (data.tributary_profile.taxation_type === 3) {
                  taxation_type = 'taxation_lucro_presumido';
                }
                if (data.tributary_profile.taxation_type === 4) {
                  taxation_type = 'taxation_lucro_real';
                }
                if (data.tributary_profile.taxation_type === 5) {
                  taxation_type = 'taxation_empregador_domestico';
                }
                if (data.tributary_profile.taxation_type === 6) {
                  taxation_type = 'taxation_empregador_pf';
                }
                if (data.tributary_profile.taxation_type === 7) {
                  taxation_type = 'taxation_simples_com_inscricao';
                }
                if (data.tributary_profile.taxation_type === 8) {
                  taxation_type = 'taxation_simples_sem_inscricao';
                }
                if (data.tributary_profile.taxation_type === 9) {
                  taxation_type = 'taxation_condominio';
                }
                if (data.tributary_profile.taxation_type === 10) {
                  taxation_type = 'taxation_terceiro';
                }
                if (data.tributary_profile.taxation_type === 11) {
                  taxation_type = 'taxation_mei_somente_declaracoes';
                }

                await api.post('checklists-parameters/documents', {
                  parameter_id,
                  model_id: model.id,
                  taxation_type,
                  department: model.department,
                  start_period: data.tributary_profile.start_period,
                  company_id: company.id,
                  client_id,
                });
              } else {
                const parameterData = {
                  company_id: company.id,
                  client_id,
                  model_id: model.id,
                  start_period: data.tributary_profile.start_period,
                  end_period: null,
                };

                const createdParameter = await api.post(
                  'checklists-parameters',
                  parameterData
                );

                const { id: parameter_id } = createdParameter.data;

                const responsibleData = {
                  parameter_id,
                  responsible_id: data.tributary_profile.accounting,
                  start_period: data.tributary_profile.start_period,
                  end_period: null,
                };

                await api.post(
                  'checklists-parameters/responsibles',
                  responsibleData
                );
                /**
                 * Temporary removed
                 */
                // await api.post(
                //   `checklists-parameters/accounting-documents/${company.id}`,
                //   {
                //     parameter_id,
                //     client_id,
                //   }
                // );
              }
            });

            await Promise.all(promises);
          }

          const addressesData = data.addresses.map(address => {
            return {
              id: Number(address.id),
              type: address.type,
              owner_id: client_id,
              street: address.street,
              zipcode: address.zipcode.replace(/[^0-9]+/g, ''),
              number: address.number,
              neighborhood: address.neighborhood,
              city: address.city,
              state: address.state,
              complement: address.complement,
            };
          });

          await api.post(
            'relationships/addresses',
            {
              addresses: addressesData,
            },
            {
              params: {
                owner_id: client_id,
              },
            }
          );

          if (data.related) {
            const relatedData = data.related.map(related => {
              return {
                id: related.id,
                relationship_id: client_id,
                old_relationship_id: old_client_id,
                related_name: related.related_name,
                treatment_name: related.treatment_name,
                related_relationship_id: related.related_relationship_id,
                old_related_relationship_id:
                  related.old_related_relationship_id,
                company_id: company.id,
                birthday: related.birthday,
                document: related.document.replace(/[^0-9]+/g, ''),
                email: related.email,
                contact: related.contact
                  ? related.contact.replace(/[^0-9]+/g, '')
                  : '',
                dpt_labour:
                  data.related.length === 1 ? true : related.dpt_labour,
                dpt_tax: data.related.length === 1 ? true : related.dpt_tax,
                dpt_accounting:
                  data.related.length === 1 ? true : related.dpt_accounting,
                dpt_financial:
                  data.related.length === 1 ? true : related.dpt_financial,
                dpt_admin: data.related.length === 1 ? true : related.dpt_admin,
                responsible:
                  data.related.length === 1 ? true : related.responsible,
                old_client_id,
              };
            });

            await api.post(
              'relationships/related',
              {
                company_id: company.id,
                related: relatedData,
              },
              {
                params: {
                  relationship_id: client_id,
                  old_relationship_id: old_client_id,
                  old_company_id,
                  mainid,
                },
              }
            );
          }

          if (data.contacts) {
            const contactsData = data.contacts.map(contact => {
              return {
                id: Number(contact.id),
                owner_id: client_id,
                type: contact.type,
                content:
                  contact.type === 1
                    ? contact.content
                    : contact.content.replace(/[^0-9]+/g, ''),
              };
            });

            await api.post(
              'relationships/contacts',
              {
                company_id: company.id,
                contacts: contactsData,
              },
              {
                params: {
                  relationship_id: client_id,
                  old_relationship_id: old_client_id,
                  old_company_id,
                  mainid,
                },
              }
            );
          }

          if (data.tags) {
            const tagsData = data.tags.map(tag => {
              return {
                id: Number(tag.id),
                owner_id: client_id,
                tag: tag.tag,
              };
            });

            await api.post(
              'relationships/tags',
              {
                company_id: company.id,
                tags: tagsData,
              },
              {
                params: {
                  relationship_id: client_id,
                  old_relationship_id: old_client_id,
                  old_company_id,
                  mainid,
                },
              }
            );
          }

          if (!id && data.client_type === 0) {
            await api.post('/processes/create-expiration-process', {
              user_id: user.id,
              user_name: user.short_name,
              company_id: company.id,
              type: data.tributary_profile.contract_type,
              client_id,
            });

            if (!id && newProcess.current) {
              await api.post('/processes/default', {
                processModel_id: newProcess.current.processModel_id,
                user_id: newProcess.current.user_id,
                company_id: company.id,
                client_id,
              });
            }

            // if (data.tributary_profile.contract_type === 2) {
            await api.post('/costs/client', {
              company_id: company.id,
              client_id,
              period: format(new Date(), 'MM/yyyy'),
            });
            // }
          }

          formRef.current.setErrors({});

          setTimeout(() => {
            toast.success('Cliente salvo com sucesso.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

            setSaveLoading(false);

            history.push('/client', { id: null });
          }, 250);
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              if (error.path === 'related') {
                setRequiredRelatedPeopleErrorMessage(error.message);
              }
              if (error.path === 'addresses') {
                setRequiredAddressErrorMessage(error.message);
              }
              if (error.path === 'tags') {
                setRequiredTagsErrorMessage(error.message);
              }
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);

            toast.error(
              'Existem campos obrigatórios em vermelho que precisam ser preenchidos.',
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          } else {
            toast.error('Falha ao salvar cliente.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setSaveLoading(false);
        }
      }
    },
    [id, company, user, currentClientType]
  );

  const resetForm = useCallback(() => {
    formRef.current.reset();
    formRef.current.setErrors({});
    setRequiredAddressErrorMessage('');
    setRequiredRelatedPeopleErrorMessage('');
    setRequiredTagsErrorMessage('');
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/client', { id: null })}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleChangeDocumentType = useCallback(event => {
    setSelectedDocumentType({
      value: event.value,
      label: event.label,
    });
  }, []);

  const confirmRemove = useCallback((functionToExecute, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => functionToExecute(index)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleAddNewAddress = useCallback(() => {
    setClient(
      produce(client, draft => {
        const blankAddress = {
          id: uuid(),
          type: 1,
          state_label: '',
          owner_id: '',
          street: '',
          number: '',
          neighborhood: '',
          city: '',
          state: '',
          zipcode: '',
          complement: '',
        };

        if (draft?.addresses?.length > 0) {
          draft.addresses.unshift(blankAddress);
        } else if (draft?.addresses) {
          draft.addresses[0] = blankAddress;
        } else {
          draft.addresses = [blankAddress];
        }
      })
    );
  }, [client]);

  const handleRemoveAddress = useCallback(
    async index => {
      setClient(
        produce(client, draft => {
          draft.addresses.splice(index, 1);
        })
      );
    },
    [client]
  );

  const handleAddNewContact = useCallback(() => {
    setClient(
      produce(client, draft => {
        const blankContact = {
          id: uuid(),
          type: 1,
          content: '',
        };

        if (draft?.contacts?.length > 0) {
          draft.contacts.unshift(blankContact);
        } else if (draft?.contacts) {
          draft.contacts[0] = blankContact;
        } else {
          draft.contacts = [blankContact];
        }
      })
    );
  }, [client]);

  const handleRemoveContact = useCallback(
    async index => {
      setClient(
        produce(client, draft => {
          draft.contacts.splice(index, 1);
        })
      );
    },
    [client]
  );

  const handleAddNewRelated = useCallback(() => {
    setClient(
      produce(client, draft => {
        const blankRelated = {
          id: uuid(),
          related_relationship_id: '',
          old_related_relationship_id: '',
          related_name: '',
          treatment_name: '',
          document: '',
          birthday: null,
          email: '',
          contact: '',
          dpt_labour: true,
          dpt_tax: true,
          dpt_accounting: true,
          dpt_financial: true,
          dpt_admin: true,
          responsible: true,
        };

        if (draft?.related?.length > 0) {
          draft.related.unshift(blankRelated);
        } else if (draft?.related) {
          draft.related[0] = blankRelated;
        } else {
          draft.related = [blankRelated];
        }
      })
    );
  }, [client]);

  const handleRemoveRelated = useCallback(
    async index => {
      setClient(
        produce(client, draft => {
          draft.related.splice(index, 1);
        })
      );
    },
    [client]
  );

  const handleChangeRelatedPeopleByCpf = useCallback(
    async (value, index, relatedId) => {
      if (company) {
        const formattedCpf = value.replace(/[^0-9]+/g, '');

        if (formattedCpf.length !== 11) {
          return;
        }

        setSearchCpfLoading(true);

        const { data } = await api.get('relationships', {
          params: {
            company_id: company.id,
            selectOnly: true,
            selectOnlyType: 'cpf',
            search: formattedCpf,
          },
        });

        if (data.length === 0) {
          setRelatedRelationshipPeopleId(null);
          setRelatedPeopleId(relatedId);
          setRelatedPeopleIndex(index);
          setRelatedPeopleDocument(formattedCpf);
          setToggleRelatedPeopleModal(true);
        } else if (data.length === 1) {
          const formattedContact = data[0].contact
            ? formatContact(3, data[0].contact)
            : '';

          setClient(
            produce(client, draft => {
              draft.related[index].id = relatedId;
              draft.related[index].related_relationship_id = data[0].id;
            })
          );

          formRef.current.setFieldValue(
            `related[${index}].related_relationship_id`,
            `${data[0].id}`
          );
          formRef.current.setFieldValue(
            `related[${index}].old_related_relationship_id`,
            `${data[0].old_id ? data[0].old_id : ''}`
          );
          formRef.current.setFieldValue(
            `related[${index}].related_name`,
            `${data[0].name}`
          );
          formRef.current.setFieldValue(
            `related[${index}].treatment_name`,
            `${data[0].treatment_name || data[0].name.split(' ', 1)}`
          );
          formRef.current.setFieldValue(
            `related[${index}].email`,
            `${data[0].email}`
          );
          formRef.current.setFieldValue(
            `related[${index}].contact`,
            `${formattedContact}`
          );
          formRef.current.setFieldValue(
            `related[${index}].birthday`,
            data[0].birthday ? parseISO(data[0].birthday) : null
          );
        } else {
          setClient(
            produce(client, draft => {
              const relatedPerson = {
                id: uuid(),
                document: value,
                dpt_labour: true,
                dpt_tax: true,
                dpt_accounting: true,
                dpt_financial: true,
                dpt_admin: true,
                responsible: true,
                options: data,
              };

              draft.related[index] = relatedPerson;
            })
          );
        }

        setSearchCpfLoading(false);
      }
    },
    [company, client]
  );

  const handleToggleEditRelatedPeopleModal = useCallback(
    (relatedRelationshipPeopleIdParam, index, relatedId) => {
      if (relatedRelationshipPeopleIdParam) {
        setRelatedRelationshipPeopleId(relatedRelationshipPeopleIdParam);
        setRelatedPeopleIndex(index);
        setRelatedPeopleId(relatedId);
      }
      setToggleRelatedPeopleModal(!toggleRelatedPeopleModal);
    },
    [toggleRelatedPeopleModal]
  );

  const updateRelatedPeople = useCallback(
    async (value, index, relatedId) => {
      if (company) {
        const { data } = await api.get('relationships', {
          params: {
            company_id: company.id,
            selectOnly: true,
            selectOnlyType: 'cpf',
            search: value,
          },
        });

        const formattedContact = data[0].contact
          ? formatContact(3, data[0].contact)
          : '';

        setClient(
          produce(client, draft => {
            draft.related[index].id = relatedId;
            draft.related[index].related_relationship_id = data[0].id;
          })
        );

        formRef.current.setFieldValue(
          `related[${index}].related_relationship_id`,
          `${data[0].id}`
        );
        formRef.current.setFieldValue(
          `related[${index}].old_related_relationship_id`,
          `${data[0].old_id}`
        );
        formRef.current.setFieldValue(
          `related[${index}].related_name`,
          `${data[0].name}`
        );
        formRef.current.setFieldValue(
          `related[${index}].treatment_name`,
          `${data[0].treatment_name || data[0].name.split(' ', 1)}`
        );
        formRef.current.setFieldValue(
          `related[${index}].email`,
          `${data[0].email}`
        );
        formRef.current.setFieldValue(
          `related[${index}].contact`,
          `${formattedContact}`
        );
        formRef.current.setFieldValue(
          `related[${index}].birthday`,
          data[0].birthday ? parseISO(data[0].birthday) : null
        );
      }
    },
    [company, client]
  );

  const handleChangeRelatedPeopleByCpfWithOptions = useCallback(
    (event, index, relatedId) => {
      const formattedContact = event.contact
        ? formatContact(3, event.contact)
        : '';

      setClient(
        produce(client, draft => {
          draft.related[index].id = relatedId;
          draft.related[index].related_relationship_id = event.id;
        })
      );

      formRef.current.setFieldValue(
        `related[${index}].related_relationship_id`,
        `${event.id}`
      );
      formRef.current.setFieldValue(
        `related[${index}].old_related_relationship_id`,
        `${event.old_id ? event.old_id : ''}`
      );
      formRef.current.setFieldValue(
        `related[${index}].related_name`,
        `${event.name}`
      );
      formRef.current.setFieldValue(
        `related[${index}].treatment_name`,
        `${event.treatment_name || event.name.split(' ', 1)}`
      );
      formRef.current.setFieldValue(
        `related[${index}].email`,
        `${event.email}`
      );
      formRef.current.setFieldValue(
        `related[${index}].contact`,
        `${formattedContact}`
      );
      formRef.current.setFieldValue(
        `related[${index}].birthday`,
        event.birthday ? parseISO(event.birthday) : null
      );
    },
    [client]
  );

  const handleAddNewTag = useCallback(() => {
    setClient(
      produce(client, draft => {
        const blankTag = {
          id: uuid(),
          tag: '',
        };

        if (draft.tags.length > 0) {
          draft.tags.unshift(blankTag);
        } else if (draft.tags) {
          draft.tags[0] = blankTag;
        } else {
          draft.tags = [blankTag];
        }
      })
    );
  }, [client]);

  const handleRemoveTag = useCallback(
    async index => {
      setClient(
        produce(client, draft => {
          draft.tags.splice(index, 1);
        })
      );
    },
    [client]
  );

  const handleChangeClientType = useCallback(
    event => {
      setSelectedClientType({
        value: event.value,
        label: event.label,
      });

      if (event.value !== 0) {
        if (client.tags) {
          if (client.tags.length === 0) {
            handleAddNewTag();
          }
        } else {
          handleAddNewTag();
        }
      }
    },
    [handleAddNewTag, client]
  );

  const handleChangeContactType = useCallback(
    (event, index) => {
      setClient(
        produce(client, draft => {
          draft.contacts[index].type = event.value;
        })
      );
    },
    [client]
  );

  const handleChangeZipcode = useCallback(
    async (value, index) => {
      const unmaskedValue = value.replace(/[^0-9]+/g, '');

      if (unmaskedValue.length !== 8) {
        return;
      }

      try {
        setCepLoading(true);

        formRef.current.setErrors({});

        const cepData = await cep(unmaskedValue);

        const citiesOptions = await axios
          .get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${cepData.state}/municipios`
          )
          .then(response => {
            const cityNames = response.data.map(city => ({
              value: city.nome,
              label: city.nome,
            }));

            return cityNames;
          });

        setClient(
          produce(client, draft => {
            const address = {
              id: uuid(),
              type: 1,
              zipcode: unmaskedValue,
              street: cepData.street,
              number: '',
              complement: '',
              neighborhood: cepData.neighborhood,
              state: cepData.state,
              city: cepData.city,
              citiesOptions,
            };

            draft.addresses[index] = address;
          })
        );

        const numberEl = document.getElementById(`number${index}`);

        numberEl.focus();
      } catch (err) {
        formRef.current.setFieldError(
          `addresses[${index}].zipcode`,
          'CEP inválido.'
        );
      } finally {
        setCepLoading(false);
      }
    },
    [client]
  );

  const handleChangeState = useCallback(
    async (value, index) => {
      const citiesOptions = await axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`
        )
        .then(response => {
          const cityNames = response.data.map(city => ({
            value: city.nome,
            label: city.nome,
          }));

          return cityNames;
        });

      setClient(
        produce(client, draft => {
          if (draft.addresses[index]) {
            draft.addresses[index].state = value;
            draft.addresses[index].citiesOptions = citiesOptions;
          }
        })
      );
    },
    [client]
  );

  const handleChangeStartDay = useCallback(date => {
    setSelectedStartDay(date);
  }, []);

  const handleGroup = debounce(
    useCallback(
      async value => {
        if (company) {
          if (!value) {
            return Promise.resolve({ options: [] });
          }

          const { data } = await api.get('relationships', {
            params: {
              company_id: company.id,
              selectOnly: true,
              selectOnlyType: 'name',
              search: value,
              type: 1,
            },
          });

          if (data.length > 0) {
            return data.map(item => {
              return { value: item.id, label: item.label };
            });
          }

          return [];
        }
        return [];
      },
      [company]
    ),
    500
  );

  const handleAddNewOptionModal = useCallback(
    type => {
      setShowAddOptionModal(!showAddOptionModal);
      setAddOptionType(type);
    },
    [showAddOptionModal]
  );

  const handleAddNewOption = useCallback(
    async value => {
      if (company) {
        const formattedValue = removeAccents(
          value.replace(/ /g, '_').toLowerCase()
        );

        try {
          setSaveLoading(true);

          if (addOptionType === 0) {
            await api.post(`relationships/acquisition-types/${company.id}`, {
              value,
            });

            setAcquisitionTypeOptions(
              produce(acquisitionTypeOptions, draft => {
                draft.push({ value: formattedValue, label: value });
              })
            );
          } else if (addOptionType === 1) {
            await api.post(`relationships/inactive-types/${company.id}`, {
              value,
            });

            setInactiveTypeOptions(
              produce(inactiveTypeOptions, draft => {
                draft.push({ value: formattedValue, label: value });
              })
            );
          } else {
            toast.error('Falha ao adicionar tipo.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setSaveLoading(false);
        } catch {
          toast.error('Falha ao salvar tipo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setSaveLoading(false);
        }

        handleAddNewOptionModal(null);
      }
    },
    [
      company,
      handleAddNewOptionModal,
      acquisitionTypeOptions,
      addOptionType,
      inactiveTypeOptions,
    ]
  );

  const handleChangeActive = useCallback(() => {
    setShowEndDay(!showEndDay);
  }, [showEndDay]);

  const handleNewProcessInfo = useCallback((processModel_id, user_id) => {
    newProcess.current = { processModel_id, user_id };
    formRef.current.submitForm();
  }, []);

  const confirmCreateNewProcess = useCallback(() => {
    const client_type = formRef.current.getFieldValue('client_type');

    if (!id && client_type === 0 && processModelsOptions.length > 0) {
      setCreateProcessModalIsOpen(true);
    } else {
      formRef.current.submitForm();
    }
  }, [processModelsOptions, id]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaAddressCard size={20} color="#44546a" />
            <h1>Clientes</h1>
          </div>
        </Header>
        <Controls>
          <button type="button" onClick={confirmCreateNewProcess}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {(loading || acquisitionTypeLoading || !company) && (
          <FormLoading className="loading" />
        )}
        {client && acquisitionTypeOptions && (
          <Content className="content">
            <FormContainer
              ref={formRef}
              loading={loading ? 1 : 0}
              onSubmit={handleSubmit}
              initialData={client}
            >
              <BasicInfo>
                <h4>INFORMAÇÕES BÁSICAS</h4>
                <section>
                  <Select
                    name="document_type"
                    className="document_type"
                    label="PF/PJ"
                    options={documentOptions}
                    onChange={handleChangeDocumentType}
                    placeholder="Selecione um tipo"
                  />
                  {selectedDocumentType.value === 1 && (
                    <InputMask
                      name="document_cpf"
                      className="document_cpf"
                      label="CPF"
                      mask="999.999.999-99"
                    />
                  )}
                  {selectedDocumentType.value === 2 && (
                    <InputMask
                      name="document_cnpj"
                      className="document_cnpj"
                      label="CNPJ"
                      mask="99.999.999/9999.99"
                    />
                  )}
                  <Input name="name" className="name" label="Nome" />
                  {id ? (
                    <>
                      <Select
                        name="client_type"
                        className="client_type"
                        label="Tipo de cliente"
                        options={clientTypeOptions}
                        onChange={e => {
                          setClientTypeStante(e.value === 0);
                          setClient({
                            ...client,
                            client_type: e.value,
                            client_type_label: e.label,
                          });
                        }}
                      />
                    </>
                  ) : (
                    <Select
                      name="client_type"
                      className="client_type"
                      label="Tipo de cliente"
                      options={clientTypeOptions}
                      placeholder="Selecione um tipo"
                      onChange={handleChangeClientType}
                    />
                  )}
                </section>
                <section>
                  <Input
                    name="nickname"
                    className="nickname"
                    label="Nome abreviado"
                  />
                  <Input
                    name="state_registration"
                    className="state_registration"
                    label={
                      selectedDocumentType.value === 1
                        ? 'Identidade'
                        : 'Inscrição Estadual'
                    }
                  />
                  <InputMask
                    name="cei"
                    className="cei"
                    label="CEI/CAEPF"
                    mask="99.999999999/99-99"
                  />
                  {selectedDocumentType.value === 2 && (
                    <InputMask
                      name="phone"
                      className="phone"
                      label="Telefone Geral"
                      mask="(99) 9999-99999"
                    />
                  )}
                  <AsyncSelect
                    name="group"
                    cacheOptions
                    label="Grupo"
                    className="group"
                    loadOptions={handleGroup}
                    defaultValue={
                      client.group
                        ? {
                            value: client.group,
                            label: client.group_relationship.name,
                          }
                        : { value: null, label: 'Selecione um grupo' }
                    }
                    placeholder="Selecione um grupo"
                    noOptionsMessage={() => 'Digite para pesquisar'}
                    loadingMessage={() => 'Carregando...'}
                  />
                </section>
                <section>
                  {selectedDocumentType.value === 1 ? (
                    <DatePicker
                      name="birthday_cpf"
                      className="birthday_cpf"
                      label="Nascimento"
                    />
                  ) : (
                    <DatePicker
                      name="birthday_cnpj"
                      className="birthday_cnpj"
                      label="Fundação"
                    />
                  )}
                  <DatePicker
                    name="contract_date"
                    className="contract_date"
                    label="Data do contrato"
                  />
                  <DatePicker
                    name="start_day"
                    className="start_day"
                    label="Data de implantação"
                    selected={selectedStartDay}
                    onChange={handleChangeStartDay}
                    minDate={new Date(2020, 9, 1)}
                  />

                  <Select
                    name="protocol_preference"
                    className="protocol_preference"
                    label="Preferência de protocolo"
                    options={protocolPreferenceOptions}
                    placeholder="Selecione um tipo"
                  />
                  <Select
                    name="tributes_payment"
                    className="tributes_payment"
                    label="Pgto de Tributos"
                    options={tributesPaymentOptions}
                    placeholder="Selecione um tipo"
                  />
                </section>
                <section className="checkbox">
                  <SelectWithAddNewOptionButton
                    name="acquisition_type"
                    label="Tipo de Aquisição"
                    className="acquisition_type"
                    options={acquisitionTypeOptions}
                    handleAddNewOption={() => handleAddNewOptionModal(0)}
                    placeholder="Selecione um tipo"
                  />
                  <Checkbox
                    id="closing_tax"
                    name="closing_tax"
                    className="closing_tax"
                    label="Taxa de encerramento"
                  />
                  {id && (
                    <Checkbox
                      id="active"
                      name="active"
                      className="situation"
                      label="Ativo"
                      onChange={handleChangeActive}
                    />
                  )}
                  {id && showEndDay && (
                    <DatePicker
                      name="end_day"
                      className="end_day"
                      label="Data de encerramento"
                    />
                  )}
                  {id && showEndDay && (
                    <SelectWithAddNewOptionButton
                      name="inactive_type"
                      className="inactive"
                      label="Tipo de encerramento"
                      options={inactiveTypeOptions}
                      handleAddNewOption={() => handleAddNewOptionModal(1)}
                      placeholder="Selecione um tipo"
                    />
                  )}
                </section>
              </BasicInfo>

              <Addresses>
                <header>
                  <h4>ENDEREÇOS</h4>
                  <button type="button" onClick={handleAddNewAddress}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {requiredAddressErrorMessage && (
                  <span>{requiredAddressErrorMessage}</span>
                )}

                {client.addresses &&
                  client.addresses.length > 0 &&
                  client.addresses.map((address, index) => (
                    <Address key={address.id}>
                      <button
                        type="button"
                        onClick={() =>
                          confirmRemove(handleRemoveAddress, index)
                        }
                      >
                        <FaMinus size={10} />
                      </button>

                      <Scope path={`addresses[${index}]`}>
                        <section>
                          <Input
                            name="id"
                            type="text"
                            className="hide"
                            readOnly
                          />
                          <Select
                            name="type"
                            className="address_type"
                            label="Tipo"
                            placeholder="Selecione um tipo"
                            options={addressTypeOptions}
                          />
                          <InputMask
                            name="zipcode"
                            className="zipcode"
                            label="CEP"
                            mask="99999-999"
                            onChange={e =>
                              handleChangeZipcode(e.target.value, index)
                            }
                          />
                          <Input
                            name="street"
                            className="street"
                            label="Endereço"
                          />
                          <Input
                            id={`number${index}`}
                            name="number"
                            className="number"
                            label="Nº"
                          />
                        </section>
                        <section>
                          <Input
                            name="complement"
                            className="complement"
                            label="Complemento"
                          />
                          <Input
                            name="neighborhood"
                            className="neighborhood"
                            label="Bairro"
                          />
                          <Select
                            name="state"
                            className="state"
                            label="Estado"
                            placeholder="Selecione um estado"
                            options={states}
                            onChange={e => handleChangeState(e.value, index)}
                          />
                          {address.state && (
                            <Select
                              name="city"
                              className="city"
                              label="Cidade"
                              placeholder="Selecione uma cidade"
                              defaultValue={{
                                value: address.city,
                                label: address.city,
                              }}
                              options={address.citiesOptions}
                            />
                          )}
                        </section>
                      </Scope>
                    </Address>
                  ))}
              </Addresses>

              {selectedDocumentType.value === 1 && (
                <ContactsList>
                  <header>
                    <h4>CONTATOS</h4>
                    <button type="button" onClick={handleAddNewContact}>
                      <FaPlus size={10} />
                    </button>
                  </header>

                  {client.contacts &&
                    client.contacts.length > 0 &&
                    client.contacts.map((contact, index) => (
                      <Contact key={contact.id}>
                        <button
                          type="button"
                          onClick={() =>
                            confirmRemove(handleRemoveContact, index)
                          }
                        >
                          <FaMinus size={10} />
                        </button>
                        <Scope path={`contacts[${index}]`}>
                          <section>
                            <Input
                              name="id"
                              type="text"
                              className="hide"
                              readOnly
                            />
                            <Select
                              name="type"
                              className="contact_type"
                              label="Tipo"
                              options={contactsTypeOptions}
                              onChange={e => handleChangeContactType(e, index)}
                              placeholder="Selecione um tipo"
                            />
                            {contact.type === 1 && (
                              <Input
                                name="content"
                                className="content"
                                label="E-mail"
                                type="email"
                              />
                            )}
                            {contact.type === 2 && (
                              <InputMask
                                name="content"
                                className="content"
                                label="Fixo"
                                mask="(99) 9999-9999"
                              />
                            )}
                            {contact.type === 3 && (
                              <InputMask
                                name="content"
                                className="content"
                                label="Celular"
                                mask="(99) 99999-9999"
                              />
                            )}
                          </section>
                        </Scope>
                      </Contact>
                    ))}
                </ContactsList>
              )}

              {selectedDocumentType.value === 2 && (
                <RelatedList>
                  <header>
                    <h4>PESSOAS RELACIONADAS</h4>
                    <button type="button" onClick={handleAddNewRelated}>
                      <FaPlus size={10} />
                    </button>
                  </header>

                  {requiredRelatedPeopleErrorMessage && (
                    <span>{requiredRelatedPeopleErrorMessage}</span>
                  )}

                  {client.related &&
                    client.related.length > 0 &&
                    client.related.map((related, index) => (
                      <Related key={related.id}>
                        <nav>
                          <button
                            type="button"
                            onClick={() =>
                              confirmRemove(handleRemoveRelated, index)
                            }
                          >
                            <FaMinus size={10} />
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              handleToggleEditRelatedPeopleModal(
                                related.related_relationship_id,
                                index,
                                related.id
                              )
                            }
                          >
                            <FaPen size={10} />
                          </button>
                        </nav>
                        <aside>
                          <Scope path={`related[${index}]`}>
                            <section>
                              <Input
                                name="id"
                                type="text"
                                className="hide"
                                readOnly
                              />
                              <Input
                                name="related_relationship_id"
                                type="text"
                                className="hide"
                                readOnly
                              />
                              <Input
                                name="old_related_relationship_id"
                                type="text"
                                className="hide"
                                readOnly
                              />
                              <InputSelect
                                name="document"
                                className="related_document"
                                label="CPF"
                                mask="999.999.999-99"
                                onChangeInput={e =>
                                  handleChangeRelatedPeopleByCpf(
                                    e.target.value,
                                    index,
                                    related.id
                                  )
                                }
                                onChangeSelect={e =>
                                  handleChangeRelatedPeopleByCpfWithOptions(
                                    e,
                                    index,
                                    related.id
                                  )
                                }
                                options={related.options}
                              />
                              <Input
                                name="related_name"
                                type="text"
                                className="related_name"
                                label="Nome"
                                readOnly
                              />
                              <Input
                                name="treatment_name"
                                className="treatment_name"
                                label="Tratamento"
                                readOnly
                              />
                              <DatePicker
                                name="birthday"
                                className="related_birthday"
                                label="Nascimento"
                                readOnly
                              />
                              <Input
                                name="email"
                                className="related_email"
                                label="E-mail"
                                type="email"
                                readOnly
                              />
                              <InputMask
                                name="contact"
                                className="related_contact"
                                label="Telefone"
                                mask="(99) 99999-9999"
                                readOnly
                              />
                            </section>
                            <section className="checkbox">
                              <Checkbox
                                id={`dpt_labour${index}`}
                                name="dpt_labour"
                                className="dpt_labour"
                                label="Trabalhista"
                              />
                              <Checkbox
                                id={`dpt_tax${index}`}
                                name="dpt_tax"
                                className="dpt_tax"
                                label="Tributário"
                              />
                              <Checkbox
                                id={`dpt_accounting${index}`}
                                name="dpt_accounting"
                                className="dpt_accounting"
                                label="Contábil"
                              />
                              <Checkbox
                                id={`dpt_financial${index}`}
                                name="dpt_financial"
                                className="dpt_financial"
                                label="Financeiro"
                              />
                              <Checkbox
                                id={`dpt_admin${index}`}
                                name="dpt_admin"
                                className="dpt_admin"
                                label="Administração"
                              />
                              <Checkbox
                                id={`responsible${index}`}
                                name="responsible"
                                className="responsible"
                                label="Sócio responsável"
                              />
                            </section>
                          </Scope>
                        </aside>
                      </Related>
                    ))}
                </RelatedList>
              )}

              {((id &&
                currentClientType !== 0 &&
                selectedClientType.value === 0) ||
                (!id && selectedClientType.value === 0) ||
                clientTypeStante) && (
                <TributaryProfile>
                  <h4>PERFIL TRIBUTÁRIO</h4>
                  <Scope path="tributary_profile">
                    <section>
                      <Select
                        name="contract_type"
                        className="contract_type"
                        label="Tipo de Contrato"
                        placeholder="Selecione um tipo"
                        options={contractTypeOptions}
                      />
                      <Select
                        name="taxation_type"
                        className="taxation_type"
                        label="Tributação"
                        placeholder="Selecione um tipo"
                        options={taxationTypeOptions}
                      />
                      {selectedStartDay ? (
                        <DatePicker
                          name="start_period"
                          className="start_period"
                          label="Início"
                          selected={startOfMonth(selectedStartDay)}
                          readOnly
                        />
                      ) : (
                        <DatePicker
                          name="start_period"
                          className="start_period"
                          label="Início"
                          readOnly
                        />
                      )}
                    </section>
                    <section>
                      <Select
                        name="labour"
                        className="labour"
                        label="Responsável Trabalhista"
                        placeholder="Selecione um responsável"
                        options={usersOptions}
                      />
                      <Select
                        name="tax"
                        className="tax"
                        label="Responsável Tributário"
                        placeholder="Selecione um responsável"
                        options={usersOptions}
                      />
                      <Select
                        name="accounting"
                        className="accounting"
                        label="Responsável Contábil"
                        placeholder="Selecione um responsável"
                        options={usersOptions}
                      />
                    </section>
                  </Scope>
                </TributaryProfile>
              )}

              <TagsList>
                <header>
                  <h4>TAGS</h4>
                  <button type="button" onClick={handleAddNewTag}>
                    <FaPlus size={10} />
                  </button>
                </header>

                {requiredTagsErrorMessage && (
                  <span>{requiredTagsErrorMessage}</span>
                )}

                {client.tags &&
                  client.tags.length > 0 &&
                  client.tags.map((tag, index) => (
                    <Tag key={tag.id}>
                      <button
                        type="button"
                        onClick={() => confirmRemove(handleRemoveTag, index)}
                      >
                        <FaMinus size={10} />
                      </button>
                      <Scope path={`tags[${index}]`}>
                        <section>
                          <Input
                            name="id"
                            type="text"
                            className="hide"
                            readOnly
                          />
                          <Select
                            name="tag"
                            className="tag"
                            label="Tag"
                            options={tagsOptions}
                            placeholder="Selecione uma opção"
                          />
                        </section>
                      </Scope>
                    </Tag>
                  ))}
              </TagsList>
            </FormContainer>
          </Content>
        )}
      </Container>

      {(saveLoading || searchCpfLoading || cepLoading) && <Loading />}
      {showAddOptionModal && (
        <AddOptionModal
          handleAddNewOptionModal={() => handleAddNewOptionModal(null)}
          handleAddNewOption={handleAddNewOption}
        />
      )}
      {toggleRelatedPeopleModal && (
        <RelatedPeopleModal
          isOpen={toggleRelatedPeopleModal}
          setIsOpen={() => setToggleRelatedPeopleModal(false)}
          id={relatedRelationshipPeopleId}
          relatedIndex={relatedPeopleIndex}
          relatedId={relatedPeopleId}
          relatedDocument={relatedPeopleDocument}
          updateRelatedPeople={updateRelatedPeople}
        />
      )}
      {createProcessModalIsOpen && (
        <ConfirmCreateProcess
          onClick={handleNewProcessInfo}
          setIsOpen={() => setCreateProcessModalIsOpen(false)}
          submitForm={() => formRef.current.submitForm()}
          companyUsers={companyUsers}
          modelsOptions={processModelsOptions}
        />
      )}
    </>
  );
};

export default Form;

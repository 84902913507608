import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { useWindowSize } from '@react-hook/window-size';

import './styles.css';

const Modal = ({ children, isOpen, setIsOpen, widthProp, heightProp }) => {
  const [modalStatus, setModalStatus] = useState(isOpen);
  const [modalWidth, setModalWidth] = useState(null);
  const [modalHeight, setModalHeight] = useState(null);

  const [width, height] = useWindowSize();

  useEffect(() => {
    setModalStatus(isOpen);
    if (widthProp) {
      setModalWidth(widthProp);
    } else {
      setModalWidth(width);
    }
    if (heightProp) {
      setModalHeight(heightProp);
    } else {
      setModalHeight(height);
    }
  }, [isOpen, widthProp, width, heightProp, height]);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={false}
      isOpen={modalStatus}
      onRequestClose={setIsOpen}
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      style={{
        content: {
          top: 'calc(50% + 20px)',
          left: 'calc(50% + 25px)',
          background: '#fafafa',
          border: 'none',
          overflow: 'hidden',
          width: `${modalWidth - 70}px`,
          height: `${modalHeight - 59}px`,
          padding: '0px',
          borderRadius: '0px',
          transform: 'translate(-50%,-50%)',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.65)',
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;

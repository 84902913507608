import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;

  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  h2 {
    font-size: 1.5rem;
    color: #01579b;
    margin-left: 8px;
  }

  .close {
    position: relative;
    left: 110px;
    cursor: pointer;

    transition: opacity 0.2s;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Content = styled.div`
  background: #f0f0f0;
  padding: 16px 24px;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: row;
  border-radius: 4px;

  img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }

  button {
    margin-left: 4px;
    background: #01579b;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    padding: 6px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.05, '#01579B')};
    }
  }

  section {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  section:first-child {
    margin: 0;
  }

  section:nth-child(2) {
    min-width: 250px;
    max-width: 450px;
    border-left: 1px solid #bbb;
  }
`;

export const Message = styled.div`
  margin-top: 24px;
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 20px;
    display: block;
  }

  strong {
    display: block;
    margin-top: 24px;
    font-size: 46px;
    color: #01579b;
  }

  h3 {
    margin-top: 12px;
    color: #01579b;
  }

  @media (max-height: 820px) {
    span {
      font-size: 16px;
    }

    strong {
      font-size: 32px;
    }
  }

  @media (max-height: 660px) {
    span {
      font-size: 14px;
    }

    strong {
      font-size: 28px;
    }
  }
`;

export const NotShowAgain = styled.div`
  margin-top: 16px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  label {
    margin-left: 4px;
    font-size: 14px;
    color: #7d7d7d;

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-height: 820px) {
    label {
      font-size: 10px;
    }
  }
`;

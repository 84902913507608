import React, { createContext, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import jwt from 'jsonwebtoken';
import { format, parse } from 'date-fns';
import base64 from 'base-64';

import api from '~/services/api';
import formatContact from '~/util/formatContact';
import history from '~/services/history';

const ProcessAuthContext = createContext({});

const ProcessAuthProvider = ({ children }) => {
  const [company, setCompany] = useState(null);
  const [user, setUser] = useState({});
  const [processModel, setProcessModel] = useState({});
  const [clientsRelateds, setClientsRelateds] = useState([]);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Diretiva:process:token');
    localStorage.removeItem('@Diretiva:process:cpf');
    localStorage.removeItem('@Diretiva:process:birthday');
    localStorage.removeItem('@Diretiva:process:clientId');

    api.defaults.headers.processauthorization = null;

    setUser({});

    history.push(`/process-portal/signin`);
  }, []);

  const handleChangeClient = async client_id => {
    if (!client_id) return;

    if (client_id === -1) {
      setUser(oldUser => ({ ...oldUser, client_id }));
      localStorage.setItem(
        '@Diretiva:process:clientId',
        base64.encode(client_id)
      );

      return;
    }

    const response = await api.get(`/process-portal/client/${client_id}`);

    const { name } = response.data;

    setUser(oldUser => ({ ...oldUser, client_id, clientName: name }));
    localStorage.setItem(
      '@Diretiva:process:clientId',
      base64.encode(client_id)
    );
  };

  const loadCompanyAndModel = useCallback(async () => {
    try {
      const token = localStorage.getItem('@Diretiva:process:token');
      const cpf = localStorage.getItem('@Diretiva:process:cpf');
      const birthday = localStorage.getItem('@Diretiva:process:birthday');
      const companyIdStorage = localStorage.getItem(
        '@Diretiva:process:companyId'
      );
      const modelIdStorage = localStorage.getItem('@Diretiva:process:modelId');
      const clientIdStorage = localStorage.getItem(
        '@Diretiva:process:clientId'
      );

      const formattedCpf = cpf ? cpf.replace(/[^0-9]+/g, '') : null;
      const formattedBirthday = birthday
        ? format(parse(birthday, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
        : null;

      if (!companyIdStorage || !modelIdStorage) {
        throw new Error('Dados não encontrados');
      }

      api.defaults.headers.processauthorization = token
        ? `Bearer ${token}`
        : null;

      const decodeCompanyId = base64.decode(companyIdStorage);
      const decodeModelId = base64.decode(modelIdStorage);
      const decodeClientId = clientIdStorage
        ? Number(base64.decode(clientIdStorage))
        : null;

      const companyResponse = await api.get(
        `/process-portal/company/${decodeCompanyId}`
      );
      const companyData = companyResponse.data;

      const modelResponse = await api.get(
        `/process-portal/model/${decodeModelId}`,
        {
          params: {
            company_id: decodeCompanyId,
          },
        }
      );
      const modelData = modelResponse.data;

      if (!companyData) {
        throw new Error('Contador não encontrado');
      }
      if (!modelData) {
        throw new Error('Modelo não encontrado');
      }

      if (companyData.phone) {
        companyData.phone =
          companyData.phone > 9999999999
            ? formatContact(3, companyData.phone)
            : formatContact(2, companyData.phone);
      } else {
        companyData.phone = '';
      }
      setCompany(companyData);
      setProcessModel(modelData);

      if (formattedCpf && formattedBirthday && token) {
        const relatedResponse = await api.get('/process-portal/related', {
          params: {
            companyId: decodeCompanyId,
            cpf: formattedCpf,
            birthday: formattedBirthday,
          },
        });

        const related = relatedResponse.data;

        if (related) {
          const { id, old_id, name, relations } = related;

          let clients;
          if (processModel.process_type > 1 && processModel.model_type === 0) {
            clients = relations
              .filter(relation => relation.client.document === '00000000000000')
              .filter(
                relation => relation.client.status && relation.client.active
              )
              .map(relation => relation.relationship_id);

            if (clients.length === 0) {
              clients = [-1];
            }
          } else {
            clients = relations
              .filter(
                relation => relation.client.status && relation.client.active
              )
              .map(relation => relation.relationship_id);

            if (clients.length === 0) {
              signOut();
            }
          }

          setUser({
            id,
            old_id,
            name,
            cpf,
            birthday,
            client_id:
              clients.length === 1 &&
              !(
                processModel.model_type === 0 && processModel.process_type === 2
              )
                ? clients[0]
                : decodeClientId,
          });
          handleChangeClient(
            clients.length === 1 &&
              !(
                processModel.model_type === 0 && processModel.process_type === 2
              )
              ? clients[0]
              : decodeClientId
          );
          setClientsRelateds(clients);
        } else if (modelData.process_type > 0 && modelData.model_type === 0) {
          setUser({ cpf, birthday });
          setClientsRelateds([]);
        } else {
          signOut();
        }
      }
    } catch (err) {
      toast.error('Houve uma falhar, recarregue a página.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setCompany({});
      setProcessModel({});
    }
  }, [signOut, processModel]);

  const signIn = useCallback(
    async ({ cpf, birthday }) => {
      if (!cpf || !birthday || !company) return {};

      const formattedCpf = cpf.replace(/[^0-9]+/g, '');
      const formattedBirthday = format(
        parse(birthday, 'dd/MM/yyyy', new Date()),
        'yyyy-MM-dd'
      );

      const response = await api.post('/process-portal/signin', {
        cpf: formattedCpf,
        birthday: formattedBirthday,
        companyId: company.id,
      });

      const { related, token } = response.data;
      api.defaults.headers.processauthorization = `Bearer ${token}`;

      let userData = {};
      if (related) {
        const { id, old_id, name, relations } = related;

        let clients;
        if (processModel.process_type > 0 && processModel.model_type === 0) {
          clients = relations
            .filter(relation => relation.client.document === '00000000000000')
            .filter(
              relation => relation.client.status && relation.client.active
            )
            .map(relation => relation.relationship_id);

          if (clients.length === 0) {
            clients = [-1];
          }
        } else {
          clients = relations
            .filter(relation => relation.client !== null)
            .filter(
              relation => relation.client.status && relation.client.active
            )
            .map(relation => relation.relationship_id);

          if (clients.length === 0) {
            return {};
          }
        }

        userData = {
          id,
          old_id,
          name,
          cpf,
          birthday,
          client_id:
            clients.length === 1 &&
            !(processModel.model_type === 0 && processModel.process_type === 2)
              ? clients[0]
              : null,
        };
        setUser(userData);
        handleChangeClient(
          clients.length === 1 &&
            !(processModel.model_type === 0 && processModel.process_type === 2)
            ? clients[0]
            : null
        );
        setClientsRelateds(clients);
      } else if (
        processModel.process_type > 0 &&
        processModel.model_type === 0
      ) {
        userData = { cpf, birthday };
        setUser(userData);
        setClientsRelateds([]);
      } else {
        return {};
      }

      localStorage.setItem('@Diretiva:process:token', token);
      localStorage.setItem('@Diretiva:process:cpf', cpf);
      localStorage.setItem('@Diretiva:process:birthday', birthday);

      return userData;
    },
    [company, processModel]
  );

  const isLogged = useCallback(() => {
    const token = localStorage.getItem('@Diretiva:process:token');

    if (token) {
      const { exp } = jwt.decode(token);

      const currentTime = new Date().getTime() / 1000;

      if (currentTime < exp) {
        return true;
      }

      localStorage.removeItem('@Diretiva:process:token');
      localStorage.removeItem('@Diretiva:process:cpf');
      localStorage.removeItem('@Diretiva:process:birthday');
      localStorage.removeItem('@Diretiva:process:clientId');
      return false;
    }

    return false;
  }, []);

  return (
    <ProcessAuthContext.Provider
      value={{
        loadCompanyAndModel,
        signIn,
        signOut,
        isLogged,
        company,
        user,
        processModel,
        clientsRelateds,
        handleChangeClient,
      }}
    >
      {children}
    </ProcessAuthContext.Provider>
  );
};

export { ProcessAuthProvider, ProcessAuthContext };

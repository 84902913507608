import React, { useRef, useState } from 'react';
import { FaExclamationTriangle, FaTimes } from 'react-icons/fa';

import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  Container,
  FormContainer,
  Header,
  Content,
  Left,
  Right,
  Options,
} from './styles';
import { Checkbox, Input, Select, TextArea } from '~/components/Form';
import ClearBackground from '~/components/ClearBackground';
import { ButtonSpinner } from '~/components/Spinner';
import api from '~/services/api';

const ConfirmMarkDocument = ({
  onClick,
  onClose,
  conclude,
  phase,
  companyUsers,
  userId,
  permissionRequired,
}) => {
  const [documentReleased, setDocumentReleased] = useState(false);
  const [loading, setLoading] = useState(false);

  const adminsOptions = companyUsers
    .filter(item => item.level >= 6)
    .map(item => ({
      label: item.short_name,
      value: item.user_id,
    }));

  const formRef = useRef(null);

  const submitForm = async data => {
    try {
      setLoading(true);

      if (permissionRequired) {
        const schema = Yup.object().shape({
          released: Yup.bool(),
          admin_id: Yup.number().typeError('O administrador é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
          obs: Yup.string().required('A observação é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      } else {
        const schema = Yup.object().shape({
          released: Yup.bool(),
          obs: Yup.string().required('A observação é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
      }

      data.user_id = userId;
      data.permissionRequired = permissionRequired;

      await api.put(`/processes/phases/release/${phase.phaseId}`, data);

      setLoading(false);
      onClick(phase.indexProcess, phase.indexPhase, 4);
      onClose();
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        });

        formRef.current.setErrors(errorMessages);
      } else {
        toast.error(err.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };
  return (
    <>
      <ClearBackground />
      <Container>
        <Content>
          <Left>
            <FaExclamationTriangle size={38} color="#01579b" />
          </Left>
          <Right>
            <Header>
              <FaTimes onClick={onClose} size={14} color="#e53935" />
              <strong>Atenção</strong>
            </Header>

            <span style={{ color: '#545454' }}>
              <strong>Como deseja marcar </strong> <br />
              <strong>{phase.phaseName}</strong> <br />
              <span> da empresa </span> <strong>{phase.clientName}</strong>{' '}
              <br />
            </span>

            <Options>
              <section>
                {phase.documentRequired ? (
                  <button
                    type="button"
                    onClick={() => {
                      conclude(phase.indexProcess, phase.indexPhase);
                      onClose();
                    }}
                  >
                    Realizado
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      onClick(phase.indexProcess, phase.indexPhase, 3);
                      onClose();
                    }}
                  >
                    Realizado
                  </button>
                )}

                <button
                  type="button"
                  onClick={() => {
                    onClick(phase.indexProcess, phase.indexPhase, 2);
                    onClose();
                  }}
                >
                  Pendente
                </button>
              </section>
              <section>
                <button
                  type="button"
                  onClick={() => {
                    onClick(phase.indexProcess, phase.indexPhase, 0);
                    onClose();
                  }}
                >
                  Não realizado
                </button>
                <button
                  type="button"
                  onClick={() => {
                    onClick(phase.indexProcess, phase.indexPhase, 1);
                    onClose();
                  }}
                >
                  Em andamento
                </button>

                {!phase.phaseRequired && (
                  <button
                    type="button"
                    onClick={() => {
                      onClick(phase.indexProcess, phase.indexPhase, 4);
                      onClose();
                    }}
                  >
                    Desobrigado
                  </button>
                )}
              </section>
            </Options>

            {phase.phaseRequired && (
              <FormContainer ref={formRef} onSubmit={submitForm}>
                <Checkbox
                  id="released"
                  name="released"
                  className="checkbox"
                  label="Desobrigado"
                  onChange={e => setDocumentReleased(e.target.checked)}
                />

                {documentReleased && (
                  <>
                    {permissionRequired && (
                      <>
                        <strong>Permissão para desobrigar</strong>
                        <Select
                          name="admin_id"
                          options={adminsOptions}
                          className="select"
                          placeholder="Selecione um usuário"
                          label="Administrador"
                        />
                        <Input
                          name="password"
                          className="password"
                          label="Senha"
                          type="password"
                        />
                      </>
                    )}

                    <TextArea name="obs" className="obs" label="Observação" />

                    <button type="submit" disabled={loading}>
                      {loading ? <ButtonSpinner /> : 'Desobrigar'}
                    </button>
                  </>
                )}
              </FormContainer>
            )}
          </Right>
        </Content>
      </Container>
    </>
  );
};

export default ConfirmMarkDocument;

import styled from 'styled-components';

export const Container = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 10;
`;

export const Content = styled.div`
  max-width: 400px;
  background: #fafafa;
  padding: 25px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  input {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
    margin: 16px 0 16px 0;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: 8px;
      border-radius: 4px;
      color: ${({ theme }) => theme.colors.white};
      transition: background 0.2s;
      padding-left: 20px;
      padding-right: 20px;

      &:nth-child(1) {
        background: ${({ theme }) => theme.colors.success};
        margin-right: 16px;

        &:hover {
          background: ${({ theme }) => theme.hover.success};
        }
      }

      &:nth-child(2) {
        background: ${({ theme }) => theme.colors.error};

        &:hover {
          background: ${({ theme }) => theme.hover.error};
        }
      }
    }
  }
`;

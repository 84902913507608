import React, { useRef, useEffect } from 'react';
import ReactSelect, { components } from 'react-select';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';
import { lighten } from 'polished';

import { Container } from './styles';

const SelectWithAddNewOptionButton = ({
  name,
  options,
  label,
  className,
  isDisabled,
  handleAddNewOption,
  ...rest
}) => {
  const selectRef = useRef(null);

  const CustomMenu = ({ children, ...props }) => (
    <components.Menu {...props}>
      <button type="button" onClick={handleAddNewOption}>
        + Criar novo tipo
      </button>
      {children}
    </components.Menu>
  );

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  const customStyles = {
    container: provided => ({
      ...provided,
      display: 'inline-block',
      minHeight: '1px',
      textAlign: 'left',
      border: '0',
    }),
    control: provided => ({
      ...provided,
      borderRadius: '0',
      minHeight: '1px',
      height: '30px',
      border: 0,
      borderBottom: error ? '1px solid #e53935' : '1px solid #ddd',
      fontSize: 12,
      background: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '',
      },
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#ccc',
      '&:hover': {
        color: '#ccc',
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: '#545454',
    }),
    option: (provided, { isDisabled: disabled, isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: disabled
        ? null
        : isSelected
        ? '#01579b'
        : isFocused
        ? '#e5eef5'
        : null,
    }),
    input: provided => ({
      ...provided,
      top: '40%',
    }),
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map(option => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.setValue(value);
      },
      clearValue: ref => {
        ref.select.setValue('');
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container className={className} isDisabled={isDisabled}>
      <label>{label}</label>
      <ReactSelect
        components={{ Menu: CustomMenu }}
        styles={customStyles}
        defaultValue={
          defaultValue !== undefined &&
          options.find(option => option.value === defaultValue)
        }
        ref={selectRef}
        classNamePrefix="react-select"
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#094AB2',
            primary25: lighten(0.55, '#094AB2'),
          },
        })}
        options={options}
        onFocus={clearError}
        menuPlacement="auto"
        {...rest}
      />
      {error && <span>{error}</span>}
    </Container>
  );
};

export default SelectWithAddNewOptionButton;

SelectWithAddNewOptionButton.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType[(PropTypes.number, PropTypes.string)],
    })
  ).isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleAddNewOption: PropTypes.func.isRequired,
};

SelectWithAddNewOptionButton.defaultProps = {
  label: null,
  className: null,
  isDisabled: false,
};

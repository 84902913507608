import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import {
  FaPhoneAlt,
  FaTimes,
  FaEye,
  FaPlus,
  FaEraser,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import formatContact from '~/util/formatContact';

import { Input, Select } from '~/components/Form';
import { TableLoading } from '~/components/Table';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import ConfirmWindow from '~/components/ConfirmWindow';

import {
  Container,
  Header,
  Controls,
  Table,
  DetailsContainer,
} from '~/styles/components';
import {
  Filter,
  Content,
  RelatedPeopleInfo,
  Addresses,
  Address,
  Contacts,
  Tags,
  Audit,
} from './styles';
import PermissionComponent from '~/components/PermissionComponent';

const List = () => {
  const { company } = useAuth();

  const { state } = useLocation();

  let matchParamsId;

  if (state) {
    matchParamsId = state.id;
  } else {
    matchParamsId = null;
  }

  const filterRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [showTable, setShowTable] = useState(true);

  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState({});
  const [contactIndex, setContactIndex] = useState(0);
  const [totalContacts, setTotalContacts] = useState(0);

  const [currentPage, setCurrentPage] = useState(() => {
    const currentPageStorage = sessionStorage.getItem('@Diretiva:contact:page');

    if (currentPageStorage) {
      return Number(currentPageStorage);
    }

    return 1;
  });
  const [totalPages, setTotalPages] = useState(1);

  const [nameSearch, setNameSearch] = useState(() => {
    const nameStorage = localStorage.getItem('@Diretiva:contact:filter:name');

    if (nameStorage) {
      return nameStorage;
    }

    return '';
  });
  const [selectedSituation, setSelectedSituation] = useState(() => {
    const situationStorage = localStorage.getItem(
      '@Diretiva:contact:filter:situation'
    );

    if (situationStorage) {
      return JSON.parse(situationStorage);
    }

    return { value: '', label: 'Todos' };
  });

  const loadContacts = useCallback(async () => {
    if (company) {
      try {
        if (matchParamsId) {
          setShowTable(false);
          setLoading(true);

          const response = await api.get(`relationships/${matchParamsId}`);

          const { data } = response;

          const formattedRelatedPeople = {
            ...data,
            addresses: data?.addresses?.map(address => ({
              ...address,
              complement:
                address.complement === 'Array' ? '' : address.complement,
            })),
            contacts: data?.contacts?.map(contactItem => ({
              ...contactItem,
              content: contactItem.content
                ? formatContact(
                    contactItem.type,
                    contactItem.type !== 1
                      ? contactItem.content.replace(/[^0-9]+/g, '')
                      : contactItem.content
                  )
                : '',
            })),
            audit: data?.audit?.map(audit => ({
              ...audit,
              formatted_audit_date: format(
                parseISO(audit.created_at),
                "dd/MM/yyyy 'às' HH:mm"
              ),
            })),
          };

          setContact(formattedRelatedPeople);
        } else {
          setLoading(true);

          const response = await api.get('relationships', {
            params: {
              company_id: company.id,
              page: currentPage,
              type: 0,
              search: nameSearch,
              active: selectedSituation.value,
            },
          });

          if (response.data.docs.length > 0) {
            const formattedRelatedPeoples = response.data.docs.map(item => ({
              ...item,
              addresses: item?.addresses?.map(address => ({
                ...address,
                complement:
                  address.complement === 'Array' ? '' : address.complement,
              })),
              contacts: item?.contacts?.map(contactItem => ({
                ...contactItem,
                content: contactItem.content
                  ? formatContact(
                      contactItem.type,
                      contactItem.type !== 1
                        ? contactItem.content.replace(/[^0-9]+/g, '')
                        : contactItem.content
                    )
                  : '',
              })),
              audit: item?.audit?.map(audit => ({
                ...audit,
                formatted_audit_date: format(
                  parseISO(audit.created_at),
                  "dd/MM/yyyy 'às' HH:mm"
                ),
              })),
            }));

            setContacts(formattedRelatedPeoples);
            if (!matchParamsId) {
              setContact(formattedRelatedPeoples[0]);
            }

            setTotalPages(response.data.pages);
            setTotalContacts(response.data.total);
          } else {
            toast.warn('Nenhum contato foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }
      } catch (err) {
        toast.error('Falha ao buscar contatos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [company, currentPage, nameSearch, selectedSituation, matchParamsId]);

  useEffect(() => {
    loadContacts();
  }, [loadContacts]);

  const situationOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Ativo' },
      { value: 0, label: 'Inativo' },
    ];
  }, []);

  const handleView = useCallback(() => {
    if (matchParamsId) {
      history.push('/contact', { id: null });
    }
    if (contacts.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, contacts, matchParamsId]);

  const handlePrevItem = useCallback(() => {
    if (contactIndex !== 0) {
      setContactIndex(contactIndex - 1);
      setContact(contacts[contactIndex - 1]);
    } else {
      setContactIndex(contacts.length - 1);
      setContact(contacts[contacts.length - 1]);
    }
  }, [contactIndex, contacts]);

  const handleNextItem = useCallback(() => {
    if (contactIndex !== contacts.length - 1) {
      setContactIndex(contactIndex + 1);
      setContact(contacts[contactIndex + 1]);
    } else {
      setContactIndex(0);
      setContact(contacts[0]);
    }
  }, [contactIndex, contacts]);

  const handleDelete = useCallback(
    async id => {
      try {
        setDeleteLoading(true);

        await api.delete(`relationships/${id}`);

        handleView();

        loadContacts();

        toast.success('Contato deletado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      } catch (err) {
        toast.error('Falha ao deletar contato.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      }
    },
    [handleView, loadContacts]
  );

  const confirmDelete = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => handleDelete(contact.id)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete, contact]);

  const handleFilter = useCallback(
    ({ name, situation }) => {
      setCurrentPage(1);
      localStorage.setItem('@Diretiva:contact:page', 1);

      setNameSearch(name);
      localStorage.setItem('@Diretiva:contact:filter:name', name);

      setSelectedSituation(
        situationOptions.find(option => option.value === situation)
      );
      localStorage.setItem(
        '@Diretiva:contact:filter:situation',
        JSON.stringify(
          situationOptions.find(option => option.value === situation)
        )
      );
    },
    [situationOptions]
  );

  const handleResetFilter = useCallback(() => {
    setCurrentPage(1);
    localStorage.removeItem('@Diretiva:contact:page');

    filterRef.current.reset();

    setNameSearch('');
    localStorage.removeItem('@Diretiva:contact:filter:name');

    filterRef.current.setFieldValue('situation', {
      value: '',
      label: 'Todos',
    });
    setSelectedSituation({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:contact:filter:situation');
  }, []);

  const handleDetails = useCallback(
    (id, index) => {
      const relatedPeopleDetails = contacts.find(item => item.id === id);

      setContact(relatedPeopleDetails);
      setContactIndex(index);
      handleView();
    },
    [contacts, handleView]
  );

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
        sessionStorage.setItem('@Diretiva:contact:page', 1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
        sessionStorage.setItem('@Diretiva:contact:page', totalPages);
      } else {
        setCurrentPage(page);
        sessionStorage.setItem('@Diretiva:contact:page', page);
      }
    },
    [totalPages]
  );

  return (
    <Container>
      <Header>
        <div>
          <FaPhoneAlt />
          <h1>Contatos</h1>
        </div>

        <Link to="/">
          <FaTimes />
        </Link>
      </Header>
      <Controls>
        <button type="button" onClick={handleView}>
          <FaEye />
          <span>Visualização</span>
        </button>
        <Link to={{ pathname: '/contact/new', state: { id: null } }}>
          <FaPlus />
          <span>Novo</span>
        </Link>
        {showTable ? (
          <button type="button" onClick={handleResetFilter}>
            <FaEraser />
            <span>Limpar filtros</span>
          </button>
        ) : (
          <>
            {totalContacts !== 0 && (
              <>
                <PermissionComponent level={5}>
                  <Link
                    to={{
                      pathname: '/contact/edit',
                      state: { id: contact.id },
                    }}
                  >
                    <FaEdit />
                    <span>Editar</span>
                  </Link>
                </PermissionComponent>
                <PermissionComponent level={9}>
                  <button type="button" onClick={confirmDelete}>
                    <FaTrash />
                    <span>Excluir</span>
                  </button>
                </PermissionComponent>
                {!matchParamsId && (
                  <div>
                    <button type="button" onClick={handlePrevItem}>
                      <FaChevronLeft />
                    </button>
                    {totalContacts > 25 ? (
                      <span>{contactIndex + 1} de 25</span>
                    ) : (
                      <span>
                        {contactIndex + 1} de {totalContacts}
                      </span>
                    )}
                    <button type="button" onClick={handleNextItem}>
                      <FaChevronRight />
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Controls>
      {showTable && (
        <Filter ref={filterRef} onSubmit={handleFilter}>
          <Input
            name="name"
            className="name"
            label="Nome"
            defaultValue={nameSearch}
          />
          <Select
            label="Situação"
            name="situation"
            className="situation"
            options={situationOptions}
            defaultValue={selectedSituation}
          />

          <button type="submit">
            <FaSearch />
          </button>
        </Filter>
      )}
      {loading || !company ? (
        <TableLoading />
      ) : (
        <Content className="content">
          {showTable ? (
            <Table>
              <thead>
                <tr>
                  <th className="name-table">Nome</th>
                  <th className="address-table">Endereço</th>
                  <th className="email-table">E-mail</th>
                  <th className="phone-table">Telefone</th>
                </tr>
              </thead>
              <tbody>
                {contacts?.map((item, index) => (
                  <tr
                    key={item.id}
                    className="hover"
                    onClick={() => handleDetails(item.id, index)}
                  >
                    <td className="name-table">{item?.name}</td>
                    <td className="address-table">
                      {item?.addresses[0]?.street}
                    </td>
                    <td className="email-table">
                      {
                        item?.contacts.find(
                          contactItem => contactItem.type === 1
                        )?.content
                      }
                    </td>
                    <td className="phone-table">
                      {
                        item?.contacts.find(
                          contactItem =>
                            contactItem.type === 2 || contactItem.type === 3
                        )?.content
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <DetailsContainer>
              <RelatedPeopleInfo>
                <h4>INFORMAÇÕES BÁSICAS</h4>
                <section>
                  <div className="name">
                    <label>Nome</label>
                    <input name="name" value={contact?.name || ''} readOnly />
                  </div>
                </section>
                <section>
                  <div className="obs">
                    <label>Obs</label>
                    <input name="obs" value={contact?.obs || ''} readOnly />
                  </div>
                </section>
              </RelatedPeopleInfo>
              {contact?.addresses?.length > 0 && (
                <Addresses>
                  <h4>ENDEREÇOS</h4>
                  {contact.addresses?.map(address => (
                    <Address key={address.id}>
                      <section>
                        <div className="zipcode">
                          <label>CEP</label>
                          <input
                            name="zipcode"
                            value={address?.zipcode || ''}
                            readOnly
                          />
                        </div>
                        <div className="street">
                          <label>Endereço</label>
                          <input
                            name="street"
                            value={address?.street || ''}
                            readOnly
                          />
                        </div>
                        <div className="number">
                          <label>Nº</label>
                          <input
                            name="number"
                            value={address?.number || ''}
                            readOnly
                          />
                        </div>
                      </section>
                      <section>
                        <div className="complement">
                          <label>Complemento</label>
                          <input
                            name="complement"
                            value={address?.complement || ''}
                            readOnly
                          />
                        </div>
                        <div className="neighborhood">
                          <label>Bairro</label>
                          <input
                            name="neighborhood"
                            value={address?.neighborhood || ''}
                            readOnly
                          />
                        </div>
                        <div className="city">
                          <label>Cidade</label>
                          <input
                            name="city"
                            value={address.city || ''}
                            readOnly
                          />
                        </div>
                        <div className="state">
                          <label>Estado</label>
                          <input
                            name="state"
                            value={address.state_label || ''}
                            readOnly
                          />
                        </div>
                      </section>
                    </Address>
                  ))}
                </Addresses>
              )}
              {contact?.contacts?.length > 0 && (
                <Contacts>
                  <h4>CONTATOS</h4>
                  {contact.contacts?.map(contactItem => (
                    <section key={contactItem.id}>
                      <div className="contact_type">
                        <label>Tipo</label>
                        <input
                          name="contact_type"
                          value={contactItem.type_label || ''}
                          readOnly
                        />
                      </div>
                      <div className="content">
                        <label>{contactItem.type_label}</label>
                        <input
                          name="content"
                          value={contactItem.content || ''}
                          readOnly
                        />
                      </div>
                    </section>
                  ))}
                </Contacts>
              )}
              {contact?.tags?.length > 0 && (
                <Tags>
                  <h4>TAGS</h4>
                  {contact?.tags?.map(tag => (
                    <section key={tag.id}>
                      <div className="tag">
                        <label>Tag</label>
                        <input name="tag" value={tag?.tag || ''} readOnly />
                      </div>
                    </section>
                  ))}
                </Tags>
              )}
              {contact?.audit?.length > 0 && (
                <Audit>
                  <h4>LOGS</h4>
                  {contact?.audit?.map(audit => (
                    <section key={audit.id}>
                      <div className="date">
                        <label>Data/Hora</label>
                        <input
                          name="formatted_audit_date"
                          value={audit?.formatted_audit_date || ''}
                          readOnly
                        />
                      </div>
                      <div className="user">
                        <label>Usuário</label>
                        <input
                          name="audit_user"
                          value={audit?.user.short_name || ''}
                          readOnly
                        />
                      </div>
                      <div className="action">
                        <label>Ação</label>
                        <input
                          name="action"
                          value={audit?.action_label || ''}
                          readOnly
                        />
                      </div>
                    </section>
                  ))}
                </Audit>
              )}
            </DetailsContainer>
          )}
        </Content>
      )}
      <Pagination
        loading={loading ? 1 : 0}
        currentPage={currentPage}
        pages={totalPages}
        totalDocs={totalContacts}
        handlePage={handlePage}
      />
      {deleteLoading && <Loading />}
    </Container>
  );
};

export default List;

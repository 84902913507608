import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  tbody {
    display: block;
    max-height: 80vh; /* Ajuste para a altura desejada */
    overflow-y: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  border: 1px solid #c7c7c7;
`;

export const Header = styled.header`
  color: #44546a;
  background: rgba(230, 230, 230, 0.95);
  padding: 5px 10px;
  border-bottom: 1px solid #c7c7c7;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: 16px;
      margin-left: 10px;
    }
  }

  aside {
    display: flex;
    align-items: center;

    button {
      background: transparent;
      border: none;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  table {
    .copy {
      padding-left: 8px;

      width: 50px;
      text-align: center;
    }

    .name {
      width: calc(100% - 50px);
      max-width: 0;
    }

    button {
      background: transparent;
      width: 100%;
      height: 100%;

      transition: opacity 0.2s;
      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

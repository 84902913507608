import React, { useState } from 'react';
import {
  FaTimes,
  FaLaptop,
  FaUserFriends,
  FaBuilding,
  FaAddressCard,
} from 'react-icons/fa';

import Modal from '~/components/Modal';

import { Container, Header, Content, Type } from './styles';
import Users from '../Users';
import Companies from '../Companies';
import MigrateClient from '../MigrateClient';

const System = ({ isOpen, setIsOpen }) => {
  const [toggleUsers, setToggleUsers] = useState(false);
  const [toggleCompanies, setToggleCompanies] = useState(false);
  const [toggleMigrateClient, setToggleMigrateClient] = useState(false);

  const handleToggleUsers = () => setToggleUsers(oldToggle => !oldToggle);
  const handleToggleCompanies = () =>
    setToggleCompanies(oldToggle => !oldToggle);
  const handleToggleMigrateClient = () =>
    setToggleMigrateClient(oldToggle => !oldToggle);

  if (toggleUsers) {
    return <Users isOpen={isOpen} setIsOpen={handleToggleUsers} />;
  }

  if (toggleCompanies) {
    return <Companies isOpen={isOpen} setIsOpen={handleToggleCompanies} />;
  }

  if (toggleMigrateClient) {
    return (
      <MigrateClient isOpen={isOpen} setIsOpen={handleToggleMigrateClient} />
    );
  }

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaLaptop size={20} color="#44546a" />
            <h1>Gerenciar Sistema</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>

        <Content>
          <Type onClick={handleToggleUsers}>
            <FaUserFriends color="#44546a" />
            <span>Usuários</span>
          </Type>
          <Type onClick={handleToggleCompanies}>
            <FaBuilding color="#44546a" />
            <span>Empresas</span>
          </Type>
          <Type onClick={handleToggleMigrateClient}>
            <FaAddressCard color="#44546a" />
            <span>Migrar Clientes</span>
          </Type>
        </Content>
      </Container>
    </Modal>
  );
};

export default System;

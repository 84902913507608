import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import {
  FaCog,
  FaTimes,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaEraser,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaChartBar,
  FaThumbsUp,
  FaThumbsDown,
  FaEdit,
  FaClipboard,
  FaClock,
} from 'react-icons/fa';

import { addMonths, format, parse, parseISO, subMonths } from 'date-fns';
import { Select } from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import Pagination from '~/components/Pagination';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import { Container, Header, Controls, Filter, Content } from './styles';
import EditMainCosts from './EditMainCosts';
import EditClientCosts from './EditClientCosts';
import Loading from '~/components/Loading';
import Report from './Report';

const List = () => {
  const { company } = useAuth();

  const filterRef = useRef(null);
  const costsClientToEdit = useRef(null);

  const [contractType, setContractType] = useState({
    value: 2,
    label: 'Próprio',
  });
  const types = useMemo(() => {
    return [
      { value: 0, label: 'Todos' },
      { value: 2, label: 'Próprio' },
      { value: 1, label: 'Terceirizado' },
      { value: 3, label: 'Não pagantes' },
    ];
  }, []);

  const [loading, setLoading] = useState(true);
  const [period, setPeriod] = useState(format(new Date(), 'MM/yyyy'));

  const [toggleEditMain, setToggleEditMain] = useState(false);
  const [toggleEditClientCosts, setToggleEditClientCosts] = useState(false);

  const [costsClients, setCostsClients] = useState([]);
  const [totalCostsClients, setTotalCostsClients] = useState(0);

  const [reportLoading, setReportLoading] = useState(false);
  const [clientsForReport, setClientsForReport] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortConfig, setSortConfig] = useState('');
  const [selectedMargin, setSelectedMargin] = useState(() => {
    const marginStorage = localStorage.getItem('@Diretiva:costs:filter:margin');

    if (marginStorage) {
      return JSON.parse(marginStorage);
    }

    return {
      value: '',
      label: 'Todos',
    };
  });
  const [selectedClient, setSelectedClient] = useState({
    value: '',
    label: 'Todos',
  });
  const [clientsOptions, setClientsOptions] = useState([]);

  const [costsMain, setCostsMain] = useState(null);
  const loadCostsMain = useCallback(async () => {
    try {
      if (!company) return;

      const response = await api.get(`/costs/${company.id}`, {
        params: {
          period,
        },
      });

      if (response.data) {
        setCostsMain(response.data);
      } else {
        setCostsMain(null);
        toast.warn('Não foi gerado os custos desse período.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch {
      toast.error('Falha ao buscar compromissos.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [company, period]);

  useEffect(() => {
    async function loadClients() {
      if (!company) return;

      try {
        const response = await api.get(`/relationships`, {
          params: {
            company_id: company.id,
            selectOnly: true,
            type: 1,
            client_type: 0,
            contract_type: contractType.value,
          },
        });

        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          const options = response.data
            .filter(item => item.active)
            .map(item => ({
              value: item.id,
              label: item.name,
            }));

          options.unshift({
            value: '',
            label: 'Todos',
          });

          setClientsOptions(options);
        } else {
          toast.warn('Nenhum cliente foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch {
        toast.error('Falha ao buscar clientes.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    loadClients();
  }, [company, contractType]);

  const loadCostsClients = useCallback(async () => {
    if (!company || !costsMain) return;

    try {
      setLoading(true);

      const response = await api.get('/costs/client', {
        params: {
          company_id: company.id,
          period,
          key: sortConfig.key,
          direction: sortConfig.direction,
          margin: selectedMargin.value,
          client: selectedClient.value,
          page: currentPage,
          contract_type: contractType.value,
        },
      });

      const { docs, pages, total } = response.data;

      const formattedDocs = docs.map(item => {
        const {
          tributary_time,
          accounting_time,
          extra_time,
          quant_employee,
          margin,
          honorary,
          percentage,
        } = item;

        const {
          tributary_cost,
          accounting_cost,
          employee_cost,
          extra_cost,
        } = costsMain;

        const tributaryCost = (tributary_time / 60) * tributary_cost;
        const accountingCost = (accounting_time / 60) * accounting_cost;
        const extraCost = (extra_time / 60) * extra_cost;
        const employeeCost = quant_employee * employee_cost;
        const totalCost =
          tributaryCost + accountingCost + employeeCost + extraCost;

        const tributaryCostFormatted = `R$${tributaryCost
          .toFixed(2)
          .replace('.', ',')}`;
        const accountingCostFormatted = `R$${accountingCost
          .toFixed(2)
          .replace('.', ',')}`;
        const extraCostFormatted = `R$${extraCost
          .toFixed(2)
          .replace('.', ',')}`;
        const employeeCostFormatted = `R$${employeeCost
          .toFixed(2)
          .replace('.', ',')}`;
        const totalCostFormatted = `R$${totalCost
          .toFixed(2)
          .replace('.', ',')}`;
        const marginFormatted = `R$${margin.toFixed(2).replace('.', ',')}`;
        const honoraryFormatted = `R$${honorary.toFixed(2).replace('.', ',')}`;

        const tributaryTimeFormatted = `${
          tributary_time / 60 < 10
            ? `0${Math.floor(tributary_time / 60)}`
            : Math.floor(tributary_time / 60)
        }:${
          tributary_time % 60 < 10
            ? `0${tributary_time % 60}`
            : tributary_time % 60
        }`;

        const accountingTimeFormatted = `${
          accounting_time / 60 < 10
            ? `0${Math.floor(accounting_time / 60)}`
            : Math.floor(accounting_time / 60)
        }:${
          accounting_time % 60 < 10
            ? `0${accounting_time % 60}`
            : accounting_time % 60
        }`;

        const extraTimeFormatted = `${
          extra_time / 60 < 10
            ? `0${Math.floor(extra_time / 60)}`
            : Math.floor(extra_time / 60)
        }:${extra_time % 60 < 10 ? `0${extra_time % 60}` : extra_time % 60}`;

        const percentageFormatted =
          percentage > 0
            ? `${(parseInt(percentage * 10, 10) / 10)
                .toString()
                .replace('.', ',')}%`
            : `${0}%`;

        return {
          ...item,
          client: item.client?.nickname || '',
          tributaryCostFormatted,
          accountingCostFormatted,
          extraCostFormatted,
          employeeCostFormatted,
          totalCostFormatted,
          marginFormatted,
          honoraryFormatted,
          tributaryTimeFormatted,
          accountingTimeFormatted,
          extraTimeFormatted,
          percentageFormatted,
        };
      });

      setCostsClients(formattedDocs);
      setTotalPages(pages);
      setTotalCostsClients(total);

      setLoading(false);
    } catch (err) {
      toast.error('Falha ao buscar compromissos.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      setLoading(false);
    }
  }, [
    company,
    costsMain,
    currentPage,
    selectedMargin.value,
    selectedClient.value,
    sortConfig,
    period,
    contractType.value,
  ]);

  useEffect(() => {
    loadCostsMain();
  }, [loadCostsMain]);

  useEffect(() => {
    loadCostsClients();
  }, [loadCostsClients]);

  const marginOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 0, label: 'Negativa' },
      { value: 1, label: 'Positiva' },
    ];
  }, []);

  const handleFilter = useCallback(
    ({ margin, client, contract_type }) => {
      setCurrentPage(1);

      setContractType(types.find(obj => obj.value === contract_type));

      setSelectedMargin(marginOptions.find(option => option.value === margin));
      localStorage.setItem(
        '@Diretiva:costs:filter:margin',
        JSON.stringify(marginOptions.find(option => option.value === margin))
      );

      setSelectedClient(clientsOptions.find(option => option.value === client));
    },
    [types, marginOptions, clientsOptions]
  );

  const resetFilter = useCallback(() => {
    setCurrentPage(1);

    filterRef.current.setFieldValue('margin', {
      value: '',
      label: 'Todos',
    });
    setSelectedMargin({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:costs:filter:margin');
  }, [filterRef]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  const handleToggleEditMain = () => {
    setToggleEditMain(!toggleEditMain);
  };

  const handleToggleEditClient = index => {
    setToggleEditClientCosts(!toggleEditClientCosts);

    if (index || index === 0) {
      costsClientToEdit.current = costsClients[index];
    }
  };

  const sort = useCallback(
    key => {
      let direction = 'asc';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'asc'
      ) {
        direction = 'desc';
      }
      const element = document.getElementById(key);
      element.classList.add(direction);
      setSortConfig({ key, direction });
    },
    [sortConfig]
  );

  const handleNextPeriod = () => {
    if (period === format(new Date(), 'MM/yyyy')) return;

    const currentPeriodDate = parse(period, 'MM/yyyy', new Date());

    const nextPeriod = addMonths(currentPeriodDate, 1);

    setPeriod(format(nextPeriod, 'MM/yyyy'));
  };

  const handlePrevPeriod = () => {
    const currentPeriodDate = parse(period, 'MM/yyyy', new Date());

    const prevPeriod = subMonths(currentPeriodDate, 1);

    setPeriod(format(prevPeriod, 'MM/yyyy'));
  };

  const printReport = async () => {
    try {
      setReportLoading(true);

      const response = await api.get('/costs/report', {
        params: {
          company_id: company.id,
          period,
        },
      });

      if (response.data.length > 0) {
        const formattedClients = response.data.map(item => ({
          name: item.client.name,
          quant_employee: item.quant_employee,
          employees: item.client.employees.map(employeeItem => ({
            name: employeeItem.name,
            situation: employeeItem.situation === 0 ? 'Admissão' : 'Recisão',
            admission_date: employeeItem.admission_date
              ? format(parseISO(employeeItem.admission_date), 'dd/MM/yyyy')
              : '**/**/****',
          })),
        }));

        setClientsForReport(formattedClients);
      } else {
        toast.warn('Nenhum cliente para gerar relatório encontrado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (err) {
      toast.error('Falha ao gerar relatório.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setReportLoading(false);
    }
  };

  useEffect(() => {
    if (clientsForReport.length === 0) return;

    window.print();
  }, [clientsForReport]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaChartBar size={20} color="#44546a" />
            <h1>Custos</h1>
          </div>
          <aside>
            {period === format(new Date(), 'MM/yyyy') && (
              <button type="button" onClick={handleToggleEditMain}>
                <FaCog size={20} color="#44546a" />
              </button>
            )}
            <Link to="/">
              <FaTimes size={20} color="#44546a" />
            </Link>
          </aside>
        </Header>

        <Controls>
          <div>
            <button type="button" onClick={resetFilter}>
              <FaEraser />
              <span>Limpar filtros</span>
            </button>
            <button type="button" onClick={printReport}>
              <FaClipboard />
              <span>Empregados</span>
            </button>
          </div>
          <div>
            <button type="button" onClick={handlePrevPeriod}>
              <FaChevronLeft />
            </button>

            <span>{period}</span>

            <button type="button" onClick={handleNextPeriod}>
              <FaChevronRight />
            </button>
          </div>
        </Controls>

        <Filter ref={filterRef} onSubmit={handleFilter}>
          <Select
            label="Margem"
            name="margin"
            className="margin"
            options={marginOptions}
            defaultValue={selectedMargin}
          />

          <Select
            label="Cliente"
            name="client"
            className="margin"
            options={clientsOptions}
            defaultValue={selectedClient}
          />

          <Select
            label="Tipo de Contrato"
            name="contract_type"
            className="margin"
            options={types}
            defaultValue={contractType}
          />

          <button type="submit">
            <FaSearch />
          </button>
        </Filter>

        {loading ? (
          <TableLoading />
        ) : (
          <Content className="content">
            <TableContainer>
              <thead>
                <tr>
                  <th className="margin-thumb" />

                  {period === format(new Date(), 'MM/yyyy') && (
                    <th className="edit" />
                  )}

                  <th
                    id="client"
                    className="client sort"
                    onClick={() => sort('client')}
                  >
                    <div>
                      Empresa
                      {sortConfig.key !== 'client' && <FaSort />}
                      {sortConfig.key === 'client' &&
                        sortConfig.direction === 'asc' && <FaSortUp />}
                      {sortConfig.key === 'client' &&
                        sortConfig.direction === 'desc' && <FaSortDown />}
                    </div>
                  </th>
                  <th className="price">Honorário</th>
                  <th className="quant">Limite Trab</th>
                  <th className="quant">Quant Trab</th>
                  <th className="employee-cost cost">$ Trab</th>
                  <th className="time">
                    <div>
                      <FaClock /> Trib
                    </div>
                  </th>
                  <th className="trib-cost cost">$ Tributário</th>
                  <th className="time">
                    <div>
                      <FaClock /> Cont
                    </div>
                  </th>
                  <th className="acc-cost cost">$ Contábil</th>
                  <th className="time">
                    <div>
                      <FaClock /> Extra
                    </div>
                  </th>
                  <th className="extra-cost cost">$ Extra</th>
                  <th className="price">$ Total</th>
                  <th
                    id="margin"
                    className="price sort"
                    onClick={() => sort('margin')}
                  >
                    <div>
                      Margem
                      {sortConfig.key !== 'margin' && <FaSort />}
                      {sortConfig.key === 'margin' &&
                        sortConfig.direction === 'asc' && <FaSortUp />}
                      {sortConfig.key === 'margin' &&
                        sortConfig.direction === 'desc' && <FaSortDown />}
                    </div>
                  </th>
                  <th
                    id="percentage"
                    className="percentage sort"
                    onClick={() => sort('percentage')}
                  >
                    <div>
                      %{sortConfig.key !== 'percentage' && <FaSort />}
                      {sortConfig.key === 'percentage' &&
                        sortConfig.direction === 'asc' && <FaSortUp />}
                      {sortConfig.key === 'percentage' &&
                        sortConfig.direction === 'desc' && <FaSortDown />}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {costsClients.map((item, index) => (
                  <tr key={item.id} className="hover">
                    <td className="margin-thumb">
                      {item.margin > 0 ? (
                        <FaThumbsUp size={12} color="#006229" />
                      ) : (
                        <FaThumbsDown size={12} color="#E53935" />
                      )}
                    </td>
                    {period === format(new Date(), 'MM/yyyy') && (
                      <td className="edit">
                        <FaEdit
                          size={12}
                          onClick={() => handleToggleEditClient(index)}
                        />
                      </td>
                    )}
                    <td className="client">{item.client}</td>
                    <td className="price">{item.honoraryFormatted}</td>
                    <td className="quant">{item.limit_employee}</td>
                    <td className="quant">
                      <span
                        style={{
                          color:
                            item.limit_employee >= item.quant_employee
                              ? '#006229'
                              : '#E53935',
                        }}
                      >
                        {item.quant_employee}
                      </span>
                    </td>
                    <td className="employee-cost cost">
                      {item.employeeCostFormatted}
                    </td>
                    <td className="time">{item.tributaryTimeFormatted}</td>
                    <td className="trib-cost cost">
                      {item.tributaryCostFormatted}
                    </td>
                    <td className="time">{item.accountingTimeFormatted}</td>
                    <td className="acc-cost cost">
                      {item.accountingCostFormatted}
                    </td>
                    <td className="time">{item.extraTimeFormatted}</td>
                    <td className="extra-cost cost">
                      {item.extraCostFormatted}
                    </td>
                    <td className="price">{item.totalCostFormatted}</td>
                    <td className="price">
                      <span
                        style={{
                          color: item.margin > 0 ? '#006229' : '#E53935',
                        }}
                      >
                        {item.marginFormatted}
                      </span>
                    </td>
                    <td className="percentage">{item.percentageFormatted}</td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          </Content>
        )}
        <Pagination
          loading={loading ? 1 : 0}
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalCostsClients}
          handlePage={handlePage}
        />
      </Container>

      {toggleEditMain && (
        <EditMainCosts
          onClose={handleToggleEditMain}
          costsMain={costsMain}
          setCostsMain={setCostsMain}
        />
      )}
      {toggleEditClientCosts && (
        <EditClientCosts
          onClose={handleToggleEditClient}
          costsClient={costsClientToEdit.current}
          loadCostsClient={loadCostsClients}
        />
      )}

      <Report clients={clientsForReport} />

      {reportLoading && <Loading />}
    </>
  );
};

export default List;

import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const ClearBackground = ({ onClick }) => {
  return <Container onClick={onClick} />;
};

export default ClearBackground;

ClearBackground.defaultProps = {
  onClick: null,
};

ClearBackground.propTypes = {
  onClick: PropTypes.func,
};

import React, { useEffect, useState, useCallback } from 'react';

import { FaFileAlt, FaTimes } from 'react-icons/fa';
import { parseISO, format } from 'date-fns';
import { toast } from 'react-toastify';

import Modal from '~/components/Modal';
import Pagination from '~/components/Pagination';
import { TableContainer, TableLoading } from '~/components/Table';

import api from '~/services/api';

import { Header, Container, Content } from './styles';

const DocumentsModal = ({ isOpen, setIsOpen, filter }) => {
  const { company_id, client_id, user_id, situation, model_id } = filter;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get('/processes/documents', {
          params: {
            company_id,
            client_id,
            user_id,
            situation,
            model_id,
            page: currentPage,
          },
        });

        const { docs, pages, total } = response.data;

        if (total > 0) {
          const formattedData = docs.map(item => ({
            ...item,
            deadline: item.expiration_date
              ? format(parseISO(item.expiration_date), 'dd/MM/yyyy')
              : '',
            client: item.process?.client_nick || '',
          }));

          setTotalPages(pages);
          setTotalDocs(total);
          setDocuments(formattedData);
        } else {
          setTotalPages(1);
          setTotalDocs(0);
          setDocuments([]);
        }
      } catch {
        toast.error('Falha ao buscar os documentos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [company_id, client_id, user_id, situation, currentPage, model_id]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      widthProp={870}
      heightProp={650}
    >
      <Container>
        <Header>
          <div>
            <FaFileAlt size={20} color="#44546a" />
            <h1>Documentos</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>

        <Content className="content">
          {loading ? (
            <TableLoading />
          ) : (
            <TableContainer>
              <thead>
                <tr>
                  <th className="client">Cliente</th>
                  <th className="document">Documento</th>
                  <th className="deadline">Vencimento</th>
                </tr>
              </thead>
              <tbody>
                {documents.map(item => (
                  <tr key={item.id} className="hover">
                    <td className="client">{item.client}</td>
                    <td className="document">{item.document}</td>
                    <td className="deadline">{item.deadline}</td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          )}
        </Content>
        <Pagination
          loading={loading ? 1 : 0}
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalDocs}
          handlePage={handlePage}
        />
      </Container>
    </Modal>
  );
};

export default DocumentsModal;

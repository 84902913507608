import React, { useCallback, useState } from 'react';
import { FaTimes, FaCopy, FaLink } from 'react-icons/fa';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

import ClearBackground from '~/components/ClearBackground';

import { Badge, Container, Menu, Title } from './styles';

const ProcessPortalLinks = ({ models }) => {
  const [visible, setVisible] = useState(false);

  const handleToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const copyLink = link => {
    copy(link);

    toast.success('Link copiado.', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  return (
    <>
      <Container visible={visible}>
        <Badge onClick={handleToggleVisible}>
          <FaLink size={20} color="#44546a" />
        </Badge>
        <Menu visible={visible} className="content">
          <Title>
            <div>
              <FaLink size={18} color="#01579b" />
              <h3>Links</h3>
            </div>
            <FaTimes size={18} color="#ee4256" onClick={handleToggleVisible} />
          </Title>

          {models.map(item => (
            <button
              type="button"
              onClick={() => copyLink(item.portalLink)}
              key={item.id}
            >
              <FaCopy color="#44546A" />
              {item.title}
            </button>
          ))}
        </Menu>
      </Container>
      {visible && <ClearBackground onClick={handleToggleVisible} />}
    </>
  );
};

export default ProcessPortalLinks;

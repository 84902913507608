import React, { createContext, useCallback, useState } from 'react';
import jwt from 'jsonwebtoken';
import base64 from 'base-64';
import api from '~/services/api';
import history from '~/services/history';

const PortalAuthContext = createContext({});

const PortalAuthProvider = ({ children }) => {
  const [companyId, setCompanyId] = useState(
    localStorage.getItem('@Diretiva:portal:companyId')
      ? base64.decode(localStorage.getItem('@Diretiva:portal:companyId'))
      : null
  );
  const [company, setCompany] = useState(null);
  const [user, setUser] = useState({});
  const [folders, setFolders] = useState([]);

  api.defaults.headers.portalauthorization = `Bearer ${localStorage.getItem(
    '@Diretiva:portal:token'
  )}`;

  const changeCompanyId = useCallback(() => {
    const id = localStorage.getItem('@Diretiva:portal:companyId');

    if (!id) {
      history.push('/portal/error');
      return;
    }

    setCompanyId(base64.decode(id));
  }, []);

  const loadCompany = useCallback(async () => {
    if (!companyId) {
      changeCompanyId();
      return;
    }

    try {
      const response = await api.get(`/portal/company/${companyId}`);

      if (response.data) {
        setCompany(response.data);
      } else {
        history.push('/portal/error');
      }
    } catch {
      history.push('/portal/error');
    }
  }, [companyId, changeCompanyId]);

  const loadUser = useCallback(async () => {
    try {
      const response = await api.get(`/portal/user`);

      if (response.data) {
        setUser(response.data);
      } else {
        history.push('/portal/error');
      }
    } catch {
      history.push('/portal/error');
    }
  }, []);

  const signIn = useCallback(
    async ({ user: userCpf, password }) => {
      api.defaults.headers.portalauthorization = null;
      localStorage.removeItem('@Diretiva:portal:token');

      const response = await api.post(`/portal/signin`, {
        companyId,
        cpf: userCpf,
        password,
      });

      const { user: dataUser, token } = response.data;

      api.defaults.headers.portalauthorization = `Bearer ${token}`;
      localStorage.setItem('@Diretiva:portal:token', token);
      setUser(dataUser);
    },
    [companyId]
  );

  const signOut = useCallback(() => {
    api.defaults.headers.portalauthorization = null;
    localStorage.removeItem('@Diretiva:portal:token');
    setUser({});

    history.push('/portal/signin');
  }, []);

  const isLogged = useCallback(() => {
    const token = localStorage.getItem('@Diretiva:portal:token');

    if (token) {
      const { exp } = jwt.decode(token);

      const currentTime = new Date().getTime() / 1000;

      if (currentTime < exp) {
        return true;
      }

      signOut();
      return false;
    }

    return false;
  }, [signOut]);

  const loadFolders = useCallback(async () => {
    if (!companyId) return;

    try {
      const response = await api.get(`/portal/folders/${companyId}`);

      setFolders(response.data);
    } catch {
      // continue
    }
  }, [companyId]);

  return (
    <PortalAuthContext.Provider
      value={{
        signIn,
        signOut,
        isLogged,
        loadCompany,
        loadUser,
        loadFolders,
        changeCompanyId,
        companyId,
        company,
        user,
        folders,
      }}
    >
      {children}
    </PortalAuthContext.Provider>
  );
};

export { PortalAuthProvider, PortalAuthContext };

import fileDownload from 'js-file-download';
import React, { useCallback } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { FaDownload, FaEye, FaTimes } from 'react-icons/fa';

import Modal from '~/components/Modal';
import api from '~/services/api';

import {
  Header,
  Content,
  Feedback,
  FeedbackItem,
  AppointmentInfo,
} from './styles';

const ScheduleModal = ({ isOpen, setIsOpen, data }) => {
  const openFile = useCallback(async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  }, []);

  const downloadFile = useCallback(async (blobName, fileName) => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    fileDownload(response.data, fileName);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      widthProp={800}
      heightProp={500}
    >
      <Header>
        <div>
          <h1>RESUMO KPI</h1>
        </div>
        <aside>
          <button type="button" onClick={setIsOpen}>
            <FaTimes size={20} color="#44546a" />
          </button>
        </aside>
      </Header>

      <Content>
        <AppointmentInfo>
          <h4>KPI</h4>
          <section>
            <div className="description">
              <label>Descrição</label>
              <TextareaAutosize
                name="description"
                value={data.description || ''}
                readOnly
              />
            </div>
          </section>
        </AppointmentInfo>

        <Feedback>
          <h4>FEEDBACK</h4>
          {data.feedback.length > 0 &&
            data.feedback.map(feedback => (
              <FeedbackItem key={feedback.id}>
                <div className="date">
                  <label>Data/Hora</label>
                  <input
                    name="feedback_date_formatted"
                    value={feedback.feedback_date_formatted}
                    readOnly
                  />
                </div>
                <div className="user">
                  <label>Usuário</label>
                  <input
                    name="feedback_user"
                    value={feedback.user.short_name}
                    readOnly
                  />
                </div>
                <div className="content">
                  <label>Mensagem</label>
                  <TextareaAutosize
                    name="content"
                    value={feedback.content}
                    readOnly
                    maxRows={4}
                  />
                </div>
                {feedback.type === 1 && (
                  <div className="file">
                    <aside>
                      <button
                        type="button"
                        onClick={() => openFile(feedback.link)}
                        title="Visualizar arquivo"
                      >
                        <FaEye size={16} />
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          downloadFile(feedback.link, feedback.file_name)
                        }
                        title="Baixar arquivo"
                      >
                        <FaDownload size={16} />
                      </button>
                    </aside>
                    <div>
                      <label>Arquivo</label>
                      <input type="text" value={feedback.file_name} readOnly />
                    </div>
                  </div>
                )}
              </FeedbackItem>
            ))}
        </Feedback>
      </Content>
    </Modal>
  );
};

export default ScheduleModal;

import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.darkBackground};
  z-index: 9;

  @media print {
    & {
      display: none;
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FaSearch, FaTimes } from 'react-icons/fa';

import { Spinner } from '~/components/Spinner';
import ClearBackground from '~/components/ClearBackground';

import api from '~/services/api';
import { Search, Option } from './styles';
import ShowPopModal from './ShowPopModal';

const SearchPop = ({ company }) => {
  const [searchInput, setSearchInput] = useState('');
  const [focusOnSearch, setFocusOnSearch] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [pops, setPops] = useState([]);
  const [selectedPop, setSelectedPop] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    async function searchPops() {
      try {
        setLoadingSearch(true);

        const response = await api.get('/pop', {
          params: {
            company_id: company.id,
            title: searchInput,
            selectOnly: 1,
          },
        });

        setPops(response.data);

        setLoadingSearch(false);
      } catch {
        setLoadingSearch(false);
        toast.error('Falha ao buscar POPs.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    if (searchInput.trim() !== '') {
      searchPops();
    }
  }, [company, searchInput]);

  const clearInput = () => {
    setSearchInput('');
    setFocusOnSearch(false);
  };

  const selectPop = index => {
    setSelectedPop(pops[index]);
    setModalIsOpen(true);
    setFocusOnSearch(false);
  };

  return (
    <>
      <Search style={{ zIndex: focusOnSearch ? 10 : 1 }}>
        <div className="input">
          <input
            name="search"
            type="text"
            placeholder="Pesquisar POP"
            autoComplete="off"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            onFocus={() => setFocusOnSearch(true)}
          />
          {searchInput.trim() !== '' ? (
            <FaTimes
              size={18}
              color="#01579B"
              onClick={clearInput}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <FaSearch size={18} color="#01579B" />
          )}
        </div>
        <div
          className={`result content${
            searchInput.trim() === '' || !focusOnSearch ? ' hidden' : ''
          }`}
        >
          {loadingSearch ? (
            <div className="loading">
              <Spinner />
            </div>
          ) : (
            <>
              {pops.length > 0 ? (
                pops.map((pop, index) => (
                  <Option
                    onClick={() => selectPop(index)}
                    className={pop.id === selectedPop?.id ? 'selected' : ''}
                    key={pop.id}
                  >
                    {pop.title}
                  </Option>
                ))
              ) : (
                <div className="empty">Nenhum POP encontrado</div>
              )}
            </>
          )}
        </div>
      </Search>
      {focusOnSearch && (
        <ClearBackground onClick={() => setFocusOnSearch(false)} />
      )}

      {modalIsOpen && (
        <ShowPopModal
          isOpen={modalIsOpen}
          setIsOpen={() => setModalIsOpen(false)}
          pop={selectedPop}
        />
      )}
    </>
  );
};

export default SearchPop;

import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Container } from './styles';

const TableLoading = () => {
  return (
    <Container>
      <Skeleton height={30} count={6} />
    </Container>
  );
};

export default TableLoading;

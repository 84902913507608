import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { getHours, format, subMonths, getDate } from 'date-fns';
import { toast } from 'react-toastify';

import { FaCalendarAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useAuth, useFetch } from '~/hooks';

import api from '~/services/api';

import BoardContext from './context';

import Board from './Board';
import Loading from '~/components/Loading';
import CreatingChecklistMessage from '~/components/CreatingChecklistMessage';

import { Container, Header, Boards } from './styles';

const Dashboard = ({
  createChecklistFirstLogin,
  createLabourChecklistOnDay15,
}) => {
  const { user, company } = useAuth();

  const [creatingChecklistLoading, setCreatingChecklistLoading] = useState(
    false
  );

  const [scheduleInfo, setScheduleInfo] = useState(null);
  const [boards, setBoards] = useState(null);
  const [lastBoards, setLastBoards] = useState(null);

  useEffect(() => {
    async function handleCreatingChecklist() {
      if (
        (createChecklistFirstLogin || createLabourChecklistOnDay15) &&
        company
      ) {
        try {
          setCreatingChecklistLoading(true);

          await api.post(`checklists/login/${company?.id}`, {
            createChecklistFirstLogin,
            createLabourChecklistOnDay15,
          });
        } catch {
          toast.error('Falha ao criar checklist.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } finally {
          setCreatingChecklistLoading(false);
        }
      }
    }

    async function handleVerifyExistCostsOfPeriod() {
      if (!company) return;

      try {
        await api.get(`/costs/${company.id}`, {
          params: {
            period: format(new Date(), 'MM/yyyy'),
            verify: true,
          },
        });
      } catch {
        toast.error('Falha ao criar os custos do período atual.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    handleVerifyExistCostsOfPeriod();
    handleCreatingChecklist();
  }, [createChecklistFirstLogin, createLabourChecklistOnDay15, company]);

  const currentDate = useMemo(() => {
    return new Date();
  }, []);

  const taxPeriod = useMemo(() => {
    return format(subMonths(currentDate, 1), 'MM/yyyy');
  }, [currentDate]);

  const labourPeriod = useMemo(() => {
    if (getDate(currentDate) >= 15) {
      return format(currentDate, 'MM/yyyy');
    }

    return format(subMonths(currentDate, 1), 'MM/yyyy');
  }, [currentDate]);

  const kpiPeriod = useMemo(() => {
    return subMonths(currentDate, 1);
  }, [currentDate]);

  const currentHour = useMemo(() => {
    return getHours(new Date());
  }, []);

  const { data: allBoards, error, mutate } = useFetch(
    company !== null && company?.id ? 'dashboard' : null,
    {
      company_id: company?.id,
      tax_period: taxPeriod,
      labour_period: labourPeriod,
      kpi_period: kpiPeriod,
    }
  );

  const loading = useMemo(() => !allBoards && !error, [allBoards, error]);

  if (error) {
    toast.error('Falha ao carregar dashboard.', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }

  useEffect(() => {
    if (allBoards) {
      setScheduleInfo(
        allBoards[0].find(board => board.type === 'schedule') || null
      );
      setBoards(allBoards[0]);
      setLastBoards(allBoards[1]);
    }
  }, [allBoards]);

  const move = useCallback(
    (fromId, from, toId, to) => {
      api.put('dashboard', {
        fromId,
        from,
        toId,
        to,
      });

      const updatedBoards = boards.map(dash => {
        if (dash.id === fromId) {
          return { ...dash, order: to };
        }
        if (dash.id === toId) {
          return { ...dash, order: from };
        }

        return dash;
      });

      const updatedLastBoards = lastBoards.map(dash => {
        if (dash.id === fromId) {
          return { ...dash, order: to };
        }
        if (dash.id === toId) {
          return { ...dash, order: from };
        }

        return dash;
      });

      updatedBoards.sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      });

      updatedLastBoards.sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      });

      mutate([updatedBoards, updatedLastBoards], false);
    },
    [boards, lastBoards, mutate]
  );

  if (creatingChecklistLoading) {
    return <CreatingChecklistMessage period="" />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <BoardContext.Provider value={{ boards, move }}>
      <Container className="content">
        <Link
          style={{ textDecoration: 'none', cursor: 'pointer', width: '500px' }}
          to="/schedule"
        >
          <Header>
            {currentHour >= 0 && currentHour < 12 && (
              <h1> Bom dia, {user?.nick}</h1>
            )}
            {currentHour >= 12 && currentHour < 18 && (
              <h1> Boa tarde, {user?.nick}</h1>
            )}
            {currentHour >= 18 && currentHour < 23 && (
              <h1> Boa noite, {user?.nick}</h1>
            )}
            {scheduleInfo && (
              <h2>
                <FaCalendarAlt color="#44546a" />
                <span style={{ color: '#44546a' }}>
                  {' '}
                  Você possui {scheduleInfo.todayAppointments} compromisso(s)
                  para hoje.
                </span>
              </h2>
            )}
          </Header>
        </Link>
        <Boards>
          {boards &&
            boards.map((board, index) => {
              if (board.type !== 'schedule') {
                return (
                  <Board
                    key={board.id}
                    data={board}
                    lastData={lastBoards[index]}
                    index={index}
                    taxPeriod={taxPeriod}
                    labourPeriod={labourPeriod}
                  />
                );
              }
              return null;
            })}
        </Boards>
      </Container>
    </BoardContext.Provider>
  );
};

export default Dashboard;

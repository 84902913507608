import React, { useRef } from 'react';

import ClearBackground from '../../../../components/ClearBackground';

import { Container, Content } from './styles';

const AddOptionModal = ({ handleAddNewOption, handleAddNewOptionModal }) => {
  const inputRef = useRef(null);

  return (
    <>
      <ClearBackground />
      <Container>
        <Content>
          <span>Criar novo tipo</span>
          <input type="text" ref={inputRef} />
          <div>
            <button
              type="button"
              onClick={() => handleAddNewOption(inputRef.current.value)}
            >
              Criar
            </button>
            <button type="button" onClick={handleAddNewOptionModal}>
              Cancelar
            </button>
          </div>
        </Content>
      </Container>
    </>
  );
};

export default AddOptionModal;

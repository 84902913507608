import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media print {
    & {
      display: flex;
    }
  }
`;

export const Ticket = styled.table`
  border-collapse: collapse;
  td {
    border: 1px solid #000;
  }

  .logo {
    width: 112.5px;
    height: 80px;

    display: flex;
    justify-content: center;

    img {
      width: 80px;
      height: 80px;
    }
  }

  .user {
    width: 600px;
  }

  .date {
    width: 112.5px;
  }

  .protocol {
    width: 478px;
  }

  .number {
    width: 118px;
  }
`;

export const Td = styled.td`
  height: 40px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    margin: 0px;
    padding: 0px;

    span {
      font-size: 11px;
      margin-left: 8px;
      margin-right: 0px;
      align-self: flex-start;
    }

    strong {
      font-size: 13px;
      align-self: center;
      margin-bottom: 3px;
      height: 15px;
    }
  }

  .header {
    width: 600px;
    justify-content: center;

    strong {
      font-size: 16px;
    }
  }

  .client,
  .cod {
    width: 300px;
  }
`;

export const TdTable = styled.div`
  width: 100%;

  padding: 10px;

  > span {
    font-weight: bold;
  }

  table {
    border: 0;
    border-spacing: 0px;
    margin: 8px 0 30px 16px;

    th {
      font-size: 11px;
      border-bottom: 1px solid #000;
      padding: 2px 4px;
    }

    td {
      border: 0;
      font-size: 11px;
      padding: 2px 4px;
    }

    .cod {
      text-align: center;
      width: 8%;
    }

    .document {
      text-align: left;
      width: 40%;
    }

    .comp,
    .deadline,
    .price {
      text-align: center;
      width: 8%;
    }

    .obs {
      text-align: left;
      width: 28%;
    }
  }
`;

export const CompanyInfo = styled.div`
  width: 100%;
  height: 15px;
  font-size: 11px;
  text-align: center;
  margin-top: 5px;

  strong,
  span {
    margin: 5px;
  }
`;

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useWindowSize } from '@react-hook/window-size';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ReactTooltip from 'react-tooltip';
import { useAuth } from '~/hooks';

import ChooseCompany from './ChooseCompany';

import redirectToOldPlatform from '~/util/redirectToOldPlatform';

import {
  Container,
  SidebarItens,
  Menu,
  MenuTitle,
  MenuSubApps,
  Icon,
  Background,
  Options,
  ItemTitle,
} from './styles';

export default function SideBar() {
  const [, height] = useWindowSize();

  const { apps, user, company } = useAuth();

  const [sidebarApps, setSidebarApps] = useState([]);
  const [menuApps, setMenuApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chooseCompanyVisible, setChooseCompanyVisible] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const numberOfApps = Math.floor((height - 320) / 30);

  useEffect(() => {
    function getApps() {
      if (apps) {
        setSidebarApps([]);
        setMenuApps([]);

        setSidebarApps(apps.slice(0, numberOfApps));
        setMenuApps(apps.slice(numberOfApps, apps.length));

        setLoading(false);
      }
    }

    getApps();
  }, [apps, height, numberOfApps]);

  const handleToggleChooseCompany = useCallback(() => {
    setChooseCompanyVisible(!chooseCompanyVisible);
  }, [chooseCompanyVisible]);

  return (
    <>
      {chooseCompanyVisible && (
        <ChooseCompany
          visible={chooseCompanyVisible}
          handleToggleChooseCompany={handleToggleChooseCompany}
        />
      )}

      <Background
        onClick={() => {
          setMenuIsOpen(false);
          setSelectedOption(null);
        }}
        isOpen={menuIsOpen}
      />

      {!loading && user && company && (
        <Container
          height={height}
          numberOfApps={numberOfApps}
          isOpen={menuIsOpen}
          option={selectedOption}
        >
          {!menuIsOpen && (
            <ReactTooltip
              id="app"
              place="right"
              type="info"
              backgroundColor="#337ab7"
              effect="solid"
              multiline
            />
          )}

          <ul>
            <>
              {sidebarApps.map((app, index) => (
                <SidebarItens
                  key={app.app.id}
                  subApps={app.app.subApps.length}
                  isOpen={menuIsOpen}
                  className={app.app.title
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replaceAll(' ', '-')}
                >
                  <ItemTitle
                    onClick={() => {
                      setMenuIsOpen(true);
                      setSelectedOption(index + 1);
                    }}
                    data-tip={app.app.title}
                    data-for="app"
                  >
                    <Icon>
                      <FontAwesomeIcon icon={app.app.icon} size="lg" />
                    </Icon>

                    <strong>{app.app.title}</strong>
                  </ItemTitle>
                  <ul>
                    {app.app.subApps.map(subApp =>
                      subApp.link ? (
                        <Link
                          onClick={() => {
                            setMenuIsOpen(false);
                            setSelectedOption(null);
                          }}
                          to={{
                            pathname: `/${subApp.link}`,
                            state: { id: null },
                          }}
                          key={subApp.id}
                        >
                          <li>
                            <div>
                              <FontAwesomeIcon icon={subApp.icon} size="sm" />
                            </div>
                            <span>{subApp.title}</span>
                          </li>
                        </Link>
                      ) : (
                        <button
                          key={subApp.id}
                          type="button"
                          onClick={() => {
                            setMenuIsOpen(false);
                            setSelectedOption(null);
                            redirectToOldPlatform(
                              user,
                              company.old_id,
                              subApp.old_link
                            );
                          }}
                        >
                          <li>
                            <div>
                              <FontAwesomeIcon icon={subApp.icon} size="sm" />
                            </div>
                            <span>{subApp.title}</span>
                          </li>
                        </button>
                      )
                    )}
                  </ul>
                </SidebarItens>
              ))}
              {menuApps.length > 0 && (
                <Menu apps={menuApps.length}>
                  <FontAwesomeIcon icon="ellipsis-h" />
                  <ul>
                    {menuApps.map(menuApp => (
                      <li key={menuApp.app.id}>
                        <MenuTitle>
                          <div>
                            <FontAwesomeIcon icon={menuApp.app.icon} />
                          </div>
                          <strong>{menuApp.app.title}</strong>
                        </MenuTitle>
                        <MenuSubApps subApps={menuApp.app.subApps.length}>
                          {menuApp.app.subApps.map(subApp =>
                            subApp.link ? (
                              <Link
                                to={{
                                  pathname: `/${subApp.link}`,
                                  state: { id: null },
                                }}
                                key={subApp.id}
                              >
                                <li>
                                  <div>
                                    <FontAwesomeIcon icon={subApp.icon} />
                                  </div>
                                  <span>{subApp.title}</span>
                                </li>
                              </Link>
                            ) : (
                              <button
                                key={subApp.id}
                                type="button"
                                onClick={() =>
                                  redirectToOldPlatform(
                                    user,
                                    company.old_id,
                                    subApp.old_link
                                  )
                                }
                              >
                                <li>
                                  <div>
                                    <FontAwesomeIcon icon={subApp.icon} />
                                  </div>
                                  <span>{subApp.title}</span>
                                </li>
                              </button>
                            )
                          )}
                        </MenuSubApps>
                      </li>
                    ))}
                  </ul>
                </Menu>
              )}
            </>
          </ul>
          <Options isOpen={menuIsOpen}>
            <button
              type="button"
              onClick={() => {
                setMenuIsOpen(isOpen => !isOpen);
                setSelectedOption(option => option && null);
              }}
            >
              <FontAwesomeIcon icon="bars" />
              <span>Fechar</span>
            </button>
            <button type="button" onClick={handleToggleChooseCompany}>
              <FontAwesomeIcon icon="exchange-alt" />
              <span>Alterar Empresa</span>
            </button>
          </Options>
        </Container>
      )}
    </>
  );
}

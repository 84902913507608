import React from 'react';
import { PropTypes } from 'prop-types';
import { FaClock } from 'react-icons/fa';

import { Spinner } from '~/components/Spinner';
import ClearBackground from '~/components/ClearBackground';

import { Container, Content, Icon, MessageContent, Header } from './styles';

const LoadingMessage = ({ message }) => {
  return (
    <>
      <Container>
        <Content>
          <Icon>
            <FaClock size={38} color="#01579b" />
          </Icon>
          <MessageContent>
            <Header>
              <strong>Aguarde</strong>
            </Header>
            <span>
              <strong>{message}</strong>Isto pode demorar um pouco.
            </span>
            <div>
              <Spinner />
            </div>
          </MessageContent>
        </Content>
      </Container>
      <ClearBackground />
    </>
  );
};

export default LoadingMessage;

LoadingMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

import axios from 'axios';

const returnAddressesWithCitiesOptions = async addresses => {
  const promises = addresses.map(async address => ({
    ...address,
    complement: address.complement === 'Array' ? '' : address.complement,
    citiesOptions: await axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${address.state}/municipios`
      )
      .then(result => {
        const citiesOptions = result.data.map(city => ({
          value: city.nome,
          label: city.nome,
        }));

        return citiesOptions;
      }),
  }));

  return Promise.all(promises);
};

export default returnAddressesWithCitiesOptions;

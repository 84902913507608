import React, { useState, useCallback } from 'react';
import {
  FaFileAlt,
  FaMailBulk,
  FaHatWizard,
  FaSignInAlt,
  FaBuilding,
  FaLaptop,
  FaTimes,
  FaQuestionCircle,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';
import history from '~/services/history';

import ClearBackground from '~/components/ClearBackground';
import MyData from './MyData';
import CompanieData from './CompanieData';
import AutomatedRoutines from './AutomatedRoutines';
import System from './System';
import PermissionComponent from '~/components/PermissionComponent';

import logo from '~/assets/building.png';

import { Container, Badge, Menu, Close, Info } from './styles';

const Profile = () => {
  const { user, company, signOut } = useAuth();

  const [visible, setVisible] = useState(false);
  const [toggleAutomatedRoutines, setToggleAutomatedRoutines] = useState(false);
  const [toggleSystem, setToggleSystem] = useState(false);
  const [toggleMyData, setToggleMyData] = useState(false);
  const [toggleCompanieData, setToggleCompanieData] = useState(false);

  const handleToggleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleToggleAutomatedRoutines = useCallback(() => {
    setToggleAutomatedRoutines(!toggleAutomatedRoutines);
    setVisible(!visible);
  }, [toggleAutomatedRoutines, visible]);

  const handleToggleSystem = useCallback(() => {
    setToggleSystem(!toggleSystem);
    setVisible(!visible);
  }, [toggleSystem, visible]);

  const handleToggleSupport = useCallback(() => {
    setVisible(!visible);
    history.push('/support');
  }, [visible]);

  const handleToggleMail = useCallback(() => {
    setVisible(!visible);
    history.push('/mail-settings');
  }, [visible]);

  const handleToggleMyData = useCallback(() => {
    setToggleMyData(!toggleMyData);
    setVisible(!visible);
  }, [toggleMyData, visible]);

  const handleToggleCompanieData = useCallback(() => {
    setToggleCompanieData(!toggleCompanieData);
    setVisible(!visible);
  }, [toggleCompanieData, visible]);

  return (
    <>
      <Container visible={visible}>
        {company && user && (
          <>
            <Badge onClick={handleToggleVisible}>
              <img
                src={company && company.logo ? company.logo_url : logo}
                alt="Perfil"
              />
            </Badge>

            <Menu visible={visible}>
              <Close>
                <FaTimes color="#E53935" onClick={handleToggleVisible} />
              </Close>
              <Info>
                <img
                  src={company.logo ? company.logo_url : logo}
                  alt="Perfil"
                />
                <div>
                  <strong>{company.name}</strong>
                  {user.nick || ''}
                </div>
              </Info>

              <button type="button" onClick={handleToggleMyData}>
                <FaFileAlt color="#44546A" />
                Meus Dados e Senha
              </button>
              <button type="button" onClick={handleToggleSupport}>
                <FaQuestionCircle color="#44546A" />
                Solicitar suporte
              </button>
              <PermissionComponent level={9}>
                <button type="button" onClick={handleToggleCompanieData}>
                  <FaBuilding color="#44546A" />
                  Dados da Empresa
                </button>
                <button type="button" onClick={handleToggleMail}>
                  <FaMailBulk color="#44546A" />
                  Configurações de Email
                </button>
                <button type="button" onClick={handleToggleAutomatedRoutines}>
                  <FaHatWizard color="#44546A" />
                  Rotinas Automatizadas
                </button>
                {user.id === 1 && (
                  <button type="button" onClick={handleToggleSystem}>
                    <FaLaptop color="#44546A" />
                    Gerenciar Sistema
                  </button>
                )}
              </PermissionComponent>

              <button type="button" onClick={signOut}>
                <FaSignInAlt color="#44546A" />
                Sair
              </button>
            </Menu>
          </>
        )}
      </Container>
      {visible && <ClearBackground onClick={handleToggleVisible} />}
      {toggleMyData && (
        <MyData isOpen={toggleMyData} setIsOpen={handleToggleMyData} />
      )}
      {toggleCompanieData && (
        <CompanieData
          isOpen={toggleCompanieData}
          setIsOpen={handleToggleCompanieData}
        />
      )}
      {toggleAutomatedRoutines && (
        <AutomatedRoutines
          isOpen={toggleAutomatedRoutines}
          setIsOpen={handleToggleAutomatedRoutines}
        />
      )}
      {toggleSystem && (
        <System isOpen={toggleSystem} setIsOpen={handleToggleSystem} />
      )}
    </>
  );
};

export default Profile;

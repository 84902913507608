import React, { useRef, useEffect } from 'react';
import Select from 'react-select/async';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Container } from './styles';

const AsyncSelect = ({ name, label, className, ...rest }) => {
  const selectRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  const customStyles = {
    container: provided => ({
      ...provided,
      display: 'inline-block',
      minHeight: '1px',
      textAlign: 'left',
      border: '0',
    }),
    control: provided => ({
      ...provided,
      borderRadius: '0',
      minHeight: '1px',
      height: '30px',
      border: 0,
      borderBottom: error ? '1px solid #e53935' : '1px solid #ddd',
      fontSize: 12,
      background: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '',
      },
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: '#ccc',
      '&:hover': {
        color: '#ccc',
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: '#545454',
    }),
    option: (provided, { isDisabled: disabled, isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: disabled
        ? null
        : isSelected
        ? '#01579b'
        : isFocused
        ? '#e5eef5'
        : null,
    }),
    input: provided => ({
      ...provided,
      top: '40%',
    }),
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map(option => option.value);
        }
        if (!ref.select.state.value) {
          return '';
        }
        return ref.select.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container className={className}>
      <label>{label}</label>
      <Select
        cacheOptions
        styles={customStyles}
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        onFocus={clearError}
        menuPlacement="auto"
        {...rest}
      />
      <span>{error}</span>
    </Container>
  );
};

export default AsyncSelect;

AsyncSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
};

AsyncSelect.defaultProps = {
  label: null,
  className: null,
};
